const SignupValidationSchema = {
    username: {
      required: true,
      validator: {
        regEx: /^(?=[a-zA-Z0-9._]{8,20}$)(?!.*[_.]{2})[^_.].*[^_.]$/,
        error: "Invalid Username.",
      },
    },   
    firstName: {
      required: true
    },
    lastName: {
      required: true
    },
    password: {
      required: true,
    },
    confirmPassword: {
      required: true,
    },
  };
  
  export default SignupValidationSchema;
  