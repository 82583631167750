import React, {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import useForm from '../customHooks/useForm';
import HTMLEditor from "../../sharedComponents/HTMLEditor/HTMLEditor";

const useStyles = makeStyles((theme) => ({
  grid: {
    padding: theme.spacing(1),
    alignSelf: "center",
  },
  question: {
    color: theme.palette.primary.main,
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(1),
    },
  },
}));

const initialFormData = {
  taskInfo: { value: "", hasError: null },
};

const ResponsibilityAndTask = (props) => {
  const classes = useStyles();

  const [state, setState] = useState(props.data);


  const { errors, handleInputChange, values } = useForm(
    state,
    props.onChange,    
  );

  return (
    <Grid container>
      <Grid item md={12} xs={12} className={classes.grid}>
        {/* <TextField
          id="info"
          variant="outlined"
          //label={<Typography variant="body2"></Typography>}
          size="small"
          fullWidth
          multiline
          rows={4}
          name="description"
          value={values && values.description ? values.description : ''}
          onChange={handleInputChange}
        /> */}
                 <HTMLEditor
                    id="info" name="description"
                    data={values && values.description ? values.description : ''}
                    onChange={handleInputChange}
                    error={errors}
                />
      </Grid>
    </Grid>
  );
};

export default ResponsibilityAndTask;
