import Moment from "moment";
import CultureInfo from "./CultureInfo";
import { jwplayerService } from "../services";

export default class Helper {
  static FormatDate(value) {
    return value
      ? Moment(value).format(CultureInfo.GetCultureInfo().DateFormat)
      : "";
  }

  static FormatDateMMYYYY(value) {
    return value
      ? Moment(value).format(CultureInfo.GetCultureInfo().MonthWithYearFormat)
      : "";
  }

  static GetYearFromDate(date) {
    var d = new Date(date);
    try {
      return d.getFullYear();
    } catch (e) {
      return "";
    }
  }

  static FormatTime(value) {
    return value
      ? Moment(value).format(CultureInfo.GetCultureInfo().TimeFormat)
      : "";
  }

  static getDisplayDate(value) {
    return value ? Moment(value).format("ddd, MM Do YYYY, h:mm a") : ""
  }

  static getFormattedDate(value) {
    return value ? Moment(value).format("MMM DD, YYYY") : ""
  }

  static FormatDayWithDate(value) {
    return value
      ? Moment(value).format(CultureInfo.GetCultureInfo().DayWithDateFormat)
      : "";
  }

  static FormatDateToLocalTimeZone(value) {
    return value
      ? Moment(value).local().format(CultureInfo.GetCultureInfo().DateFormat)
      : "";
  }

  static FormatDateTime(value) {
    return value
      ? Moment(value).format(CultureInfo.GetCultureInfo().DateTimeFormat)
      : "";
  }

  static FormatDateTime24(value) {
    return value
      ? Moment(value).format(CultureInfo.GetCultureInfo().DateTimeFormat24)
      : "";
  }

  static FormatDateTimeToLocalTimeZone(value) {
    return value
      ? Moment(value)
        .local()
        .format(CultureInfo.GetCultureInfo().DateTimeFormat24)
      : "";
  }

  //... Returns a random Id string. Id length is based on the supplied length.
  static GetRandomId(length = 5) {
    var text = "";
    var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";

    for (var i = 0; i < length; i++)
      text += possible.charAt(Math.floor(Math.random() * possible.length));

    return text;
  }

  static IfDateIsNULL(inputDate) {
    var minDate = Moment.utc("01/01/0001"); // minimum value as per UTC

    var receiveDate = Moment(this.FormatDate(inputDate)); // replace with variable
    if (Moment.utc(receiveDate).isAfter(minDate)) {
      return false;
    } else {
      return true;
    }
  }

  static GetNameInitials(name) {
    var iChars = "";
    name &&
      name.replace(/[a-z]{2,}/gi, function (c) {
        iChars += c.charAt(0);
      });
    return iChars.toUpperCase();
  }

  static Validate(value, params) {
    let obj = { isValid: true, validationMessage: "" };

    if (
      params.Required &&
      (value === null || value === "" || value.length === 0)
    ) {
      obj.isValid = false;
      obj.validationMessage = "Please enter value";
    }
    return obj;
  }

  static MakeFirstCharCapital(value) {
    if (value && value.length > 0) {
      return value.charAt(0).toUpperCase() + value.slice(1);
    }
    return "";
  }
  static RemoveTags(str) {
    if (str === null || str === "" || str == undefined) return "";
    else str = str.toString();
    return str.replace(/(<([^>]+)>)/gi, "");
  }

  static StringExcerpt(string, count = 120) {
    if (string?.length > count) {
      return string.substring(0, count) + "...";
    }
    return string.substring(0, count);
  }

  static GetLeagueTextByValue = (leagueValue) => {
    try {
      if (leagueOptionsList.length > 0 && leagueValue != undefined && leagueValue != "")
        return leagueOptionsList.find(x => x.value == leagueValue).text;
      else return "";
    }
    catch {
      return "";
    }
  }

  static GetJWTToken = () => {
    jwplayerService.getToken()
      .then(token => {
        return token;
      })
  }
}


