import React, { useState, useContext, useEffect } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import { Grid, Typography, TextField, Button } from "@material-ui/core/";
import ProfileSummary from "../../../sharedComponents/profileSummary/ProfileSummary";
import {
    CoachProfileProvider,
    CoachProfileConsumer,
    AdminCoachProfileContext
} from  "../../../common/AdminCoachProfileContext";
import AppConstants from "../../../common/AppConstants";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import TermsAndCondition from "../../../sharedComponents/dialog/TermsAndCondition";
import { coachProfileService } from "../../../services";
import SnackbarMsg from "../../../sharedComponents/Snackbar/Snackbar";
import ProfileSubmitValidations from "../../coachProfileEdit/ProfileSubmitValidations";
import EditProfileValidationDialog from "../../../sharedComponents/dialog/EditProfileValidationDialog";
import CaptionMsg from "../../../sharedComponents/CaptionMsg";
import ProfileDetailsEdit from "./ProfileDetailsEditSectionContainer";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
    },
    grid: {
        display: "flex",
        flexGrow: 1,
        flexWrap: "wrap",
        paddingTop: theme.spacing(1),
        backgroundColor: theme.palette.background.default,
        [theme.breakpoints.up("sm")]: {
            padding: theme.spacing(1),
        },
        [theme.breakpoints.down("xs")]: {
            padding: 0,
            flexGrow: 0,
        },
    },
    section: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(3),
        textAlign: "left",
        padding: "5px 25px",
    },
    gridItem: {
        [theme.breakpoints.up("sm")]: {
            width: "100%",
            padding: theme.spacing(1),
        },
        [theme.breakpoints.down("xs")]: {
            width: "100%",
            paddingBottom: theme.spacing(2),
        },
    },
    profileSummary: {
        paddingBottom: theme.spacing(2),
        minHeight: "300px",
        boxShadow: '0px 0px 5px rgba(6, 37, 91, .2)',
        borderRadius: '0',
        backgroundColor: '#06255b',
    },
    profileDetails: {
        textAlign: "left",
        color: theme.palette.text.secondary,
        borderRadius: '0',
    },
    submitButton: {
        alignContent: "center",
        textAlign: "center",
        paddingBottom: 10,
        paddingRight: 10,
    },
}));

const ProfileEditMainContainer = (props) => {
    let isViewMode = false;
    let params=props.match.params.userName;
    
    const classes = useStyles();
    return (
        <CoachProfileProvider>
            <CoachProfileConsumer>
                {(props) => {
                    return (
                        <div className={classes.root}>
                            <Grid className={classes.grid}>
                                <Grid item md={3} sm={3} className={classes.gridItem}>
                                    <CoachProfileSummury isViewMode={isViewMode} showBackButton={true} />
                                </Grid>

                                <Grid item md={9} sm={9} className={classes.gridItem}>
                                    <Paper className={classes.profileDetails}>
                                        <ProfileDetailsEdit params={params} />
                                    </Paper>

                                </Grid>
                            </Grid>
                        </div>)
                }}
            </CoachProfileConsumer>
        </CoachProfileProvider>
    );
}


const CoachProfileSummury = (props) => {

    const classes = useStyles();

    const { profilePhoto, profileVideo, status, fullName, userName, roleAndCoachingPhilosophy, mediaAndArticle, coachingVideos, jwtTokenDetails, profileCompleteness, remainingSection, updateStatus,
        coachingExperience,
        other,
        additionalExperience,
        skillSet,
        educationDetail,
        coachingAndOtherJobExperience,
        playingExperience,lastModifiedAt } = useContext(
            AdminCoachProfileContext
        );
    const [statusState, setStatusState] = useState(status);

    const [btnSubmit, setBtnSubmit] = useState(true);
    const [showMsg, setShowMsg] = useState(false);
    const handleTermsandCondition = (event) => {
        ////// To Do Terms Save
        if (event.target.checked) {
            setBtnSubmit(false);
        } else {
            setBtnSubmit(true);
        }
    };
    const [showTermsAndConditions, setShowTermsAndConditions] = useState(false);
    const toggleTermsAndCondition = (event, value) => {
        event.preventDefault();
        setShowTermsAndConditions(value);
    };

    const [validationDialogOpen, setValidationDialogOpen] = useState(false);
    const [validationMessageList, setValidationMessageList] = useState([]);

    const handleSubmitClick = () => {
        let validationMessage = [];
        validationMessage = ProfileSubmitValidations.ValidateProfile(fullName, roleAndCoachingPhilosophy, profilePhoto, profileVideo, mediaAndArticle, coachingVideos,
            coachingExperience, other, additionalExperience, skillSet, educationDetail, coachingAndOtherJobExperience, playingExperience)
        if (validationMessage.length == 0) {
            coachProfileService.submitProfile()
                .then(status => {
                    setShowMsg(true);
                    updateStatus(AppConstants.PROFILE_STATUS.SUBMITTED);
                    setStatusState(AppConstants.PROFILE_STATUS.SUBMITTED);
                });
        }
        else {
            setValidationDialogOpen(true);
            setValidationMessageList(validationMessage);
        }
    };
    useEffect(() => {
        setStatusState(status);
    }, [status]);

    return (
        <>
            <Paper className={classes.profileSummary}>
                <ProfileSummary
                    profilePhoto={profilePhoto}
                    profileVideo={profileVideo}
                    fullName={fullName}
                    userName={userName}
                    roleAndCoachingPhilosophy={roleAndCoachingPhilosophy}
                    mediaAndArticle={mediaAndArticle}
                    coachingVideos={coachingVideos}
                    jwtTokenDetails={jwtTokenDetails}
                    profileCompleteness={profileCompleteness}
                    remainingSection={remainingSection}
                    profileStatus={status}
                    lastModifiedAt = {lastModifiedAt}
                    isViewMode={props.isViewMode} showBackButton={props.showBackButton} isCoachProfile={true}></ProfileSummary>
            </Paper>
            {props.isViewMode && <div>
                {(statusState && (statusState == AppConstants.PROFILE_STATUS.REGISTERED ||
                    statusState == AppConstants.PROFILE_STATUS.DENIED)) &&
                    <Grid container style={{ paddingTop: "10px" }} className={classes.submitButton}>
                        <Grid item md={12} xs={12}></Grid>
                        <Grid item md={12} xs={12}>
                            {/* <Checkbox
                                name="acceptTerms"
                                onChange={handleTermsandCondition}
                                title="Accept Terms And Condition"
                            />
          Accept &nbsp;
          <Link href="#" onClick={(e) => toggleTermsAndCondition(e, true)}>
                                Terms and Conditions
          </Link> */}
                            {/* {profileCompleteness < 60 &&
                                <CaptionMsg message={AppConstants.PROFILECOMPLETENESS_VALIDATION_MESSAGE} />} */}
                            <Button
                                onClick={handleSubmitClick}
                                color="primary"
                                variant="contained"
                            //  disabled={profileCompleteness < 60}
                            >
                                Submit Profile
                            </Button>
                        </Grid>

                    </Grid>
                }

                <SnackbarMsg
                    showError={showMsg}
                    onClose={() => setShowMsg(false)}
                    severity="success"
                    msg="Thank you for submitting your profile."
                />


                <EditProfileValidationDialog validationMessageList={validationMessageList} validationDialogOpen={validationDialogOpen} onClose={() => setValidationDialogOpen(false)} />
                {/* <TermsAndCondition showTermsAndConditions={showTermsAndConditions} onClose={() => setShowTermsAndConditions(false)} /> */}
            </div>}
        </>)


}

export default ProfileEditMainContainer;