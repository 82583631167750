export function reactNativeInvoker(action, data = null)
 {
   
    if (!window.ReactNativeWebView)
        throw new Error(`Cannot execute action "${action}". Please ensure that your application is running inside of the React Native Web View.`);

    //... Stringify the message object before posting.
    let msg = JSON.stringify({
        action,
        data
    });

    //... Post the message to the React Native webview component.
    window.ReactNativeWebView.postMessage(msg);

}