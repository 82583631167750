import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { TextField, Chip } from "@material-ui/core/";

const useStyles = makeStyles((theme) => ({
    grid: {
        padding: theme.spacing(1),
        alignSelf: "center",
    },
}));
const AutocompleteComponent = (props) => {
    const classes = useStyles();
    const [data, setData] = useState(props.data);
    const [error, setError] = useState(false);

    useEffect(() => {
        props.onChange(data);
        if (props.isInValid && data.length == 0)
            setError(true);
        else
            setError(false)
    }, [data, props.isInValid]);

    function handelKeyUp(e) {
        if (e.keyCode == 188) {
            var val = e.target.value.replace(",", "");
            setData(data => [...data, val]);
        }

    }

    return (
        <div className={classes.grid}>
            <Grid container>
                <Grid item md={12} xs={12} className={classes.grid}>
                    <Autocomplete
                        multiple
                        freeSolo
                        onKeyUp={handelKeyUp}
                        filterSelectedOptions
                        value={data}
                        id="skill"
                        size="small"
                        options={props.list ? props.list.map((option) => option.Name) : []}
                        renderTags={(languages, getTagProps) =>
                            languages.map((option, index) => (
                                <Chip
                                    label={option}
                                    {...getTagProps({ index })}
                                />
                            ))
                        }
                        disableCloseOnSelect
                        onChange={(event, newValue) => {
                            let val = newValue.length > 0 ? newValue : [];
                            setData(val);
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                error={error}
                                label={<Typography variant="body2">{props.label}</Typography>}
                            />
                        )}
                    />
                </Grid>
            </Grid>
        </div>
    );
};

export default AutocompleteComponent;
