import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
}));

function Loader(props) {
    const classes = useStyles();
    return (
        <Backdrop className={classes.backdrop} open={props.isLoading}>
            <CircularProgress color="inherit" />
        </Backdrop>
    );
}

export default Loader;