import React, { useState, useContext, useEffect, useRef, Component } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import Toolbar from '@material-ui/core/Toolbar';
import Hidden from '@material-ui/core/Hidden';
import SmartSearch from '../sharedComponents/smartSearch/SmartSearch';
import frontOfficeService from "../services/frontOffice.service";
import { AppDataContext } from '../common/AppContext';
const drawerWidth = 260;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        zIndex: theme.zIndex.appBar - 1,
        width: drawerWidth,
    },
    drawerContainer: {
        overflow: 'auto',
        height: '100%',
        '&::-webkit-scrollbar': {
            width: '5px'
        },
        '&::-webkit-scrollbar-track': {
            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#bdbdbd',
            outline: '1px solid slategrey'
        }
    },
}));



const FrontOfficeLayout = ({ children }) => {
    const classes = useStyles();


    return (
        <div className={classes.root}>
            <CssBaseline />

            {children}
        </div>

    )

}

export default FrontOfficeLayout;