import React, { useEffect, useState,useRef } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import ListItem from "@material-ui/core/ListItem";

import EventExperienceList from "../../../sharedComponents/questionComponents/EventExperienceList";
import CharityInformation from "../../../sharedComponents/questionComponents/CharityInformation";
import SaveButton from "../../../sharedComponents/saveButton/SaveButton";
import BackButton from "../../../sharedComponents/saveButton/BackButton";
import CaptionMsg from "../../../sharedComponents/CaptionMsg";
import TitleWithTooltip from "../../../sharedComponents/TitleWithTooltip";
import useTelemetry from "../../../utils/useTelemetry";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  section: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  question: {
    color: theme.palette.info,
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(1),
    },
  },
  questionSubtext: {
    marginLeft: theme.spacing(1),
  },
}));

const AdditionalExperience = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const container = useRef(null);
  const { trackCustomEvents } = useTelemetry();

  useEffect(() => {
    container.current.scrollIntoView({ block: 'end', behavior: 'smooth' });
  }, []);

  function save() {
    props.onChange();
    trackCustomEvents("EditEvent", { action: "Additional Experience" });
  }
  return (
    <div ref={container} className={classes.root}>
      <div className={classes.section}>
        <TitleWithTooltip
          title="1. Have you participated in any NBA or International basketball events and experiences?"
          tooltipTitle="Events_ToolTip"
          variant="body2"
          class={classes.question}
        />
        <CaptionMsg message="(Please type in the values if you don't find them listed.)" />
        <EventExperienceList
          eventList={props.eventList}
          data={props.eventDetail}
          onChange={props.handleEventInputChange}
        />
      </div>

      <div className={classes.section}>
        <TitleWithTooltip
          title="2. Please list any and all information on your charitable experiences or social responsibility efforts."
          tooltipTitle="Charitable_ToolTip"
          variant="body2"
          class={classes.question}
        />
        <CaptionMsg message="(NBA Cares participation, personal charitable activities you dedicate your time to)"/>
        <CharityInformation
          onChange={props.handleCharityInformation}
          data={props.charitableExperience}
        />
      </div>
      <Grid item md={12} xs={12}>
        <ListItem style={{ justifyContent: "flex-end" }}>
          <BackButton            
            onClick={props.handlePreveStepClick}
          ></BackButton>
          <SaveButton isLoading={props.isLoading} onClick={save}></SaveButton>
        </ListItem>
      </Grid>
    </div>
  );
};
export default AdditionalExperience;
