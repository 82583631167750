import React, { useState, useEffect, useRef } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Checkbox from "@material-ui/core/Checkbox";
import { Link } from "react-router-dom";
import Tooltip from '@material-ui/core/Tooltip';
import MedalIcon from '../../sharedComponents/customIcons/MedalIcon';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Switch from "@material-ui/core/Switch";
import EditTwoToneIcon from '@material-ui/icons/EditTwoTone';
import DataMapper from "../../common/DataMapper";
import VpnKeyTwoToneIcon from '@material-ui/icons/VpnKeyTwoTone';
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        [theme.breakpoints.up('sm')]: {
            padding: theme.spacing(1)
        },
    },
    gridItem: {
        [theme.breakpoints.up('sm')]: {
            width: '100%',
            padding: theme.spacing(1)
        },
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            paddingBottom: theme.spacing(1)
        },
    },
    Name: {
        fontWeight: 'bold',
        fontSize: '18px',
        textDecoration: 'none',
        color: '#393939'
    },
    tableRow: {
        // height: '100px !important',
        // boxShadow: '1px 1px 1px #aaaaaa',
        '& td:nth-of-type(4)': {
            paddingRight: theme.spacing(1),
        }
    }
}));

export default function UserList(props) {
    const classes = useStyles();
    const [isActive, setIsActive] = useState(props.data.isActive);

    useEffect(() => {
        setIsActive(props.data.isActive);
    }, [props.data])

    const handleStatusChange = (event) => {
        var status = event.target.checked;
        setIsActive(status);
        let postData = { "userName": props.data.userName, "email": props.data.email, "isActive": status, "role": props.data.role }
        props.handleStatusChange(postData);
    }
    const onEditClick = () => {
        props.onEditClick(props.data);
    }
    const checkProfileCheckbox = (userId) => {
		return props.selectedUsers?  props.selectedUsers.includes(userId):false;
	}

   const onChangePasswordClick=()=>{
    props.onChangePasswordClick(props.data);
   }

    return (
        <>
            <TableRow className={classes.tableRow}>
                {props.showCheckBox && props.showCheckBox == true &&
                    <TableCell align="left" style={{ width: '3%' }} >
                        <Checkbox
                            name="chkGroup"
                            id={props.data.userName}
                            checked={checkProfileCheckbox(props.data.userName)}
                            onChange={(event) => props.handleUserChecked(event, props.data.userName)}
                        />
                    </TableCell>}
                <TableCell align="left" style={{ width: '20%' }} >
                    <Typography variant="body2"> {props.data.firstName} {props.data.lastName} </Typography>
                </TableCell>
                <TableCell align="left" style={{ width: '20%' }} >
                    <Typography variant="body2">
                        {props.data.userName}</Typography>
                </TableCell>
                <TableCell align="left" style={{ width: '27%' }}>
                    <Typography color="primary" variant="body2">{props.data.email}</Typography>
                </TableCell>
                <TableCell align="left" style={{ width: '20%' }}>
                    <Typography variant="body2" color="primary">{DataMapper.GetTextByValue(props.data.role)}</Typography>
                </TableCell>
                <TableCell align="left" style={{ width: '5%' }}>
                    <Tooltip title={isActive == true ? 'Deactivate User' : 'Activate User'}>
                        <Switch
                            size="small"
                            checked={isActive ? isActive : false}
                            onChange={() => handleStatusChange(event)}
                            color='primary'
                        />
                    </Tooltip>
                </TableCell>
                <TableCell align="left" style={{ width: '5%' }}>
                    <Tooltip title="Edit" onClick={onEditClick} style={{ cursor: "pointer" }}>
                        <EditTwoToneIcon color="primary" />
                    </Tooltip>
                </TableCell>
                <TableCell align="left" style={{ width: '5%' }}>
                    <Tooltip title="Change Password" onClick={onChangePasswordClick} style={{ cursor: "pointer" }}>
                        <VpnKeyTwoToneIcon color="primary" />
                    </Tooltip>
                </TableCell>
            </TableRow>
        </>
    )
}