export const cacheStorage = {
    saveProfilePhotoToLocalStorage,
    getProfilePhotoFromLocalStorage,
    removeProfilePhotoFromLocalStorage
};

function saveProfilePhotoToLocalStorage(username, signedUrl){
    localStorage.setItem(username, signedUrl);
}

function getProfilePhotoFromLocalStorage(username) {
    return localStorage.getItem(username);
}

function removeProfilePhotoFromLocalStorage(username) {
    localStorage.removeItem(username);
}