import React, { useState, useEffect, useRef, useContext } from "react";
import { useLocation, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Toolbar from "@material-ui/core/Toolbar";
import Box from "@material-ui/core/Box";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import VisibilityIcon from "@material-ui/icons/Visibility";
import QueueOutlinedIcon from "@material-ui/icons/QueueOutlined";
import ViewListIcon from "@material-ui/icons/ViewList";
import ViewModuleIcon from "@material-ui/icons/ViewModule";
import Tooltip from "@material-ui/core/Tooltip";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CardAccountDetailsStarOutline from "../../sharedComponents/customIcons/CardAccountDetailsStarOutline";
import CardAccountDetailsOutline from "../../sharedComponents/customIcons/CardAccountDetailsOutline";
import SortAlphabeticalAscendingVariant from "../../sharedComponents/customIcons/SortAlphabeticalAscendingVariant";
import CreateGroupIcon from "../../sharedComponents/customIcons/CreateGroupIcon";
import DownloadIcon from "../../sharedComponents/customIcons/DownloadIcon";
import CreateShowcaseIcon from "../../sharedComponents/customIcons/CreateShowcaseIcon";
// import ClearIcon from "../../sharedComponents/customIcons/ClearIcon";

import Avatar from "@material-ui/core/Avatar";
import Loader from "../../sharedComponents/Loader/Loader";
import LocalBarOutlinedIcon from '@material-ui/icons/LocalBarOutlined';
import FilterIcon from '../../sharedComponents/customIcons/FilterIcon';
import RouteConstants from "../../common/RouteConstants";
import SmartSearch from "../../sharedComponents/smartSearch/SmartSearch";
import AppConstants from "../../common/AppConstants";
import CardContainer from "../dashbord/CardContainer";
import ListContainer from "../dashbord/ListContainer";
import { jwplayerService } from "../../services";
import FrontOfficeService from "../../services/frontOffice.service";
import { userService, coachProfileService } from "../../services";
import ProfileDialogs from "../../sharedComponents/dialog/ProfileDialog";
import { AppDataContext } from "../../common/AppContext";
import { FilterProfileList } from "../../common/filterHelper";
import CreateGroupDialog from "../../sharedComponents/dialog/CreateGroupDialog";
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import ClearIcon from "@material-ui/icons/Clear";
import DiscardGroupIcon from "../../sharedComponents/customIcons/DiscardGroup";
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

import {
  DashBoardProvider,
  DashBoardConsumer,
  DashBoardContext,
} from "../../common/DashBoardContext";

import { FilterContext } from "../../common/FilterContext";
import Pagination from "../../sharedComponents/Pagination/Pagination";
import ProfileList from "../../sharedComponents/ProfileCard/ProfileList";
import ProfileCommonFunction from "../../sharedComponents/ProfileCard/ProfileCommonFunction";
import RejectDialog from "../../sharedComponents/dialog/RejectDialog";
import AdminService from "../../services/admin.service";
import AdminCoachesAppBar from "./AdminCoachesAppBar";
import AddShowcaseParticipantsDialog from "./AddShowcaseParticipantsDialog";
import AdminCoachesTabPanel from "./AdminCoachesTabPanel";
import FrontOfficeCoachesAppBar from "./FrontOfficeCoachesAppBar";
import FrontOfficeCoachesTabPanel from "./FrontOfficeCoachesTabPanel";

import arrow_toggle_transparent from "../../images/arrow_toggle_transparent.png";
import { Role } from "../../helper";
import SnackbarMsg from "../../sharedComponents/Snackbar/Snackbar";
import adminService from "../../services/admin.service";
import Helper from "../../common/Helper";
import useTelemetry from "../../utils/useTelemetry";

const drawerWidth = 260;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    zIndex: theme.zIndex.appBar - 1,
    width: drawerWidth,
    background: "transparent",
    borderRight: "none",
  },
  drawerContainer: {
    background: "#06255B",
    //    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(0),
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(0.5),
    boxShadow: "0px 0px 5px 3px rgba(0,0,0,0.1)",
    overflow: "auto",
    height: "100%",
    "&::-webkit-scrollbar": {
      width: "5px",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#bdbdbd",
      outline: "1px solid slategrey",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(1),
    },
  },
  content: {
    width: "100%",
    margin: theme.spacing(2),
    boxShadow: "0px 0px 5px 3px rgba(0,0,0,0.1)",
    backgroundColor: '#ffffff',
    zIndex: '2',
    [theme.breakpoints.down("md")]: {
      width: "calc(100%  - 292px)",
    },
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(5, 0, 2, 0),
      width: "100%",
    },
  },
  tabsBar: {
    justifyContent: 'space-evenly'
  },
  tab: {
    fontSize: theme.typography.pxToRem(12),
  },
  toolbar: {
    [theme.breakpoints.down("xs")]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  marginLeft: {
    marginLeft: '10px'
  },
  sorting: {
    '& > div': {
      marginLeft: theme.spacing(1),
      '& > svg': {
        color: '#ffffff',
      },
      '& > div': {
        backgroundColor: '#1d428a !important',
        borderRadius: '12px !important',
        color: '#ffffff',
        padding: '8px 12px !important'



      },
    }
  },
  Pagination: {
    '& > div': {
      marginLeft: theme.spacing(1),
      [theme.breakpoints.down("xs")]: {
        marginLeft: theme.spacing(0),
      },
      '& > svg': {
        color: '#ffffff',
      },
      '& > div': {
        backgroundColor: '#1d428a !important',
        borderRadius: '12px !important',
        color: '#ffffff',
        padding: '8px 12px !important'
      },
    },
  },
  sort: {
    display: "flex",
    alignItems: "center",
    width: "100%",
  },
  sortBy: {
    [theme.breakpoints.up("sm")]: {
      margin: theme.spacing(1),
      width: "25ch",
    },
    [theme.breakpoints.down("xs")]: {
      margin: theme.spacing(1),
      width: "18ch",
    },
  },
  viewAs: {
    minWidth: "fit-content",
    marginRight: theme.spacing(1),
  },
  tabsColor: {
    '& button': {
      color: '#ffffff !important',
    },
  },
  iconAvatar2: {
    width: theme.spacing(1.75),
    height: theme.spacing(2.25),
  },
  iconAvatar: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    //border: "2px solid #e8e8e8",
  },
  filterIcon: {
    [theme.breakpoints.down("sm")]: {
      top: '68px',
      left: '0',
    },
    [theme.breakpoints.down("xs")]: {
      top: '60px',
      left: '0',
    },
    position: 'absolute',
    padding: theme.spacing(0),
    zIndex: '1',
  },
  searchNote: {
    display: 'flex',
    width: '60%',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginLeft: '1rem',
    border: '1px dotted #17408B',
    padding: '2px',
    paddingTop: '10px',
    paddingBottom: '10px',
    backgroundColor: '#f0f7fb',
    backgroundPosition: '9px 0px',
    backgroundRepeat: 'no-repeat',
    borderLeft: 'solid 4px orange',
    lineHeight: '18px',
    overflow: 'hidden'
  },
  searchInfoIcon: {
    color: '#17408B',
    padding: '0 2px 0 2px'
  },
  searchHelpText: {
    paddingLeft: '2px',
    fontStyle: 'italic'
  },
  iconGroup: {
    borderRight: '1px solid #999',
    '& .MuiIconButton-root': {
      color: '#000000',
    },
    '& .MuiIconButton-root.MuiIconButton-colorSecondary': {
      color: '#c8102e',
    },
  }
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scroll-tabpanel-${index}`}
      aria-labelledby={`scroll-tab-${index}`}
      {...other}
    >
      {value === index && <div p={3}>{children}</div>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scroll-tab-${index}`,
    "aria-controls": `scroll-tabpanel-${index}`,
  };
}

const CoachesManagementContainer = (props) => {
  const classes = useStyles();
  const theme = useTheme();

  const [view, setView] = useState("card");
  const [role, setRole] = useState("");
  const [isFilter, setIsFilter] = useState(false);
  const [foTabs, setFOTabs] = useState([]);
  const { trackCustomEvents } = useTelemetry();

  useEffect(() => {
    getFrontOfficeTabs()
  }, [])

  async function getFrontOfficeTabs() {
    let tabs = await adminService.getFrontOfficeTabs();
    setFOTabs(tabs);
  }

  useEffect(() => {
    setRole(userService.getUserRole());
  }, [])

  const {
    profileList,
    totalRecords,
    setTotalRecords,
    jwtTokenDetails,
    updateBookmarkedUser,
    error,
    isProcessing,
    updateStatusProfile,
    currentPageForUpdate,
    randomKey,
    updateCurrentPage,
    handleServiceCall,
    rules
  } = useContext(DashBoardContext);

  const {
    searchedTextContext,
    selectedTitleContext,
    selectedLeagueContext,
    selectedChampionshipContext,
    selectedSkillContext,
    selectSortContext,
    sortOrderContext,
    filterViewContext,
    selectedTeamsContext,
    selectedNBATeamsContext,
    selectedWNBATeamsContext,
    selectedNBAGLeagueTeamsContext,
    selectedCurrentlyWithATeamContext,
    selectedFormerCoachesContext,
    titlesearchedTextContext,
    leaguesearchedTextContext,
    championshipsearchedTextContext,
    skillsearchedTextContext,
    currentlyWithATeamsearchedTextContext,
    formerCoachessearchedTextContext,
    teamsearchedTextContext,
    nbaTeamsearchedTextContext,
    wnbaTeamsearchedTextContext,
    nbaGLeagueTeamsearchedTextContext,
    filterCriteriaContext,
    internationalContext,
    selectedExpTypeContext,
    pageSizeContext,
    currentPageContext,
    tabIndexContext,
    updateAllSearchedState, updateGroup, userGroup, selectedPExpereienceContext, pExpereienceTextContext } = useContext(FilterContext);



  const { teamList, getTeamsByLeague, getLeagueTextByValue, positionTitle, coachTitle, skills, league } = useContext(AppDataContext);
  const [nbaTeamsList, setNbaTeamList] = useState([]);
  const [WnbaTeamsList, setWnbaTeamList] = useState([]);
  const [nbaGLeagueTeamsList, setNbaGLeagueTeamList] = useState([]);




  useEffect(() => {
    let list = [];
    getTeamsByLeague("nba").forEach(element => {
      list.push({ Id: element.teamId, Name: element.teamName })
    });
    setNbaTeamList(list);
    list = [];
    getTeamsByLeague("wnba").forEach(element => {
      list.push({ Id: element.teamId, Name: element.teamName })
    });
    setWnbaTeamList(list);
    list = [];
    getTeamsByLeague("nbagleague").forEach(element => {
      list.push({ Id: element.teamId, Name: element.teamName })
    });
    setNbaGLeagueTeamList(list);

  }, [teamList]);

  const [positionTitleList, setPositionTitleList] = useState([]);
  useEffect(() => {
    let list = [{ Id: "4", Name: "Head Coach" }
      , { Id: "1", Name: "Assistant Coach" }
      , { Id: "6", Name: "Other" }
      , { Id: "2", Name: "Associate Head Coach" }
      , { Id: "3", Name: "Front of the Bench Assistant Coach" }
      , { Id: "5", Name: "Player Development Coach" }]


    list = ProfileCommonFunction.sortById(list, "Id");
    setPositionTitleList(list);
  }, [positionTitle, coachTitle])

  const [tabIndex, setTabIndex] = useState(tabIndexContext);
  const [selectedSkill, setSelectedSkill] = useState(selectedSkillContext);
  const [selectedCurrentlyWithATeam, setSelectedCurrentlyWithATeam] = useState(selectedCurrentlyWithATeamContext);
  const [selectedFormerCoach, setSelectedFormerCoach] = useState(selectedFormerCoachesContext);
  const [selectedExpType, setSelectedExpType] = useState(selectedExpTypeContext);
  const [selectedTeams, setSelectedTeams] = useState(selectedTeamsContext);
  const [selectedNBATeams, setSelectedNBATeams] = useState(selectedNBATeamsContext);
  const [selectedWNBATeams, setSelectedWNBATeams] = useState(selectedWNBATeamsContext);
  const [selectedNBAGLeagueTeams, setSelectedNBAGLeagueTeams] = useState(selectedNBAGLeagueTeamsContext);
  const [selectedLeague, setSelectedLeague] = useState(selectedLeagueContext);
  const [selectedChampionship, setSelectedChampionship] = useState(selectedChampionshipContext);
  const [selectedTitle, setSelectedTitle] = useState(selectedTitleContext);
  const [searchedText, setSearchedText] = useState(searchedTextContext);
  const [titlesearchedText, settTitleSearchedText] = useState(titlesearchedTextContext);
  const [leaguesearchedText, setLeagueSearchedText] = useState(leaguesearchedTextContext);
  const [championshipsearchedText, setChampionshipSearchedText] = useState(championshipsearchedTextContext);
  const [skillsearchedText, setSkillSearchedText] = useState(skillsearchedTextContext);
  const [currentlyWithATeamsearchedText, setCurrentlyWithATeamText] = useState(currentlyWithATeamsearchedTextContext);
  const [formerCoachessearchedText, setFormerCoachesText] = useState(formerCoachessearchedTextContext);
  const [internationalWorked, setInternationalWorked] = useState(internationalContext);
  const [teamsearchedText, setTeamSearchedText] = useState(teamsearchedTextContext);
  const [nbaTeamsearchedText, setNbaTeamsearchedText] = useState(nbaTeamsearchedTextContext);
  const [wnbaTeamsearchedText, setWnbaTeamsearchedText] = useState(wnbaTeamsearchedTextContext);
  const [nbaGLeagueTeamsearchedText, setNbaGLeagueTeamsearchedText] = useState(nbaGLeagueTeamsearchedTextContext);
  const [isLoading, setIsLoading] = React.useState(true);
  const currentUserName = userService.getUsername();
  const [filteredProfileList, setFilterProfile] = useState([]);
  const [filterView, setFilterView] = useState(filterViewContext);
  //const [profileList, setProfileList] = useState([]);
  const [selectSort, setSelectSort] = useState(selectSortContext);
  const [sortOrder, setSortOrder] = useState(sortOrderContext);
  const [currentPage, setCurrentPage] = useState(currentPageContext);
  const [randomKeyForUpdate, setRandomKeyForUpdate] = useState(randomKey);
  const [filterCriteria, setFilterCriteria] = useState(filterCriteriaContext);
  const [pageSize, setPageSize] = useState(pageSizeContext);
  const [selectedProfiles, setSelectedProfiles] = useState([]);
  const [showGroupButton, setShowGroupButton] = useState(false);
  const [showShowcase, setShowShowcase] = useState(false);
  const [showDownload, setShowDownload] = useState(false);

  const [showGroupDialog, setShowGroupDialog] = useState(false);
  const [showError, setShowError] = useState(false);
  const [severity, setSeverity] = useState("");
  const [message, setMessage] = useState("");
  const [clearSearch, setClearSearch] = useState(false);
  const [openParticipantDialog, toggleParticipantDialog] = useState(false);
  const [allGroups, setAllGroups] = useState([]);
  const [action, setAction] = useState();
  const [group, setGroup] = useState(userGroup);
  const [showGroupTab, setShowGroupTab] = useState(false);
  const [groupUsers, setGroupUsers] = useState([]);
  const [isGroupProfile, setIsGroupProfile] = useState(false);
  const [groupPageNo, setGroupPageNo] = useState(1);
  const [groupPageSize, setGroupPageSize] = useState(12);
  const [totalRecordGroup, setTotalRecordGroup] = useState(12);
  //selectedPExpereienceContext, pExpereienceTextContext
  const [selectedPExpereience, setSelectedPExpereience] = useState(selectedPExpereienceContext);
  const [pExperiencesearchedText, setPExperiencesearchedText] = useState(pExpereienceTextContext);
  useEffect(() => {
    setIsLoading(true);
    setRandomKeyForUpdate(randomKey);
    if (userService.getUserRole() == Role.Admin)
      getAllGroups();
  }, [randomKey]);



  const getAllGroups = () => {
    adminService.getAllGroups()
      .then(result => {
        if (result && result.length > 0) {
          var lstGrp = result;
          lstGrp = result.filter((g) => g.isFrontOfficeGroup && g.isFrontOfficeGroup == 1)
          setAllGroups(lstGrp);
        }
        setLoading(true);
      }).catch((err) => {
      });
  }
  useEffect(() => {
    updateGroup(undefined);
    return;
  })
  useEffect(() => {
    if (!isProcessing) {
      setIsLoading(false);
    }
  }, [randomKeyForUpdate]);

  // useEffect(()=>{
  //   updateCurrentPage(currentPageContext);
  // },[currentPageContext])


  useEffect(() => {

    updateAllSearchedState(searchedText,
      selectedTitle,
      selectedLeague,
      selectedChampionship,
      selectedSkill,
      selectSort,
      sortOrder,
      filterView,
      selectedTeams,
      selectedNBATeams,
      selectedWNBATeams,
      selectedNBAGLeagueTeams,
      selectedCurrentlyWithATeam,
      selectedFormerCoach,
      titlesearchedText,
      leaguesearchedText,
      championshipsearchedText,
      skillsearchedText,
      currentlyWithATeamsearchedText,
      formerCoachessearchedText,
      teamsearchedText,
      nbaTeamsearchedText,
      wnbaTeamsearchedText,
      nbaGLeagueTeamsearchedText,
      filterCriteria,
      pageSize, currentPage, tabIndex, internationalWorked, selectedExpType, selectedPExpereience, pExperiencesearchedText)

    // if(searchedText || selectedTitle.length > 0 ||
    //   selectedLeague.length > 0 ||
    //   selectedSkill.length > 0 ||
    //   selectedChampionship.length > 0 ||
    //   selectedTeams.length > 0 || selectedNBATeams.length > 0 ||
    //   selectedWNBATeams.length > 0 || selectedNBAGLeagueTeams.length > 0 || selectedCurrentlyWithATeam.length > 0 || 
    //   internationalWorked == true || (selectedExpType && selectedExpType.length > 0))
    //   {
    //     setShowGroupButton(true);
    //   }

  }, [searchedText,
    selectedTitle,
    selectedLeague,
    selectedChampionship,
    selectedSkill,
    selectSort,
    sortOrder,
    filterView,
    selectedTeams,
    selectedNBATeams,
    selectedWNBATeams,
    selectedNBAGLeagueTeams,
    selectedCurrentlyWithATeam,
    selectedFormerCoach,
    titlesearchedText,
    leaguesearchedText,
    championshipsearchedText,
    skillsearchedText,
    currentlyWithATeamsearchedText,
    formerCoachessearchedText,
    teamsearchedText,
    nbaTeamsearchedText,
    wnbaTeamsearchedText,
    nbaGLeagueTeamsearchedText,
    filterCriteria,
    pageSize, currentPage, tabIndex, internationalWorked, selectedExpType, selectedPExpereience, pExperiencesearchedText])



  useEffect(() => {
    if (!isProcessing) {
      //hack to show loader till details are fetched..
      setIsLoading(false);
      setFilterProfile(profileList);
      //  handleFilterSearchClick();
    }
  }, [profileList, isProcessing]);

  useEffect(() => {
    if (error && error !== "") {
      setIsLoading(false);
      //console.log(error);
    }
  }, [error]);

  const handleProfileChecked = (e, userId) => {
    let selected = [...selectedProfiles];
    if (e.target.checked) selected.push(userId);
    else {
      let index = selected.indexOf(userId);
      selected.splice(index, 1);
    }
    setSelectedProfiles(selected);
    let currentRole = userService.getUserRole();
    if (currentRole == Role.Admin) {
      if (selected.length > 0 && group == undefined) {
        setShowShowcase(true);
        setShowGroupButton(true);
        setShowDownload(true);
      }
      else if (selected.length > 0 && group) {
        setShowShowcase(false);
        setShowGroupButton(true);
        setShowDownload(false);
      }
      else if ((selected.length == 0 && isFilter == true))
        setShowGroupButton(true);
      else {
        setShowShowcase(false);
        setShowGroupButton(false);
        setShowDownload(false);
      }
    }
    else {
      if (selected.length > 0) {
        setShowDownload(true);
      }
    }
  }
  const handleChecked = (e, name, item, seachText) => {
    //setCurrentPage(1);
    //setFilterProfile([]);
    // setIsLoading(true);
    let selected = [];
    if (name === "championship") {
      setChampionshipSearchedText(seachText);
      selected = [...selectedChampionship];
      if (e.target.checked) selected.push(item);
      else {
        let index = selected.indexOf(item);
        selected.splice(index, 1);
      }
      setSelectedChampionship([...selected]);
    }
    if (name === "league") {
      setLeagueSearchedText(seachText);
      selected = [...selectedLeague];
      if (e.target.checked) selected.push(item);
      else {
        let index = selected.indexOf(item);
        selected.splice(index, 1);
      }
      setSelectedLeague([...selected]);
    }
    if (name === "team") {
      setTeamSearchedText(seachText)
      selected = [...selectedTeams];
      if (e.target.checked) selected.push(item.Name);
      else {
        let index = selected.indexOf(item.Name);
        selected.splice(index, 1);
      }
      setSelectedTeams([...selected]);
    }
    if (name === "nbaTeams") {
      setNbaTeamsearchedText(seachText)
      selected = [...selectedNBATeams];
      if (e.target.checked) selected.push(item.Name);
      else {
        let index = selected.indexOf(item.Name);
        selected.splice(index, 1);
      }
      setSelectedNBATeams([...selected]);
    }
    if (name === "wnbaTeams") {
      setWnbaTeamsearchedText(seachText)
      selected = [...selectedWNBATeams];
      if (e.target.checked) selected.push(item.Name);
      else {
        let index = selected.indexOf(item.Name);
        selected.splice(index, 1);
      }
      setSelectedWNBATeams([...selected]);
    }
    if (name === "nbaGLeagueTeams") {
      setNbaGLeagueTeamsearchedText(seachText)
      selected = [...selectedNBAGLeagueTeams];
      if (e.target.checked) selected.push(item.Name);
      else {
        let index = selected.indexOf(item.Name);
        selected.splice(index, 1);
      }
      setSelectedNBAGLeagueTeams([...selected]);
    }
    if (name === "positionTitle") {
      settTitleSearchedText(seachText)
      selected = [...selectedTitle];
      if (e.target.checked) {
        selected.push(item.Name);
      } else {
        let index = selected.indexOf(item.Name);
        selected.splice(index, 1);
      }
      setSelectedTitle([...selected]);
    }
    if (name === "skill") {
      setSkillSearchedText(seachText)
      selected = [...selectedSkill];
      if (e.target.checked) selected.push(item.Name);
      else {
        let index = selected.indexOf(item.Name);
        selected.splice(index, 1);
      }
      //console.log(selected)
      setSelectedSkill([...selected]);
    }
    if (name === "currentWork") {
      setCurrentlyWithATeamText(seachText)
      selected = [...selectedCurrentlyWithATeam];
      if (e.target.checked) {
        selected.push(item.Name);
      }
      else {
        let index = selected.indexOf(item.Name);
        selected.splice(index, 1);
      }
      setSelectedCurrentlyWithATeam([...selected]);
    }
    if (name === "formerCoaches") {
      setFormerCoachesText(seachText)
      selected = [...selectedFormerCoach];
      if (e.target.checked) {
        selected.push(item.Name);
      }
      else {
        let index = selected.indexOf(item.Name);
        selected.splice(index, 1);
      }
      setSelectedFormerCoach([...selected]);
    }
    // selected = [...selectedCurrentlyWithATeam];
    // if (e.target.checked) selected.push(item.Name);
    // else {
    //   let index = selected.indexOf(item.Name);
    //   selected.splice(index, 1);
    // }
    // setSelectedCurrentlyWithATeam([...selected]);

    if (name === "expType") {
      selected = [...selectedExpType];
      if (e.target.checked) selected.push(item.Name);
      else {
        let index = selected.indexOf(item.Name);
        selected.splice(index, 1);
      }
      setSelectedExpType([...selected]);
    }
    if (name === "international") {
      selected = internationalWorked;
      if (e.target.checked) setInternationalWorked(true);
      else {
        setInternationalWorked(false);
      }
    }

    if (name === "playingExperience") {
      setPExperiencesearchedText(seachText)
      selected = selectedPExpereience;
      if (e.target.checked) selected.push(item.Name);
      else {
        let index = selected.indexOf(item.Name);
        selected.splice(index, 1);
      }
      setSelectedPExpereience([...selected]);
    }
  };

  useEffect(() => {
    let expType = [];
    if (selectedCurrentlyWithATeam && selectedCurrentlyWithATeam.length > 0) {
      expType.push("Current")
    }
    if (selectedFormerCoach && selectedFormerCoach.length > 0) {
      expType.push("Past")
    }
    setSelectedExpType(expType);
  }, [selectedFormerCoach, selectedCurrentlyWithATeam])

  const handleChipDelete = (name, item) => {
    let selected = [];
    if (name === "championship") {
      selected = [...selectedChampionship];
      let index = selected.indexOf(item);
      selected.splice(index, 1);
      setSelectedChampionship([...selected]);
    }
    if (name === "league") {
      selected = [...selectedLeague];
      let index = selected.indexOf(item);
      selected.splice(index, 1);
      setSelectedLeague([...selected]);
    }
    if (name === "positionTitle") {
      selected = [...selectedTitle];
      let index = selected.indexOf(item);
      selected.splice(index, 1);
      setSelectedTitle([...selected]);
    }
    if (name === "skill") {
      selected = [...selectedSkill];
      let index = selected.indexOf(item);
      selected.splice(index, 1);
      setSelectedSkill([...selected]);
    }
    if (name === "currentWork") {
      selected = [...selectedCurrentlyWithATeam];
      let index = selected.indexOf(item);
      selected.splice(index, 1);
      setSelectedCurrentlyWithATeam([...selected]);
    }
    if (name === "formerCoaches") {
      selected = [...selectedFormerCoach];
      let index = selected.indexOf(item);
      selected.splice(index, 1);
      setSelectedFormerCoach([...selected]);
    }
    if (name === "expType") {
      selected = [...selectedExpType];
      let index = selected.indexOf(item);
      selected.splice(index, 1);
      setSelectedExpType([...selected]);
    }
    if (name === "teams") {
      selected = [...selectedTeams];
      let index = selected.indexOf(item);
      selected.splice(index, 1);
      setSelectedTeams([...selected]);

    }
    if (name === "nbaTeams") {
      selected = [...selectedNBATeams];
      let index = selected.indexOf(item);
      selected.splice(index, 1);
      setSelectedNBATeams([...selected]);
    }
    if (name === "wnbaTeams") {
      selected = [...selectedWNBATeams];
      let index = selected.indexOf(item);
      selected.splice(index, 1);
      setSelectedWNBATeams([...selected]);
    }
    if (name === "nbaGLeagueTeams") {
      selected = [...selectedNBAGLeagueTeams];
      let index = selected.indexOf(item);
      selected.splice(index, 1);
      setSelectedNBAGLeagueTeams([...selected]);
    }
    if (name === "international") {
      setInternationalWorked(false);
    }

    if (name === "playingExperience") {
      selected = selectedPExpereience;
      let index = selected.indexOf(item.Name);
      selected.splice(index, 1);
      setSelectedPExpereience([...selected]);
    }
  };

  const handleClearAllFilter = () => {
    setCurrentPage(1);
    setFilterProfile([]);
    //setSelectedProfiles([]);
    setIsLoading(true);
    setSearchedText("");
    setSelectedChampionship([]);
    setSelectedLeague([]);
    setSelectedTeams([]);
    setSelectedNBATeams([]);
    setSelectedWNBATeams([]);
    setSelectedNBAGLeagueTeams([]);
    setSelectedTitle([]);
    setSelectedSkill([]);
    setSelectedCurrentlyWithATeam([]);
    setSelectedFormerCoach([]);
    settTitleSearchedText("");
    setLeagueSearchedText("");
    setSkillSearchedText("");
    setChampionshipSearchedText("");
    setTeamSearchedText("");
    setNbaTeamsearchedText("");
    setWnbaTeamsearchedText("");
    setNbaGLeagueTeamsearchedText("");
    setCurrentlyWithATeamText("");
    setFormerCoachesText("");
    setSelectedExpType([]);
    setInternationalWorked(false);
    setPExperiencesearchedText("");
    setSelectedPExpereience([]);
    //setShowGroupButton(false);
    clearAllFilter();
  };

  const onWithinFilterSearchedTextchange = (name, text) => {
    if (name === "positionTitle")
      settTitleSearchedText(text);
    if (name === "league")
      setLeagueSearchedText(text);
    if (name === "skill")
      setSkillSearchedText(text);
    if (name === "currentWork")
      setCurrentlyWithATeamText(text);
    if (name === "formerCoaches")
      setFormerCoachesText(text);
    if (name === "championship")
      setChampionshipSearchedText(text);
    if (name === "team")
      setTeamSearchedText(text);
    if (name === "nbaTeams")
      setNbaTeamsearchedText(text);
    if (name === "wnbaTeams")
      setWnbaTeamsearchedText(text);
    if (name === "nbaGLeagueTeams")
      setNbaGLeagueTeamsearchedText(text);

  }

  const handleChangeAdmin = (event, newValue) => {
    setCurrentPage(1);
    setTabIndex(newValue);
    setIsGroupProfile(false);
    //setFilterProfile([]);
    //  setIsLoading(true);
    if (newValue == 0) {
      setFilterView(AppConstants.DASHBOARD_SECTION_ID.All);
      trackCustomEvents("ClickEvent", { event: "All Profiles" });
    }

    if (newValue == 1) {
      setFilterView(AppConstants.DASHBOARD_SECTION_ID.RecentlyUpdated);
      trackCustomEvents("ClickEvent", { event: "Resubmitted" });
    }

    if (newValue == 2) {
      setFilterView(AppConstants.DASHBOARD_SECTION_ID.RecentlyAdded);
      trackCustomEvents("ClickEvent", { event: "Recently Added" });
    }

    if (newValue == 3) {
      setFilterView(AppConstants.DASHBOARD_SECTION_ID.Pending);
      trackCustomEvents("ClickEvent", { event: "Pending Approvals" });
    }

    if (newValue == 4) {
      setFilterView(AppConstants.DASHBOARD_SECTION_ID.Approved);
      trackCustomEvents("ClickEvent", { event: "Approved Profiles" });
    }

    if (newValue == 5) {
      setFilterView(AppConstants.DASHBOARD_SECTION_ID.Rejected)
      trackCustomEvents("ClickEvent", { event: "On Hold Profiles" });
    }

    if (newValue == 6) {
      setIsGroupProfile(true);
      setFilterView(AppConstants.DASHBOARD_SECTION_ID.GroupProfile)
      trackCustomEvents("ClickEvent", { event: "Group Profile" });
    }
    //handleSearchClick();
  };

  const handleChange = (event, newValue) => {
    setIsGroupProfile(false);
    setGroupPageNo(1);
    setCurrentPage(1);
    setTabIndex(newValue);
    //setFilterProfile([]);
    //setIsLoading(true);
    if (newValue == 0) {
      setFilterView(AppConstants.DASHBOARD_SECTION_ID.All);
      trackCustomEvents("ClickEvent", { event: "All Profiles" });
    }
    else if (newValue == 1) {
      setFilterView(AppConstants.DASHBOARD_SECTION_ID.Bookmarked);
      trackCustomEvents("ClickEvent", { event: "Bookmarked" });
    }

    else if (newValue == 2) {
      setFilterView(AppConstants.DASHBOARD_SECTION_ID.RecentlyAdded);
      trackCustomEvents("ClickEvent", { event: "Recently Added" });
    }

    else if (newValue > 2) {
      let groupData = foTabs[newValue - 3]
      setGroup(groupData)
      setIsGroupProfile(true);
      ////
    }
  };


  useEffect(() => {
    setIsLoading(true);
    handleServiceCall(
      searchedText,
      selectedTitle,
      selectedLeague,
      selectedChampionship,
      selectedSkill,
      selectSort,
      sortOrder,
      filterView,
      selectedNBATeams,
      selectedWNBATeams,
      selectedNBAGLeagueTeams,
      pageSize,
      currentPage,
      filterCriteria,
      selectedCurrentlyWithATeam,
      selectedFormerCoach,
      internationalWorked,
      selectedExpType,
      selectedPExpereience
    )
    //handleFilterSearchClick();
  }, [
    filterViewContext,
    //selectedTitleContext,
    //selectedLeagueContext,
    //selectedChampionshipContext,
    //selectedSkillContext,
    selectSortContext,
    sortOrderContext,
    //selectedNBATeamsContext,
    //selectedWNBATeamsContext,
    //selectedNBAGLeagueTeamsContext,
    pageSizeContext,
    currentPage,
    filterCriteria,
    // selectedCurrentlyWithATeam,
    // internationalContext
  ]);

  const onPageNoChange = (pageNo) => {
    setCurrentPage(pageNo);
  }

  const onGroupTabPageNoChange = (pageNo) => {
    setGroupPageNo(pageNo);
  }

  const handleChangeIndex = (index) => {
    setTabIndex(index);
    setCurrentPage(1);
    setFilterView(index);
  };

  function isXsDown() {
    return useMediaQuery(theme.breakpoints.down("xs"));
  }

  useEffect(() => {
    if (clearSearch) {
      handleSearchClick();
      setIsFilter(false);
      //setShowGroupButton(false);
    }
  }, [clearSearch]);

  const handleBookMarked = (mode, userName) => {
    let postData = { profileUserName: userName, bookmark: mode };
    setIsLoading(true);
    FrontOfficeService.setBookmarkProfile(postData).then((data) => {
      //setFilterProfile([]);
      updateBookmarkedUser(data, currentPage);
      if (groupUsers && groupUsers.length > 0) {
        handleUpdateBookmarkedForGroup(data);
      }
      //setFilterProfile([]);
      // setIsLoading(true);
    });
  };

  const handleUpdateBookmarkedForGroup = (data) => {
    let profile = groupUsers.filter((profile) => {
      if (profile.userName === data.profileUserName) {
        if (data.bookmark) {
          let newData = [];
          profile.isBookMarked = true;
          profile.bookmarkedBy !== null
            ? profile.bookmarkedBy.push(data.bookmarkedBy)
            : (profile.bookmarkedBy = Array.from([data.bookmarkedBy]));
        } else {
          profile.isBookMarked = false;
          profile.bookmarkedBy.splice(
            profile.bookmarkedBy.indexOf(data.bookmarkedBy),
            1
          );
        }
      }
      return profile;
    });
    setGroupUsers(profile);
  };


  const [openDialog, setOpenDialog] = useState({ open: false, data: {} });

  const handleOpenDialogAdmin = (profileObj, showApprove, showReject) => {
    setOpenDialog({ open: true, data: profileObj, showApprove: showApprove, showReject: showReject });
  };

  const handleOpenDialog = (profileObj) => {
    setOpenDialog({ open: true, data: profileObj });
  };

  const handleChangeSortBy = (e) => {
    setCurrentPage(1);
    setSelectSort(e.target.value);
    setFilterProfile([]);
    setIsLoading(true);
  };

  const handleSortOrder = () => {
    setCurrentPage(1);
    setFilterProfile([]);
    setIsLoading(true);
    if (sortOrder === "asc") {
      setSortOrder("desc");
    } else {
      setSortOrder("asc");
    }
  };

  const handleSearchClick = () => {
    setCurrentPage(1);
    setFilterProfile([]);
    setIsLoading(true);
    if (showGroupTab !== true)
      //setSelectedProfiles([]);
      setShowGroupButton(true);
    setIsFilter(true);
    setClearSearch(false);
    handleServiceCall(
      searchedText,
      selectedTitle,
      selectedLeague,
      selectedChampionship,
      selectedSkill,
      selectSort,
      sortOrder,
      filterView,
      selectedNBATeams,
      selectedWNBATeams,
      selectedNBAGLeagueTeams,
      pageSize,
      currentPage,
      filterCriteria,
      selectedCurrentlyWithATeam,
      selectedFormerCoach,
      internationalWorked,
      selectedExpType,
      selectedPExpereience
    )
  };


  const clearAllFilter = () => {
    setIsFilter(false);
    handleServiceCall(
      "",
      [],
      [],
      [],
      [],
      selectSort,
      sortOrder,
      filterView,
      [],
      [],
      [],
      pageSize,
      currentPage,
      filterCriteria,
      [],
      [],
      false,
      []
    )
  };
  const sortOptions = role !== Role.Admin ? ["None", "Name", "Team"] : ["Name", "Team"];

  // const defaultProps = {
  //   options: sortOptions,
  //   getOptionLabel: (option) => option,
  // };

  const pageSizeOption = ["12", "24", "48", "72"];

  // const defaultPropsPage = {
  //   options: pageSizeOption,
  //   getOptionLabel: (option) => option,
  // };
  const handleProfileStatusChange = (userName, status, note, sendMail) => {
    let postData = { status: status, profileUserName: userName, reason: note, sendEmail: sendMail };
    setIsLoading(true);
    AdminService.changeProfileStatus(postData).then((data) => {
      // setFilterProfile([]);
      //setIsLoading(true);
      updateStatusProfile(data, currentPage);
    });
  };

  const handleChangeFilterCriteria = (e) => {
    //handleClearAllFilter();
    setCurrentPage(1);
    setFilterCriteria(e.target.value);
    setFilterProfile([]);
    setIsLoading(true);
  }

  function handleApproveClick() {
    if (openDialog.showApprove) {
      handleProfileStatusChange(openDialog.data.userName, AppConstants.PROFILE_STATUS.APPROVED)
      setOpenDialog({ open: true, data: openDialog.data, showApprove: false, showReject: true })
    }
    else if (openDialog.data.status.toLowerCase() === AppConstants.PROFILE_STATUS.APPROVED) {
      setOpenRejectDialog(true);
    }
  }
  const [openRejectDialog, setOpenRejectDialog] = useState(false);

  function btnRejectClick(note, sendMail) {
    setOpenRejectDialog(false);
    handleProfileStatusChange(openDialog.data.userName, AppConstants.PROFILE_STATUS.DENIED, note, sendMail)
    setOpenDialog({ open: true, data: openDialog.data, showApprove: true, showReject: false })
  }

  const [openDrawer, setOpenDrawer] = useState(false);
  const handleOpenDrawer = () => {
    setOpenDrawer(true);
  }

  const handlePageSizeChange = (size) => {
    setPageSize(size);
    setCurrentPage(1);
    setFilterProfile([]);
    setIsLoading(true);
    setTabIndex(0);
    setFilterView(AppConstants.DASHBOARD_SECTION_ID.All);
  }

  const handleGroupPageSizeChange = (size) => {
    setGroupPageSize(size);
    setGroupPageNo(1);

  }

  function handleGroupClick() {
    setShowGroupDialog(true);
  }

  function handleShowcaseClick() {
    // if (selectedProfiles.length > AppConstants.MAX_PROFILE_ALLOWED_TO_SHOWCASE) {
    //   showAlert("error", "Please do not select more than " + AppConstants.MAX_PROFILE_ALLOWED_TO_SHOWCASE + " profiles to showcase");
    //   return false;
    // }
    // else {
    toggleParticipantDialog(true);
    setAction("Create");
    //}
  }

  function handleGroupClear() {
    if (!isFilter)
      setShowGroupButton(false);
    setSelectedProfiles([]);
  }

  async function handleDownLoadPDFClick() {
    //event.preventDefault();
    if (selectedProfiles && selectedProfiles.length > 0) {
      if (selectedProfiles.length > AppConstants.MAX_PROFILE_ALLOWED_TO_DOWNLOAD) {
        showAlert("error", "Please do not select more than " + AppConstants.MAX_PROFILE_ALLOWED_TO_DOWNLOAD + " profiles to download");
        return false;
      }

      setIsLoading(true);
      try {
        (async () => {
          await
            selectedProfiles.forEach((userName, ind) => {
              downloadPDF(userName, ind);

            });
        })();

      }
      catch {
        setIsLoading(false);
      }

      // var xhr = adminService.donwnloadMultiplePDF();
      // xhr.onload = function (e) {
      //   if (this.status == 200) {
      //     let fileName = "coachprofiles";
      //     var blob = new Blob([this.response], { type: "application/pdf" });
      //     var link = document.createElement("a");
      //     link.href = window.URL.createObjectURL(blob);
      //     link.download = fileName + ".pdf";
      //     link.click();
      //   }
      //   setIsLoading(false);
      // };
      // xhr.onerror = function (err) {
      //   console.log(err);
      //   setIsLoading(false);
      // }
      // xhr.send(JSON.stringify(selectedProfiles));
    }
  }

  async function downloadPDF(userName, ind) {
    var xhr = await coachProfileService.downloadPDF(userName);
    xhr.onload = function (e) {
      if (this.status == 200) {
        let fileName = userName;
        fileName = fileName.replace(/([^a-z0-9 _]+)/gi, '');
        var blob = new Blob([this.response], { type: "application/pdf" });
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName + ".pdf";
        link.click();
      }
      if (ind == selectedProfiles.length - 1) {
        setTimeout(() => {
          setIsLoading(false);
        }, 5000);
      }
    };
    xhr.send();

  }


  const handleCloseClick = (isSuccess) => {
    toggleParticipantDialog(false);
  }
  const handleDeleteProfile = (email) => {
    adminService.deleteProfile(email)
      .then((response) => {
        if (response) {
          showAlert("success", 'Profile deleted successfully');
          handleSearchClick();
        }
        else
          showAlert("error", 'Cannot delete this profile.');
      })
      .catch((err) => {
        console.log(err);
      });
  }
  const handleSaveShowcaseClick = (showcaseData) => {
    if (showcaseData) {
      showcaseData.users = selectedProfiles;
      adminService.saveShowcase(showcaseData)
        .then(data => {
          if (typeof data == "string") {
            if (data == "duplicate")
              showAlert("error", "Showcase already exists.");
            else if (data == "invaliddate")
              showAlert("error", "Showcase already exists for selected date.");
            else
              showAlert("error", data);
          }
          else {
            if (showcaseData.Id)
              showAlert("success", 'Showcase updated successfully.');
            else
              showAlert("success", 'Showcase created successfully.');
            toggleParticipantDialog(false);
          }
        })
        .catch((err) => {
          showAlert("error", 'Error occured while saving.');
        });
    }
  }

  useEffect(() => {
    if (group) {
      getGroupTabData();
      if (!isGroupProfile) {
        setTabIndex(6);
        setIsGroupProfile(true);
      }
    }
    else {
      setTabIndex(0);
      setFilterView(AppConstants.DASHBOARD_SECTION_ID.All);
    }
  }, [group, groupPageSize]);

  useEffect(() => {
    if (groupPageNo > 1)
      getGroupTabData();
  }, [groupPageNo])


  function getGroupTabData() {
    if (group.id) {
      setIsLoading(true);
      setGroupUsers([]);
      setFilterProfile([]);
      setTotalRecords(0);
      setTotalRecordGroup(0);
      adminService.getUsersByGroupIdForTab(group.id, groupPageSize, groupPageNo)
        .then((response) => {
          setIsLoading(false);
          if (response.data.length > 0) {
            var users = response.data;
            setGroupUsers(users);
            setTotalRecordGroup(response.totalResults);
            let currentRole = userService.getUserRole();
            if (currentRole == Role.Admin) {
              setSelectedProfiles(users.map((u) => u.userName));
            }
          }
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
        });
    }

    setShowGroupTab(true);
    if (role == Role.Admin) {
      setSelectedProfiles(group.users);
      setShowDownload(false);
    }
    setShowGroupButton(true);
    setShowShowcase(false);
    setFilterView(AppConstants.DASHBOARD_SECTION_ID.GroupProfile);
  }


  const handleDiscardGroup = () => {
    setSelectedProfiles([]);
    setIsGroupProfile(false);
    setShowGroupDialog(false);
    setShowGroupButton(false);
    updateGroup(undefined);
    setTabIndex(0);
    setFilterView(AppConstants.DASHBOARD_SECTION_ID.All);
    setGroup(null);
    setCurrentPage(1);
    setShowGroupTab(false);
  }

  function saveGroup(name, showAsTAB, description) {
    if (name && name.trim().length > 0) {
      let userGroup = group ? group : {};
      userGroup.name = name;
      userGroup.description = description && description != null && description.trim();
      userGroup.showAsTAB = showAsTAB;
      userGroup.type = group ? group.type : selectedProfiles.length > 0 ? AppConstants.Static : AppConstants.Dynamic;
      userGroup.rules = selectedProfiles.length > 0 ? null : JSON.stringify(rules),
        userGroup.users = selectedProfiles,
        userGroup.isActive = 1
      AdminService.saveGroup(userGroup).then((data) => {
        if (!data) {
          showAlert("error", "Group already exists.");
        }
        else if (typeof data == "string") {
          showAlert("error", data);
        }
        else {
          showAlert("success", 'Group ' + (group ? "updated" : "created") + ' successfully.');
          setGroup(undefined);
          updateGroup(undefined);
          setShowGroupButton(false);
          setShowGroupDialog(false);
          setSelectedProfiles([]);
          setTimeout(() => {
            props.redirectToGroupList();
          }, 800);
        }
      });
    }
    else
      showAlert("error", "Please enter Group Name.");
  }
  function showAlert(sever, msg) {
    setShowError(true);
    setSeverity(sever)
    setMessage(msg);

  }
  const handleAlertClose = () => {
    setShowError(false);
    setSeverity("")
    setMessage("");
  }
  return (
    <>
      {!isGroupProfile && <>
        <Hidden xsDown smDown>
          <Drawer
            className={classes.drawer}
            variant="permanent"
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            <Toolbar />
            <div className={classes.drawerContainer}>
              <SmartSearch
                handleDrawerClose={() => setOpenDrawer(false)}
                skills={skills}
                league={league}
                filterCriteria={filterCriteria}
                handleChangeFilterCriteria={handleChangeFilterCriteria}
                selectedSkill={selectedSkill}
                selectedCurrentlyWithATeam={selectedCurrentlyWithATeam}
                selectedFormerCoach={selectedFormerCoach}
                selectedExpType={selectedExpType}
                selectedChampionship={selectedChampionship}
                handleChecked={handleChecked}
                handleTextchange={() => setSearchedText(event.target.value)}
                handleClearText={() => {
                  setSearchedText("");
                  setClearSearch(true);
                }}
                selectedLeague={selectedLeague}
                selectedTitle={selectedTitle}
                searchedText={searchedText}
                onChange={() => {
                  handleSearchClick();
                }}
                titlesearchedText={titlesearchedText}
                leaguesearchedText={leaguesearchedText}
                teamsearchedText={teamsearchedText}
                skillsearchedText={skillsearchedText}
                currentlyWithATeamsearchedText={currentlyWithATeamsearchedText}
                formerCoachessearchedText={formerCoachessearchedText}
                championshipsearchedText={championshipsearchedText}
                positionTitle={positionTitleList}
                handleChipDelete={handleChipDelete}
                handleClearAllFilter={handleClearAllFilter}
                selectedTeams={selectedTeams}
                selectedNBATeams={selectedNBATeams}
                selectedWNBATeams={selectedWNBATeams}
                selectedNBAGLeagueTeams={selectedNBAGLeagueTeams}
                teamList={teamList}
                nbaTeams={nbaTeamsList}
                wnbaTeams={WnbaTeamsList}
                nbaGLeagueTeams={nbaGLeagueTeamsList}
                nbaTeamsearchedText={nbaTeamsearchedText}
                wnbaTeamsearchedText={wnbaTeamsearchedText}
                nbaGLeagueTeamsearchedText={nbaGLeagueTeamsearchedText}
                onWithinFilterSearchedTextchange={onWithinFilterSearchedTextchange}
                getLeagueTextByValue={getLeagueTextByValue}
                internationalWorked={internationalWorked}
                showAndOrFilter={true}
                handleFilterClick={() => {
                  handleSearchClick()
                }}
                selectedPExpereience={selectedPExpereience}
                pExperiencesearchedText={pExperiencesearchedText}
                handleLoading={() => setIsLoading(true)}
                searchObject={(searchObject) => {
                  setIsLoading(true);

                  //... Pass the search object to the below method.
                  handleServiceCall(
                    searchObject.searchText, //searchedText
                    searchObject.title, //selectedTitle
                    searchObject.league, //selectedLeague
                    searchObject.championship, //selectedChampionship
                    searchObject.skills, //selectedSkill
                    searchObject.sortBy, //selectSort
                    searchObject.sortOrder, //sortOrder
                    searchObject.filterP, //filterView
                    searchObject.nbaTeams, //selectedNBATeams
                    searchObject.wnbaTeams, //selectedWNBATeams
                    searchObject.nbaGLeagueTeams, //selectedNBAGLeagueTeams
                    searchObject.pageSize, //pageSize
                    searchObject.pageNo, //pageNo
                    searchObject.filterCriteria, //filterCriteria
                    searchObject.currentlyWithATeam, //selectedCurrentlyWithATeam
                    searchObject.formerCoaches, //selectedFormerCoach
                    searchObject.internationalWorked, //internationalWorked
                    searchObject.expType, //selectedExpType
                    searchObject.playingExperience, //selectedPExpereience
                  );

                  setSearchedText(searchObject.searchText);
                  setSelectedTitle(searchObject.title);
                  setSelectedLeague(searchObject.league);
                  setSelectedChampionship(searchObject.championship);
                  setSelectedSkill(searchObject.skills);
                  setSelectSort(searchObject.sortBy);
                  setSortOrder(searchObject.sortOrder);
                  setFilterView(searchObject.filterP);
                  setSelectedNBATeams(searchObject.nbaTeams);
                  setSelectedWNBATeams(searchObject.wnbaTeams);
                  setSelectedNBAGLeagueTeams(searchObject.nbaGLeagueTeams);
                  //setPageSize(searchObject.pageSize); //... Page size is set from the UI.
                  setFilterCriteria(searchObject.filterCriteria);
                  setSelectedCurrentlyWithATeam(searchObject.currentlyWithATeam);
                  setSelectedFormerCoach(searchObject.formerCoaches);
                  setInternationalWorked(searchObject.internationalWorked);
                  setSelectedExpType(searchObject.expType);
                  setSelectedPExpereience(searchObject.playingExperience);
                }}
              ></SmartSearch>
            </div>
          </Drawer>
        </Hidden>
        <Hidden mdUp>
          <IconButton className={classes.filterIcon} onClick={() => setOpenDrawer(!openDrawer)}><FilterIcon fontSize="large" color="primary" /></IconButton>
        </Hidden></>
      }
      <div className={classes.content}>
        {role == Role.Admin &&
          <AdminCoachesAppBar tabIndex={tabIndex} handleChangeAdmin={handleChangeAdmin} isXsDown={isXsDown} a11yProps={a11yProps} showUserPanel={showGroupTab} />}
        {(role == Role.FrontOffice || role == Role.FrontOfficePlusCoach) &&
          <FrontOfficeCoachesAppBar foTabs={foTabs} tabIndex={tabIndex} handleChange={handleChange} isXsDown={isXsDown} a11yProps={a11yProps} />}

        {!isGroupProfile &&
          <Hidden mdUp>
            <Drawer
              className={classes.drawer}
              variant="persistent"
              open={openDrawer}
              classes={{
                paper: classes.drawerPaper,
              }}
            >
              <Toolbar />
              <div className={classes.drawerContainer}>
                <SmartSearch
                  handleDrawerClose={() => setOpenDrawer(false)}
                  skills={skills}
                  league={league}
                  filterCriteria={filterCriteria}
                  handleChangeFilterCriteria={handleChangeFilterCriteria}
                  selectedSkill={selectedSkill}
                  selectedChampionship={selectedChampionship}
                  handleChecked={handleChecked}
                  handleTextchange={() => setSearchedText(event.target.value)}
                  handleClearText={() => {
                    setSearchedText("");
                    setClearSearch(true);
                  }}
                  selectedLeague={selectedLeague}
                  selectedTitle={selectedTitle}
                  searchedText={searchedText}
                  onChange={() => {
                    handleSearchClick();
                  }}
                  titlesearchedText={titlesearchedText}
                  leaguesearchedText={leaguesearchedText}
                  teamsearchedText={teamsearchedText}
                  skillsearchedText={skillsearchedText}
                  currentlyWithATeamsearchedText={currentlyWithATeamsearchedText}
                  selectedCurrentlyWithATeam={selectedCurrentlyWithATeam}
                  selectedFormerCoach={selectedFormerCoach}
                  championshipsearchedText={championshipsearchedText}
                  positionTitle={positionTitleList}
                  handleChipDelete={handleChipDelete}
                  handleClearAllFilter={handleClearAllFilter}
                  selectedTeams={selectedTeams}
                  selectedNBATeams={selectedNBATeams}
                  selectedWNBATeams={selectedWNBATeams}
                  selectedNBAGLeagueTeams={selectedNBAGLeagueTeams}
                  teamList={teamList}
                  nbaTeams={nbaTeamsList}
                  wnbaTeams={WnbaTeamsList}
                  nbaGLeagueTeams={nbaGLeagueTeamsList}
                  nbaTeamsearchedText={nbaTeamsearchedText}
                  wnbaTeamsearchedText={wnbaTeamsearchedText}
                  nbaGLeagueTeamsearchedText={nbaGLeagueTeamsearchedText}
                  onWithinFilterSearchedTextchange={onWithinFilterSearchedTextchange}
                  getLeagueTextByValue={getLeagueTextByValue}
                  internationalWorked={internationalWorked}
                  showAndOrFilter={true}
                  handleFilterClick={() => {
                    setShowGroupButton(true)
                    handleSearchClick()
                  }
                  }
                  selectedPExpereience={selectedPExpereience}
                  pExperiencesearchedText={pExperiencesearchedText}
                  searchObject={(searchObject) => {
                    setIsLoading(true);

                    //... Pass the search object to the below method.
                    handleServiceCall(
                      searchObject.searchText, //searchedText
                      searchObject.title, //selectedTitle
                      searchObject.league, //selectedLeague
                      searchObject.championship, //selectedChampionship
                      searchObject.skills, //selectedSkill
                      searchObject.sortBy, //selectSort
                      searchObject.sortOrder, //sortOrder
                      searchObject.filterP, //filterView
                      searchObject.nbaTeams, //selectedNBATeams
                      searchObject.wnbaTeams, //selectedWNBATeams
                      searchObject.nbaGLeagueTeams, //selectedNBAGLeagueTeams
                      searchObject.pageSize, //pageSize
                      searchObject.pageNo, //pageNo
                      searchObject.filterCriteria, //filterCriteria
                      searchObject.currentlyWithATeam, //selectedCurrentlyWithATeam
                      searchObject.formerCoaches, //selectedFormerCoach
                      searchObject.internationalWorked, //internationalWorked
                      searchObject.expType, // selectedExpType
                      searchObject.playingExperience, // selectedPExpereience
                    );

                    setSearchedText(searchObject.searchText);
                    setSelectedTitle(searchObject.title);
                    setSelectedLeague(searchObject.league);
                    setSelectedChampionship(searchObject.championship);
                    setSelectedSkill(searchObject.skills);
                    setSelectSort(searchObject.sortBy);
                    setSortOrder(searchObject.sortOrder);
                    setFilterView(searchObject.filterP);
                    setSelectedNBATeams(searchObject.nbaTeams);
                    setSelectedWNBATeams(searchObject.wnbaTeams);
                    setSelectedNBAGLeagueTeams(searchObject.nbaGLeagueTeams);
                    //setPageSize(searchObject.pageSize); //... Page size is set from the UI.
                    setFilterCriteria(searchObject.filterCriteria);
                    setSelectedCurrentlyWithATeam(searchObject.currentlyWithATeam);
                    setSelectedFormerCoach(searchObject.formerCoaches);
                    setInternationalWorked(searchObject.internationalWorked);
                    setSelectedExpType(searchObject.expType);
                    setSelectedPExpereience(searchObject.playingExperience);
                  }}
                ></SmartSearch>
              </div>
            </Drawer>
          </Hidden>
        }

        <div>
          <Toolbar className={classes.toolbar}>
            <div className={classes.sort}>
              {!isGroupProfile ?
                <>
                  <Hidden xsDown>
                    <label>Sort by</label>
                  </Hidden>
                  <TextField
                    id="outlined-select-currency"
                    select
                    // label="Sort"
                    value={selectSort}
                    onChange={(event) => handleChangeSortBy(event)}
                    variant="standard"
                    size="small"
                    style={{ width: "11ch" }}
                    className={classes.sorting}
                    InputProps={{
                      disableUnderline: true,
                    }}
                  >
                    {sortOptions.map((option) => (
                      <MenuItem key={option} value={option} className="MenuItem">
                        {option}
                      </MenuItem>
                    ))}
                  </TextField>
                  {selectSort !== 'None' &&
                    <IconButton
                      aria-label="card view"
                      color="primary"
                      title={sortOrder}
                      className="alphaSort"
                      onClick={handleSortOrder}
                    >
                      <>
                        <Typography variant="caption" size="small" style={{ fontWeight: "500", fontSize: ".93rem" }} >
                          A-Z &nbsp;
                        </Typography><Avatar variant="square" className={classes.iconAvatar2} src={arrow_toggle_transparent}></Avatar></>
                    </IconButton>
                  }
                  <Hidden xsDown >
                    <label className={classes.marginLeft}>Page Size</label>
                  </Hidden>
                  <TextField
                    id="outlined-select-currency"
                    select
                    // label="Page Size"
                    value={String(pageSize)}
                    onChange={(event) => handlePageSizeChange(parseInt(event.target.value))}
                    variant="standard"
                    size="small"
                    style={{ width: "10ch" }}
                    className={classes.Pagination}
                    InputProps={{
                      disableUnderline: true,
                    }}
                  >
                    {pageSizeOption.map((option) => (
                      <MenuItem key={option} value={option} className="MenuItem">
                        {option}
                      </MenuItem>
                    ))}
                  </TextField>

                </> :
                <>
                  <Hidden xsDown >
                    <label className={classes.marginLeft}>Page Size</label>
                  </Hidden>
                  <TextField
                    id="outlined-select-currency"
                    select
                    // label="Page Size"
                    value={String(groupPageSize)}
                    onChange={(event) => handleGroupPageSizeChange(parseInt(event.target.value))}
                    variant="standard"
                    size="small"
                    style={{ width: "10ch" }}
                    className={classes.Pagination}
                    InputProps={{
                      disableUnderline: true,
                    }}
                  >
                    {pageSizeOption.map((option) => (
                      <MenuItem key={option} value={option} className="MenuItem">
                        {option}
                      </MenuItem>
                    ))}
                  </TextField>
                </>


              }
              {searchedText &&
                <div className={classes.searchNote}>
                  <Hidden xsDown>
                    {/* <div><InfoOutlinedIcon className={classes.searchInfoIcon} /></div> */}
                    <div className={classes.searchHelpText}>
                      Note: Search results are returned in priority of match to coach name,
                      followed by match to the list of notable players coached.
                    </div>
                  </Hidden>
                </div>
              }
            </div>

            <Box component="div" display="flex" mr={1} className={classes.iconGroup}>
              <>
                {showDownload && selectedProfiles && selectedProfiles.length > 0 &&
                  <Tooltip title="Download Profiles">
                    <IconButton onClick={handleDownLoadPDFClick} aria-label="download">
                      <DownloadIcon />
                    </IconButton>
                  </Tooltip>
                }
                {(role == Role.Admin && ((selectedProfiles && selectedProfiles.length > 0) || showGroupButton)) &&
                  <Tooltip title={group ? "Update Group" : "Create Group"}>
                    <IconButton onClick={handleGroupClick} >
                      <CreateGroupIcon></CreateGroupIcon></IconButton>
                  </Tooltip>
                }
                {group && role == Role.Admin &&
                  <Tooltip title="Discard Group">
                    <IconButton onClick={handleDiscardGroup} color="secondary">
                      <DiscardGroupIcon></DiscardGroupIcon></IconButton>
                  </Tooltip>
                }
                {(role == Role.Admin && showShowcase && selectedProfiles && selectedProfiles.length > 0) &&
                  <Tooltip title="Create ShowCase">
                    <IconButton onClick={handleShowcaseClick}>
                      <CreateShowcaseIcon></CreateShowcaseIcon></IconButton>
                  </Tooltip>
                }
                {role == Role.Admin && selectedProfiles && selectedProfiles.length > 0 &&
                  <Tooltip title="Clear">
                    <IconButton onClick={handleGroupClear} color="secondary">
                      <ClearIcon></ClearIcon></IconButton>
                  </Tooltip>
                }
              </>
            </Box>
            <Hidden xsDown>
              <Typography variant="subtitle2" className={classes.viewAs}>
                View as
              </Typography>
            </Hidden>
            <Tooltip title="List view">
              <IconButton
                aria-label="card view"
                edge="end"
                color={view === "list" ? "primary" : "default"}
                onClick={() => {
                  setView("list");
                  trackCustomEvents("ClickEvent", { event: "List View" });
                }}
              >
                <ViewListIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Card view">
              <IconButton
                aria-label="card view"
                color={view === "card" ? "primary" : "default"}
                onClick={() => {
                  setView("card");
                  trackCustomEvents("ClickEvent", { event: "Card View" });
                }}
              >
                <ViewModuleIcon />
              </IconButton>
            </Tooltip>
          </Toolbar>
        </div>
        <Divider></Divider>

        {role == Role.Admin &&
          <AdminCoachesTabPanel
            theme={theme}
            tabIndex={tabIndex}
            handleChangeIndex={handleChangeIndex}
            currentUserName={currentUserName}
            handleOpenDialog={handleOpenDialogAdmin}
            profilesToDisplay={filteredProfileList}
            handleProfileStatusChange={handleProfileStatusChange}
            getLeagueTextByValue={getLeagueTextByValue}
            view={view}
            handleProfileChecked={handleProfileChecked}
            selectedProfiles={selectedProfiles}
            handleEditProfileClick={props.handleEditProfileClick}
            handleViewProfileClick={props.handleViewProfileClick}
            handleDeleteProfile={handleDeleteProfile}
            showUserPanel={showGroupTab}
            groupUsers={groupUsers}
          />}
        {(role == Role.FrontOffice || role == Role.FrontOfficePlusCoach || role == Role.Coach) &&
          <FrontOfficeCoachesTabPanel
            theme={theme}
            tabIndex={tabIndex}
            handleChangeIndex={handleChangeIndex}
            currentUserName={currentUserName}
            handleOpenDialog={handleOpenDialog}
            profilesToDisplay={filteredProfileList}
            handleBookMarked={handleBookMarked}
            getLeagueTextByValue={getLeagueTextByValue}
            view={view}
            handleViewProfileClick={props.handleViewProfileClick}
            handleProfileChecked={handleProfileChecked}
            selectedProfiles={selectedProfiles}
            foTabs={foTabs}
            groupUsers={groupUsers}
          />
        }
        {filteredProfileList.length > 0 && !isGroupProfile && <Pagination
          pageSize={pageSize}
          totalProfile={totalRecords}
          changePageNumber={(e) => {
            onPageNoChange(e.selected + 1);
          }}
          currentPageNo={currentPage}
        />
        }
        {role != Role.Admin &&
          groupUsers && groupUsers.length > 0 && isGroupProfile && <Pagination
            pageSize={groupPageSize}
            totalProfile={totalRecordGroup}
            changePageNumber={(e) => {
              onGroupTabPageNoChange(e.selected + 1);
            }}
            currentPageNo={groupPageNo}
          />
        }

        {/* <ProfileDialogs
          handleEditProfileClick={props.handleEditProfileClick}
          open={openDialog.open}
          data={openDialog.data}
          showApprove={openDialog.showApprove}
          showReject={openDialog.showReject}
          onApproveClick={handleApproveClick}
          onRejectClick={() => setOpenRejectDialog(true)}
          jwtTokenDetails={jwtTokenDetails}
          onClose={() => setOpenDialog(false)}
          getLeagueTextByValue={getLeagueTextByValue}
        /> */}

        <RejectDialog
          open={openRejectDialog}
          onClose={() => setOpenRejectDialog(false)}
          onReject={(note, sendMail) => btnRejectClick(note, sendMail)}
        />
        {showGroupDialog && <CreateGroupDialog
          open={showGroupDialog}
          showFrontOfficeUser={true}
          onClose={() => setShowGroupDialog(false)}
          name={group ? group.name : ''}
          description={group ? group.description : ''}
          showAsTAB={group ? group.showAsTAB : ''}
          saveGroup={saveGroup}
        />
        }
        {role === Role.Admin && openParticipantDialog &&
          <AddShowcaseParticipantsDialog
            action={action}
            groupList={allGroups}
            open={openParticipantDialog}
            handleCloseClick={handleCloseClick}
            memoId={""}
            handleSaveShowcaseClick={handleSaveShowcaseClick}
            selectedProfiles={selectedProfiles}
          />}
      </div>
      <Loader isLoading={isLoading} />
      <SnackbarMsg showError={showError} onClose={handleAlertClose} severity={severity} msg={message} />
    </>
  );
};

const CoachesManagementMainContainer = (props) => {
  const handleEditProfileClick = (username) => {
    props.history.push(RouteConstants.ADMIN_COACH_PROFILE_EDIT + "/" + username);
  }


  const handleViewProfileClick = (username) => {
    props.history.push(RouteConstants.COACHES_PROFILE_VIEW + "/" + username);
  }

  const redirectToGroupList = () => {
    props.history.push({
      pathname: RouteConstants.ADMIN_GROUP,
      state: {}
    });
  }
  return (
    <DashBoardProvider>
      <DashBoardConsumer>
        {(props) => {
          return <CoachesManagementContainer
            handleEditProfileClick={handleEditProfileClick}
            redirectToGroupList={redirectToGroupList}
            handleViewProfileClick={handleViewProfileClick}
          />;
        }}
      </DashBoardConsumer>
    </DashBoardProvider>

  );
};

export default withRouter(CoachesManagementMainContainer);