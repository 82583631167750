import React, { useContext, useState, useEffect } from 'react';
import { useHistory, useParams, withRouter } from 'react-router-dom';
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import { Paper, Typography } from '@material-ui/core';
import Checkbox from "@material-ui/core/Checkbox";
import './FilterableData.css';
import Tooltip from '@material-ui/core/Tooltip';
import EditTwoToneIcon from '@material-ui/icons/EditTwoTone';
import DeleteTwoToneIcon from '@material-ui/icons/DeleteTwoTone';
import IconButton from "@material-ui/core/IconButton";
import FilterableTableManualPagination from '../../thirdParty/react-filterable-table/Components/FilterableTableManualPagination';

const useStyles = makeStyles(theme => ({
    root: {
        margin: 10,
        flexGrow: 1,
    },
    btnAssistants: {
        backgroundColor:'#3897ff',
        color: '#fff',
        marginRight: '8px',
        height:'36px',
        '&:hover': {
            color: '#fff',
            background: '#3897ff',
        },
        '&:focus': {
            color: '#fff',
            background: '#3897ff',
        },
        '&:disabled': {
            color: '#fff',
            background: '#a2bfde !important',
            cursor:'default'
        }
    },
    btnPlayers: {
        backgroundColor:'#ff781f',
        color: '#fff',
        marginRight: '12px',
        height:'36px',
        '&:hover': {
            color: '#fff',
            background: '#ff781f',
        },
        '&:focus': {
            color: '#fff',
            background: '#ff781f',
        },
        '&:disabled': {
            color: '#fff',
            background: '#e88a4d !important',
            cursor:'default'
        }
        
    },
    groupContent: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        overflow: 'auto',
        height: 'auto',
    },
    cursorPointer: {
        cursor: 'pointer',
    },
    customFilterableTable :{
        width: '100%',
        borderCollapse: 'collapse',
        '& th': {
            background: '#316ad8',
            color: '#fff',
        },
        '& th, & td': {
            padding: theme.spacing(.75),
            borderBottom: '1px solid #ccc',
            fontSize: '0.875rem',
        },
        '& th:first-child,  & td:first-child': {
            textAlign: 'left',
            width : '5%'
        },
        '& th:last-child, & td:last-child': {
            textAlign: 'right',
        },

    }
}));

function HeadCoachDataContainer(props) {
    const classes = useStyles();  

   
    const renderAction = (data) => {
        return (
            <>
             <IconButton                              
                className={classes.btnAssistants} 
                onClick={() => props.onAssistantCountClick(data.record,false)}
                disabled={data.record.assistantsCount == 0} 
                style={{ cursor : data.record.assistantsCount == 0 ? "default":"pointer"}}           
              >
                
                  <Typography variant="caption" size="small" style={{ fontWeight: "500", fontSize: ".93rem" }} >
                  {data.record.assistantsCount}
                </Typography>
              </IconButton>

              <IconButton
                aria-label="card view"
                color="secondary"               
                className={classes.btnPlayers} 
                onClick={() => props.onAssistantCountClick(data.record,true)} 
                disabled={data.record.playersCount == 0} 
                style={{ cursor : data.record.playersCount == 0 ? "default":"pointer"}}                 
              >
                
                  <Typography variant="caption" size="small" style={{ fontWeight: "500", fontSize: ".93rem" }} >
                  {data.record.playersCount}
                </Typography>
              </IconButton>
                <Tooltip title="Edit"  onClick={() => props.onEditClick(data.record)} style={{ cursor: "pointer" }}>
                    <EditTwoToneIcon color="primary" />
                </Tooltip>
                <Tooltip title="Delete" onClick={()=>props.onDeleteClick(data.record.Id)} style={{ cursor: "pointer" }}>
                    <DeleteTwoToneIcon color="primary" />
                </Tooltip>
            </>
        );
    };

   
    const renderCheckBox = (data) => {
        return (
            <Checkbox
                name="chkGroup"
                id={data.record.Id}
                checked={checkRefCheckbox(data.record.Id)}
                onChange={(event) => props.handleRefChecked(event,data.record.Id)}>
            </Checkbox>
        )
    };

    const checkRefCheckbox = (id) => {
		return props.selectedData?  props.selectedData.includes(id):false;
	}



    const fieldsWithAssistants = [
        { name: 'Id', displayName: "", inputFilterable: false, sortable: false, render: renderCheckBox },
        { name: 'Name', displayName: "Name", inputFilterable: false, sortable: false},
        { name: 'Action', displayName: "Action", inputFilterable: false, sortable: false, exactFilterable: false, render: renderAction },

    ];
    return (
        <div className="repsContainer" style={{ overflow: 'hidden' }}>
            <div className={classes.groupContent}>
                <Paper>
                    {props.data && <FilterableTableManualPagination
                       // filter={'behind'}
                        namespace="Assistant Coaches"
                        initialSort="name"
                        data={props.data}
                        fields={fieldsWithAssistants}
                        noRecordsMessage="There is no data available"
                        noFilteredRecordsMessage="No data match your filters!"
                        pageSize={props.defaultPageSize}
                        pageSizes={[12, 24, 48, 72]}
                        topPagerVisible ={false}
                        tableClassName ={classes.customFilterableTable}
                        maintainPageOnSetData
                        currentPage={props.currentPage}
                        onPageChange={props.onPageChange}
                        currentFilterText={props.currentFilterText}
                        onFilterTextChange={props.onFilterTextChange}
                        totalPages={props.totalHeadCoachCount}
                        totalHeadCoachCount={props.totalHeadCoachCount}
                        loadingdata={props.loading}
                    />
                    }
                    {/* <TableContainer component={Paper} className={classes.table}>
                        <Table size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell style={{ width: '60%' }}>Name</StyledTableCell>
                                    <StyledTableCell align="right" style={{ width: '40%' }}>Action</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {props.data && props.data.map((item, index) => {
                                    return <ManageItem
                                        key={index}
                                        data={item}
                                        onDeleteClick={props.onDeleteClick}
                                        onEditClick={props.onEditClick}
                                    />
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer> */}
                </Paper>
            </div>
        </div>
    );
};
export default withRouter(HeadCoachDataContainer);