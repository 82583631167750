import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import ExperienceDetailsList from "../ExperienceDetailsList";
import ChampionshipDetailsList from "../ChampionshipDetailsList";

const useStyles = makeStyles((theme) => ({
  grid: {
    padding: theme.spacing(1),
    alignSelf: "center",
  },
  header: {
    paddingLeft: theme.spacing(1),
    paddingTop: theme.spacing(1),
    color: theme.palette.text.secondary,
  },
}));

const SelectLeagueOptions = (props) => {
  const classes = useStyles();
  const [leagueValue, setLeague] = useState([]);
  const [leagueExperience, setExperience] = useState(props.data);

  function setSelectedLeague() {
    let league = [];
    leagueExperience.map((x) => {
      if (league.indexOf(x.league) === -1) {
        league.push(x.league);
      }
    });
    setLeague(league);
  }
  useEffect(() => {
    setExperience(props.data);
  }, [props.data]);

  useEffect(() => {
    setSelectedLeague();
  }, []);


  const [leagueOptions, setLeagueOption] = useState(props.leagueOptionsList.filter(x => x.show == true).sort((a, b) => (a.order > b.order) ? 1 : -1));

  useEffect(() => {
    setLeagueOption(props.leagueOptionsList.filter(x => x.show == true).sort((a, b) => (a.order > b.order) ? 1 : -1));
  }, props.leagueOptionsList)

  const handleChange = (event) => {
    let leagues = [...leagueValue];
    if (event.target.checked) {
      leagues.push(event.target.name);
      if (event.target.name == "na") {
        onDataChange([], "na");
      }
    } else {
      let index = leagues.indexOf(event.target.name);
      leagues.splice(index, 1);
      clearDataForNotSavedLeague(event.target.name);
    }
    setLeague(leagues);
  };

  const clearDataForNotSavedLeague = (leagueName) => {
    let exp = [...leagueExperience];
    exp = exp.filter((x) => x.league !== leagueName);
    setExperience(exp);
    props.onChange(exp);
  }

  const onDataChange = (data, name) => {
    let leagueExp = [...leagueExperience];
    let exp = leagueExp.filter((x) => x.league === name);
    let otherexp = leagueExp.filter((x) => x.league !== name);
    let experience = [];
    if (props.championship) {
      exp[0] = { league: name, experience: data };
    }
    else {
      data.map((item) => {
        let obj = {};
        if (props.useHeadCoach && props.useTitle && props.useTitleSubCategory) {
          obj = {
            id: item.id, team: { teamId: item.teamId, teamName: item.teamName, logoURL: item.logoURL },
            fromDate: item.fromDate, toDate: item.toDate, headCoachName: item.headCoachName, title: { Id: item.Id, Name: item.Name }, titleSubCategory: { Id: item.titleSubCategoryId, Name: item.titleSubCategoryName },
          };

        }

        else if (props.useHeadCoach) {
          obj = {
            id: item.id, team: { teamId: item.teamId, teamName: item.teamName },
            fromDate: item.fromDate, toDate: item.toDate, headCoachName: item.headCoachName,
          };
        }
        else {
          obj = {
            id: item.id, team: { teamId: item.teamId, teamName: item.teamName, logoURL: item.logoURL },
            fromDate: item.fromDate, toDate: item.toDate,
          };
        }
        experience.push(obj);
      });
      exp[0] = { league: name, experience: experience };
    }
    let newData = otherexp.concat(exp);
    props.onChange(newData);
  };

  const getLeagueTextByValue = (leagueValue) => {
    let league = leagueOptions.find(x => x.value == leagueValue);
    return league && league.text ? league.text : "";
  }

  return (
    <Grid container>
      <Grid item md={12} xs={12} className={classes.grid}>
        <FormControl component="fieldset">
          <FormGroup row>
            {leagueOptions.map((item) => {
              let checked = false;
              let checkDisabled = false;
              if (leagueValue.length > 0) {
                let selectedLeaguge = leagueValue;
                let checkLeague = selectedLeaguge.find((x) => x === item.value);
                if (selectedLeaguge.includes("na") && item.value != "na") {
                  checkDisabled = true;
                }
                else if (!selectedLeaguge.includes("na") && selectedLeaguge.length > 0 && item.value == "na") {
                  checkDisabled = true;
                }
                if (checkLeague) {
                  checked = true;
                }
              }
              return (
                <FormControlLabel
                  key={item.value}
                  control={
                    <Checkbox
                      checked={checked}
                      disabled={checkDisabled}
                      name={item.value}
                      onChange={handleChange}
                    />
                  }
                  label={
                    <Typography variant="caption">
                      {item.text}
                    </Typography>
                  }
                />
              );
            })}
          </FormGroup>
        </FormControl>
      </Grid>
      {leagueValue.filter(x => x != "na").map((item) => {
        let expData = leagueExperience.find((x) => x.league === item);
        if (expData && expData.experience && expData.experience.length > 0) {
          expData.experience.forEach(function (exp) {
            let availableHeadCoachNames = exp.headCoachName ? exp.headCoachName.filter(x => props.headCoachesList.map(hc => hc.Name)) : [];
            exp.headCoachName = availableHeadCoachNames;
            // if (exp.title) {
            //   exp.title.Name = ( props.coachTitle && props.coachTitle.map(p => p.Name).includes(exp.title.Name)) ? exp.title.Name : "";
            // }
            // if (exp.team) {
            //   exp.team.teamName = ( props.teamList && props.teamList.map(p => p.teamName).includes(exp.team.teamName)) ? exp.team.teamName : exp.team.teamName;
            // }
          });
        }
        return (
          <Grid key={item} item md={12} xs={12}>
            <div className={classes.header}>
              <Typography variant="subtitle2">
                {getLeagueTextByValue(item)}
              </Typography>
            </div>
            {/* to show Head Coach element pass prop useHeadCoach true */}
            {/* to show Title element pass prop useTitle true */}
            {/* to show TitleSubCategory element pass prop useTitleSubCategory true */}

            {!props.championship &&
              <ExperienceDetailsList
                data={expData && expData.experience ? expData.experience : []}
                league={item}
                teamList={props.teamList}
                headCoachesList={props.headCoachesList}
                onDataChange={onDataChange}
                showCurrentCheckBox={props.showCurrentCheckBox}
                positionTitle={props.coachTitle}
                useHeadCoach={props.useHeadCoach}
                useTitle={props.useTitle}
                useTitleSubCategory={props.useTitleSubCategory}
                isInValid={props.isInValid}
              />}

            {props.championship &&
              <ChampionshipDetailsList
                league={item}
                onDataChange={onDataChange}
                data={expData && expData.experience ? expData.experience : []}
                useHeadCoach={props.useHeadCoach}
                teamList={props.teamList}
                headCoachesList={props.headCoachesList}
                isInValid={props.isInValid}
              />
            }
          </Grid>
        );
      })}
    </Grid>
  );
};

export default SelectLeagueOptions;
