import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const useStyles = makeStyles((theme) => ({
  grid: {
    padding: theme.spacing(1),
    alignSelf: "center",
  },
}));

const TeamCoordinator = (props) => {
  const classes = useStyles();

  const [isCoordinator, setIsCoordinaor] = useState(props.data);

  useEffect(() => {
    props.onChange(isCoordinator);
  }, [isCoordinator]);

  return (
    <Grid container>
      <Grid item md={12} xs={12} className={classes.grid}>
        <RadioGroup
          row
          aria-label="coordinator"
          name="isCoordinator"
          value={isCoordinator}
          onChange={(event) => setIsCoordinaor(event.target.value)}
        >
          <FormControlLabel
            value="no"
            control={<Radio />}
            label={<Typography variant="caption">No</Typography>}
          ></FormControlLabel>
          <FormControlLabel
            value="offensive"
            control={<Radio />}
            label={
              <Typography variant="caption">
                Yes, Offensive Coordinator
              </Typography>
            }
          />
          <FormControlLabel
            value="defensive"
            control={<Radio />}
            label={
              <Typography variant="caption">
                Yes, Defensive Coordinator
              </Typography>
            }
          />
        </RadioGroup>
      </Grid>
    </Grid>
  );
};

export default TeamCoordinator;
