import React, {useState, useEffect} from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import CKEditor from 'ckeditor4-react';
import Helper from "../../common/Helper";

const useStyles = makeStyles((theme) => ({
    grid: {
        padding: theme.spacing(1),
        alignSelf: "center",
    },
    errorMessage :{color: 'red', paddingLeft: '2px'}
}));


const HTMLEditor = (props) => {
    const classes = useStyles();
    const [value, setValue] = useState(props.data);

    useEffect(() => {
        setValue(props.data);
    }, [props.data]);

    const onChange = (e) => {
        setValue(e.editor.getData());
        e.target = {name: props.name, value: e.editor.getData()};
        props.onChange(e);
    }

    return (
        <Grid container>
            <Grid item md={12} xs={12} className={classes.grid}>
            <div style={{display: 'flex', justifyContent: 'flex-end', fontStyle: 'italic', color: 'gray', fontSize:'11px'}}>{Helper.RemoveTags(value).length} chars</div>
                <CKEditor
                    config={{extraPlugins: 'autogrow', autoGrow_minHeight: 50  }}
                    data={value}
                    initialValue={value}
                    onChange={onChange}
                />
                {props.error ? <p className={classes.errorMessage}>
                    {props.error}</p> : null}
            </Grid>
        </Grid>
    );
};

export default HTMLEditor;