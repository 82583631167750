import React, { createContext, useContext, useEffect, useState } from 'react';
import AppConstants from '../common/AppConstants';
import ContentReviewMessage from '../common/GetContentReviewMessage';
import { getCurrentUser } from '../helper/currentLoggedInUser';
import { gameVideoService } from '../services/gameVideo.service';
import { userService } from '../services';
import { Role } from '../helper';

const GameVideoContext = createContext();

export const useGameVideo = () => useContext(GameVideoContext);

export const GameVideoProvider = ({ children }) => {

    const [gameVideoConfigValues, setGameVideoConfigValues] = useState({
        returnedReasonVideo: null,
        approvedReasonVideo: null,
        pendingReasonVideo: null
    });
    const [isLoading, setLoading] = useState(false);
    const [pendingVideo, setPendingVideo] = useState([]);

    //... Get current loggedIn user token.
    const currentUserLocalStorage = getCurrentUser();

    useEffect(() => {
        // Fetch the config values asynchronously
        const fetchVideoConfigData = async () => {
            try {
                const returnedMessage = await ContentReviewMessage.getVideoReviewMessage(AppConstants.REVIEW_STATUS.RETURNED);
                const approvedMessage = await ContentReviewMessage.getVideoReviewMessage(AppConstants.REVIEW_STATUS.APPROVED);
                const pendingMessage = await ContentReviewMessage.getVideoReviewMessage(AppConstants.REVIEW_STATUS.PENDING);

                setGameVideoConfigValues({
                    returnedReasonVideo: returnedMessage,
                    approvedReasonVideo: approvedMessage,
                    pendingReasonVideo: pendingMessage
                });
            } catch (error) {
                console.error('Error fetching config values:', error);
            }
        };

        if (currentUserLocalStorage?.token) {
            fetchVideoConfigData();

        }
    }, [currentUserLocalStorage?.token]);

    useEffect(() => {
        if (currentUserLocalStorage?.token && userService.getUserRole() === Role.Admin) {
            getAllPendingVideos();
        }
    }, [currentUserLocalStorage?.token]);

    const getAllPendingVideos = () => {
        gameVideoService.getAllPendingProfileVideos().then((res) => {
            setLoading(true);
            setPendingVideo(res);
        }).catch(error => {
            console.log("Error", error);
        }).finally(() => {
            setLoading(false);
        });
    };

    const handleGameVideoAction = (mediaObject, reviewStatus, reviewedReason) => {
        setLoading(true);
        const updatedMediaObject = {
            ...mediaObject,
            profileVideo: {
                ...mediaObject.profileVideo,
                reviewedStatus: reviewStatus,
                reviewedReason: reviewedReason,
            }
        };

        gameVideoService.approveRejectPendingProfileVideos(updatedMediaObject)
            .then((profileVideoRes) => {

                const pendingVideoList = [...pendingVideo];
                // Update the list
                const updatedPendingVideo = pendingVideoList.map(item => {
                    if (item.profileVideo.id === profileVideoRes.id) {
                        if (profileVideoRes.reviewedStatus == AppConstants.REVIEW_STATUS.APPROVED || profileVideoRes.reviewedStatus == AppConstants.REVIEW_STATUS.RETURNED) {
                            return {
                                ...item,
                                profileVideo: {
                                    ...item.profileVideo,
                                    reviewedStatus: profileVideoRes.reviewedStatus,
                                    reviewedReason: profileVideoRes.reviewedReason
                                }
                            };
                        }
                    }
                    return item; // Return the original item if it doesn't match the condition
                });

                const filteredApprovedVideos = updatedPendingVideo.filter(item => item.profileVideo.reviewedStatus !== AppConstants.REVIEW_STATUS.APPROVED);

                // Set the pendingVideo state with the updated array
                setPendingVideo(filteredApprovedVideos);
            })
            .catch(error => {
                console.log("Error", error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <GameVideoContext.Provider
            value={{
                gameVideoConfigValues,
                isLoading,
                pendingVideo,
                setLoading,
                setPendingVideo,
                handleGameVideoAction,
                getAllPendingVideos
            }}>
            {children}
        </GameVideoContext.Provider>
    );
};