

export default class ProfileSubmitValidations {

    static ValidateProfile(fullName, roleAndCoachingPhilosophy, profilePhoto, profileVideo, mediaAndArticle, coachingVideos,
        coachingExperience, other, additionalExperience, skillSet, educationDetail, coachingAndOtherJobExperience, playingExperience) {
        let validationMessages = [];
        if (roleAndCoachingPhilosophy.description == undefined || roleAndCoachingPhilosophy.description == "" || roleAndCoachingPhilosophy.description == null) {
            validationMessages.push("Section: Personal Details/ Question: 2 / Your approach to coaching, highlighting your strengths and what NBA players can expect from you.")
        }
        if (profilePhoto == null || profilePhoto == undefined || profilePhoto.photoURL == undefined || profilePhoto.photoURL == null) {
            validationMessages.push("Section: Personal Details/ Question: 3 / Current headshot/photo.")
        }
        // if(coachingAndOtherJobExperience==null || coachingAndOtherJobExperience==undefined ||(coachingAndOtherJobExperience.assistantCoachExperience.length==0 && coachingAndOtherJobExperience.awardsAndAllocades.length==0 && coachingAndOtherJobExperience.championship.length==0 && coachingAndOtherJobExperience.headCoachExperience.length==0 && coachingAndOtherJobExperience.notablePlayer.length==0 && coachingAndOtherJobExperience.skillExperience.length==0 && coachingAndOtherJobExperience.taskInfo==""))
        // {
        // validationMessages.push("Section: 2 / Please fill coaching and other job experience.")
        // }
        if(skillSet==null || skillSet==undefined ||skillSet.coachingSkill ==undefined || skillSet.coachingSkill==null || skillSet.coachingSkill.length==0)
        {
            validationMessages.push("Section: Skills/ Question: 1 / Add any and all basketball and coaching skills you have acquired.")
        }
        // if(skillSet==null || skillSet==undefined ||skillSet.coordinator ==undefined || skillSet.coordinator==null || skillSet.coordinator=="")
        // {
        //     validationMessages.push("Section: 5 / Question: 3 / Are you currently your team's coordinator.")
        // }
        // if(skillSet==null || skillSet==undefined ||skillSet.coachPhilosophyAndSkill ==undefined || skillSet.coachPhilosophyAndSkill==null || skillSet.coachPhilosophyAndSkill=="")
        // {
        //     validationMessages.push("Section: 5 / Question: 4 / Unique about your coaching philosophy, skills and approach.")
        // }
        return validationMessages;
    }
}