//import { Platform } from 'react-native';
//import { ReactNativePlugin } from '@microsoft/applicationinsights-react-native';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { useEffect } from 'react';
//import { useUserDetails } from '../contexts/UserContext';
//import { useAppConfigContext } from '../contexts/AppConfigContext';
//import AppConfig from '../common/AppConfig';

//var RNPlugin = new ReactNativePlugin();
var appInsights = null;

const useTelemetry = () => {

    //const { user } = useUserDetails();
    //const { appConfig, appId } = useAppConfigContext();

    useEffect(() => {
        // if (AppConfig.instrumentationKey) {
        //     __initialize();
        // }
        __initialize();
    }, []);

    const trackException = (err) => {
        try {
            appInsights.trackException({
                exception: new Error(err),
                properties:
                    { ...value}
            });
        }
        catch {
            //... Eat exception.
        }
    }

    const trackCustomEvents = async (event, value) => {
        try {

            // console.log('EVENT -> ', event, 'VALUE -> ', value, ' - ', Platform.OS, ' appId - ', appId);

            appInsights?.trackEvent({
                name: event,
                properties:
                    { ...value}
            });
        }
        catch (err) {
            console.log('err', err);
            //... Eat exception.
        }
    }

    const __initialize = () => {

        if (!appInsights) {

            //console.log('INITIALIZING APP INSIGHTS ---> ');


            var insights = new ApplicationInsights({
                config: {
                    instrumentationKey: "d4e05bfd-df3c-4c8c-b0e2-6990fff47dfa", //... Pass the instrumentation key here.
                    //enableCorsCorrelation: true,
                    //enableRequestHeaderTracking: true,
                    //enableResponseHeaderTracking: true,
                    //disableAjaxTracking: true,
                    //maxAjaxCallsPerView: 0,
                    autoTrackPageVisitTime: true,
                    correlationHeaderExcludedDomains: ['*.queue.core.windows.net'],
                    //extensions: [RNPlugin],
                },
            });

            insights.loadAppInsights();

            appInsights = insights;
        }
    }

    return { trackException, trackCustomEvents }
}

export default useTelemetry;