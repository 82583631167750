import React, { useEffect, useState } from 'react';
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        width: '90%',
        flexWrap: 'wrap',
        fontSize: '11px',
        fontWeight: '400',
        alignItems: 'center'
    },
    name: {
        display: 'flex',
        padding: '3px',
        border: '1px solid #17408B',
        borderRadius: '5px',
        marginLeft: '3px',
        fontSize: '12px',
        fontStyle: 'italic',
        height: '25px'
    },
    xButton: {
        cursor: 'pointer',
        fontStyle: 'normal',
        fontWeight: '500',
        color: '#c9082a',
        padding: '0 3px'
    },
    clearAll: {
        fontStyle: 'normal',
        color: '#c9082a',
        cursor: 'pointer',
        paddingLeft: '5px'
    }
}));

export default function SelectedNames(props) {
    const classes = useStyles();
    const [value, setValue] = useState([]);

    useEffect(() => {
        if(props.data && props.data.length > 0)
        {
            setValue(props.data);
        }
    }, [props.data]);

    return(
        <div className={classes.root}>
            <div style={{color: '#c9082a '}}>You have selected:</div> 
            {value && value.length > 0 && value.map(d => { 
               return  (<div key={d.Id} className={classes.name}>
                            <div>{d.Name}</div>
                            <div onClick={() => props.onRemove(d.Id)} className={classes.xButton}>X</div>
                   </div>)
            })}
            <div onClick={props.onClearAll} className={classes.clearAll} >Clear All</div>
        </div>
    )
}