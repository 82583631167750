import { Box, Button, FormControl, FormHelperText, Grid, TextField, Typography } from "@material-ui/core/";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import socialPostService from "../../services/socialPost.service";
import Loader from "../../sharedComponents/Loader/Loader";
import usePostForm from "../../sharedComponents/customHooks/usePostForm";
import createSocialPostSchema from "../../validationSchema/createSocialPostSchema";
import { useToast } from "../../context/SnackBarContext";
import { userService } from "../../services";
import { Role } from "../../helper";
import AppConstants from "../../common/AppConstants";
import RouteConstants from "../../common/RouteConstants";
import CKEditor from 'ckeditor4-react';

const useStyles = makeStyles((theme) => ({
    sectionDivider: {
        margin: theme.spacing(2),
    },
}));

const CreateSocialPost = (props) => {

    const { showToast } = useToast();
    const userRole = userService.getUserRole();

    const [isLoading, setIsLoading] = useState(false);
    const [isTitleChanged, setIsTitleChanged] = useState(false);
    const [isDescriptionChanged, setIsDescriptionChanged] = useState(false);
    const classes = useStyles();
    let { editPost,
        postDetails
    } = props?.location?.state;

    useEffect(() => {

        if (editPost) {
            prefillForm({
                'postTitle': postDetails?.title,
                'postDescription': postDetails?.description,
            });
        }
    }, []);

    const handleNavigation = (isCancel = false) => {

        // If the user is creating or updating a post, they will navigate to the "My Pending Approval Posts" section,
        // where the tabIndex will be set to 1. 
        // Otherwise, if they cancel creating the post and for admin role, the tabIndex will default to 0.

        let tabIndex = 0;

        if (userRole === Role.Coach && !isCancel) {
            tabIndex = 1; // Set tabIndex to 1 for coach role
        }

        // Navigate back to the previous screen
        props.history.replace({
            pathname: RouteConstants.SOCIAL_COMMUNITY_CONTAINER,
            state: { tabIndex: tabIndex }
        });
    };

    const onSubmitForm = () => {

        //submit the form
        if (errors.postTitle) {
            showToast("error", errors.postTitle); return;
        }
        if (errors.postDescription) {
            showToast("error", errors.postDescription); return;
        }

        setIsLoading(true);
        const postData = {
            title: values.postTitle,
            description: values.postDescription
        }

        if (editPost) {
            postData.id = postDetails.id;
            socialPostService.updatePost(postData).then((res) => {
                showToast("success", "Your post is updated and has been submitted for review.");
                handleNavigation();
            }).catch(error => {
                console.log("Error", error);
                showToast("error", "Failed to update Post.");
            }).finally(() => {
                setIsLoading(false);
            });
        } else {
            socialPostService.createNewPost(postData).then((res) => {
                userRole === Role.Coach ?
                    showToast("success", "Your post has been submitted for review.") :
                    showToast("success", "Your post has been created successfully.")
                handleNavigation();
            }).catch(error => {
                console.log("Error", error);
                showToast("error", "Failed to create Post.");
            }).finally(() => {
                setIsLoading(false);
            });
        }
    };

    const {
        errors,
        handleChange,
        handleSubmit,
        values,
        prefillForm
    } = usePostForm(() => onSubmitForm(), createSocialPostSchema);

    // Check if title or description has changed
    useEffect(() => {
        setIsTitleChanged(values.postTitle !== postDetails?.title);
        setIsDescriptionChanged(values.postDescription !== postDetails?.description);
    }, [values, postDetails]);

    return (
        <Box sizeHeight={"100vh"} sizeWidth={'100vw'} display="flex" style={{ justifyContent: "center" }}>
            <Grid item xs={12} sm={12} md={8} lg={6} xl={6} style={{
                padding: 24
            }}>
                <Typography variant="h6" color="secondary">
                    Create Post
                </Typography>
                <div className={classes.sectionDivider}></div>
                <Grid item spacing={2} xs={12} >
                    <FormControl fullWidth>
                        <TextField
                            id="title"
                            variant="outlined"
                            value={values.postTitle}
                            name="title"
                            label="Title"
                            onChange={(event) => handleChange('postTitle', event.target.value)}
                            fullWidth
                        />
                        <FormHelperText error>{errors.postTitle}</FormHelperText>
                    </FormControl>
                    <div className={classes.sectionDivider}></div>
                    <FormControl fullWidth>
                    <CKEditor
                            id="description"
                            name="description"
                            data={values.postDescription}
                            onChange={(event) => handleChange('postDescription',event.editor.getData())}
                        />
                        {/* <TextField
                            id="description"
                            variant="outlined"
                            value={values.postDescription}
                            name="description"
                            label="Content"
                            onChange={(event) => handleChange('postDescription', event.target.value)}
                            multiline
                            rows={10}
                        /> */}
                        <FormHelperText disabled style={{ fontStyle: 'italic' }} >Do not share personal information such as contact info, emails, etc.</FormHelperText>
                        <FormHelperText error>{errors.postDescription}</FormHelperText>
                    </FormControl>
                    <div className={classes.sectionDivider}></div>
                </Grid>
                <Box style={{ display: 'flex', justifyContent: "flex-end" }}>

                    {/* added a cancel button which will render us back to post list */}
                    <Button
                        color="primary"
                        variant="outlined"
                        onClick={() => handleNavigation(true)}
                        disabled={isLoading}
                        style={{ marginRight: '20px' }}>Cancel</Button>

                    <Button
                        color="secondary"
                        variant="contained"
                        onClick={handleSubmit}
                        disabled={isLoading || (editPost && !isTitleChanged && !isDescriptionChanged)}
                    >
                        {editPost ? "Update" : "Post"}

                    </Button>
                </Box>
                <Loader isLoading={isLoading} />
            </Grid>
        </Box>
    );
};

export default CreateSocialPost;


