const DATE_FORMAT = 'MM/DD/YYYY';
const TIME_FORMAT = 'hh:mm A';
const DATE_TIME_FORMAT = 'MM/DD/YYYY, h:mm A';
const DATE_TIME_FORMAT_24 = 'MM/DD/YYYY, HH:mm';
const DAY_DATE_FORMAT = 'dddd, MM/DD/YYYY';
const MONTH_YEAR_FORMAT ='MM/YYYY'
const info = {
    DateFormat: DATE_FORMAT,
    TimeFormat: TIME_FORMAT,
    DateTimeFormat: DATE_TIME_FORMAT,
    DateTimeFormat24: DATE_TIME_FORMAT_24,
    DayWithDateFormat: DAY_DATE_FORMAT,
    MonthWithYearFormat: MONTH_YEAR_FORMAT
}

class CultureInfo {
    static GetCultureInfo() {
        return info;
    }
}

export default CultureInfo;