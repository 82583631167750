import AppBar from "@material-ui/core/AppBar";
import IconButton from "@material-ui/core/IconButton";
import Toolbar from "@material-ui/core/Toolbar";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import AccountCircle from "@material-ui/icons/AccountCircle";
import ForumIcon from '@material-ui/icons/Forum';
import InsertInvitationIcon from '@material-ui/icons/InsertInvitation';
import PieChartIcon from '@material-ui/icons/PieChart';
import PortraitIcon from '@material-ui/icons/Portrait';
import SettingsIcon from '@material-ui/icons/Settings';
import SportsBasketballIcon from '@material-ui/icons/SportsBasketball';
import StorageIcon from '@material-ui/icons/Storage';
import React, { useContext, useEffect, useState } from "react";
import { useLocation, withRouter } from "react-router-dom";
// import NotificationsIcon from '@material-ui/icons/Notifications';
import GroupIcon from '@material-ui/icons/Group';
import CreateShowcaseIcon from "../../sharedComponents/customIcons/CreateShowcaseIcon";

import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ContactSupportOutlinedIcon from "@material-ui/icons/ContactSupportOutlined";
import DashboardIcon from '@material-ui/icons/Dashboard';
import EmailOutlinedIcon from "@material-ui/icons/EmailOutlined";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import LiveHelpOutlinedIcon from '@material-ui/icons/LiveHelpOutlined';
import VpnKeyOutlinedIcon from "@material-ui/icons/VpnKeyOutlined";
import { FilterContext } from "../../common/FilterContext";
import Helper from '../../common/Helper';
import RouteConstants from "../../common/RouteConstants";

import { ChatBubbleOutlineOutlined, VideoLibraryOutlined, VideogameAssetOutlined } from "@material-ui/icons";
import { AppDataContext } from '../../common/AppContext';
import { Role } from '../../helper';
import nbca from "../../images/nbca_logo.png";
import ActiveShowCaseDialog from "../../screens/other/ActiveShowCaseDialog";
import { userService } from '../../services';
import "./AppHeaderBar.css";
import AssignmentIcon from "@material-ui/icons/Assignment";
import { useProfileContext } from "../../common/ProfileContext";
import useTelemetry from '../../utils/useTelemetry';

const useStyles = makeStyles((theme) => ({
  appBar: {
    color: theme.palette.type === "dark" && "#fff",
    backgroundColor:
      theme.palette.type === "dark" && theme.palette.primary.dark,
  },
  list: {
    "&:focus": {
      outline: "none",
    },
  },
  title: {
    flexGrow: 1,
  },
  coachTitle: {
    cursor: 'pointer',
  },
  menu: {
    width: "250px",
  },
  itemIcon: {
    minWidth: "35px",
  },
  avatar: {
    backgroundColor: theme.palette.primary.dark,
  },
  coachAvatar: {
    backgroundColor: theme.palette.primary.dark,
    cursor: 'pointer',
  },
  version: {
    paddingLeft: theme.spacing(2),
  },
}));

const CONST_COACHES_COMMUNITY = "Coaches Community";

const AppHeaderBar = (props) => {
  const classes = useStyles();
  const location = useLocation();
  const [linkTextForProfile, setLinkTextForProfile] = useState();
  const [linkTextForProfileCoach, setLinkTextForProfileCoach] = useState();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showcaseDialog, setShowcaseDialog] = React.useState(false);
  const [showNotification, setShowNotification] = React.useState(false);
  
  const {
    canISeeCoachingTree
  } = useContext(AppDataContext);
  const open = Boolean(anchorEl);
  const userRole = userService.getUserRole();
  const { status } = useProfileContext();
  const { trackCustomEvents } = useTelemetry();

  useEffect(() => {
    setLinkTextForProfile(props.location && props.location.pathname && props.location.pathname == RouteConstants.DASHBOARD ? 'My Profile' : 'Back to Dashboard');
    setLinkTextForProfileCoach(props.location && props.location.pathname && props.location.pathname == RouteConstants.COACH_DASHBOARD ? 'My Profile' : 'Dashboard');
  }, [props.location.pathname]);

  useEffect(() => {
    if (userRole == Role.FrontOfficePlusCoach || userRole == Role.FrontOffice) {
      userService.getAllActiveShowCase()
        .then(data => {
          if (!data) {
            setShowNotification(false);
          }
          else {
            setShowNotification(true);
          }
        })
        .catch(err => {
          setShowNotification(false);
        })
    }
  }, []);


  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
    trackCustomEvents("ClickEvent", { event: "Menu" });
  };

  const handleShowcase = (event) => {
    setShowcaseDialog(true);
    trackCustomEvents("ClickEvent", { event: "Showcase" });
  };

  const handleSettings = (event) => {
    handleClose();
    props.history.push({
      pathname: RouteConstants.ADMIN_SETTINGS,
      state: undefined,
    });
    trackCustomEvents("ClickEvent", { event: "Settings" });
  }

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleThemeChange = () => {
    props.onThemeChange(props.currentThemeType === "dark" ? "light" : "dark");
  };

  function isUserOnLoginScreen() {
    if (
      location.pathname.toLowerCase() ===
      `${RouteConstants.COACH_LOGIN}`.toLowerCase()
    )
      return true;
    else return false;
  }

  function isUserOnsignUpScreen() {
    if (
      location.pathname.toLowerCase() ===
      `${RouteConstants.COACH_REGISTRATION}`.toLowerCase()
    )
      return true;
    else return false;
  }

  const handleSignInClick = () => {
    props.history.push({
      pathname: RouteConstants.COACH_LOGIN,
      state: undefined,
    });
    trackCustomEvents("ClickEvent", { event: "Sign in" });
  };

  const handleRegistrationClick = () => {
    props.history.push({
      pathname: RouteConstants.COACH_REGISTRATION,
      state: undefined,
    });
    trackCustomEvents("ClickEvent", { event: "Registration" });
  };

  const handleUpdateEmailClick = () => {
    handleClose();
    props.history.push({
      pathname: RouteConstants.COACH_CHANGE_EMAIL,
      state: undefined,
    });
    trackCustomEvents("ClickEvent", { event: "Update Email" });
  };

  const handleSocialCommunityClick = () => {
    handleClose();
    props.history.push({
      pathname: RouteConstants.SOCIAL_COMMUNITY_CONTAINER,
      state: undefined,
    });
    trackCustomEvents("ClickEvent", { event: "Coaches Community" });
    // setTabIndex(0);
    // setSkip(0);
  };

  const handleMyMemoClick = () => {
    handleClose();
    props.history.push({
      pathname: RouteConstants.COACH_VIEW_USER_MEMO,
      state: undefined,
    });
    trackCustomEvents("ClickEvent", { event: "Memo" });
  };


  const handleChangePasswordClick = () => {
    handleClose();
    props.history.push({
      pathname: RouteConstants.COACH_CHANGE_PASSWORD,
      state: undefined,
    });
    trackCustomEvents("ClickEvent", { event: "Change Password" });
  };

  const handleHelpDeskClick = () => {
    handleClose();
    props.history.push({
      pathname: RouteConstants.COACH_HELP_DESK,
      state: undefined,
    });
    trackCustomEvents("ClickEvent", { event: "Help Desk" });
  };

  const handleFAQClick = () => {
    handleClose();
    props.history.push({
      pathname: RouteConstants.COACH_FAQ,
      state: undefined,
    });
    trackCustomEvents("ClickEvent", { event: "FAQ" });
  };

  const handleToggleProfileAndDashboard = () => {
    handleClose();
    let pathname = RouteConstants.COACH_PROFILE_VIEW;
    if (props.location && props.location.pathname && props.location.pathname !== RouteConstants.DASHBOARD) {
      pathname = RouteConstants.DASHBOARD;
    }
    props.history.push({
      pathname,
      state: undefined,
    });
    trackCustomEvents("ClickEvent", { event: "ProfileAndDashboard" });
  }

  const handleToggleCoachProfileAndDashboard = () => {
    handleClose();
    let pathname = RouteConstants.COACH_PROFILE_VIEW;
    if (props.location && props.location.pathname && props.location.pathname !== RouteConstants.COACH_DASHBOARD) {
      pathname = RouteConstants.COACH_DASHBOARD;
    }
    props.history.push({
      pathname,
      state: undefined,
    });
    trackCustomEvents("ClickEvent", { event: "CoachProfileAndDashboard" });
  }

  const handleProfileViewCLick = () => {

    if (userRole === Role.Coach) {
      handleClose();
      let pathname = RouteConstants.COACH_PROFILE_VIEW;
      props.history.push({
        pathname,
        state: undefined,
      });

    }
    trackCustomEvents("ClickEvent", { event: "ProfileView" });

  }

  const { refreshFilterData } = useContext(FilterContext)
  const handleLogOut = () => {
    handleClose();
    refreshFilterData();
    props.history.push({
      pathname: RouteConstants.COACH_LOGOUT,
      state: undefined,
    });
    trackCustomEvents("ClickEvent", { event: "Log Out" });
  }
  const handleDashboardClick = () => {
    handleClose();
    props.history.push({
      pathname: RouteConstants.DASHBOARD,
      state: undefined,
    });
    trackCustomEvents("ClickEvent", { event: "Dashboard" });
  }
  const handleUserManagementClick = () => {
    handleClose();
    props.history.push({
      pathname: RouteConstants.USER_MANAGEMENT,
      state: undefined,
    });
    trackCustomEvents("ClickEvent", { event: "User Management" });
  }

  const handleUserRoleClick = () => {
    handleClose();
    props.history.push({
      pathname: RouteConstants.USER_ROLE,
      state: undefined,
    });
  }

  const handleInviteUserClick = () => {
    handleClose();
    props.history.push({
      pathname: RouteConstants.INVITE_USER,
      state: undefined,
    });
    trackCustomEvents("ClickEvent", { event: "Invite User" });
  }

  const handleCoachesManagementClick = () => {
    handleClose();
    props.history.push({
      pathname: RouteConstants.COACH_MANAGEMENT,
      state: undefined,
    });
    trackCustomEvents("ClickEvent", { event: "Coach Management" });
  };

  const handleAdminDashboardClick = () => {
    handleClose();
    props.history.push({
      pathname: RouteConstants.ADMIN_DASHBOARD,
      state: undefined,
    });
    trackCustomEvents("ClickEvent", { event: "Admin Dashboard" });
  };
  const handleAdminMemoClick = () => {
    handleClose();
    props.history.push({
      pathname: RouteConstants.ADMIN_MEMO,
      state: undefined,
    });
    trackCustomEvents("ClickEvent", { event: "Admin Memo" });
  };
  const handleAdminShowcaseClick = () => {
    handleClose();
    props.history.push({
      pathname: RouteConstants.ADMIN_SHOWCASE,
      state: undefined,
    });
    trackCustomEvents("ClickEvent", { event: "Admin Showcase" });
  };
  const handleAdminGroupClick = () => {
    handleClose();
    props.history.push({
      pathname: RouteConstants.ADMIN_GROUP,
      state: undefined,
    });
    trackCustomEvents("ClickEvent", { event: "Admin Group" });
  };

  const handleMediaManagementClick = () => {
    handleClose();
    props.history.push({
      pathname: RouteConstants.MEDIA_MANAGEMENT,
    });
    trackCustomEvents("ClickEvent", { event: "Media Management" });
  };

  const closeShowcaseDialog = () => {
    setShowcaseDialog(false);
  };

  const handleAdminDataClick = () => {
    handleClose();
    props.history.push({
      pathname: RouteConstants.ADMIN_DATA,
      state: undefined,
    });
  };

  return (
    <AppBar id="header" position="static" className={classes.appBar}>
      <Toolbar>
        {showcaseDialog && (<ActiveShowCaseDialog open={true} closeShowcaseDialog={closeShowcaseDialog}></ActiveShowCaseDialog>)}
        {/* <div className="appbar-nba-logo appbar-nba-logo-rightMargin"></div> */}
        <Typography variant="subtitle1" className={`${classes.title} headerTitle`}>
          <span className={userRole == Role.Coach && classes.coachTitle} onClick={handleProfileViewCLick} >NBA Coaches Database</span>
        </Typography>
        <div className="appbar-nba-logo appbar-nba-logo-rightMargin"></div>
        <div className="appbar-nba-logo-rightMargin nbcaLogo"><img src={nbca} />
        </div>
        {!isUserOnLoginScreen() && !isUserOnsignUpScreen() && (
          <div className="menuButtons">
            {(userRole == Role.Admin || (userRole == Role.Coach && status === "approved")) &&
              <IconButton
                aria-label={CONST_COACHES_COMMUNITY}
                aria-controls={CONST_COACHES_COMMUNITY}
                aria-haspopup="true"
                onClick={handleSocialCommunityClick}
                color="inherit"
              >
                <Tooltip title={CONST_COACHES_COMMUNITY}>
                  <ChatBubbleOutlineOutlined />
                </Tooltip>
              </IconButton>
            }
            {userRole == Role.Admin &&
              <IconButton
                aria-label="Settings"
                aria-controls="Settings"
                aria-haspopup="true"
                onClick={handleSettings}
                color="inherit"
              ><Tooltip title="Settings">
                  <SettingsIcon /></Tooltip>
              </IconButton>
            }
            {showNotification &&
              <Tooltip title="Current Coach Showcase">
                <IconButton
                  aria-label="Showcase"
                  aria-controls="ShowCase"
                  aria-haspopup="true"
                  onClick={handleShowcase}
                  color="inherit"
                >
                  <CreateShowcaseIcon />
                </IconButton></Tooltip>
            }
            {!props.onlyHeader &&
              <IconButton
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
            }
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              keepMounted
              open={open}
              onClose={handleClose}
            >
              <List className={classes.list}>
                <ListItem
                  className={classes.menu}
                  style={{ paddingBottom: "20px" }}
                >
                  <ListItemAvatar>
                    <Avatar onClick={handleProfileViewCLick} className={userRole === Role.Coach ? classes.coachAvatar : classes.avatar}>{Helper.GetNameInitials(userService.getUserFullName())}</Avatar>
                  </ListItemAvatar>
                  <ListItemText id="user-name" primary={userService.getUserFullName()} />
                </ListItem>

                <Divider></Divider>
                {/* <ListItem className={classes.menu}>
                  <ListItemIcon className={classes.itemIcon}>
                    <Brightness4OutlinedIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText id="dark-theme" primary="Dark Mode" />
                  <ListItemSecondaryAction>
                    <Switch
                      edge="end"
                      onChange={handleThemeChange}
                      checked={props.currentThemeType === "dark"}
                      inputProps={{ "aria-labelledby": "switch-dark-theme" }}
                    />
                  </ListItemSecondaryAction>
                </ListItem> */}
                {userRole == Role.FrontOfficePlusCoach && linkTextForProfile &&
                  <>
                    <MenuItem onClick={handleToggleProfileAndDashboard}>
                      <ListItemIcon className={classes.itemIcon}>
                        {props.location && props.location.pathname && props.location.pathname !== RouteConstants.DASHBOARD &&
                          <DashboardIcon fontSize="small" />
                        }
                        {props.location && props.location.pathname && props.location.pathname === RouteConstants.DASHBOARD &&
                          <AccountCircle fontSize="small" />
                        }
                      </ListItemIcon>
                      <ListItemText primary={linkTextForProfile} />
                    </MenuItem>
                  </>
                }

                {userRole == Role.Coach && linkTextForProfileCoach && canISeeCoachingTree != undefined && canISeeCoachingTree === 'true' &&
                  <>
                    <MenuItem onClick={handleToggleCoachProfileAndDashboard}>
                      <ListItemIcon className={classes.itemIcon}>
                        {props.location && props.location.pathname && props.location.pathname !== RouteConstants.COACH_DASHBOARD &&
                          <DashboardIcon fontSize="small" />
                        }
                        {props.location && props.location.pathname && props.location.pathname === RouteConstants.COACH_DASHBOARD &&
                          <AccountCircle fontSize="small" />
                        }
                      </ListItemIcon>
                      <ListItemText primary={linkTextForProfileCoach} />
                    </MenuItem>
                  </>
                }

                {userRole == Role.FrontOffice &&
                  <>
                    <MenuItem onClick={handleDashboardClick}>
                      <ListItemIcon className={classes.itemIcon}>
                        <DashboardIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText primary="Dashboard" />
                    </MenuItem>
                  </>
                }
                {(userRole == Role.Admin) &&
                  <>
                    <MenuItem onClick={handleAdminDashboardClick}>
                      <ListItemIcon className={classes.itemIcon}>
                        <PieChartIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText primary="Coach Analytics" />
                    </MenuItem>
                    <MenuItem onClick={handleUserManagementClick}>
                      <ListItemIcon className={classes.itemIcon}>
                        <PortraitIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText primary="User Management" />
                    </MenuItem>
                    <MenuItem onClick={handleCoachesManagementClick}>
                      <ListItemIcon className={classes.itemIcon}>
                        <SportsBasketballIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText primary="Coaches Management" />
                    </MenuItem>
                    <MenuItem onClick={handleMediaManagementClick}>
                      <ListItemIcon className={classes.itemIcon}>
                        <VideoLibraryOutlined fontSize="small" />
                      </ListItemIcon>
                      <ListItemText primary="Media Management" />
                    </MenuItem>
                    <MenuItem onClick={handleInviteUserClick}>
                      <ListItemIcon className={classes.itemIcon}>
                        <InsertInvitationIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText primary="Invite User" />
                    </MenuItem>
                    <MenuItem onClick={handleAdminMemoClick}>
                      <ListItemIcon className={classes.itemIcon}>
                        <ForumIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText primary="Manage Memos" />
                    </MenuItem>
                    <MenuItem onClick={handleAdminShowcaseClick}>
                      <ListItemIcon className={classes.itemIcon}>
                        <CreateShowcaseIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText primary="Manage Showcase" />
                    </MenuItem>
                    <MenuItem onClick={handleAdminGroupClick}>
                      <ListItemIcon className={classes.itemIcon}>
                        <GroupIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText primary="Manage Groups" />
                    </MenuItem>
                    <MenuItem onClick={handleAdminDataClick}>
                      <ListItemIcon className={classes.itemIcon}>
                        <StorageIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText primary="Manage Data" />
                    </MenuItem>
                  </>
                }
                {(userRole == Role.FrontOffice || userRole == Role.Coach || userRole == Role.FrontOfficePlusCoach)
                  &&
                  <>
                    <MenuItem onClick={handleMyMemoClick}>
                      <ListItemIcon className={classes.itemIcon}>
                        <AccountCircle fontSize="small" />
                      </ListItemIcon>
                      <ListItemText primary="My Memos" />
                    </MenuItem>
                  </>
                }
                <MenuItem onClick={handleUpdateEmailClick}>
                  <ListItemIcon className={classes.itemIcon}>
                    <EmailOutlinedIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary="Update Email" />
                </MenuItem>

                <MenuItem onClick={handleChangePasswordClick}>
                  <ListItemIcon className={classes.itemIcon}>
                    <VpnKeyOutlinedIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary="Change Password" />
                </MenuItem>

                <MenuItem onClick={handleHelpDeskClick}>
                  <ListItemIcon className={classes.itemIcon}>
                    <ContactSupportOutlinedIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary="Help Desk" />
                </MenuItem>

                <MenuItem onClick={handleFAQClick}>
                  <ListItemIcon className={classes.itemIcon}>
                    <LiveHelpOutlinedIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary="FAQ" />
                </MenuItem>


                <MenuItem onClick={handleLogOut}>
                  <ListItemIcon className={classes.itemIcon}>
                    <ExitToAppIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary="Log out" />
                </MenuItem>
              </List>
              <Divider></Divider>
              {/* <ListItemText
                className={classes.version}
                primary={
                  <Typography variant="caption">
                    version: 0.0.8754.4543
                  </Typography>
                }
              /> */}
            </Menu>
          </div>
        )}
        {isUserOnLoginScreen() && (
          <Button color="inherit" onClick={handleRegistrationClick}>
            Sign Up
          </Button>
        )}
        {isUserOnsignUpScreen() && (
          <Button color="inherit" onClick={handleSignInClick}>
            Login
          </Button>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default withRouter(AppHeaderBar);
