
import React, { useState, useEffect, useRef, useContext } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Drawer from "@material-ui/core/Drawer";
import Loader from "../../sharedComponents/Loader/Loader";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Toolbar from "@material-ui/core/Toolbar";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Hidden from "@material-ui/core/Hidden";
import Tooltip from "@material-ui/core/Tooltip";
import ListContainer from "./UserListContainer";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";

import { userService } from "../../services/user.service";
import AdminService from "../../services/admin.service";

import AppConstants from "../../common/AppConstants";
import RouteConstants from "../../common/RouteConstants";

import { FilterUserList } from "./userFilterHelper";
import FilterIcon from '../../sharedComponents/customIcons/FilterIcon';
import DiscardGroupIcon from "../../sharedComponents/customIcons/DiscardGroup";

import SmartSearch from "../../sharedComponents/smartSearch/SmartSearch";
import SnackbarMsg from "../../sharedComponents/Snackbar/Snackbar";
import CreateGroupDialog from "../../sharedComponents/dialog/CreateGroupDialog";
import ChangePasswordDialog from "../changePassword/ChangePasswordDialog";
import {
    UserProvider,
    UserConsumer,
    UserContext,
} from "../../common/UserContext";
import { FilterContext } from "../../common/FilterContext";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import IconButton from "@material-ui/core/IconButton";
import Divider from "@material-ui/core/Divider";
import SwipeableViews from "react-swipeable-views";
import Pagination from "../../sharedComponents/Pagination/Pagination";

import CardAccountDetailsStarOutline from "../../sharedComponents/customIcons/CardAccountDetailsStarOutline";
import CardAccountDetailsOutline from "../../sharedComponents/customIcons/CardAccountDetailsOutline";
import SortAlphabeticalAscendingVariant from "../../sharedComponents/customIcons/SortAlphabeticalAscendingVariant";
import QueueOutlinedIcon from "@material-ui/icons/QueueOutlined";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import EditUserDialogs from "../../sharedComponents/dialog/EditUserDialog";
import arrow_toggle_transparent from "../../images/arrow_toggle_transparent.png";
import CreateGroupIcon from "../../sharedComponents/customIcons/CreateGroupIcon";
import ClearIcon from "@material-ui/icons/Clear";
import { Role } from "../../helper/role";
import PasswordValidationSchema from "../../validationSchema/PasswordValidationSchema";

const drawerWidth = 260;
const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        zIndex: theme.zIndex.appBar - 1,
        width: drawerWidth,
        background: "transparent",
        borderRight: "none",
    },
    drawerContainer: {
        background: "#06255B",
        // marginTop: theme.spacing(2),
        marginBottom: theme.spacing(0),
        marginLeft: theme.spacing(0),
        marginRight: theme.spacing(0.5),
        boxShadow: "0px 0px 5px 3px rgba(0,0,0,0.1)",
        overflow: "auto",
        height: "100%",
        "&::-webkit-scrollbar": {
            width: "5px",
        },
        "&::-webkit-scrollbar-track": {
            boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
            webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
        },
        "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#bdbdbd",
            outline: "1px solid slategrey",
        },
        [theme.breakpoints.down("sm")]: {
            marginTop: theme.spacing(1),
        },
    },
    content: {
        width: "100%",
        margin: theme.spacing(2),
        boxShadow: "0px 0px 5px 3px rgba(0,0,0,0.1)",
        backgroundColor: "#ffffff",
        zIndex: '2',
        [theme.breakpoints.down("md")]: {
            width: "calc(100%  - 292px)",
        },
        [theme.breakpoints.down("sm")]: {
            margin: theme.spacing(5, 0, 2, 0),
            width: '100%',
        },
    },
    tabsBar: {
        justifyContent: 'space-evenly'
    },
    tab: {
        fontSize: theme.typography.pxToRem(12),
    },
    hidetab: {
        display: 'none'
    },
    toolbar: {
        [theme.breakpoints.down("xs")]: {
            paddingLeft: 0,
            paddingRight: 0,
        },
    },
    sorting: {
        '& > div': {
            marginLeft: theme.spacing(1),
            '& > svg': {
                color: '#ffffff',
            },
            '& > div': {
                backgroundColor: '#1d428a !important',
                borderRadius: '4px !important',
                color: '#ffffff',
            },
        }
    },
    Pagination: {
        '& > div': {
            marginLeft: theme.spacing(1),
            [theme.breakpoints.down("xs")]: {
                marginLeft: theme.spacing(0),
            },
            '& > svg': {
                color: '#ffffff',
            },
            '& > div': {
                backgroundColor: '#1d428a !important',
                borderRadius: '4px !important',
                color: '#ffffff',
            },
        },
    },
    sort: {
        display: "flex",
        alignItems: "center",
        width: "100%",
    },
    sortBy: {
        [theme.breakpoints.up("sm")]: {
            margin: theme.spacing(1),
            width: "25ch",
        },
        [theme.breakpoints.down("xs")]: {
            margin: theme.spacing(1),
            width: "18ch",
        },
    },
    viewAs: {
        minWidth: "fit-content",
        marginRight: theme.spacing(1),
    },
    tabsColor: {
        '& button': {
            color: '#ffffff !important',
        },
    },
    iconAvatar2: {
        width: theme.spacing(1.75),
        height: theme.spacing(2.25),

        //border: "2px solid #e8e8e8",
    },
    filterIcon: {
        [theme.breakpoints.down("sm")]: {
            top: '68px',
            left: '0',
        },
        [theme.breakpoints.down("xs")]: {
            top: '60px',
            left: '0',
        },
        position: 'absolute',
        padding: theme.spacing(0),
        zIndex: '1',
    },
}));

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`scroll-tabpanel-${index}`}
            aria-labelledby={`scroll-tab-${index}`}
            {...other}
        >
            {value === index && <div>{children}</div>}
        </Typography>
    );
}


TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `scroll-tab-${index}`,
        "aria-controls": `scroll-tabpanel-${index}`,
    };
}

const UserContainer = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const [isLoading, setIsLoading] = React.useState(true);
    const [filteredProfileList, setFilterProfile] = useState([]);
    const [tabIndex, setTabIndex] = useState(0);
    const [selectSort, setSelectSort] = useState("Name");
    const [sortOrder, setSortOrder] = useState("asc");
    const [pageSize, setPageSize] = useState(12);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchedText, setSearchedText] = useState("");
    const [filterView, setFilterView] = useState();
    const [selectedStatus, setSelectedStatus] = useState([]);
    const [selectedUsers, setselectedUsers] = useState([]);
    const [showGroupButton, setShowGroupButton] = useState(false);
    const [showGroupDialog, setShowGroupDialog] = useState(false);
    const [groupUsers, setGroupUsers] = useState([]);
    const [showError, setShowError] = useState(false);
    const [severity, setSeverity] = useState("");
    const [message, setMessage] = useState("");

    const {
        userList,
        error,
        isProcessing,
        refreshUserList,
    } = useContext(UserContext);
    const { updateGroup, userGroup } = useContext(FilterContext)

    const [group, setGroup] = useState(userGroup);

    useEffect(() => {
        if (error && error !== "") {
            setIsLoading(false);
            console.log(error);
        }
    }, [error]);

    useEffect(() => {
        if (group) {
            setGroupUsers(group.users);
            setselectedUsers(group.users);
            setShowGroupButton(true);
            setTabIndex(4);

            var grUsers = userList.filter((user) => {
                return ((user.role === Role.FrontOffice || user.role === Role.FrontOfficePlusCoach)
                    && (groupUsers && groupUsers.includes(user.userName)))
            });
            setFilterProfile(grUsers);
        }
        else
            setShowGroupDialog(false);
    }, [group]);

    const handleDiscardGroup = () => {
        setselectedUsers([]);
        setShowGroupButton(false);
        updateGroup(undefined);
        setGroup(undefined);
        setGroupUsers([]);
        setShowGroupDialog(false);
        setTabIndex(1);
        setFilterProfile([]);
        setIsLoading(true);
        setFilterView(AppConstants.USER_SECTION_ID.FrontOffice);
    }
    useEffect(() => {
        if (group) {
            var grUsers = userList.filter((user) => {
                return ((user.role === Role.FrontOffice || user.role === Role.FrontOfficePlusCoach)
                    && (groupUsers && groupUsers.includes(user.userName)))
            });
            setFilterProfile(grUsers);
        }
        else
            setFilterProfile(userList);

        if (isProcessing && isProcessing == true) {
            //hack to show loader till details are fetched..
            setIsLoading(true);

            //  handleFilterSearchClick();
        }
        else {
            setIsLoading(false);
        }
    }, [userList, isProcessing]);

    const handleStatusChange = (status, userName) => {
        let postData = { status: status, userName: userName };
        userService.changeStatus(postData).then((data) => {
        });
    };

    const handleChange = (event, newValue) => {
        setTabIndex(newValue);
        setFilterProfile([]);
        setIsLoading(true);
        if (newValue == 0) setFilterView(AppConstants.USER_SECTION_ID.All);
        if (newValue == 1)
            setFilterView(AppConstants.USER_SECTION_ID.FrontOffice);
        if (newValue == 2)
            setFilterView(AppConstants.USER_SECTION_ID.Coach);
        if (newValue == 3)
            setFilterView(AppConstants.USER_SECTION_ID.Admin);
        if (newValue == 4)
            setFilterView(AppConstants.USER_SECTION_ID.Group);
    };

    function isXsDown() {
        return useMediaQuery(theme.breakpoints.down("xs"));
    }

    const handleChecked = (e, name, item) => {
        setFilterProfile([]);
        setIsLoading(true);
        let selected = [];

        if (name === "status") {
            selected = selectedStatus;
            if (e.target.checked) selected.push(item);
            else {
                let index = selected.indexOf(item);
                selected.splice(index, 1);
            }
            setSelectedStatus[selected];
        }

    };

    const handleChipDelete = (name, item) => {
        setFilterProfile([]);
        setIsLoading(true);
        let selected = [];
        if (name === "Status") {
            selected = selectedStatus;
            let index = selected.indexOf(item);
            selected.splice(index, 1);
            setSelectedStatus[selected];
        }
    }

    const handleClearAllFilter = function () {
        setFilterProfile([]);
        setIsLoading(true);
        setSearchedText("");
        setSelectedStatus([]);
    };


    const handleChangeSortBy = (e) => {
        setSelectSort(e.target.value);
        setFilterProfile([]);
        setIsLoading(true);
        setselectedUsers([]);
        //handleFilterSearchClick();
    };

    const handleSortOrder = () => {
        setFilterProfile([]);
        setIsLoading(true);
        setselectedUsers([]);
        if (sortOrder === "asc") {
            setSortOrder("desc");
        } else {
            setSortOrder("asc");
        }
    };


    const handleChangeIndex = (index) => {
        setTabIndex(index);
        setFilterProfile([]);
        setIsLoading(true);
        setFilterView(index);
        //setCurrentPage(1);
    };

    const handleSearchClick = () => {
        setFilterProfile([]);
        setIsLoading(true);
    };

    useEffect(() => {
        if (userList.length > 0 && isLoading) handleFilterSearchClick();
    }, [filteredProfileList]);

    const handleFilterSearchClick = () => {
        FilterUserList(
            userList,
            {
                searchedText,
                selectSort,
                sortOrder,
                filterView,
                selectedStatus,
            },
            groupUsers,
            (list) => {
                setFilterProfile(list);
                setIsLoading(false);
                setCurrentPage(1);
            }
        );
    };

    let indexOfLastProfile = currentPage * pageSize;
    let indexOfFirstProfile = currentPage * pageSize - pageSize;
    let profilesToDisplay = filteredProfileList.slice(
        indexOfFirstProfile,
        indexOfLastProfile
    );

    const pageSizeOption = ["12", "24", "48", "72"];
    const sortOptions = ["Name", "Username", "Email"];

    const [openEditPopup, setOpenEditPopup] = useState({ open: false, data: {} });
    const [openChangePasswordPopup, setOpenChangePasswordPopup] = useState({ open: false, data: {} });

    const onEditClick = (user) => {
        setOpenEditPopup({ open: true, data: user });
    }
    const onChangePasswordClick = (user) => {
        setOpenChangePasswordPopup({ open: true, data: user });
    }

    const onSubmitChangePassword = (values, userName) => {

        let valid = PasswordValidationSchema.ValidatePassword(values.newPassword);
        if (!valid) {
            setShowError(true);
            setSeverity("error");
            setMessage("Password should be minimum 8 and maximum 50 characters, Password should contain at least one uppercase , one lowercase, one digit, Password should not contain space(s).")
        }
        else {
            if (values.confirmPassword == "" || values.confirmPassword == undefined || values.confirmPassword == null) {
                setShowError(true);
                setSeverity("error");
                setMessage("Please enter confirm password.")
                return;
            }
            else if (values.newPassword != values.confirmPassword) {
                setShowError(true);
                setSeverity("error");
                setMessage("Password & confirm password does not match.")
                return;
            } else {
                setIsLoading(true);
                userService.changePasswordByAdmin(values.currentPassword, values.newPassword, userName)
                    .then((data) => {
                        setOpenChangePasswordPopup({ open: false, data: null });
                        values.currentPassword = "";
                        values.newPassword = "";
                        values.confirmPassword = "";
                        setIsLoading(false);
                        if (typeof data == "string") {
                            showAlert("error", data);
                        }
                        else {
                            setShowError(true);
                            setSeverity("success");
                            setMessage("Password Changed Successfully")
                        }
                    })
                    .catch((err) => {
                        values.currentPassword = "";
                        values.newPassword = "";
                        values.confirmPassword = "";
                        setIsLoading(false);
                        setShowError(true);
                        setSeverity("error");
                        setMessage(err);
                    })
            }
        }

    };



    useEffect(() => {
        updateGroup(undefined);
        return;
    })

    function onUserUpdate(postData) {
        userService.updateUser(postData)
            .then((data) => {
                setOpenEditPopup({ open: false, data: {} });
                showAlert("success", "User updated successfully");
                refreshUserList(data);
            })
            .catch((err) => {
                showAlert("error", err);
            });
    }

    function handleSnackbarClose() {
        setShowError(false);
        setSeverity("")
        setMessage("");
    }

    function showAlert(sever, msg) {
        setShowError(true);
        setSeverity(sever)
        setMessage(msg);

    }

    const [openDrawer, setOpenDrawer] = useState(false);
    const handleOpenDrawer = () => {
        setOpenDrawer(true);
    }
    function handleGroupClick() {
        setShowGroupDialog(true);
    }

    function handleGroupClear() {
        setShowGroupButton(false);
        setselectedUsers([]);
    }
    const handleUserChecked = (e, userId) => {
        let selected = [...selectedUsers];
        if (e.target.checked) selected.push(userId);
        else {
            let index = selected.indexOf(userId);
            selected.splice(index, 1);
        }
        setselectedUsers(selected);
        if (selected.length > 0)
            setShowGroupButton(true);
        else if (selected.length == 0)
            setShowGroupButton(true);
        else
            setShowGroupButton(false);
    }

    const handleAllUserChecked = (e) => {
        let selected = [...selectedUsers];
        if (e.target.checked) {
            var currPageUsers = [];
            if (profilesToDisplay && profilesToDisplay.length > 0) {
                currPageUsers = profilesToDisplay.map((u) => u.userName);
            }
            selected = [...selected, ...currPageUsers];
            setselectedUsers(selected);
        }
        else {
            var removeCurr = selected.filter((u) => !profilesToDisplay.map((p) => p.userName).includes(u));
            setselectedUsers(removeCurr);
        }
        if (selected.length > 0)
            setShowGroupButton(true);
        else if (selected.length == 0)
            setShowGroupButton(true);
        else
            setShowGroupButton(false);
    }

    function saveGroup(name, showAsTAB, description) {
        if (name && name.trim().length > 0) {
            let userGroup = group ? group : {};
            userGroup.name = name;
            userGroup.type = AppConstants.Static;
            userGroup.rules = selectedUsers.length > 0 ? null : JSON.stringify(rules),
                userGroup.users = selectedUsers,
                userGroup.isFrontOfficeGroup = 1,
                userGroup.description = description && description.trim();
            userGroup.showAsTAB = showAsTAB;
            userGroup.isActive = 1
            AdminService.saveGroup(userGroup).then((data) => {
                if (!data) {
                    showAlert("error", "Group already exists.");
                }
                else if (typeof data == "string") {
                    showAlert("error", data);
                }
                else {
                    showAlert("success", 'Group ' + (group ? "updated" : "created") + ' successfully.');
                    setShowGroupButton(false);
                    setselectedUsers([]);
                    setGroup(undefined);
                    updateGroup(undefined);
                    setTimeout(() => {
                        props.redirectToGroupList();
                    }, 800);
                }
            });
        }
        else
            showAlert("error", "Please enter Group Name.");
    }


    const handleChangePassword = () => {

    }

    return (
        <>
            <Hidden xsDown smDown>
                <Drawer
                    className={classes.drawer}
                    variant="permanent"
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                >
                    <Toolbar />
                    <div className={classes.drawerContainer}>
                        <SmartSearch
                            handleDrawerClose={() => setOpenDrawer(false)}
                            searchForUser={true}
                            searchedText={searchedText}
                            handleTextchange={() => setSearchedText(event.target.value)}
                            handleClearText={() => {
                                setSearchedText("");
                                handleSearchClick();
                            }}
                            handleChipDelete={handleChipDelete}
                            handleClearAllFilter={handleClearAllFilter}
                            handleChecked={handleChecked}
                            selectedStatus={selectedStatus}
                            onChange={handleSearchClick}
                            showAndOrFilter={false}
                            searchObject={(searchObject) => {
                                //console.log("******************* User Main Container 1 -> ", searchObject);
                            }}
                            handleLoading={() => setIsLoading(true)}
                            disableSmartSearch={true}
                        ></SmartSearch>
                    </div>
                </Drawer>
            </Hidden>


            <Hidden mdUp>
                <IconButton className={classes.filterIcon} onClick={() => setOpenDrawer(!openDrawer)}><FilterIcon fontSize="large" color="primary" /></IconButton>
            </Hidden>
            <div className={classes.content}>
                <AppBar position="static" color="primary" elevation={1}>
                    <Tabs
                        value={tabIndex}
                        onChange={handleChange}
                        scrollButtons="auto"
                        indicatorColor="primary"
                        textColor="primary"
                        variant="scrollable"
                        aria-label="profile tabs"
                        className={classes.tabsColor}
                    >
                        <Tab
                            className={classes.tab}
                            icon={<CardAccountDetailsOutline />}
                            label={
                                !isXsDown() && AppConstants.USER_MANAGEMENT_TITLE.ALLUser
                            }
                            {...a11yProps(0)}
                        />
                        <Tab
                            className={classes.tab}
                            icon={<CardAccountDetailsOutline />}
                            label={
                                !isXsDown() &&
                                AppConstants.USER_MANAGEMENT_TITLE.FRONTOFFICE_USER
                            }
                            {...a11yProps(1)}
                        />
                        <Tab
                            className={classes.tab}
                            icon={<CardAccountDetailsOutline />}
                            label={
                                !isXsDown() &&
                                AppConstants.USER_MANAGEMENT_TITLE.COACH_USER
                            }
                            {...a11yProps(2)}
                        />
                        <Tab
                            className={classes.tab}
                            icon={<CardAccountDetailsOutline />}
                            label={
                                !isXsDown() &&
                                AppConstants.USER_MANAGEMENT_TITLE.ADMIN_USER
                            }
                            {...a11yProps(3)}
                        />
                        <Tab
                            className={group ? classes.tab : classes.hidetab}
                            icon={<CardAccountDetailsOutline />}
                            label={
                                !isXsDown() &&
                                AppConstants.USER_MANAGEMENT_TITLE.GROUP_USER
                            }
                            {...a11yProps(3)}
                        />
                    </Tabs>
                </AppBar>

                <Hidden mdUp>
                    <Drawer
                        className={classes.drawer}
                        variant="persistent"
                        open={openDrawer}
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                    >
                        <Toolbar />
                        <div className={classes.drawerContainer}>
                            <SmartSearch
                                handleDrawerClose={() => setOpenDrawer(false)}
                                searchForUser={true}
                                searchedText={searchedText}
                                handleTextchange={() => setSearchedText(event.target.value)}
                                handleClearText={() => {
                                    setSearchedText("");
                                    handleSearchClick();
                                }}
                                handleChipDelete={handleChipDelete}
                                handleClearAllFilter={handleClearAllFilter}
                                handleChecked={handleChecked}
                                selectedStatus={selectedStatus}
                                onChange={handleSearchClick}
                                showAndOrFilter={false}
                                searchObject={(searchObject) => {
                                    //console.log("******************* User Main Container 2 -> ", searchObject);
                                }}
                                disableSmartSearch={true}
                            ></SmartSearch>
                        </div>
                    </Drawer>
                </Hidden>

                <div>
                    <Toolbar className={classes.toolbar}>
                        <div className={classes.sort}>
                            <Hidden xsDown>
                                <label>Sort by</label>
                            </Hidden>
                            <TextField
                                id="outlined-select-currency"
                                select
                                // label="Sort"
                                value={selectSort}
                                onChange={(event) => handleChangeSortBy(event)}
                                variant="outlined"
                                size="small"
                                style={{ width: "13ch" }}
                                className={classes.sorting}
                            >
                                {sortOptions.map((option) => (
                                    <MenuItem key={option} value={option} className="MenuItem">
                                        {option}
                                    </MenuItem>
                                ))}
                            </TextField>

                            <IconButton
                                aria-label="card view"
                                color="primary"
                                title={sortOrder}
                                className="alphaSort"
                                onClick={handleSortOrder}
                            >
                                <>
                                    <Typography variant="caption" size="small" style={{ fontWeight: "500", fontSize: ".93rem" }} >
                                        A-Z &nbsp;
                                    </Typography><Avatar variant="square" className={classes.iconAvatar2} src={arrow_toggle_transparent}></Avatar></>
                            </IconButton>


                            <Hidden xsDown>
                                <label>Page Size</label>
                            </Hidden>
                            <TextField
                                id="outlined-select-currency"
                                select
                                // label="Page Size"
                                value={String(pageSize)}
                                onChange={(event) => setPageSize(parseInt(event.target.value))}
                                variant="outlined"
                                size="small"
                                style={{ width: "10ch" }}
                                className={classes.Pagination}
                            >
                                {pageSizeOption.map((option) => (
                                    <MenuItem key={option} value={option} className="MenuItem">
                                        {option}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </div>
                        {(tabIndex == AppConstants.USER_SECTION_ID.FrontOffice || tabIndex == AppConstants.USER_SECTION_ID.Group) && showGroupButton && (selectedUsers && selectedUsers.length > 0) &&
                            <Tooltip title={group ? "Update Group" : "Create Group"}>
                                <IconButton onClick={handleGroupClick} >
                                    <CreateGroupIcon></CreateGroupIcon></IconButton>
                            </Tooltip>
                        }
                        {(group && tabIndex == AppConstants.USER_SECTION_ID.FrontOffice || tabIndex == AppConstants.USER_SECTION_ID.Group) &&
                            <Tooltip title="Discard Group">
                                <IconButton onClick={handleDiscardGroup} color="secondary">
                                    <DiscardGroupIcon></DiscardGroupIcon></IconButton>
                            </Tooltip>
                        }
                        {(tabIndex == AppConstants.USER_SECTION_ID.FrontOffice || tabIndex == AppConstants.USER_SECTION_ID.Group) && (selectedUsers && selectedUsers.length > 0) &&
                            <Tooltip title="Clear">
                                <IconButton onClick={handleGroupClear} color="secondary">
                                    <ClearIcon></ClearIcon></IconButton>
                            </Tooltip>
                        }
                    </Toolbar>
                </div>
                <Divider></Divider>
                <SwipeableViews
                    axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                    index={tabIndex}
                    onChangeIndex={onUserUpdate}
                >
                    <TabPanel value={tabIndex} index={0} dir={theme.direction}>
                        <ListContainer
                            userList={profilesToDisplay}
                            handleStatusChange={onUserUpdate}
                            onEditClick={onEditClick}
                            onChangePasswordClick={onChangePasswordClick}
                            handleUserChecked={handleUserChecked}
                            selectedUsers={selectedUsers}
                            showCheckBox={tabIndex == AppConstants.USER_SECTION_ID.FrontOffice}
                            handleAllUserChecked={handleAllUserChecked}
                        ></ListContainer>
                    </TabPanel>
                    <TabPanel value={tabIndex} index={1} dir={theme.direction}>
                        <ListContainer
                            userList={profilesToDisplay}
                            handleStatusChange={onUserUpdate}
                            onEditClick={onEditClick}
                            onChangePasswordClick={onChangePasswordClick}
                            handleUserChecked={handleUserChecked}
                            selectedUsers={selectedUsers}
                            showCheckBox={tabIndex == AppConstants.USER_SECTION_ID.FrontOffice}
                            handleAllUserChecked={handleAllUserChecked}
                        ></ListContainer>
                    </TabPanel>
                    <TabPanel value={tabIndex} index={2} dir={theme.direction}>
                        <ListContainer
                            userList={profilesToDisplay}
                            handleStatusChange={onUserUpdate}
                            onEditClick={onEditClick}
                            handleUserChecked={handleUserChecked}
                            onChangePasswordClick={onChangePasswordClick}
                            selectedUsers={selectedUsers}
                            showCheckBox={tabIndex == AppConstants.USER_SECTION_ID.FrontOffice}
                            handleAllUserChecked={handleAllUserChecked}
                        ></ListContainer>
                    </TabPanel>
                    <TabPanel value={tabIndex} index={3} dir={theme.direction}>
                        <ListContainer
                            userList={profilesToDisplay}
                            handleStatusChange={onUserUpdate}
                            onEditClick={onEditClick}
                            onChangePasswordClick={onChangePasswordClick}
                            handleUserChecked={handleUserChecked}
                            selectedUsers={selectedUsers}
                            showCheckBox={tabIndex == AppConstants.USER_SECTION_ID.FrontOffice}
                            handleAllUserChecked={handleAllUserChecked}
                        ></ListContainer>
                    </TabPanel>
                    {/* {showUserGroupTab && */}
                    <TabPanel value={tabIndex} index={4} dir={theme.direction}>
                        <ListContainer
                            userList={profilesToDisplay}
                            handleStatusChange={onUserUpdate}
                            onEditClick={onEditClick}
                            onChangePasswordClick={onChangePasswordClick}
                            handleUserChecked={handleUserChecked}
                            selectedUsers={selectedUsers}
                            showCheckBox={tabIndex == AppConstants.USER_SECTION_ID.Group}
                            handleAllUserChecked={handleAllUserChecked}
                        ></ListContainer>
                    </TabPanel>

                </SwipeableViews>
                {filteredProfileList.length > pageSize && (
                    <Pagination
                        pageSize={pageSize}
                        totalProfile={filteredProfileList.length}
                        changePageNumber={(e) => {
                            setCurrentPage(e.selected + 1);
                        }}
                        currentPageNo={currentPage}
                    />
                )}
            </div>
            <Loader isLoading={isLoading} />
            <ChangePasswordDialog
                open={openChangePasswordPopup.open}
                data={openChangePasswordPopup.data}
                handleClose={() => {
                    setOpenChangePasswordPopup({ open: false, data: {} });
                }}
                onSubmit={onSubmitChangePassword}
            />
            <EditUserDialogs
                open={openEditPopup.open}
                data={openEditPopup.data}
                onClose={() => {
                    setOpenEditPopup({ open: false, data: {} });
                }}
                onSave={onUserUpdate}
            />
            {showGroupDialog && <CreateGroupDialog
                open={showGroupDialog}
                showFrontOfficeUser={false}
                onClose={() => setShowGroupDialog(false)}
                name={group ? group.name : ''}
                saveGroup={saveGroup}
                description={group ? group.description : ''}
            />
            }
            <SnackbarMsg showError={showError} onClose={handleSnackbarClose} severity={severity} msg={message} />
        </>
    );
};

const UserMainContainer = (props) => {
    const redirectToGroupList = () => {
        props.history.push({
            pathname: RouteConstants.ADMIN_GROUP,
            state: {}
        });
    }
    return (
        <UserProvider>
            <UserConsumer>
                {(props) => {
                    return <UserContainer redirectToGroupList={redirectToGroupList} />;
                }}
            </UserConsumer>
        </UserProvider>
    );
};

export default UserMainContainer;
