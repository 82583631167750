import { makeStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import React, { useState } from "react";
import AdminDashboardAppBar from "./AdminDashboardAppBar";
import AdminDashboardTabPanel from "./AdminDashboardTabPanel";

const useStyles = makeStyles((theme) => ({
    content: {
        margin: theme.spacing(2),
        boxShadow: "0px 0px 5px 3px rgba(0,0,0,0.1)",
        backgroundColor: '#ffffff',
        zIndex: '2',
    },
}));

const AdminDashboardContainer = (props) => {

    const classes = useStyles();
    const [tabIndex, setTabIndex] = useState(0)

    function TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <Typography
                component="div"
                role="tabpanel"
                hidden={value !== index}
                id={`scroll-tabpanel-${index}`}
                aria-labelledby={`scroll-tab-${index}`}
                {...other}
            >
                {value === index && <div p={3}>{children}</div>}
            </Typography>
        );
    }

    TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.any.isRequired,
        value: PropTypes.any.isRequired,
    };

    const handleTabChange = (event, newValue) => {
        setTabIndex(newValue);
    }

    return (
        <div className={classes.content}>
            <AdminDashboardAppBar tabIndex={tabIndex} handleTabChange={handleTabChange} />
            <AdminDashboardTabPanel tabIndex={tabIndex} handleTabChange={handleTabChange} />
        </div >
    )
}

export default AdminDashboardContainer;