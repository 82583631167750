import React, { useEffect, useState } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import TextField from '@material-ui/core/TextField';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
    },
    grid: {
        display: "flex",
        flexGrow: 1,
        flexWrap: "wrap",
        paddingTop: theme.spacing(1),
        backgroundColor: theme.palette.background.default,
        [theme.breakpoints.up("sm")]: {
            padding: theme.spacing(1),
        },
        [theme.breakpoints.down("xs")]: {
            padding: 0,
        },
    },
    deleteTitle: {
        fontWeight: 'bold',
        color: '#fff'
    },
    deleteGrid: {
        display: "flex",
        justifyContent: 'center',
        alignItems: 'center'
    },
    deleteIcon: {
        color: 'red',
        width: '50px',
        height: '50px'
    },
    deleteText: {
        fontWeight: 500,
        border: '1px dashed red',
        padding: '1rem'
    }
}));

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography style={{display: 'flex', justifyContent: 'space-around', fontWeight: 'bold'}}>{children}</Typography>
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
        display: 'flex',
        justifyContent: "left",
        //backgroundColor: "#e6e6e6",
        [theme.breakpoints.down('sm')]: {
            padding: "0.5rem",
        }
    }
}))(MuiDialogContent);

export default function DeleteProfileDialogs(props) {
    const classes = useStyles();

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const handleClose = () => {
        props.onClose();
    };
   
    const DialogActions = withStyles((theme) => ({
        root: {
            margin: 0,
            padding: theme.spacing(1),
        },
    }))(MuiDialogActions);
    return (<div>
       <Dialog onClose={handleClose}
                fullScreen={fullScreen}
                fullWidth
                maxWidth="xs" aria-labelledby="customized-dialog-title" open={props.open}>
                <DialogTitle id="customized-dialog-title" onClose={handleClose} style={{background: 'red', color: '#fff', fontSize: 'bold'}}>
                    <Typography className={classes.deleteTitle}>Are you sure you want to delete this profile?</Typography>
                </DialogTitle>
            <DialogContent>
                <Grid container>
                    <Grid className={classes.deleteGrid}>
                        <Grid>
                            <DeleteIcon className={classes.deleteIcon} />
                        </Grid>
                        <Grid className={classes.deleteText}>
                            Are you sure you want to permanantely delete this profile? Once deleted, you will not be able to recover.
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancel
                </Button>
                <Button color="secondary" onClick={props.onDelete}>
                    Delete Permanently
                </Button>
            </DialogActions>
        </Dialog>
    </div>)
}
