import React, { useEffect, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import ClearIcon from "@material-ui/icons/Clear";
const useStyles = makeStyles((theme) => ({
    checkBox: {
        minWidth: 0,
        maxWidth: '200px',
        '& label > span': {
            padding: theme.spacing(.75, .5),
            alignSelf: 'start',
            lineHeight: '1.1',
            '& svg': {
                width: '.8em',
                height: '.8em',
            },
        }
    },
    listItem: {
        paddingLeft: '30px',
        paddingTop: 0,
        paddingBottom: 0
    }
}));

export default function ExpandFilter(props) {
    const classes = useStyles();
    const [searchedText, setSearchText] = useState("");
    const [list, setList] = useState(props.list ? props.list : []);
    useEffect(() => {
        setList(props.list);
        setSearchText(props.searchedText);
        if (props.searchedText != "" || props.searchedText != undefined)
            filterList(props.searchedText)
    }, [props])

    useEffect(() => {
        filterList(searchedText)
    }, [searchedText])

    function filterList(Text) {
        let filteredList = props.list;
        if (Text != "") {
            filteredList = filteredList.filter((item) => {
                if ((item.Name ? item.Name : item).toLowerCase().includes(Text.toLowerCase()))
                    return item
            })
        }
        setList(filteredList);
    }

    const onChangeText = (text) => {
        setSearchText(text);
        props.onWithinFilterSearchedTextchange(props.Name, text);
    }

    return (
        <>
            {props.Name != "international" && props.Name != "expType" &&
                <ListItem>
                    <TextField
                        fullWidth
                        value={searchedText}
                        onChange={(event) => onChangeText(event.target.value)}
                        style={{background:"#fff"}}
                        size="small"
                        variant="outlined"
                        id="Search"
                        placeholder="Search"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    {searchedText != "" && (
                                        <>
                                            <ClearIcon
                                                fontSize="small"
                                                onClick={() => onChangeText("")}
                                                style={{ cursor: "pointer" }}
                                            ></ClearIcon>
                                        </>
                                    )}
                                </InputAdornment>
                            ),
                        }}
                    />
                </ListItem>}
            {props.Name == "team" &&
                <ListItem>
                    <Typography variant="caption" style={{ fontStyle: 'italic',color:"#fff" }} display="block" size="small">(Only NBA/WNBA/G League Teams)</Typography>
                </ListItem>
            }
            {list.map((item, index) => {
                return (
                    <ListItem key={index} role={undefined} dense button className={classes.listItem}>
                        <ListItemIcon className={classes.checkBox}>
                            <FormControlLabel
                                control={<Checkbox
                                    onChange={() => props.onChange(event, props.Name, item, searchedText)}
                                    edge="start"
                                    style={{color:"#fff"}}
                                    checked={props.selectedItem ? props.selectedItem.includes(item.Name ? item.Name : item) : false}
                                    inputProps={{ 'aria-labelledby': `checkbox-list-label-${props.index}` }}
                                    disabled = {props.disabled ? props.disabled : false}
                                />}
                                label={<Typography style={{color:"#fff"}} variant="caption">{item.Name ? item.Name : props.getLeagueTextByValue(item)}</Typography>} />
                        </ListItemIcon>
                    </ListItem>)
            })
            }
        </>)
}