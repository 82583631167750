import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

function UploadIcon(props) {
    return (
        <SvgIcon {...props}>
            <path d="M9,16V10H5L12,3L19,10H15V16H9M5,20V18H19V20H5Z" />
        </SvgIcon>
    );
}

export default UploadIcon;