import { useMediaQuery } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import React from "react";
import AppConstants from "../../common/AppConstants";
import { Role } from "../../helper";
import { userService } from "../../services";
import CardAccountDetailsOutline from "../../sharedComponents/customIcons/CardAccountDetailsOutline";
import OnHoldAprovals from "../../sharedComponents/customIcons/OnHoldAprovals";
import RecentlyUpdated from "../../sharedComponents/customIcons/RecentlyUpdated";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
    },
    tab: {
        fontSize: theme.typography.pxToRem(12),
    },
    hidetab: {
        display: 'none'
    },
    tabsColor: {
        '& button': {
            color: '#ffffff !important',
        },
    }
}));


const SocialCommunityAppBar = (props) => {

    const classes = useStyles();
    const theme = useTheme();

    const isXsDown = () => {
        return useMediaQuery(theme.breakpoints.down("xs"));
    }

    const a11yProps = (index) => {
        return {
            id: `scroll-tab-${index}`,
            "aria-controls": `scroll-tabpanel-${index}`,
        };
    }

    const userRole = userService.getUserRole();

    return (
        <AppBar position="static" color="primary" elevation={1}>
            <Tabs
                value={props.tabIndex}
                onChange={props.handleTabChange}
                scrollButtons="auto"
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                aria-label="profile tabs"
                className={classes.tabsColor}
            >
                <Tab
                    className={classes.tab}
                    icon={<CardAccountDetailsOutline />}
                    label={
                        !isXsDown() && AppConstants.SOCIAL_COMMUNITY.ALL_POSTS
                    }
                    {...a11yProps(0)}
                />
                {userRole == Role.Admin &&
                    <Tab
                        className={classes.tab}
                        icon={<RecentlyUpdated />}
                        label={
                            !isXsDown() &&
                            AppConstants.SOCIAL_COMMUNITY.PENDING_REVIEW_POSTS
                        }
                        {...a11yProps(1)}
                    />
                }
                {userRole == Role.Admin &&
                    <Tab
                        className={classes.tab}
                        icon={<OnHoldAprovals />}
                        label={
                            !isXsDown() &&
                            AppConstants.SOCIAL_COMMUNITY.RETURNED_POSTS
                        }
                        {...a11yProps(1)}
                    />
                }
                {userRole == Role.Coach &&
                    <Tab
                        className={classes.tab}
                        icon={<RecentlyUpdated />}
                        label={
                            !isXsDown() &&
                            AppConstants.SOCIAL_COMMUNITY.MY_PENDING_REVIEW_POSTS
                        }
                        {...a11yProps(1)}
                    />
                }
            </Tabs>
        </AppBar>
    )
}

export default SocialCommunityAppBar;