import React from "react";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import Typography from "@material-ui/core/Typography";
import AppConstants from "../../common/AppConstants";
import CardContainer from "../dashbord/CardContainer";
import ListContainer from "../dashbord/ListContainer";

const FrontOfficeCoachesTabPanel = (props) => {

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`scroll-tabpanel-${index}`}
        aria-labelledby={`scroll-tab-${index}`}
        {...other}
      >
        {value === index && <div p={3}>{children}</div>}
      </Typography>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };


  return (<SwipeableViews
    axis={props.theme.direction === "rtl" ? "x-reverse" : "x"}
    index={props.tabIndex}
    onChangeIndex={props.handleChangeIndex}
  >
    <TabPanel value={props.tabIndex} index={0} dir={props.theme.direction}>
      {props.view === "card" ? (
        <CardContainer
          userName={props.currentUserName}
          container={AppConstants.DASHBOARD_TYPE.FRONT_OFFICE}
          handleBookMarked={props.handleBookMarked}
          handleOpenDialog={props.handleOpenDialog}
          profileList={props.profilesToDisplay}
          getLeagueTextByValue={props.getLeagueTextByValue}
          handleProfileChecked={props.handleProfileChecked}
          selectedProfiles={props.selectedProfiles}
          handleViewProfileClick={props.handleViewProfileClick}
        ></CardContainer>
      ) : (
        <ListContainer
          userName={props.currentUserName}
          container={AppConstants.DASHBOARD_TYPE.FRONT_OFFICE}
          handleBookMarked={props.handleBookMarked}
          profileList={props.profilesToDisplay}
          handleOpenDialog={props.handleOpenDialog}
          getLeagueTextByValue={props.getLeagueTextByValue}
          handleProfileChecked={props.handleProfileChecked}
          selectedProfiles={props.selectedProfiles}
          handleViewProfileClick={props.handleViewProfileClick}
        ></ListContainer>
      )}
    </TabPanel>
    <TabPanel value={props.tabIndex} index={1} dir={props.theme.direction}>
      {props.view === "card" ? (
        <CardContainer
          userName={props.currentUserName}
          container={AppConstants.DASHBOARD_TYPE.FRONT_OFFICE}
          handleBookMarked={props.handleBookMarked}
          handleOpenDialog={props.handleOpenDialog}
          profileList={props.profilesToDisplay}
          getLeagueTextByValue={props.getLeagueTextByValue}
          handleProfileChecked={props.handleProfileChecked}
          selectedProfiles={props.selectedProfiles}
          handleViewProfileClick={props.handleViewProfileClick}
        ></CardContainer>
      ) : (
        <ListContainer
          userName={props.currentUserName}
          container={AppConstants.DASHBOARD_TYPE.FRONT_OFFICE}
          handleBookMarked={props.handleBookMarked}
          profileList={props.profilesToDisplay}
          handleOpenDialog={props.handleOpenDialog}
          getLeagueTextByValue={props.getLeagueTextByValue}
          handleProfileChecked={props.handleProfileChecked}
          selectedProfiles={props.selectedProfiles}
          handleViewProfileClick={props.handleViewProfileClick}
        ></ListContainer>
      )}
    </TabPanel>
    <TabPanel value={props.tabIndex} index={2} dir={props.theme.direction}>
      {props.view === "card" ? (
        <CardContainer
          userName={props.currentUserName}
          container={AppConstants.DASHBOARD_TYPE.FRONT_OFFICE}
          handleBookMarked={props.handleBookMarked}
          handleOpenDialog={props.handleOpenDialog}
          profileList={props.profilesToDisplay}
          getLeagueTextByValue={props.getLeagueTextByValue}
          handleProfileChecked={props.handleProfileChecked}
          selectedProfiles={props.selectedProfiles}
          handleViewProfileClick={props.handleViewProfileClick}
        ></CardContainer>
      ) : (
        <ListContainer
          userName={props.currentUserName}
          container={AppConstants.DASHBOARD_TYPE.FRONT_OFFICE}
          handleBookMarked={props.handleBookMarked}
          profileList={props.profilesToDisplay}
          handleOpenDialog={props.handleOpenDialog}
          getLeagueTextByValue={props.getLeagueTextByValue}
          handleProfileChecked={props.handleProfileChecked}
          selectedProfiles={props.selectedProfiles}
          handleViewProfileClick={props.handleViewProfileClick}
        ></ListContainer>
      )}
    </TabPanel>

    {props.foTabs && props.foTabs.length > 0 &&
      props.foTabs.map((val, ind) => {
        return (

          <TabPanel value={props.tabIndex} index={3 + ind} dir={props.theme.direction}>
            {props.view === "card" ? (
              <CardContainer
                userName={props.currentUserName}
                container={AppConstants.DASHBOARD_TYPE.FRONT_OFFICE}
                handleBookMarked={props.handleBookMarked}
                handleOpenDialog={props.handleOpenDialog}
                profileList={props.groupUsers}
                getLeagueTextByValue={props.getLeagueTextByValue}
                handleProfileChecked={props.handleProfileChecked}
                selectedProfiles={props.selectedProfiles}
                handleViewProfileClick={props.handleViewProfileClick}
              ></CardContainer>
            ) : (
              <ListContainer
                userName={props.currentUserName}
                container={AppConstants.DASHBOARD_TYPE.FRONT_OFFICE}
                handleBookMarked={props.handleBookMarked}
                profileList={props.groupUsers}
                handleOpenDialog={props.handleOpenDialog}
                getLeagueTextByValue={props.getLeagueTextByValue}
                handleProfileChecked={props.handleProfileChecked}
                selectedProfiles={props.selectedProfiles}
                handleViewProfileClick={props.handleViewProfileClick}
              ></ListContainer>
            )}
          </TabPanel>)
      })
    }

  </SwipeableViews>

  )

}

export default FrontOfficeCoachesTabPanel;