import { ThemeProvider, createMuiTheme, makeStyles } from '@material-ui/core/styles';
import React, { useContext, useEffect, useState } from 'react';
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import { AppDataConsumer, AppDataContext, AppDataProvider } from './common/AppContext';
import { FilterConsumer, FilterProvider } from './common/FilterContext';
import RouteConstants from './common/RouteConstants';
import PageNotFoundUI from './sharedComponents/emptyStateUIContainers/PageNotFoundUI';
import ErrorBoundary from './sharedComponents/errorBoundary/ErrorBoundary';

import CoachProfileMainContainer from './screens/coachProfile/CoachProfileMainContainer';
import CoachProfileEditMainContainer from './screens/coachProfileEdit/CoachProfileEditMainContainer';
import CoachProfileView from './screens/coachProfileView/CoachProfileViewContainer';
import AppHeaderBar from './sharedComponents/appBar/AppHeaderBar';

import CoachesManagementMainContainer from "./screens/CoachesManagement/CoachesManagementMainContainer";
import CoachLogin from './screens/coachLogin/CoachLogin';
import Logout from './screens/coachLogin/Logout';
import PrivacyPolicy from './screens/coachLogin/PrivacyPolicy';
import ForgotUsername from './screens/forgotPassword/ForgotUsername';
import ForgotPassword from './screens/forgotPassword/Forgotpassword';
import ResetPassword from './screens/forgotPassword/ResetPassword';

import './App.css';
import { Role, history } from './helper';
import FAQ from "./screens/FAQ/FAQ";
import ContactHelpDesk from "./screens/HelpDesk/contactHelpDesk";
import InviteUser from "./screens/InviteUser/InviteUser";
import UserMainContainer from './screens/admin/UserMainContainer';
import ChangeEmailId from "./screens/changeEmailId/ChangeEmailId";
import ChangePassword from "./screens/changePassword/ChangePassword";
import CoachRegistration from './screens/coachRegistration/CoachRegistration';
import { authenticationService, userService } from './services';
import { PrivateRoute } from './sharedComponents/PrivateRoute';

///// For Layout
import TagManager from 'react-gtm-module';
import AdminLayout from './Layout/adminLayout';
import CoachesProfileLayout from './Layout/coachesProfileLayout';
import FrontOfficeLayout from './Layout/frontOfficeLayout';
import ProfileEditMainContainer from "./screens/admin/CoachProfileEdit/ProfileEditMainContainer";
import Settings from "./screens/admin/settings/Settings";
import TermsAndConditions from "./screens/other/TermsAndConditions";
import ConfirmationDialog from './sharedComponents/dialog/ConfirmationDialog';

import { ToastProvider } from './context/SnackBarContext';
import { GameVideoProvider } from './context/GameVideoContext';
import Discovery from './discovery';
import CoachingTreeContainer from './screens/CoachingTree/CoachingTreeContainer';
import manageData from './screens/ManageData/manageData';
import MediaManagementContainer from './screens/mediaManagement/MediaManagementContainer';
import addEditMemo from './screens/memo/addEditMemo';
import memoBoardContainer from './screens/memo/memoBoardContainer';
import userMemoBoardContainer from './screens/memo/userMemoBoardContainer';
import ActiveShowCase from "./screens/other/ActiveShowCaseDialog";
import showcaseBoardContainer from './screens/showcase/showcaseBoardContainer';
import CreateSocialPost from './screens/socialCommunity/CreateSocialPost';
import SocialCommunityContainer from './screens/socialCommunity/SocialCommunityContainer';
import groupContainer from './screens/userGroup/groupContainer';
import { UserProfileProvider } from './common/ProfileContext';
import AdminDashboardContainer from './Layout/adminDashboard/AdminDashboardContainer';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        overflow: 'auto',
        height: '100vh'
    },
}));

function App() {

    const tagManagerArgs = {
        gtmId: 'GTM-NWV7RK7'
    }

    TagManager.initialize(tagManagerArgs)

    const [themeType, setThemeType] = useState('light');

    //... See the theme colors at the bottom of this control.
    const theme = getTheme({
        paletteType: themeType,
    });

    const handleThemeChange = (themeType) => {
        setThemeType(themeType);
    }

    return (
        // <CacheBuster>
        // {({ loading, isLatestVersion, refreshCacheAndReload }) => {
        //   if (loading) return null;
        //   if (!loading && !isLatestVersion) {
        //     refreshCacheAndReload();
        //   }
        //   return (
        <ThemeProvider theme={theme}>
            <ErrorBoundary>
                <AppDataProvider>
                    <AppDataConsumer>
                        {props => {
                            return (
                                <ToastProvider>
                                        <GameVideoProvider>
                                            <AppRoute
                                                handleThemeChange={handleThemeChange}
                                                themeType={themeType}
                                            />
                                        </GameVideoProvider>
                                </ToastProvider>
                            )
                        }}
                    </AppDataConsumer>
                </AppDataProvider>
            </ErrorBoundary>
        </ThemeProvider>
    );
    // }}
    // </CacheBuster>
    //);
}
export default App;


const AppRoute = (props) => {
    const classes = useStyles();

    const [currentUser, setCurrentUser] = useState(null);
    const [isAdmin, setIsAdmin] = useState(false);

    const {
        mobileView,
        canISeeCoachingTree
    } = useContext(AppDataContext);

    //const networkStatus = useNetworkStatus();

    useEffect(() => {
        //... Register service worker.
        //registerServiceWorker();
        authenticationService.currentUser.subscribe(x => {
            setCurrentUser(x);
        });
        setIsAdmin(userService.getUserRole() == Role.Admin);
    }, []);


    function logout() {
        authenticationService.logout();
        history.push('/');
    }

    function coachLogin() {
        return (
            <CoachLogin></CoachLogin>
        )
    }

    function privacyPolicy() {
        return (
            <PrivacyPolicy></PrivacyPolicy>
        )
    }

    function LogoutPage() {
        return (
            <Logout></Logout>
        )
    }
    function forgotPassword() {
        return (
            <ForgotPassword></ForgotPassword>
        )
    }

    function forgotUsername() {
        return (
            <ForgotUsername></ForgotUsername>
        )
    }

    function resetPassword() {
        return (<ResetPassword></ResetPassword>)
    }

    function coachRegistration() {
        return (
            <CoachRegistration></CoachRegistration>
        )
    }

    function termsAndCondition() {
        return (
            <TermsAndConditions></TermsAndConditions>
        )
    }
    function activeShowCase() {
        return (
            <ActiveShowCase open={false}></ActiveShowCase>
        )
    }
    function pageNotFound() {
        return (
            <PageNotFoundUI></PageNotFoundUI>
        )
    }

    function pageDiscovery() {
        return (
            <Discovery></Discovery>
        )
    }


    const getUserConfirmation = (message, callback) => {
        ConfirmationDialog(message, callback);
    };

    return (
        <FilterProvider>
            <FilterConsumer>
                {(props) => {
                    return (
                        <div className={classes.root} >
                            <Router getUserConfirmation={getUserConfirmation}>
                                {currentUser && !mobileView &&
                                    <UserProfileProvider>
                                        <AppHeaderBar currentThemeType={props.themeType} onThemeChange={props.handleThemeChange} onLogout={logout}></AppHeaderBar>
                                    </UserProfileProvider>
                                }
                                <div className={classes.container}>
                                    <Switch>
                                        <Route exact path={RouteConstants.COACH_LOGIN}>
                                            {coachLogin()}
                                        </Route>
                                        <Route exact path={RouteConstants.PRIVACY_POLICY}>
                                            {privacyPolicy()}
                                        </Route>
                                        <Route exact path={RouteConstants.DISCOVERY_PAGE}>
                                            {pageDiscovery()}
                                        </Route>
                                        <Route exact path={RouteConstants.COACH_LOGOUT}>
                                            {LogoutPage()}
                                        </Route>
                                        <Route exact path={RouteConstants.FORGOT_PASSWORD}>
                                            {forgotPassword()}
                                        </Route>
                                        <Route exact path={RouteConstants.FORGOT_USERNAME}>
                                            {forgotUsername()}
                                        </Route>
                                        <Route exact path={RouteConstants.RESET_PASSWORD}>
                                            {resetPassword()}
                                        </Route>
                                        <Route exact path={RouteConstants.TERMS_AND_CONDITIONS}>
                                            {termsAndCondition()}
                                        </Route>
                                        <Route exact path={RouteConstants.ACTIVE_SHOWCASE}>
                                            {activeShowCase()}
                                        </Route>
                                        {/* <Route exact path={RouteConstants.COACH_REGISTRATION}>
                                                    {props.coachRegistration()}
                                                </Route> */}
                                        <Route exact path={RouteConstants.INVITE_SIGNUP}>
                                            {coachRegistration()}
                                        </Route>
                                        <PrivateRoute exact path={RouteConstants.COACH_CHANGE_PASSWORD} component={ChangePassword} layout={CoachesProfileLayout} />
                                        <PrivateRoute path={RouteConstants.COACH_CHANGE_EMAIL} exact component={ChangeEmailId} layout={CoachesProfileLayout} />
                                        <PrivateRoute path={RouteConstants.COACH_HELP_DESK} exact component={ContactHelpDesk} layout={CoachesProfileLayout} />
                                        <PrivateRoute path={RouteConstants.COACH_FAQ} exact component={FAQ} layout={CoachesProfileLayout} />

                                        <PrivateRoute path={RouteConstants.COACH_PROFILE_VIEW} exact component={CoachProfileMainContainer} roles={[Role.Coach, Role.FrontOfficePlusCoach]} layout={CoachesProfileLayout} />
                                        <PrivateRoute path={`${RouteConstants.COACH_PROFILE_VIEW}/:coachName`} exact component={CoachProfileMainContainer} layout={CoachesProfileLayout} />
                                        <PrivateRoute path={RouteConstants.COACH_PROFILE_EDIT} exact component={CoachProfileEditMainContainer} roles={[Role.Coach, Role.FrontOfficePlusCoach]} layout={CoachesProfileLayout} />

                                        <PrivateRoute path={`${RouteConstants.ADMIN_COACH_PROFILE_EDIT}/:userName`} exact component={ProfileEditMainContainer} roles={[Role.Admin]} />
                                        <PrivateRoute exact path={RouteConstants.DASHBOARD} component={CoachesManagementMainContainer} layout={FrontOfficeLayout} roles={[Role.FrontOffice, Role.FrontOfficePlusCoach]} />
                                        <PrivateRoute path={`${RouteConstants.COACHES_PROFILE_VIEW}/:userName`} exact component={CoachProfileView} roles={[Role.FrontOffice, Role.FrontOfficePlusCoach, Role.Admin, Role.Coach]} />
                                        <PrivateRoute exact path={RouteConstants.INVITE_USER} component={InviteUser} layout={CoachesProfileLayout} roles={[Role.Admin]} />
                                        <PrivateRoute exact path={RouteConstants.ADMIN_SETTINGS} component={Settings} layout={CoachesProfileLayout} roles={[Role.Admin]} />
                                        <PrivateRoute exact path={RouteConstants.USER_MANAGEMENT} component={UserMainContainer} layout={FrontOfficeLayout} roles={[Role.Admin]} />
                                        {/* <PrivateRoute exact path={RouteConstants.USER_ROLE} component={UserRoleContainer} layout={FrontOfficeLayout} roles={[Role.Admin]} /> */}
                                        <PrivateRoute exact path={RouteConstants.COACH_MANAGEMENT} component={CoachesManagementMainContainer} layout={AdminLayout} roles={[Role.Admin]} />
                                        <PrivateRoute exact path={RouteConstants.ADMIN_MEMO} component={memoBoardContainer} layout={AdminLayout} roles={[Role.Admin]} />
                                        <PrivateRoute exact path={RouteConstants.ADMIN_SHOWCASE} component={showcaseBoardContainer} layout={AdminLayout} roles={[Role.Admin]} />
                                        <PrivateRoute path={`${RouteConstants.ADMIN_EDIT_MEMO}/:id`} exact component={addEditMemo} layout={AdminLayout} />
                                        <PrivateRoute path={`${RouteConstants.ADMIN_ADD_MEMO}`} exact component={addEditMemo} layout={AdminLayout} />
                                        <PrivateRoute exact path={RouteConstants.ADMIN_DASHBOARD} component={AdminDashboardContainer} roles={[Role.Admin]} />
                                        <PrivateRoute exact path={RouteConstants.ADMIN_GROUP} component={groupContainer} layout={AdminLayout} roles={[Role.Admin]} />
                                        <PrivateRoute exact path={RouteConstants.COACH_VIEW_USER_MEMO} component={userMemoBoardContainer} layout={CoachesProfileLayout} />
                                        <PrivateRoute exact path={RouteConstants.ADMIN_DATA} component={manageData} layout={AdminLayout} roles={[Role.Admin]} />
                                        {
                                            (canISeeCoachingTree !== undefined && canISeeCoachingTree === 'true') &&
                                            <PrivateRoute exact path={RouteConstants.COACH_DASHBOARD} component={CoachesManagementMainContainer} layout={FrontOfficeLayout} roles={[Role.Coach]} />

                                        }
                                        {
                                            (isAdmin == true || (canISeeCoachingTree !== undefined && canISeeCoachingTree === 'true')) &&
                                            <PrivateRoute exact path={`${RouteConstants.COACHES_TREE}/:userName`} component={CoachingTreeContainer} layout={FrontOfficeLayout} roles={[Role.FrontOffice, Role.FrontOfficePlusCoach, Role.Admin, Role.Coach]} />
                                        }
                                        <PrivateRoute exact path={RouteConstants.CREATE_SOCIAL_POST} component={CreateSocialPost} />
                                        <PrivateRoute exact path={RouteConstants.SOCIAL_COMMUNITY_CONTAINER} component={SocialCommunityContainer} roles={[Role.Admin, Role.Coach]} />
                                        <PrivateRoute exact path={RouteConstants.MEDIA_MANAGEMENT} component={MediaManagementContainer} roles={[Role.Admin]} />
                                        <Route exact path='*'>
                                            {pageNotFound()}
                                        </Route>
                                    </Switch>
                                </div>
                            </Router>
                        </div>
                    )
                }}
            </FilterConsumer>
        </FilterProvider>
    )


}

//... Setting colors for the light and dark theme. 
function getTheme(theme) {
    return createMuiTheme({
        palette: {
            type: theme.paletteType,
            primary: {
                main: (theme.paletteType === 'light') ? '#1d428a' : '#90caf9',
                //light: (theme.paletteType === 'light') ? '#8797bf' : '#a6d4fa',
                dark: (theme.paletteType === 'light') ? "#172b5b" : "#648dae"
            },
            secondary: {
                main: (theme.paletteType === 'light') ? '#c8102e' : '#f48fb1',
            },
        },
        overrides: {
            MuiTooltip: {
                tooltip: {
                    fontSize: ".75rem",
                    fontWeight: 'normal',
                }
            }
        }
    });
}