import React, { useState, useEffect, useRef, useContext } from "react";
import PropTypes from "prop-types";

import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core/";
import Card from "@material-ui/core/Card";
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import AppConstants from "../../common/AppConstants";
import CardContent from "@material-ui/core/CardContent";
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ListItemIcon from "@material-ui/core/ListItemIcon";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import IconButton from "@material-ui/core/IconButton";
import Divider from "@material-ui/core/Divider";
import SwipeableViews from "react-swipeable-views";
import OndemandVideoIcon from '@material-ui/icons/OndemandVideo';
import useMediaQuery from "@material-ui/core/useMediaQuery";
import MediaCard from "./MediaCard";
import { UserProfileContext } from "../../common/ProfileContext";
import ArrowRightSharpIcon from '@material-ui/icons/ArrowRightSharp';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { referenceDataService } from "../../services/referenceData.service";
import { useParams } from "react-router-dom";
import { AppDataContext} from '../../common/AppContext';

const useStyles = makeStyles((theme) => ({
  cardDetails: {
    display: 'flex',
    justifyContent: 'left',
  },
  section: {
    textAlign: "left",
    '& div': {
      borderRadius: '0',
    },
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexShrink: 0,
    fontWeight: "bold",
    [theme.breakpoints.up('sm')]: {
      flexBasis: '85%',
    },
    [theme.breakpoints.down('xs')]: {
      flexBasis: '85%',
    },
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  itemIcon: {
    [theme.breakpoints.up('sm')]: {
      minWidth: '45px'
    },
    [theme.breakpoints.down('xs')]: {
      minWidth: '35px'
    },
  },
  expansionPanelSummary: {
    [theme.breakpoints.down('xs')]: {
      padding: '0 16px 0 16px'
    },
  },
  expansionPanelDetails: {
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(1),
      paddingRight: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      paddingLeft: theme.spacing(1)
    },
  },
  tabsColor: {
    '& button': {
      color: '#ffffff !important',
    },
  }, tab: {
    fontSize: theme.typography.pxToRem(12),
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scroll-tabpanel-${index}`}
      aria-labelledby={`scroll-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scroll-tab-${index}`,
    "aria-controls": `scroll-tabpanel-${index}`,
  };
}

export default function FAQ(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [tabIndex, setTabIndex] = useState(0);
  const { jwtTokenDetails, setjwtTokenDetails } = useContext(UserProfileContext);
  const {
    setMobileView
  } = useContext(AppDataContext);
  let params = useParams();

  const query = new URLSearchParams(props.location.search);
  const view = query.get('view')

  const handleChangeTab = (event, newValue) => {
    setTabIndex(newValue);
  };

  function isXsDown() {
    return useMediaQuery(theme.breakpoints.down("xs"));
  }

  const [expanded, setExpanded] = React.useState();

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  //let data = require('./faq.json');

  const [data, setData] = React.useState({});
  useEffect(() => {
    if(view && view === 'm')
    {
      setMobileView(true)

    }
    referenceDataService.getFaqAndTrainingVideo().then((faq) => {
      setData(faq[0]);
    })

    return () => {
      // Anything in here is fired on component unmount.
      setMobileView(false)
  }
  }, [])


  return (
    <div className={classes.section}>

      <AppBar position="static" color="primary" elevation={1}>
        <Tabs
          value={tabIndex}
          onChange={handleChangeTab}
          scrollButtons="auto"
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          aria-label="profile tabs"
          className={classes.tabsColor}
        >
          <Tab
            className={classes.tab}
            icon={<LiveHelpIcon />}
            label={
              !isXsDown() && AppConstants.FAQ_Title.FAQ
            }
            {...a11yProps(0)}
          />
          <Tab
            className={classes.tab}
            icon={<OndemandVideoIcon />}
            label={
              !isXsDown() &&
              AppConstants.FAQ_Title.TRAINING_VIDEOS
            }
            {...a11yProps(1)}
          />
        </Tabs></AppBar>

      <SwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={tabIndex}
      >
        <TabPanel value={tabIndex} index={0} dir={theme.direction}>

          <Card>
            <CardContent>
              <Typography variant="subtitle1" color="secondary">
                {data.faq && data.faq.title}
              </Typography>
              <br></br>
              {data.faq && data.faq.data.map((option, index) => {
                return (
                  <ExpansionPanel key={index} expanded={expanded === `panel1_` + (index + 1)} onChange={handleChange(`panel1_` + (index + 1))}>
                    <ExpansionPanelSummary className={classes.expansionPanelSummary}
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                    >
                      <Typography className={classes.heading}>{option.key}</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails className={classes.expansionPanelDetails}>
                      {
                        (expanded === `panel1_` + (index + 1)) &&
                        <List>
                          {option.value.map(val => {
                            return (<ListItem style={{ alignItems: "self-start" }}>
                              <>
                                <ArrowRightSharpIcon style={{ paddingRight: '5px' }} />{" "}
                                <Typography >{val}</Typography>
                              </>
                            </ListItem>)
                          })}</List>
                      }
                    </ExpansionPanelDetails>
                  </ExpansionPanel>)
              })}
              <br />

              <Typography variant="subtitle1" color="secondary">
                {data.faqClarification && data.faqClarification.title}
              </Typography>
              <br></br>
              {data.faqClarification && data.faqClarification.data.map((option, index) => {
                return (
                  <ExpansionPanel key={index} expanded={expanded === `panel` + (index + 1)} onChange={handleChange(`panel` + (index + 1))}>
                    <ExpansionPanelSummary className={classes.expansionPanelSummary}
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                    >
                      <Typography className={classes.heading}>{option.key}</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails className={classes.expansionPanelDetails}>
                      {
                        (expanded === `panel` + (index + 1)) &&
                        <List>
                          {option.value.map(val => {
                            return (<ListItem style={{ alignItems: "self-start" }}>
                              <>
                                <ArrowRightSharpIcon style={{ paddingRight: '5px' }} />{" "}
                                <Typography >{val}</Typography>
                              </>
                            </ListItem>)
                          })}</List>
                      }
                    </ExpansionPanelDetails>
                  </ExpansionPanel>)
              })}
            </CardContent>
          </Card>
        </TabPanel>

        <TabPanel value={tabIndex} index={1} dir={theme.direction}>
          <Card>
            <CardContent>
              <Typography variant="subtitle1" color="secondary">
                {data.videos && data.videos.title}
              </Typography>
              <br></br>
              <Grid container spacing={2} >
                {data.videos && data.videos.data.map((item, index) => {
                  return (
                    <Grid key={index} item
                      xs={12} sm={6} md={4} lg={4} xl={3}
                    >
                      <MediaCard data={item}
                        jwtTokenDetails={jwtTokenDetails}></MediaCard>
                    </Grid>
                  )
                })}
              </Grid>

              <br></br>

              <Typography variant="subtitle1" color="secondary">
                {(data.gm_videos && data.gm_videos.data.length > 0) && data.gm_videos.title}
              </Typography>
              <br></br>
              <Grid container spacing={2} >
                {data.gm_videos && data.gm_videos.data.map((item, index) => {
                  return (
                    <Grid key={index} item
                      xs={12} sm={6} md={4} lg={4} xl={3}
                    >
                      <MediaCard data={item}
                        jwtTokenDetails={jwtTokenDetails}></MediaCard>
                    </Grid>
                  )
                })}
              </Grid>


            </CardContent></Card>
        </TabPanel>
      </SwipeableViews>
    </div>
  );
}