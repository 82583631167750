import { Button, InputAdornment, TextField } from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import SearchIcon from "@material-ui/icons/Search";
import { memo, useEffect, useState } from "react";
import useTelemetry from '../../utils/useTelemetry';

const SocialSearchBar = ({ onSearch, tabIndex }) => {


    const { trackCustomEvents } = useTelemetry();

    const [searchedText, setSearchText] = useState("");
    const [postSearched, setPostSearched] = useState(false);

    useEffect(() => {
        //.. Reset search text when the tab index is changed.
        setSearchText("");
        setPostSearched(false);
    }, [tabIndex]);

    const getSocialPost = (searchValue = searchedText) => {
        onSearch(searchValue);
    }

    const handleKeyDown = async (event) => {
        if (event.key === 'Enter') {
            // Prevent default behavior of the Enter key (form submission)
            event.preventDefault();
            getSocialPost();
            setPostSearched(true);
        }
       
    };

    const onChangeSearchText = (text) => {
        setSearchText(text);
    };

    const onResetSearch = () => {
        //... Rest search result only if user have already search the posts.
        if (postSearched) {
            getSocialPost("");
            setSearchText("");
            setPostSearched(false);
        }
        trackCustomEvents("ClickEvent", { event: "Reset Search" });
    };

    return (
        <>
            <TextField
                value={searchedText}
                onChange={(event) => {
                    setSearchText(event.target.value);
                }}
                onKeyDown={handleKeyDown}
                style={{ background: "#fff", width: "80%" }}
                size="small"
                variant="outlined"
                id="Search"
                placeholder="Search Post"
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            {searchedText != "" && (
                                <>
                                    <ClearIcon
                                        fontSize="small"
                                        onClick={() => {
                                            onChangeSearchText("");
                                        }}
                                        style={{ cursor: "pointer", marginRight: 8 }}
                                    ></ClearIcon>
                                    <SearchIcon
                                        onClick={() => getSocialPost(searchedText)}
                                        style={{ cursor: "pointer" }}
                                        size="small"
                                    ></SearchIcon>
                                </>
                            )}
                        </InputAdornment>
                    ),
                }}
            />
            <Button
                color="secondary"
                variant="text"
                onClick={onResetSearch}
                style={{ maxWidth: '264px', marginLeft: 16 }}
            >
                Reset Search
            </Button>
        </>
    );
};

export default memo(SocialSearchBar);