import { authHeader, handleResponse, handleError, Role } from '../helper';
import { authenticationService } from '../services';
import APIHelper from "../common/ApiHelper";
import APIURLConstants from "../common/ApiURLConstants";


export const userService = {
    changePassword,
    changePasswordByAdmin,
    changeEmail,
    registerUser,
    registerInvitedUser,
    getUserFullName,
    getUserRole,
    getUsername,
    getAllUser,
    changeStatus,
    updateUser,
    validateToken,
    acceptTerms,
    getSitekeyForRecaptcha,
    getTrainingVideoSignedUrl,
    getAnalytics,
    downloadAnalyticsPDF,
    getAllFrontOfficeUser,
    getAllActiveShowCase,
    hideShowcase,
    getProfileVideosSignedUrl,
    getCoachingTreeHeadCoachesData,
  //  getCoachingTreeAssitantAndStaffData,
    getCoachingTreeSameStaffData,
    getPrivacyPolicy,
    getCoachingTreeAssistantsAndPlayersData
};

function registerUser(userDetails) {
    //userDetails.username=userDetails.username.toLowerCase();
    return APIHelper.post(APIURLConstants.SINGNUP_COACH, userDetails)
        .then(handleResponse)
        .then(user => {
            return user;
        }).catch(handleError);
}


function registerInvitedUser(userDetails, token) {
    userDetails.resettoken = token;
    return APIHelper.post(APIURLConstants.SINGNUP_INVITED_USER, userDetails)
        .then(handleResponse)
        .then(user => {
            return user;
        }).catch(handleError);
}

function validateToken(token) {
    return APIHelper.get(APIURLConstants.VALIDATE_TOKEN + '/' + token)
        .then(handleResponse)
        .then(response => {
            return response;
        }).catch(handleError);
}


function updateUser(userDetails) {
    return APIHelper.post(APIURLConstants.UPADTE_USER_DETAIL, userDetails, authHeader())
        .then(handleResponse)
        .then(user => {
            return user;
        }).catch(handleError);
}


function changePassword(currentPassword, newPassword) {
    let postData = {
        CurrentPassword: currentPassword,
        NewPassword: newPassword,
    };

    return APIHelper.post(APIURLConstants.CHANGE_PASSWORD, postData, authHeader())
        .then(handleResponse)
        .then(user => {
            return user;
        }).catch(handleError);
}

function changePasswordByAdmin(currentPassword, newPassword,userName) {
    let postData = {
        CurrentPassword: currentPassword,
        NewPassword: newPassword,
        userName:userName
    };

    return APIHelper.post(APIURLConstants.CHANGE_PASSWORD_BY_ADMIN, postData, authHeader())
        .then(handleResponse)
        .then(user => {
            return user;
        }).catch(handleError);
}


function changeEmail(newEmailId) {
    let postData = {
        NewEmail: newEmailId
    };

    return APIHelper.post(APIURLConstants.CHANGE_EMAIL, postData, authHeader())
        .then(handleResponse)
        .then(user => {
            return user;
        }).catch(handleError);
}

function getUserFullName() {
    const currentUser = authenticationService && authenticationService.currentUserValue;
    return currentUser ? currentUser.firstName + " " + currentUser.lastName : "";
}

function getUsername() {
    var jwtDecode = require('jwt-decode');
    let currentUserLocalStorage = JSON.parse(localStorage.getItem('currentUser'));
    if (currentUserLocalStorage) {
        let jwt = currentUserLocalStorage && currentUserLocalStorage.token ? currentUserLocalStorage.token : '';
        const currentUser = jwtDecode(jwt);
        return currentUser.role ? currentUser.unique_name : "";
    }
    return "";
}

function getUserRole() {

    var jwtDecode = require('jwt-decode');
    let currentUserLocalStorage = JSON.parse(localStorage.getItem('currentUser'));
    if (currentUserLocalStorage) {
        let jwt = currentUserLocalStorage && currentUserLocalStorage.token ? currentUserLocalStorage.token : '';
        const currentUser = jwtDecode(jwt);
        return currentUser.role ? currentUser.role : Role.Coach;
    }
    return Role.Coach;
}

function getAllUser() {
    return APIHelper.get(APIURLConstants.GET_ALL_USERS, authHeader())
        .then(handleResponse)
        .then(lstuser => {
            return lstuser;
        }).catch(handleError);
}

function getSitekeyForRecaptcha() {
    return APIHelper.get(APIURLConstants.GET_SITEKEY)
        .then(handleResponse)
        .then(data => {
            return data;
        }).catch(handleError);
}

function changeStatus(postData) {
    return APIHelper.post(APIURLConstants.UPADTE_USER_STATUS, postData, authHeader())
        .then(handleResponse)
        .then(user => {
            return user;
        })
        .catch(handleError);
}

function acceptTerms() {

    return APIHelper.post(APIURLConstants.ACCEPT_TERMS_CONDITIONS, {}, authHeader())
        .then(res => {
            return res;
        });
}

function getTrainingVideoSignedUrl(fileName) {
    return APIHelper.get(APIURLConstants.GET_TRAINING_VIDEO + fileName, authHeader())
        .then(trainingVideoUrl => {
            return trainingVideoUrl;
        })
        .catch(handleError);
}

function getProfileVideosSignedUrl(fileName) {
    return APIHelper.get(APIURLConstants.GET_VIDEOS_SIGNED_LINK + fileName, authHeader())
        .then(trainingVideoUrl => {
            return trainingVideoUrl;
        })
        .catch(handleError);
}

function getAnalytics() {
    return APIHelper.get(APIURLConstants.GET_ANALYTICS, authHeader())
        .then(handleResponse)
        .then(analytics => {
            return analytics;
        })
        .catch(handleError);
}

function downloadAnalyticsPDF() {
    var xhr = new XMLHttpRequest();
    xhr.open('GET', 'api' + APIURLConstants.GET_ANALYTICS_PDF, true);
    let auth = authHeader();
    xhr.setRequestHeader('Authorization', auth['Authorization']);
    xhr.responseType = 'arraybuffer';
    return xhr;
}

function getAllFrontOfficeUser() {
    return APIHelper.get(APIURLConstants.GET_FRONTOFFICE_USERS, authHeader())
        .then(handleResponse)
        .then(lstuser => {
            return lstuser;
        }).catch(handleError);
}

function getAllActiveShowCase() {
    return APIHelper.get(APIURLConstants.GET_ACTIVE_SHOWCASE, authHeader())
        .then(handleResponse)
        .then(lstuser => {
            return lstuser;
        }).catch(handleError);
}
function hideShowcase(id) {
    return APIHelper.post(APIURLConstants.HIDE_SHOWCASE + id, {}, authHeader())
        .then(handleResponse)
        .then(res => {
            return res;
        })
        .catch(handleError);
}

function getCoachingTreeHeadCoachesData(username) {
    return APIHelper.get(APIURLConstants.GET_COACHING_TREE_HEAD_COACHES_DATA+'/'+username, authHeader())
        .then(handleResponse)
        .then(lstuser => {
            return lstuser;
        }).catch(handleError);
}

// function getCoachingTreeAssitantAndStaffData(username) {
//     return APIHelper.get(APIURLConstants.GET_COACHING_TREE_ASSISTANT_AND_STAFF_DATA+'/'+username, authHeader())
//         .then(handleResponse)
//         .then(lstuser => {
//             return lstuser;
//         }).catch(handleError);
// }

function getCoachingTreeSameStaffData(username) {
    return APIHelper.get(APIURLConstants.GET_COACHING_TREE_SAME_STAFF_DATA+'/'+username, authHeader())
        .then(handleResponse)
        .then(lstuser => {
            return lstuser;
        }).catch(handleError);
}

function getCoachingTreeAssistantsAndPlayersData(username) {
    return APIHelper.get(APIURLConstants.GET_COACHING_TREE_ASSISTANTS_AND_PLAYERS_DATA+'/'+username, authHeader())
        .then(handleResponse)
        .then(lstuser => {
            return lstuser;
        }).catch(handleError);
}







function getPrivacyPolicy() {
    return APIHelper.get(APIURLConstants.GET_PRIVECY_POLICY)
        .then(handleResponse)
        .then(data => {
            return data;
        }).catch(handleError);
}
