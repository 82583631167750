import React, { useContext, useEffect, useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Checkbox, Typography } from "@material-ui/core";
import Hidden from "@material-ui/core/Hidden";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import List from "@material-ui/core/List";
import { ListItemText, ListItemAvatar } from '@material-ui/core';
import TextField from "@material-ui/core/TextField";
import { Radio, RadioGroup } from "@material-ui/core";
import SelectFilter from "../../sharedComponents/filter/SelectFilter";
import ShowFilter from "../../sharedComponents/filter/ShowFilter";
import InputAdornment from "@material-ui/core/InputAdornment";
import ClearIcon from "@material-ui/icons/Clear";
import SearchIcon from "@material-ui/icons/Search";
import { Grid } from "@material-ui/core";
import ListItem from '@material-ui/core/ListItem';
import ExpandFilter from "./ExpandFilter";
import InfoRounded from '@material-ui/icons/InfoRounded';
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import arrow_toggle_transparent from "../../images/arrow_toggle_transparent.png";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import AppConstants from "../../common/AppConstants";
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { from } from "rxjs";
import frontOfficeService from "../../services/frontOffice.service";
import DashBoardContext from "../../common/DashBoardContext";
import AutoCompleteInput from "./autocompleteInput/AutoCompleteInput";
import { mappers } from "../../helper/objectMapper";
import useSmartSearchService from "../../services/useSmartSearch.service";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    background: "#06255B"
  },
  expansionPanel: {
    background: "#06255B",
    '&::before': {
      opacity: '1 !important',
      background: "#06255B"
    },
    '&:last-child:after': {
      bottom: '-1px',
      left: '0',
      right: '0',
      height: '1px',
      content: '""',
      opacity: '1',
      position: 'absolute',
      transition: 'opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      backgroundColor: 'rgba(0, 0, 0, 0.12)',
    },
    marginTop: '0 !important',
    boxShadow: 'none',
  },
  expansionPanelSummary: {
    padding: "0 16px 0 10px",
    minHeight: '48px !important',
    margin: 0,
    '& > div:first-child': {
      margin: theme.spacing(1.5, 0),
    },
  },
  expansionPanelDetails: {
    padding: 0,
    color: "#fff"
  },
  search: {
    padding: theme.spacing(1),
    "& > div": {
      marginBottom: theme.spacing(0.5),
    },
    "& > div[role='button']": {
      maxWidth: '235px',
    },
    background: "#06255B"
  },
  search1: {
    padding: theme.spacing(1),
    "& > div": {
      marginBottom: theme.spacing(0.5),
    },
  },
  checkBox: {
    minWidth: 0,
  },
  filterTitle: {
    fontSize: '14px',
    fontWeight: 'bold',
    color: "#fff"
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
    [theme.breakpoints.down("md")]: {
      minHeight: 'initial',
      alignItems: 'self-start',
      '& button': {
        position: 'absolute',
        right: '4px',
        background: '#c8102e',
        color: '#ffffff',
        padding: theme.spacing(0),
        borderRadius: theme.spacing(0, 0, 0, .75),
      },
    },
  },
  filterButton: {
    border: '1px solid #1d428a',
    borderRadius: '4px',
    padding: '9px 9px',
    marginLeft: '5px',
    fontSize: '1rem',
    color: '#fff',
    background: '#1d428a',
    width: 'fit-content',
    marginBottom: '5px',
    borderRadius: '16px',
    alignSelf: "center",
    '&:hover': {
      color: '#fff',
      background: '#1d428a',
    },
    '&:focus': {
      color: '#fff',
      background: '#1d428a',
    },
    '& svg': {
      marginRight: '5px',
    },
    '& *': { pointerEvents: 'none' }
  },
}));

const AntSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    '&$checked': {
      transform: 'translateX(16px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: '#52d869',
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: '#52d869',
      border: '6px solid #fff',
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
}))(Switch);


const ExpansionPanelSegment = (props) => {
  const classes = useStyles();
  const [isExpanded, setIsExpanded] = useState(props.expanded);

  useEffect(() => {
    setIsExpanded(props.expanded);
  }, [props.expanded]);

  return (
    <ExpansionPanel className={classes.expansionPanel}
      expanded={isExpanded}
      onChange={() => setIsExpanded(!isExpanded)}
    >
      <ExpansionPanelSummary
        className={classes.expansionPanelSummary}
        expandIcon={<ExpandMoreIcon style={{ color: "#fff" }} />}
      >
        <Typography display="inline" className={classes.filterTitle} color="primary">
          {props.title}</Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className={classes.expansionPanelDetails}>
        {isExpanded && <React.Fragment>{props.children}</React.Fragment>}
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

ExpansionPanelSegment.defaultProps = {
  expanded: false,
  title: "",
};

export default function SmartSearch(props) {
  const classes = useStyles();
  const [hideOtherFilter, setHideOtherFilter] = useState(true);
  const [showCurrentPast, setShowCurrentPast] = useState(true);
  const [isAdvancedSearch, setIsAdvancedSearch] = useState(props.disableSmartSearch || false);
  //const [enableStatus, setEnableStatus] = useState(false);
  const [searchPreviewSuggestions, setSearchPreviewSuggestions] = useState([]);
  const [clearFilter, setClearFilter] = useState(false);

  const { getSearchObjectBySearchText } = useSmartSearchService();

  const {
    handleServiceCall
  } = useContext(DashBoardContext);

  const handleOnKeyUp = (event) => {
    if (event.keyCode === 13) {
      props.onChange();
    }
  };

  useEffect(() => {
    //console.log(props.selectedExpType);
    if (props.selectedExpType && props.selectedExpType.length > 0) {
      setHideOtherFilter(false);
    }
    else setHideOtherFilter(true);
  }, [props.selectedExpType])

  useEffect(() => {
    if ((props.selectedChampionship && props.selectedChampionship.length > 0) || (props.selectedSkill && props.selectedSkill.length > 0)) {
      setShowCurrentPast(false);
    }
    else setShowCurrentPast(true);
  }, [props.selectedChampionship, props.selectedSkill])

  const handleSmartSearch = async (text) => {
    props.handleLoading(true);
    //... Call the API to get the search object based on the search text.
    const resp = await getSearchObjectBySearchText(text);
    //const obj = mappers.searchObject(resp.data);
    //... Pass the object to the parent.
    props.searchObject(resp.data);
  }

  // useEffect(() => {
  //   if ((props.selectedCurrentlyWithATeam && props.selectedCurrentlyWithATeam.length > 0) ||
  //     props.selectedSkill?.length > 0 ||
  //     props.selectedChampionship?.length > 0) {
  //     setEnableStatus(true);
  //   }
  //   else setEnableStatus(false);
  // }, [props.selectedCurrentlyWithATeam, props.selectedSkill, props.selectedChampionship])

  const handleSmartSearchFilterClear = () => {
    setClearFilter(true);
    setSearchPreviewSuggestions([]);
    props.handleClearAllFilter();
  }

  return (
    <div className={classes.root}>
      <Hidden mdUp>
        <div className={classes.drawerHeader}>
          <IconButton onClick={props.handleDrawerClose}>
            <ClearIcon
              fontSize="small"
              style={{ cursor: "pointer" }}
            ></ClearIcon>
          </IconButton></div></Hidden>
      <div className={classes.search}>
        <Typography variant="subtitle1" style={{ color: "#fff" }}>
          {isAdvancedSearch ? "Search By Name" : "Search"}
        </Typography>
        {
          isAdvancedSearch ?
            <TextField
              fullWidth
              value={props.searchedText}
              onKeyUp={(event) => handleOnKeyUp(event)}
              onChange={props.handleTextchange}
              style={{ background: "#fff" }}
              size="small"
              variant="outlined"
              id="search"
              placeholder="Enter coach or player name"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {props.searchedText != "" && (
                      <>
                        <ClearIcon
                          fontSize="small"
                          onClick={props.handleClearText}
                          style={{ cursor: "pointer" }}
                        ></ClearIcon>
                        <SearchIcon
                          onClick={props.onChange}
                          style={{ cursor: "pointer" }}
                          size="small"
                        ></SearchIcon>
                      </>
                    )}
                  </InputAdornment>
                ),
              }}
            />
            :
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div style={{ height: "100px" }}>
                <AutoCompleteInput
                  clearFilter={clearFilter}
                  onSearch={(text) => handleSmartSearch(text)}
                  onPreviewSuggestionsChange={(list) => {
                    setSearchPreviewSuggestions(list);
                    setClearFilter(false);
                  }} />
              </div>

              <Typography
                onClick={() => handleSmartSearchFilterClear()}
                style={{ cursor: "pointer", color: "#fff", alignSelf: 'center' }}
                variant="caption"
                size="caption">
                Clear All
              </Typography>
            </div>
        }

        {!props.disableSmartSearch &&
          <FormControlLabel style={{ color: "white" }}
            control={<Checkbox defaultChecked checked={isAdvancedSearch} style={{ color: "white" }} size="small"
              onChange={() => setIsAdvancedSearch(prev => !prev)} />}
            label="Advanced Search" />
        }
        {
          (!isAdvancedSearch) && <div>
            {
              <List
                sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
                component="nav"
                aria-labelledby="nested-list-subheader"
              >
                {
                  searchPreviewSuggestions?.map((item, index) => {
                    return (
                      <ListItem button key={`${Object.keys(item)[0]}-${index}`} style={{ color: "white" }}
                        onClick={() => handleSmartSearch(Object.keys(item)[0])}>
                        <ListItemAvatar>
                          <SearchIcon />
                        </ListItemAvatar>
                        <ListItemText
                          primary={Object.keys(item)[0]}
                        />
                      </ListItem>
                    )
                  })
                }
              </List>
            }
          </div>
        }


      </div>

      {
        isAdvancedSearch &&
        <>
          <Grid container>
            {/* {props.showAndOrFilter && <>
          <Typography variant="caption" style={{ fontStyle: 'italic', paddingLeft: '10px' }} display="block" size="small">Please select below filter criteria </Typography>
          <RadioGroup row style={{ paddingLeft: '10px' }} aria-label="filterCondition" name="filterCondition" value={props.filterCriteria} onChange={props.handleChangeFilterCriteria}>
            <FormControlLabel value="AND" control={<Radio />} label={<Typography variant="caption">AND</Typography>} />
            <FormControlLabel value="OR" control={<Radio />} label={<Typography variant="caption">OR</Typography>} />
          </RadioGroup>
        </>} */}
            {!props.searchForUser && <>
              {(props.selectedTitle.length > 0 ||
                props.selectedLeague.length > 0 ||
                props.selectedSkill.length > 0 ||
                props.selectedChampionship.length > 0 ||
                props.selectedTeams.length > 0 || props.selectedNBATeams.length > 0 ||
                props.selectedWNBATeams.length > 0 || props.selectedNBAGLeagueTeams.length > 0 || props.selectedCurrentlyWithATeam.length > 0 ||
                props.selectedFormerCoach.length > 0 || props.internationalWorked == true || (props.selectedExpType && props.selectedExpType.length > 0) || (props.selectedPExpereience && props.selectedPExpereience.length > 0)) && (
                  // <Grid item
                  //   style={{ textAlign: "right", paddingRight: 10, paddingTop: 12, width: '100%' }}
                  // >
                  <span style={{ paddingLeft: "80px" }}>
                    <Typography
                      onClick={props.handleClearAllFilter}
                      style={{ cursor: "pointer", color: "#fff" }}
                      variant="caption"
                      size="caption">
                      Clear All
                    </Typography>
                  </span>
                  // </Grid>
                )}</>}



            {(props.searchForUser && props.selectedStatus.length > 0) &&
              <Grid item style={{ textAlign: "right", paddingRight: 10, paddingTop: 12, width: '100%' }}>
                <Typography
                  onClick={props.handleClearAllFilter}
                  style={{ cursor: "pointer", color: "#fff" }}
                  variant="caption"
                  size="caption">
                  Clear All
                </Typography>
              </Grid>}
          </Grid>

          <div className={classes.search}>
            {!props.searchForUser &&
              <>
                <ShowFilter
                  list={props.selectedTitle}
                  for="positionTitle"
                  onDelete={props.handleChipDelete}
                  getLeagueTextByValue={props.getLeagueTextByValue}
                />
                <ShowFilter
                  list={props.selectedLeague}
                  for="league"
                  onDelete={props.handleChipDelete}
                  getLeagueTextByValue={props.getLeagueTextByValue}
                />
                <ShowFilter
                  list={props.selectedSkill}
                  for="skill"
                  onDelete={props.handleChipDelete}
                  getLeagueTextByValue={props.getLeagueTextByValue}
                />
                <ShowFilter
                  list={props.selectedChampionship}
                  for="championship"
                  onDelete={props.handleChipDelete}
                  getLeagueTextByValue={props.getLeagueTextByValue}
                />
                <ShowFilter
                  list={props.selectedTeams}
                  for="teams"
                  onDelete={props.handleChipDelete}
                  getLeagueTextByValue={props.getLeagueTextByValue}
                />
                <ShowFilter
                  list={props.selectedNBATeams}
                  for="nbaTeams"
                  onDelete={props.handleChipDelete}
                  getLeagueTextByValue={props.getLeagueTextByValue}
                />
                <ShowFilter
                  list={props.selectedWNBATeams}
                  for="wnbaTeams"
                  onDelete={props.handleChipDelete}
                  getLeagueTextByValue={props.getLeagueTextByValue}
                />
                <ShowFilter
                  list={props.selectedNBAGLeagueTeams}
                  for="nbaGLeagueTeams"
                  onDelete={props.handleChipDelete}
                  getLeagueTextByValue={props.getLeagueTextByValue}
                />
                <ShowFilter
                  list={props.selectedCurrentlyWithATeam}
                  for="currentWork"
                  onDelete={props.handleChipDelete}
                  getLeagueTextByValue={props.getLeagueTextByValue}
                />
                <ShowFilter
                  list={props.selectedFormerCoach}
                  for="formerCoaches"
                  onDelete={props.handleChipDelete}
                  getLeagueTextByValue={props.getLeagueTextByValue}
                />
                <ShowFilter
                  list={props.selectedPExpereience}
                  for="playingExperience"
                  onDelete={props.handleChipDelete}
                  getLeagueTextByValue={props.getLeagueTextByValue}
                />
                {/* <ShowFilter
              list={props.selectedExpType ? props.selectedExpType : []}
              for="expType"
              onDelete={props.handleChipDelete}
              getLeagueTextByValue={props.getLeagueTextByValue}
            /> */}
                {/* <ShowFilter
              list={ props.internationalWorked == true ?[AppConstants.INTERNATIONAL]:[]}
              for="international"
              onDelete={props.handleChipDelete}
              getLeagueTextByValue={props.getLeagueTextByValue}
            /> */}
              </>}
            {props.searchForUser &&
              <ShowFilter
                list={props.selectedStatus}
                for="Status"
                onDelete={props.handleChipDelete}
                getLeagueTextByValue={props.getLeagueTextByValue} />
            }
          </div>
          {!props.searchForUser && <>
            <IconButton className={classes.filterButton} onClick={props.handleFilterClick}
              data-gaeventlabel="Search"
              data-gaeventcategory="Coach Profile Search"
              data-gaeventaction="Apply Filter Click" >Apply Filter</IconButton>

            {showCurrentPast && <> <ExpansionPanelSegment title="Current Coaches">
              <List dense style={{ width: "100%" }}>
                <ExpandFilter selectedItem={props.selectedCurrentlyWithATeam}
                  list={[{ Id: 1, Name: "NBA" }, { Id: 2, Name: "WNBA" }, { Id: 2, Name: "G League" }]}
                  Name="currentWork"
                  onChange={props.handleChecked}
                  searchedText={props.currentlyWithATeamsearchedText}
                  onWithinFilterSearchedTextchange={props.onWithinFilterSearchedTextchange}
                  getLeagueTextByValue={props.getLeagueTextByValue} />
              </List>
            </ExpansionPanelSegment>

              <ExpansionPanelSegment title="Former Coaches">
                <List dense style={{ width: "100%" }}>
                  <ExpandFilter selectedItem={props.selectedFormerCoach}
                    list={[{ Id: 1, Name: "NBA" }, { Id: 2, Name: "WNBA" }, { Id: 2, Name: "G League" }]}
                    Name="formerCoaches"
                    onChange={props.handleChecked}
                    searchedText={props.formerCoachessearchedText}
                    onWithinFilterSearchedTextchange={props.onWithinFilterSearchedTextchange}
                    getLeagueTextByValue={props.getLeagueTextByValue} />
                </List>
              </ExpansionPanelSegment>
            </>
            }



            {/* {hideOtherFilter &&
          <ExpansionPanelSegment title="Current Status">
            <List dense style={{ width: "100%" }}>
              <ExpandFilter selectedItem={props.selectedCurrentlyWithATeam}
                list={[{ Id: 1, Name: AppConstants.CURRENTLY_NOT_WORKING }, { Id: 2, Name: AppConstants.CURRENTLY_WORKING }]}
                Name="currentWork"
                onChange={props.handleChecked}
                searchedText={props.currentlyWithATeamsearchedText}
                onWithinFilterSearchedTextchange={props.onWithinFilterSearchedTextchange}
                getLeagueTextByValue={props.getLeagueTextByValue} />
            </List>
          </ExpansionPanelSegment>
        }
        <ExpansionPanelSegment title={"Current/Past"}>
          <List dense style={{ width: "100%" }}>
            <ExpandFilter selectedItem={props.selectedExpType}
              list={[{ Id: 1, Name: AppConstants.CURRENT_EXP }, { Id: 2, Name: AppConstants.PAST_EXP }]}
              Name="expType"
              onChange={props.handleChecked}
              searchedText={""}
              getLeagueTextByValue={props.getLeagueTextByValue}
              disabled={enableStatus} />
          </List>
        </ExpansionPanelSegment>
        <ExpansionPanelSegment title="League/Level">
          <List dense style={{ width: "100%" }}>
            <ExpandFilter selectedItem={props.selectedLeague}
              list={props.league.filter(x => x != "na")}
              Name="league"
              onChange={props.handleChecked}
              searchedText={props.leaguesearchedText}
              onWithinFilterSearchedTextchange={props.onWithinFilterSearchedTextchange}
              getLeagueTextByValue={props.getLeagueTextByValue} />
          </List>
        </ExpansionPanelSegment> */}
            {/* <ExpansionPanelSegment title="Team">
          <List dense style={{ width: "100%" }}>
            <ExpandFilter selectedItem={props.selectedTeams}
              list={props.teamList}
              Name="team"
              onChange={props.handleChecked}
              searchedText={props.teamsearchedText}
              onWithinFilterSearchedTextchange={props.onWithinFilterSearchedTextchange} />
          </List>
        </ExpansionPanelSegment> */}

            <ExpansionPanelSegment title="NBA Teams">
              <List dense style={{ width: "100%" }}>
                <ExpandFilter selectedItem={props.selectedNBATeams}
                  list={props.nbaTeams}
                  Name="nbaTeams"
                  onChange={props.handleChecked}
                  searchedText={props.nbaTeamsearchedText}
                  onWithinFilterSearchedTextchange={props.onWithinFilterSearchedTextchange}
                  getLeagueTextByValue={props.getLeagueTextByValue} />
              </List>
            </ExpansionPanelSegment>

            <ExpansionPanelSegment title="WNBA Teams">
              <List dense style={{ width: "100%" }}>
                <ExpandFilter selectedItem={props.selectedWNBATeams}
                  list={props.wnbaTeams}
                  Name="wnbaTeams"
                  onChange={props.handleChecked}
                  searchedText={props.wnbaTeamsearchedText}
                  onWithinFilterSearchedTextchange={props.onWithinFilterSearchedTextchange}
                  getLeagueTextByValue={props.getLeagueTextByValue} />
              </List>
            </ExpansionPanelSegment>

            <ExpansionPanelSegment title="NBA G League Teams">
              <List dense style={{ width: "100%" }}>
                <ExpandFilter selectedItem={props.selectedNBAGLeagueTeams}
                  list={props.nbaGLeagueTeams}
                  Name="nbaGLeagueTeams"
                  onChange={props.handleChecked}
                  searchedText={props.nbaGLeagueTeamsearchedText}
                  onWithinFilterSearchedTextchange={props.onWithinFilterSearchedTextchange}
                  getLeagueTextByValue={props.getLeagueTextByValue} />
              </List>
            </ExpansionPanelSegment>
            {/* <ExpansionPanelSegment title="International">
          <List dense style={{ width: "100%" }}>
            <ExpandFilter selectedItem={[props.internationalWorked ?AppConstants.INTERNATIONAL : '']}
              list={[{ Id: 1, Name: AppConstants.INTERNATIONAL }]}
              Name="international"
              onChange={props.handleChecked}
              searchedText={""}
              getLeagueTextByValue={props.getLeagueTextByValue} />
          </List>
        </ExpansionPanelSegment> */}

            <ExpansionPanelSegment title="Title">
              <List dense style={{ width: "100%", margin: 0 }}>
                <ExpandFilter selectedItem={props.selectedTitle}
                  list={props.positionTitle}
                  Name="positionTitle"
                  onChange={props.handleChecked}
                  searchedText={props.titlesearchedText}
                  onWithinFilterSearchedTextchange={props.onWithinFilterSearchedTextchange}
                  getLeagueTextByValue={props.getLeagueTextByValue} />
              </List>
            </ExpansionPanelSegment>

            {hideOtherFilter && <>
              <ExpansionPanelSegment title="Championship">
                <List dense style={{ width: "100%" }}>
                  <ExpandFilter selectedItem={props.selectedChampionship}
                    list={props.league.filter(l => l != 'na')}
                    Name="championship"
                    onChange={props.handleChecked}
                    searchedText={props.championshipsearchedText}
                    onWithinFilterSearchedTextchange={props.onWithinFilterSearchedTextchange}
                    getLeagueTextByValue={props.getLeagueTextByValue} />
                </List>
              </ExpansionPanelSegment>

              <ExpansionPanelSegment title="Skills">
                <List dense style={{ width: "100%" }}>
                  <ExpandFilter selectedItem={props.selectedSkill}
                    list={props.skills.filter(skill => skill.includeUnderSearchFilter)}     // Sushrut
                    Name="skill"
                    onChange={props.handleChecked}
                    searchedText={props.skillsearchedText}
                    onWithinFilterSearchedTextchange={props.onWithinFilterSearchedTextchange}
                    getLeagueTextByValue={props.getLeagueTextByValue} />
                </List>
              </ExpansionPanelSegment>


            </>
            }
            <ExpansionPanelSegment title="Playing Experience">
              <List dense style={{ width: "100%" }}>
                <ExpandFilter selectedItem={props.selectedPExpereience}
                  list={[{ Id: 1, Name: "NBA" }, { Id: 2, Name: "WNBA" }, { Id: 2, Name: "G League" }, { Id: 3, Name: "Other" }]}
                  Name="playingExperience"
                  onChange={props.handleChecked}
                  searchedText={props.pExperiencesearchedText}
                  onWithinFilterSearchedTextchange={props.onWithinFilterSearchedTextchange}
                  getLeagueTextByValue={props.getLeagueTextByValue} />
              </List>
            </ExpansionPanelSegment>
          </>
          }
          {
            props.searchForUser && <ExpansionPanelSegment title="Status">
              <List dense style={{ width: "100%" }}>
                {["Active", "Deactive"].map((item, index) => {
                  return (
                    <SelectFilter
                      key={index}
                      index={index}
                      Name={item}
                      item={item}
                      selectedItem={props.selectedStatus}
                      onChange={() => props.handleChecked(event, "status", item)}
                    />
                  );
                })}
              </List>
            </ExpansionPanelSegment>
          }
        </>
      }


    </div >
  );
}
