import React, { useEffect, useState } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import TextField from '@material-ui/core/TextField';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import Checkbox from "@material-ui/core/Checkbox";
import ToolTip from "@material-ui/core/Tooltip";
import FormControlLabel from '@material-ui/core/FormControlLabel';

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
    },
    grid: {
        display: "flex",
        flexGrow: 1,
        flexWrap: "wrap",
        paddingTop: theme.spacing(1),
        backgroundColor: theme.palette.background.default,
        [theme.breakpoints.up("sm")]: {
            padding: theme.spacing(1),
        },
        [theme.breakpoints.down("xs")]: {
            padding: 0,
        },
    },
    noteBox: {
        width: "100%",
    }
}));

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography color="secondary" >{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
        display: 'flex',
        justifyContent: "left",
        //backgroundColor: "#e6e6e6",
        [theme.breakpoints.down('sm')]: {
            padding: "0.5rem",
        }
    },
    UserDetails: {
        display: 'flex',
    }
}))(MuiDialogContent);

export default function RejectDialog(props) {
    const classes = useStyles();
    const [note, setNote] = useState('');
    const [sendMail, setSendMail] = useState(false);

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const handleClose = () => {
        props.onClose();
    };
   
    const handleChange = (e) => {
        setSendMail(e.target.checked);
    }
    const DialogActions = withStyles((theme) => ({
        root: {
            margin: 0,
            padding: theme.spacing(1),
        },
    }))(MuiDialogActions);
    return (<>
       <Dialog onClose={handleClose}
                fullScreen={fullScreen}
                fullWidth
                maxWidth="xs" aria-labelledby="customized-dialog-title" open={props.open}>
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                    <Typography color="secondary">Keep this profile on hold?</Typography>
                </DialogTitle>
            <DialogContent>
                <Grid container>
                    <Grid className={classes.grid}>
                        <TextField variant='outlined' multiline value={note} className={classes.noteBox}
                            rows={4} onChange={(e) => setNote(e.target.value)} label="Reason for hold" />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    onChange={handleChange}
                                    name="sendEmail"
                                    title="Notify user by email"
                                    color="primary"
                                />
                            }
                            label="Notify user by email"
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={() => props.onReject(note,sendMail)} color='primary'>
                    Keep on Hold
                </Button>
            </DialogActions>
        </Dialog>
    </>)
}
