import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';

const NBAChart = (props) => {
    const [labels, setLabels] = useState(props.labels);
    const [series, setSeries] = useState(props.series);
    const [type, setType] = useState(props.type ? props.type : 'pie');
    const [width, setWidth] = useState(props.width ? props.width : 380);
    const [colors, setColors] = useState(props.colors ? props.colors : undefined);
    useEffect(() => {
        setLabels(props.labels);
        setSeries(props.series);
        setWidth(props.width);
    }, [props.labels, props.series, props.width])

    const [options, setOptions] = useState({
        colors: colors,
        labels: labels,
        dataLabels: { enabled: false },
        legend: {
            formatter: function (val, opt) {
                return opt.w.config.labels[opt.seriesIndex] + " (" + opt.w.config.series[opt.seriesIndex] + ")"
            },
            position: 'bottom'
        },
        chart: {
            events: {
                dataPointSelection: (event, chartContext, config) => {
                    props.onChartClick({selected: config.w.config.labels[config.dataPointIndex], title: props.graphTitle})
                }
            }
        },
        responsive: [{ breakpoint: 480, options: { chart: { width: 200 }, legend: { position: 'bottom' } }, }]
    });
    return (
        <>
            {props.labels && props.series &&
                <ReactApexChart options={options} series={series} type={type} width={width} />
            }
        </>
    )
}

export default NBAChart;