import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
  },
  wrapper: {
    //margin: theme.spacing(1),
    display: "flex",
    alignItems: "center",
  },
  buttonProgress: {
    marginRight: theme.spacing(1),
  },
}));

const BackButton = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>        
        <Button
          onClick={props.onClick}
          color="primary"
          size="small"
          disabled={props.isLoading}
          startIcon={<ArrowBackIcon />}
        >
          Back
        </Button>
      </div>
    </div>
  );
};

BackButton.defaultProps = {
  onClick: () => {},
  isLoading: false,
};

export default BackButton;
