const changeEmailIdValidationSchema = {
  emailId: {
    required: true,
    validator: {
      regEx: /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/,
      error: "Invalid Email address.",
    },    
  },
  confirmEmailId: {
    required: true,
    validator: {
      regEx: /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/,
      error: "Invalid Email address.",
    },
  }
};

export default changeEmailIdValidationSchema;
