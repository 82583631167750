import React, { useEffect, useState, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Divider from "@material-ui/core/Divider";
import nbaLogo from '../../images/nba_login.png';
import nbca_splash from '../../images/nbca_splash.png';
import RouteConstants from '../../common/RouteConstants';
import ErrorPopover from "../../sharedComponents/dialog/ErrorPopover";
import useForm from '../../sharedComponents/customHooks/useForm';
import { authenticationService } from '../../services';
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Loader from "../../sharedComponents/Loader/Loader";
import './ForgotPassword.css';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        justifyContent: 'center',
        alignItems: 'center'
    },
    logoStrip: {
        display: 'flex',
        // flex: 1,
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'start',
        width: '100%',
        height: '150px',
        // paddingTop: '7%'
    },
    logo: {
        [theme.breakpoints.up('sm')]: {
            width: '425px',
            height: '170px'
        },
        [theme.breakpoints.down('md')]: {
            height: '83px',
            width: '260px'
        },
    },
    loginBox: {
        width: '280px',
        position: 'fixed',
        padding: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: theme.spacing(1.5),
        [theme.breakpoints.up('lg')]: {
            right: '10%',
            alignSelf: 'flex-end',
            height: '320px',
        },
        [theme.breakpoints.down('sm')]: {
            alignSelf: 'center',
            height: '400px',
            position: 'static',
            margin: theme.spacing(2),
        },
    },
    sectiondivider: {
        margin: theme.spacing(1)
    },
    nbaLogo: {
        width: '50px',
        paddingLeft: '10.5%',
        paddingRight: '2.5rem',
    },
    nbcaSplashLogo: {
        width: '100px',
        paddingLeft: '30px',
    },
    orText: {
        margin: theme.spacing(.5, 0),
    },
}));

const ForgotUsername = (props) => {
    const classes = useStyles();

    useEffect(() => {
        if (authenticationService.currentUserValue) {
            props.history.push('/view');
        }
    }, []);

    const [showError, setShowError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [saverity, setSaverity] = useState('error');
    const [postdata, setPostdata] = useState({ username: "", email: "" })
    const [errorEmail, setErrorEmail] = useState(false)
    const [isLoading, setIsLoading] = useState(false);

    const handleInputChange = (e) => {
        let obj = { username: "", email: "" }
        setErrorEmail(false);
        if (e.target.name == "email") { obj = { username: "", email: e.target.value }; }
        else obj = { username: e.target.value, email: "" }
        setPostdata(obj);
    }

    //Move this to common components
    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }


    const keyPress = (e) => {
        if (e.keyCode == 13) {
            handleSubmit();
        }
    }

    const handleSubmit = () => {
        if (postdata.email != "") {
            let regEx = /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/;
            if (!regEx.test(postdata.email)) {
                setErrorEmail(true);
                return;
            }
        }
        setIsLoading(true);
        authenticationService.forgotUsername(postdata)
            .then(data => {
                setIsLoading(false);
                setErrorMessage("If there's a NBA Coaches database account connected to this email address, we'll email you username. If you don't receive the email, please try again and make sure you enter the email address associated with your NBA Coaches database account..")
                setShowError(true);
                setSaverity('success');

            },
                error => {
                    setIsLoading(false);
                    setErrorMessage('Invalid email.');
                    if (error == "inactive") {
                        setErrorMessage('Your account is inactive.');
                    }
                    setSaverity('error');
                    setShowError(true);
                }
            );
    }

    const gotoSignin = () => {
        props.history.push(RouteConstants.COACH_LOGIN);
    }

    return (
        <div className={clsx("login-bg", classes.root)}>
            <Hidden smDown>
                <div style={{ flex: 2 }}></div>
            </Hidden>
            <Hidden mdDown>
                <div className={clsx("login-strip-bg", classes.logoStrip)}>
                    {/* <img src={nbaLogo} className={classes.nbaLogo} /> */}
                    <p className={clsx("appTitle")}>NBA Coaches Database</p>
                </div>
            </Hidden>
            <Hidden smDown>
                <div style={{ flex: 2, width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'flex-start', }}>
                    <img src={nbaLogo} className={classes.nbaLogo} />
                    <img src={nbca_splash} className={classes.nbcaSplashLogo} />
                </div>
            </Hidden>
            <Paper className={classes.loginBox} elevation={10}>
                <Hidden lgUp>
                    <div className={clsx("app-logo-small", classes.logo)}></div>
                </Hidden>

                <div className={classes.sectiondivider}></div>
                <Typography variant="h5" color="secondary">Forgot Username</Typography>
                <div className={classes.sectiondivider}></div>
                <div className={classes.sectiondivider}></div>

                <TextField
                    variant="outlined"
                    id="coachEmailId"
                    value={postdata.email}
                    name="email"
                    size="small"
                    label="Email"
                    onChange={handleInputChange}
                    fullWidth
                    error={errorEmail}
                ></TextField>
                <ErrorPopover target="coachEmailId" isOpen={errorEmail} for="Email" />
                <div className={classes.sectiondivider}></div>
                <Button color="secondary" variant="contained" className="capitalize" fullWidth onClick={handleSubmit}>Submit</Button>


                <div className={classes.sectiondivider}></div>

                <Typography variant="caption" className="forgot-link">Remember Username?
                    </Typography>
                <Button className="create-btn" variant="text" onClick={gotoSignin}>
                    <Typography variant="caption">Sign In
                    </Typography>
                </Button>

                <Snackbar
                    open={showError}
                    autoHideDuration={6000}
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    onClose={() => setShowError(false)}
                >
                    <Alert onClose={() => setShowError(false)} severity={saverity}>
                        {errorMessage}
                    </Alert>
                </Snackbar>
            </Paper>
            <Loader isLoading={isLoading} />
        </div> 
    );
}

export default withRouter(ForgotUsername);