import React, { useEffect, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
    checkBox: {
        minWidth: 0,
        '& label > span':{
            padding:theme.spacing(.5),
            '& svg':{
                width:'.8em',
                height:'.8em',
            },
        }
    },
    listItem: {
        paddingLeft: '30px',
        paddingTop: 0,
        paddingBottom: 0
    }
}));
export default function SelectFilter(props) {
    const classes = useStyles();
    return (
        <ListItem role={undefined} dense button className={classes.listItem}>
            <ListItemIcon className={classes.checkBox}>
            <FormControlLabel
                control={<Checkbox
                    onChange={props.onChange}
                    edge="start"
                    style={{color:"#fff"}}
                    checked={props.selectedItem.includes(props.item)}
                    disableRipple
                    inputProps={{ 'aria-labelledby': `checkbox-list-label-${props.index}` }}
                />}
                label={<Typography style={{color:"#fff"}} variant="caption">{props.Name}</Typography>}
            />
                
            </ListItemIcon>
            {/* <ListItemText id={`checkbox-list-label-${props.index}`} primary={<Typography variant="caption">{`${props.Name}`}</Typography>} /> */}
        </ListItem>
    )
}