import React, { useEffect, useState, useRef } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import ListItem from "@material-ui/core/ListItem";
import AutocompleteComponent from "../../../sharedComponents/questionComponents/AutocompleteComponent";

import TeamCoordinator from "../../../sharedComponents/questionComponents/TeamCoordinator";
import CoachPhilosophyAndSkill from "../../../sharedComponents/questionComponents/CoachPhilosophyAndSkill";

import SaveButton from "../../../sharedComponents/saveButton/SaveButton";
import BackButton from "../../../sharedComponents/saveButton/BackButton";

import TitleWithTooltip from "../../../sharedComponents/TitleWithTooltip";
import CaptionMsg from "../../../sharedComponents/CaptionMsg";
import useTelemetry from "../../../utils/useTelemetry";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    section: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(3),
    },
    grid: {
        padding: theme.spacing(1),
        alignSelf: "center",
    },
    question: {
        color: theme.palette.info,
        [theme.breakpoints.down("xs")]: {
            padding: theme.spacing(1),
        },
    },
    questionSubtext: {
        marginLeft: theme.spacing(1),
    },
}));

const Skills = (props) => {
    const classes = useStyles();
    const { trackCustomEvents } = useTelemetry();

    function saveSkills() {
        props.onChange();
        trackCustomEvents("EditEvent", { action: "Skills" });
    }
    const container = useRef(null);

    useEffect(() => {
        container.current.scrollIntoView({ block: 'end', behavior: 'smooth' });
    }, []);


    return (
        <div ref={container} className={classes.root}>
            <div className={classes.section}>
                <TitleWithTooltip
                    title="1. Please add any and all basketball and coaching skills you have acquired."
                    showRequired
                    tooltipTitle="Skill_ToolTip"
                    variant="body2"
                    class={classes.question}
                />
                 <CaptionMsg message="(Select from the answers below and/or create your own by typing your text and pressing “enter”.)" />
                 <AutocompleteComponent
                    label="Skill(+)"
                    list={props.skills}
                    data={props.coachingSkillState}
                    onChange={props.oncoachingSkillChange}
                    isInValid={props.isInValid}
                />

            </div>
            <div className={classes.section}>
                <TitleWithTooltip
                    title="2. Please add any and all languages in which you are conversationally fluent."
                    tooltipTitle="Language_ToolTip"
                    variant="body2"
                    class={classes.question}
                />
                <CaptionMsg message="(Select from the answers below and/or create your own by typing your text and pressing “enter”.)" />
                <AutocompleteComponent
                    label="Language(+)"
                    list={props.language}
                    data={props.languageSkillState}
                    onChange={props.onLanguageSkillChange}                    
                />
                {/* <LanguageSkills
          data={languageSkillState}
          onChange={onLanguageSkillChange}
          validationSchema={props.validationSchema.languageSkill}
          language={props.language}
        /> */}
            </div>
            <div className={classes.section}>
                <TitleWithTooltip
                    title="3. Are you currently your team's coordinator?"
                    tooltipTitle="Coordinator_ToolTip"
                    variant="body2"
                    class={classes.question}                    
                />
                <TeamCoordinator
                    data={props.coordinatorState}
                    onChange={props.onCoordinatorChange}
                    validationSchema={props.validationSchema.coordinator}
                    //isInValid={props.isInValid}
                />
            </div>
            <div className={classes.section}>
                <TitleWithTooltip
                    title="4. What is unique about your coaching philosophy, skills and approach?"
                    tooltipTitle="UniquePhilosophy_ToolTip"
                    variant="body2"
                    class={classes.question}
                />
                <CoachPhilosophyAndSkill
                    data={props.coachPhilosophyAndSkillState}
                    onChange={props.onsetCoachPhilosophyAndSkillChange}
                    validationSchema={props.validationSchema.coachPhilosophyAndSkill}
                    //isInValid={props.isInValid}
                />
            </div>
            <Grid item md={12} xs={12}>
                <ListItem style={{ justifyContent: "flex-end" }}>
                    <BackButton
                        onClick={props.handlePreveStepClick}
                    ></BackButton>
                    <SaveButton isLoading={props.isLoading} onClick={saveSkills}></SaveButton>
                </ListItem>
            </Grid>
        </div>
    );
};
export default Skills;
