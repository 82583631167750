import React, { useContext, useState, useEffect } from 'react';
import { useHistory, useParams, withRouter } from 'react-router-dom';
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import AddCircleIcon from '@material-ui/icons/AddCircleOutline';
import { Paper, Typography } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Dialog from "@material-ui/core/Dialog";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";
import SnackbarMsg from "../../sharedComponents/Snackbar/Snackbar";
import Button from "@material-ui/core/Button";
import adminService from "../../services/admin.service";
import { UserContext } from '../../common/UserContext';
import LinearProgress from '@material-ui/core/LinearProgress';
import ShowcaseItem from './showcaseItem';
import RouteConstants from "../../common/RouteConstants";
import AddShowcaseParticipantsDialog from "../CoachesManagement/AddShowcaseParticipantsDialog";
import SendMailDialog from "../../sharedComponents/dialog/SendMailDialog";


const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    searchBox: {
        width: '250px',
        [theme.breakpoints.only('xs')]: {
            width: '200px',
        },
        [theme.breakpoints.down(325)]: {
            width: '165px',
        },
    },
    memoboardContent: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        overflow: 'auto',
        height: 'auto',
    },
    addMemoSection: {
        padding: theme.spacing(1, 2, 0, 2),
        display: 'flex',
        justifyContent: 'flex-end',
    },
    cursorPointer: {
        cursor: 'pointer',
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 150,
        color: '#fff',
    },
    table: {
        borderRadius: 0,
        margin: 10
    },
    addGroup: {
        border: '1px solid #1d428a',
        borderRadius: '4px',
        padding: '6px 8px',
        marginBottom: '10px',
        fontSize: '1rem',
        color: '#fff',
        background: '#1d428a',
        '&:hover': {
            color: '#fff',
            background: '#1d428a',
        },
        '&:focus': {
            color: '#fff',
            background: '#1d428a',
        },
        '& svg': {
            marginRight: '5px',
        },
    },
}));
const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: "#316ad8",
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);
const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

function showcaseBoardContainer(props) {
    const classes = useStyles();
    const context = useContext(UserContext);
    const [loading, setLoading] = useState(false);
    const [openConfirmationDialog, setOpenConfirmationDialog] = React.useState(false);
    const [allShowcases, setAllShowcases] = useState(null);
    const [currentShowcaseId, setCurrentShowcaseId] = useState();
    const [showError, setShowError] = useState(false);
    const [severity, setSeverity] = useState("");
    const [message, setMessage] = useState("");
    const [action, setAction] = useState();
    const [openParticipantDialog, toggleParticipantDialog] = useState(false);
    const [showcase, setShowcase] = useState(null);
    const [allGroups, setAllGroups] = useState([]);
    const [openSendMail, setopenSendMail] = useState(false);
    const [mailId, setMailId] = useState('');

    useEffect(() => {
        getAllShowcases();
        getAllGroups();
    }, [context.user]);

    const getAllGroups = () => {
        adminService.getAllGroups()
            .then(result => {
                if (result) {
                    var lstGrp = result;
                    lstGrp = result.filter((g) => g.isFrontOfficeGroup && g.isFrontOfficeGroup == 1)
                    setAllGroups(lstGrp);
                }
                setLoading(true);
            }).catch((err) => {
            });
    }

    const getAllShowcases = () => {
        adminService.getAllShowcases()
            .then(result => {
                if (result) {
                    setAllShowcases(result);
                }
                setLoading(true);
            }).catch((err) => {
            });
    };

    const getShowcase = (id) => {
        adminService.getShowcase(id)
            .then(result => {
                if (result) {
                    setShowcase(result);
                    toggleParticipantDialog(true);
                    setCurrentShowcaseId(id);
                    setAction("Edit");
                }
                setLoading(true);
            }).catch((err) => {
            });
    };

    const onEditClick = (id => {
        getShowcase(id);
    });
    const onDeleteClick = (id => {
        setCurrentShowcaseId(id);
        setOpenConfirmationDialog(true);
    });
    const handleConfirmation = (mode) => {
        if (mode === "Y") {
            if (currentShowcaseId) {
                setLoading(false);
                adminService.deleteShowcase(currentShowcaseId).then(result => {
                    getAllShowcases();
                    setLoading(true);
                    setOpenConfirmationDialog(false);
                    setCurrentShowcaseId(null);
                    showAlert("success", 'Showcase deleted successfully.');
                }).catch((err) => {
                    setLoading(false);
                });
            }
        }
        else
            setOpenConfirmationDialog(false);
    }

    const showAlert = (sever, msg) => {
        setShowError(true);
        setSeverity(sever)
        setMessage(msg);
    }

    const handleClose = () => {
        setShowError(false);
        setSeverity("")
        setMessage("");
    }

    const handleSendMailClose = () => {
        setopenSendMail(false);
        setCurrentShowcaseId(null);
    }

    const handleCloseClick = (isSuccess) => {
        // if (!!isSuccess) {
        //     setShowError(true);
        //     setMessage("Memo sent to selected users.")
        //     setSeverity("success")
        // }
        // toggleParticipantDialog(false);
        setCurrentShowcaseId(null);
        toggleParticipantDialog(false);
    }

    const openSendMAilDialog = (id) => {
        setCurrentShowcaseId(id)
        setopenSendMail(true);
    }

    const SendMail = () => {
        let regex = /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/;
        let validEmail = regex.test(mailId)
        if (!validEmail) {
            setSeverity("error");
            setMessage("Enter valid email")
            setShowError(true);
            return;
        }
        else {
            adminService.sendShowCaseEmail(currentShowcaseId, mailId)
                .then(data => {
                    setMailId("");
                    handleSendMailClose();
                    setSeverity("success");
                    setMessage("Email Sent")
                    setShowError(true);
                })
        }
    }

    const handleSaveShowcaseClick = (showcaseData) => {
        if (showcaseData) {
            showcaseData.users = showcase.users;
            showcaseData.id = showcase.id
            showcaseData.isActive = 1
            let postData = showcaseData;
            postData.assignedUsers = [...new Set(showcaseData.assignedUsers)]
            adminService.saveShowcase(postData)
                .then(data => {
                    if (typeof data == "string") {
                        if (data == "duplicate")
                            showAlert("error", "Showcase already exists.");
                        else if (data == "invaliddate")
                            showAlert("error", "Showcase already exists for selected date.");
                        else
                            showAlert("error", data);
                    }
                    else {
                        if (showcaseData.id)
                            showAlert("success", 'Showcase updated successfully.');
                        else
                            showAlert("success", 'Showcase created successfully.');
                        toggleParticipantDialog(false);
                        getAllShowcases();
                    }
                })
                .catch((err) => {
                    showAlert("error", 'Error occured while saving.');
                });
        }

    }
    return (
        <div className={classes.root} style={{ backgroundColor: '#f5f5f5' }}>
            <div className="repsContainer" style={{ overflow: 'hidden' }}>
                <div className={classes.memoboardContent}>
                    {
                        <React.Fragment>
                            {
                                (!loading) &&
                                <LinearProgress color="secondary"></LinearProgress>
                            }
                        </React.Fragment>
                    }
                    <Grid container>
                        <TableContainer component={Paper} className={classes.table}>
                            <Table size="small" aria-label="a dense table">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell style={{ width: '35%' }}>Title</StyledTableCell>
                                        <StyledTableCell style={{ width: '15%' }}>Start Date</StyledTableCell>
                                        <StyledTableCell style={{ width: '15%' }}>End Date</StyledTableCell>
                                        <StyledTableCell align="right" style={{ width: '40%' }}>Action</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {allShowcases && allShowcases.map((item, index) => {
                                        return <ShowcaseItem
                                            key={index}
                                            data={item}
                                            onEditClick={onEditClick}
                                            onDeleteClick={onDeleteClick}
                                            showEditDeleteButton={true}
                                            openSendMail={openSendMAilDialog}
                                        />
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                    <Dialog
                        open={openConfirmationDialog}
                        onClose={() => handleConfirmation("N")}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description">
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                Do you want to delete this Showcase?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => handleConfirmation("Y")} color="primary">
                                Yes</Button>
                            <Button
                                onClick={() => handleConfirmation("N")}
                                color="primary"
                                autoFocus>No
                            </Button>
                        </DialogActions>
                    </Dialog>
                    {openParticipantDialog &&
                        <AddShowcaseParticipantsDialog
                            data={showcase}
                            action={action}
                            groupList={allGroups}
                            open={openParticipantDialog}
                            handleCloseClick={handleCloseClick}
                            showcaseId={currentShowcaseId}
                            handleSaveShowcaseClick={handleSaveShowcaseClick}
                            selectedProfiles={showcase.users}

                        />}

                    <SendMailDialog open={openSendMail}
                        onClose={handleSendMailClose}
                        onSendMail={SendMail}
                        mailId={mailId}
                        setMailId={setMailId}
                    />
                    <SnackbarMsg showError={showError} onClose={handleClose} severity={severity} msg={message} />
                </div>
            </div>
        </div>
    );
};

export default withRouter(showcaseBoardContainer);