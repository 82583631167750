import { Done, ErrorOutline, InfoOutlined } from "@material-ui/icons";

const AppConstants = {
  //size in MB
  MAX_PROFILE_PHOTO_FILE_SIZE: "10",
  MAX_PROFILE_VIDEO_FILE_SIZE: "50",
  NA: "N/A",
  CURRENTLY_NOT_WORKING: "Not Currently with a Team",
  CURRENTLY_WORKING: "Currently with a Team",
  CURRENT_EXP: "Current",
  PAST_EXP: "Past",
  INTERNATIONAL: "International",
  PROFILECOMPLETENESS_VALIDATION_MESSAGE: "(Your profile should complete atlease 60% for submition)",
  EDIT_SECTION_TITLE: {
    PersonalDetails: "Personal Details",
    //CurrentCoachingExp: "Current Coaching Experience",
    CoachingExp: "Coaching and Other Job Experience",
    PlayingExp: "Playing Experience",
    AdditionalExp: "Additional Experience",
    Skills: "Skills",
    EducationalDetails: "Education",
    Videos: "Media",
    Other: "Other",
  },
  PROFILE_SECTION_TITLE: {
    CoachingExp: "Coaching Experience",
    PlayingExp: "Playing Experience",
    AdditionalExp: "Additional Experience",
    Skills: "Skills",
    EducationalDetails: "Education",
    Other: "Other",
    Videos: "Media"
  },
  DASHBOARD_SECTION_TITLE: {
    AllProfiles: "All Profiles",
    BookmarkedProfiles: "Bookmarked",
    RecentlyViewedProfiles: "Recently Viewed",
    RecentlyAddedProfiles: "Recently Added",
    RecentlyUpdatedProfiles: "Resubmitted",
    Approved: "Approved Profiles",
    Rejected: "On Hold Profiles",
    Pending: "Pending Approvals",
    GroupProfile: "Group Profiles",
    PendingMediaApprovals: "Pending Media Approvals",
  },
  MANAGE_DATA_TITLE: {
    AssistantCoach: "Assistant Coach",
    Events: "Events",
    HeadCoaches: "Head Coaches",
    Skills: "Skills",
    Languages: "Languages",
    Degree: "Degree",
    Awards: "Awards",
    NotablePlayers: "Notable Players",
    Teams: "Teams",
    PositionTitles: "Position Titles",
    TitlesSubCategory: "Titles Sub-Category"

  },
  USER_MANAGEMENT_TITLE: {
    ALLUser: "All User",
    FRONTOFFICE_USER: "Front Office User",
    COACH_USER: "Coach User",
    ADMIN_USER: "ADMIN User",
    GROUP_USER: "Group User",
  },

  FAQ_Title: {
    FAQ: "FAQs",
    TRAINING_VIDEOS: "Training Resources",
  },
  DASHBOARD_SECTION_ID: {
    All: 0,
    Bookmarked: 1,
    RecentlyUpdated: 2,
    RecentlyAdded: 3,
    RecentlyViwed: 4,
    Approved: 5,
    Rejected: 6,
    Pending: 7,
    GroupProfile: 8
  },
  USER_SECTION_ID: {
    All: 0,
    FrontOffice: 1,
    Coach: 2,
    Admin: 3,
    Group: 4,
  },
  PROFILE_STATUS: {
    SUBMITTED: 'submitted',
    APPROVED: 'approved',
    DENIED: 'denied',
    REGISTERED: 'registered',
    RESUBMITTED: 'resubmitted',
  },
  DASHBOARD_TYPE: {
    ADMIN: 0,
    FRONT_OFFICE: 1,
    USER_MANAGEMENT: 2,
  },
  RECENT_PROFILE: {
    DAYS: 5,
  },
  VIDEO_PLAYER: {
    PROFILE_TITLE: "Profile Video",
    TRAINING_VIDEOS_TITLE: "Training Video"
  },
  SOCIAL_COMMUNITY: {
    ALL_POSTS: "All Posts",
    PENDING_REVIEW_POSTS: "Pending Review Posts",
    MY_PENDING_REVIEW_POSTS: "My Pending Approval Posts",
    ON_HOLD_POSTS: "On Hold Posts",
    RETURNED_POSTS: "Returned Posts",
  },
  ANALYTICS_DASHBOARD: {
    COACH_ANALYTICS: "Coach Analytics",
    APPLICATION_INSIGHTS: "Application Insights"
  },
  REVIEW_STATUS: {
    APPROVED: "Approved",
    ON_HOLD: "OnHold",
    RETURNED: "Returned",
    PENDING: "Pending"
  },
  Static: "static",
  Dynamic: "dynamic",
  MAX_PROFILE_ALLOWED_TO_DOWNLOAD: 5,
  MAX_PROFILE_ALLOWED_TO_SHOWCASE: 5,
  COACHING_TREE_CONFIG_KEY: 'showCoachingTree',
  ANDROID_URL_KEY: 'androidURL',
  IOS_URL_KEY: 'iosURL',
  PENDING_REVIEW_MEDIA: "PENDING REVIEW MEDIA",
  VIDEO_TYPE: {
    GAME_VIDEO: "gameVideo",
    PROFILE_VIDEO: "userVideo"
  },
  StatusSeverityToColorMapper: {
    "Pending": "warning",
    "Returned": "error",
    "Approved": "success",
  },
  VideoReviewStatusToConfigKeyMapper: {
    "Pending": "pendingReasonVideo",
    "Returned": "returnedReasonVideo",
    "Approved": "approvedReasonVideo"
  },
  PostReviewStatusToConfigKeyMapper: {
    "Pending": "pendingReasonSocialPost",
    "Returned": "returnedReasonSocialPost",
    "Approved": "approvedReasonSocialPost"
  },
  SeverityBasedIconMapper: {
    "Pending": <InfoOutlined />,
    "Approved": <Done />,
    "Returned": <ErrorOutline />
  }


};

export default AppConstants;
