import React, { useContext, useState, useEffect } from 'react';
import { useHistory, useParams, withRouter } from 'react-router-dom';
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Paper, Typography } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Dialog from "@material-ui/core/Dialog";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";
import SnackbarMsg from "../../sharedComponents/Snackbar/Snackbar";
import Button from "@material-ui/core/Button";
import adminService from "../../services/admin.service";
import { UserContext } from '../../common/UserContext';
import LinearProgress from '@material-ui/core/LinearProgress';
import GroupItem from './groupItem';
import ViewUsersDialog from './viewUsersDialog';
import RouteConstants from "../../common/RouteConstants";
import { FilterContext } from "../../common/FilterContext";
import CreateGroupDialog from "../../sharedComponents/dialog/CreateGroupDialog";



const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    searchBox: {
        width: '250px',
        [theme.breakpoints.only('xs')]: {
            width: '200px',
        },
        [theme.breakpoints.down(325)]: {
            width: '165px',
        },
    },
    groupContent: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        overflow: 'auto',
        height: 'auto',
    },
    addGroupSection: {
        padding: theme.spacing(1, 2, 0, 2),
        display: 'flex',
        justifyContent: 'flex-end',
    },
    cursorPointer: {
        cursor: 'pointer',
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 150,
        color: '#fff',
    },
    table: {
        borderRadius: 0,
        margin: '10px',
    },
    addGroup: {
        border: '1px solid #1d428a',
        borderRadius: '4px',
        padding: '6px 8px',
        marginBottom: '10px',
        fontSize: '1rem',
        color: '#fff',
        background: '#1d428a',
        '&:hover': {
            color: '#fff',
            background: '#1d428a',
        },
        '&:focus': {
            color: '#fff',
            background: '#1d428a',
        },
        '& svg': {
            marginRight: '5px',
        },
    },
}));
const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: "#316ad8",
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);
const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

const GroupContainer = (props) => {
    const classes = useStyles();
    const context = useContext(UserContext);
    const [loading, setLoading] = useState(false);
    const [openConfirmationDialog, setOpenConfirmationDialog] = React.useState(false);
    const [openUsersDialog, setOpenUsersDialog] = React.useState(false);
    const [allGroups, setAllGroups] = useState(null);
    const [currentGroupId, setCurrentGroupId] = useState();
    const [showError, setShowError] = useState(false);
    const [severity, setSeverity] = useState("");
    const [message, setMessage] = useState("");
    const { updateGroup, refreshFilterData } = useContext(FilterContext)
    const [showGroupDialog, setShowGroupDialog] = useState(false);
    const [group, setGroup] = useState(undefined);

    useEffect(() => {
        getAllGroups();
    }, [context.user]);

    const getAllGroups = () => {
        adminService.getAllGroups()
            .then(result => {
                if (result) {                   
                    setAllGroups(result);
                }
                setLoading(true);
            }).catch((err) => {
            });
    };

    function saveGroup(name, showAsTAB, description) {       
        if (name && name.trim().length > 0) {
            let userGroup = group ? group : {};
            userGroup.name = name;
            userGroup.description = description && description.trim();
            userGroup.showAsTAB = showAsTAB;
            // userGroup.type = group ? group.type : selectedProfiles.length > 0 ? AppConstants.Static : AppConstants.Dynamic;
            // userGroup.rules = selectedProfiles.length > 0 ? null : JSON.stringify(rules),
            //     userGroup.users = selectedProfiles,
            //     userGroup.isActive = 1
            adminService.saveGroup(userGroup).then((data) => {
                if (!data) {
                    showAlert("error", "Group already exists.");
                }
                else if (typeof data == "string") {
                    showAlert("error", data);
                }
                else {
                    showAlert("success", 'Group updated successfully.');
                    setGroup(undefined);
                    updateGroup(undefined);
                   // setShowGroupButton(false);
                    setShowGroupDialog(false);
                    
                    // setTimeout(() => {
                    //     props.redirectToGroupList();
                    // }, 800);
                }
            });
        }
        else
            showAlert("error", "Please enter Group Name.");
    }

  

    const onEditClick = ((group) => {
        if (group && group.isFrontOfficeGroup === 1) {
            updateGroup(group);
            props.history.push(RouteConstants.USER_MANAGEMENT);
        }
        else {
            updateGroup(group);
            refreshFilterData();
            props.history.push(RouteConstants.COACH_MANAGEMENT);
        }
    });

    const onEditGroupDetailsClick = ((group) => {
        setGroup(group)
        setShowGroupDialog(true)
        
        // if (group && group.isFrontOfficeGroup === 1) {
        //     updateGroup(group);
        //     props.history.push(RouteConstants.USER_MANAGEMENT);
        // }
        // else {
        //     updateGroup(group);
        //     refreshFilterData();
        //     props.history.push(RouteConstants.COACH_MANAGEMENT);
        // }
    });

    const onDeleteClick = (id => {
        setCurrentGroupId(id);
        setOpenConfirmationDialog(true);
    });
    const handleConfirmation = (mode) => {
        if (mode === "Y") {
            if (currentGroupId) {
                setLoading(false);
                adminService.deleteGroup(currentGroupId).then(result => {
                    getAllGroups();
                    setLoading(true);
                    setOpenConfirmationDialog(false);
                    setCurrentGroupId(null);
                    showAlert("success", 'Group deleted successfully.');
                }).catch((err) => {
                    setLoading(false);
                });
            }
        }
        else
            setOpenConfirmationDialog(false);
    }

    const showAlert = (sever, msg) => {
        setShowError(true);
        setSeverity(sever)
        setMessage(msg);
    }

    const handleClose = () => {
        setShowError(false);
        setSeverity("")
        setMessage("");
    }
    const onViewUsersClick = (id) => {
        setOpenUsersDialog(true);
        setCurrentGroupId(id);
    }
    const handleCloseClick = () => {
        setOpenUsersDialog(false);
        setCurrentGroupId(null);
    }
    return (
        <div className={classes.root} style={{ backgroundColor: '#f5f5f5' }}>
            <div className="repsContainer" style={{ overflow: 'hidden' }}>
                <div className={classes.groupContent}>
                    {
                        <React.Fragment>
                            {
                                (!loading) &&
                                <LinearProgress color="secondary"></LinearProgress>
                            }
                        </React.Fragment>
                    }
                    <Grid container>
                        <TableContainer component={Paper} className={classes.table}>
                            <Table size="small" aria-label="a dense table">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell style={{ width: '30%' }}>Name</StyledTableCell>
                                        <StyledTableCell style={{ width: '40%' }}>Description</StyledTableCell>
                                        <StyledTableCell style={{ width: '10%' }}>Show as tab</StyledTableCell>
                                        <StyledTableCell align="right" style={{ width: '20%' }}>Action</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {allGroups && allGroups.map((item, index) => {
                                        return <GroupItem
                                            key={index}
                                            data={item}
                                            onEditClick={onEditClick}
                                            onDeleteClick={onDeleteClick}
                                            onViewUsersClick={onViewUsersClick}
                                            onEditGroupDetailClick={onEditGroupDetailsClick}
                                        />
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                    <Dialog
                        open={openConfirmationDialog}
                        onClose={() => handleConfirmation("N")}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description">
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                Do you want to delete this Group?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => handleConfirmation("Y")} color="primary">
                                Yes</Button>
                            <Button
                                onClick={() => handleConfirmation("N")}
                                color="primary"
                                autoFocus >No
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <CreateGroupDialog
                        open={showGroupDialog}
                        onClose={() => setShowGroupDialog(false)}
                        showFrontOfficeUser={true}
                        name={group ? group.name : ''}
                        description={group ? group.description : ''}
                        showAsTAB={group ? group.showAsTAB : false}
                        saveGroup={saveGroup}
                    />
                    {openUsersDialog && <ViewUsersDialog
                        users={[]}
                        open={openUsersDialog}
                        handleCloseClick={handleCloseClick}
                        groupId={currentGroupId}
                    />}
                    <SnackbarMsg showError={showError} onClose={handleClose} severity={severity} msg={message} />
                </div>
            </div>
        </div>
    );
};

export default withRouter(GroupContainer);