import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from "react";
import { useGameVideo } from '../../context/GameVideoContext';
import { userService } from '../../services';
import MediaCardList from './MediaCardList';
import RouteConstants from '../../common/RouteConstants';
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        [theme.breakpoints.up('sm')]: {
            padding: theme.spacing(1)
        },
    }
}));


export default function MediaCardContainer(props) {

    let history = useHistory();
    const classes = useStyles();
    const [role, setRole] = useState();
    const { pendingVideo } = useGameVideo();


    useEffect(() => {
        setRole(userService.getUserRole());
    }, []);

    const handleViewProfileClick = (username) => {
        history.push(RouteConstants.COACHES_PROFILE_VIEW + "/" + username);
    }

    return (
        <div className={classes.root} >
            <Grid container >
                {pendingVideo && pendingVideo.length > 0 ? pendingVideo.map((item, index) => {
                    return <MediaCardList
                        key={index}
                        handleOpenDialog={props.handleOpenDialog}
                        data={item}
                        hideAction={props.hideAction}
                        role={role}
                        showMediaContent={props.showMediaContent}
                        mediaList={item?.profileVideos?.ProfileVideo}
                        handleViewProfileClick={handleViewProfileClick}
                    />
                }) :
                    <Grid item xs={12} style={{ margin: 24, display: "flex", justifyContent: "center", alignItems: "center", minHeight: '60vh' }}>
                        <Typography variant="body1" >No videos available for review.</Typography>
                    </Grid>}
            </Grid>
        </div>
    );
}
