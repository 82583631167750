import React from 'react';
import { Typography } from "@material-ui/core/";

const ViewProfileHeading = (props) => {
    return(
        <Typography variant="subtitle1" className="subtitle1" color="secondary">
          {props.title ? props.title : ''}
        </Typography>
    )
}

export default ViewProfileHeading;
    
  