import { makeStyles } from "@material-ui/core/styles";
import deepEqual from "deep-equal";
import React, { useContext, useEffect, useState } from "react";
import AppConstants from "../../common/AppConstants";
import { UserProfileContext } from "../../common/ProfileContext";
import { useGameVideo } from "../../context/GameVideoContext";
import { coachProfileService } from "../../services";
import CaptionMsg from "../../sharedComponents/CaptionMsg";
import SnackbarMsg from "../../sharedComponents/Snackbar/Snackbar";
import TitleWithTooltip from "../../sharedComponents/TitleWithTooltip";
import ProfileVideos from "../coachProfileEdit/sections/ProfileVideos";
import { Prompt } from "react-router-dom";
import useTelemetry from "../../utils/useTelemetry";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexShrink: 0,
        [theme.breakpoints.up("sm")]: {
            flexBasis: "85%",
        },
        [theme.breakpoints.down("xs")]: {
            flexBasis: "85%",
        },
        cursor: "pointer",
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
    itemIcon: {
        [theme.breakpoints.up("sm")]: {
            minWidth: "45px",
        },
        [theme.breakpoints.down("xs")]: {
            minWidth: "35px",
        },
    },
    expansionPanelSummary: {
        [theme.breakpoints.down("xs")]: {
            padding: "0 16px 0 16px",
        },
    },
    expansionPanelDetails: {
        [theme.breakpoints.down("xs")]: {
            paddingTop: theme.spacing(1),
            paddingRight: theme.spacing(1),
            paddingBottom: theme.spacing(1),
            paddingLeft: theme.spacing(1),
        },
    },
    profileCompleteness: {
        color: theme.palette.text.secondary,
    },
    divider: {
        marginTop: theme.spacing(2),
        width: "100%",
    },
    submitButton: {
        alignContent: "right",
        textAlign: "right",
        paddingBottom: 10,
        paddingRight: 10,
    }
}));

export default function ProfileMediaEditSection(props) {

    const classes = useStyles();

    const {
        profileVideo,
        coachingVideos,
        profileVideos,
        updateProfileVideosDetails,
        userName,
    } = useContext(UserProfileContext);

    const { gameVideoConfigValues } = useGameVideo();
    const [isLoading, setIsLoading] = useState(false);
    const [changesMade, setChangesMade] = useState(false);
    const [gameVideosState, setGameVideosState] = useState([]);
    const [profileVideoState, setProfileVideoState] = useState([]);
    const [videoUrlState, setVideoUrlState] = useState(
        coachingVideos && coachingVideos.externalLinks ? coachingVideos.externalLinks : []);
    const [showError, setShowError] = useState(false);
    let [errorMessage, setErrorMessage] = useState("");
    const [severity, setSeverity] = useState("error");
    const [isInValid, setIsInValid] = useState(false);
    const [deleteVideos, setDeleteVideos] = useState([]);
    const { trackCustomEvents } = useTelemetry();

    useEffect(() => {
        setVideoUrlState(
            coachingVideos && coachingVideos.externalLinks
                ? coachingVideos.externalLinks
                : []
        );
    }, [
        profileVideo,
        coachingVideos,
    ]);

    useEffect(() => {
        compareDetailsIfChangesMade();
    }, [
        profileVideo,
        videoUrlState,
    ]);

    useEffect(() => {
        let pv = [];
        let gv = [];

        if (profileVideos && profileVideos.length > 0) {
            profileVideos.map((item) => {
                if (!item.reviewedStatus && item.videoCategory !== AppConstants.VIDEO_TYPE.GAME_VIDEO) {
                    pv.push(item);
                } else {
                    gv.push(item);
                }
            });
        }
        if (pv?.length > 0) {
            setProfileVideoState(pv);
        }

        if (gv?.length > 0) {
            setGameVideosState(gv);
        }
    }, []);

    useEffect(() => {
        compareProfileVideoDetails();
    }, [profileVideo])

    const compareDetailsIfChangesMade = () => {
        let equalVideoUrlState = deepEqual(
            videoUrlState,
            coachingVideos && coachingVideos.externalLinks
                ? coachingVideos.externalLinks
                : []
        );
        let equalProfileVideo = deepEqual([...profileVideoState, ...gameVideosState], profileVideo);

        if (equalProfileVideo && equalVideoUrlState) {
            setChangesMade(false);
        } else {
            setChangesMade(true);
        }
    }

    const handelGameVideosOnChange = (data) => {
        //... If their is only one video in the list. Set it as primary video.
        if (data.length == 1) {
            data[0].isPrimaryVideo = true;
        }

        setIsInValid(false);
        setGameVideosState(data);
    }

    const handleProfileVideosOnChange = (data, category) => {
        setIsInValid(false);
        setProfileVideoState(data)
    }

    const compareProfileVideoDetails = () => {
        let deepequal = require("deep-equal");
        let equal = deepequal([...profileVideoState, ...gameVideosState], profileVideos);
        if (equal) setChangesMade(false);
        else setChangesMade(true);
    }

    const updateProfileVideoState2 = (videoUrl, obj) => {
        var data = profileVideoState;
        let itemIndex = -1;
        if (data && data.length > 0) {
            itemIndex = data.findIndex(x => x.id == obj.id);
        }
        if (itemIndex > -1) {
            data.forEach(element => {
                if (element.id == obj.id) {
                    element.videoURL = videoUrl;
                }
            });
        }
        else {
            obj.videoURL = videoUrl;
            data.push(obj);
        }
        setProfileVideoState(data);
    };

    const updateGameVideoState2 = (videoUrl, obj) => {
        var data = gameVideosState;
        let itemIndex = -1;
        if (data && data.length > 0) {
            itemIndex = data.findIndex(x => x.id == obj.id);
        }
        if (itemIndex > -1) {
            data.forEach(element => {
                if (element.id == obj.id) {
                    element.videoURL = videoUrl;
                }
            });
        }
        else {
            obj.videoURL = videoUrl;
            data.push(obj);
        }
        setGameVideosState(data);
    };

    const onProfileVideosDetailChange = () => {
        let dataCollection = [...profileVideoState, ...gameVideosState];
        let isValid = true;
        dataCollection.forEach(async d => {
            if (!d.videoURL || d.videoURL == "") {
                isValid = false;
                return
            }

            if (!d.title || d.title == "") {
                isValid = false;
                return
            }

            if (d.videoCategory === AppConstants.VIDEO_TYPE.GAME_VIDEO) {
                //... Set review message for saved data, based on review status
                const configKey = AppConstants.VideoReviewStatusToConfigKeyMapper[d.reviewedStatus || AppConstants.REVIEW_STATUS.PENDING];
                d.reviewedReason = gameVideoConfigValues[configKey];
                d.reviewedStatus = d.reviewedStatus || AppConstants.REVIEW_STATUS.PENDING
                d.videoCategory = d.videoCategory;
                d.isPrimaryVideo = d.isPrimaryVideo;
            }
        })

        if (isValid == false) {
            showSnackBar("error");
        } else {
            setChangesMade(false);
            setIsLoading(true);
            coachProfileService
                .updateProfileVideosDetails(dataCollection, userName)
                .then((data) => {
                    showSnackBar("success");
                    updateProfileVideosDetails(dataCollection);
                }, error => { showSnackBar("error"); })
                .catch((err) => {
                    showSnackBar("error");
                });
        }
    }

    const showSnackBar = (mode) => {
        let messageToShow = "";
        if (mode == "error") {
            setSeverity("error");
            messageToShow = "Please complete missing fields highlighted in red.";
            setIsInValid(true);
        }
        if (mode == "success") { setSeverity("success"); messageToShow = "Data saved successfully."; setIsInValid(false); }
        if (mode == "submit") { setSeverity("success"); messageToShow = "Thank you for submitting your profile"; setIsInValid(false); }
        setIsLoading(false);
        setErrorMessage(messageToShow);
        setShowError(true);
    }

    const handlePreviousStepClick = (index) => {
        props.handleStepClick(index);
    };

    const deleteProfileVideo = (url) => {
        setLoading(true);
        coachProfileService
            .deleteProfileVideo(url)
            .then((result) => {
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
            });
    }

    const onSave = () => {
        let gameVideos = [...gameVideosState];
        //... If there is only one game video uploaded. If yes. Set it as primary video

        if (gameVideos?.length == 1) {
            gameVideos[0].isPrimaryVideo = true;
        }

        let dataCollection = [...profileVideoState, ...gameVideos];
        let dataWithoutTitle = dataCollection.filter(x => x.title?.value?.trim() === '');
        if (dataWithoutTitle.length > 0) {
            if (dataWithoutTitle.length == 1) {
                let obj = dataWithoutTitle[0];
                if (obj.title && obj.title && obj.title.value == "" && obj.videoURL && obj.videoURL && obj.videoURL.value == "") {
                    onProfileVideosDetailChange();
                    return;
                }
            }

            for (let i = 0; i < dataWithoutTitle.length; i++) {
                dataWithoutTitle[i].title.hasError = true;
                dataWithoutTitle[i].videoURL.hasError = (dataWithoutTitle[i].videoURL?.value?.trim() === '') ? true : false;;
                // handleInputChange({ itemId: dataWithoutTitle[i].id, formData: dataWithoutTitle[i] }, true);
            }
        } else {
            if (deleteVideos && deleteVideos.length > 0) {
                deleteVideos.forEach(url => {
                    deleteProfileVideo(url);
                })
            }
            setDeleteVideos([]);
            onProfileVideosDetailChange();
        }
        trackCustomEvents("EditEvent", { action: "Media" });
    }

    return (
        <div className={classes.root}>
            <Prompt
                when={changesMade}
                message={JSON.stringify({
                    confirmText: "Yes, Leave this page",
                    messageText: "You have unsaved changes that will be lose if you navigate away from this page. Are you sure you want to leave this page?",
                    cancelText: "Stay on this Page",
                })}
            />
            <TitleWithTooltip
                title="1. My Profile Videos"
                tooltipTitle="Uploaded profile video will be sent for approval to Admin."
                variant="body2"
                class={classes.question}
            />
            <CaptionMsg message="(You can upload maximum of 3 videos.)" />
            <ProfileVideos
                data={gameVideosState}
                userName={userName}
                onChange={handelGameVideosOnChange}
                onProfileVideoRemove={updateGameVideoState2}
                onSave={onSave}
                deleteProfileVideo={deleteProfileVideo}
                isLoading={isLoading}
                isInValid={isInValid}
                maxVideoUploadLimit={2}
                showBackButton={false}
                showSaveButton={false}
                videoCategory={AppConstants.VIDEO_TYPE.GAME_VIDEO}
            />
            <TitleWithTooltip
                title="2. Uploaded Media"
                tooltipTitle="Uploaded video/game will be sent for approval to Admin."
                variant="body2"
                class={classes.question}
            />
            <ProfileVideos
                data={profileVideoState}
                userName={userName}
                onChange={handleProfileVideosOnChange}
                onProfileVideoRemove={updateProfileVideoState2}
                onSave={onSave}
                deleteProfileVideo={deleteProfileVideo}
                handlePreveStepClick={() => handlePreviousStepClick(5)}
                isLoading={isLoading}
                isInValid={isInValid}
                showBackButton={true}
                showSaveButton={true}
                videoCategory={AppConstants.VIDEO_TYPE.PROFILE_VIDEO}
            />
            <SnackbarMsg
                showError={showError}
                onClose={() => setShowError(false)}
                severity={severity}
                msg={errorMessage}
            />
        </div>
    );
}