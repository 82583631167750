import { Grid } from "@material-ui/core/";
import { makeStyles } from "@material-ui/core/styles";
import React, { useContext } from "react";
import { AdminCoachProfileContext } from "../../../common/AdminCoachProfileContext";
import AppConstants from "../../../common/AppConstants";
import { UserProfileContext } from "../../../common/ProfileContext";
import { Role } from "../../../helper";
import { userService } from "../../../services";
import NotAvailable from "../../../sharedComponents/NotAvailable/NotAvailable";
import ViewProfileHeading from '../../../sharedComponents/ViewProfileHeading';
import MediaCard from "../../FAQ/MediaCard";
import Link from "@material-ui/core/Link";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  section: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
    textAlign: "left",
  },
  gridInRow: {
    display: "flex",
    justifyContent: "space-between",
    paddingLeft: "25px",
  },
  linkContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  linkIcon: {
    fontSize: "1.25rem",
    color: '#1d428a',
  },
  linkText: {
    textAlign: "left",
  },
}));

const ProfileVideoView = (props) => {

  const classes = useStyles();
  const { jwtTokenDetails } = useContext(userService.getUserRole() === Role.Coach ? UserProfileContext : AdminCoachProfileContext);
  const userName = userService.getUsername();

  const getCategoryBasedVideo = (videoType) => {
    if (videoType === AppConstants.VIDEO_TYPE.GAME_VIDEO) {
      if (userName !== props?.userName) {
        return props.data?.filter(item => item.videoCategory === videoType && item.reviewedStatus === AppConstants.REVIEW_STATUS.APPROVED);
      }
      return props.data?.filter(item => item.videoCategory === videoType);
    }
    return props.data?.filter(item => !item.videoCategory);
  };

  const renderVideoCards = (category) => {
    const videos = getCategoryBasedVideo(category);
    return (
      videos && videos.length > 0 ? (
        videos.map((item, index) => (
          <Grid key={index} item xs={12} sm={6} md={4} lg={4} xl={3}>
            <MediaCard data={item} jwtTokenDetails={jwtTokenDetails} />
          </Grid>
        ))
      ) : <Grid item md={12} xs={12} className={classes.gridInRow}>
        <Grid container>
          <NotAvailable />
        </Grid>
      </Grid>
    );
  }

  return (
    <div className={classes.root}>
      <div className={classes.section}>
        <ViewProfileHeading title="My Profile Video" />
        <Grid container spacing={2} >
          {renderVideoCards(AppConstants.VIDEO_TYPE.GAME_VIDEO)}
        </Grid>
      </div>
      <div className={classes.section}>
        <ViewProfileHeading title="Uploaded Media" />
        <Grid container spacing={2} >
          {renderVideoCards(AppConstants.VIDEO_TYPE.PROFILE_VIDEO)}
        </Grid>
      </div>
      <div className={classes.section}>
        <ViewProfileHeading title="Video Links" />
        <div>
          {props && props.coachingVideos && props.coachingVideos.externalLinks && props.coachingVideos.externalLinks.length > 0 &&
            props.coachingVideos.externalLinks.map((link, index) => {
              if (link && link.label && link.label !== '') {
                return (
                  <div key={index} className={classes.linkContainer}>
                    <Link
                      variant="body1"
                      className={classes.linkText}
                      href={link.url}
                      target="blank"
                    >
                      {link.label}
                    </Link>
                    <OpenInNewIcon className={classes.linkIcon} />
                  </div>
                );
              } else {
                return <NotAvailable />;
              }
            })
          }
        </div>
      </div>
    </div>
  );
};

export default ProfileVideoView;
