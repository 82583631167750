import { Button, CardContent, Tooltip, useTheme } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardMedia from '@material-ui/core/CardMedia';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import React, { useEffect, useState } from 'react';
import AppConstants from '../../common/AppConstants';
import helper from "../../common/Helper";
import playIcon from '../../images/app_logo_small.png';
import { userService } from '../../services';
import CustomizedDialogs from "../../sharedComponents/dialog/jwPlayerDialog";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        width: "100%",
        height: '100%',
        flexDirection: 'column',
        borderRadius: '5px !important',
        boxShadow: '0px 3px 5px 2px rgba(0, 0, 0, 0.1)',
    },
    details: {
        display: 'flex',
        flexDirection: 'column',
    },
    content: {
        flex: '1 0 auto',
    },
    cover: {
        width: '100%',
        height: '145px',
        objectFit: 'contain'
    },
    controls: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    playVideo: {
        position: 'absolute',
        top: '39px',
        left: 'calc(50% - 39px)',
        '& > button': {
            background: 'rgba(0, 0, 0, 0.54)',
            color: '#ffffff',
            '&:hover': {
                background: 'rgba(0, 0, 0, 0.54)',
                color: '#ffffff',
            },
        },
    },
    playIcon: {
        height: 38,
        width: 38,
    },
}));

export default function MediaCard(props) {

    const classes = useStyles();
    const theme = useTheme();
    const [openDialog, setOpenDialog] = useState(false);
    const [videoSource, setVideoSource] = useState('');


    useEffect(() => {
        let fileName = !props.data.sources ? props.data.videoURL : props.data.sources;
        let service = !props.data.sources ? userService.getProfileVideosSignedUrl : userService.getTrainingVideoSignedUrl;
        service(fileName)
            .then(videoUrl => {
                setVideoSource(videoUrl);
            })
    }, []);

    const playVideo = (event) => {
        event.preventDefault();
        setOpenDialog(true);
    }

    return (
        <>
            <Card className={classes.root}>
                <Card className={classes.root}>
                    <CardActionArea>
                        <CardMedia
                            component="img"
                            alt={props.data.title}
                            className={classes.cover}
                            image={playIcon}
                            title={props.data.title}
                            src={props.data.sources}
                        />
                        <CardActions className={classes.playVideo}>
                            <IconButton aria-label="play/pause">
                                <PlayArrowIcon className={classes.playIcon}
                                    onClick={(e) =>
                                        playVideo(e)} />
                            </IconButton>
                        </CardActions>
                        <CardContent>
                            <Typography gutterBottom variant="body1" title={props.data.title} component="h5">
                                {helper.StringExcerpt(props.data?.title, 24)}
                            </Typography>
                            <Tooltip title={props.data?.reviewedReason}>
                                <Button
                                    variant="outlined"
                                    startIcon={AppConstants.SeverityBasedIconMapper[props.data?.reviewedStatus]}
                                    style={{
                                        borderRadius: 16,
                                        color: theme.palette[AppConstants.StatusSeverityToColorMapper[props.data?.reviewedStatus]].dark,
                                        borderColor: theme.palette[AppConstants.StatusSeverityToColorMapper[props.data?.reviewedStatus]].dark
                                    }}> {props.data?.reviewedStatus}
                                </Button>
                            </Tooltip>
                        </CardContent>
                    </CardActionArea>
                </Card>
            </Card >
            {
                <CustomizedDialogs
                    open={openDialog}
                    title="View Video"
                    data={{
                        jwtTokenDetails: props.jwtTokenDetails,
                        playExternalVideo: true,
                        videoURL: videoSource,
                        title: props.data?.title ? props.data?.title : AppConstants.VIDEO_PLAYER.TRAINING_VIDEOS_TITLE
                    }}
                    onClose={() => setOpenDialog(false)} />
            }
        </>
    );
}