import React, { useEffect, useState } from "react";
import "./AutoCompleteInput.css";
import { FormControl, TextField, Typography } from "@material-ui/core";
import Fade from "react-reveal/Fade";
import useSmartSearchService from "../../../services/useSmartSearch.service";
import { useAppData } from '../../../common/AppContext';

let fullSentence = "";
let matchObj = [];
let currentMatchIndex = 0;
let counter = 0;

const AutoCompleteInput = ({ onSearch, onPreviewSuggestionsChange, clearFilter }) => {
    const { getPreviewSuggestions } = useSmartSearchService();
    const { proxyAPISettings } = useAppData();

    const [text, setText] = useState("");
    const [mirroredText, setMirroredText] = useState("");
    const [searchTips, setSearchTips] = useState(
        "Try entering: name all players from NBA"
    );
    const [searchTipVisibility, setSearchTipVisibility] = useState(true);
    const [previewSuggestions, setPreviewSuggestions] = useState([]);

    const searchTipText = [
        "Try entering: name all players from NBA",
        "Hint: Press TAB key to auto complete",
        "Try entering: show all head coach from NBA",
        "Try entering: list all assistant coach Aaron Miles had",
        "Try entering: Head coaches currently with any league",
        "Can't find what you are looking for? Try advanced search",
    ];

    const fillPreviewSuggestions = async () => {
        const response = await getPreviewSuggestions();
        let suggestions = [];

        if (response && response.data) {
            suggestions = response.data.map(item => ({ [item]: "entity" }));
        }

        setPreviewSuggestions(suggestions);
    }

    useEffect(() => {
        setInterval(() => change(), 8000);

    }, []);

    useEffect(() => {
        //... Wait for the API proxy settings URL and key to be fetched from the coaches db config.
        if (proxyAPISettings !== null && proxyAPISettings !== undefined && Object.keys(proxyAPISettings).length !== 0) {
            fillPreviewSuggestions();
        }
    }, [proxyAPISettings]);

    function change() {
        setTimeout(() => {
            setSearchTips(searchTipText[counter]);
            setSearchTipVisibility(true);
        }, 1500);

        setSearchTipVisibility(false);
        counter++;

        if (counter >= searchTipText.length) {
            counter = 0;
        }
    }

    // let suggestionsObj = [
    //     {
    //         "name all players from NBA": "condition",
    //     },
    //     {
    //         "show all head coach from NBA": "condition",
    //     },
    //     {
    //         "list all assistant coach Aaron Miles had": "condition",
    //     },
    //     {
    //         "show all coaches currently with a team": "condition",
    //     },
    //     {
    //         "show all coaches currently not with a team": "condition",
    //     },
    //     {
    //         "currently with a team": "condition",
    //     },
    //     {
    //         "currently not with any team": "condition",
    //     },
    //     {
    //         "currently with": "condition",
    //     },
    //     {
    //         "currently not with": "condition",
    //     },
    //     {
    //         "with skill": "condition",
    //     },

    //     {
    //         team: "condition",
    //     },
    //     {
    //         "any team": "condition",
    //     },
    //     {
    //         another: "condition",
    //     },
    //     {
    //         players: "playerType",
    //     },
    //     {
    //         NBA: "league",
    //     },
    //     {
    //         GLeague: "league",
    //     },
    //     {
    //         "head coach": "role",
    //     },
    //     {
    //         "assistant coach": "role",
    //     },
    //     {
    //         "Aaron Miles": "player",
    //     },
    //     {
    //         "Adam Caporn": "player",
    //     },
    //     {
    //         "Adam Harrington": "player",
    //     },
    //     {
    //         "Adrian Griffin": "player",
    //     },
    //     {
    //         "Alton Lister": "player",
    //     },
    // ];

    const hideSuggestion = () => {
        setMirroredText("");
    };

    const findIndexOfCurrentWord = (e) => {
        // Get current value and cursor position
        const currentValue = e.target.value;
        const cursorPos = e.target.selectionStart;

        // Iterate backwards through characters until we find a space or newline character
        let startIndex = cursorPos - 1;
        while (startIndex >= 0 && !/\s/.test(currentValue[startIndex])) {
            startIndex--;
        }
        return startIndex;
    };

    const previewSuggestion = (e) => {
        if (matchObj.length === 0) {
            hideSuggestion();
            return;
        }

        const currentValue = e.target.value;
        const cursorPos = e.target.selectionStart;
        const textBeforeCursor = currentValue.substring(0, cursorPos);

        let postCursorEleText = "";
        for (let key in matchObj[currentMatchIndex]) {
            postCursorEleText = key;
        }

        setMirroredText(postCursorEleText);
    };

    const replaceCurrentWord = (e, newWord, endWord) => {
        const currentValue = e.target.value;
        const cursorPos = e.target.selectionStart;
        const startIndex = findIndexOfCurrentWord(e);

        //curWord = endWord;
        fullSentence = currentValue.substring(0, startIndex + 1) + endWord;

        const newValue =
            currentValue.substring(0, startIndex + 1) +
            newWord +
            currentValue.substring(cursorPos);
        setText(newValue);
        //inputEle.focus();
        //inputEle.selectionStart = inputEle.selectionEnd = startIndex + 1 + newWord.length;
    };

    const handleInputChange = (e) => {
        const currentValue = e.target.value;
        setText(currentValue);
        fullSentence = currentValue;
        const cursorPos = e.target.selectionStart;
        if (cursorPos !== currentValue.length) {
            hideSuggestion();
            return;
        }

        const startIndex = findIndexOfCurrentWord(e);
        // Extract just the current word
        const currentWord = currentValue.substring(startIndex + 1, cursorPos);
        if (currentWord === "") {
            hideSuggestion();
            return;
        }

        let mo = [];
        for (let i = 0; i < previewSuggestions.length; i++) {
            for (let key in previewSuggestions[i]) {
                if (key && key.toLowerCase().startsWith(currentWord.toLowerCase())) {
                    mo.push(previewSuggestions[i]);
                }
            }
        }

        matchObj = mo;
        currentMatchIndex = 0;
        previewSuggestion(e);
        onPreviewSuggestionsChange(mo);
    };

    const handleKeyDownChange = (e) => {
        const currentValue = e.target.value;
        const cursorPos = e.target.selectionStart;

        if (cursorPos !== currentValue.length) {
            return;
        }

        switch (e.key) {
            case "ArrowDown":

                if (matchObj.length > 0 && currentMatchIndex < matchObj.length - 1) {
                    e.preventDefault();
                    currentMatchIndex++;
                    previewSuggestion(e);
                }
                break;
            case "ArrowUp":
                if (matchObj.length > 0 && currentMatchIndex >= 1) {
                    e.preventDefault();
                    currentMatchIndex--;
                    previewSuggestion(e);
                }
                break;
            case "Tab":
                e.preventDefault();
                replaceCurrentWord(e, mirroredText, text);
                hideSuggestion();
                break;

            case " ":
                break;

            case "Enter":
                break;

            default:
                break;
        }
    };

    const handleKeyUpChange = (e) => {
        if (e.keyCode === 13) {
            onSearch(text);
        }
    }

    const handlePress = (e) => {

        const currentValue = e.target.value;
        const cursorPos = e.target.selectionStart;

        if (cursorPos !== currentValue.length) {
            return;
        }

        replaceCurrentWord(e, mirroredText, text);
        hideSuggestion();
    };

    // const clearFilter = () => {
    //     setText("");
    //     hideSuggestion();
    // }

    useEffect(() => {
        if (clearFilter === true) {
            setText("");
            hideSuggestion();
        }
    }, [clearFilter]);

    return (
        <div id="autoCompleteTextInput">
            <FormControl fullWidth style={{ backgroundColor: 'white' }}>
                <Typography
                    style={{
                        display: "flex",
                        alignItems: "center",
                        paddingLeft: "14px",
                        height: "40px",
                    }}
                >
                    <span style={{ color: "transparent" }}>{text}</span>
                    <span>&nbsp;</span>
                    <span
                        style={{ color: "red", opacity: 0.4, fontStyle: "italic", textOverflow: 'ellipsis', textWrap: 'nowrap', overflow: 'hidden' }}
                    >{`${mirroredText}`}</span>
                </Typography>
                <TextField
                    id="search"
                    variant="outlined"
                    value={text}
                    size="small"
                    onChange={(e) => handleInputChange(e)}
                    onKeyDown={(e) => handleKeyDownChange(e)}
                    onKeyUp={(e) => handleKeyUpChange(e)}
                    onTouchStart={(e) => handlePress(e)}
                    fullWidth
                    autoComplete={false}
                    style={{ position: "absolute" }}
                    helperText={<Typography variant="body2" style={{ color: 'white' }}><Fade when={searchTipVisibility}>{searchTips}</Fade></Typography>}
                />
            </FormControl>
        </div>
    );
};

export default AutoCompleteInput;
