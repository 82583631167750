import React, { useState, useEffect, useRef, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import  NotAvailable from "../../../../sharedComponents/NotAvailable/NotAvailable";
import ViewProfileHeading from '../../../../sharedComponents/ViewProfileHeading';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  section: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
    textAlign: "left",
  },
  arrayText: {
    fontSize: "12px",
    color: "black",
  },
  arrayItem: {
    paddingLeft: "25px",
    color: "black",
    font: '8px'
  },
}));

export default function ShowArrayList(props) {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <ViewProfileHeading title={props.heading} />
      <Grid container className={classes.arrayItem}>
        {props.data && props.data.length > 0 &&
          <Typography className={classes.arrayText} >{props.data.join(", ")}</Typography>
        }
        {(!props.data || props.data.length == 0 ||props.data[0]=="") &&
          <NotAvailable/>
        }
      </Grid>

    </div>
  );
}
