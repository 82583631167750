import React, { useState, useEffect, useContext } from "react";
import HeadShot from "../../sharedComponents/profilePhoto/HeadShot";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Box, Container, Typography, Card, CardContent } from "@material-ui/core";



const handleStyle = { left: 10 };

const useStyles = makeStyles((theme) => ({
	coachName: {
		'&.MuiCard-root': {
			borderRadius: theme.spacing(2),
			border: '2.5px solid #C5C5C5',
			margin: theme.spacing(0, 1, 1, 1),
			'& .MuiCardContent-root': {
				padding: theme.spacing(1, 2, 1, 1),
				display: 'flex',
				alignItems: 'center',
				'& .MuiTypography-root': {
					fontWeight: '500',
					marginLeft: theme.spacing(1.25),
				},
			},			
		},		
		fontFamily: "'Lato', sans-serif",
		fontWeight: '500',
		fontSize: theme.spacing(2.25),
		lineHeight: '21.6px'


	},
	mainCoach: {
		'&.MuiCard-root': {
			display: 'flex',
			justifyContent: 'center',
			border: 'none',
			'& .MuiCardContent-root': {
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				background: '#fff',
				border: '1px dashed #000000',
				boxShadow: 'inset 0px 0px 25px rgba(0, 0, 0, 0.15)',
				borderRadius: theme.spacing(3),
				padding: theme.spacing(2, 10),

				[theme.breakpoints.down('xs')]: {
					background: '#fff',
					border: '1px dashed #000000',
					boxShadow: 'inset 0px 0px 25px rgba(0, 0, 0, 0.15)',
					borderRadius: theme.spacing(3),
					padding: theme.spacing(2, 10),
				},
			},
		},
		

	},
	mainAvatar: {
		width: theme.spacing(6),
		height: theme.spacing(6),
		border: "2px solid #e8e8e8",
	},
	mainAvatarL: {
		width: theme.spacing(12.5),
		height: theme.spacing(12.5),
		border: "3px solid #bdbdbd",
		boxShadow: '0px 3px 5px #bdbdbd'
	},
	mainCoachName: {
		fontFamily: "'Lato', sans-serif",
		fontWeight: '700',
		fontSize: theme.spacing(2.37),
		lineHeight: '22.8px',
		[theme.breakpoints.down('xs')]: {
			fontSize: theme.spacing(2),
			lineHeight: '19.2px',
		},

	},
	otherCoachName: {
		fontFamily: "'Lato', sans-serif",
		fontWeight: '400',
		lineHeight: '14.4px',
		fontSize: theme.spacing(1.5),
		[theme.breakpoints.down('xs')]: {
			fontSize: theme.spacing(2.25),
			fontWeight: '500',
			lineHeight: '24px'
		},

	},
	pHeadCoach: {
		'&.MuiCard-root': {
			border: '2.5px solid #ff781f',
		}
	},
	cHeadCoach: {
		'&.MuiCard-root': {
			border: '2.5px solid #3897FF',
		}
	},
	assistantCoach: {
		'&.MuiCard-root': {
			border: '2.5px solid #3897FF',
		}
	},
	player: {
		'&.MuiCard-root': {
			border: '2.5px solid #ff781f',
		}
	},
	activeCoaches: {
		cursor: 'pointer'
	}
}));

function CoachesNode({ data, isMainCoach, handleCoachClick,backToProfile }) {
	const [name, setName] = useState('');
	const [lastName, setLastName] = useState('');
	const [otherCoachClass, setOtherCoachClass] = useState('')


	useEffect(() => {
		if (data && data.personalDetails && data.personalDetails.fullName) {
			let fullName = data.personalDetails.fullName;
			setName(fullName.firstName)
			setLastName(fullName.lastName)
		} else {
			if (data && data.name)
				setName(capitalizeFirstLetter(data.name))
			if (data.lastName) {
				setLastName(data.lastName)
			}
		}
		

		if (data) {
			if (data.coachType === 'playerheadCoach') {
				setOtherCoachClass(`${classes.coachName} ${classes.pHeadCoach}`)
			} else if (data.coachType === 'headCoach') {
				setOtherCoachClass(`${classes.coachName} ${classes.cHeadCoach}`)
			} else if (data.coachType === 'assistant' || data.coachType === 'sameStaff' ) {
				setOtherCoachClass(`${classes.coachName} ${classes.assistantCoach}`)
			} else if (data.coachType === 'player') {
				setOtherCoachClass(`${classes.coachName} ${classes.player}`)
			} 
		}

	}, [data])

	const classes = useStyles();

	const handleNodeClick = () => {
		if(isMainCoach){
			backToProfile();
		}
		else if (data.userName) {
			handleCoachClick(data.userName)
		}

	}

	const capitalizeFirstLetter = (name) => {
		return name.charAt(0).toUpperCase() + name.slice(1);
	}

	return (

		<Card className={isMainCoach ? classes.mainCoach : otherCoachClass} onClick={handleNodeClick} variant="outlined">
			<CardContent className={data.userName ? classes.activeCoaches : null}>
				<HeadShot
					className={isMainCoach ? classes.mainAvatarL : classes.mainAvatar}
					userName={data.userName}
					coachName={`${name ? capitalizeFirstLetter(name) : ''} ${lastName ? capitalizeFirstLetter(lastName) : ''}`}
					profilePhoto={
						isMainCoach ? data.personalDetails &&
							data.personalDetails.profilePhoto
							? data.personalDetails.profilePhoto
							: "" : data.profilePhoto
							? data.profilePhoto : ""
					}
				/>
				<Typography variant={isMainCoach ? "h6" : "subtitle1"} className={isMainCoach ? classes.mainCoachName : classes.otherCoachName}>{(capitalizeFirstLetter(name) + " " + capitalizeFirstLetter(lastName))}</Typography>
			</CardContent>
		</Card>
	);
}

export default CoachesNode;
