import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Hidden from "@material-ui/core/Hidden";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Recaptcha from "react-recaptcha";
import useRegistrationForm from "../../sharedComponents/customHooks/useRegistrationForm";
import SignupValidationSchema from "../../validationSchema/signupValidationSchema";
import nbaLogo from '../../images/nba_login.png';
import nbca_splash from '../../images/nbca_splash.png';
import "./CoachRegistration.css";

import APIHelper from "../../common/ApiHelper";
import APIURLConstants from "../../common/ApiURLConstants";
import RouteConstants from "../../common/RouteConstants";
import { userService } from "../../services";

import { Popover, PopoverHeader, PopoverBody } from "reactstrap";
import Card from "@material-ui/core/Card";
import ErrorPopover from "../../sharedComponents/dialog/ErrorPopover";
import red from "@material-ui/core/colors/red";
import Loader from '../../sharedComponents/Loader/Loader';

const primary800 = red["800"];

import zxcvbn from "zxcvbn";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  logoStrip: {
    display: 'flex',
    // flex: 1,
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'start',
    width: '100%',
    height: '150px',
    // paddingTop: '7%'
  },
  logo: {
    [theme.breakpoints.up("sm")]: {
      width: "425px",
      height: "170px",
    },
    [theme.breakpoints.down("md")]: {
      height: "83px",
      width: "260px",
    },
  },
  registrationBox: {
    width: "280px",
    position: "fixed",
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: theme.spacing(1.5),
    [theme.breakpoints.up("lg")]: {
      right: "10%",
      alignSelf: "flex-end",
      height: "550px",
    },
    [theme.breakpoints.down("sm")]: {
      alignSelf: "center",
      height: "666px",
      position: 'static',
      margin: theme.spacing(2),
    },
  },
  sectiondivider: {
    margin: theme.spacing(1),
  },
  section: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
    textAlign: "left",
    padding: "5px 25px",
  },
  passwordRule: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(1),
    color: "#fff",
  },
  sectionPopover: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    textAlign: "left",
  },
  popoverInnerSection: {
    '&::before': {
      position: 'absolute',
      display: 'block',
      content: "''",
      borderColor: 'transparent',
      borderStyle: 'solid',
      top: '0',
      right: '10px',
      borderWidth: '0 0.5rem 0.5rem 0.5rem',
      borderBottomColor: 'rgba(0, 0, 0, 0.25)',
    },
    '&::after': {
      position: 'absolute',
      display: 'block',
      content: "''",
      borderColor: 'transparent',
      borderStyle: 'solid',
      top: '1px',
      right: '10px',
      borderWidth: '0 0.5rem 0.5rem 0.5rem',
      borderBottomColor: '#fff',
    },
    background: '#fff !important',
    boxShadow: '0px 3px 5px 2px rgba(0, 0, 0, 0.5)',
    padding: theme.spacing(1),
    '& p': {
      color: '#c70e2d',
      fontSize: '.75rem',
      lineHeight: '1',
      padding: theme.spacing(.3, 0),
    },
  },
  nbaLogo: {
    width: '50px',
    paddingLeft: '10.5%',
    paddingRight: '2.5rem',
},
nbcaSplashLogo : {
    width: '100px',
    paddingLeft: '30px',
}
}));

//Move this to common components
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const CoachRegistration = (props) => {
  const classes = useStyles();

  const [invited, setInvited] = useState(false);
  let token = "";
  const [state, setState] = useState({
    username: "",
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
  });

  const [isHuman, setIsHuman] = useState(false);
  const [pwdNotMatch, setpwdNotMatch] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [captchaResponse, setCaptchaResponse] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [validUser, setValidUser] = useState(false);
  const [sitekey, setSitekey] = useState("");

  useEffect(() => {
    getSitekeyForRecaptcha();
    token = props.match.params.inviteToken;
    if (token != undefined) {
      setInvited(true);
      validateToken();
    }
  }, [])


  const getSitekeyForRecaptcha = () => {
    userService.getSitekeyForRecaptcha()
    .then(data => { 
      setSitekey(data);
    }, error => {
      setErrorMessage("Site key is not available for recaptcha");
      setShowError(true);
    }).catch((err) => {      
      setShowError(true);
      setErrorMessage(err ? err : "Something went wrong");
    });
  }
  const validateToken = () => {
    userService
      .validateToken(props.match.params.inviteToken)
      .then((data) => {
        setValidUser(true);
        setIsLoading(false);
        setShowError(false);
      }, error => {
        setIsLoading(false);
        setErrorMessage("You are not allowed to register at this time.");
        setShowError(true);
      })
      .catch((err) => {
        setIsLoading(false);
        setShowError(true);
        setErrorMessage(err ? err : "Something went wrong");
      });
  }

  const onSubmitForm = (validationErrors) => {
    //validationErrors: this is hack for now. need to correct it with useeffect
    if (values.password != values.confirmPassword) {
      setpwdNotMatch(true);
    } else {
      setpwdNotMatch(false);
    }
    let errorKeys = Object.keys(validationErrors);
    if (invited) {
      if (isHuman === true && pwdNotMatch === false && errorKeys.length === 0) {
        values.captchaResponse = captchaResponse;
        //submit the form
        setIsLoading(true);
        userService
          .registerInvitedUser(values, props.match.params.inviteToken)
          .then((data) => {
            setIsLoading(false);
            setIsHuman(false);
            grecaptcha.reset();
            setShowError(false);
            props.history.push({
              pathname: RouteConstants.COACH_LOGIN,
              state: undefined,
            });
          }, error => {
            setIsLoading(false);
            setIsHuman(false);
            grecaptcha.reset();
            setErrorMessage(error ? error : "Invalid Token");
            setShowError(true);
          })
          .catch((err) => {
            setIsLoading(false);
            setIsHuman(false);
            grecaptcha.reset();
            setShowError(true);
            setErrorMessage(err ? err : "Something went wrong");
          });
      } else if (
        isHuman === false &&
        pwdNotMatch === false &&
        errorKeys.length === 0
      ) {
        setShowError(true);
        setErrorMessage("Please validate the capcha");
      }

    }
    // else {
    //   if (isHuman === true && pwdNotMatch === false && errorKeys.length === 0) {
    //     values.captchaResponse = captchaResponse;
    //     //submit the form
    //     setIsLoading(true);
    //     userService
    //       .registerUser(values)
    //       .then((data) => {
    //         setIsLoading(false);
    //         setIsHuman(false);
    //         grecaptcha.reset();
    //         setShowError(false);
    //         props.history.push({
    //           pathname: RouteConstants.COACH_LOGIN,
    //           state: undefined,
    //         });
    //       })
    //       .catch((err) => {
    //         setIsLoading(false);
    //         setIsHuman(false);
    //         grecaptcha.reset();
    //         setShowError(true);
    //         setErrorMessage(err ? err : "Something went wrong");
    //       });
    //   } else if (
    //     isHuman === false &&
    //     pwdNotMatch === false &&
    //     errorKeys.length === 0
    //   ) {
    //     setShowError(true);
    //     setErrorMessage("Please validate the capcha");
    //   }
    // }
  };

  const {
    errors,
    handleInputChange,
    handleOnSubmit,
    values,
  } = useRegistrationForm(state, onSubmitForm, SignupValidationSchema);

  const verifyCallback = (response) => {
    if (response) {
      setCaptchaResponse(response);
      setIsHuman(true);
    }
  };

  const onLoadCallback = () => {
    setIsHuman(false);
  };

  const testedResult = zxcvbn(values.password);
  const createPasswordLabel = (result) => {
    switch (result.score) {
      case 0:
        return "Weak";
      case 1:
        return "Weak";
      case 2:
        return "Fair";
      case 3:
        return "Good";
      case 4:
        return "Strong";
      default:
        return "Weak";
    }
  };

  const [popoverOpen, setPopoverOpen] = useState(false);

  const handleOpen = () => setPopoverOpen(true);
  const handleClosePopOver = () => setPopoverOpen(false);

  const gotoLogin = () => {
    props.history.push(RouteConstants.COACH_LOGIN);
  }
  return (
    <div className={clsx("registration-bg", classes.root)}>
      <Hidden smDown>
        <div style={{ flex: 2 }}></div>
      </Hidden>
      <Hidden mdDown>
        <div className={clsx("registration-strip-bg", classes.logoStrip)}>
          {/* <img src={nbaLogo} className={classes.nbaLogo} /> */}
          <p className={clsx("appTitle")}>NBA Coaches Database</p>
        </div>
      </Hidden>
      <Hidden smDown>
                <div style={{ flex: 2, width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'flex-start', }}>
                    <img src={nbaLogo} className={classes.nbaLogo} />
                    <img src={nbca_splash} className={classes.nbcaSplashLogo} />
                </div>
      </Hidden>

      <Paper className={classes.registrationBox} elevation={10}>
        <Hidden lgUp>
          <div className={clsx("app-logo-small", classes.logo)}></div>
        </Hidden>

        <div className={classes.sectiondivider}></div>
        <Typography variant="h5" color="secondary">
          Create New Account
        </Typography>
        <div className={classes.sectiondivider}></div>
        <TextField
          variant="outlined"
          name="username"
          value={values.username}
          id="usename"
          size="small"
          label="Username"
          onChange={handleInputChange}
          fullWidth
          error={errors && errors.username && errors.username.error}
        ></TextField>
        <ErrorPopover target="usename" isOpen={errors && errors.username && errors.username.error} for="Username" />
        <div className={classes.sectiondivider}></div>
        <TextField
          variant="outlined"
          id="coachFirstName"
          value={values.firstName}
          name="firstName"
          size="small"
          label="First Name"
          onChange={handleInputChange}
          fullWidth
          error={errors && errors.firstName && errors.firstName.error}

        ></TextField>
        <ErrorPopover target="coachFirstName" isOpen={errors && errors.firstName && errors.firstName.error} for="FirstName" />
        <div className={classes.sectiondivider}></div>
        <TextField
          variant="outlined"
          id="coachLastName"
          value={values.lastName}
          name="lastName"
          size="small"
          label="Last Name"
          onChange={handleInputChange}
          fullWidth
          error={errors && errors.lastName && errors.lastName.error}
        ></TextField>
        <ErrorPopover target="coachLastName" isOpen={errors && errors.lastName && errors.lastName.error} for="LastName" />

        <div className={classes.sectiondivider}></div>

        {/* <TextField
          variant="outlined"
          id="coachEmailId"
          name="email"          
          size="small"
          label="Email"
          onChange={handleInputChange}
          value={values.email}
          fullWidth
          error={errors && errors.email && errors.email.error}
          disabled={invited}
        ></TextField>
        <ErrorPopover target="coachEmailId" isOpen={errors && errors.email && errors.email.error} for="Email" />
        <div className={classes.sectiondivider}></div> */}
        <TextField
          type="password"
          id="coachPassword"
          name="password"
          variant="outlined"
          onFocus={handleOpen}
          onBlur={handleClosePopOver}
          size="small"
          label="Create Password"
          onChange={handleInputChange}
          error={errors && errors.password && errors.password.error}
          fullWidth
        ></TextField>

        <ErrorPopover target="coachPassword" isOpen={popoverOpen || (errors && errors.password && errors.password.error)} for="Password" />

        <div className="password-strength-meter">
          <progress
            value={testedResult.score}
            className={`password-strength-meter-progress strength-${createPasswordLabel(testedResult)}`}
            max="4"
          />
        </div>

        {/* <Typography>{values.password}</Typography> */}
        <div className={classes.sectiondivider}></div>
        <TextField
          type="password"
          id="coachConfirmPassword"
          variant="outlined"
          size="small"
          name="confirmPassword"
          label="Confirm Password"
          onChange={handleInputChange}
          fullWidth
          error={
            errors && errors.confirmPassword && errors.confirmPassword.error
          }
        ></TextField>
        <ErrorPopover target="coachConfirmPassword" isOpen={errors && errors.confirmPassword && errors.confirmPassword.error} for="ConfirmPassword" />

        <div className={classes.sectiondivider}></div>
        {sitekey != "" &&
          <Recaptcha
          width="80%"
          sitekey={sitekey}
          render="explicit"
          verifyCallback={verifyCallback}
          onloadCallback={onLoadCallback}
          expiredCallback={onLoadCallback}
        />
        }
        
        <div className={classes.sectiondivider}></div>
        <Button
          color="secondary"
          variant="contained"
          fullWidth
          onClick={handleOnSubmit}
          className="capitalize"
          disabled={isLoading || !validUser}
        //disabled={!isHuman}
        >
          Sign up
        </Button>
        <Button className="create-btn" variant="text" onClick={gotoLogin}>
          <Typography variant="caption">Already have an account? Sign in</Typography>
        </Button>
      </Paper>
      <Snackbar
        open={showError}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={() => setShowError(false)}
      >
        <Alert onClose={() => setShowError(false)} severity="error">
          {errorMessage}
        </Alert>
      </Snackbar>
      <Loader isLoading={isLoading} />
    </div>
  );
};

export default withRouter(CoachRegistration);
