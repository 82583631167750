const changePasswordValidationSchema = {
  currentPassword: {
    required: true,
  },
  password: {
    required: true,
  },
  confirmPassword: {
    required: true,
  },
};

export default changePasswordValidationSchema;
