import React, { useEffect, useState, useRef } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import ListItem from "@material-ui/core/ListItem";
import QualificationDetails from "../../../sharedComponents/questionComponents/QualificationDetails";
import SaveButton from "../../../sharedComponents/saveButton/SaveButton";
import BackButton from "../../../sharedComponents/saveButton/BackButton";
import CaptionMsg from "../../../sharedComponents/CaptionMsg";
import TitleWithTooltip from "../../../sharedComponents/TitleWithTooltip";
import useTelemetry from "../../../utils/useTelemetry";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  section: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  grid: {
    padding: theme.spacing(1),
    alignSelf: "center",
  },
  question: {
    color: theme.palette.info,
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(1),
    },
  },
}));

const EducationalDetails = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const { trackCustomEvents } = useTelemetry();

  const container = useRef(null);

  useEffect(() => {
    container.current.scrollIntoView({ block: 'end', behavior: 'smooth' });
  }, []);

  function save() {
    props.onChange();
    trackCustomEvents("EditEvent", { action: "Educational Details" });
  }


  return (
    <div ref={container} className={classes.root}>
      <div className={classes.section}>
        <TitleWithTooltip
          title="1. Please list any and all undergraduate degrees, graduate degrees, graduate certificates, coaching certifications, or similar qualifications, and the schools or organizations you received these from"
          tooltipTitle="Educational_ToolTip"
          variant="body2"
          class={classes.question}
        />
        {/* <CaptionMsg message= "(Please type in the values if you don't find them listed.)"/> */}
        <QualificationDetails
          data={props.data}
          degree={props.degrees}
          schools={props.schools}
          onChange={props.handleEducatinalDetalInputChange}
        />
      </div>
      <Grid item md={12} xs={12}>
        <ListItem style={{ justifyContent: "flex-end" }}>
          <BackButton
            onClick={props.handlePreveStepClick}
          ></BackButton>
          <SaveButton isLoading={props.isLoading} onClick={save}></SaveButton>
        </ListItem>
      </Grid>
    </div>
  );
};
export default EducationalDetails;
