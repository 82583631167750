import React, { useState, useContext, useEffect, useTheme, useRef } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
// import CheckIcon from '@material-ui/icons/Check';
import { Typography, Checkbox, Grid, Tooltip } from '@material-ui/core';
import PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import HeadShot from '../profilePhoto/HeadShot';

import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import ClearIcon from "@material-ui/icons/Clear";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    disabled: {
        color: '#A9A9A9'
    },
    selected: {
        color: theme.palette.primary.main
    },
    paddinRight: {
        paddingRight: theme.spacing(1),
    },
    font: {
        fontSize: ".60rem",
    },
    userList: {
        width: '100%',
        minHeight: '500px',
        maxHeight: '500px',
        overflowY: 'auto',
        padding: '24px 0 100px 24px',
        backgroundColor: theme.palette.background.paper
    },
    coachCss: {
        position: 'relative',
        '&:before': {
            position: 'absolute',
            left: '0',
            top: '10px',
            content: '""',
            width: '0px',
            height: '0px',
            borderRight: ' 15px solid transparent',
            borderBottom: '15px solid transparent',
            borderTop: '15px solid #c8102e'
        },
    },
    foCss: {
        position: 'relative',
        '&:before': {
            position: 'absolute',
            left: '0',
            top: '10px',
            content: '""',
            width: '0px',
            height: '0px',
            borderRight: ' 15px solid transparent',
            borderBottom: '15px solid transparent',
            borderTop: '15px solid #1d428a'
        },
    },
    coachFo: {
        position: 'relative',
        '&:before': {
            position: 'absolute',
            left: '0',
            top: '10px',
            content: '""',
            width: '0px',
            height: '0px',
            borderRight: ' 15px solid transparent',
            borderBottom: '15px solid transparent',
            borderTop: '15px solid orange'
        },
    },
    searchText: {
        paddingRight: '2rem'
    }
}));


const UserList = (props) => {
    const classes = useStyles();
    const userListRef = useRef();
    const [searchedText, setSearchText] = useState(props.searchText ? props.searchText : '');

    useEffect(() => {
        if(userListRef && userListRef.current && props.scrollTopList > 0)
        {
            userListRef.current.scrollTop = props.scrollTopList;
        }
    }, [props.scrollTopList]);

    const onChangeText = (text) => {
        if(props.onSearchTextChange)
         props.onSearchTextChange(text);
        setSearchText(text);
        if(props.setScrollTop)
            props.setScrollTop(0);
    }

    const handleSelect = (checked, id) => {
        props.handleSelectUser(checked, id);
        if (props.setScrollTop)
            props.setScrollTop(userListRef.current.scrollTop);
    }

    const renderUsersList = () => {
        let allUsers = props.users;
        if(searchedText != '')
        {
            allUsers = props.users.filter(u => u.personalDetails.fullName && u.personalDetails.fullName.firstName != null && 
                 u.personalDetails.fullName.firstName.toLowerCase().includes(searchedText.toLowerCase()) 
                ||  u.personalDetails.fullName.lastName != null && u.personalDetails.fullName.lastName.toLowerCase().includes(searchedText.toLowerCase()));
        }
        return allUsers && allUsers.map((user, index) => {
            return (
                <ListItem autoFocus key={user.userName} className={user.Role ? (user.Role == 'Coach' ? classes.coachCss : user.Role == 'FrontOffice' ? classes.foCss : classes.coachFo) : ''}>
                    {props.selectedUsers && <Checkbox checked={props.selectedUsers.includes(user.userName)}
                        id={user.userName} onChange={(event) => handleSelect(event.target.checked, user.userName)} />}

                    <ListItemAvatar>
                        <HeadShot userName={user.userName} profilePhoto={user.personalDetails.profilePhoto} />
                    </ListItemAvatar>
                    <ListItemText primary={`${user.personalDetails.fullName.firstName} ${user.personalDetails.fullName.lastName}`}/>

                </ListItem>
            )
        })

    }

    const handleSelectAllUsers = (e) => {
        if (e.target.checked) {
            let data = props.users.map((user) => user.userName)
            let finalData = [...props.selectedUsers].concat(data);
            finalData = finalData.filter((item, index) => {
                return (finalData.indexOf(item) == index)
            })
            props.setSelectedUsers(finalData)
        } else {
            let data = [...props.selectedUsers];
            props.users.filter(x => {
                let index = data.findIndex(y => y === x.userName);
                data.splice(index, 1);
            })

            props.setSelectedUsers(data)
        }

    }
    const getStatus = () => {
        let val = props.users.every(e => props.selectedUsers.includes(e.userName));
        return val;
    }
    return (
        <List className={classes.userList} ref={userListRef}>
            {props.selectedUsers && 
            <ListItem key='all'>
                <Checkbox title='Select All' checked={getStatus()} onChange={handleSelectAllUsers} />Select All
               {props.showLegend && props.value == 0 &&
                    <><div style={{ backgroundColor: '#c8102e', marginLeft: '2rem', padding: '2px', color: '#fff', fontSize: '12px' }}>Coach</div>
                        <div style={{ backgroundColor: '#1d428a', marginLeft: '5px', padding: '2px', color: '#fff', fontSize: '12px' }}>Front Office</div>
                        <div style={{ backgroundColor: 'orange', marginLeft: '5px', padding: '2px', color: '#fff', fontSize: '12px' }}>Front Office + Coach</div>
                    </>}
            </ListItem>}
            <TextField
                className={classes.searchText}
                autoFocus
                    fullWidth
                    value={searchedText}
                    onChange={(event) => onChangeText(event.target.value)}
                    size="small"
                    variant="outlined"
                    id="Search"
                    placeholder="Filter by either first name or last name"
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                {searchedText != "" && (
                                    <>
                                        <ClearIcon
                                            fontSize="small"
                                            onClick={() => onChangeText("")}
                                            style={{ cursor: "pointer" }}
                                        ></ClearIcon>
                                    </>
                                )}
                            </InputAdornment>
                        ),
                    }}
                />
            {renderUsersList()}
        </List>
    )
}

export default UserList;

UserList.defaultProps = {
    groupList: [],
    clearParticipants: false,
    setClearParticipants: () => { }
};
