import { IconButton, MenuItem, MenuList, Paper, Popper } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import React, { useContext, useEffect, useRef, useState } from "react";
import AppConstants from "../../common/AppConstants";
import UserProfileContext from "../../common/ProfileContext";
import { useGameVideo } from "../../context/GameVideoContext";
import HeadShot from "../../sharedComponents/profilePhoto/HeadShot";
import ReviewContentDialog from "../dialog/ReviewContentDialog";
import MediaCard from "./MediaCard";

const useStyles = makeStyles((theme) => ({
    gridItem: {
        [theme.breakpoints.up("sm")]: {
            width: "100%",
            padding: theme.spacing(1),
        },
        [theme.breakpoints.down("xs")]: {
            width: "100%",
            paddingBottom: theme.spacing(1),
        },
    },
    card: {
        height: "355px",
        border: "1px solid #e8e8e8",
        boxShadow: "0px 3px 5px 2px rgba(0, 0, 0, 0.1)",
        borderRadius: 16,
    },
    cardHeader: {
        height: "96px",
        overflow: "hidden",
        "& button": {
            padding: theme.spacing(0.5),
        },
        backgroundColor: "#C0CCE2"
    },
    cardContent: {
        height: "355px",
    },
    mainAvatar: {
        width: theme.spacing(8),
        height: theme.spacing(8),
        border: "2px solid #1D428A",
    },
    coachName: {
        fontWeight: "bolder",
        fontSize: "18px",
        textDecoration: "none",
    },
}));

export default function MediaCardList(props) {

    const classes = useStyles();
    const anchorRef = useRef(null);
    const { gameVideoConfigValues, handleGameVideoAction } = useGameVideo();
    const { jwtTokenDetails } = useContext(UserProfileContext);
    const [open, setOpen] = useState(false);
    const [fullName, setFullName] = useState(
        props.data && props.data.fullName
            ? props.data.fullName
            : {}
    );

    const [videoReturnDialog, setVideoReturnDialog] = useState(false);
    const [videoApproveDialog, setVideoApproveDialog] = useState(false);

    let Name = fullName.firstName + " " + fullName.lastName;

    useEffect(() => {
        setFullName(
            props.data && props.data.fullName
                ? props.data.fullName
                : {}
        );
    }, [props.data]);


    const handleToggle = () => {
        setOpen(!open);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };


    const handleApproveVideoUpload = () => {
        setVideoApproveDialog(true);
    };

    const handleRejectVideoUpload = () => {
        setVideoReturnDialog(true);
    };

    return (
        <Grid item xl={3} lg={4} md={6} sm={6} xs={12} className={classes.gridItem}>
            <Card className={classes.card}>
                <CardHeader
                    className={classes.cardHeader}
                    avatar={
                        <React.Fragment>
                            <HeadShot
                                userName={props.data.userName}
                                coachName={Name}
                                className={classes.mainAvatar}
                                profilePhoto={
                                    props.data.personalDetails &&
                                        props.data.personalDetails.profilePhoto
                                        ? props.data.personalDetails.profilePhoto
                                        : ""
                                }
                            />
                        </React.Fragment>
                    }
                    title={
                        <Typography style={{ cursor: "pointer", color: "#484e5b" }}>
                            {" "}
                            <span data-gaeventlabel={fullName.firstName + " " + fullName.lastName}
                                data-gaeventcategory="Coach Profile card click"
                                data-gaeventaction="Profile Name Click"
                                className={classes.coachName} onClick={() => !props.hideAction ? props.handleViewProfileClick(props.data.userName) : false}>
                                {" "}
                                {fullName.firstName} {fullName.lastName}{" "}
                            </span>
                        </Typography>
                    }
                    action={
                        <div>
                            <IconButton
                                ref={anchorRef}
                                aria-label="more"
                                aria-controls={open ? 'menu-list-grow' : undefined}
                                aria-haspopup="true"
                                onClick={handleToggle}
                            >
                                <MoreVertIcon style={{ color: "#484e5b" }} />
                            </IconButton>
                            <Popper style={{ zIndex: 99 }} placement='bottom-end' open={open} anchorEl={anchorRef.current} role={undefined} transition='fade' disablePortal>
                                {({ TransitionProps, placement }) => (
                                    <Paper>
                                        <ClickAwayListener onClickAway={handleClose}>
                                            <MenuList>
                                                <MenuItem onClick={handleApproveVideoUpload}>Approve</MenuItem>
                                                <MenuItem onClick={handleRejectVideoUpload}>Return</MenuItem>
                                            </MenuList>
                                        </ClickAwayListener>
                                    </Paper>
                                )}
                            </Popper>
                        </div>
                    }
                />
                <MediaCard
                    data={props?.data?.profileVideo}
                    jwtTokenDetails={jwtTokenDetails}
                />
            </Card>
            <ReviewContentDialog
                title="Return this Video/Game?"
                reviewMessageFunction={() => gameVideoConfigValues.returnedReasonVideo}
                onApproveOrReject={(note, sendMail) => {
                    setVideoReturnDialog(false);
                    handleGameVideoAction(props.data, AppConstants.REVIEW_STATUS.RETURNED, note)
                }}
                onClose={() => {
                    setVideoReturnDialog(false)
                }}
                open={videoReturnDialog}
                actionText="RETURN"
                showNotifyCheck={false}
            />
            <ReviewContentDialog
                title="Approve this Video/Game?"
                reviewMessageFunction={() => gameVideoConfigValues.approvedReasonVideo}
                onApproveOrReject={(note, sendMail) => {
                    setVideoApproveDialog(false);
                    handleGameVideoAction(props.data, AppConstants.REVIEW_STATUS.APPROVED, note)
                }}
                onClose={() => {
                    setVideoApproveDialog(false)
                }}
                open={videoApproveDialog}
                actionText="APPROVE"
                showNotifyCheck={false}
            />
        </Grid >
    );
}
