import React, { useEffect, useState } from 'react';
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import TextField from "@material-ui/core/TextField";
import Button from '@material-ui/core/Button';

import { configService } from "../../../services";
import SnackbarMsg from "../../../sharedComponents/Snackbar/Snackbar";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.up('sm')]: {
        },
    },
    heading: {
        fontWeight: 'bold',
        fontSize: '18px'
    },
    settingValue: {
        paddingTop: '2rem'
    },
    sectiondivider: {
        margin: theme.spacing(1),
      },
      buttonContainer: {
          paddingTop: '2rem',
          paddingBottom: '5px',
          paddingRight: '2rem',
          display: 'flex',
          justifyContent: 'flex-end'
      }
}));


export default function Settings(props) {
    const classes = useStyles();
    const [reminderDays, setReminderDays] = useState(30);
    const [showMsg, setShowMsg] = useState(false);
    const [messageType, setMessageType] = useState('Success');
    const [message, setMessage] = useState("Settings saved successfully.");

    useEffect(() => {
        getReminderDays();
    }, []);

    const getReminderDays = () => {
        configService.getReminderDays()
        .then(data => { 
          setReminderDays(data);
        }).catch((err) => {  
            console.log(err)    
        });
      }

    const handleChangeReminderDays = (e) => {
        setReminderDays(e.target.value);
    }

    const saveReminderDays = () => {
        if(reminderDays == '') {
            setMessageType('error');
            setMessage('Number of days cannot be empty!');
            setShowMsg(true);
        }
        else
        {
            var postData = {key: 'profileReminderDays', value: reminderDays};
            configService.setReminderDays(postData)
            .then(data => { 
                setMessageType('success');
                setMessage('Settings saved successfully.');
                setShowMsg(true);
            }).catch((err) => {  
                console.log(err)    
            });
        }
    }

    return (
        <div className={classes.root}>
            <Grid container>
                <div className={classes.sectiondivider}></div>
                <TextField
                    variant="outlined"
                    name="Reminder Days"
                    value={reminderDays}
                    id="reminder"
                    size="small"
                    label="Coach profile update reminder days"
                    onChange={handleChangeReminderDays}
                    fullWidth
                    type="number"
                ></TextField>
            </Grid>
            <Grid container className={classes.buttonContainer}>
                <Button variant="contained" color="primary" onClick={saveReminderDays}>
                    Save Settings
                </Button>
            </Grid>
            <SnackbarMsg
                    showError={showMsg}
                    onClose={() => setShowMsg(false)}
                    severity={messageType}
                    msg={message}
                />
        </div>
    );

}
