import { BehaviorSubject } from 'rxjs';

import { handleResponse ,handleError} from '../helper';
import APIHelper from "../common/ApiHelper";
import APIURLConstants from "../common/ApiURLConstants";

const currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('currentUser')));

export const authenticationService = {
    login,
    logout,
    forgotPassword,
    forgotUsername,
    resetPassword,
    updateName,
    currentUser: currentUserSubject.asObservable(),
    get currentUserValue() { return currentUserSubject.value }
};

function login(username, password) {
    let postData = { username, password };
    return APIHelper.post(APIURLConstants.AUTHENTICATE_USER, postData)
        .then(handleResponse)
        .then(user => {
            let randNum = Math.floor((Math.random() * 20) + 1);
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('currentUser', JSON.stringify(user));
            localStorage.setItem('randomRecordsNumber', randNum );
            currentUserSubject.next(user);
            return user;
        }).catch(handleError);
}

function forgotPassword(postdata) {
    return APIHelper.post(APIURLConstants.FORGOT_PASSWORD, postdata)
        .then(handleResponse)
        .then(data => {
            return data;
        }).catch(handleError);
}

function forgotUsername(postdata) {    
    return APIHelper.post(APIURLConstants.FORGOT_USERNAME, postdata)
        .then(data => {
            return data;
        }).catch(handleError);
}


function resetPassword(NewPassword, resettoken) {
    let postData = { resettoken, NewPassword };
    return APIHelper.post(APIURLConstants.RESET_PASSWORD, postData)
        .then(handleResponse)
        .then(data => {
            return data;
        }).catch(handleError);
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    localStorage.removeItem('randomRecordsNumber');
    localStorage.clear();
    currentUserSubject.next(null);
}


function updateName(firstname,lastname) {
    // update user's first name and last name from local storage 
   var user= localStorage.getItem('currentUser');
   if(user){
     let userObj = JSON.parse(user);
       userObj.firstName = firstname;
       userObj.lastName = lastname;
       localStorage.setItem('currentUser', JSON.stringify(userObj));
       currentUserSubject.next(userObj);
   }
}