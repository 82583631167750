const RouteConstants = {
  COACH_LOGIN: "/",
  PRIVACY_POLICY: "/privacypolicy",
  COACH_LOGOUT: "/logout",
  FORGOT_PASSWORD: "/forgotpassword",
  FORGOT_USERNAME: "/forgotusername",
  RESET_PASSWORD: "/resetpassword/:token",
  COACH_REGISTRATION: "/signup",
  COACH_CHANGE_PASSWORD: "/changepassword",
  COACH_CHANGE_EMAIL: "/update-email",
  COACH_HELP_DESK: "/helpdesk",
  COACH_FAQ: "/faq",
  TERMS_AND_CONDITIONS: "/termsandconditions",
  COACH_PROFILE_VIEW: "/view",
  COACH_PROFILE_VIEW2: "/view2",
  COACHES_PROFILE_VIEW: "/Coaches/View",
  COACH_PROFILE_EDIT: "/edit",
  ADMIN_COACH_PROFILE_EDIT: "/admin/coach/edit",
  DASHBOARD: "/frontoffice/coaches",
  COACH_DASHBOARD: "/coaches",
  ADMIN_DASHBOARD: "/admin/dashboard",
  USER_MANAGEMENT: "/admin/users",
  //USER_ROLE: "/admin/role",
  COACH_MANAGEMENT: "/admin/coaches",
  COACH_MANAGEMENT_New: "/admin/coachesmanagement",
  ADMIN_USER: "/admin/user",
  INVITE_USER: "/admin/inviteuser",
  INVITE_SIGNUP: "/invite-signup/:inviteToken",
  ADMIN_MEMO: "/admin/memo",
  ADMIN_SHOWCASE: "/admin/showcase",
  ADMIN_EDIT_MEMO: "/admin/memo/edit",
  ADMIN_ADD_MEMO: "/admin/memo/create",
  ADMIN_VIEW_MEMO: "/admin/memo/view",
  ADMIN_GROUP: "/admin/group/view",
  COACH_VIEW_USER_MEMO: "/coach/usermemo/view",
  ADMIN_DATA: "/admin/data/view",
  ACTIVE_SHOWCASE: "/frontoffice/showcase",
  ADMIN_SETTINGS: "/settings",
  COACHES_TREE: "/coaches/tree",
  DISCOVERY_PAGE: "/discovery",
  CREATE_SOCIAL_POST: "/createSocialPost",
  SOCIAL_COMMUNITY_CONTAINER: "/socialCommunity",
  MEDIA_MANAGEMENT: "/mediaManagement",

};

export default RouteConstants;