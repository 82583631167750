import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';

import useForm from '../customHooks/useForm';

const useStyles = makeStyles((theme) => ({
    grid: {
        padding: theme.spacing(1),
        alignSelf: 'center'
    },
    question: {
        color: theme.palette.primary.main,
        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(1)
        },
    }
}));

const FullName = ((props) => {
    const classes = useStyles();
    const [state, setState] = useState(props.data);

    useEffect(() => {
       setState(props.data);
    }, [props.data]);

    const { errors, handleInputChange, values } = useForm(state, props.onChange, props.validationSchema);
    return (
        <Grid container>
            <Grid item md={3} xs={6} className={classes.grid}>
                <TextField id="lastName" variant="outlined"
                    label={<Typography variant="body2">Last Name</Typography>}
                    size="small" fullWidth
                    value={values.lastName}
                    name="lastName"
                    onChange={handleInputChange}
                    error={errors && errors.lastName && errors.lastName.error} />
            </Grid>
            <Grid item md={3} xs={6} className={classes.grid}>
                <TextField id="firstName" variant="outlined"
                    label={<Typography variant="body2">First Name</Typography>}
                    size="small" fullWidth
                    value={values.firstName}
                    name="firstName"
                    onChange={handleInputChange}
                    error={errors && errors.firstName && errors.firstName.error} />
            </Grid>
            <Grid item md={3} xs={6} className={classes.grid}>
                <TextField id="middleName" variant="outlined"
                    label={<Typography variant="body2">Middle Name</Typography>}
                    size="small" fullWidth
                    value={values.middleName}
                    name="middleName"
                    onChange={handleInputChange}
                    error={errors && errors.middleName && errors.middleName.error} />
            </Grid>
            <Grid item md={3} xs={6} className={classes.grid}>
                <TextField id="suffix" variant="outlined"
                    label={<Typography variant="body2">Suffix</Typography>}
                    size="small" fullWidth
                    value={values.suffix}
                    name="suffix"
                    onChange={handleInputChange}
                    error={errors && errors.suffix && errors.suffix.error} />
            </Grid>
        </Grid>
    )
})

export default FullName;