import { authHeader, handleResponse, handleError } from '../helper';
import APIHelper from "../common/ApiHelper";
import APIURLConstants from "../common/ApiURLConstants";

export const coachProfileService = {
    getCoachProfile,
    getCoachProfilePhoto,
    updatePersonalDetails,
    updateCoachingAndJobExperience,
    updatePlayingExperience,
    updateAdditionalExperience,
    updateSkills,
    updateEducationalDetails,
    updateProfileVideosDetails,
    updateOtherDetails,
    downloadPDF,
    downloadCoachPDF,
    uploadProfileAssets,
    deleteProfileVideo,
    submitProfile,
    sendProfile

};

function getCoachProfile(userName) {
    return APIHelper.get(APIURLConstants.GET_COACH_PROFILE + userName, authHeader())
        .then(handleResponse)
        .then(coachProfile => {
            return coachProfile;
        })
        .catch(handleError);
}

function getCoachProfilePhoto(username) {
    return APIHelper.get(APIURLConstants.GET_PROFILE_PHOTO + username, authHeader())
        .then(coachProfilePhoto => {
            return coachProfilePhoto;
        })
        .catch(handleError);
}

function updatePersonalDetails(personalDetails, userName) {
    let profileVideoUrl = '';
    if ((personalDetails.profileVideo && personalDetails.profileVideo.length > 0 && personalDetails.profileVideo[0].videoURL)) {
        profileVideoUrl = personalDetails.profileVideo[0].videoURL;
    }

    if (personalDetails.profileVideo && personalDetails.profileVideo.videoURL) {
        profileVideoUrl = personalDetails.profileVideo.videoURL;
    }

    let postData = {
        personalDetails: {
            fullName: personalDetails.fullName,
            profilePhoto: personalDetails.profilePhoto,
            profileVideo: profileVideoUrl && profileVideoUrl !== '' ? { videoURL: profileVideoUrl } : {},
            roleAndCoachingPhilosophy: personalDetails.roleAndCoachingPhilosophy,
            mediaAndArticle: personalDetails.mediaAndArticle,
            coachingVideos: personalDetails.coachingVideos,
        },
        userName: userName,
    };

    return APIHelper.post(APIURLConstants.SAVE_PERSONAL_DETAILS, postData, authHeader())
        .then(handleResponse)
        .then(details => {
            return postData.personalDetails;
        })
        .catch(handleError);
}

function updateCoachingAndJobExperience(coachingJobExperience, userName) {
    let postData = {
        coachingAndOtherJobExperience: {
            assistantCoachExperience: coachingJobExperience && coachingJobExperience.assistantCoachExperience ? coachingJobExperience.assistantCoachExperience : [],
            awardsAndAllocades: coachingJobExperience && coachingJobExperience.awardsAndAllocades ? coachingJobExperience.awardsAndAllocades : [],
            championship: coachingJobExperience && coachingJobExperience.championship ? coachingJobExperience.championship : [],
            headCoachExperience: coachingJobExperience && coachingJobExperience.headCoachExperience ? coachingJobExperience.headCoachExperience : [],
            notablePlayer: coachingJobExperience && coachingJobExperience.notablePlayer ? coachingJobExperience.notablePlayer : [],
            skillExperience: coachingJobExperience && coachingJobExperience.skillExperience ? coachingJobExperience.skillExperience : [],
            taskInfo: coachingJobExperience && coachingJobExperience.taskInfo ? coachingJobExperience.taskInfo : "",
        },
        userName: userName,
    }

    return APIHelper.post(APIURLConstants.SAVE_COACHINNG_JOB_EXPERIENCE, postData, authHeader())
        .then(handleResponse)
        .then(details => {
            return postData.coachingAndOtherJobExperience;
        }).catch(handleError);
}

function updatePlayingExperience(playingExperienceDetails, userName) {
    let postData = {
        playingExperience: {
            formerExperience: playingExperienceDetails.formerExperience ? playingExperienceDetails.formerExperience : [],
            championship: playingExperienceDetails.championship ? playingExperienceDetails.championship : [],
            awardsAndAllocades: playingExperienceDetails.awardsAndAllocades ? playingExperienceDetails.awardsAndAllocades : [],
        },
        userName: userName,
    };

    return APIHelper.post(APIURLConstants.SAVE_PLAYING_EXPERIENCE, postData, authHeader())
        .then(handleResponse)
        .then(details => {
            return postData.playingExperience;
        }).catch(handleError);
}

function updateAdditionalExperience(additionalExperienceDetails, userName) {
    let postData = {
        additionalExperience: additionalExperienceDetails,
        userName: userName,
    };

    return APIHelper.post(APIURLConstants.SAVE_ADDITIONAL_EXPERIENCE, postData, authHeader())
        .then(handleResponse)
        .then(details => {
            return postData.additionalExperience;
        }).catch(handleError);
}

function updateSkills(skillsets, userName) {
    let postData = {
        skillSet: {
            coachingSkill: skillsets && skillsets.coachingSkill ? skillsets.coachingSkill : [],
            languageSkill: skillsets && skillsets.languageSkill ? skillsets.languageSkill : [],
            coordinator: skillsets && skillsets.coordinator ? skillsets.coordinator : "",
            coachPhilosophyAndSkill: skillsets && skillsets.coachPhilosophyAndSkill ? skillsets.coachPhilosophyAndSkill : "",
        },
        userName: userName,
    };

    return APIHelper.post(APIURLConstants.SAVE_SKILLSET, postData, authHeader())
        .then(handleResponse)
        .then(details => {
            return postData.skillSet;
        }).catch(handleError);
}

function updateEducationalDetails(educationDetails, userName) {
    let postData = {
        educationDetail: { educationDetail: Object.values(educationDetails) },
        userName: userName,
    };

    return APIHelper.post(APIURLConstants.SAVE_EDUCATION_DETAILS, postData, authHeader())
        .then(handleResponse)
        .then(details => {
            return postData.educationDetail;
        }).catch(handleError);
}

function updateProfileVideosDetails(profileVideosDetails, userName) {
    let postData = {
        ProfileVideos: { ProfileVideo: Object.values(profileVideosDetails) },
        userName: userName,
    };

    return APIHelper.post(APIURLConstants.SAVE_PROFILEVIDEOS_DETAILS, postData, authHeader())
        .then(handleResponse)
        .then(details => {
            return postData.profileVideosDetails;
        }).catch(handleError);
}

function updateOtherDetails(otherDescription, userName) {
    let description = otherDescription && otherDescription.other && otherDescription.other.description ? otherDescription.other.description : "";
    let postData = {
        other: { description },
        userName: userName,
    };

    return APIHelper.post(APIURLConstants.SAVE_OTHER_DESCRIPTION, postData, authHeader())
        .then(handleResponse)
        .then(details => {
            return postData.other;
        }).catch(handleError);
}

function uploadProfileAssets(photo) {
    return APIHelper.post(APIURLConstants.SAVE_PROFILE_PHOTO, photo, authHeader())
        .then(details => {
            return details;
        }).catch(handleError);
}


function deleteProfileVideo(fileName) {
    return APIHelper.get(APIURLConstants.DELETE_PROFILE_VIDEO + fileName, authHeader())
        .then(result => {
            return result;
        }).catch(handleError);
}

function downloadPDF(username) {
    var xhr = new XMLHttpRequest();
    xhr.open('GET', 'api' + APIURLConstants.GET_PROFILE_PDF + username, true);
    let auth = authHeader();
    xhr.setRequestHeader('Authorization', auth['Authorization']);
    xhr.responseType = 'arraybuffer';
    return xhr;
}
function downloadCoachPDF() {
    var xhr = new XMLHttpRequest();
    xhr.open('GET', 'api' + APIURLConstants.GET_COACH_PROFILE_PDF, true);
    let auth = authHeader();
    xhr.setRequestHeader('Authorization', auth['Authorization']);
    xhr.responseType = 'arraybuffer';
    return xhr;
}

function submitProfile(userName) {
    return APIHelper.post(APIURLConstants.SUBMIT_PROFILE, { userName: userName }, authHeader())
        .then(handleResponse)
        .then(status => {
            return status;
        }).catch(handleError);
}

function sendProfile(userName, emailId) {
    let data = { userName: userName, emailId: emailId }
    return APIHelper.post(APIURLConstants.SEND_PROFILE, data, authHeader())
        .then(handleResponse)
        .then(lstShowcases => {
            return lstShowcases;
        }).catch(ex => console.log(ex));
}