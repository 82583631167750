import React, { useState, useEffect, useRef } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Badge from "@material-ui/core/Badge";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";

import { Link } from "react-router-dom";
import Tooltip from "@material-ui/core/Tooltip";
import GetAppIcon from "@material-ui/icons/GetApp";

import yellow from "@material-ui/core/colors/yellow";
import Grid from '@material-ui/core/Grid';

import BookmarkBorderIcon from "@material-ui/icons/BookmarkBorder";
import BookmarkIcon from "@material-ui/icons/Bookmark";
import MedalIcon from "../../sharedComponents/customIcons/MedalIcon";
import TrophyIcon from "../../images/trophy.png";
import Avatar from "@material-ui/core/Avatar";
import HeadShot from "../../sharedComponents/profilePhoto/HeadShot";
import Pending from "../../images/Pending.png";
import Approve from "../../images/approve.png";
import Hold from "../../images/hold.png";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import ProfileCommonFunction from "./ProfileCommonFunction";
import { coachProfileService } from "../../services";
import RouteConstant from '../../common/RouteConstants';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import AppConstants from '../../common/AppConstants';
import RejectDialog from '../../sharedComponents/dialog/RejectDialog';
import MoreVertIcon from "@material-ui/icons/MoreVert";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import Divider from "@material-ui/core/Divider";
import Checkbox from "@material-ui/core/Checkbox";
import { Role } from '../../helper';
import useTelemetry from "../../utils/useTelemetry";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        [theme.breakpoints.up("sm")]: {
            padding: theme.spacing(1),
        },
    },
    gridItem: {
        [theme.breakpoints.up("sm")]: {
            width: "100%",
            padding: theme.spacing(1),
        },
        [theme.breakpoints.down("xs")]: {
            width: "100%",
            paddingBottom: theme.spacing(1),
        },
    },
    downloadAction: {
        marginLeft: "auto",
    },
    mainAvatar: {
        width: theme.spacing(8),
        height: theme.spacing(8),
    },
    statusAvatar: {
        width: theme.spacing(3),
        height: theme.spacing(3),
        border: "2px solid #FFFFFF",
    },
    avatarGroup: {
        marginLeft: theme.spacing(1),
        display: "flex",
        alignItems: "center",
    },
    avatarGroupItem: {
        width: theme.spacing(3.5),
        height: theme.spacing(3.5),
        marginRight: theme.spacing(0.5),
    },
    Name: {
        fontWeight: "bold",
        fontSize: "18px",
        textDecoration: "none",
        color: "#393939",
    },
    tableRow: {
        // height: '100px !important',
        boxShadow: "1px 1px 1px #aaaaaa",
        "& td:nth-of-type(4)": {
            paddingRight: theme.spacing(0),
        },
    },
    TrophyIcon: {
        position: 'absolute',
        right: '18%',
        bottom: '18%',
        transform: 'scale(1) translate(50%, 50%)',
        transformOrigin: '100% 100%',
        zIndex: '2',
        height: theme.spacing(3.25),
        width: theme.spacing(3.25),
    },
    countCircle: {
        height: "25px",
        width: "25px",
        backgroundColor: "#7B99D7",
        borderRadius: "50%",
        display: "inline-block",
        color: "#fff",
        textAlign: "center",
        lineHeight: "25px",
        fontWeight: "bold",
    },
    cardSubHeaderTitle: {
        fontWeight: 600,
    },
    inputCheckBox: {
        '& > span': {
            padding: theme.spacing(.5),
        },
    },
}));

const ChampionshipBadge = withStyles((theme) => ({
    badge: {
        color: yellow.A700,
    },
}))(Badge);

const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
    },
}))(Tooltip);


export default function ProfileList(props) {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const anchorRef = useRef(null);
    const { trackCustomEvents } = useTelemetry();

    const handleToggle = () => {
        setOpen(!open);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    const [fullName, setFullName] = useState(
        props.data && props.data.personalDetails.fullName
            ? props.data.personalDetails.fullName
            : {}
    );
    const [
        coachingAndOtherJobExperience,
        setCoachingAndOtherJobExperience,
    ] = useState(
        props.data && props.data.coachingAndOtherJobExperience
            ? props.data.coachingAndOtherJobExperience
            : []
    );
    const [playingExperience, setPlayingExperience] = useState(
        props.data && props.data.playingExperience
            ? props.data.playingExperience
            : []
    );
    const [currentCoachingExp, setCurrentCoachingExp] = useState([]);
    const [pastCoachingExp, setPastCoachingExp] = useState([]);
    const [coachingSkill, setCoachingSkill] = useState(
        props.data.skillSet && props.data.skillSet.coachingSkill
            ? props.data.skillSet.coachingSkill
            : []
    );
    const [headCoaches, setHeadCoaches] = useState([]);
    const [bookmarked, setBookmarked] = useState(false);
    const [wonChampianship, setWonChampianship] = useState(0);
    const [yearOfExp, setYearOfExp] = useState({ year: 0, month: 0 });
    const [showApprove, setShowApprove] = useState(false);
    const [showReject, setShowReject] = useState(false);

    let Name = fullName.firstName + " " + fullName.lastName;

    useEffect(() => {
        setFullName(
            props.data && props.data.personalDetails.fullName
                ? props.data.personalDetails.fullName
                : {}
        );
        setCoachingAndOtherJobExperience(
            props.data && props.data.coachingAndOtherJobExperience
                ? props.data.coachingAndOtherJobExperience
                : []
        );
        setCoachingSkill(
            props.data.skillSet && props.data.skillSet.coachingSkill
                ? props.data.skillSet.coachingSkill
                : []
        );
        setPlayingExperience(
            props.data && props.data.playingExperience
                ? props.data.playingExperience
                : []
        );
        let count = ProfileCommonFunction.getWonChampionship(
            coachingAndOtherJobExperience,
            playingExperience
        );
        setWonChampianship(count);
        getBookMarked();
        getShowApprove();
        getShowReject();
    }, props.data);

    function getBookMarked() {
        let bookmarkedBy =
            props.data && props.data.bookmarkedBy ? props.data.bookmarkedBy : [];
        if (bookmarkedBy != null) {
            if (bookmarkedBy.includes(props.userName)) {
                setBookmarked(true);
            } else {
                setBookmarked(false);
            }
        }
    }
    function getShowApprove() {
        if (props.data.status.toLowerCase() === AppConstants.PROFILE_STATUS.SUBMITTED ||
            props.data.status.toLowerCase() === AppConstants.PROFILE_STATUS.DENIED ||
            props.data.status.toLowerCase() === AppConstants.PROFILE_STATUS.RESUBMITTED) {
            setShowApprove(true);
        } else {
            setShowApprove(false);
        }
    }

    function getShowReject() {
        if (props.data.status.toLowerCase() === AppConstants.PROFILE_STATUS.SUBMITTED ||
            props.data.status.toLowerCase() === AppConstants.PROFILE_STATUS.APPROVED ||
            props.data.status.toLowerCase() === AppConstants.PROFILE_STATUS.RESUBMITTED) {
            setShowReject(true);
        } else {
            setShowReject(false);
        }
    }
    useEffect(() => {
        Name = fullName.firstName + " " + fullName.lastName;
        let outPutCurrentCoaching = ProfileCommonFunction.getCurrentCoachingExperience(
            coachingAndOtherJobExperience,
            headCoaches
        );
        setCurrentCoachingExp(outPutCurrentCoaching.currentExp);
        let outPutPastCoaching = ProfileCommonFunction.getPastCoachingExperience(
            coachingAndOtherJobExperience,
            headCoaches
        );
        let headCoachArray = ProfileCommonFunction.calculateHeadcoaches(
            coachingAndOtherJobExperience,
            playingExperience
        );
        setHeadCoaches(headCoachArray);
        setPastCoachingExp(outPutPastCoaching.pastExp);
        let yearAndMonth = ProfileCommonFunction.getYearOfExperience(
            coachingAndOtherJobExperience
        );
        setYearOfExp(yearAndMonth);
        setCoachingSkill(
            props.data.skillSet && props.data.skillSet.coachingSkill
                ? props.data.skillSet.coachingSkill
                : []
        );
    }, [coachingAndOtherJobExperience]);

    const handleBookMarked = (mode) => {
        setBookmarked(mode);
        props.handleBookMarked(mode, props.data.userName);
    };
 
    function donwloadPDF(event) {
        event.preventDefault();
        var xhr = coachProfileService.downloadPDF(props.data.userName);
        xhr.onload = function (e) {
            if (this.status == 200) {
                let fileName = fullName.firstName + "_" + fullName.lastName;
                fileName = fileName.replace(/([^a-z0-9 _]+)/gi, '')

                var blob = new Blob([this.response], { type: "application/pdf" });
                var link = document.createElement("a");
                link.href = window.URL.createObjectURL(blob);
                link.download = fileName + ".pdf";
                link.click();
            }
        };
        xhr.send();
        trackCustomEvents("ClickEvent", { event: "Download PDF from List" });
    }
    function handleClick() {
        if (showApprove)
            props.handleProfileStatusChange(props.data.userName, AppConstants.PROFILE_STATUS.APPROVED)
        else if (props.data.status.toLowerCase() === AppConstants.PROFILE_STATUS.APPROVED) {
            setOpenDialog(true);
        }
    }
    function handleRejectClick() {
        setOpenDialog(true);
        //props.handleProfileStatusChange(props.data.userName, AppConstants.PROFILE_STATUS.DENIED)
    }
    function btnRejectClick(note, sendMail) {
        props.handleProfileStatusChange(props.data.userName, AppConstants.PROFILE_STATUS.DENIED, note, sendMail)
    }

    const [openCurrentCoachingTooltip, setOpenCurrentCoachingTooltip] = useState(false);
    const handleCurrentCoachingTooltipClose = () => {
        setOpenCurrentCoachingTooltip(false);
    };
    const handleCurrentCoachingTooltipOpen = () => {
        setOpenCurrentCoachingTooltip(true);
    };
    const checkProfileCheckbox = (userId) => {
        return props.selectedProfiles ? props.selectedProfiles.includes(userId) : false;
    }
    return (
        <>
            <TableRow className={classes.tableRow} style={props.data.needToHighlight ? { boxShadow: "0px 1px 0px 3px orange", border: '1px solid orange' } : {}}>
                <TableCell scope="row">
                    <Grid container style={{ alignItems: 'center' }}>
                        {(props.container === AppConstants.DASHBOARD_TYPE.ADMIN ||
                            props.container === AppConstants.DASHBOARD_TYPE.FRONT_OFFICE) &&
                            <Grid item lg={1} md={1} sm={1} xs={1} style={{ maxWidth: "35px" }} className={classes.inputCheckBox}>
                                <Checkbox
                                    name="chkGroup"
                                    id={props.data.userName}
                                    checked={checkProfileCheckbox(props.data.userName)}
                                    onChange={(event) => props.handleProfileChecked(event, props.data.userName)}
                                />
                            </Grid>
                        }
                        <Grid item lg={1} md={2} sm={2} xs={3} style={{ maxWidth: "75px" }}>
                            {wonChampianship > 0 && (
                                <Tooltip onClick={() => props.handleViewProfileClick(props.data.userName)} title={`${wonChampianship} championships won`}>
                                    <ChampionshipBadge>
                                        <Avatar variant="square" className={classes.TrophyIcon} src={TrophyIcon}></Avatar>

                                        <HeadShot
                                            userName={props.data.userName}
                                            coachName={Name}
                                            className={classes.mainAvatar}
                                            profilePhoto={
                                                props.data.personalDetails &&
                                                    props.data.personalDetails.profilePhoto
                                                    ? props.data.personalDetails.profilePhoto
                                                    : ""
                                            }
                                        />
                                    </ChampionshipBadge>
                                </Tooltip>
                            )}
                            {wonChampianship == 0 && (
                                <HeadShot
                                    userName={props.data.userName}
                                    coachName={Name}
                                    className={classes.mainAvatar}
                                    profilePhoto={
                                        props.data.personalDetails &&
                                            props.data.personalDetails.profilePhoto
                                            ? props.data.personalDetails.profilePhoto
                                            : ""
                                    }
                                />
                            )}
                        </Grid>
                        {/* </TableCell>
        <TableCell align="left" style={{ width: "43%" }}> */}
                        <Grid item lg={5} md={4} sm={3} xs={8}>
                            <>
                                <Typography color="primary">
                                    {" "}
                                    <Link onClick={() => props.handleViewProfileClick(props.data.userName)} className={classes.Name}
                                        data-gaeventlabel={fullName.firstName + " " + fullName.lastName}
                                        data-gaeventcategory="Coach Profile card click"
                                        data-gaeventaction="Profile Name List View Click">
                                        {" "}
                                        {fullName.firstName} {fullName.lastName}{" "}
                                    </Link>
                                </Typography>
                                {currentCoachingExp && currentCoachingExp.length == 0 && <Typography variant="caption" style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                                    {AppConstants.CURRENTLY_NOT_WORKING}
                                </Typography>}

                                {currentCoachingExp.length > 0 && [currentCoachingExp[0]].map((data, index) => {
                                    return (
                                        <Typography
                                            key={index}
                                            variant="caption"
                                            display="inline"
                                            className={classes.cardSubHeaderTitle}
                                        >
                                            {data.title}, {data.teamName},{" "}
                                            {props.getLeagueTextByValue(data.league)}
                                        </Typography>
                                    );
                                })}{" "}
                                {currentCoachingExp.length > 1 && <>
                                    <ClickAwayListener onClickAway={handleCurrentCoachingTooltipClose}>
                                        <HtmlTooltip open={openCurrentCoachingTooltip}
                                            title={currentCoachingExp.map((data, index) => {
                                                return (
                                                    <React.Fragment key={index}>
                                                        {index > 0 && <>
                                                            <div
                                                                style={{ cursor: "pointer" }}
                                                                onClick={() => props.handleViewProfileClick(props.data.userName)}
                                                            >
                                                                {data.title}
                                                            </div>
                                                            <Typography
                                                                style={{ cursor: "pointer" }}
                                                                onClick={() => props.handleViewProfileClick(props.data.userName)}
                                                                variant="caption"
                                                                className={classes.cardSubHeaderTitle}
                                                            >
                                                                {data.teamName}, {props.getLeagueTextByValue(data.league)}
                                                            </Typography>
                                                            {index != (currentCoachingExp.length - 1) && <Divider></Divider>}
                                                        </>}
                                                    </React.Fragment>
                                                );
                                            })
                                            }
                                        >
                                            <Typography
                                                variant="caption"
                                                color="textSecondary"
                                                onClick={handleCurrentCoachingTooltipOpen}
                                                style={{ cursor: "pointer" }}
                                                className={classes.countCircle}
                                            >
                                                +{currentCoachingExp.length - 1}
                                            </Typography>
                                        </HtmlTooltip>
                                    </ClickAwayListener></>}


                            </>
                        </Grid>
                        {/* </TableCell>
        <TableCell align="left" style={{ width: "30%" }}> */}
                        <Grid item lg={3} md={3} sm={4} xs={12} className="yearExperience">
                            <Typography color="primary">Coaching experience</Typography>
                            <Typography variant="caption">
                                {yearOfExp.year} years & {yearOfExp.month} months
          </Typography>
                        </Grid>
                        {/* </TableCell>
        <TableCell align="right" style={{ width: "12%" }}> */}
                        <Grid item lg={1} md={1} sm={1} xs={2} className="bookmarkButton">
                            {!props.hideAction && props.container === AppConstants.DASHBOARD_TYPE.FRONT_OFFICE && props.role !== Role.Coach ?
                                (bookmarked ?
                                    <Link to="#" onClick={() => handleBookMarked(false)}>
                                        <Tooltip title="Bookmark">
                                            <IconButton
                                                className={classes.bookmark}
                                                aria-label="add to highlighted profiles"
                                            >
                                                <BookmarkIcon color="secondary" />{" "}
                                            </IconButton>
                                        </Tooltip>
                                    </Link>
                                    :
                                    <Link to="#" onClick={() => handleBookMarked(true)}>
                                        <Tooltip title="Bookmark">
                                            <IconButton aria-label="add to highlighted profiles">
                                                <BookmarkBorderIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </Link>) : <>

                                    {!props.hideAction && (showApprove || showReject) && props.role !== Role.Coach && <Tooltip title="Options">
                                        <IconButton
                                            ref={anchorRef}
                                            aria-label="more"
                                            size="small"
                                            aria-controls={open ? 'menu-list-grow' : undefined}
                                            aria-haspopup="true"
                                            onClick={handleToggle}
                                        >
                                            <MoreVertIcon />
                                            {(showApprove && showReject) && <>
                                                <Avatar alt="Pending" src={Pending} className={classes.statusAvatar} ></Avatar>
                                            </>}
                                            {(!showApprove && showReject) && <>
                                                <Avatar alt="Approve" src={Approve} className={classes.statusAvatar} ></Avatar>
                                            </>}
                                            {(showApprove && !showReject) && <>
                                                <Avatar alt="Hold" src={Hold} className={classes.statusAvatar} ></Avatar>
                                            </>}
                                        </IconButton>
                                    </Tooltip>
                                    }

                                    <Popper style={{ zIndex: 99 }} placement='left-start' open={open} anchorEl={anchorRef.current} role={undefined} transition='fade' disablePortal>
                                        {({ TransitionProps, placement }) => (
                                            // <Grow
                                            //   {...TransitionProps}
                                            //   style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                                            // >
                                            <Paper>
                                                <ClickAwayListener onClickAway={handleClose}>
                                                    <MenuList>
                                                        {showApprove && <MenuItem onClick={handleClick}>Approve</MenuItem>}
                                                        {showReject && <MenuItem onClick={handleRejectClick}>On Hold </MenuItem>}
                                                        {(showApprove || showReject) && <MenuItem onClick={() => props.handleEditProfileClick(props.data.userName)}> Edit Profile</MenuItem>}
                                                        {/* <MenuItem> Change to FrontOFfice </MenuItem>
                              <MenuItem onClick={handleStatusChange}>{props.data.isActive ? 'Change to Inactive' :'Change to Active' }  </MenuItem> */}
                                                    </MenuList>
                                                </ClickAwayListener>
                                            </Paper>
                                            //</Grow>
                                        )}
                                    </Popper>
                                </>
                            }
                        </Grid>
                        {/* </TableCell>       
        <TableCell align="left" style={{ width: "5%" }}> */}
                        <Grid item lg={1} md={1} sm={1} xs={2}>
                            <Tooltip title="Download profile PDF">
                                <Link href="#" onClick={donwloadPDF}>
                                    <IconButton
                                        aria-label="download"
                                        className={classes.downloadAction}
                                    >
                                        <GetAppIcon />
                                    </IconButton>
                                </Link>
                            </Tooltip>
                        </Grid>
                    </Grid>
                </TableCell>
            </TableRow>
            <RejectDialog
                open={openDialog}
                onClose={() => setOpenDialog(false)}
                onReject={(note, sendMail) => btnRejectClick(note, sendMail)}
            />
        </>
    );
}
