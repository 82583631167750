import { Box } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { configService } from "../../services";

const ApplicationInsights = () => {

    const [insightURL, setInsightURL] = useState(null)
    const { innerHeight } = window;

    useEffect(() => {
        const getInsightsURL = async () => {
            const url = await configService.getConfigValue('adminAnalyticsDashboardURL')
                .then(res => {
                    return res.value
                }).catch(err => {
                    console.log("Error getting review status message", err);
                });
            setInsightURL(url);
        };

        getInsightsURL();

    }, [insightURL]);

    return (
        <Box sizeHeight={"100vh"} sizeWidth={'100vw'} display={"flex"}>
            {insightURL &&
                <iframe src={insightURL} title="description" width="100%" height={innerHeight - 200} />
            }
        </Box>
    )
}

export default ApplicationInsights;