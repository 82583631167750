import React, { useEffect, useRef, useState, useContext } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import ImageCropper from '../questionComponents/ImageCropper';

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexShrink: 0,
    [theme.breakpoints.up("sm")]: {
      flexBasis: "85%",
    },
    [theme.breakpoints.down("xs")]: {
      flexBasis: "85%",
    },
    cursor: "pointer",
  },
}));

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});
const ImageCropperDialog = (props) => {
  const classes = useStyles();
  
  const DialogTitleCropper = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    )
  })

  const DialogContentCropper = withStyles((theme) => ({
    // root: {
    //     padding: theme.spacing(2),
    //     display: "flex",
    //     justifyContent: "center",
    //     backgroundColor: "#e6e6e6",
    //     [theme.breakpoints.down("sm")]: {
    //         padding: "0.5rem",
    //     },
    // },
  }))(MuiDialogContent);

  const DialogActions = withStyles((theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(1),
    },
  }))(MuiDialogActions);
  return (
    <Dialog
      onClose={props.onClose}
      maxWidth="sm"
      aria-labelledby="customized-dialog-title"
      open={props.open}
    >
      <DialogTitleCropper
        id="customized-dialog-title"
        onClose={props.onClose}
      >
      </DialogTitleCropper>
      <DialogContentCropper>
        <ImageCropper src={props.data} saveCroppedImage={props.saveCroppedImage}
        onClose ={props.onClose}
          />
      </DialogContentCropper>
    </Dialog>
  )
}

export default ImageCropperDialog;
