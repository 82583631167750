import React, { useState, useContext, Fragment, useEffect } from 'react';
import { UserContext } from '../../common/UserContext';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Tooltip from '@material-ui/core/Tooltip';
import EditTwoToneIcon from '@material-ui/icons/EditTwoTone';
import DeleteTwoToneIcon from '@material-ui/icons/DeleteTwoTone';
import GroupIcon from '@material-ui/icons/Group';
import VisibilityIcon from '@material-ui/icons/Visibility';
import PublishIcon from '@material-ui/icons/PublishOutlined';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import ViewMemoDialog from './ViewMemo';
import helper from "../../common/Helper";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        [theme.breakpoints.up('sm')]: {
            padding: theme.spacing(1)
        },
    },
    gridItem: {
        [theme.breakpoints.up('sm')]: {
            width: '100%',
            padding: theme.spacing(1)
        },
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            paddingBottom: theme.spacing(1)
        },
    },
    Name: {
        fontWeight: 'bold',
        fontSize: '18px',
        textDecoration: 'none',
        color: '#393939'
    },
    tableRow: {
        '& td p': {
            margin: 0,
        },
        // height: '100px !important',
        // boxShadow: '1px 1px 1px #aaaaaa',
        // '& td:nth-of-type(4)': {
        //     paddingRight: theme.spacing(1),
        // }
    },
    contentCell: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        maxWidth: 0,
        '& *': {
            fontSize: '1rem',
            fontWeight: 'normal',
        },
        '& p *': {
            margin: 0,
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
        },
    },
}));

const MemoItem = (props) => {

    const classes = useStyles()
    const [showMemoDialog, setShowMemoDialog] = useState(false);
    const [currentMemo, setCurrentMemo] = useState(null);

    const onEditClick = () => {
        props.onEditClick(props.data.Id);
    }
    const onDeleteClick = () => {
        props.onDeleteClick(props.data.Id);
    }
    const onViewMemoClick = (data) => {
        setShowMemoDialog(true);
        setCurrentMemo(data);
    }
    const onCloseClick = () => {
        setShowMemoDialog(false);
        setCurrentMemo(null);
    }
    const formattedDate = (date) => {
        return helper.IfDateIsNULL(date) ? '' : helper.FormatDateTime(date);
    }

    const onViewUsersClick = () => {
        props.onViewUsersClick(props.data.Id);
    }

    return (
        <>
            <TableRow className={classes.tableRow}>
                <TableCell align="left" style={{ width: '45%' }} >
                    <Typography variant="body2"> {props.data.Title} </Typography>
                </TableCell>
                {props.showEditDeleteButton &&
                <>
                <TableCell align="left" style={{ width: '25%' }} >
                    <Typography variant="body2"> {props.data.CreatedBy} </Typography>
                </TableCell>
                <TableCell align="left" style={{ width: '20%' }} >
                    <Typography variant="body2"> {formattedDate(props.data.UpdatedDate)} </Typography>
                </TableCell>
                </>}
                <TableCell align="center" style={{ width: '10%', whiteSpace: 'nowrap' }}>
                    <Tooltip title="View" onClick={() => onViewMemoClick(props.data)} style={{ marginRight: 10, cursor: "pointer" }}>
                        <VisibilityIcon color="primary" />
                    </Tooltip>
                    {props.showEditDeleteButton &&
                        <>
                            <Tooltip title="Add Users to Memo" onClick={() => props.onManageGroupClick(props.data.Id)} style={{ marginRight: 10, cursor: "pointer" }}>
                                <GroupIcon color="primary" />
                            </Tooltip>
                            <Tooltip title={"Publish"} aria-disabled={props.data.disablePublish} style={{ marginRight: 10, cursor: "pointer" }}>
                                <PublishIcon color= { props.data.disablePublish ? "disabled" : "primary" } onClick={() => !props.data.disablePublish ? props.onPublishClick(props.data.Id): true} />
                            </Tooltip>
                            <Tooltip title="Assigned Users List" onClick={onViewUsersClick} style={{ marginRight: 10, cursor: "pointer" }}>
                                <AssignmentIndIcon color="primary" />
                            </Tooltip>
                            <Tooltip title="Edit" onClick={onEditClick} style={{ cursor: "pointer" }}>
                                <EditTwoToneIcon color="primary" />
                            </Tooltip>
                            <Tooltip title="Delete" onClick={onDeleteClick} style={{ cursor: "pointer" }}>
                                <DeleteTwoToneIcon color="primary" />
                            </Tooltip>
                        </>
                    }
                </TableCell>
            </TableRow>
            {showMemoDialog && <ViewMemoDialog open={showMemoDialog} data={currentMemo} onCloseClick={onCloseClick}></ViewMemoDialog>}
        </>
    );
};

export default MemoItem;