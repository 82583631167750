import React, { useState, useEffect, useRef, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Typography,
  Paper,
  Divider,
  Grid,
  Box,
  CardHeader,
} from "@material-ui/core/";
import Helper from "../../../common/Helper";
import CoachingExperiene from "./subComponent/HeadCoachingExperience";
import ShowArrayList from "./subComponent/ShowArrayList";
import ExperienceDetail from "./subComponent/ExperienceDetail";
import ViewProfileHeading from '../../../sharedComponents/ViewProfileHeading';
import NotAvailable from "../../../sharedComponents/NotAvailable/NotAvailable";
import ShowHTML from "../../../sharedComponents/HTMLEditor/ShowHTML";
import { AppDataContext } from "../../../common/AppContext";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  card: {
    border: "1px solid gray",
    backgroundColor: "#F3F3F3",
    header: {
      backgroundColor: "#E5ECFC",
      color: "#2D4D89",
    },
  },
  header: {
    backgroundColor: "#E5ECFC",
    color: "#2D4D89",
    fontSize: "12px",
    fontWeight: "bold",
    padding: "3px 0 3px 10px",
  },
  subHeading: {
    backgroundColor: "#63C5DA",
    color: "#1520A6",
    padding: "5px 25px",
  },
  section: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
    textAlign: "left",
  },
  grid: {
    padding: theme.spacing(1),
    alignSelf: "center",
  },
  gridInRow: {
    display: "flex",
    justifyContent: "space-between",
    paddingLeft: "25px",
  },
  section1: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
    borderLeft: "5px solid lightgray",
    paddingLeft: "20px",
    // width: "50%",
  },
  teamName: {
    fontWeight: "bold",
    fontSize: "12px",
    color: "black",
  },
  dates: {
    fontSize: "12px",
  },
  headCoach: {
    fontSize: "12px",
    color: "black",
  },
  teams: {
    fontSize: '0.9rem',
    fontWeight: '500',
  },
}));

const CurrentCoaching = (props) => {
  const {
    teamList

  } = useContext(AppDataContext);
  const classes = useStyles();
  const [headCoachingExp, setHeadCoachingExp] = useState([]);
  const [assistantCoachingExp, setAssistantCoachingExp] = useState([]);
  const [otherSkillsExperience, setOtherSkillsExperience] = useState([]);

  function GetHeadCoachinExp() {
    let headCoachingArray = props.data && props.data.headCoachExperience ? props.data.headCoachExperience : [];
    let headCoach = [];
    if (headCoachingArray.length > 0) {
      for (let i = 0; i < headCoachingArray.length; i++) {
        let expArray = headCoachingArray[i].experience;

        if (expArray.length > 0) {
          for (let y = 0; y < expArray.length; y++) {
            if (expArray[y].toDate != "Present")
              headCoach.push({ league: headCoachingArray[i].league, teamName: expArray[y].team && expArray[y].team.teamName ? expArray[y].team.teamName : "", fromDate: expArray[y].fromDate, toDate: expArray[y].toDate })
          }
        }
        // else if (expArray.length == 0 && headCoachingArray[i].league == "na") {
        //   headCoach.push({ league: headCoachingArray[i].league, teamName: "", fromDate: "", toDate: "" })
        // }
      }
    }

    // sort by name
    headCoach.sort(function (a, b) {

      if (a.fromDate > b.fromDate) {
        return -1;
      }
      if (a.fromDate < b.fromDate) {
        return 1;
      }
      // names must be equal
      return 0;
    });

    setHeadCoachingExp(headCoach);
  }

  function GetAssistantCoachinExp() {
    let assistantCoachingArray = props.data && props.data.assistantCoachExperience ? props.data.assistantCoachExperience : [];
    let assistantCoach = [];
    if (assistantCoachingArray.length > 0) {
      for (let i = 0; i < assistantCoachingArray.length; i++) {
        let expArray = assistantCoachingArray[i].experience;

        if (expArray.length > 0) {
          for (let y = 0; y < expArray.length; y++) {
            if (expArray[y].toDate != "Present")
              assistantCoach.push({ league: assistantCoachingArray[i].league, teamName: expArray[y].team && expArray[y].team.teamName ? expArray[y].team.teamName : "", fromDate: expArray[y].fromDate, toDate: expArray[y].toDate, headCoachName: expArray[y].headCoachName, titleName: expArray[y].title && expArray[y].title.Name ? expArray[y].title.Name : "", titleSubCategoryName: expArray[y].titleSubCategory && expArray[y].titleSubCategory.Name ? expArray[y].titleSubCategory.Name : "" })  //sushrut
          }
        }
        // else if (expArray.length == 0 && assistantCoachingArray[i].league == "na") {
        //   assistantCoach.push({ league: assistantCoachingArray[i].league, teamName: "", fromDate: "", toDate: "", headCoachName: [] })
        // }
      }
    }
    // sort by form Date
    assistantCoach.sort(function (a, b) {
      if (a.fromDate > b.fromDate) {
        return -1;
      }
      if (a.fromDate < b.fromDate) {
        return 1;
      }
      return 0;
    });
    setAssistantCoachingExp(assistantCoach);
  }

  function GetOtherSkillExperience() {
    let otherCoachingArray = props.data && props.data.skillExperience ? props.data.skillExperience : [];
    let otherCoach = [];
    if (otherCoachingArray.length > 0) {
      for (let i = 0; i < otherCoachingArray.length; i++) {
        let expArray = otherCoachingArray[i].experience;
        if (expArray.length > 0) {
          for (let y = 0; y < expArray.length; y++) {
            //if (expArray[y].toDate != "Present")
            otherCoach.push({ league: otherCoachingArray[i].league, teamName: expArray[y].team && expArray[y].team.teamName ? expArray[y].team.teamName : "", fromDate: expArray[y].fromDate, toDate: expArray[y].toDate, headCoachName: expArray[y].headCoachName, titleName: expArray[y].title && expArray[y].title.Name ? expArray[y].title.Name : "", titleSubCategoryName: expArray[y].titleSubCategory && expArray[y].titleSubCategory.Name ? expArray[y].titleSubCategory.Name : "" }) //sushrut
          }
        }
        // else if (expArray.length == 0 && otherCoachingArray[i].league == "na") {
        //   otherCoach.push({ league: otherCoachingArray[i].league, teamName: "", fromDate: "", toDate: "", headCoachName: [], titleName: "" })
        // }
      }
    }
    // sort by form Date
    otherCoach.sort(function (a, b) {
      if (a.fromDate > b.fromDate) {
        return -1;
      }
      if (a.fromDate < b.fromDate) {
        return 1;
      }
      return 0;
    });
    setOtherSkillsExperience(otherCoach);
  }

  const [skillExperience, setSkillExperience] = useState(props.data && props.data.skillExperience ? props.data.skillExperience : []);

  const [championship, setChampionship] = useState(props.data && props.data.championship ? props.data.championship.filter(c => c.league && c.league != "na") : []);
  const [notablePlayer, setNotablePlayer] = useState(props.data && props.data.notablePlayer ? props.data.notablePlayer : []);
  const [awardsOrAccolades, setAwardsOrAccolades] = useState(props.data && props.data.awardsAndAllocades ? props.data.awardsAndAllocades : []);
  const [taskInfo, setTaskInfo] = useState(props.data && props.data.taskInfo ? props.data.taskInfo : "");
  useEffect(() => {
    GetHeadCoachinExp();
    GetAssistantCoachinExp();
    GetOtherSkillExperience();
    setSkillExperience(props.data && props.data.skillExperience ? props.data.skillExperience : []);
    setChampionship(props.data && props.data.championship ? props.data.championship.filter(c => c.league && c.league != "na") : []);
    setNotablePlayer(props.data && props.data.notablePlayer ? props.data.notablePlayer : []);
    setAwardsOrAccolades(props.data && props.data.awardsAndAllocades ? props.data.awardsAndAllocades : []);
    setTaskInfo(props.data && props.data.taskInfo ? props.data.taskInfo : "");

  }, [props.data])

  const getTeamURL = (teamName, leagueName) => {
    let team = teamList.find(team => team.teamName === teamName && team.Type === leagueName)
    if (team !== undefined && team !== null) {
      return team.logoURL
    } else {
      return null
    }


  }

  //(data.team data.league data.logoUrl)

  return (
    <div className={classes.root}>
      {/* /////////////////////////////// Current Coaching Experience //////////////////////////// */}
      <div className={classes.section}>
        <ViewProfileHeading title="Current Coaching Experience" />
        {props.currentCoachingExp.length == 0 && <NotAvailable currentCoaching={true} />}
        {props.currentCoachingExp.map((data, index) => {
          let logoURL = getTeamURL(data.teamName, data.league)
          return (
            <Grid
              key={index}
              container
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <Grid item md={4} xs={12} className={classes.grid}>
                <Typography variant="subtitle2" color="primary">
                  Coach For
                </Typography>
                <Typography
                  style={{ display: "flex", alignItems: "center", color: 'black' }}
                >
                  {logoURL !== null && logoURL !== '' && logoURL !== undefined && <img
                    src={logoURL}
                    width="35px"
                    height="35px"
                  />}

                  {data.teamName}, {props.getLeagueTextByValue(data.league)}
                </Typography>
              </Grid>

              {/* added the titleSubCategory field for assistantCoach */}

              <Grid item md={6} xs={6} className={classes.grid}>
                <Typography variant="subtitle2" color="primary"> Current title </Typography>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Typography style={{ color: 'black' }}>{data.title}</Typography>
                  {data.title !== "Head Coach" && data.titleSubCategory && data.titleSubCategory.trim() !== "" && (
                    <Typography variant="caption" style={{ color: 'black', fontSize: '13px', marginLeft: '5px' }}>{`(${data.titleSubCategory})`}</Typography>
                  )}
                </div>
                <Typography variant="caption" size="small" style={{ color: 'gray' }}>From: {Helper.FormatDateMMYYYY(data.fromDate)}</Typography>
              </Grid>

            </Grid>
          );
        })}
      </div>

      <Divider></Divider>

      {props.coordinator && props.coordinator != "" && props.coordinator.toLowerCase() != "no" && <>
        <ShowArrayList heading="Offensive/Defensive Coordinator" data={[props.coordinator ? props.coordinator.charAt(0).toUpperCase() + props.coordinator.slice(1) : '']} />
        <Divider></Divider></>}

      {/* /////////////////////////////// Past Coaching Experience //////////////////////////// */}
      <div className={classes.section}>
        <ViewProfileHeading title="Past Coaching And Other Job Experience" />
        <Grid container>
          <Grid item md={12} xs={12} className={classes.grid}>
            <Typography className={classes.header}>
              Head Coaching Experience
            </Typography>
          </Grid>
          <Grid item md={12} xs={12} className={classes.gridInRow}>
            <Grid container>
              {headCoachingExp.length == 0 && <NotAvailable />}
              {headCoachingExp.map((data, index) => {
                return (
                  <Grid item md={4} sm={6} xs={12}>
                    <ExperienceDetail
                      key={index}
                      league={props.getLeagueTextByValue(data.league)}
                      experience={data}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </Grid>

          <Grid item md={12} xs={12} className={classes.grid}>
            <Typography className={classes.header}>
              Assistant Coaching Experience
            </Typography>
          </Grid>
          <Grid item md={12} xs={12} className={classes.gridInRow}>
            <Grid container>
              {assistantCoachingExp.length == 0 && <NotAvailable />}
              {assistantCoachingExp.map((data, index) => {
                return (
                  <Grid item md={4} sm={6} xs={12}>
                    <ExperienceDetail
                      key={index}
                      headcoaches={true}
                      headCoachName={data.headCoachName}
                      league={props.getLeagueTextByValue(data.league)}
                      experience={data}
                      useTitle={true}
                      useTitleSubCategory={true}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </Grid>

          <Grid item md={12} xs={12} className={classes.grid}>
            <Typography className={classes.header}>
              Other Experience
            </Typography>
          </Grid>

          <Grid item md={12} xs={12} className={classes.gridInRow}>
            <Grid container>
              {otherSkillsExperience.length == 0 && <NotAvailable />}
              {otherSkillsExperience.map((data, index) => {
                return (
                  <Grid key={index} item md={4} sm={6} xs={12}>
                    <ExperienceDetail
                      key={index}
                      headcoaches={true}
                      useTitle={true}
                      useTitleSubCategory={true}
                      headCoachName={data.headCoachName}
                      league={props.getLeagueTextByValue(data.league)}
                      experience={data}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </Grid>
      </div>

      <Divider></Divider>

      <div className={classes.section}>
        <ViewProfileHeading title="Championships" />
        <Grid container>
          <Grid item md={12} xs={12} className={classes.gridInRow}>
            {championship.length > 0 &&
              <Grid container>
                {championship.map((data, index) => {
                  return (
                    <CoachingExperiene
                      key={index}
                      championship={true}
                      league={props.getLeagueTextByValue(data.league)}
                      experience={data.experience}
                    />
                  );
                })}
              </Grid>
            }
            {championship.length == 0 &&
              <NotAvailable />
            }
          </Grid>
        </Grid>
      </div>
      <Divider></Divider>
      <ShowArrayList
        data={notablePlayer}
        heading="Notable Players Coached"
      />

      <Divider></Divider>

      <div className={classes.section}>
        <ViewProfileHeading title="Awards And Accolades" />
        <Grid container>
          <Grid item md={12} xs={12} className={classes.gridInRow}>
            <Grid container>
              {awardsOrAccolades.length == 0 && <NotAvailable />}
              {awardsOrAccolades.map((data, index) => {
                return (
                  <Grid key={index} item md={4} sm={6} xs={12}>
                    <div className={classes.section1}>
                      <Typography color="primary" className={classes.teams}>{data.award && data.award.Name ? data.award.Name : ''}</Typography>
                      <Typography className={classes.teamName}>
                        {data.year ? data.year : ''}
                      </Typography>
                    </div>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </Grid>
      </div>
      <Divider></Divider>
      <ShowArrayList
        data={props.coachingSkill}
        heading="Basketball & Coaching Skills"
      />
      {taskInfo && taskInfo.trim() != '' &&
        <>
          <Divider></Divider>
          {/* <ShowArrayList
            data={[taskInfo]}
            heading="Current Responsibilities And Tasks Performed"
          /> */}
          <ShowHTML heading="Current Responsibilities And Tasks Performed" data={taskInfo} />

        </>
      }

    </div>
  );
};

export default CurrentCoaching;
