import React, { useState, useEffect, useCallback } from "react";
import PasswordValidationSchema from "../../validationSchema/PasswordValidationSchema";


const useRegistrationForm = (
  initialValues,
  submitCallBack,
  validationSchema = {}
) => {
  // HOOKS
  const [values, setValues] = React.useState(initialValues);
  const [errors, setErrors] = React.useState([]);

  //Set the values if there is change in props
  useEffect(() => {
    setValues(initialValues);
  }, [initialValues]);

  const validateState = useCallback(() => {
    setErrors([]);
    let errors = {};
    const hasErrorInState = Object.keys(validationSchema).some((key) => {
      let error = "";
      let value = values[key];
      if (validationSchema[key].required) {
        if (!value) {
          error = "This is required field.";
          errors[key] = { error };
          setErrors((prevErrors) => ({
            ...prevErrors,
            [key]: { error },
          }));
        }
      }

      if (
        validationSchema[key].validator !== null &&
        typeof validationSchema[key].validator === "object"
      ) {
        if (value && !validationSchema[key].validator.regEx.test(value)) {
          error = validationSchema[key].validator.error;
          errors[key] = { error };
          setErrors((prevErrors) => ({
            ...prevErrors,
            [key]: { error },
          }));
        }
      }
    });

    let password = values.password;
    let confirmPassword = values.confirmPassword;

    if (password !== confirmPassword) {
      let error = 'Password do not match';
      errors['confirmPassword'] = { error };
      setErrors((prevErrors) => ({
        ...prevErrors,
        ['confirmPassword']: { error },
      }));
    }
    let valid = PasswordValidationSchema.ValidatePassword(password);
    if (!valid) {
      let error = 'Password is not valid';
      errors['password'] = { error };
      setErrors((prevErrors) => ({
        ...prevErrors,
        ["password"]: { error }
      }));
    }
    return errors;
  }, [values, validationSchema]);

  const handleInputChange = useCallback(
    (event) => {
      const name = event.target.name;
      let value = event.target.value;
      if (name == "firstName" || name == "lastName") {
        value = value.replace(/[^A-Za-z. ']/ig, '')
      }
      if (name == "username") {
        value = value.replace(/[^A-Za-z0-9._]/ig, '')
      }
      let error = "";
      setValues((prevState) => ({
        ...prevState,
        [name]: value,
      }));

      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: { error },
      }));
    },
    [validationSchema]
  );

  const handleOnSubmit = () => {
    let errorss = validateState(values);
    submitCallBack(errorss);
  };

  return {
    errors,
    handleInputChange,
    handleOnSubmit,
    values,
    setValues
  };
};

export default useRegistrationForm;
