import React, { useEffect, useState } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import TextField from "@material-ui/core/TextField";
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import MenuItem from "@material-ui/core/MenuItem";
import Checkbox from "@material-ui/core/Checkbox";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import Avatar from "@material-ui/core/Avatar";
import DataMapper from "../../common/DataMapper";
import Helper from "../../common/Helper";

const styles = (theme) => ({
    root: {
        display: "flex",
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    }
});

const useStyles = makeStyles((theme) => ({

    sectiondivider: {
        margin: theme.spacing(1),
    },
}));
const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
        display: 'flex',
        justifyContent: "left",
        backgroundColor: "#e6e6e6",
        [theme.breakpoints.down('sm')]: {
            padding: "0.5rem",
        }
    },
    UserDetails: {
        display: 'flex',
    }
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

export default function EditUserDialogs(props) {
    const classes = useStyles();
    const [isActive, setIsActive] = useState(props.data.isActive);
    const [email, setEmail] = useState(props.data.email);
    const [role, setRole] = useState("");
    const [validEmail, setValidEmail] = useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
        setIsActive(props.data.isActive);
        setEmail(props.data.email);
        setRole(props.data.role)
    }, [props.data])

    const handleClose = () => {
        props.onClose();
    };
    const onSave = () => {
        let regex = /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/;
        if (!regex.test(email)) {
            setValidEmail(true);
            return;
        }
        else {
            let postData = { "userName": props.data.userName, "email": email, "isActive": isActive, "role": role }
            props.onSave(postData);
        }

    }

    const handleChecked = (event) => {
        setIsActive(event.target.checked);
    }



    return (
        <div className={classes.root}>
            <Dialog onClose={handleClose}
                // fullScreen={fullScreen}
                fullWidth
                maxWidth="xs" aria-labelledby="customized-dialog-title" open={props.open}>
                <DialogTitle className="popup-title" id="customized-dialog-title" onClose={handleClose}>
                    <Typography >Update User Details</Typography>
                </DialogTitle>
                <DialogContent className="popup-container">
                    <Grid container>
                        <div style={{ width: '25%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                            <Avatar style={{ height: '70px', width: '70px' }} alt="profile photo">{Helper.GetNameInitials(props.data.firstName + " " + props.data.lastName)}</Avatar>
                            <Typography variant="caption" style={{ fontWeight: "bold", fontSize: "12px" }}>
                                {Helper.MakeFirstCharCapital(props.data.firstName) + " " + Helper.MakeFirstCharCapital(props.data.lastName)}
                            </Typography>
                        </div>
                        <div style={{ width: '75%', paddingLeft: '20%' }}>
                            <div>
                                <TextField
                                    value={email}
                                    onChange={(event) => {
                                        setEmail(event.target.value);
                                        setValidEmail(false);
                                    }}
                                    name="email"
                                    id="email"
                                    size="small"
                                    label="Email"
                                    error={validEmail} />
                            </div>
                            <div className={classes.sectiondivider}></div>
                            <div style={{ paddingTop: 10 }}>
                                <TextField
                                    id="outlined-select-currency"
                                    select
                                    label="Role"
                                    value={role}
                                    onChange={(event) => setRole(event.target.value)}
                                    size="small"
                                    style={{ width: "24ch" }}
                                >
                                    {["Admin", "Coach", "FrontOffice", "FrontOfficePlusCoach"].map((option) => (
                                        <MenuItem key={option} value={option}>
                                            {DataMapper.GetTextByValue(option)}
                                        </MenuItem>
                                    ))}
                                </TextField>
                                <div className={classes.sectiondivider}></div>
                                <div style={{ marginLeft: '-10px' }}>
                                    <Checkbox
                                        checked={isActive}
                                        name="isActive"
                                        onChange={handleChecked}
                                        title="Active"
                                    /> Active
                                    </div>

                            </div>
                        </div>
                    </Grid>

                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleClose} color="secondary"> Cancel </Button>
                    <Button autoFocus onClick={onSave} color="primary"> Save </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}