import React, { useContext, useState, useEffect, useRef } from 'react';
import { withRouter, useParams, } from 'react-router-dom';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import DialogContentText from "@material-ui/core/DialogContentText";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles(theme => ({
    root: {
        margin: 10,
        flexGrow: 1,
    },
    cursorPointer: {
        cursor: 'pointer',
    },
}));
const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

const CofirmationDialogBox = (props) =>{
    const classes = useStyles();
    return (
        <>
            <div>
                <Dialog
                    open={props.open}
                    onClose={props.onClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {props.message}
                    </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={props.onYesClick} color="primary">
                            Yes</Button>
                        <Button
                            onClick={props.onNoClick}
                            color="primary"
                            autoFocus>No
                        </Button>
                    </DialogActions>
                </Dialog></div>
        </>)
}
export default withRouter(CofirmationDialogBox);
