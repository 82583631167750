import React, { useState, useContext, Fragment, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Tooltip from '@material-ui/core/Tooltip';
import EditTwoToneIcon from '@material-ui/icons/EditTwoTone';
import DeleteTwoToneIcon from '@material-ui/icons/DeleteTwoTone';
import helper from "../../common/Helper";
import moment from 'moment';
import MailTwoToneIcon from '@material-ui/icons/MailTwoTone';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        [theme.breakpoints.up('sm')]: {
            padding: theme.spacing(1)
        },
    },
    gridItem: {
        [theme.breakpoints.up('sm')]: {
            width: '100%',
            padding: theme.spacing(1)
        },
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            paddingBottom: theme.spacing(1)
        },
    },
    Name: {
        fontWeight: 'bold',
        fontSize: '18px',
        textDecoration: 'none',
        color: '#393939'
    },
    tableRow: {
        '& td p': {
            margin: 0,
        },
        // height: '100px !important',
        // boxShadow: '1px 1px 1px #aaaaaa',
        // '& td:nth-of-type(4)': {
        //     paddingRight: theme.spacing(1),
        // }
    },
    contentCell: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        maxWidth: 0,
        '& *': {
            fontSize: '1rem',
            fontWeight: 'normal',
        },
        '& p *': {
            margin: 0,
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
        },
    },
}));

const ShowcaseItem = (props) => {

    const classes = useStyles()

    const onEditClick = () => {
        props.onEditClick(props.data.id);
    }

    const onSendEmailClick = () => {
        props.openSendMail(props.data.id);
    }

    const onDeleteClick = () => {
        props.onDeleteClick(props.data.id);
    }

    const formattedDate = (date) => {
        return helper.IfDateIsNULL(date) ? '' : helper.FormatDate(date);
    }
    return (
        <>
            <TableRow className={classes.tableRow}>
                <TableCell align="left" style={{ width: '35%' }} >
                    <Typography variant="body2"> {props.data.title} </Typography>
                </TableCell>
                <TableCell align="left" style={{ width: '15%' }} >
                    <Typography variant="body2"> {formattedDate(props.data.startDate)} </Typography>
                </TableCell>
                <TableCell align="left" style={{ width: '15%' }} >
                    <Typography variant="body2"> {formattedDate(props.data.endDate)} </Typography>
                </TableCell>
                <TableCell align="right" style={{ width: '40%' }}>

                    <Tooltip title="SendMail" onClick={onSendEmailClick} style={{ cursor: "pointer",marginRight:"8px" }}>
                        <MailTwoToneIcon color="primary" />
                    </Tooltip>

                    {new Date(props.data.endDate) > moment().toDate() && <Tooltip title="Edit" onClick={onEditClick} style={{ cursor: "pointer" }}>
                        <EditTwoToneIcon color="primary" />
                    </Tooltip>}
                    <Tooltip title="Delete" onClick={onDeleteClick} style={{ cursor: "pointer" }}>
                        <DeleteTwoToneIcon color="primary" />
                    </Tooltip>
                </TableCell>
            </TableRow>
            {/* {showMemoDialog && <ViewMemoDialog open={showMemoDialog} data={currentMemo} onCloseClick={onCloseClick}></ViewMemoDialog>} */}
        </>
    );
};

export default ShowcaseItem;