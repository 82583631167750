import React, { useState, useEffect } from "react";
import Avatar from "@material-ui/core/Avatar";
import APIHelper from "../../common/ApiHelper";
import APIURLConstants from "../../common/ApiURLConstants";
import Helper from "../../common/Helper";
import { coachProfileService } from "../../services";
import { cacheStorage } from '../../helper';

const HeadShot = (props) => {
    const [image, setImage] = useState(null);


    useEffect(() => {
        if (props.userName) {
            let imageUrl = cacheStorage.getProfilePhotoFromLocalStorage(props.userName);
            if (imageUrl) {
                setImage(imageUrl);
            }
            else {
                if (props.profilePhoto && props.profilePhoto.photoURL) {
                    coachProfileService.getCoachProfilePhoto(props.userName)
                        .then(profilePhoto => {
                            setImage(profilePhoto);
                            cacheStorage.saveProfilePhotoToLocalStorage(props.userName, profilePhoto);
                        })
                        .catch((err) => {
                            //console.log(err);
                        });
                }
                else setImage(null);
            }


        }
    }, [props.userName, props.profilePhoto]);



    return (
        <Avatar alt="profile photo" src={image} className={props.className} >{Helper.GetNameInitials(props.coachName)}</Avatar>
    )
}

export default HeadShot;