import React, { useState, useEffect, useRef, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Helper from "../../../../common/Helper";
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    section: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(3),
        borderLeft: "5px solid lightgray",
        paddingLeft: "20px",
        //width: "50%",
    },
    teamName: {
        fontWeight: "bold",
        fontSize: "12px",
        color: "black",
    },
    dates: {
        fontSize: "12px",
    },
    headCoach: {
        fontSize: "12px",
        color: "black",
    },
    titleName: {
        fontSize: "12px",
        color: "black",
        fontWeight: "bold"
    },
    titleSubCategoryName: {
        fontSize: "12px",
        color: "black",
        fontWeight: "bold"
    },
    teams: {
        fontSize: '0.9rem',
        fontWeight: '500',
    },
}));

export default function ExperienceDetail(props) {
    const classes = useStyles();
    const [data, setExperience] = useState(
        props.experience ? props.experience : {}
    );
    return (
        <div className={classes.section}>
            <Typography color="primary" className={classes.teams}>{props.league}</Typography>
            <Typography className={classes.teamName}>
                {data.teamName}
            </Typography>
            {data.title && data.title.Name && data.title.Name != '' && (
                <Typography className={classes.dates}>
                    {data.title.Name}
                </Typography>
            )}
            {props.championship && (
                <Typography className={classes.dates}>
                    Season- {data.season}
                </Typography>
            )}

            {data.fromDate != "" &&
                <Typography className={classes.dates}>
                    From {Helper.FormatDateMMYYYY(data.fromDate)} To{" "}
                    {data.toDate != "Present" && Helper.FormatDateMMYYYY(data.toDate)}
                    {data.toDate == "Present" && data.toDate}
                </Typography>
            }

            {/* This code will display the titleSubCategoryName field to the right side and in brackets of the titleName field */}

            {(props.useTitle && data.titleName !== "") && (
                <Typography className={classes.titleName}>
                    {data.titleName}
                    {props.useTitleSubCategory && data.titleSubCategoryName && (
                        <span className={classes.titleSubCategoryName}>
                            {" "}
                            ({data.titleSubCategoryName})
                        </span>
                    )}
                </Typography>
            )}

            {(props.headcoaches && props.headCoachName.length > 0) && (
                <>
                    <Typography className={classes.headCoach}>
                        Head Coach: {props.headCoachName.join(", ")}
                    </Typography>
                </>
            )}
        </div>
    );
}
