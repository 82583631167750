import React, { useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";

import CssBaseline from "@material-ui/core/CssBaseline";
import {
  UserProfileProvider,
  UserProfileConsumer,
} from "../../common/ProfileContext";

import ProfileSummary from "../../sharedComponents/profileSummary/ProfileSummary";
import ProfileDetailsEditSectionContainer from "./ProfileDetailsEditSectionContainer";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
  },
  grid: {
    display: "flex",
    flexGrow: 1,
    flexWrap: "wrap",
    paddingTop: theme.spacing(1),
    backgroundColor: theme.palette.background.default,
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(1),
    },
    [theme.breakpoints.down("xs")]: {
      padding: 0,
    },
  },
  gridItem: {
    [theme.breakpoints.up("sm")]: {
      width: "100%",
      padding: theme.spacing(1),
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      paddingBottom: theme.spacing(2),
    },
  },
  profileSummary: {
    padding: theme.spacing(2),
    minHeight: "300px",
  },
  profileDetails: {
    color: theme.palette.text.secondary,
  },
}));

export default function CoachProfileEditMainContainer() {
  const classes = useStyles();

  return (
    <div className={classes.profileDetails}>
      <ProfileDetailsEditSectionContainer></ProfileDetailsEditSectionContainer>
    </div>
  );
}
