import React, { useState, useEffect, useRef } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Checkbox from "@material-ui/core/Checkbox";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Divider from "@material-ui/core/Divider";
import Avatar from "@material-ui/core/Avatar";
import Badge from "@material-ui/core/Badge";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { red } from "@material-ui/core/colors";
import { Link } from "react-router-dom";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Tooltip from "@material-ui/core/Tooltip";
import GetAppIcon from "@material-ui/icons/GetApp";
import Chip from "@material-ui/core/Chip";
import HeadShot from "../../sharedComponents/profilePhoto/HeadShot";
import Pending from "../../images/Pending.png";
import Approve from "../../images/approve.png";
import Hold from "../../images/hold.png";
import yellow from "@material-ui/core/colors/yellow";

import BookmarkBorderIcon from "@material-ui/icons/BookmarkBorder";
import BookmarkIcon from "@material-ui/icons/Bookmark";
import MedalIcon from "../../sharedComponents/customIcons/MedalIcon";
import TrophyIcon from "../../images/trophy.png";
import DeleteIcon from '@material-ui/icons/Delete';
import Helper from "../../common/Helper";
import ProfileDialogs from "../../sharedComponents/dialog/ProfileDialog";
import { coachProfileService } from "../../services";
//import download from "downloadjs";
//import { saveAs } from 'file-saver';
import APIURLConstants from "../../common/ApiURLConstants";
import ProfileCommonFunction from "./ProfileCommonFunction";
import RouteConstant from '../../common/RouteConstants';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Paper from '@material-ui/core/Paper';
import AppConstants from '../../common/AppConstants';
import RejectDialog from '../../sharedComponents/dialog/RejectDialog';
import DeleteProfileDialog from '../../sharedComponents/dialog/deleteProfileDialog';
import { Role } from '../../helper';
import CircularProgress from "@material-ui/core/CircularProgress";
import useTelemetry from "../../utils/useTelemetry";

const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
		[theme.breakpoints.up("sm")]: {
			padding: theme.spacing(1),
		},
	},
	gridItem: {
		[theme.breakpoints.up("sm")]: {
			width: "100%",
			padding: theme.spacing(1),
		},
		[theme.breakpoints.down("xs")]: {
			width: "100%",
			paddingBottom: theme.spacing(1),
		},
	},
	card: {
		height: "355px",
		border: "1px solid #e8e8e8",
		boxShadow: "0px 3px 5px 2px rgba(0, 0, 0, 0.1)",
		borderRadius: 16
	},
	CardContent: {
		paddingBottom: "4px !important",
	},
	cardHeader: {
		height: "96px",
		overflow: "hidden",
		"& button": {
			padding: theme.spacing(0.5),
		},
		backgroundColor: "#C0CCE2"
	},
	bookmark: {
		color: "#7c9adb",
	},
	pastExpSection: {
		height: "70px",
		overflow: "hidden",
	},
	pastshowcaseExpSection: {
		height: "60px",
		overflow: "hidden",
	},
	pastCoachingList: {
		color: "#383838",
		fontWeight: "bold",
	},
	skills: {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
		height: "49px",
		overflow: "hidden",
	},
	skillChip: {
		marginRight: theme.spacing(1),
		backgroundColor: "#7B99D7",
		color: "#FFF",
		border: "1px solid #7B99D7",
		fontWeight: "bold",
		maxWidth: '86%',
	},
	downloadAction: {
		marginLeft: "auto",
		color: "#000",
	},
	mainAvatar: {
		width: theme.spacing(8),
		height: theme.spacing(8),
		border: "2px solid #1D428A",
	},
	statusAvatar: {
		width: theme.spacing(3),
		height: theme.spacing(3),
		border: "2px solid #484e5b",
	},
	avatarGroup: {
		// marginLeft: theme.spacing(1),
		display: "flex",
		alignItems: "center",
		"& $countCircle": {
			height: "25px",
			width: "25px",
			fontSize: theme.spacing(1.5),
		},
		"& $pastCoachingList": {
			fontSize: '.8rem',
			color: "#000",
		},
	},
	avatarGroupItem: {
		width: theme.spacing(3.5),
		height: theme.spacing(3.5),
		marginRight: theme.spacing(0.5),
	},
	Name: {
		fontWeight: "bolder",
		fontSize: "18px",
		textDecoration: "none",
	},
	heading: {
		fontWeight: "bold",
	},
	cardSubHeaderTitle: {
		fontWeight: 600,
	},
	cardFooterTitle: {
		fontSize: theme.spacing(1.5),
	},
	section: {
		marginTop: theme.spacing(1),
	},
	countCircle: {
		height: "25px",
		width: "25px",
		backgroundColor: "#7B99D7",
		borderRadius: "50%",
		display: "inline-block",
		color: "#fff",
		textAlign: "center",
		lineHeight: "25px",
		fontWeight: "bold",
	},
	TrophyIcon: {
		position: 'absolute',
		right: '18%',
		bottom: '18%',
		transform: 'scale(1) translate(50%, 50%)',
		transformOrigin: '100% 100%',
		zIndex: '2',
		height: theme.spacing(3.25),
		width: theme.spacing(3.25),
	},
	inputCheckBox: {
		display: 'flex',
		justifyContent: 'flex-end',
		'& > span': {
			padding: theme.spacing(.5),
		},
	},
}));

const ChampionshipBadge = withStyles((theme) => ({
	badge: {
		color: yellow.A700,
	},
}))(Badge);

const HtmlTooltip = withStyles((theme) => ({
	tooltip: {
		backgroundColor: '#f5f5f9',
		color: 'rgba(0, 0, 0, 0.87)',
		maxWidth: 220,
		fontSize: theme.typography.pxToRem(12),
		border: '1px solid #dadde9',
	},
}))(Tooltip);

export default function ProfileCard(props) {
	const classes = useStyles();
	const [open, setOpen] = useState(false);
	const [openDialog, setOpenDialog] = useState(false);
	const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
	const [isDownloading, setIsDownloading] = useState(false);
	const anchorRef = useRef(null);
	const { trackCustomEvents } = useTelemetry();

	const handleToggle = () => {
		setOpen(!open);
	};

	const handleClose = (event) => {
		if (anchorRef.current && anchorRef.current.contains(event.target)) {
			return;
		}
		setOpen(false);
	};

	const [fullName, setFullName] = useState(
		props.data && props.data.personalDetails.fullName
			? props.data.personalDetails.fullName
			: {}
	);
	const [
		coachingAndOtherJobExperience,
		setCoachingAndOtherJobExperience,
	] = useState(
		props.data && props.data.coachingAndOtherJobExperience
			? props.data.coachingAndOtherJobExperience
			: []
	);
	const [playingExperience, setPlayingExperience] = useState(
		props.data && props.data.playingExperience
			? props.data.playingExperience
			: []
	);
	const [currentCoachingExp, setCurrentCoachingExp] = useState([]);
	const [pastCoachingExp, setPastCoachingExp] = useState([]);
	const [coachingSkill, setCoachingSkill] = useState(
		props.data.skillSet && props.data.skillSet.coachingSkill
			? props.data.skillSet.coachingSkill
			: []
	);
	const [headCoaches, setHeadCoaches] = useState([]);

	const [bookmarked, setBookmarked] = useState(false);
	const [wonChampianship, setWonChampianship] = useState(0);
	const [yearOfExp, setYearOfExp] = useState({ year: 0, month: 0 });
	const [showApprove, setShowApprove] = useState(false);
	const [showReject, setShowReject] = useState(false);

	let Name = fullName.firstName + " " + fullName.lastName;

	useEffect(() => {
		setFullName(
			props.data && props.data.personalDetails.fullName
				? props.data.personalDetails.fullName
				: {}
		);
		setCoachingAndOtherJobExperience(
			props.data && props.data.coachingAndOtherJobExperience
				? props.data.coachingAndOtherJobExperience
				: []
		);
		setCoachingSkill(
			props.data.skillSet && props.data.skillSet.coachingSkill
				? props.data.skillSet.coachingSkill
				: []
		);
		setPlayingExperience(
			props.data && props.data.playingExperience
				? props.data.playingExperience
				: []
		);
		getBookMarked();
		let count = ProfileCommonFunction.getWonChampionship(
			coachingAndOtherJobExperience,
			playingExperience
		);
		setWonChampianship(count);
		getShowApprove();
		getShowReject();
	}, [props.data]);

	function getBookMarked() {
		let bookmarkedBy =
			props.data && props.data.bookmarkedBy ? props.data.bookmarkedBy : [];
		if (bookmarkedBy != null) {
			if (bookmarkedBy.includes(props.userName)) {
				setBookmarked(true);
			} else {
				setBookmarked(false);
			}
		}
	}
	function getShowApprove() {
		if (props.data.status.toLowerCase() === AppConstants.PROFILE_STATUS.SUBMITTED ||
			props.data.status.toLowerCase() === AppConstants.PROFILE_STATUS.DENIED ||
			props.data.status.toLowerCase() === AppConstants.PROFILE_STATUS.RESUBMITTED) {
			setShowApprove(true);
		} else {
			setShowApprove(false);
		}
	}

	function getShowReject() {
		if (props.data.status.toLowerCase() === AppConstants.PROFILE_STATUS.SUBMITTED ||
			props.data.status.toLowerCase() === AppConstants.PROFILE_STATUS.APPROVED ||
			props.data.status.toLowerCase() === AppConstants.PROFILE_STATUS.RESUBMITTED) {
			setShowReject(true);
		} else {
			setShowReject(false);
		}
	}

	useEffect(() => {
		Name = fullName.firstName + " " + fullName.lastName;
		let outPutCurrentCoaching = ProfileCommonFunction.getCurrentCoachingExperience(
			coachingAndOtherJobExperience,
			headCoaches
		);
		setCurrentCoachingExp(outPutCurrentCoaching.currentExp);
		let outPutPastCoaching = ProfileCommonFunction.getPastCoachingExperience(
			coachingAndOtherJobExperience,
			headCoaches
		);
		let outputHeadCoaches = ProfileCommonFunction.calculateHeadcoaches(
			coachingAndOtherJobExperience,
			playingExperience
		);
		setHeadCoaches(outputHeadCoaches);
		setPastCoachingExp(outPutPastCoaching.pastExp);
		let yearAndMonth = ProfileCommonFunction.getYearOfExperience(
			coachingAndOtherJobExperience
		);
		setYearOfExp(yearAndMonth);
		setCoachingSkill(
			props.data.skillSet && props.data.skillSet.coachingSkill
				? props.data.skillSet.coachingSkill
				: []
		);
	}, [coachingAndOtherJobExperience]);

	const handleBookMarked = (mode) => {
		setBookmarked(mode);
		props.handleBookMarked(mode, props.data.userName);
	};

	function donwloadPDF(event) {
		event.preventDefault();
		setIsDownloading(true)
		var xhr = coachProfileService.downloadPDF(props.data.userName);
		xhr.onload = function (e) {
			if (this.status == 200) {
				let fileName = fullName.firstName + "_" + fullName.lastName;
				fileName = fileName.replace(/([^a-z0-9 _]+)/gi, '');

				var blob = new Blob([this.response], { type: "application/pdf" });
				var link = document.createElement("a");
				link.href = window.URL.createObjectURL(blob);
				link.download = fileName + ".pdf";
				link.click();

			}
			setIsDownloading(false)
		};
		xhr.send();
		trackCustomEvents("ClickEvent", { event: "Download PDF from Card" });
	}

	function handleClick() {
		if (showApprove)
			props.handleProfileStatusChange(props.data.userName, AppConstants.PROFILE_STATUS.APPROVED)
		else if (props.data.status.toLowerCase() === AppConstants.PROFILE_STATUS.APPROVED) {
			setOpenDialog(true);
		}
	}
	function handleRejectClick() {
		setOpenDialog(true);
		//props.handleProfileStatusChange(props.data.userName, AppConstants.PROFILE_STATUS.DENIED)
	}

	function handleDeleteProfileClick() {
		setOpenDeleteDialog(true);
	}

	function btnRejectClick(note, sendMail) {
		props.handleProfileStatusChange(props.data.userName, AppConstants.PROFILE_STATUS.DENIED, note, sendMail)
	}

	function btnDeleteClick() {
		props.handleDeleteProfile(props.data.email);
	}

	const [openSkillTooltip, setOpenSkillTooltip] = React.useState(false);
	const handleTooltipClose = () => {
		setOpenSkillTooltip(false);
	};
	const handleTooltipOpen = () => {
		setOpenSkillTooltip(true);
	};

	const [openHeadCoachTooltip, setOpenHeadCoachTooltip] = useState(false);
	const handleHeadCoachTooltipClose = () => {
		setOpenHeadCoachTooltip(false);
	};
	const handleHeadCoachTooltipOpen = () => {
		setOpenHeadCoachTooltip(true);
	};

	const [openCurrentCoachingTooltip, setOpenCurrentCoachingTooltip] = useState(false);
	const handleCurrentCoachingTooltipClose = () => {
		setOpenCurrentCoachingTooltip(false);
	};
	const handleCurrentCoachingTooltipOpen = () => {
		setOpenCurrentCoachingTooltip(true);
	};
	// const handleProfileChecked = (event) => {
	// 	props.handleProfileChecked(event, props.data.userId);
	// };
	const checkProfileCheckbox = (userId) => {
		return props.selectedProfiles ? props.selectedProfiles.includes(userId) : false;
	}
	return (
		<Grid item xl={3} lg={4} md={6} sm={6} xs={12} className={classes.gridItem}>
			<Card className={classes.card} style={props.data.needToHighlight ? { boxShadow: "0px 0px 5px 4px orange" } : {}}>
				<CardHeader
					className={classes.cardHeader}
					avatar={
						<React.Fragment>
							{wonChampianship > 0 && (
								<Tooltip
									onClick={() => !props.hideAction ? props.handleOpenDialog(props.data, showApprove, showReject) : false}
									title={`${wonChampianship} championships won`}
									style={{ cursor: "pointer" }}
								>
									<ChampionshipBadge>
										<Avatar variant="square" className={classes.TrophyIcon} src={TrophyIcon}></Avatar>
										<HeadShot
											userName={props.data.userName}
											coachName={Name}
											className={classes.mainAvatar}
											profilePhoto={
												props.data.personalDetails &&
													props.data.personalDetails.profilePhoto
													? props.data.personalDetails.profilePhoto
													: ""
											}
										/>
									</ChampionshipBadge>
								</Tooltip>
							)}
							{wonChampianship == 0 && (
								<span
									style={{ cursor: "pointer" }}
									onClick={() => !props.hideAction ? props.handleOpenDialog(props.data, showApprove, showReject) : false}
								>
									{" "}
									<HeadShot
										userName={props.data.userName}
										coachName={Name}
										className={classes.mainAvatar}
										profilePhoto={
											props.data.personalDetails &&
												props.data.personalDetails.profilePhoto
												? props.data.personalDetails.profilePhoto
												: ""
										}
									/>
								</span>
							)}
						</React.Fragment>
					}
					action={
						<>
							{!props.hideAction &&
								(props.container === AppConstants.DASHBOARD_TYPE.ADMIN || props.container === AppConstants.DASHBOARD_TYPE.FRONT_OFFICE) &&
								!props.hideAction && <Grid item className={classes.inputCheckBox}>
									<Checkbox
										style={{ color: "#484e5b" }}
										name="chkGroup"
										id={props.data.userId}
										checked={checkProfileCheckbox(props.data.userName)}
										onChange={(event) => props.handleProfileChecked(event, props.data.userName)}
									/>
								</Grid>
							}
							{!props.hideAction && props.container === AppConstants.DASHBOARD_TYPE.FRONT_OFFICE && props.role !== Role.Coach ?
								(bookmarked ?
									<Link to="#" onClick={() => handleBookMarked(false)}>
										<Tooltip title="Bookmark">
											<IconButton
												className={classes.bookmark}
												aria-label="add to highlighted profiles"
											>
												<BookmarkIcon style={{ color: "#484e5b" }} />{" "}
											</IconButton>
										</Tooltip>
									</Link>
									:
									<Link to="#" onClick={() => handleBookMarked(true)}>
										<Tooltip title="Bookmark">
											<IconButton aria-label="add to highlighted profiles">
												<BookmarkBorderIcon style={{ color: "#484e5b" }} />
											</IconButton>
										</Tooltip>
									</Link>
								) : <div>
									{!props.hideAction && (showApprove || showReject) && props.role !== Role.Coach && <IconButton
										ref={anchorRef}
										aria-label="more"
										aria-controls={open ? 'menu-list-grow' : undefined}
										aria-haspopup="true"
										onClick={handleToggle}
									>
										<MoreVertIcon style={{ color: "#484e5b" }} />
										{(showApprove && showReject) && <><Avatar alt="Pending" src={Pending} className={classes.statusAvatar} ></Avatar>
										</>}
										{(!showApprove && showReject) && <>
											<Avatar alt="Approve" src={Approve} className={classes.statusAvatar} ></Avatar>
										</>}
										{(showApprove && !showReject) && <>
											<Avatar alt="Hold" src={Hold} className={classes.statusAvatar} ></Avatar>
										</>}

									</IconButton>}
									<Popper style={{ zIndex: 99 }} placement='bottom-end' open={open} anchorEl={anchorRef.current} role={undefined} transition='fade' disablePortal>
										{({ TransitionProps, placement }) => (
											// <Grow
											//   {...TransitionProps}
											//   style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
											// >
											<Paper>
												<ClickAwayListener onClickAway={handleClose}>
													<MenuList>
														{showApprove && <MenuItem onClick={handleClick}>Approve</MenuItem>}
														{showReject && <MenuItem onClick={handleRejectClick}>On Hold </MenuItem>}
														{(showApprove || showReject) && <MenuItem onClick={() => props.handleEditProfileClick(props.data.userName)}> Edit Profile</MenuItem>}
														{(showApprove) && <MenuItem onClick={handleDeleteProfileClick}> <div style={{ color: 'red' }}>Delete Profile</div></MenuItem>}
														{/* <MenuItem onClick={handleStatusChange}>{props.data.isActive ? 'Change to Inactive' :'Change to Active' }  </MenuItem>  */}
													</MenuList>
												</ClickAwayListener>
											</Paper>
											//</Grow>
										)}
									</Popper>
								</div>
							}
						</>
					}
					title={
						<Typography style={{ cursor: "pointer", color: "#484e5b" }}>
							{" "}
							<span data-gaeventlabel={fullName.firstName + " " + fullName.lastName}
								data-gaeventcategory="Coach Profile card click"
								data-gaeventaction="Profile Name Click"
								className={classes.Name} onClick={() => !props.hideAction ? props.handleViewProfileClick(props.data.userName) : false}>
								{" "}
								{fullName.firstName} {fullName.lastName}{" "}
							</span>
						</Typography>
					}
					subheader={<>
						{currentCoachingExp && currentCoachingExp.length == 0 && <Typography variant="caption" style={{ color: "#484e5b" }}>{AppConstants.CURRENTLY_NOT_WORKING}</Typography>}
						{currentCoachingExp &&
							currentCoachingExp.length > 0 &&
							<React.Fragment>
								{[currentCoachingExp[0]].map((data, index) => {
									return (
										<React.Fragment key={index}>
											<div
												style={{ cursor: "pointer", color: "#484e5b" }}
												onClick={() => !props.hideAction ? props.handleOpenDialog(props.data, showApprove, showReject) : false}
											>
												{data.title}
											</div>
											<Typography
												style={{ cursor: "pointer", color: "#484e5b" }}
												onClick={() => !props.hideAction ? props.handleOpenDialog(props.data, showApprove, showReject) : false}
												variant="caption"
												className={classes.cardSubHeaderTitle}
											>
												{data.teamName}, {props.getLeagueTextByValue(data.league)}
											</Typography>
										</React.Fragment>
									);
								})
								}{" "}
								{currentCoachingExp.length > 1 && <>
									<ClickAwayListener onClickAway={handleCurrentCoachingTooltipClose}>
										<HtmlTooltip open={openCurrentCoachingTooltip}
											title={currentCoachingExp.map((data, index) => {
												return (
													<React.Fragment key={index}>
														{index > 0 && <>
															<div
																style={{ cursor: "pointer" }}
																onClick={() => !props.hideAction ? props.handleOpenDialog(props.data, showApprove, showReject) : false}
															>
																{data.title}
															</div>
															<Typography
																style={{ cursor: "pointer" }}
																onClick={() => !props.hideAction ? props.handleOpenDialog(props.data, showApprove, showReject) : false}
																variant="caption"
																className={classes.cardSubHeaderTitle}
															>
																{data.teamName}, {props.getLeagueTextByValue(data.league)}
															</Typography>
															{index != (currentCoachingExp.length - 1) && <Divider></Divider>}
														</>}
													</React.Fragment>
												);
											})
											}
										>
											<Typography
												variant="caption"
												color="textSecondary"
												onClick={handleCurrentCoachingTooltipOpen}
												style={{ cursor: "pointer" }}
												className={classes.countCircle}
											>
												+{currentCoachingExp.length - 1}
											</Typography>
										</HtmlTooltip>
									</ClickAwayListener></>}
							</React.Fragment>
						}
					</>}
				/>
				<Divider></Divider>
				<CardContent className={classes.CardContent}>
					<Typography
						variant="body2"
						color="primary"
						className={classes.heading}
					>
						Coaching experience
					</Typography>
					<Typography variant="caption" color="textSecondary">
						{yearOfExp.year} years & {yearOfExp.month} months
					</Typography>
					<div className={!props.hideAction ? `${classes.section}  ${classes.pastExpSection}` : `${classes.section}  ${classes.pastshowcaseExpSection}`}>
						<Typography
							variant="body2"
							color="primary"
							className={classes.heading}
						>
							Past coaching experience
						</Typography>
						{pastCoachingExp.length > 0 && <>
							{pastCoachingExp.map((data, index) => {
								return (
									<div key={index}>
										<Typography
											variant="caption"
											className={classes.pastCoachingList}
										>
											{data.teamName} ({props.getLeagueTextByValue(data.league)}) -{" "}
											{data.fromDate}-{data.toDate}
										</Typography>
									</div>
								);
							})}</>}
						{pastCoachingExp.length == 0 && <Typography variant="caption" style={{ color: "rgba(0, 0, 0, 0.54)" }}>{AppConstants.NA}</Typography>}
					</div>
					<div className={classes.skills}>
						<Typography
							variant="body2"
							color="primary"
							className={classes.heading}
						>
							Skills
						</Typography>
						{coachingSkill.map((data, index) => {
							return (
								index < 1 && (
									<Chip
										key={index}
										variant="outlined"
										size="small"
										className={classes.skillChip}
										label={<Typography variant="caption">{data}</Typography>}
									/>
								)
							);
						})}
						{coachingSkill.length > 1 &&
							<ClickAwayListener onClickAway={handleTooltipClose}><Tooltip onClose={handleTooltipClose} open={openSkillTooltip} title={coachingSkill.filter(x => x != coachingSkill[0]).join(", ")}>
								<Typography onClick={handleTooltipOpen} style={{ cursor: "pointer" }} variant="caption" color="textSecondary" className={classes.countCircle}>+{coachingSkill.length - 1}</Typography></Tooltip></ClickAwayListener>}

					</div>
					<Divider></Divider>

					<div style={{ display: "flex", justifyContent: "space-between" }}>
						<div>
							{headCoaches && headCoaches.length > 0 && (
								<>
									<Typography
										variant="subtitle2"
										color="textSecondary"
										className={classes.cardSubHeaderTitle}
									>
										Head Coaches worked with
									</Typography>
									<ClickAwayListener onClickAway={handleHeadCoachTooltipClose}>
										<Tooltip onClose={handleHeadCoachTooltipClose} open={openHeadCoachTooltip}
											title={
												headCoaches
													.filter((x) => x != headCoaches[0])
													.join(",\n ")
											}
										>
											<div className={classes.avatarGroup}>
												{headCoaches.map((data, index) => {
													return (
														index < 1 && (
															<Typography
																key={index}
																variant="caption"
																className={classes.pastCoachingList}
																style={{ paddingRight: "5px" }}
															>
																{data}
															</Typography>
														)
													);
												})}
												{headCoaches.length > 1 && (
													<Typography
														variant="caption"
														color="textSecondary"
														onClick={handleHeadCoachTooltipOpen}
														style={{ cursor: "pointer" }}
														className={classes.countCircle}
													>
														+{headCoaches.length - 1}
													</Typography>
												)}
											</div>
										</Tooltip></ClickAwayListener>
								</>
							)}
						</div>
						{!props.hideAction && <Tooltip title="Download profile PDF">
							<div>{isDownloading ? <div style={{ width: "48px", height: "48px", padding: "12px" }}><CircularProgress size={20} color="inherit" /></div> :
								<Link to="#" onClick={donwloadPDF}>
									<IconButton
										aria-label="download"
										className={classes.downloadAction}
									>
										<GetAppIcon />
									</IconButton>
								</Link>
							}

							
							</div>
						</Tooltip>}
					</div>
				</CardContent>
			</Card>
			<RejectDialog
				open={openDialog}
				onClose={() => setOpenDialog(false)}
				onReject={(note, sendMail) => btnRejectClick(note, sendMail)}
			/>
			<DeleteProfileDialog
				open={openDeleteDialog}
				onClose={() => setOpenDeleteDialog(false)}
				onDelete={btnDeleteClick}
			/>
		</Grid>
	);
}