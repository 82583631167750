import { Box, useTheme } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";
import SwipeableViews from "react-swipeable-views";
import MediaCardContainer from "../../sharedComponents/mediaCard/MediaCardContainer";
import AppConstants from "../../common/AppConstants";

const MediaManagementTabPanel = (props) => {

    const theme = useTheme();

    function TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <Box
                component="div"
                role="tabpanel"
                hidden={value !== index}
                id={`scroll-tabpanel-${index}`}
                aria-labelledby={`scroll-tab-${index}`}
                {...other}
            >
                {value === index && <div p={3}>{children}</div>}
            </Box>
        );
    }

    TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.any.isRequired,
        value: PropTypes.any.isRequired,
    };

    return (
        <SwipeableViews
            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
            index={props.tabIndex}
            onChangeIndex={props.handleTabChange}
        >
            <TabPanel value={props.tabIndex} index={0} dir={props?.theme?.direction}>
                <MediaCardContainer
                    userName={props.currentUserName}
                    container={AppConstants.DASHBOARD_TYPE.ADMIN}
                    handleOpenDialog={props.handleOpenDialog}
                    handleProfileStatusChange={props.handleProfileStatusChange}
                    getLeagueTextByValue={props.getLeagueTextByValue}
                    handleEditProfileClick={props.handleEditProfileClick}
                    handleViewProfileClick={props.handleViewProfileClick}
                    handleProfileChecked={props.handleProfileChecked}
                    selectedProfiles={props.selectedProfiles}
                    handleDeleteProfile={props.handleDeleteProfile}
                    //... Using the coach card container for handling media content for approval. If showMediaContent is true render the video content.
                    showMediaContent={true}
                />
            </TabPanel>
        </SwipeableViews>
    )
}

export default MediaManagementTabPanel;