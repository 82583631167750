import React, { useState, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, TextField, Button } from "@material-ui/core/";
import zxcvbn from "zxcvbn";
import useRegistrationForm from "../../sharedComponents/customHooks/useRegistrationForm";
import changePasswordValidationSchema from "../../validationSchema/changePasswordValidationSchema";
import { userService } from '../../services';
import SnackbarMsg from "../../sharedComponents/Snackbar/Snackbar";
import PasswordValidationSchema from "../../validationSchema/PasswordValidationSchema";
import { Popover, PopoverHeader, PopoverBody } from 'reactstrap';
import Card from "@material-ui/core/Card";
import Box from "@material-ui/core/Box";
import { yellow } from "@material-ui/core/colors";
import red from "@material-ui/core/colors/red";
import Loader from "../../sharedComponents/Loader/Loader";

const primary800 = red["800"];
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
  },
  grid: {
    display: "flex",
    flexGrow: 1,
    flexWrap: "wrap",
    paddingTop: theme.spacing(1),
    backgroundColor: theme.palette.background.default,
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(1),
    },
    [theme.breakpoints.down("xs")]: {
      padding: 0,
    },
  },
  section: {
    // marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
    textAlign: "left",
    padding: "5px 25px",
  },
  passwordRule: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(1),
    color: "#fff",
  },
  sectionPopover: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    textAlign: "left",
  },
  popoverInnerSection: {
    '&::before': {
      position: 'absolute',
      display: 'block',
      content: "''",
      borderColor: 'transparent',
      borderStyle: 'solid',
      top: '0',
      left: '10px',
      borderWidth: '0 0.5rem 0.5rem 0.5rem',
      borderBottomColor: 'rgba(0, 0, 0, 0.25)',
    },
    '&::after': {
      position: 'absolute',
      display: 'block',
      content: "''",
      borderColor: 'transparent',
      borderStyle: 'solid',
      top: '1px',
      left: '10px',
      borderWidth: '0 0.5rem 0.5rem 0.5rem',
      borderBottomColor: '#fff',
    },
    background: '#fff !important',
    boxShadow: '0px 3px 5px 2px rgba(0, 0, 0, 0.5)',
    padding: theme.spacing(1),
    '& p': {
      color: '#c70e2d',
      fontSize: '.75rem',
      lineHeight: '1',
      padding: theme.spacing(.3, 0),
    },
  },
  gridItem: {
    [theme.breakpoints.up("sm")]: {
      width: "100%",
      padding: theme.spacing(1),
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      paddingBottom: theme.spacing(2),
    },
  },
  profileSummary: {
    padding: theme.spacing(2),
    minHeight: "300px",
  },
  profileDetails: {
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  sectiondivider: {
    margin: theme.spacing(1),
  },
}));

export default function ChangePassword() {

  const classes = useStyles();
  const container = useRef(null);

  const [state, setState] = useState({
    currentPassword: "",
    password: "",
    confirmPassword: "",
  });
  const [pwdNotMatch, setpwdNotMatch] = useState(false);
  const [showError, setShowError] = useState(false);
  const [severity, setSeverity] = useState("");
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const onSubmitForm = (validationErrors) => {
    //validationErrors: this is hack for now. need to correct it with useeffect
    if (values.password != values.confirmPassword) {
      setpwdNotMatch(true);
    } else {
      setpwdNotMatch(false);
    }
    let errorKeys = Object.keys(validationErrors);
    let valid = PasswordValidationSchema.ValidatePassword(values.password);
    if (!valid) {
      handleOpen();
    }
    else if (errorKeys && errorKeys.length > 0) {
      showAlert("error", 'New password and confirm password do not match');
    }
    else {
      setIsLoading(true);
      userService.changePassword(values.currentPassword, values.password)
        .then((data) => {
          values.currentPassword = "";
          values.password = "";
          values.confirmPassword = "";
          setIsLoading(false);
          if (typeof data == "string") {
            showAlert("error", data);
          }
          else {
            showAlert("success", "Password Changed Successfully");
          }
        })
        .catch((err) => {
          values.currentPassword = "";
          values.password = "";
          values.confirmPassword = "";
          setIsLoading(false);
          showAlert("error", err);
        })
    }

  };

  function showAlert(sever, msg) {
    setShowError(true);
    setSeverity(sever)
    setMessage(msg);

  }


  const handleClose = () => {
    setShowError(false);
    setSeverity("")
    setMessage("");
  }

  const {
    errors,
    handleInputChange,
    handleOnSubmit,
    values,
  } = useRegistrationForm(state, onSubmitForm, changePasswordValidationSchema);


  const testedResult = zxcvbn(values.password);
  const createPasswordLabel = (result) => {
    switch (result.score) {
      case 0:
        return "Weak";
      case 1:
        return "Weak";
      case 2:
        return "Fair";
      case 3:
        return "Good";
      case 4:
        return "Strong";
      default:
        return "Weak";
    }
  };

  const [popoverOpen, setPopoverOpen] = useState(false);


  const handleOpen = () => setPopoverOpen(true);
  const handleClosePopOver = () => setPopoverOpen(false);
  return (
    <div className={classes.section}>
      <Typography variant="subtitle1" color="secondary">
        Change Password
    </Typography>
      <div className={classes.sectiondivider}></div>
      <Grid item md={4} sm={12} className={classes.gridItem}>
        <TextField
          type="password"
          value={values.currentPassword}
          id="coachCurrentPassword"
          name="currentPassword"
          variant="outlined"
          size="small"
          label="Current Password"
          onChange={handleInputChange}
          error={
            errors &&
            errors.currentPassword &&
            errors.currentPassword.error
          }
          fullWidth
        ></TextField>
        <div className={classes.sectiondivider}></div>

        <TextField
          type="password"
          value={values.password}
          id="coachPassword"
          name="password"
          variant="outlined"
          size="small"
          label="New Password"
          onFocus={handleOpen}
          onBlur={handleClosePopOver}
          onChange={handleInputChange}
          error={errors && errors.password && errors.password.error}
          fullWidth
        ></TextField>
        <Popover placement="bottom-start" isOpen={popoverOpen} target="coachPassword">
          <PopoverBody>
            <div className={classes.sectionPopover}>
              <Card style={{ backgroundColor: '#7986cb' }} className={classes.popoverInnerSection}>
                <Typography className={classes.passwordRule}>Password should be minimum 8 and maximum 50 characters.</Typography>
                <Typography className={classes.passwordRule}>Password should contain at least one uppercase , one lowercase, one digit.</Typography>
                <Typography className={classes.passwordRule}>Password should not contain space(s).</Typography>
              </Card>
            </div>
          </PopoverBody>

        </Popover>

        <div className="password-strength-meter">
          <progress
            value={testedResult.score}
            className={`password-strength-meter-progress strength-${createPasswordLabel(
              testedResult
            )}`}
            max="4"
          />
        </div>

        <div className={classes.sectiondivider}></div>
        <TextField
          type="password"
          id="coachConfirmPassword"
          value={values.confirmPassword}
          variant="outlined"
          size="small"
          name="confirmPassword"
          label="Confirm Password"
          onChange={handleInputChange}
          fullWidth
          error={
            errors &&
            errors.confirmPassword &&
            errors.confirmPassword.error
          }
        ></TextField>

        <div className={classes.sectiondivider}></div>
        <Button
          color="primary"
          variant="contained"
          fullWidth
          onClick={handleOnSubmit}
          disabled={isLoading}
        >
          Submit
      </Button>
        <Loader isLoading={isLoading} />
      </Grid>
      <SnackbarMsg showError={showError} onClose={handleClose} severity={severity} msg={message} />
    </div>

  );
}
