import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

import React, { createContext, useContext, useState } from 'react';

const ToastContext = createContext();

export const useToast = () => useContext(ToastContext);

export const ToastProvider = ({ children }) => {
    const [toastMessage, setToastMessage] = useState('');
    const [toastMessageSeverity, setToastMessageSeverity] = useState('success');
    const [openSnackbar, setOpenSnackbar] = useState(false);

    const showToast = (severity, message) => {
        setOpenSnackbar(true);
        setToastMessage(message);
        setToastMessageSeverity(severity);
        setTimeout(() => {
            setToastMessage('');
            setOpenSnackbar(false);
        }, 3000); // Hide toast after 3 seconds
    };

    return (
        <ToastContext.Provider value={{ showToast }}>
            {children}
            {toastMessage && <Snackbar open={openSnackbar} autoHideDuration={6000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                TransitionComponent="fade">
                <Alert severity={toastMessageSeverity}>
                    {toastMessage}
                </Alert>
            </Snackbar>}
        </ToastContext.Provider>
    );
};