import React, { useState, useEffect, useCallback, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import ListItem from "@material-ui/core/ListItem";
import FullName from "../../../sharedComponents/questionComponents/FullName";
import RoleAndCoachingPhilosophy from "../../../sharedComponents/questionComponents/RoleAndCoachingPhilosophy";
import ImageFileUpload from "../../../sharedComponents/questionComponents/ImageFileUpload";

import VideoURLs from "../../../sharedComponents/questionComponents/VideoURLs";
import SaveButton from "../../../sharedComponents/saveButton/SaveButton";

import TitleWithTooltip from "../../../sharedComponents/TitleWithTooltip";
import AppConstant from "../../../common/AppConstants";
import CaptionMsg from "../../../sharedComponents/CaptionMsg";
import Helper from "../../../common/Helper";
import useTelemetry from "../../../utils/useTelemetry";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  section: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  grid: {
    padding: theme.spacing(1),
    alignSelf: "center",
  },
  question: {
    color: theme.palette.info,
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(1),
    },
  },
  question1: {
    color: theme.palette.tonalOffset,
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(1),
    },
  },
}));

const PersonalDetails = (props) => {
  const classes = useStyles();
  const { trackCustomEvents } = useTelemetry();
  //added hack for only allowing 180 characters into html editor 
  const [summaryCharLength, setSummaryCharLength] = useState(props.data?.philosophyState?.description ? Helper.RemoveTags(props.data.philosophyState?.description).length : 0);

useEffect(() => {
  setSummaryCharLength(Helper.RemoveTags(props.data.philosophyState?.description).length);
}, [props.data.philosophyState?.description]);

  function handleProfileSummaryChange(name, value) {
    let stringWithoutHtmlTags = Helper.RemoveTags(value);
    setSummaryCharLength(stringWithoutHtmlTags.length);
    props.onPhilosophyChange(name, value);
  }

  function savePersonalDetails() {
    props.onChange();
    trackCustomEvents("EditEvent", { action: "Personal Details" });
  }
  return (
    <div className={classes.root}>
      <div className={classes.section}>
        <TitleWithTooltip
          title="1. Please state your name."
          tooltipTitle="Name_ToolTip"
          variant="body2"
          class={classes.question}
        />
        <FullName
          data={props.data.fullNameState}
          onChange={props.onFullnameChange}
          validationSchema={
            props.validationSchema.fullNameValidationStateSchema
          }
        />
      </div>

      <div className={classes.section}>
        <TitleWithTooltip
          title="2. Please describe your approach to coaching, highlighting your strengths and what NBA players can expect from you, in 180 characters or less."
          tooltipTitle='CurrentRole_ToolTip'
          variant="body2"
          showRequired
          class={classes.question}
        />
        <CaptionMsg message="(This summary will appear in the at-a-glance information teams see in the blue panel at left)" />
        <RoleAndCoachingPhilosophy
          data={props.data.philosophyState}
          onChange={handleProfileSummaryChange}
          validationSchema={props.validationSchema.roleAndCoachingPhilosophy}
        />
      </div>

      <div className={classes.section}>
        <TitleWithTooltip
                  title="3. Please upload a current headshot/photo."
                  showRequired
                  additionalText="A headshot is required to help team decision-makers identify the coaching candidates with whom they are developing relationships."
          tooltipTitle="Photo_ToolTip"
          variant="body2"
          class={classes.question}
        />
        <ImageFileUpload
          data={
            props.data.profilePhotoState &&
              props.data.profilePhotoState.photoURL
              ? props.data.profilePhotoState.photoURL
              : undefined
          }
          userName={props.userName}
          onChange={props.onProfilePhotoChange}
          maxFileSize={AppConstant.MAX_PROFILE_PHOTO_FILE_SIZE}
          allowedFileType="image/*"
          onDelete={props.onProfilePhotoRemove}
          showRed={(! props.data.profilePhotoState.photoURL ||  props.data.profilePhotoState.photoURL == "")}
        ></ImageFileUpload>
      </div>

      <div className={classes.section}>
        <TitleWithTooltip
          title="4. Please share interview or coaching video URL(s)."
          tooltipTitle="Video_ToolTip"
          variant="body2"
          class={classes.question}
        />

        {/* <ImageFileUpload
          data={
            props.data.profileVideoState &&
              props.data.profileVideoState.videoURL
              ? props.data.profileVideoState.videoURL
              : undefined
          }
          userName={props.userName}
          onChange={props.onProfileVideoChange}
          maxFileSize={AppConstant.MAX_PROFILE_VIDEO_FILE_SIZE}
          allowedFileType="video/*"
          onDelete={props.onProfileVideoRemove}
        ></ImageFileUpload>
        <CaptionMsg message={"Maximum Video Size: " + AppConstant.MAX_PROFILE_VIDEO_FILE_SIZE + " MB"} />
        <TitleWithTooltip
          title="or share Video URL(s)."
          tooltipTitle="URL_ToolTip"
          variant="body2"
          class={classes.question}
        />
        <CaptionMsg message="(https://www.nba.com)" /> */}
        <VideoURLs
          data={props.data.videoUrlState}
          onChange={props.onVideoUrlChange}
          isInValid={props.isInValid}
        />
      </div>

      <div className={classes.section}>
        <TitleWithTooltip
          title="5. Please provide links/URLs to relevant articles or media reports"
          tooltipTitle="URL_Tooltip2"
          variant="body2"
          class={classes.question}
        />
        <CaptionMsg message="(https://www.nba.com)" />
        <VideoURLs
          data={props.data.mediaAndArticleState}
          onChange={props.onMediaLinkChange}
          isInValid={props.isInValid}
        />
      </div>

      <Grid item md={12} xs={12}>
        <ListItem style={{ justifyContent: "flex-end" }}>
          {summaryCharLength > 180 &&
            <div style={{color: 'red', fontSize: '12px', fontStyle: 'italic'}}>Please describe your approach to coaching in 180 characters or less.</div>
          }
          <SaveButton
            isLoading={props.isLoading}
            onClick={savePersonalDetails}
            disabled={summaryCharLength > 180}
          ></SaveButton>
        </ListItem>
      </Grid>
    </div>
  );
};

export default PersonalDetails;
