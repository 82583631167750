import AppConstants from "../../common/AppConstants";
import Moment from "@date-io/moment";
import { Role } from "../../helper/role";

export const FilterUserList = (userList, filterOptions, groupUsers, callback) => {
    let { moment } = new Moment();
    if (filterOptions.filterView === AppConstants.USER_SECTION_ID.FrontOffice) {
        userList = userList.filter((user) => {
            return (user.role === Role.FrontOffice || user.role === Role.FrontOfficePlusCoach)
        });
    } else if (filterOptions.filterView === AppConstants.USER_SECTION_ID.Coach) {
        userList = userList.filter((user) => {
            return (user.role === Role.Coach)
        });
    }
    else if (filterOptions.filterView === AppConstants.USER_SECTION_ID.Admin) {
        userList = userList.filter((user) => {
            return (user.role === Role.Admin)
        });
    }
    else if (filterOptions.filterView === AppConstants.USER_SECTION_ID.Group) {
        userList = userList.filter((user) => {
            return ((user.role === Role.FrontOffice || user.role === Role.FrontOfficePlusCoach) 
            && (groupUsers && groupUsers.includes(user.userName)))
        });
    }
    let filteredList = userList;

    if (filterOptions.selectedStatus.length != 0) {
        if (filterOptions.selectedStatus.includes("Active") && filterOptions.selectedStatus.includes("Deactive"))
            filteredList = filteredList;
        else if (filterOptions.selectedStatus.includes("Active"))
            filteredList = filteredList.filter(user => user.isActive)
        else if (filterOptions.selectedStatus.includes("Deactive"))
            filteredList = filteredList.filter(user => !user.isActive)
    }
    if (filterOptions.searchedText !== "") {
        filteredList = userList.filter((user) => {
            //filter by search text
            if (filterOptions.searchedText && filterOptions.searchedText !== "") {
                let objName = {};

                objName = { Name: user.firstName + " " + user.lastName }

                let userWithName = { ...user, ...objName };
                let jsonString = JSON.stringify(userWithName);
                if (jsonString.toLowerCase().includes(filterOptions.searchedText.trim().toLowerCase())) {
                    return user;
                }
            }
        });
    }
    if (filterOptions.selectSort !== "") {
        //callback(filteredList);
        //if (filterOptions.selectedSort !== "" && filterOptions.orderBy !== "") {
        //sortBy Name
        if (filterOptions.selectSort.toLowerCase() === "name") {
            if (filterOptions.sortOrder.toLowerCase() === "asc") {
                filteredList.sort((a, b) => {
                    return a.firstName?.toLowerCase() >
                        b.firstName?.toLowerCase()
                        ? -1
                        : a.firstName?.toLowerCase() <
                            b.firstName?.toLowerCase()
                            ? 1
                            : 0;
                });
            } else {
                filteredList.sort((a, b) => {
                    return a.firstName?.toLowerCase() <
                        b.firstName?.toLowerCase()
                        ? -1
                        : a.firstName?.toLowerCase() >
                            b.firstName?.toLowerCase()
                            ? 1
                            : 0;
                });
            }

            // }
        }
        if (filterOptions.selectSort.toLowerCase() === "username") {
            if (filterOptions.sortOrder.toLowerCase() === "asc") {
                filteredList.sort((a, b) => {
                    return a.userName.toLowerCase() >
                        b.userName.toLowerCase()
                        ? -1
                        : a.userName.toLowerCase() <
                            b.userName.toLowerCase()
                            ? 1
                            : 0;
                });
            } else {
                filteredList.sort((a, b) => {
                    return a.userName.toLowerCase() <
                        b.userName.toLowerCase()
                        ? -1
                        : a.userName.toLowerCase() >
                            b.userName.toLowerCase()
                            ? 1
                            : 0;
                });
            }
        }
        if (filterOptions.selectSort.toLowerCase() === "email") {
            if (filterOptions.sortOrder.toLowerCase() === "asc") {
                filteredList.sort((a, b) => {
                    return a.email.toLowerCase() >
                        b.email.toLowerCase()
                        ? -1
                        : a.email.toLowerCase() <
                            b.email.toLowerCase()
                            ? 1
                            : 0;
                });
            } else {
                filteredList.sort((a, b) => {
                    return a.email.toLowerCase() <
                        b.email.toLowerCase()
                        ? -1
                        : a.email.toLowerCase() >
                            b.email.toLowerCase()
                            ? 1
                            : 0;
                });
            }
        }
    }
    callback(filteredList);
};
const unique = (array) => [...new Set(array)];
const concat = (...arrays) => [].concat(...arrays.filter(Array.isArray));