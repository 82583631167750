import React, { useState, useEffect, useContext } from "react";
import { withRouter } from 'react-router-dom';
import { BehaviorSubject } from 'rxjs';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Card, CardHeader, CardContent, CardActions, Button, Divider } from "@material-ui/core/";
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import CardContainer from '../../screens/dashbord/CardContainer';

import { Container } from '@material-ui/core';
import { userService } from '../../services';
import { Role, cacheStorage } from '../../helper';
import RouteConstants from '../../common/RouteConstants';
import AppConstants from '../../common/AppConstants';
import { AppDataContext } from "../../common/AppContext";


const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    dialogHeight: {
        paddingTop: "10px",
        // minHeight: "500px",
        // maxHeight: "500px"
        overflow: 'auto',
        '& .MuiCardHeader-root': {
            maxHeight:  '96px',
            height: 'unset',
        },
    },
    progress: {
        marginLeft: '50%',

    },
    userList: {
        overflowY: 'auto',
        padding: '24px 0 100px 24px',
        backgroundColor: theme.palette.background.paper,
    },
}));

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
        backgroundColor: theme.palette.primary.main,
        color: '#fff'
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography className="font500" variant="subtitle1" noWrap>{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        [theme.breakpoints.down(281)]: {
            width: '218px',
        },
        [theme.breakpoints.between(281, 322)]: {
            width: '258px',
        },
        [theme.breakpoints.between(321, 556)]: {
            width: '275px'
        },
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

const ActiveShowCaseDialog = (props) => {
    const classes = useStyles();
    const [showCase, setShowCase] = useState();
    const [showDialog, setShowDialog] = useState();
    const { getLeagueTextByValue } = useContext(AppDataContext);

    useEffect(() => {
        userService.getAllActiveShowCase()
            .then(data => {
                if (!data) {
                   redirectUser();
                }
                else {
                    props.open ? setShowDialog(props.open): setShowDialog(data.isShow);
                    props.open ?  setShowCase(data) : (data.isShow ? setShowCase(data) : redirectUser());
                }
            })
            .catch(err => {
                setShowDialog(false);
                redirectUser();
            })
    }, []);

    const hideShowCase = () => {
        setShowDialog(false);
        if(!props.open){
            userService.hideShowcase(showCase.id)
            .then(data => {
                redirectUser();
            })
            .catch(err => {
                console.log(err);
            })
        }
        else {
            props.closeShowcaseDialog();
        }
    }

    const redirectUser = () => {
        let role = userService.getUserRole();

        if (role && (role == Role.FrontOffice || role == Role.FrontOfficePlusCoach)) {
            const { from } = { from: { pathname: RouteConstants.DASHBOARD } };
            props.history.push(from);
        }
        else if (role && role == Role.Admin) {
            const { from } = { from: { pathname: RouteConstants.COACH_MANAGEMENT } };
            props.history.push(from);
        }
        else {
            const { from } = props.location.state || { from: { pathname: RouteConstants.COACH_PROFILE_VIEW } };
            props.history.push(from);
        }
    }

    const handleViewProfileClick = (username) => {        
        hideShowCase();
        props.history.push(RouteConstants.COACHES_PROFILE_VIEW + "/" + username);        
      }

    return (<div>
        <Dialog fullWidth={true} fullScreen={false}
            maxWidth="lg" onClose={hideShowCase} aria-labelledby="customized-dialog-title" open={showDialog}>
            <DialogTitle id="customized-dialog-title" onClose={hideShowCase}>
                {showCase && showCase.title}
            </DialogTitle>
            <DialogContent dividers className={classes.dialogHeight}>
                <Container>
                    <Grid container alignItems='center' spacing={2}>
                        <Grid item xs={12} sm={12}>
                            {showCase && showCase.userProfiles && showCase.userProfiles && <CardContainer
                                userName={props.currentUserName}
                                handleViewProfileClick={handleViewProfileClick}
                                container={AppConstants.DASHBOARD_TYPE.FRONT_OFFICE}
                                profileList={showCase.userProfiles}
                                getLeagueTextByValue={getLeagueTextByValue}
                                hideAction={false}
                            ></CardContainer>
                            }
                        </Grid>
                    </Grid>
                </Container>
            </DialogContent>
            <DialogActions>
                <Button color="secondary" variant='contained' onClick={hideShowCase}>Close</Button>
            </DialogActions>
        </Dialog>
    </div >
    );
}

export default withRouter(ActiveShowCaseDialog);