import React, { useState, useRef, useEffect } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Grid, Typography, TextField, Button } from "@material-ui/core/";
import zxcvbn from "zxcvbn";
import useRegistrationForm from "../../sharedComponents/customHooks/useRegistrationForm";
import changePasswordValidationSchema from "../../validationSchema/changePasswordValidationSchema";
import { userService } from '../../services';
import SnackbarMsg from "../../sharedComponents/Snackbar/Snackbar";
import PasswordValidationSchema from "../../validationSchema/PasswordValidationSchema";
import { Popover, PopoverHeader, PopoverBody } from 'reactstrap';
import Card from "@material-ui/core/Card";
import Box from "@material-ui/core/Box";
import { yellow } from "@material-ui/core/colors";
import red from "@material-ui/core/colors/red";
import Loader from "../../sharedComponents/Loader/Loader";
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const primary800 = red["800"];
const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
    },
    grid: {
        display: "flex",
        flexGrow: 1,
        flexWrap: "wrap",
        paddingTop: theme.spacing(1),
        backgroundColor: theme.palette.background.default,
        [theme.breakpoints.up("sm")]: {
            padding: theme.spacing(1),
        },
        [theme.breakpoints.down("xs")]: {
            padding: 0,
        },
    },
    section: {
        // marginTop: theme.spacing(2),
        marginBottom: theme.spacing(3),
        textAlign: "left",
        padding: "5px 25px",
    },
    passwordRule: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(1),
        color: "#fff",
    },
    sectionPopover: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        textAlign: "left",
    },
    popoverInnerSection: {
        '&::before': {
            position: 'absolute',
            display: 'block',
            content: "''",
            borderColor: 'transparent',
            borderStyle: 'solid',
            top: '0',
            left: '10px',
            borderWidth: '0 0.5rem 0.5rem 0.5rem',
            borderBottomColor: 'rgba(0, 0, 0, 0.25)',
        },
        '&::after': {
            position: 'absolute',
            display: 'block',
            content: "''",
            borderColor: 'transparent',
            borderStyle: 'solid',
            top: '1px',
            left: '10px',
            borderWidth: '0 0.5rem 0.5rem 0.5rem',
            borderBottomColor: '#fff',
        },
        background: '#fff !important',
        boxShadow: '0px 3px 5px 2px rgba(0, 0, 0, 0.5)',
        padding: theme.spacing(1),
        '& p': {
            color: '#c70e2d',
            fontSize: '.75rem',
            lineHeight: '1',
            padding: theme.spacing(.3, 0),
        },
    },
    gridItem: {
        [theme.breakpoints.up("sm")]: {
            width: "100%",
            padding: theme.spacing(1),
        },
        [theme.breakpoints.down("xs")]: {
            width: "100%",
            paddingBottom: theme.spacing(2),
        },
    },
    profileSummary: {
        padding: theme.spacing(2),
        minHeight: "300px",
    },
    profileDetails: {
        textAlign: "center",
        color: theme.palette.text.secondary,
    },
    sectiondivider: {
        margin: theme.spacing(1),
    },
}));

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography color="secondary" >{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
        display: 'flex',
        justifyContent: "left",
        //backgroundColor: "#e6e6e6",
        [theme.breakpoints.down('sm')]: {
            padding: "0.5rem",
        }
    }
}))(MuiDialogContent);

export default function ChangePasswordDialog(props) {

    const classes = useStyles();
    const container = useRef(null);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [state, setState] = useState({
        currentPassword: "",
        newPassword: "",
        confirmPassword: "",
    });
    const [pwdNotMatch, setpwdNotMatch] = useState(false);
    const [showError, setShowError] = useState(false);
    const [severity, setSeverity] = useState("");
    const [message, setMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const handleClose = () => {
        setShowError(false);
        setSeverity("")
        setMessage("");
    }

    const {
        errors,
        handleInputChange,
        values,
        setValues
    } = useRegistrationForm(state, changePasswordValidationSchema);

    useEffect(() => {
        setValues(state);
    }, [props.open])

    const testedResult = zxcvbn(values.newPassword);
    const createPasswordLabel = (result) => {
        switch (result.score) {
            case 0:
                return "Weak";
            case 1:
                return "Weak";
            case 2:
                return "Fair";
            case 3:
                return "Good";
            case 4:
                return "Strong";
            default:
                return "Weak";
        }
    };

    const [popoverOpen, setPopoverOpen] = useState(false);


    const handleOpen = () => setPopoverOpen(true);
    const handleClosePopOver = () => setPopoverOpen(false);


    const DialogActions = withStyles((theme) => ({
        root: {
            margin: 0,
            padding: theme.spacing(1),
        },
    }))(MuiDialogActions);


    return (
        <Dialog onClose={props.handleClose}
            fullScreen={fullScreen}
            fullWidth
            maxWidth="xs" aria-labelledby="customized-dialog-title" open={props.open}>
            <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                <Typography color="secondary">
                    Change Password
                  </Typography>
            </DialogTitle>
            <DialogContent>
                <Grid item className={classes.gridItem}>
                    <TextField
                        type="password"
                        value={values.newPassword}
                        id="coachPassword"
                        name="newPassword"
                        variant="outlined"
                        size="small"
                        label="New Password"
                        placeholder="Max 50 Character"
                        onFocus={handleOpen}
                        onBlur={handleClosePopOver}
                        onChange={handleInputChange}
                        inputProps={{ maxLength: 50 }}
                        error={errors && errors.password && errors.password.error}
                        fullWidth
                    ></TextField>
                    {/* <Popover placement="bottom-start" isOpen={popoverOpen} target="coachPassword">
                        <PopoverBody>
                            <div className={classes.sectionPopover}>
                                <Card style={{ backgroundColor: '#7986cb' }} className={classes.popoverInnerSection}>
                                    <Typography className={classes.passwordRule}>Password should be minimum 8 and maximum 50 characters.</Typography>
                                    <Typography className={classes.passwordRule}>Password should contain at least one uppercase , one lowercase, one digit.</Typography>
                                    <Typography className={classes.passwordRule}>Password should not contain space(s).</Typography>
                                </Card>
                            </div>
                        </PopoverBody>

                    </Popover> */}

                    <div className="password-strength-meter">
                        <progress
                            value={testedResult.score}
                            className={`password-strength-meter-progress strength-${createPasswordLabel(
                                testedResult
                            )}`}
                            max="4"
                        />
                    </div>

                    <div className={classes.sectiondivider}></div>
                    <TextField
                        type="password"
                        id="coachConfirmPassword"
                        value={values.confirmPassword}
                        variant="outlined"
                        size="small"
                        name="confirmPassword"
                        label="Confirm Password"
                        onChange={handleInputChange}
                        placeholder="Max 50 Character"
                        inputProps={{ maxLength: 50 }}
                        fullWidth
                        error={
                            errors &&
                            errors.confirmPassword &&
                            errors.confirmPassword.error
                        }
                    ></TextField>

                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.handleClose} color="secondary">
                    Cancel
                </Button>
                <Button onClick={() => props.onSubmit(values, props.data.userName)} color='primary'>
                    Submit </Button>
            </DialogActions>
            <Loader isLoading={isLoading} />

            <SnackbarMsg showError={showError} onClose={props.handleClose} severity={severity} msg={message} />

        </Dialog >
    );
}
