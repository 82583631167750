import React, { useState, useContext, useEffect } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';


const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    notesHeader: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(1.5),
    },
    dialogHeight: {
        padding: theme.spacing(2, 0) + '!important',
    },
    ErrorName: {
        color: 'red',
    },
    nameContainer: {
        margin: '15px 15px 0 15px',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    formLabel: {
        color: theme.palette.primary.main,
        fontWeight: 500,
    },
    userLabel: {
        paddingLeft: '10px',
    },
    addButton: {
        position: 'relative',
        top: theme.spacing(-4),
        paddingRight: theme.spacing(1)
    },
    progress: {
        marginLeft: '50%',

    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
}));

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
        backgroundColor: theme.palette.primary.main,
        color: '#fff'
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography className="font500" variant="subtitle1" noWrap>{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        // padding: '25px 16px',
        padding: '0px 0px',
        width: '500px',
        [theme.breakpoints.down(281)]: {
            width: '218px',
        },
        [theme.breakpoints.between(281, 322)]: {
            width: '258px',
        },
        [theme.breakpoints.between(321, 556)]: {
            width: '275px'
        },
    },
}))(MuiDialogContent);



const AssistantList = (props) => {
    const classes = useStyles();
   

    const handleClose = () => {
        props.handleCloseClick();
    };

    

    
    

    return (
        <div>
            <Dialog maxWidth={false} onClose={handleClose} aria-labelledby="customized-dialog-title" open={props.open}>
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                    {props.title}
                </DialogTitle>
                <DialogContent dividers className={classes.dialogHeight}>
                    <Container>
                        <Grid container alignItems='center' spacing={2}>
                            <Grid item xs={12} sm={12}>
                            {props.data && props.data.length > 0 &&
                            props.data.map((val, ind) => {
                            return (
                              <Typography>{val.name + ' ' + val.lastName}</Typography>
                            )
                          })}
                             </Grid></Grid>   
                            
                    </Container>
                </DialogContent>               

            </Dialog>
        </div >
    );
}

export default AssistantList;