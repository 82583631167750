
import moment from "moment";

//... Required Field Validation
export const required = (value) => {
    //... Returns true when the value is empty.
    return /^\s*$/.test(value) || /null/.test(value) || (value === undefined);
}

export const validate = (value, rules) => {
    if (!rules) {
        return { isValid: true, message: null };
    }

    let isValid = true;
    let validationResult = { isValid: true, message: null };


    //... Required Field Validation
    if (rules.Required && isValid) {
        isValid = !required(value);

        validationResult = {
            isValid: isValid,
            message: (isValid) ? null : 'Field is required.'
        };

        console.log("validationResult", validationResult);
    }


    return validationResult;
}



