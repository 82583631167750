import AppBar from "@material-ui/core/AppBar";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import AppConstants from "../../common/AppConstants";
import ApprovedProfiles from "../../sharedComponents/customIcons/ApprovedProfiles";
import CardAccountDetailsOutline from "../../sharedComponents/customIcons/CardAccountDetailsOutline";
import OnHoldAprovals from "../../sharedComponents/customIcons/OnHoldAprovals";
import PendingApproval from "../../sharedComponents/customIcons/PendingApproval";
import RecentlyAdded from "../../sharedComponents/customIcons/RecentlyAdded";
import RecentlyUpdated from "../../sharedComponents/customIcons/RecentlyUpdated";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
    },
    tab: {
        fontSize: theme.typography.pxToRem(12),
    },
    hidetab: {
        display: 'none'
    },
    tabsColor: {
        '& button': {
            color: '#ffffff !important',
        },
    }
}));


const AdminCoachesAppBar = (props) => {
    const classes = useStyles();
    const [showTab, setShowTab] = useState(props.showUserPanel)
    useEffect(() => {
        setShowTab(props.showUserPanel);
    }, [props.showUserPanel])
    return (
        <AppBar position="static" color="primary" elevation={1}>
            <Tabs
                value={props.tabIndex}
                onChange={props.handleChangeAdmin}
                scrollButtons="auto"
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                aria-label="profile tabs"
                className={classes.tabsColor}
            >
                <Tab
                    className={classes.tab}
                    icon={<CardAccountDetailsOutline />}
                    label={
                        !props.isXsDown() && AppConstants.DASHBOARD_SECTION_TITLE.AllProfiles
                    }
                    {...props.a11yProps(0)}
                />
                <Tab
                    className={classes.tab}
                    icon={<RecentlyUpdated />}
                    label={
                        !props.isXsDown() &&
                        AppConstants.DASHBOARD_SECTION_TITLE.RecentlyUpdatedProfiles
                    }
                    {...props.a11yProps(1)}
                />
                <Tab
                    className={classes.tab}
                    icon={<RecentlyAdded />}
                    label={
                        !props.isXsDown() &&
                        AppConstants.DASHBOARD_SECTION_TITLE.RecentlyAddedProfiles
                    }
                    {...props.a11yProps(2)}
                />
                <Tab
                    className={classes.tab}
                    icon={<PendingApproval />}
                    label={
                        !props.isXsDown() &&
                        AppConstants.DASHBOARD_SECTION_TITLE.Pending
                    }
                    {...props.a11yProps(3)}
                />
                <Tab
                    className={classes.tab}
                    icon={<ApprovedProfiles />}
                    label={
                        !props.isXsDown() &&
                        AppConstants.DASHBOARD_SECTION_TITLE.Approved
                    }
                    {...props.a11yProps(4)}
                />
                <Tab
                    className={classes.tab}
                    icon={<OnHoldAprovals />}
                    label={
                        !props.isXsDown() &&
                        AppConstants.DASHBOARD_SECTION_TITLE.Rejected
                    }
                    {...props.a11yProps(5)}
                />
                <Tab
                    className={showTab ? classes.tab : classes.hidetab}
                    icon={<ApprovedProfiles />}
                    label={
                        !props.isXsDown() &&
                        AppConstants.DASHBOARD_SECTION_TITLE.GroupProfile
                    }
                    {...props.a11yProps(5)}
                />
            </Tabs>
        </AppBar>
    )
}

export default AdminCoachesAppBar;