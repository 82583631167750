import { configService } from '../services';
import AppConstants from './AppConstants';

export default class ContentReviewMessage {

    static getVideoReviewMessage = async (reviewStatus) => {
        const configKey = AppConstants.VideoReviewStatusToConfigKeyMapper[reviewStatus];
        const reviewedReason = await configService.getConfigValue(configKey)
            .then(res => {
                return res.value
            }).catch(err => {
                console.log("Error getting review status message", err);
            });
        return reviewedReason;
    };

    static getPostReviewMessage = async (reviewStatus) => {
        const configKey = AppConstants.PostReviewStatusToConfigKeyMapper[reviewStatus];
        const reviewedReason = await configService.getConfigValue(configKey)
            .then(res => {
                return res.value
            }).catch(err => {
                console.log("Error getting review status message", err);
            });
        return reviewedReason;
    };

}
