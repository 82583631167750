import React, { useState, useEffect, useContext } from "react";
import { withRouter } from "react-router-dom";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import Badge from "@material-ui/core/Badge";
import Typography from "@material-ui/core/Typography";
import VideoPlayIcon from "../../images/video-play-button.png";
import LinearProgress from "@material-ui/core/LinearProgress";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import Skeleton from "@material-ui/lab/Skeleton";
import RouteConstants from "../../common/RouteConstants";
import APIHelper from "../../common/ApiHelper";
import APIURLConstants from "../../common/ApiURLConstants";
import JWPlayer from "../JWPlayer/JWPlayer";
import HeadShot from "../profilePhoto/HeadShot";
import CustomizedDialogs from "../../sharedComponents/dialog/jwPlayerDialog";
import HelpIcon from '@material-ui/icons/Help';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { Tooltip } from 'react-tippy';
import { Role } from '../../helper';
import helper from "../../common/Helper";
import { userService, coachProfileService } from '../../services';
import AppConstants from "../../common/AppConstants";
import TitleWithTooltip from "../../sharedComponents/TitleWithTooltip";
import IconButton from "@material-ui/core/IconButton";
import GetAppIcon from "@material-ui/icons/GetApp";
import parse from 'html-react-parser';
import SendMailDialog from "../../sharedComponents/dialog/SendMailDialog";
import SnackbarMsg from "../../sharedComponents/Snackbar/Snackbar";
import DOMPurify from 'dompurify';
import { AppDataContext } from '../../common/AppContext';
import useTelemetry from "../../utils/useTelemetry";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  avatar: {
    width: theme.spacing(15),
    height: theme.spacing(15),
    margin: '0 auto 8px',
    border: '2px solid #96aacb',
  },
  PlayIcon: {
    position: 'absolute',
    right: '18%',
    bottom: '18%',
    transform: 'scale(1) translate(50%, 50%)',
    transformOrigin: '100% 100%',
    zIndex: '2',
    height: theme.spacing(6.5),
    width: theme.spacing(6.5),
    cursor: 'pointer',
  },
  divider: {
    marginTop: theme.spacing(2),
    width: "100%",
  },
  profileCompletenessTitle: {
    display: "flex",
    flexDirection: "row",
    // width: "100%",
    marginTop: theme.spacing(2),
    alignItems: "center",
  },
  profileCompletenessBar: {
    // width: "100%",
    marginTop: theme.spacing(1),
    '& > div': {
      backgroundColor: '#898989',
      '& > div': {
        backgroundColor: '#d9d9d9',
      },
    },
  },
  sections: {
    display: "flex",
    flexDirection: "column",
    // width: "100%",
    marginTop: theme.spacing(3),
    alignItems: "start",
  },
  linkIcon: {
    fontSize: "0.75rem",
  },
  linkText: {
    textAlign: "left",
    color: '#ffffff',
  },
  editProfile: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    '& button': {
      marginBottom: theme.spacing(1),
    },
  },
  editProfile1: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  skeleton: {
    width: "100%",
  },
  profileVideo: {
    width: '130px',
    height: '80px',
    cursor: 'pointer'
  },
  downloadAction: {
    marginLeft: "auto",
    color: "#000",
  },
}));

const ProfileSummary = (props) => {
  const classes = useStyles();

  const [image, setImage] = useState(null);
  const [videoURL, setVideoURL] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const userRole = userService.getUserRole();
  const userName = userService.getUsername();
  const [openSendMail, setopenSendMail] = useState(false);
  const [mailId, setMailId] = useState('');
  const [showError, setShowError] = useState(false);
  const [severity, setSeverity] = useState("");
  const [message, setMessage] = useState("");
  const { trackCustomEvents } = useTelemetry();
  const {
    canISeeCoachingTree
  } = useContext(AppDataContext);
  const [showTree, setShowTree] = useState(false);
  const [primaryVideoData, setPrimaryVideoData] = useState([]);
  const [openPrimaryVideo, setOpenPrimaryVideo] = useState(false);

  const handleClick = () => {
    props.history.push({
      pathname: props.isViewMode
        ? RouteConstants.COACH_PROFILE_EDIT
        : RouteConstants.COACH_PROFILE_VIEW,
      state: undefined,
    });
    trackCustomEvents("ClickEvent", {event: props.isViewMode ? "Edit Profile" : "View Profile"})
  };

  function donwloadPDF(event) {
    event.preventDefault();
    var xhr = coachProfileService.downloadCoachPDF();
    xhr.onload = function (e) {
      if (this.status == 200) {
        let fileName = props.fullName.firstName + "_" + props.fullName.lastName;
        fileName = fileName.replace(/([^a-z0-9 _]+)/gi, '');

        var blob = new Blob([this.response], { type: "application/pdf" });
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName + ".pdf";
        link.click();
      }
    };
    xhr.send();
    trackCustomEvents("ClickEvent", { event: "Download PDF" });
  }

  function handleViewCoachingTree(event) {
    console.log(props.userName)
    props.history.push({
      pathname: RouteConstants.COACHES_TREE + '/' + props.userName,
      state: { isCoachProfile: props.isCoachProfile }
    });
    trackCustomEvents("ClickEvent", { event: "View Coaching Tree" });
  }

  const handleBackToDashboard = () => {
    if (userRole === Role.Admin) {
      props.history.push({
        pathname: RouteConstants.COACH_MANAGEMENT
      });
      trackCustomEvents("ClickEvent", { event: "Back To Dashboard" });
    } else if (userRole === Role.FrontOffice || userRole === Role.FrontOfficePlusCoach) {
      props.history.push({
        pathname: RouteConstants.DASHBOARD
      });
      trackCustomEvents("ClickEvent", { event: "Back To Dashboard" });

    } else if (userRole === Role.Coach) {
      props.history.push({
        pathname: RouteConstants.COACH_DASHBOARD
      });
      trackCustomEvents("ClickEvent", { event: "Back To Dashboard" });

    }
  }

  const handleClickOpen = () => {
    if (props.jwtTokenDetails && props.jwtTokenDetails.JWPlayerKey && props.jwtTokenDetails.JWPlayerSource) {
      setOpenDialog(true);
    }
  }
  useEffect(() => {
    if (props.userName) {
      let profileurl = APIURLConstants.GET_PROFILE_VIDEO + props.userName;
      setVideoURL(profileurl);

      if (props.profileVideos) {
        // Find the primary video object
        let primaryVideoObject = props.profileVideos.find(video => video.isPrimaryVideo);

        if (primaryVideoObject && primaryVideoObject.videoURL) {
          let service = userService.getProfileVideosSignedUrl;
          service(primaryVideoObject.videoURL)
            .then(videoUrl => {

              primaryVideoObject.videoURL = videoUrl;

              // Set primary video object in state
              setPrimaryVideoData(primaryVideoObject);
            })
            .catch(error => {
              // Handle any errors
              console.error('Error fetching video URL:', error);
            });
        }
      }
    }
  }, [props.userName, props.profileVideos]);

  const [open, setOpen] = React.useState(false);
  const handleTooltipClose = () => {
    setOpen(false);
  };
  const handleTooltipOpen = () => {
    setOpen(true);
  };
  const formattedDate = (date) => {
    return helper.IfDateIsNULL(date) ? '' : helper.FormatDateToLocalTimeZone(date);
  }
  const togalSendMAilDialog = () => {
    setopenSendMail(!openSendMail);
    trackCustomEvents("ClickEvent", { event: "Share Profile" });
  }

  const SendMail = () => {
    let regex = /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/;
    let validEmail = regex.test(mailId)
    if (!validEmail) {
      setSeverity("error");
      setMessage("Enter valid email")
      setShowError(true);
      return;
    }
    else {
      coachProfileService.sendProfile(props.userName, mailId)
        .then(data => {
          setMailId("");
          setopenSendMail(!openSendMail);
          setSeverity("success");
          setMessage("Email Sent")
          setShowError(true);
        })
    }
  }

  const handleClose = () => {
    setShowError(false);
    setSeverity("")
    setMessage("");
  }

  const GameVideoBadge = withStyles((theme) => ({

  }))(Badge);

  const handleHeadShotClick = () => {

    if (props.jwtTokenDetails && props.jwtTokenDetails.JWPlayerKey && props.jwtTokenDetails.JWPlayerSource) {

      setOpenPrimaryVideo(true);
    }
    trackCustomEvents("ClickEvent", { event:"Headshot Video"})
  };

  return (
    <div className={classes.root}>
      {/* {image && */}
      {/* <Avatar alt="profile photo" src={image} className={classes.avatar} /> */}
      
        <div className="headShot">
        <div >
      
         <GameVideoBadge>
            {primaryVideoData && primaryVideoData.videoURL && (<Avatar variant="square" className={classes.PlayIcon} src={VideoPlayIcon} onClick={handleHeadShotClick}></Avatar>)}
            <HeadShot
              userName={props.userName}
              coachName={`${props.fullName && props.fullName.firstName ? props.fullName.firstName : ''} ${props.fullName && props.fullName.lastName ? props.fullName.lastName : ''}`} className={classes.avatar} profilePhoto={props.profilePhoto} />
          </GameVideoBadge>

        </div>


        <Typography
          variant="h6"
          style={{ textAlign: "center", color: '#ffffff', }}
        >{`${props.fullName && props.fullName.firstName ? props.fullName.firstName : ''} ${props.fullName && props.fullName.lastName ? props.fullName.lastName : ''} ${props.fullName && props.fullName.suffix ? props.fullName.suffix : ''}`}</Typography>
        <Typography variant="caption"></Typography>
      </div>
      {/* <Typography variant="caption">{email ? email : ''}</Typography> */}
      {/* <Divider className={classes.divider}></Divider> */}
      <div className="profile-summary">
        {(userRole == Role.FrontOfficePlusCoach || userRole == Role.Coach) &&
          props.profileCompleteness && <>
            <div className={classes.profileCompletenessTitle}>
              <Typography variant="subtitle2">
                Profile Completeness
              </Typography>
              <Typography
                variant="body2"
                style={{ marginLeft: "auto" }}
              >
                {props.profileCompleteness}% &nbsp;
              </Typography>
              {props.remainingSection && props.remainingSection.length > 0 &&
                <ClickAwayListener onClickAway={handleTooltipClose}>
                  <Tooltip
                    PopperProps={{
                      disablePortal: true,
                    }}
                    onClose={handleTooltipClose}
                    open={open}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    title={`Please complete following section(s) to complete your profile: ${props.remainingSection.join(", ")}`}>
                    <HelpIcon onClick={handleTooltipOpen} fontSize="small" style={{ width: '20px', heigh: '20px' }} />
                  </Tooltip>
                </ClickAwayListener>}

            </div>

            <div className={classes.profileCompletenessBar}>
              <LinearProgress
                variant="determinate"
                value={props.profileCompleteness}
              ></LinearProgress>
            </div>
          </>
        }
        {props.isViewMode && (
          <>
            <div className={classes.sections}>
              <Typography variant="subtitle2">About Me</Typography>
              {props.roleAndCoachingPhilosophy && props.roleAndCoachingPhilosophy.description ? (
                <Typography variant="body2" >
                  {parse(DOMPurify.sanitize(props.roleAndCoachingPhilosophy.description))}
                  {/* {(props.roleAndCoachingPhilosophy.description)} */}
                </Typography>
              ) : (
                <div className={classes.skeleton}>
                  <Skeleton />
                  <Skeleton />
                  <Skeleton width="60%" />
                </div>
              )}
            </div>
            {/* {props.profileVideo && props.profileVideo.videoURL &&
              <div className={classes.sections}>
                <Typography variant="subtitle2">Uploaded Video</Typography>
             
                <img src={playIcon} className={classes.profileVideo} onClick={handleClickOpen} />
              </div>
            } */}
            {/* <div className={classes.sections}> */}
              {/* <TitleWithTooltip
                variant="subtitle2"
                title="Video Links"
                tooltipTitle="Video_Link_Tooltip"               
                class={classes.question}
              /> */}
              {/* <Typography variant="subtitle2">Video Links</Typography>
              {props.coachingVideos && props.coachingVideos.externalLinks && props.coachingVideos.externalLinks.length > 0 &&
                props.coachingVideos.externalLinks.map((link, index) => {
                  if (link && link.label && link.label != '') {
                    return (
                      <Link
                        key={index}
                        variant="body2"
                        className={classes.linkText}
                        href={link.url}
                        target="blank"
                      >
                        {link.label} &nbsp;
                        <OpenInNewIcon className={classes.linkIcon} />
                      </Link>
                    )
                  }
                  else {
                    return null;
                  }
                })
              } */}
            {/* </div> */}

            <div className={classes.sections}>
              <Typography variant="subtitle2">Articles/Media Links</Typography>
              {props.mediaAndArticle && props.mediaAndArticle.externalLinks && props.mediaAndArticle.externalLinks.length > 0 &&
                props.mediaAndArticle.externalLinks.map((link, index) => {
                  if (link && link.label && link.label != '') {
                    return (
                      <Link
                        key={index}
                        variant="body2"
                        className={classes.linkText}
                        href={link.url}
                        target="blank"
                      >
                        {link.label} &nbsp;
                        <OpenInNewIcon className={classes.linkIcon} />
                      </Link>
                    )
                  }
                  else {
                    return null;
                  }
                })
              }
            </div>
            {props && props.profileViewCount > 0 &&
              <div className={classes.sections}>
                <Typography variant="subtitle2">Profile Views In The Last 7 Days</Typography>
                <Typography variant="body2">
                  {props ? props.profileViewCount + " Times" : '0' + " Times"}</Typography>
              </div>
            }
            <div className={classes.sections}>
              <Typography variant="subtitle2">Last Updated On </Typography>
              <Typography variant="body2">
                {props ? formattedDate(props.lastModifiedAt) : ''}</Typography>
            </div>
            {/* <Divider className={classes.divider}></Divider> */}
          </>
        )}
      </div>
      {(userRole == Role.FrontOfficePlusCoach || userRole == Role.Coach) &&
        (props.isCoachProfile || props.userName === userName) && (
          <div className={classes.editProfile}>
            <Button
              variant="contained"
              color="secondary"
              size="small"
              onClick={handleClick}
            >
              {props.isViewMode ? "Edit Profile" : "View Profile"}
            </Button>
            {props.isViewMode ?
              <><Button
                variant="contained"
                color="secondary"
                size="small"
                onClick={donwloadPDF}
              >
                Download Profile
              </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  size="small"
                  onClick={togalSendMAilDialog}
                >
                  Share Profile
                </Button>
                {/* <Tooltip title="SendMail" style={{ cursor: "pointer" }}>
                  <ShareIcon onClick={togalSendMAilDialog} color="primary" />
                </Tooltip> */}
              </> : ""}
          </div>
        )
      }

      {props.isViewMode && (userRole === Role.Admin || ((userRole === Role.FrontOffice || userRole === Role.FrontOfficePlusCoach) && canISeeCoachingTree != undefined && canISeeCoachingTree == 'true') || (userRole === Role.Coach && canISeeCoachingTree != undefined && canISeeCoachingTree === 'true')) &&
        <div className={classes.editProfile1}>
          {/* {props.isViewMode ? */}
          <Button
            variant="contained"
            color="secondary"
            size="small"
            onClick={handleViewCoachingTree}
          >
            View Coaching Tree
          </Button>
        </div>
      }
      {/* : ""} */}

      {((props.showBackButton || props.isViewMode) && (userRole === Role.Admin || userRole === Role.FrontOffice || (userRole === Role.Coach && !props.isCoachProfile && canISeeCoachingTree != undefined && canISeeCoachingTree === 'true') || (userRole === Role.FrontOfficePlusCoach))) &&
        <div className={classes.editProfile1}>
          <Button
            variant="contained"
            color="secondary"
            size="small"
            onClick={handleBackToDashboard}
          >
            Back To Dashboard
          </Button>
        </div>
      }
      {props.jwtTokenDetails && props.jwtTokenDetails.JWPlayerKey && props.profileVideo && props.profileVideo.videoURL &&
        <CustomizedDialogs open={openDialog} title="View Video"
          data={{
            jwtTokenDetails: props.jwtTokenDetails,
            videoURL: videoURL,
            userName: props.userName,
            title: AppConstants.VIDEO_PLAYER.PROFILE_TITLE
          }} onClose={() => setOpenDialog(false)} />
      }
      {props.jwtTokenDetails && props.jwtTokenDetails.JWPlayerKey && primaryVideoData && primaryVideoData.videoURL &&
        <CustomizedDialogs open={openPrimaryVideo} title="View Video"
          data={{
            jwtTokenDetails: props.jwtTokenDetails,
            videoURL: primaryVideoData.videoURL,
            userName: props.userName,
            playExternalVideo: true,
            title: primaryVideoData.title
          }} onClose={() => setOpenPrimaryVideo(false)} />
      }
      <SendMailDialog open={openSendMail}
        onClose={togalSendMAilDialog}
        onSendMail={SendMail}
        mailId={mailId}
        setMailId={setMailId}
      />
      <SnackbarMsg showError={showError} onClose={handleClose} severity={severity} msg={message} />

    </div>
  );
};

export default withRouter(ProfileSummary);
