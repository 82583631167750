import React, {
  Fragment,
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
  useContext,
  useRef,
} from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import ToolTip from "@material-ui/core/Tooltip";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import DeleteIcon from "@material-ui/icons/Delete";

import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Helper from "../common/Helper";

import { AppDataContext } from "../common/AppContext";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  grid: {
    padding: theme.spacing(1),
    alignSelf: "center",
    display: "flex",
  },
  gridIcons: {
    display: 'flex',
    '& button': {
      [theme.breakpoints.down("xs")]: {
        padding: theme.spacing(.75),
      },
    },
  },
  error: {
    borderColor: theme.palette.error.dark,
  },
  btnDel: {
    color: theme.palette.secondary.main,
  },
  teamListImage: {
    marginRight: theme.spacing(1),
  },
}));
//let allData=[];
const ChampionshipDetailsList = (props) => {
  let preData = [];
  const [formDataCollection, setFormData] = useState([getEmptyItem()]);

  const { getTeamsByLeague, positionTitle } = useContext(AppDataContext);

  useEffect(() => {
    preData = props.data;
    let detail = formDataFromProp();
    if (props.isInValid) {
      detail = ValidateData(detail);
    }
    if (preData.length == 0) {
      detail.push(getEmptyItem());
    }
    setFormData(detail);
  }, [props.data,props.isInValid]);

  const getTeamData = () => {
    let list = getTeamsByLeague(props.league);
    return list;
  };

  const ValidateData = (detail) => {
    detail.forEach(lastData => {
      if (lastData.teamName.value === "" || lastData.season.value === "") {
        lastData.teamName.value === ""
          ? (lastData.teamName.hasError = true)
          : (lastData.teamName.hasError = false);
        lastData.season.value === ""
          ? (lastData.season.hasError = true)
          : (lastData.season.hasError = false);
      }
    })
    return detail;
  }

  function formDataFromProp() {
    return props.data.map((item) => {
      return {
        id: item.id,
        league: props.league,
        teamName: {
          value: item.team.teamId,
          label: item.team.teamName,
          logoURL: item.team.logoURL ? item.team.logoURL : "",
          validationMessage: "",
          hasError: item.team && item.team.teamName !== "" ? null : true,
        },
        season: { value: item.season, validationMessage: "", hasError: null },
      };
    });
  }
  function getEmptyItem() {
    let randomId = Helper.GetRandomId();
    return {
      id: randomId,
      league: props.league,
      teamName: { value: "", label: "", logoURL: "", validationMessage: "", hasError: null },
      season: { value: "", validationMessage: "", hasError: null },
    };
  }
  function handleInputChange(e, hasError) {
    let dataCollection = [...formDataCollection];
    let index = dataCollection.findIndex((x) => x.id === e.itemId);
    dataCollection[index] = e.formData;

    setFormData(dataCollection);
    if (!hasError || hasError == undefined)
      notifyParentAboutDataChange(dataCollection);
  }
  const notifyParentAboutDataChange = (newData) => {
    let DataCollection = newData;
    let data = [];

    for (let i = 0; i < DataCollection.length; i++) {
      let formData = DataCollection[i];

      data.push({
        id: formData.id,
        team: {
          teamId: formData.teamName.value,
          teamName: formData.teamName.label,
          logoURL: formData.teamName.logoURL,
        },
        season: formData.season.value,
      });
    }
    preData = data;
    props.onDataChange(data, props.league);
  };
  function addItem() {
    //... Add a new item to the formDataCollection array.
    let dataCollection = [...formDataCollection];
    let lastData = dataCollection[dataCollection.length - 1];
    if (lastData.teamName.value === "" || lastData.season.value === "") {
      lastData.teamName.value === ""
        ? (lastData.teamName.hasError = true)
        : (lastData.teamName.hasError = false);
      lastData.season.value === ""
        ? (lastData.season.hasError = true)
        : (lastData.season.hasError = false);
      handleInputChange({ itemId: lastData.id, formData: lastData }, true);
    } else {
      lastData.teamName.hasError = false;
      lastData.season.hasError = false;
      dataCollection.push(getEmptyItem());
      setFormData(dataCollection);
    }
  }
  function removeItem(itemId) {
    //... Remove an item from formDataCollection array.
    let DataCollection = [...formDataCollection];
    let index = DataCollection.findIndex((x) => x.id === itemId);
    DataCollection.splice(index, 1);
    setFormData(DataCollection);
    notifyParentAboutDataChange(DataCollection);
  }


  return (
    <Grid container>
      {formDataCollection.map((item, index) => {
        return (
          <ExperienceDetail
            key={index}
            addItem={addItem}
            itemRow={item}
            onChange={handleInputChange}
            showAddOption={index === formDataCollection.length - 1}
            removeItem={removeItem}
            teamData={getTeamData()}
          />
        );
      })}
    </Grid>
  );
};

function ExperienceDetail(props) {
  const classes = useStyles();
  const teamSelected = getTeamValue(props.itemRow.teamName.label);

  function handleAddClick() {
    props.addItem();
  }
  function handleInputChange(value, name, newValue) {
    let formData = props.itemRow;
    formData[name].value = value;
    formData[name].hasError = false;
    if (name === "teamName") {
      formData[name].logoURL = newValue && newValue.logoURL ? newValue.logoURL : "";
      formData[name].label = newValue && newValue.teamName ? newValue.teamName : "";
    }
    //... Call Parent
    props.onChange({ itemId: props.itemRow.id, formData: formData });
  }

  function handelOnKeyUp(e) {
    if (e.target.value != "") {
      var teamExist = {};
      teamExist = props.teamData.find(x => x.teamName === e.target.value)
      if (teamExist === undefined) {
        let formData = props.itemRow;
        formData["teamName"].value = "New";
        formData["teamName"].hasError = false;
        formData["teamName"].label = e.target.value;
        //... Call Parent
        props.onChange({ itemId: props.itemRow.id, formData: formData });

      }
    }
  }

  function getTeamValue(val) {
    let item = [...props.teamData];
    let sele = item.find((x) => x.teamId == val);
    return sele || { teamId: "New", teamName: val };
  }
  const renderTeamItem = (option) => {
    return (
      <React.Fragment>
        {option.logoURL ? (
          <img
            className={classes.teamListImage}
            src={option.logoURL}
            height={35}
            width={35}
          ></img>
        ) : (
            <div
              className={classes.teamListImage}
              style={{ height: "35px", width: "35px" }}
            >
              &nbsp;
            </div>
          )}
        {option.teamName}
      </React.Fragment>
    );
  };
  return (
    <Fragment>
      <Grid item md={6} xs={12} className={classes.grid}>
        <Autocomplete
          onClose={handelOnKeyUp}
          value={teamSelected}
          id={`team_${props.itemRow.id}`}
          size="small"
          options={props.teamData}
          getOptionSelected={(option, { multiple, teamSelected }) => {
            if (!multiple) {
              if (teamSelected != null) {
                return option.id === teamSelected.value;
              }
              return false;
            }

            return false;
          }}
          renderOption={(option) => renderTeamItem(option)}
          getOptionLabel={(option) => option.teamName}
          onChange={(event, newValue) => {
            let val = newValue !== null ? newValue.teamId : "";
            handleInputChange(val, "teamName", newValue);
          }}
          style={{ width: "100%" }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              error={props.itemRow.teamName.hasError}
              label={<Typography variant="body2">Team</Typography>}
            />
          )}
        />
      </Grid>
      <Grid item md={4} xs={8} className={classes.grid}>
        <TextField
          id={`season_${props.itemRow.id}`}
          error={props.itemRow.season.hasError}
          variant="outlined"
          value={props.itemRow.season.value}
          onChange={(e) => handleInputChange(e.target.value, "season")}
          label={<Typography variant="body2">Season</Typography>}
          size="small"
          fullWidth
        />
      </Grid>
      <Grid item md={1} xs={4} className={classes.gridIcons}>
        <ToolTip title="Remove">
          <IconButton onClick={(e) => props.removeItem(props.itemRow.id)}>
            <DeleteIcon className={classes.btnDel} >Delete</DeleteIcon>
          </IconButton>
        </ToolTip>
        {props.showAddOption &&
          <ToolTip title="Add more">
            <IconButton onClick={handleAddClick}>
              <AddCircleIcon color="primary" >Add</AddCircleIcon>
            </IconButton>
          </ToolTip>
        }
      </Grid>
    </Fragment>
  );
}
ChampionshipDetailsList.defaultProps = {
  data: [],
};

export default ChampionshipDetailsList;
