import React, { useState, useEffect, useRef, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Typography,
  Paper,
  Divider,
  Grid,
  Box,
  CardHeader,
} from "@material-ui/core/";
import ShowArrayList from "./subComponent/ShowArrayList";
import ViewProfileHeading from '../../../sharedComponents/ViewProfileHeading';
import NotAvailable from "../../../sharedComponents/NotAvailable/NotAvailable";
import ShowHTML from "../../../sharedComponents/HTMLEditor/ShowHTML";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  card: {
    border: "1px solid gray",
    backgroundColor: "#F3F3F3",
    header: {
      backgroundColor: "#E5ECFC",
      color: "#2D4D89",
    },
  },
  header: {
    backgroundColor: "#E5ECFC",
    color: "#2D4D89",
    fontSize: "12px",
    fontWeight: "bold",
    padding: "3px 0 3px 10px",
  },
  subHeading: {
    backgroundColor: "#63C5DA",
    color: "#1520A6",
    padding: "5px 25px",
  },
  section: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
    textAlign: "left",
  },
  section2: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
    borderLeft: "5px solid lightgray",
    paddingLeft: "20px",
    // width: "50%",
  },
  grid: {
    padding: theme.spacing(1),
    alignSelf: "center",
  },
  gridInRow: {
    display: "flex",
    justifyContent: "space-between",
    paddingLeft: "25px",
  },
  notablePlayers: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    width: "95%",
    paddingLeft: "25px",
    lineHeight: "2",
    color: "black",
  },
  teamName: {    
    fontSize: "12px",
    color: "black",
  },
  teams: {
    fontSize: '0.9rem',
    fontWeight:'500',
},
}));

const AdditionalExperienceView = (props) => {
  const classes = useStyles();
  const [eventDetail, setEventDetail] = useState(
    props.data && props.data.eventDetail ? props.data.eventDetail : []
  );
  const [charitableExperience, setCharitableExperience] = useState(props.data && props.data.charitableExperience ? props.data.charitableExperience : "");

  useEffect(() => {
    setEventDetail(props.data && props.data.eventDetail ? props.data.eventDetail : []);
    setCharitableExperience(props.data && props.data.charitableExperience ? props.data.charitableExperience : "");
  }, [props.data])

  return (
    <div className={classes.root}>
      {/* /////////////////////////////// Events and Expereince //////////////////////////// */}

      <div className={classes.section}>
        <ViewProfileHeading title="NBA/International Events & Experiences" />
        <Grid container>
          <Grid item md={12} xs={12} className={classes.gridInRow}>
            <Grid container>
              {eventDetail.length == 0 &&
                <NotAvailable />
              }
              {eventDetail.map((data, index) => {
                return (
                  <Grid item md={4} sm={6} xs={12}>
                    <div key={index} className={classes.section2}>
                      <Typography color="primary" className={classes.teams}>
                        {data.events && data.events.Name ? data.events.Name : ""}
                      </Typography>
                      <Typography className={classes.teamName}>
                        {data.experience}
                      </Typography>
                    </div>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </Grid>
      </div>
      <Divider></Divider>
      <ShowHTML heading="Charitable Experience" data={charitableExperience} />
      {/* <ShowArrayList
        heading="Charitable Experience"
        data={[charitableExperience]}
      /> */}
    </div>
  );
};

export default AdditionalExperienceView;
