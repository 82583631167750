import React, { useEffect, useState } from "react";
import { Box, useTheme } from "@material-ui/core";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import SocialPostList from "./SocialPostList";

const SocialCommunityTabPanel = (props) => {
    const theme = useTheme();
    const [tabIndex, setTabIndex] = useState(props.tabIndex);

    // Update tabIndex
    useEffect(() => {
        setTabIndex(props.tabIndex);
    }, [props]);

    return (
        <SwipeableViews
            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
            index={tabIndex}
            onChangeIndex={props.handleTabChange}>
            <TabPanel value={tabIndex} index={0} dir={theme.direction === "rtl" ? "x-reverse" : "x"}>
                <SocialPostList tabIndex={0} fallBackMessage="No available posts found." searchedText={props.searchedText} setIsLoading={props.setIsLoading} />
            </TabPanel>
            <TabPanel value={tabIndex} index={1} dir={theme.direction === "rtl" ? "x-reverse" : "x"}>
                <SocialPostList tabIndex={1} fallBackMessage="No posts available for review." searchedText={props.searchedText} setIsLoading={props.setIsLoading} />
            </TabPanel>
            <TabPanel value={tabIndex} index={2} dir={theme.direction === "rtl" ? "x-reverse" : "x"}>
                <SocialPostList tabIndex={2} fallBackMessage="No posts returned." searchedText={props.searchedText} setIsLoading={props.setIsLoading} />
            </TabPanel>
        </SwipeableViews>
    );
};

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <Box
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`scroll-tabpanel-${index}`}
            aria-labelledby={`scroll-tab-${index}`}
            {...other}
        >
            {value === index && <div p={3}>{children}</div>}
        </Box>
    );
};

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

export default SocialCommunityTabPanel;
