import React, { useState, useEffect, useContext } from "react";
import APIHelper from "./ApiHelper";
import APIURLConstants from "./ApiURLConstants";
import { jwplayerService } from "../services";
import FrontOfficeService from "../services/frontOffice.service";
import Helper from "./Helper";
import { FilterContext } from "./FilterContext";
/*
    This context provider contains data to fuel the multiple questions and allow us to maintain it at one place, 
    hence achieving flexibility in placing the questions anywhere in/and across multiple sections.
*/

//... This JSON should always match the structure of JSON retrieved from the API.
// let initialProfileList = {
//   profileList: [],
// };

export const DashBoardContext = React.createContext([]);

export const DashBoardProvider = (props) => {

  const { currentPageContext } = useContext(FilterContext);

  const [jwtTokenDetails, setJwtTokenDetails] = useState({});
  const [profileList, setProfileList] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(currentPageContext);
  const [randomKey, setRandomKey] = useState(Helper.GetRandomId());
  const [error, setError] = useState("");
  const [processing, setProcessing] = useState(true);
  const [rules, setRules] = useState();

  useEffect(() => {
    getJWToken();
    //handleServiceCall();
  }, []);

  const getJWToken = () => {
    jwplayerService.getToken().then((token) => {
      setJwtTokenDetails(token);
    });
  };

  const getLeagueNameForService = (arr) => {
    let newArr = [];
    if (arr.length > 0) {
      arr.forEach(element => {
        if (element == "NBA" || element == "nba") {
          newArr.push( "nba");
        }
        else if (element == "WNBA" || element == "wnba") {
          newArr.push("wnba");
        }
        else if (element == "G League" || element == "g league") {
          newArr.push("nbagleague");
        }
        else if (element == "Other" || element == "other") {
          newArr.push("other");
        }
      });
    }
    return newArr;
  }


  const handleServiceCall = (
    searchedText = "",
    selectedTitle = [],
    selectedLeague = [],
    selectedChampionship = [],
    selectedSkill = [],
    selectSort = "Name",
    sortOrder = "asc",
    filterView = "0",
    selectedNBATeams = [],
    selectedWNBATeams = [],
    selectedNBAGLeagueTeams = [],
    pageSize = 12,
    pageNo = currentPage,
    filterCriteria = "And",
    selectedCurrentlyWithATeam = [],
    selectedFormerCoach = [],
    internationalWorked = false,
    expType = [],
    playingExperience = []
  ) => {
    //setIsLoading(true);
    let postData = {
      searchText: searchedText,
      title: selectedTitle,
      league: selectedLeague,
      championship: selectedChampionship,
      skills: selectedSkill,
      sortBy: selectSort,
      sortOrder: sortOrder,
      filterP: filterView,
      nbaTeams: selectedNBATeams,
      wnbaTeams: selectedWNBATeams,
      nbaGLeagueTeams: selectedNBAGLeagueTeams,
      filterCriteria: filterCriteria,
      pageSize: pageSize,
      pageNo: pageNo,
      currentlyWithATeam: getLeagueNameForService(selectedCurrentlyWithATeam),
      formerCoaches: getLeagueNameForService(selectedFormerCoach),
      internationalWorked: internationalWorked,
      expType: expType,
      randomRecordNumber: parseInt(localStorage.getItem('randomRecordsNumber')),
      playingExperience:  getLeagueNameForService(playingExperience)

    };
    setRules(postData);
    FrontOfficeService.getSearchedCoachProfileList(postData)
      .then((data) => {
        setError("");
        // setCurrentPage(pageNo);
        //Highlight profile if search by first and last name
        if (data.data && data.data.length > 0) {
          if (postData.searchText && postData.searchText != '') {
            data.data = data.data.map(p => ({
              ...p,
              needToHighlight: p.personalDetails && p.personalDetails.fullName && ((p.personalDetails.fullName.firstName + p.personalDetails.fullName.lastName).toLowerCase() == postData.searchText.replace(/\s+/g, '').toLowerCase())
            }))
          }
        }
        setProcessing(false);
        setProfileList(data.data);
        setTotalRecords(data.totalResults);
      })
      .catch((err) => {
        setError(err);
        setProcessing(false);
        //handle error
        //setIsLoading(false);
      });
  };
  //... This provides values to the context consumers.
  const providerValue = {
    profileList: profileList,
    totalRecords,
    setTotalRecords,
    jwtTokenDetails,
    error: error,
    isProcessing: processing,
    currentPageForUpdate: currentPage,
    randomKey,
    updateBookmarkedUser: (data, currentPage) => {
      handleUpdateBookmarked(data, currentPage);
    },
    handleServiceCall,
    updateStatusProfile: (data, currentPage) => {
      updateStatusProfile(data, currentPage);
    },
    rules
  };
  const handleUpdateBookmarked = (data, currentPage) => {
    let profile = profileList.filter((profile) => {
      if (profile.userName === data.profileUserName) {
        if (data.bookmark) {
          let newData = [];
          profile.isBookMarked = true;
          profile.bookmarkedBy !== null
            ? profile.bookmarkedBy.push(data.bookmarkedBy)
            : (profile.bookmarkedBy = Array.from([data.bookmarkedBy]));
        } else {
          profile.isBookMarked = false;
          profile.bookmarkedBy.splice(
            profile.bookmarkedBy.indexOf(data.bookmarkedBy),
            1
          );
        }
      }
      return profile;
    });
    setProfileList(profile);
    setCurrentPage(currentPage);
    setRandomKey(Helper.GetRandomId());
  };
  const updateStatusProfile = (data, currentPage) => {
    //profileUserName, reason, status
    let profiles = profileList.filter((profile) => {
      if (profile.userName === data.profileUserName) {
        profile.deniedReason = data.reason;
        profile.status = data.status;
      }
      return profile;
    })
    setProfileList(profiles);
    setCurrentPage(currentPage);
    setRandomKey(Helper.GetRandomId());
  }

  return (
    <DashBoardContext.Provider value={providerValue}>
      {props.children}
    </DashBoardContext.Provider>
  );
};

export const DashBoardConsumer = DashBoardContext.Consumer;

export default DashBoardContext;
