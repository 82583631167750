
import { Button, Card, CardContent, ClickAwayListener, IconButton, ListItem, ListItemText, MenuItem, MenuList, Paper, Popper, Typography, useTheme } from "@material-ui/core/";
import { CommentOutlined, EditOutlined, ReplyOutlined, ThumbUpAlt, ThumbUpAltOutlined, DeleteOutline } from "@material-ui/icons";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Alert from "@material-ui/lab/Alert";
import React, { useContext, useRef, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import AppConstants from "../../common/AppConstants";
import Helper from "../../common/Helper";
import RouteConstants from "../../common/RouteConstants";
import { Role, convertTextToLinks } from "../../helper";
import { userService } from "../../services";
import socialPostService from "../../services/socialPost.service";
import ReviewContentDialog from "../../sharedComponents/dialog/ReviewContentDialog";
import HeadShot from "../../sharedComponents/profilePhoto/HeadShot";
import CommentSection from "./CommentSection";
import ReadMore from "./ReadMore";
import ReplySection from "./ReplySection";
import CofirmationDialogBox from "../ManageData/cofirmationDialogBox";
import useTelemetry from '../../utils/useTelemetry';
import AppDataContext from "../../common/AppContext";

const postActionTabs = {
    REPLY: 'REPLY_TAB',
    COMMENT: 'COMMENT_TAB'
};

const PostCard = (props) => {

    const myRef = useRef(null)
    const anchorRef = useRef(null);
    const theme = useTheme();
    let history = useHistory();
    const currentUserName = userService.getUsername();
    const { item, index, postList, setPostList } = props;
    const { trackCustomEvents } = useTelemetry();
    const { postConfigValues } = useContext(AppDataContext);

    const [commentList, setCommentList] = useState([]);
    const [selectedPostId, setSelectedPostId] = useState(null);
    const [currentTab, setTabPressed] = useState(null);
    const [postReturnDialog, setPostReturnDialog] = useState(false);
    const [postApproveDialog, setPostApproveDialog] = useState(false);

    const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
    const [postIdToDelete, setPostIdToDelete] = useState(null);

    const [open, setOpen] = useState(false);
    const [totalComments, setTotalComments] = useState(item.totalComments || 0);

    const handleToggle = () => {
        setOpen(!open);
    };

    const handleTabIndex = (tabIndex) => {       // function created to change the tab from reply -> comment
        setTabPressed(tabIndex);
    }

    const likePost = (postId) => {
        try {
            //... Update like count locally.
            const postItem = postList?.find(item => item.id === postId);
            if (!postItem) return;

            //... Check if user already liked the post.
            const userLiked = postItem.likedByUsers?.includes(currentUserName);

            const updatePostList = (likedByUsers, totalLikes) => postList.map(obj =>
                obj.id === postId ? { ...obj, likedByUsers, totalLikes } : obj
            );

            const updatedArray = userLiked ?
                updatePostList(postItem.likedByUsers.filter(item => item !== currentUserName), postItem.totalLikes - 1) :
                updatePostList([...(postItem.likedByUsers || []), currentUserName], postItem.totalLikes + 1);

            setPostList(updatedArray);

            socialPostService.likePost(postId)
                .then((res) => {
                    console.log("Response", res);
                })
                .catch(error => {
                    console.log("Error", error);
                });
            trackCustomEvents("EditEvent", { action: "Like Post" });
        } catch (error) {
            console.log("Error", error);
        }

    }

    const ifUserLiked = (postId) => {
        const postItem = postList?.find(item => item.id === postId);
        return postItem?.likedByUsers?.includes(currentUserName);
    };

    const formattedDate = (date) => {
        return Helper.getFormattedDate(date);
    }

    const onPressReplyButton = (postId) => {
        setSelectedPostId(postId);
        //... 1 used for Reply button and 2 for comments
        setTabPressed(postActionTabs.REPLY);
        trackCustomEvents("EditEvent", { action: "Reply" });
    }

    const onPressCommentsButton = (postId) => {
        setSelectedPostId(postId);
        //... GET all comments for the selected post
        getAllComments(postId);
        //... 1 used for Reply button and 2 for comments
        setTabPressed(postActionTabs.COMMENT);
        trackCustomEvents("EditEvent", { action: "Comment" });
    }

    const getAllComments = (postId) => {
        socialPostService.getAllComments(postId).then((res) => {
            setCommentList(res);
        }).catch(error => {
            console.log("Error", error);
        })
    };

    const onPressEditPost = (item) => {
        history.push({
            pathname: RouteConstants.CREATE_SOCIAL_POST,
            state: {
                postDetails: item,
                editPost: true,
            }
        });
        trackCustomEvents("EditEvent", { action: "Edit Post" });
    }

    const onPressDeletePost = (postId) => {
        setOpenConfirmationDialog(true);
        setPostIdToDelete(postId);
        trackCustomEvents("EditEvent", { action: "Delete Post" });
    }

    const onDeletePost = () => {

        if (postIdToDelete) {
            socialPostService.deletePost(postIdToDelete).then((res) => {
                const indexToRemove = postList.findIndex(post => post.id === postIdToDelete);
                if (indexToRemove !== -1) {
                    // remove the post from the postList
                    const updatedPostList = [...postList];
                    updatedPostList.splice(indexToRemove, 1);
                    setPostList(updatedPostList);
                }
            })
                .catch(error => {
                    console.log("Error", error);
                });
        }

    }

    const onDeleteComment = (commentId) => {

        socialPostService.deleteComment(commentId).then((res) => {

            const indexToRemove = commentList.findIndex(comment => comment.id === commentId);

            if (indexToRemove !== -1) {
                // remove the post from the postList
                const updatedPostList = [...commentList];
                updatedPostList.splice(indexToRemove, 1);
                setCommentList(updatedPostList);
                setTotalComments(updatedPostList ? updatedPostList.length : 0)
            }
        })
            .catch(error => {
                console.log("Error", error);
            });
    }

    
    const handleConfirmation = (flag => {
        setOpenConfirmationDialog(false);
        if (flag == 'Y') {
            onDeletePost();
        }
        else {
            setPostIdToDelete(null);
        }
    })

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };


    const onSaveComment = (commentId, updatedComment) => {
        // Find the index of the comment in the commentList
        const commentIndex = commentList.findIndex(comment => comment.id === commentId);

        if (commentIndex !== -1) {
            // Create a new array with the updated comment
            const updatedCommentList = [...commentList];
            updatedCommentList[commentIndex] = {
                ...updatedCommentList[commentIndex],
                comment: updatedComment
            };

            // Update the commentList state with the updated array
            setCommentList(updatedCommentList);
        }
    };

    return (
        <div style={{ marginBottom: 8 }}>
            <Card >
                <CardContent>
                    <ListItem key={index}>
                        <ListItemText
                            primary={
                                <div>
                                    <Typography variant="h5" color="primary" style={{ fontWeight: "bold" }}>
                                        {item.title}
                                    </Typography>

                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>

                                        <div style={{ marginTop: 10, marginBottom: 16, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                            <div style={{ marginRight: 16 }}>
                                                <HeadShot
                                                    coachName={item.createdByUserName}
                                                    className={{
                                                        width: 15,
                                                        height: 15,
                                                        border: "2px solid #1D428A",
                                                    }}
                                                    profilePhoto={""}
                                                />
                                            </div>
                                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                <Typography variant="subtitle1" style={{ color: theme.palette.text.secondary }}>
                                                    {item.createdByName}
                                                </Typography>
                                                <Typography variant="subtitle1" style={{ color: theme.palette.text.secondary, }}>
                                                    {"\u00A0|\u00A0"}
                                                </Typography>
                                                <Typography variant="subtitle1" style={{ color: theme.palette.text.secondary }}>
                                                    {formattedDate(item.createdAt)}
                                                </Typography>



                                                {item.reviewedStatus !== AppConstants.REVIEW_STATUS.APPROVED &&
                                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                        <Typography variant="subtitle1" style={{ color: theme.palette.text.secondary, }}>
                                                            {"\u00A0|\u00A0"}
                                                        </Typography>
                                                        <Typography variant="subtitle1" style={{
                                                            color: item.reviewedStatus === AppConstants.REVIEW_STATUS.RETURNED ? theme.palette.secondary.dark : theme.palette.warning.dark, fontWeight: "bold"
                                                        }}>
                                                            {/* Regex is used to add space between camel case  words*/}
                                                            {item?.reviewedStatus?.replace(/([A-Z])/g, ' $1').trim()}
                                                        </Typography>
                                                    </div>


                                                }
                                            </div>
                                        </div>
                                        {userService.getUserRole() == Role.Admin && item.reviewedStatus !== AppConstants.REVIEW_STATUS.APPROVED &&
                                            < div style={{ marginBottom: 16, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                <div>
                                                    <IconButton
                                                        ref={anchorRef}
                                                        aria-label="more"
                                                        aria-controls={open ? 'menu-list-grow' : undefined}
                                                        aria-haspopup="true"
                                                        onClick={handleToggle}
                                                    >
                                                        <MoreVertIcon style={{ color: "#484e5b" }} />
                                                    </IconButton>
                                                    <Popper style={{ zIndex: 99 }} placement='bottom-end' open={open} anchorEl={anchorRef.current} role={undefined} transition='fade' disablePortal>
                                                        {({ TransitionProps, placement }) => (
                                                            <Paper>
                                                                <ClickAwayListener onClickAway={handleClose}>
                                                                    <MenuList onClick={handleClose}>
                                                                        <MenuItem onClick={() => {
                                                                            setPostApproveDialog(true)
                                                                        }}>
                                                                            Approve
                                                                        </MenuItem>
                                                                        {item?.reviewedStatus != AppConstants.REVIEW_STATUS.RETURNED &&
                                                                            <MenuItem onClick={() => {
                                                                                setPostReturnDialog(true)
                                                                            }}>
                                                                                Return
                                                                            </MenuItem>
                                                                        }
                                                                    </MenuList>
                                                                </ClickAwayListener>
                                                            </Paper>
                                                        )}
                                                    </Popper>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>

                            }
                            secondary={
                                <div>
                                    <div>

                                        <ReadMore>
                                            <div dangerouslySetInnerHTML={{ __html: convertTextToLinks(item.description) }} />
                                        </ReadMore>
                                    </div>
                                    <div style={{ marginTop: 16 }}>
                                        {item?.reviewedStatus == AppConstants.REVIEW_STATUS.APPROVED &&
                                            <>
                                                <Button variant="text"
                                                    startIcon={<ReplyOutlined style={{ color: theme.palette.text.hint }} />}
                                                    onClick={() => onPressReplyButton(item.id)}
                                                    style={{ color: theme.palette.text.hint }}
                                                >
                                                    Comment
                                                </Button>
                                                <Button variant="text"
                                                    startIcon={<CommentOutlined style={{ color: theme.palette.text.hint }} />}
                                                    style={{ marginLeft: 16, color: theme.palette.text.hint }}
                                                    onClick={() => {
                                                        onPressCommentsButton(item.id)
                                                    }}
                                                >
                                                    {totalComments}
                                                </Button>
                                                <Button variant="text"
                                                    startIcon={ifUserLiked(item.id) ?
                                                        <ThumbUpAlt color="secondary" /> : <ThumbUpAltOutlined style={{ color: theme.palette.text.hint }} />}
                                                    style={{ marginLeft: 16, color: theme.palette.text.hint }}
                                                    onClick={() => { likePost(item.id) }}
                                                >
                                                    {item.totalLikes}
                                                </Button>
                                            </>
                                        }
                                        {item.createdByUserName === userService.getUsername() &&
                                            item.canIUpdateOrDelete &&
                                            <Button variant="text"
                                                startIcon={<EditOutlined style={{ color: theme.palette.text.hint }} />}
                                                style={{ marginLeft: 16, color: theme.palette.text.hint }}
                                                onClick={() => { onPressEditPost(item) }}
                                            >
                                                Edit Post
                                            </Button>
                                        }

                                        {(userService.getUserRole() == Role.Admin || item.createdByUserName === userService.getUsername() &&
                                            item.canIUpdateOrDelete) &&
                                            <Button variant="text"
                                                startIcon={<DeleteOutline style={{ color: theme.palette.text.hint }} />}
                                                style={{ marginLeft: 16, color: theme.palette.text.hint }}
                                                onClick={() => { onPressDeletePost(item.id) }}
                                            >
                                                Delete Post
                                            </Button>
                                        }

                                        {item.createdByUserName === userService.getUsername() && item.reviewedStatus && item?.reviewedStatus != AppConstants.REVIEW_STATUS.APPROVED &&
                                            <Alert severity={item?.reviewedStatus === AppConstants.REVIEW_STATUS.PENDING ? "warning" : "error"}>{item.reviewedStatus + " - " + item.reviewedReason}</Alert>
                                        }
                                    </div>
                                    {item.id === selectedPostId &&
                                        <div ref={myRef} >
                                            {currentTab === postActionTabs.REPLY &&
                                                <ReplySection postId={selectedPostId}
                                                    handleTabIndex={handleTabIndex}
                                                    setCommentList={(res) => {
                                                        setCommentList(res);
                                                        const totalCommentsCount = res ? res.length : 0;
                                                        setTotalComments(totalCommentsCount);

                                                        // Update the totalComments count for the selected post
                                                        const updatedPostList = postList.map(post => {
                                                            if (post.id === selectedPostId) {
                                                                return {
                                                                    ...post,
                                                                    totalComments: res ? totalCommentsCount : 0
                                                                };
                                                            }
                                                            return post;
                                                        });

                                                        // Set the state with the updated post list
                                                        setPostList(updatedPostList);
                                                    }} />
                                            }
                                            {currentTab === postActionTabs.COMMENT &&
                                                <CommentSection
                                                    commentList={commentList}
                                                    postId={selectedPostId}
                                                    onDeleteComment={(id) => onDeleteComment(id)}
                                                    onSaveComment={(id, updatedComment) => onSaveComment(id, updatedComment)}
                                                />
                                            }
                                        </div>
                                    }
                                </div>
                            }
                        />
                    </ListItem >
                </CardContent>
            </Card>
            <ReviewContentDialog
                title="Return this Post?"
                reviewMessageFunction={() => postConfigValues.returnedPostReviewMessage}
                onApproveOrReject={(note, sendMail) => {
                    props.handlePostAction(item.id, AppConstants.REVIEW_STATUS.RETURNED, note, sendMail)
                }}
                onClose={() => {
                    setPostReturnDialog(false)
                }}
                open={postReturnDialog}
                actionText="RETURN"
                showNotifyCheck={false}
            />
            <ReviewContentDialog
                title="Approve this Post?"
                reviewMessageFunction={() => postConfigValues.approvedPostReviewMessage}
                onApproveOrReject={(note, sendMail) => {
                    props.handlePostAction(item.id, AppConstants.REVIEW_STATUS.APPROVED, note, sendMail)
                }}
                onClose={() => {
                    setPostApproveDialog(false)
                }}
                open={postApproveDialog}
                actionText="APPROVE"
                showNotifyCheck={true}
            />

            <CofirmationDialogBox
                message="Are you sure you want to delete this entry?"
                open={openConfirmationDialog}
                onClose={() => handleConfirmation("N")}
                onYesClick={() => handleConfirmation("Y")}
                onNoClick={() => handleConfirmation("N")}
            ></CofirmationDialogBox>
        </div >
    );
};

export default PostCard;
