import AppConstants from "./AppConstants";
import { userService } from "../services";
import Moment from "@date-io/moment";

export const FilterProfileList = (profileList, filterOptions, callback) => {
  let { moment } = new Moment();
  if (
    filterOptions.filterView === AppConstants.DASHBOARD_SECTION_ID.Bookmarked
  ) {
    profileList = profileList.filter((profile) => {
      return (
        profile.bookmarkedBy &&
        profile.bookmarkedBy.indexOf(userService.getUsername()) >= 0
      );
    });
  } else if (filterOptions.filterView === AppConstants.DASHBOARD_SECTION_ID.RecentlyUpdated) {
    //Resubmitted
    profileList = profileList.filter(profile => profile.status === AppConstants.PROFILE_STATUS.RESUBMITTED)
  }
  else if (filterOptions.filterView === AppConstants.DASHBOARD_SECTION_ID.RecentlyAdded) {

    profileList.sort((a, b) => {
      return moment(b.CreatedAt) - moment(a.CreatedAt);
    });
    profileList = profileList.slice(0, 10);

    profileList.sort((a, b) => {
      return moment(a.CreatedAt) - moment(b.CreatedAt);
    });

  } else if (filterOptions.filterView === AppConstants.DASHBOARD_SECTION_ID.Approved) {
    //Approved
    profileList = profileList.filter(profile => profile.status === AppConstants.PROFILE_STATUS.APPROVED)
  }
  else if (filterOptions.filterView === AppConstants.DASHBOARD_SECTION_ID.Pending) {
    //Pending Approvals
    profileList = profileList.filter(profile => profile.status === AppConstants.PROFILE_STATUS.SUBMITTED ||
      profile.status === AppConstants.PROFILE_STATUS.RESUBMITTED)
  } else if (filterOptions.filterView === AppConstants.DASHBOARD_SECTION_ID.Rejected) {
    // Rejected List
    profileList = profileList.filter(profile => profile.status === AppConstants.PROFILE_STATUS.DENIED)
  }
  let filteredList = profileList;
  if (
    filterOptions.selectedSkill.length !== 0 ||
    filterOptions.selectedTitle.length !== 0 ||
    filterOptions.selectedTeams.length !== 0 ||
    filterOptions.selectedNBATeams.length !== 0 ||
    filterOptions.selectedWNBATeams.length !== 0 ||
    filterOptions.selectedNBAGLeagueTeams.length !== 0 ||
    filterOptions.selectedLeague.length !== 0 ||
    filterOptions.selectedChampionship.length !== 0 ||
    filterOptions.searchedText !== "" ||
    filterOptions.selectedCurrentlyWithATeam.length != 0
  ) {
    if (filterOptions.filterCriteria == "OR") {
      filteredList = profileList.filter((profile) => {
        //filter by search text
        if (filterOptions.searchedText && filterOptions.searchedText !== "") {
          let objName = {};
          if (profile.personalDetails && profile.personalDetails.fullName) {
            objName = { Name: profile.personalDetails.fullName.firstName + " " + profile.personalDetails.fullName.lastName }
          }
          let profileWithName = { ...profile, ...objName };
          let jsonString = JSON.stringify(profileWithName);
          if (jsonString.toLowerCase().includes(filterOptions.searchedText.trim().toLowerCase())) {
            return profile;
          }
        }
        //filter by selected Skills
        if (
          profile.skillSet &&
          profile.skillSet.coachingSkill &&
          filterOptions.selectedSkill.length > 0
        ) {
          if (
            filterOptions.selectedSkill.some(
              (skill) => profile.skillSet.coachingSkill.indexOf(skill) >= 0
            )
          ) {
            return profile;
          }
        }
        //selectedLeague
        if (
          profile.coachingAndOtherJobExperience &&
          (profile.coachingAndOtherJobExperience.headCoachExperience ||
            profile.coachingAndOtherJobExperience.assistantCoachExperience) &&
          filterOptions.selectedLeague.length > 0
        ) {
          let currentExp = [];
          if (
            profile.coachingAndOtherJobExperience.headCoachExperience &&
            profile.coachingAndOtherJobExperience.headCoachExperience.length > 0
          ) {
            profile.coachingAndOtherJobExperience.headCoachExperience.filter(
              (headCoachExp) => {
                if (headCoachExp.experience.length > 0)
                  headCoachExp.experience.filter((exp) => {
                    //exp.toDate === "Present" &&
                    currentExp.push(headCoachExp.league.trim());
                  });
              }
            );
          }
          if (
            profile.coachingAndOtherJobExperience.assistantCoachExperience &&
            profile.coachingAndOtherJobExperience.assistantCoachExperience
              .length > 0
          ) {
            profile.coachingAndOtherJobExperience.assistantCoachExperience.filter(
              (assistCoachExp) => {
                if (assistCoachExp.experience.length > 0)
                  assistCoachExp.experience.filter((exp) => {
                    //exp.toDate === "Present" &&
                    currentExp.push(assistCoachExp.league.trim());
                  });
              }
            );
          }
          currentExp = unique(currentExp);
          if (
            filterOptions.selectedLeague.some(
              (league) => currentExp.indexOf(league) >= 0
            )
          ) {
            return profile;
          }
        }
        //SelectedCurrentlyNotWithA Team
        if (filterOptions.selectedCurrentlyWithATeam.length > 0 && (profile.coachingAndOtherJobExperience == null || !profile.coachingAndOtherJobExperience)) {
          if (filterOptions.selectedCurrentlyWithATeam.includes(AppConstants.CURRENTLY_NOT_WORKING))
            return profile;
        }
        if (
          profile.coachingAndOtherJobExperience &&
          (profile.coachingAndOtherJobExperience.headCoachExperience ||
            profile.coachingAndOtherJobExperience.assistantCoachExperience) &&
          filterOptions.selectedCurrentlyWithATeam.length > 0
        ) {
          let currentWorking = false;
          if (
            (profile.coachingAndOtherJobExperience.headCoachExperience &&
              profile.coachingAndOtherJobExperience.headCoachExperience.length == 0) && (profile.coachingAndOtherJobExperience.assistantCoachExperience &&
                profile.coachingAndOtherJobExperience.assistantCoachExperience.length == 0)
          ) {
            currentWorking = false;
          }

          if (
            profile.coachingAndOtherJobExperience.headCoachExperience &&
            profile.coachingAndOtherJobExperience.headCoachExperience.length > 0
          ) {
            profile.coachingAndOtherJobExperience.headCoachExperience.filter(
              (headCoachExp) => {
                if (headCoachExp.experience.length > 0)
                  headCoachExp.experience.filter((exp) => {
                    if (exp.toDate === "Present")
                      currentWorking = true;
                  });
              }
            );
          }
          if (
            profile.coachingAndOtherJobExperience.assistantCoachExperience &&
            profile.coachingAndOtherJobExperience.assistantCoachExperience
              .length > 0
          ) {
            profile.coachingAndOtherJobExperience.assistantCoachExperience.filter(
              (assistCoachExp) => {
                if (assistCoachExp.experience.length > 0)
                  assistCoachExp.experience.filter((exp) => {
                    if (exp.toDate === "Present")
                      currentWorking = true;
                  });
              }
            );
          }

          if (filterOptions.selectedCurrentlyWithATeam.includes(AppConstants.CURRENTLY_NOT_WORKING) && !currentWorking)
            return profile;
          else if (filterOptions.selectedCurrentlyWithATeam.includes(AppConstants.CURRENTLY_WORKING) && currentWorking)
            return profile;

        }


        //selectedChampionship
        if (
          ((profile.coachingAndOtherJobExperience &&
            profile.coachingAndOtherJobExperience.championship) ||
            (profile.playingExperience &&
              profile.playingExperience.championship)) &&
          filterOptions.selectedChampionship.length > 0
        ) {
          let coachChampionship = [];
          let playerChampionship = [];
          coachChampionship =
            profile.coachingAndOtherJobExperience &&
            profile.coachingAndOtherJobExperience.championship &&
            profile.coachingAndOtherJobExperience.championship.length > 0 &&
            profile.coachingAndOtherJobExperience.championship.map((champ) =>
              champ.league.trim()
            );
          playerChampionship =
            profile.playingExperience &&
            profile.playingExperience.championship &&
            profile.playingExperience.championship.length > 0 &&
            profile.playingExperience.championship.map((champ) =>
              champ.league.trim()
            );

          //const unique = (array) => [...new Set(array)];

          const concated = concat(coachChampionship, playerChampionship);
          const championships = unique(concated);
          //let championships = [...coachChampionship, ...playerChampionship];
          if (
            filterOptions.selectedChampionship.some(
              (champ) => championships.indexOf(champ) >= 0
            )
          ) {
            return profile;
          }
        }
        //selectedTeams
        let teamList = [...filterOptions.selectedTeams, ...filterOptions.selectedNBATeams, ...filterOptions.selectedWNBATeams, ...filterOptions.selectedNBAGLeagueTeams]
        if (profile.coachingAndOtherJobExperience &&
          (profile.coachingAndOtherJobExperience.headCoachExperience ||
            profile.coachingAndOtherJobExperience.assistantCoachExperience) &&
          teamList.length > 0
        ) {
          let list = profile.coachingAndOtherJobExperience.assistantCoachExperience.filter(
            (assexp) => {
              if (
                assexp.experience &&
                assexp.experience.length > 0 &&
                assexp.experience.some(
                  (exp) =>
                    teamList.indexOf(exp.team.teamName) >= 0
                )
              ) {
                return profile;
              }
            }
          );
          let list2 = [];

          list2 = profile.coachingAndOtherJobExperience.headCoachExperience.filter(
            (headExp) => {
              if (
                headExp.experience &&
                headExp.experience.length > 0 &&
                headExp.experience.some(
                  (exp) =>
                    teamList.indexOf(exp.team.teamName) >= 0
                )
              ) {
                return profile;
              }
            }
          );


          if ((list && list.length > 0) || (list2 && list2.length > 0)) {
            let list3 = [...list, ...list2];
            const unique = (value, index, self) => {
              return self.indexOf(value) === index
            }
            const FilnalList = list3.filter(unique)
            return FilnalList;
          }

        }
        //selectedTitle
        if (
          profile.coachingAndOtherJobExperience &&
          (profile.coachingAndOtherJobExperience.skillExperience ||
            profile.coachingAndOtherJobExperience.headCoachExperience) &&
          filterOptions.selectedTitle.length > 0
        ) {
          let list = profile.coachingAndOtherJobExperience.skillExperience.filter(
            (skillExp) => {
              if (
                skillExp.experience &&
                skillExp.experience.length > 0 &&
                skillExp.experience.some(
                  (exp) =>
                    filterOptions.selectedTitle.indexOf(exp.title.Name) >= 0
                )
              ) {
                return profile;
              }
            }
          );
          
          let list11 = profile.coachingAndOtherJobExperience.assistantCoachExperience.filter(
            (assistantExp) => {
              if (
                assistantExp.experience &&
                assistantExp.experience.length > 0 &&
                assistantExp.experience.some(
                  (exp) =>
                  exp.title && exp.title.Name && filterOptions.selectedTitle.indexOf(exp.title.Name) >= 0
                )
              ) {
                return profile;
              }
            }
          );

          let list2 = [];
          if (filterOptions.selectedTitle.includes("Head Coach")) {
            list2 = profile.coachingAndOtherJobExperience.headCoachExperience.filter(
              (headExp) => {
                if (
                  headExp.experience &&
                  headExp.experience.length > 0
                ) {
                  return profile;
                }
              }
            );
          }

          if (filterOptions.selectedTitle.includes("Assistant Coach")) {
            list2 = profile.coachingAndOtherJobExperience.assistantCoachExperience.filter(
              (assExp) => {
                if (
                  assExp.experience &&
                  assExp.experience.length > 0
                ) {
                  return profile;
                }
              }
            );
          }

          if ((list && list.length > 0) || (list2 && list2.length > 0) || (list11 && list11.length > 0)) {
            let list3 = [...list, ...list2, ...list11];
            const unique = (value, index, self) => {
              return self.indexOf(value) === index
            }
            const FilnalList = list3.filter(unique)
            return FilnalList;
          }
        }
      });
    }
    else {
      filteredList = profileList.filter((profile) => {
        let returnProfile = [];
        let obj = {};
        //filter by search text
        if (filterOptions.searchedText && filterOptions.searchedText !== "") {
          obj = { option: true, result: false };
          let objName = {};
          if (profile.personalDetails && profile.personalDetails.fullName) {
            objName = { Name: profile.personalDetails.fullName.firstName + " " + profile.personalDetails.fullName.lastName }
          }
          let profileWithName = { ...profile, ...objName };
          let jsonString = JSON.stringify(profileWithName);
          if (jsonString.toLowerCase().includes(filterOptions.searchedText.toLowerCase())) {
            obj = { option: true, result: true };
          }
          returnProfile.push(obj);
        }
        //filter by selected Skills      
        if (filterOptions.selectedSkill.length > 0) {
          obj = { option: true, result: false };
          if (profile.skillSet && profile.skillSet.coachingSkill) {
            if (filterOptions.selectedSkill.some((skill) => profile.skillSet.coachingSkill.indexOf(skill) >= 0)) {
              obj = { option: true, result: true };
            }
          }
          returnProfile.push(obj);
        }
        //selectedLeague
        if (filterOptions.selectedLeague.length > 0) {
          obj = { option: true, result: false };
          if (profile.coachingAndOtherJobExperience &&
            (profile.coachingAndOtherJobExperience.headCoachExperience ||
              profile.coachingAndOtherJobExperience.assistantCoachExperience)) {

            let currentExp = [];
            if (
              profile.coachingAndOtherJobExperience.headCoachExperience &&
              profile.coachingAndOtherJobExperience.headCoachExperience.length > 0
            ) {
              profile.coachingAndOtherJobExperience.headCoachExperience.filter(
                (headCoachExp) => {
                  if (headCoachExp.experience.length > 0)
                    headCoachExp.experience.filter((exp) => {
                      //exp.toDate === "Present" &&
                      currentExp.push(headCoachExp.league.trim());
                    });
                }
              );
            }
            if (
              profile.coachingAndOtherJobExperience.assistantCoachExperience &&
              profile.coachingAndOtherJobExperience.assistantCoachExperience
                .length > 0
            ) {
              profile.coachingAndOtherJobExperience.assistantCoachExperience.filter(
                (assistCoachExp) => {
                  if (assistCoachExp.experience.length > 0)
                    assistCoachExp.experience.filter((exp) => {
                      //exp.toDate === "Present" &&
                      currentExp.push(assistCoachExp.league.trim());
                    });
                }
              );
            }
            currentExp = unique(currentExp);
            if (
              filterOptions.selectedLeague.some(
                (league) => currentExp.indexOf(league) >= 0
              )
            ) {
              obj = { option: true, result: true };
            }
          }
          returnProfile.push(obj);
        }

        //SelectedCurrentlyNotWithA Team
        if (filterOptions.selectedCurrentlyWithATeam.length > 0) {
          obj = { option: true, result: false };
          if (profile.coachingAndOtherJobExperience == null || !profile.coachingAndOtherJobExperience) {
            if (filterOptions.selectedCurrentlyWithATeam.includes(AppConstants.CURRENTLY_NOT_WORKING))
              obj = { option: true, result: true };
          }

          ////////////////////////////////////////////////////// 

          if (
            profile.coachingAndOtherJobExperience &&
            (profile.coachingAndOtherJobExperience.headCoachExperience ||
              profile.coachingAndOtherJobExperience.assistantCoachExperience)
          ) {
            let currentWorking = false;
            if (
              (profile.coachingAndOtherJobExperience.headCoachExperience &&
                profile.coachingAndOtherJobExperience.headCoachExperience.length == 0) && (profile.coachingAndOtherJobExperience.assistantCoachExperience &&
                  profile.coachingAndOtherJobExperience.assistantCoachExperience.length == 0)
            ) {
              currentWorking = false;
            }

            if (
              profile.coachingAndOtherJobExperience.headCoachExperience &&
              profile.coachingAndOtherJobExperience.headCoachExperience.length > 0
            ) {
              profile.coachingAndOtherJobExperience.headCoachExperience.filter(
                (headCoachExp) => {
                  if (headCoachExp.experience.length > 0)
                    headCoachExp.experience.filter((exp) => {
                      if (exp.toDate === "Present")
                        currentWorking = true;
                    });
                }
              );
            }
            if (
              profile.coachingAndOtherJobExperience.assistantCoachExperience &&
              profile.coachingAndOtherJobExperience.assistantCoachExperience.length > 0
            ) {
              profile.coachingAndOtherJobExperience.assistantCoachExperience.filter(
                (assistCoachExp) => {
                  if (assistCoachExp.experience.length > 0)
                    assistCoachExp.experience.filter((exp) => {
                      if (exp.toDate === "Present")
                        currentWorking = true;
                    });
                }
              );
            }

            if (filterOptions.selectedCurrentlyWithATeam.includes(AppConstants.CURRENTLY_NOT_WORKING) && !currentWorking)
              obj = { option: true, result: true };
            else if (filterOptions.selectedCurrentlyWithATeam.includes(AppConstants.CURRENTLY_WORKING) && currentWorking)
              obj = { option: true, result: true };
          }
          returnProfile.push(obj);
        }


        //selectedChampionship
        if (filterOptions.selectedChampionship.length > 0) {
          obj = { option: true, result: false };
          if (
            ((profile.coachingAndOtherJobExperience &&
              profile.coachingAndOtherJobExperience.championship) ||
              (profile.playingExperience &&
                profile.playingExperience.championship))
          ) {

            let coachChampionship = [];
            let playerChampionship = [];
            coachChampionship =
              profile.coachingAndOtherJobExperience &&
              profile.coachingAndOtherJobExperience.championship &&
              profile.coachingAndOtherJobExperience.championship.length > 0 &&
              profile.coachingAndOtherJobExperience.championship.map((champ) =>
                champ.league.trim()
              );
            playerChampionship =
              profile.playingExperience &&
              profile.playingExperience.championship &&
              profile.playingExperience.championship.length > 0 &&
              profile.playingExperience.championship.map((champ) =>
                champ.league.trim()
              );

            //const unique = (array) => [...new Set(array)];

            const concated = concat(coachChampionship, playerChampionship);
            const championships = unique(concated);
            //let championships = [...coachChampionship, ...playerChampionship];
            if (
              filterOptions.selectedChampionship.some(
                (champ) => championships.indexOf(champ) >= 0
              )
            ) {
              obj = { option: true, result: true };
            }
          }
          returnProfile.push(obj);
        }
        //selected NBA Teams  
        let teamList = [...filterOptions.selectedNBATeams]
        if (teamList.length > 0) {
          obj = { option: true, result: false };
          if (profile.coachingAndOtherJobExperience &&
            (profile.coachingAndOtherJobExperience.headCoachExperience ||
              profile.coachingAndOtherJobExperience.assistantCoachExperience)
          ) {
            let list = profile.coachingAndOtherJobExperience.assistantCoachExperience.filter(
              (assexp) => {
                if (
                  assexp.experience &&
                  assexp.experience.length > 0 &&
                  assexp.experience.some(
                    (exp) =>
                      teamList.indexOf(exp.team.teamName) >= 0
                  )
                ) {
                  return profile;
                }
              }
            );
            let list2 = [];

            list2 = profile.coachingAndOtherJobExperience.headCoachExperience.filter(
              (headExp) => {
                if (
                  headExp.experience &&
                  headExp.experience.length > 0 &&
                  headExp.experience.some(
                    (exp) =>
                      teamList.indexOf(exp.team.teamName) >= 0
                  )
                ) {
                  return profile;
                }
              }
            );


            if ((list && list.length > 0) || (list2 && list2.length > 0)) {
              let list3 = [...list, ...list2];
              const unique = (value, index, self) => {
                return self.indexOf(value) === index
              }
              const FilnalList = list3.filter(unique)
              obj = { option: true, result: true };
            }
          }
          returnProfile.push(obj);
        }

        //selected WNBA Teams  
        teamList = [...filterOptions.selectedWNBATeams]
        if (teamList.length > 0) {
          obj = { option: true, result: false };
          if (profile.coachingAndOtherJobExperience &&
            (profile.coachingAndOtherJobExperience.headCoachExperience ||
              profile.coachingAndOtherJobExperience.assistantCoachExperience)
          ) {

            let list = profile.coachingAndOtherJobExperience.assistantCoachExperience.filter(
              (assexp) => {
                if (
                  assexp.experience &&
                  assexp.experience.length > 0 &&
                  assexp.experience.some(
                    (exp) =>
                      teamList.indexOf(exp.team.teamName) >= 0
                  )
                ) {
                  return profile;
                }
              }
            );
            let list2 = [];

            list2 = profile.coachingAndOtherJobExperience.headCoachExperience.filter(
              (headExp) => {
                if (
                  headExp.experience &&
                  headExp.experience.length > 0 &&
                  headExp.experience.some(
                    (exp) =>
                      teamList.indexOf(exp.team.teamName) >= 0
                  )
                ) {
                  return profile;
                }
              }
            );


            if ((list && list.length > 0) || (list2 && list2.length > 0)) {
              let list3 = [...list, ...list2];
              const unique = (value, index, self) => {
                return self.indexOf(value) === index
              }
              const FilnalList = list3.filter(unique)
              obj = { option: true, result: true };
            }
          }
          returnProfile.push(obj);
        }

        //selected NBA G League Teams  
        teamList = [...filterOptions.selectedNBAGLeagueTeams]
        if (teamList.length > 0) {
          obj = { option: true, result: false };
          if (profile.coachingAndOtherJobExperience &&
            (profile.coachingAndOtherJobExperience.headCoachExperience ||
              profile.coachingAndOtherJobExperience.assistantCoachExperience)
          ) {

            let list = profile.coachingAndOtherJobExperience.assistantCoachExperience.filter(
              (assexp) => {
                if (
                  assexp.experience &&
                  assexp.experience.length > 0 &&
                  assexp.experience.some(
                    (exp) =>
                      teamList.indexOf(exp.team.teamName) >= 0
                  )
                ) {
                  return profile;
                }
              }
            );
            let list2 = [];

            list2 = profile.coachingAndOtherJobExperience.headCoachExperience.filter(
              (headExp) => {
                if (
                  headExp.experience &&
                  headExp.experience.length > 0 &&
                  headExp.experience.some(
                    (exp) =>
                      teamList.indexOf(exp.team.teamName) >= 0
                  )
                ) {
                  return profile;
                }
              }
            );


            if ((list && list.length > 0) || (list2 && list2.length > 0)) {
              let list3 = [...list, ...list2];
              const unique = (value, index, self) => {
                return self.indexOf(value) === index
              }
              const FilnalList = list3.filter(unique)
              obj = { option: true, result: true };
            }
          }
          returnProfile.push(obj);
        }

        //selectedTitle
        if (filterOptions.selectedTitle.length > 0) {
          obj = { option: true, result: false };
          if (
            profile.coachingAndOtherJobExperience &&
            (profile.coachingAndOtherJobExperience.skillExperience ||
              profile.coachingAndOtherJobExperience.headCoachExperience)
          ) {
            let list = profile.coachingAndOtherJobExperience.skillExperience.filter(
              (skillExp) => {
                if (
                  skillExp.experience &&
                  skillExp.experience.length > 0 &&
                  skillExp.experience.some(
                    (exp) =>
                      filterOptions.selectedTitle.indexOf(exp.title.Name) >= 0
                  )
                ) {
                  return profile;
                }
              }
            );

            let list11 = profile.coachingAndOtherJobExperience.assistantCoachExperience.filter(
              (assistantExp) => {
                if (
                  assistantExp.experience &&
                  assistantExp.experience.length > 0 &&
                  assistantExp.experience.some(
                    (exp) =>
                    exp.title && exp.title.Name && filterOptions.selectedTitle.indexOf(exp.title.Name) >= 0
                  )
                ) {
                  return profile;
                }
              }
            );

            let list2 = [];
            if (filterOptions.selectedTitle.includes("Head Coach")) {
              list2 = profile.coachingAndOtherJobExperience.headCoachExperience.filter(
                (headExp) => {
                  if (
                    headExp.experience &&
                    headExp.experience.length > 0
                  ) {
                    return profile;
                  }
                }
              );
            }

            if (filterOptions.selectedTitle.includes("Assistant Coach")) {
              list2 = profile.coachingAndOtherJobExperience.assistantCoachExperience.filter(
                (assExp) => {
                  if (
                    assExp.experience &&
                    assExp.experience.length > 0
                  ) {
                    return profile;
                  }
                }
              );
            }

            if ((list && list.length > 0) || (list2 && list2.length > 0) || (list11 && list11.length > 0)) {
              let list3 = [...list, ...list2, ...list11];
              const unique = (value, index, self) => {
                return self.indexOf(value) === index
              }
              const FilnalList = list3.filter(unique)
              obj = { option: true, result: true };
            }
          }
          returnProfile.push(obj);
        }
        let result = returnProfile.find(x => x.result == false);
        console.log("returnProfile Final", returnProfile, result)
        if (!result) { return profile; }


      });
    }
  }
  if (filterOptions.selectSort !== "") {
    //callback(filteredList);
    //if (filterOptions.selectedSort !== "" && filterOptions.orderBy !== "") {
    //sortBy Name
    if (filterOptions.selectSort.toLowerCase() === "name") {
      if (filterOptions.sortOrder.toLowerCase() === "asc") {
        filteredList.sort((a, b) => {
          return a.personalDetails.fullName.firstName.toLowerCase() >
            b.personalDetails.fullName.firstName.toLowerCase()
            ? -1
            : a.personalDetails.fullName.firstName.toLowerCase() <
              b.personalDetails.fullName.firstName.toLowerCase()
              ? 1
              : 0;
        });
        // b.personalDetails.fullName.lastName.toLowerCase() <
        //     a.personalDetails.fullName.lastName.toLowerCase()
        //     ? -1
        //     : b.personalDetails.fullName.lastName.toLowerCase() >
        //       a.personalDetails.fullName.lastName.toLowerCase()
        //     ? 1
        //     :
        //coaches = coaches.OrderBy(c => c.personalDetails.fullName.lastName).ThenBy(c => c.personalDetails.fullName.firstName).ThenByDescending(c => c.personalDetails.fullName.lastName);
      } else {
        filteredList.sort((a, b) => {
          return a.personalDetails.fullName.firstName.toLowerCase() <
            b.personalDetails.fullName.firstName.toLowerCase()
            ? -1
            : a.personalDetails.fullName.firstName.toLowerCase() >
              b.personalDetails.fullName.firstName.toLowerCase()
              ? 1
              : 0;
        });
        // a.personalDetails.fullName.lastName.toLowerCase() <
        //     b.personalDetails.fullName.lastName.toLowerCase()
        //     ? -1
        //     : a.personalDetails.fullName.lastName.toLowerCase() >
        //       b.personalDetails.fullName.lastName.toLowerCase()
        //       ? 1
        //       : //: 0;
      }

      // }
    }
    if (filterOptions.selectSort.toLowerCase() === "date") {
      if (filterOptions.sortOrder.toLowerCase() === "asc") {
        filteredList.sort((a, b) => {
          return moment(b.CreatedAt) - moment(a.CreatedAt);
        });
      } else {
        filteredList.sort((a, b) => {
          return moment(a.CreatedAt) - moment(b.CreatedAt);
        });
      }
    }
    if (filterOptions.selectSort.toLowerCase() === "team") {
      filteredList.sort((a, b) => {
        let AExp = [];
        let BExp = [];
        //Exp of a
        if (
          a.coachingAndOtherJobExperience &&
          a.coachingAndOtherJobExperience.headCoachExperience &&
          a.coachingAndOtherJobExperience.headCoachExperience.length > 0
        ) {
          a.coachingAndOtherJobExperience.headCoachExperience.filter(
            (headCoachExp) => {
              if (headCoachExp.experience.length > 0)
                headCoachExp.experience.filter((exp) => {
                  exp.toDate === "Present" && AExp.push(exp);
                });
            }
          );
        }
        if (
          a.coachingAndOtherJobExperience &&
          a.coachingAndOtherJobExperience.assistantCoachExperience &&
          a.coachingAndOtherJobExperience.assistantCoachExperience.length > 0
        ) {
          a.coachingAndOtherJobExperience.assistantCoachExperience.filter(
            (assisCoachExp) => {
              if (assisCoachExp.experience.length > 0)
                assisCoachExp.experience.filter((exp) => {
                  exp.toDate === "Present" && AExp.push(exp);
                });
            }
          );
        }
        //Exp of b
        if (
          b.coachingAndOtherJobExperience &&
          b.coachingAndOtherJobExperience.headCoachExperience &&
          b.coachingAndOtherJobExperience.headCoachExperience.length > 0
        ) {
          // BExp.concat(
          b.coachingAndOtherJobExperience.headCoachExperience.filter(
            (headCoachExp) => {
              headCoachExp.experience.filter((exp) => {
                exp.toDate === "Present" && BExp.push(exp);
              });
            }
          );
          // );
        }
        if (
          b.coachingAndOtherJobExperience &&
          b.coachingAndOtherJobExperience.assistantCoachExperience &&
          b.coachingAndOtherJobExperience.assistantCoachExperience.length > 0
        ) {
          b.coachingAndOtherJobExperience.assistantCoachExperience.map(
            (assisCoachExp) => {
              if (assisCoachExp.experience.length > 0)
                assisCoachExp.experience.map((exp) => {
                  exp.toDate === "Present" && BExp.push(exp);
                });
            }
          );
        }

        let AName = "";
        let BName = "";
        if (filterOptions.sortOrder.toLowerCase() === "asc") {
          AName = AExp.length > 0 ? AExp[0].team.teamName : "";
          BName = BExp.length > 0 ? BExp[0].team.teamName : "";
          return BName.toLowerCase() < AName.toLowerCase()
            ? -1
            : BName.toLowerCase() > AName.toLowerCase()
              ? 1
              : 0;
        } else {
          AName = AExp.length > 0 ? AExp[0].team.teamName : "z";
          BName = BExp.length > 0 ? BExp[0].team.teamName : "z";
          return AName.toLowerCase() < BName.toLowerCase()
            ? -1
            : AName.toLowerCase() > BName.toLowerCase()
              ? 1
              : 0;
        }
      });
    }
  }
  callback(filteredList);
};
const unique = (array) => [...new Set(array)];
const concat = (...arrays) => [].concat(...arrays.filter(Array.isArray));
