import React, { useEffect, useState } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Grid from "@material-ui/core/Grid";
import TextField from '@material-ui/core/TextField';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import SnackbarMsg from "../../sharedComponents/Snackbar/Snackbar";
import RouteConstants from "../../common/RouteConstants";
import Checkbox from "@material-ui/core/Checkbox";

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
    },
    grid: {
        display: "flex",
        flexGrow: 1,
        flexWrap: "wrap",
        paddingTop: theme.spacing(1),
        backgroundColor: theme.palette.background.default,
        [theme.breakpoints.up("sm")]: {
            padding: theme.spacing(1),
        },
        [theme.breakpoints.down("xs")]: {
            padding: 0,
        },
    },
    nameBox: {
        width: "100%",
    }
}));

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography color="secondary" >{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
        display: 'flex',
        justifyContent: "left",
        //backgroundColor: "#e6e6e6",
        [theme.breakpoints.down('sm')]: {
            padding: "0.5rem",
        }
    }
}))(MuiDialogContent);

export default function CreateGroupDialog(props) {
    const classes = useStyles();
    const [name, setName] = useState(props.name);
    const [description, setDescription] = useState(props.description);
    const [showAsTAB, setShowAsTAB] = useState(props.showAsTAB ? props.showAsTAB : false);
    const [errorName, setErrorName] = useState(false)
    const [showError, setShowError] = useState(false);
    const [severity, setSeverity] = useState("");
    const [message, setMessage] = useState("");
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
        setDescription(props.description)
        setName(props.name)
        setShowAsTAB(props.showAsTAB ? props.showAsTAB : false)
    }, [props]);

    const handleClose = () => {
        setName('');
        props.onClose();
    };

    function showAlert(sever, msg) {
        setShowError(true);
        setSeverity(sever)
        setMessage(msg);
    }

    const handleAlertClose = () => {
        setShowError(false);
        setSeverity("")
        setMessage("");
    }
    const handleInputChange = (e) => {
        var name = e.target.value;
        if (name == null || name.trim().length == 0)
            setErrorName(true);
        else setErrorName(false);
        setName(name);

    }

    const handleDescriptionChange = (e) => {
        var dis = e.target.value;
        if (dis == null || dis.trim().length == 0)
            setErrorName(true);
        else setErrorName(false);
        setDescription(dis);

    }
    const saveGroup = () => {
        if (name && name.trim().length > 0) {            
            props.saveGroup(name, showAsTAB, description);
            setName('');
            setDescription('');
        }
        else {
            setErrorName(true);
            // showAlert("error", "Please enter Group Name.");
        }
    }

    const DialogActions = withStyles((theme) => ({
        root: {
            margin: 0,
            padding: theme.spacing(1),
        },
    }))(MuiDialogActions);

    return (<div>
        <Dialog onClose={handleClose}
            fullScreen={fullScreen}
            fullWidth
            maxWidth="xs" aria-labelledby="customized-dialog-title" open={props.open}>
            <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                <Typography color="secondary">User Group</Typography>
            </DialogTitle>
            <DialogContent>
                <Grid container>
                    <Grid className={classes.grid}>
                        <TextField variant='outlined' placeholder="50 Character Max" value={name} className={classes.nameBox}
                            onChange={(e) => handleInputChange(e)} label="Group Name" inputProps={{ maxLength: 50 }}
                            error={errorName}
                        />
                    </Grid>
                    {props.showFrontOfficeUser &&
                        <Grid className={classes.grid}>
                            <div style={{ marginLeft: '-10px' }}>
                                <Checkbox
                                    name="chkGroup"
                                    id="showAsTab"
                                    checked={showAsTAB}
                                    onChange={e => setShowAsTAB(e.target.checked)}
                                />
                        Show To Front Office User As A Tab
                        </div>
                        </Grid>
                    }
                    <Grid className={classes.grid}>
                        <TextField variant='outlined' multiline rows="3" placeholder="150 Character Max" value={description} className={classes.nameBox}
                            onChange={(e) => handleDescriptionChange(e)} label="Description" inputProps={{ maxLength: 150 }}
                            error={errorName}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={saveGroup} color='primary'>
                    Save </Button>
            </DialogActions>
        </Dialog>
        <SnackbarMsg showError={showError} onClose={handleAlertClose} severity={severity} msg={message} />

    </div>)
}
