import APIURLConstants from "../common/ApiURLConstants";
import useAPI from '../common/useAPI';
import { useAppData } from '../common/AppContext';

const useSmartSearchService = () => {
    const { secureAPI } = useAPI();
    const { proxyAPISettings } = useAppData();

    const proxyAPIBaseURL = proxyAPISettings.CoachesDBProxyAPIBaseURL;

    function getSearchObjectBySearchText(searchText) {
        return secureAPI.post(proxyAPIBaseURL + APIURLConstants.SMART_SEARCH,
            { "searchText": searchText }, {
        })
            .then(data => {
                return data;
            }).catch((error) => {
                console.error(error);
            })
    }

    function getPreviewSuggestions() {
        return secureAPI.post(proxyAPIBaseURL + APIURLConstants.SMART_SEARCH_PREVIEW_SUGGESTIONS,
            { "type": "all" }, {
        })
            .then(data => {
                return data;
            }).catch((error) => {
                console.error(error);
            })
    }

    return { getSearchObjectBySearchText, getPreviewSuggestions };
};

export default useSmartSearchService;