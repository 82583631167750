import React from "react";
import ReactDOM from "react-dom";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";

const ConfirmationDialog = (message, callback) => {
  const container = document.createElement("div");

  container.setAttribute("custom-confirmation-navigation", "");

  document.body.appendChild(container);

  const closeModal = (callbackState) => {
    ReactDOM.unmountComponentAtNode(container);
    callback(callbackState);
  };

  const textObj = JSON.parse(message);
  ReactDOM.render(
    <Dialog open={open} onClose={() => closeModal(false)}>
      {/* <DialogTitle>Warning ?</DialogTitle> */}
      <DialogContent>
        <DialogContentText>{textObj.messageText}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => closeModal(false)} color="primary">
          {textObj.cancelText}
        </Button>
        <Button onClick={() => closeModal(true)} color="primary">
          {textObj.confirmText}
        </Button>
      </DialogActions>
    </Dialog>,
    container
  );
};
export default ConfirmationDialog;
