import React, { useState, useEffect } from 'react';
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Paper, Checkbox } from '@material-ui/core';
import UserList from "./UserList";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        [theme.breakpoints.up('sm')]: {
            //  padding: theme.spacing(1)
        },
        // paddingTop: 10
    },
    table: {
        borderRadius: '0',
    },
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
}));

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: "#1d428a",
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);


export default function ListContainer(props) {
    const classes = useStyles();
    const [selectAll, setSelectAll] = useState(false);
    useEffect(() => {
        if (props.selectedUsers && props.selectedUsers.length > 0) {
            var currentProfiles = props.userList.map(u => u.userName);
            var selectedProfiles = currentProfiles.filter((u) => props.selectedUsers && props.selectedUsers.includes(u));
            if (selectedProfiles.length == currentProfiles.length){
                setSelectAll(true);
            }
            else
            setSelectAll(false);
        }
        else setSelectAll(false);
    },[props.selectedUsers, props.userList])

    return (
        <div className={classes.root}>
            <Grid container>
                <TableContainer component={Paper} className={classes.table}>
                    <Table size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                {props.showCheckBox && props.showCheckBox == true &&
                                    <StyledTableCell style={{ width: '3%' }}>
                                        <Checkbox checked={selectAll}
                                            onChange={props.handleAllUserChecked}
                                            id="chkSelectAll">
                                        </Checkbox>
                                    </StyledTableCell>}
                                <StyledTableCell style={{ width: '20%' }}>Name</StyledTableCell>
                                <StyledTableCell align="left" style={{ width: '20%' }}>Username</StyledTableCell>
                                <StyledTableCell align="left" style={{ width: '27%' }}>Email</StyledTableCell>
                                <StyledTableCell align="left" style={{ width: '20%' }}>Role</StyledTableCell>
                                <StyledTableCell align="left" style={{ width: '5%' }}>Active </StyledTableCell>
                                <StyledTableCell align="left" style={{ width: '5%' }}>Edit </StyledTableCell>
                                <StyledTableCell align="left" style={{ width: '5%' }}>Update Password </StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {props.userList.map((value, index) => {
                                return <UserList
                                    key={index}
                                    data={value}
                                    onEditClick={props.onEditClick}
                                    onChangePasswordClick={props.onChangePasswordClick}
                                    handleStatusChange={props.handleStatusChange}
                                    handleUserChecked={props.handleUserChecked}
                                    selectedUsers={props.selectedUsers}
                                    showCheckBox={props.showCheckBox} />
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </div>
    );

}
