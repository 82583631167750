import React, { useEffect, useState } from "react";
import { Box, Grid, IconButton, Toolbar, makeStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { PostAddOutlined } from "@material-ui/icons";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import RouteConstants from "../../common/RouteConstants";
import SocialCommunityAppBar from "./SocialCommunityAppBar";
import SocialCommunityTabPanel from "./SocialCommunityTabPanel";
import SocialSearchBar from "./SocialSearchBar";
import Loader from "../../sharedComponents/Loader/Loader";
import useTelemetry from "../../utils/useTelemetry";

const useStyles = makeStyles((theme) => ({
    content: {
        margin: theme.spacing(2),
        boxShadow: "0px 0px 5px 3px rgba(0,0,0,0.1)",
        backgroundColor: '#ffffff',
        zIndex: '2',
    },
    toolbar: {
        flex: 1,
        display: "flex",
        justifyContent: "space-between",
        alignItems: 'center',
        [theme.breakpoints.down("xs")]: {
            paddingLeft: 0,
            paddingRight: 0,
        },
    },
    button: {
        border: '1px solid #1d428a',
        borderRadius: '4px',
        padding: '6px 8px',
        marginBottom: '10px',
        fontSize: '1rem',
        color: '#fff',
        background: '#1d428a',
        '&:hover': {
            color: '#fff',
            background: '#1d428a',
        },
        '&:focus': {
            color: '#fff',
            background: '#1d428a',
        },
        '& svg': {
            marginRight: '5px',
        },
    },
}));

const SocialCommunityContainer = (props) => {
    const classes = useStyles();
    let history = useHistory();
    const [tabIndex, setTabIndex] = useState(0); // Initial tabIndex state
    const [isLoading, setIsLoading] = useState(false); // Initial isLoading state
    const { trackCustomEvents } = useTelemetry();
    const [searchedText, setSearchedText] = useState("");


    // Extract tabIndex from props.location.state or default to 0
    useEffect(() => {

        if (props.location.state && props.location.state.tabIndex) {
            setTabIndex(props.location.state.tabIndex);
        }
    }, [props.location.state]);


    const handleTabChange = (event, newValue) => {
        setTabIndex(newValue);
        trackCustomEvents("ClickEvent", { event: newValue === 0 ? "All Post" : newValue === 1 ? "Pending Review Posts" : "Returned Posts" });
    };

    const onPressCreatePost = () => {
        history.replace({
            pathname: RouteConstants.CREATE_SOCIAL_POST,
            state: {
                postDetails: null,
                editPost: false,
            }
        });
        trackCustomEvents("ClickEvent", { event: "Create Social Post" });
    };

    const handleSearch = (text) => {
        setSearchedText(text);
    };

    return (
        <div className={classes.content}>
            <SocialCommunityAppBar tabIndex={tabIndex} handleTabChange={handleTabChange} />
            <Toolbar className={classes.toolbar}>
                <Typography variant="subtitle1" color="secondary">
                    NBA Coaches Database - Coaches Community
                </Typography>
                <IconButton onClick={onPressCreatePost} className={classes.button}>
                    <PostAddOutlined className={classes.cursorPointer} />
                    Create Post
                </IconButton>
            </Toolbar>
            <Box sizeHeight={"100vh"} sizeWidth={'100vw'} display={"flex"} style={{ justifyContent: "center" }}>
                <Grid item xs={12} md={8} lg={6} style={{ padding: 24, display: 'flex' }}>
                    <SocialSearchBar onSearch={handleSearch} tabIndex={tabIndex} />
                </Grid>
            </Box>
            <SocialCommunityTabPanel tabIndex={tabIndex} handleTabChange={handleTabChange} searchedText={searchedText} setIsLoading={setIsLoading} />
            <Loader isLoading={isLoading} />
        </div>
    );
};

export default SocialCommunityContainer;
