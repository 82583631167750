import APIHelper from "../common/ApiHelper";
import APIURLConstants from "../common/ApiURLConstants";
import { authHeader, handleError, handleResponse } from '../helper';

export const appSettingsService = {
    getProxyAPISettings
};

function getProxyAPISettings() {
    return APIHelper.get(APIURLConstants.GET_PROXY_API_SETTINGS, authHeader())
        .then(handleResponse)
        .then(data => {
            return data
        })
        .catch(handleError);
}

export default appSettingsService;