import AppBar from "@material-ui/core/AppBar";
import Box from "@material-ui/core/Box";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Typography from "@material-ui/core/Typography";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import AssignmentIcon from "@material-ui/icons/Assignment";
import BusinessCenterIcon from "@material-ui/icons/BusinessCenter";
import SchoolIcon from "@material-ui/icons/School";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import SwipeableViews from "react-swipeable-views";
import AppConstants from "../../common/AppConstants";
import BasketballIcon from "../../sharedComponents/customIcons/BasketballIcon";
import HammerScrewdriverIcon from "../../sharedComponents/customIcons/HammerScrewdriverIcon";
import WhistleIcon from "../../sharedComponents/customIcons/WhistleIcon";
import AdditionalExperienceView from "./sections/AdditionalExperienceView";
import CurrentCoaching from "./sections/CurrentCoaching";
import EducationalDetailView from "./sections/EducationalDetailView";
import OtherView from "./sections/OtherView";
import PlayingExperienceView from "./sections/PlayingExperienceView";
import ProfileVideoView from "./sections/ProfileVideosView";
import SkillView from "./sections/SkillView";
import { VideoLibraryOutlined } from "@material-ui/icons";
import useTelemetry from "../../utils/useTelemetry";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
  },
  flexContainer: {
    justifyContent: 'space-evenly',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'unset',
    },
  },
  tab: {
    fontSize: theme.typography.pxToRem(12),
    [theme.breakpoints.up('sm')]: {
      minWidth: 'initial !important',
    },
  },
  tabsColor: {
    '& button': {
      color: '#ffffff !important'
    },
    flexContainer: {
      justifyContent: "space-evenly"
    }
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scroll-tabpanel-${index}`}
      aria-labelledby={`scroll-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scroll-tab-${index}`,
    "aria-controls": `scroll-tabpanel-${index}`,
  };
}

export default function ProfileDetails(props) {

  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const [currentCoachingExp, setCurrentCoachingExp] = useState([]);
  const { trackCustomEvents } = useTelemetry();

  useEffect(() => {
    let exp = [];
    let league = "";
    let currentExp = [];
    let headExp = props.coachingAndOtherJobExperience && props.coachingAndOtherJobExperience.headCoachExperience ? props.coachingAndOtherJobExperience.headCoachExperience : [];
    let assExp = props.coachingAndOtherJobExperience && props.coachingAndOtherJobExperience.assistantCoachExperience ? props.coachingAndOtherJobExperience.assistantCoachExperience : [];
    if (headExp.length > 0) {
      for (let i = 0; i < headExp.length; i++) {
        league = headExp[i].league;
        exp = headExp[i].experience.filter((x) => x.toDate === "Present");
        if (exp.length > 0) {
          for (let y = 0; y < exp.length; y++) {
            let obj = {
              league: league,
              teamName: exp[y].team.teamName,
              logoURL: exp[y].team.logoURL,
              fromDate: exp[y].fromDate,
              title: "Head Coach",
              titleSubCategory: exp[y].titleSubCategory && exp[y].titleSubCategory.Name ? exp[y].titleSubCategory.Name : "",
            };
            currentExp.push(obj);
          }
        }
      }
    }
    if (assExp.length > 0) {
      for (let i = 0; i < assExp.length; i++) {
        league = assExp[i].league;
        exp = assExp[i].experience.filter((x) => x.toDate === "Present");
        if (exp.length > 0) {
          for (let y = 0; y < exp.length; y++) {
            let obj = {
              league: league,
              teamName: exp[y].team.teamName,
              logoURL: exp[y].team.logoURL,
              title: exp[y].title && exp[y].title.Name ? exp[y].title.Name : "Assistant Coach",
              titleSubCategory: exp[y].titleSubCategory && exp[y].titleSubCategory.Name ? exp[y].titleSubCategory.Name : "",
              fromDate: exp[y].fromDate
            };

            currentExp.push(obj);
          }
        }
      }
    }
    setCurrentCoachingExp(currentExp);
  }, [props.coachingAndOtherJobExperience]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue === 0) {
      trackCustomEvents("ClickEvent", { event: "Coaching Experience" });
    } else if (newValue === 1) {
      trackCustomEvents("ClickEvent", { event: "Playing Experience" });
    } else if (newValue === 2) {
      trackCustomEvents("ClickEvent", { event: "Additional Experience" });
    } else if (newValue === 3) {
      trackCustomEvents("ClickEvent", { event: "Skills" });
    } else if (newValue === 4) {
      trackCustomEvents("ClickEvent", { event: "Education" });
    } else if (newValue === 5) {
      trackCustomEvents("ClickEvent", { event: "Media" });
    } else if (newValue === 6) {
      trackCustomEvents("ClickEvent", { event: "Other" });
    }
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  function isXsDown() {
    return useMediaQuery(theme.breakpoints.down("xs"));
  }

  return (
    <div className={classes.root}>
      <AppBar position="static" color="primary" elevation={1}>
        <Tabs
          value={value}
          onChange={handleChange}
          scrollButtons="auto"
          indicatorColor="secondary"
          textColor="secondary"
          variant="scrollable"
          aria-label="profile tabs"
          className={classes.tabsColor}
          classes={{ flexContainer: classes.flexContainer }}
        >
          <Tab
            className={classes.tab}
            icon={<WhistleIcon />}
            label={
              !isXsDown() && AppConstants.PROFILE_SECTION_TITLE.CoachingExp
            }
            {...a11yProps(0)}
          />
          <Tab
            className={classes.tab}
            icon={<BasketballIcon />}
            label={!isXsDown() && AppConstants.PROFILE_SECTION_TITLE.PlayingExp}
            {...a11yProps(1)}
          />
          <Tab
            className={classes.tab}
            icon={<BusinessCenterIcon />}
            label={
              !isXsDown() && AppConstants.PROFILE_SECTION_TITLE.AdditionalExp
            }
            {...a11yProps(2)}
          />
          <Tab
            className={classes.tab}
            icon={<HammerScrewdriverIcon />}
            label={!isXsDown() && AppConstants.PROFILE_SECTION_TITLE.Skills}
            {...a11yProps(3)}
          />
          <Tab
            className={classes.tab}
            icon={<SchoolIcon />}
            label={
              !isXsDown() &&
              AppConstants.PROFILE_SECTION_TITLE.EducationalDetails
            }
            {...a11yProps(4)}
          />
          <Tab
            className={classes.tab}
            icon={<VideoLibraryOutlined />}
            label={!isXsDown() && AppConstants.PROFILE_SECTION_TITLE.Videos}
            {...a11yProps(5)}
          />
          <Tab
            className={classes.tab}
            icon={<AssignmentIcon />}
            label={!isXsDown() && AppConstants.PROFILE_SECTION_TITLE.Other}
            {...a11yProps(6)}
          />

        </Tabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel value={value} index={0} dir={theme.direction}>
          <CurrentCoaching
            currentCoachingExp={currentCoachingExp}
            getLeagueTextByValue={props.getLeagueTextByValue}
            data={props.coachingAndOtherJobExperience}
            coachingSkill={props.skillSet && props.skillSet.coachingSkill ? props.skillSet.coachingSkill : []}
            coordinator={props.skillSet && props.skillSet.coordinator ? props.skillSet.coordinator : ""}
          />
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          <PlayingExperienceView getLeagueTextByValue={props.getLeagueTextByValue} data={props.playingExperience} />
        </TabPanel>
        <TabPanel value={value} index={2} dir={theme.direction}>
          <AdditionalExperienceView data={props.additionalExperience} />
        </TabPanel>
        <TabPanel value={value} index={3} dir={theme.direction}>
          <SkillView data={props.skillSet} />
        </TabPanel>
        <TabPanel value={value} index={4} dir={theme.direction}>
          <EducationalDetailView data={props.educationDetail} />
        </TabPanel>
        <TabPanel value={value} index={5} dir={theme.direction}>
          <ProfileVideoView data={props.videoData} coachingVideos={props.coachingVideos} userName={props.userName} />
        </TabPanel>
        <TabPanel value={value} index={6} dir={theme.direction}>
          <OtherView data={props.other} />
        </TabPanel>
      </SwipeableViews>
    </div>
  );
}
