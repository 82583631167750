import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import helper from "../../common/Helper";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        [theme.breakpoints.up('sm')]: {
            padding: theme.spacing(1)
        },
    },
    gridItem: {
        [theme.breakpoints.up('sm')]: {
            width: '100%',
            padding: theme.spacing(1)
        },
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            paddingBottom: theme.spacing(1)
        },
    },
    Name: {
        fontWeight: 'bold',
        fontSize: '18px',
        textDecoration: 'none',
        color: '#393939'
    },
    tableRow: {
        '& td p': {
            margin: 0,
        },
    },
    contentCell: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        maxWidth: 0,
        '& *': {
            fontSize: '1rem',
            fontWeight: 'normal',
        },
        '& p *': {
            margin: 0,
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
        },
    },
}));

const InviteHistoryList = (props) => {

    const classes = useStyles();

    const formattedDate = (date) => {
        return helper.IfDateIsNULL(date) ? '' : helper.FormatDateTime(date);
    }

    return (
        <>
            <TableRow className={classes.tableRow}>
                <TableCell align="left" style={{ width: '45%' }} >
                    <Typography variant="body2"> {props.data.email} </Typography>
                </TableCell>
                <TableCell align="left" style={{ width: '25%' }} >
                    <Typography variant="body2"> {props.data.invitedBy} </Typography>
                </TableCell>
                <TableCell align="left" style={{ width: '20%' }} >
                    <Typography variant="body2"> {formattedDate(props.data.invitationDate)} </Typography>
                </TableCell>
                <TableCell align="left" style={{ width: '45%' }} >
                    <Typography variant="body2"> {props.data.role} </Typography>
                </TableCell>
                <TableCell align="left" style={{ width: '25%' }} >
                    <Typography variant="body2"> {props.data.status} </Typography>
                </TableCell>
            </TableRow>
        </>
    );
};

export default InviteHistoryList;
