import React from "react";
import Typography from "@material-ui/core/Typography";
import { Tooltip } from 'react-tippy';
import 'react-tippy/dist/tippy.css';
import HelpIcon from '@material-ui/icons/Help';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grid from '@material-ui/core/Grid';

const TitleWithTooltip = (props) => {
    var data = require('./Tooltip.json');
    const [open, setOpen] = React.useState(false);
    const handleTooltipClose = () => {
        setOpen(false);
    };
    const handleTooltipOpen = () => {
        setOpen(true);
    };
    let msg = props.tooltipTitle ? data[props.tooltipTitle] : "";
    return (
        <Typography variant={props.variant ? props.variant : "body2"} className={props.class}>
            {props.title}
            <ClickAwayListener onClickAway={handleTooltipClose}>
                <Tooltip
                    component={'span'}
                    PopperProps={{
                        disablePortal: true,
                    }}
                    onClose={handleTooltipClose}
                    open={open}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    title={msg}>
                    <HelpIcon onClick={handleTooltipOpen} fontSize="small" style={{ width: '18px', height: '18px', verticalAlign: 'middle' }} />
                </Tooltip>
            </ClickAwayListener>
            {props.showRequired &&
                <span style={{ fontWeight: 'bold', color: 'red', paddingLeft: '2px' }}>(REQUIRED)</span>
            }
            {props.additionalText && props.additionalText != '' &&
                <span style={{ color: 'red', paddingLeft: '2px', fontStyle: 'italic' }}>{props.additionalText}</span>
            }
        </Typography>

    )
}

export default TitleWithTooltip;