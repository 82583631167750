import React, { useState, useEffect, useContext } from "react";
import RouteConstants from "../../common/RouteConstants";
import { useLocation, withRouter } from "react-router-dom";
import CoachesNode from './CoachesNode';
import { userService, coachProfileService } from '../../services';
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Box, Container, Grid, Typography, Paper, Divider } from "@material-ui/core";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import same_staff from '../../images/player_1.svg';
import head_coach from '../../images/head_coach_icon.svg';
import assistant_coach from '../../images/staff_1.svg';
import { AppDataContext } from '../../common/AppContext';
import Hidden from "@material-ui/core/Hidden";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CoachingTreeLegend from './CoachingTreeLegend';
import { reactNativeInvoker } from '../../helper';



const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0),
    },
  },

  paperMargin: {
    margin: theme.spacing(3, 0),
    padding: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(0),
      boxShadow: 'none'
    },

  },
  content: {
    '& .MuiAccordionSummary-content': {
      justifyContent: "center",
    },


  },
  accordianTitle: {
    fontFamily: 'lato',
    fontWeight: '700',
    fontSize: theme.spacing(2)

  },
  accordionPanel: {
    '&.MuiAccordion-root.Mui-disabled': {
      background: 'none',
      '& .MuiAccordionSummary-root.Mui-expanded': {
        minHeight: theme.spacing(6),
        '& .MuiAccordionSummary-content': {
          margin: theme.spacing(1.5),
        },
      },
      '& .MuiCollapse-entered': {
        display: 'none',
      },
      '& .MuiAccordionSummary-expandIcon.Mui-expanded': {
        transform: 'rotate(0deg)',
      },
    },
    '& .MuiIconButton-root': {
      color: '#000000'
    },

  },
  headCoacheBG: {
    boxShadow: 'none',
    '& .MuiAccordionSummary-root': {
      backgroundColor: "#9BADD1",
      color: "#000000",
      borderRadius: theme.spacing(6),
    }
  },
  assitantCoachesBG: {
    boxShadow: 'none',
    '& .MuiAccordionSummary-root': {
      backgroundColor: "#62A1B8",
      color: "#000000",
      borderRadius: theme.spacing(6),
    }
  },
  sameStaffBG: {
    boxShadow: 'none',
    '& .MuiAccordionSummary-root': {
      backgroundColor: "#86B862",
      color: "#000000 ",
      borderRadius: theme.spacing(6),
    }
  },
  slickSlide: {
    '& .slick-list': {
      '& .slick-track': {
        margin: '0',
      },
    },
    margin: theme.spacing(0, 3),
    '& .slick-prev, .slick-next': {
      color: '#333',
      '&.slick-disabled': {
        display: 'none !important'
      }
    }
  },

  headerImage: {
    marginTop: theme.spacing(0),
    height: theme.spacing(12),
    [theme.breakpoints.down('xs')]: {
      height: theme.spacing(5),
      marginTop: theme.spacing(0),
    },
  },
  accordianDetail: {
    display: 'block',
    padding: theme.spacing(1, 0, 2),
    '& .MuiPaper-outlined': {
      backgroundColor: '#f4f4f4',
      margin: theme.spacing(1),

    }
  },

  headTitle: {
    margin: theme.spacing(0, 0, 0, 4),
    fontFamily: "'Lato', sans-serif",
    fontWeight: '700',
    fontSize: theme.spacing(2.25),
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(0),
      lineHeight: '21.6px'
    },
  },
  headDescription: {
    margin: theme.spacing(0, 0, 1, 4),
    fontFamily: "'Lato', sans-serif",
    fontWeight: '400',
    fontSize: theme.spacing(1.75),
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(0),
      lineHeight: '16.8px'
    },
  },
  legendMobile:{
    marginBottom: '16px',
    justifyContent:'center'

  }


}));








const CoachingTreeContainer = (props) => {

  const classes = useStyles();
  let { state } = useLocation();
  const [sameStaffList, setSameStaffList] = useState([]);
  const [headCoachesList, setheadCoachesList] = useState([]);
  const [assitantsAndPlayersList, setAssistantsAndPlayerList] = useState([]);
  const [isLoadingHeadCoaches, setIsLoadingHeadCoaches] = useState(false);
  const [isLoadingACoaches, setIsLoadingACoaches] = useState(false);
  const [isLoadingSStaff, setIsLoadingSStaff] = useState(false);
  const [isCoachProfile, setIsCoachProfile] = useState(state !== undefined ? state.isCoachProfile : false);
  const [mainCoach, setMainCoach] = useState({});
  const {
    setMobileView
  } = useContext(AppDataContext);
  const query = new URLSearchParams(props.location.search);
  const view = query.get('view')
  const currentUserName = userService.getUsername();
  const [expanded, setExpanded] = React.useState("");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  useEffect(() => {
    if (view && view === 'm') {
      setMobileView(true)

    }

    return () => {
      // Anything in here is fired on component unmount.
      setMobileView(false)
    }
  }, [])


  useEffect(() => {
  
    setIsLoadingHeadCoaches(true);
    setIsLoadingACoaches(true)
    setIsLoadingSStaff(true)
    setheadCoachesList([])
    setSameStaffList([])
    setAssistantsAndPlayerList([])
    userService.getCoachingTreeHeadCoachesData(props.match.params.userName)
      .then((data) => {
        setIsLoadingHeadCoaches(false);
        setheadCoachesList(data.headCoaches);
      })
      .catch((err) => {
        console.log(err);
        setIsLoadingHeadCoaches(false);
      });


    userService.getCoachingTreeSameStaffData(props.match.params.userName)
      .then((data) => {
        setIsLoadingACoaches(false);
        setSameStaffList(data.sameStaff);
      })
      .catch((err) => {
        console.log(err);
        setIsLoadingACoaches(false);
      });

    userService.getCoachingTreeAssistantsAndPlayersData(props.match.params.userName)
      .then((data) => {
        setIsLoadingSStaff(false);
        setAssistantsAndPlayerList(data.assistantAndPlayers);
      })
      .catch((err) => {
        console.log(err);
        setIsLoadingSStaff(false);
      });



  }, [props.match.params.userName])

  useEffect(() => {
    coachProfileService.getCoachProfile(props.match.params.userName)
      .then((data) => {
        console.log(data)
        setMainCoach(data)
      }
      )
      .catch((err) => {

        //setProcessing(false);
        console.log(err);
      });

  }, [props.match.params.userName])




  var settingsheadCoaches = {


    dots: false,
    infinite: false,
    speed: 500,

    slidesToScroll: 8,
    initialSlide: 0,
    rows: headCoachesList.length > 16 ? 3 : headCoachesList.length > 8 ? 2 : 1,

    // slidesPerRow:1,
    slidesToShow: 8,

    nextArrow: <ArrowForwardIosIcon />,
    prevArrow: <ArrowBackIosIcon />,
    responsive: [
      {//desktop normal
        breakpoint: 1440,
        settings: {
          slidesToShow: 7,
          rows: headCoachesList.length > 14 ? 3 : headCoachesList.length > 7 ? 2 : 1,
          slidesToScroll: 8,
        }
      },
      {//desktop normal/ipad landscape
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
          rows: headCoachesList.length > 10 ? 3 : headCoachesList.length > 5 ? 2 : 1,
          slidesToScroll: 5,
        }
      },
      {//Ipad
        breakpoint: 768,
        settings: {
          slidesToShow: 4,
          rows: 1,
          slidesToScroll: 5,
        }
      },
      {//phone
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          rows: 1
        }
      },
      {//phone
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  var settingsAssistantCoaches = {


    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 8,
    slidesToScroll: 8,
    initialSlide: 0,
    rows: sameStaffList.length > 16 ? 3 : sameStaffList.length > 8 ? 2 : 1,
    nextArrow: <ArrowForwardIosIcon />,
    prevArrow: <ArrowBackIosIcon />,
    responsive: [
      {//desktop normal
        breakpoint: 1440,
        settings: {
          slidesToShow: 7,
          slidesToScroll: 7,
          rows: sameStaffList.length > 14 ? 3 : sameStaffList.length > 7 ? 2 : 1,

        }
      },
      {//desktop normal/ipad landscape
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 5,
          rows: sameStaffList.length > 10 ? 3 : sameStaffList.length > 5 ? 2 : 1,
        }
      },
      {//Ipad
        breakpoint: 768,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          rows: 1
        }
      },
      {//phone
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {//phone
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };


  var settingsSameStaff = {


    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 8,
    slidesToScroll: 8,
    rows: assitantsAndPlayersList.length > 16 ? 3 : assitantsAndPlayersList.length > 8 ? 2 : 1,
    nextArrow: <ArrowForwardIosIcon />,
    prevArrow: <ArrowBackIosIcon />,
    responsive: [
      {//desktop normal
        breakpoint: 1440,
        settings: {
          slidesToShow: 7,
          slidesToScroll: 7,
          rows: assitantsAndPlayersList.length > 14 ? 3 : assitantsAndPlayersList.length > 7 ? 2 : 1,
        }
      },
      {//desktop normal/ipad landscape
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 5,
          rows: assitantsAndPlayersList.length > 10 ? 3 : assitantsAndPlayersList.length > 5 ? 2 : 1,
        }
      },
      {//Ipad
        breakpoint: 768,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          rows: 1
        }
      },
      {//phone
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {//phone
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };





  const handleCoachClick = (newValue) => {
    if (newValue) {
      if (view && view === 'm') {
        props.history.push(RouteConstants.COACHES_TREE + "/" + newValue + '?view=m');

      } else {
        props.history.push(RouteConstants.COACHES_TREE + "/" + newValue);
      }

    }


  }

  const backButtonClick = () => {


    if(view === 'm'){      
      reactNativeInvoker('OPEN_PROFILE',props.match.params.userName)
    } else{
      if (isCoachProfile && props.match.params.userName === currentUserName) {
        props.history.push(RouteConstants.COACH_PROFILE_VIEW);
      } else {
        props.history.push(RouteConstants.COACHES_PROFILE_VIEW + "/" + props.match.params.userName);
      }
    }
   

  }



  return (
    <>

      <Container maxWidth={'false'} className={classes.container}>
        <Paper className={classes.paperMargin} elevation={2} >
          <Grid container spacing={1}>
            {view !== 'm' && <Hidden xsDown>
              <Grid item xs={12} style={{ display: 'flex' }}>
                <Grid item lg={9} md={8} sm={6} >
                  <Button
                    color="primary"
                    size="small"
                    startIcon={<ArrowBackIcon />}
                    variant="contained"
                    onClick={backButtonClick}
                  >
                    Back to profile
                  </Button>
                </Grid>
                <Grid container lg={3} md={4} sm={6} justifyContent="flex-end">
                  <Box display="flex" flexDirection="column">
                    <CoachingTreeLegend data={{ description: 'Person worked for/with as Coach', color: '#3897FF' }} ></CoachingTreeLegend>
                    <CoachingTreeLegend data={{ description: 'Person played for as player', color: '#ff781f' }}></CoachingTreeLegend>
                  </Box>
                </Grid>
              </Grid>
            </Hidden>}

            <Hidden smUp>
              <Grid item xs={12}>

                <Grid container alignItems="center">

                  <Grid container xs={12} alignItems = "center" className={classes.legendMobile} >
                    <Box display="flex" flexDirection="column">
                      <CoachingTreeLegend data={{ description: 'Person worked for/with as Coach', color: '#3897FF' }} ></CoachingTreeLegend>
                      <CoachingTreeLegend data={{ description: 'Person played for as player', color: '#ff781f' }}></CoachingTreeLegend>
                    </Box>
                  </Grid>

                  <Grid item xs={2} >
                    <img src={head_coach} className={classes.headerImage} />
                  </Grid>
                  <Grid item xs={10} >
                    <Typography variant='subtitle2' className={classes.headTitle} component='p'>Head Coaches
                    </Typography>
                    {mainCoach && mainCoach.personalDetails &&
                      <Typography variant='caption' className={classes.headDescription} component='p'>{'Coaches '}<Typography component='span' color="primary" variant='caption'>{mainCoach.personalDetails.fullName.firstName}</Typography>
                        {' worked and/or played for'} </Typography>
                    }
                  </Grid>
                </Grid>


                <Grid item xs={12} >
                  <div className={classes.root}>
                    <Accordion className={`${classes.accordionPanel} ${classes.headCoacheBG}`} disabled={headCoachesList.length === 0}
                      expanded={expanded === "panel1"}
                      onChange={handleChange("panel1")}>
                      <AccordionSummary
                        className={classes.content}
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"

                      >
                        <Typography className={classes.accordianTitle} >Head Coaches</Typography>
                      </AccordionSummary>
                      {isLoadingHeadCoaches ? <div style={{ width: "48px", height: "48px", padding: "12px" }}><CircularProgress size={20} color="inherit" /></div> :
                        <AccordionDetails className={classes.accordianDetail}>
                          {headCoachesList && headCoachesList.length > 0 &&
                            headCoachesList.map((val, ind) => {
                              return (

                                <CoachesNode handleCoachClick={handleCoachClick} data={val} isMainCoach={false}></CoachesNode>

                              )
                            })}
                        </AccordionDetails>
                      }
                    </Accordion>

                  </div>
                </Grid>
              </Grid>
            </Hidden>

            <Hidden xsDown><Grid container justify="center" alignItems="center" sm={1}>
              <img src={head_coach} className={classes.headerImage} />
            </Grid>
              <Grid sm={11}>
                <Box display="flex" flexDirection="column" marginY={2} >
                  <Typography variant="h6" className={classes.headTitle} component="h6">Head Coaches
                  </Typography>
                  {mainCoach && mainCoach.personalDetails &&
                    <Typography variant='caption' className={classes.headDescription} component='p'>{'Coaches '}
                      <Typography component='span' color="primary" variant='caption'>{mainCoach.personalDetails.fullName.firstName}</Typography>{' worked and/or played for'}
                    </Typography>
                  }
                  {isLoadingHeadCoaches ? <div style={{ width: "48px", height: "48px", padding: "12px" }}><CircularProgress size={20} color="inherit" /></div> :
                    <>
                      {headCoachesList.length > 0 ?
                        <Slider className={classes.slickSlide} {...settingsheadCoaches}>
                          {headCoachesList && headCoachesList.length > 0 &&
                            headCoachesList.map((val, ind) => {
                              return (
                                <CoachesNode handleCoachClick={handleCoachClick} data={val} isMainCoach={false}></CoachesNode>
                              )
                            })}
                        </Slider> : <Typography variant='caption' className={classes.headDescription} component='p'>
                          N/A
                        </Typography>
                      }
                    </>}




                </Box>
              </Grid> </Hidden>


            <Grid item xs={12}>
              <CoachesNode handleCoachClick={handleCoachClick} data={mainCoach} isMainCoach={true}  backToProfile ={backButtonClick}></CoachesNode>
            </Grid>


            <Hidden smUp>
              <Grid container xs={12} style={{ marginTop: '16px' }}>
                <Grid container alignItems="center">
                  <Grid item xs={2} >
                    <img src={assistant_coach} className={classes.headerImage} />
                  </Grid>
                  <Grid item xs={10} >
                    <Typography variant='subtitle2' className={classes.headTitle} component='p'>Assistant Coaches/Staff
                    </Typography>
                    {mainCoach && mainCoach.personalDetails &&
                      <Typography variant='caption' className={classes.headDescription} component='p'>{'Coaches '} <Typography component='span' color="primary" variant='caption'>{mainCoach.personalDetails.fullName.firstName}</Typography>{' worked with'}</Typography>
                    }
                  </Grid>
                </Grid>
                <Grid item xs={12} >
                  <div className={classes.root}>
                    <Accordion className={`${classes.accordionPanel} ${classes.assitantCoachesBG}`} disabled={sameStaffList.length === 0}
                      expanded={expanded === "panel2"}
                      onChange={handleChange("panel2")}>
                      <AccordionSummary
                        className={classes.content}
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography className={classes.accordianTitle}>Assistant Coaches/Staff</Typography>
                      </AccordionSummary>
                      {isLoadingACoaches ? <div style={{ width: "48px", height: "48px", padding: "12px" }}><CircularProgress size={20} color="inherit" /></div> :
                        <AccordionDetails className={classes.accordianDetail}>
                          {sameStaffList && sameStaffList.length > 0 &&
                            sameStaffList.map((val, ind) => {
                              return (
                                <CoachesNode handleCoachClick={handleCoachClick} data={val} isMainCoach={false}></CoachesNode>
                              )
                            })}
                        </AccordionDetails>
                      }
                    </Accordion>

                  </div>
                </Grid>

              </Grid>
            </Hidden>

            <Hidden xsDown>
              <Divider width={'100%'} style={{ marginTop: '8px' }} />
              <Grid container justify="center" alignItems="center" sm={1}>
                <img src={assistant_coach} className={classes.headerImage} />
              </Grid>

              <Grid item xs={12} sm={11}>
                <Box display="flex" flexDirection="column" marginY={2} >
                  <Typography variant="h6" className={classes.headTitle} component="h6">Assistant Coaches/Staff
                  </Typography>
                  {mainCoach && mainCoach.personalDetails &&
                    <Typography variant='caption' className={classes.headDescription} component='p'>{'Coaches '}<Typography component='span' color="primary" variant='caption'>{mainCoach.personalDetails.fullName.firstName}</Typography>{' worked with'}</Typography>
                  }
                  {isLoadingACoaches ? <div style={{ width: "48px", height: "48px", padding: "12px" }}><CircularProgress size={20} color="inherit" /></div> :
                    <>
                      {
                        sameStaffList.length > 0 ?

                          <Slider className={classes.slickSlide}  {...settingsAssistantCoaches}>

                            {sameStaffList && sameStaffList.length > 0 &&
                              sameStaffList.map((val, ind) => {
                                return (
                                  <CoachesNode handleCoachClick={handleCoachClick} data={val} isMainCoach={false}></CoachesNode>
                                )
                              })}

                          </Slider> :
                          <Typography variant='caption' className={classes.headDescription} component='p'>
                            N/A
                          </Typography>
                      }
                    </>
                  }




                </Box>
              </Grid>
            </Hidden>

            <Hidden smUp><Grid item xs={12} style={{ marginTop: '16px' }}>
              <Grid container alignItems="center">
                <Grid item xs={2} >
                  <img src={same_staff} className={classes.headerImage} />
                </Grid>
                <Grid item xs={10} >
                  <Typography variant='subtitle2' className={classes.headTitle} component='p'>Coaches/Players
                  </Typography>
                  {mainCoach && mainCoach.personalDetails &&
                    <Typography className={classes.headDescription} variant='caption' component='p'>{'Coaches/Players that worked/played for '}<Typography component='span' color="primary" variant='caption'>{mainCoach.personalDetails.fullName.firstName}</Typography></Typography>
                  }
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <div className={classes.root}>
                  <Accordion className={`${classes.accordionPanel} ${classes.sameStaffBG}`} disabled={assitantsAndPlayersList.length === 0}
                    expanded={expanded === "panel3"}
                    onChange={handleChange("panel3")}>
                    <AccordionSummary
                      className={classes.content}
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel3a-content"
                      id="panel3a-header"
                    >
                      <Typography className={classes.accordianTitle}>Coaches/Players</Typography>
                    </AccordionSummary>
                    {isLoadingSStaff ? <div style={{ width: "48px", height: "48px", padding: "12px" }}><CircularProgress size={20} color="inherit" /></div> :
                      <AccordionDetails className={classes.accordianDetail}>
                        {assitantsAndPlayersList && assitantsAndPlayersList.length > 0 &&
                          assitantsAndPlayersList.map((val, ind) => {
                            return (
                              <CoachesNode handleCoachClick={handleCoachClick} data={val} isMainCoach={false}></CoachesNode>
                            )
                          })}
                      </AccordionDetails>
                    }
                  </Accordion>

                </div>
              </Grid>
            </Grid>
            </Hidden>
            <Hidden xsDown>
              <Divider width={'100%'} />
              <Grid container justify="center" alignItems="center" sm={1}>
                <img src={same_staff} className={classes.headerImage} />
              </Grid>

              <Grid item sm={11}>

                <Box display="flex" flexDirection="column" marginY={2} >
                  <Typography variant="h6" className={classes.headTitle} component="h6">Coaches/Players
                  </Typography>
                  {mainCoach && mainCoach.personalDetails &&
                    <Typography className={classes.headDescription} variant='caption' component='p'>{'Coaches/Players that worked/played for '}<Typography component='span' color="primary" variant='caption'>{mainCoach.personalDetails.fullName.firstName}</Typography></Typography>
                  }

                  {isLoadingSStaff ? <div style={{ width: "48px", height: "48px", padding: "12px" }}><CircularProgress size={20} color="inherit" /></div> :
                    <>
                      {
                        assitantsAndPlayersList.length > 0 ?
                          <Slider className={classes.slickSlide}  {...settingsSameStaff}>
                            {assitantsAndPlayersList && assitantsAndPlayersList.length > 0 &&
                              assitantsAndPlayersList.map((val, ind) => {
                                return (
                                  <CoachesNode handleCoachClick={handleCoachClick} data={val} isMainCoach={false}></CoachesNode>
                                )
                              })}
                          </Slider> :
                          <Typography variant='caption' className={classes.headDescription} component='p'>
                            N/A
                          </Typography>
                      }
                    </>
                  }



                </Box>
              </Grid>
            </Hidden>
          </Grid>
        </Paper>


      </Container>
    </>
  )
}

export default withRouter(CoachingTreeContainer);
