import APIHelper from "../common/ApiHelper";
import APIURLConstants from "../common/ApiURLConstants";
import { authHeader, handleError, handleResponse } from '../helper';

export const socialPostService = {
    getAllPostList,
    createNewPost,
    likePost,
    getAllComments,
    addComment,
    updatePost,
    approveRejectPost,
    deletePost,
    deleteComment,
    updateComment
};

function getAllPostList(reviewedStatus, get, skip, searchBy) {
    const endpoint = APIURLConstants.POST_LIST + "/" + reviewedStatus + "/" + get + "/" + skip + "/" + searchBy;
    return APIHelper.get(endpoint, authHeader())
        .then(handleResponse)
        .then(data => {
            return data
        })
        .catch(handleError);
}

function createNewPost(postData) {
    return APIHelper.post(APIURLConstants.CREATE_POST, postData, authHeader())
        .then(handleResponse)
        .then(data => {
            return data
        })
        .catch(handleError);
}

function updatePost(postData) {
    return APIHelper.post(APIURLConstants.UPDATE_POST, postData, authHeader())
        .then(handleResponse)
        .then(res => {
            return res;
        }).catch(handleError);
}

function likePost(postId) {
    return APIHelper.post(APIURLConstants.LIKE_POST + "/" + postId, null, authHeader())
        .then(handleResponse)
        .then(res => {
            return res;
        }).catch(handleError);
}

function getAllComments(postId) {
    return APIHelper.get(APIURLConstants.GET_ALL_COMMENTS + "/" + postId, authHeader())
        .then(handleResponse)
        .then(res => {
            return res;
        }).catch(handleError);
}

function addComment(postData) {
    return APIHelper.post(APIURLConstants.ADD_COMMENT, postData, authHeader())
        .then(handleResponse)
        .then(res => {
            return res;
        }).catch(handleError);
}

function approveRejectPost(postData, notify) {
    console.log("notify", notify)
    return APIHelper.post(APIURLConstants.APPROVE_REJECT_POST + "/" + notify, postData, authHeader())
        .then(handleResponse)
        .then(res => {
            return res;
        }).catch(handleError);
}

function deletePost(postId) {
    return APIHelper.post(APIURLConstants.DELETE_POST + "/" + postId, null, authHeader())
        .then(handleResponse)
        .then(res => {
            return res;
        }).catch(handleError);
}

function deleteComment(commentId) {
    return APIHelper.post(APIURLConstants.DELETE_COMMENT + "/" + commentId, null, authHeader())
        .then(handleResponse)
        .then(res => {
            return res;
        }).catch(handleError);
}

function updateComment(commentData) {
    return APIHelper.post(APIURLConstants.UPDATE_COMMENT, commentData, authHeader())
        .then(handleResponse)
        .then(res => {
            return res;
        }).catch(handleError);
}

export default socialPostService;