import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import React from "react";
import SwipeableViews from "react-swipeable-views";
import AppConstants from "../../common/AppConstants";
import CardContainer from "../dashbord/CardContainer";
import ListContainer from "../dashbord/ListContainer";

const AdminCoachesTabPanel = (props) => {

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`scroll-tabpanel-${index}`}
        aria-labelledby={`scroll-tab-${index}`}
        {...other}
      >
        {value === index && <div p={3}>{children}</div>}
      </Typography>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };


  return (<SwipeableViews
    axis={props.theme.direction === "rtl" ? "x-reverse" : "x"}
    index={props.tabIndex}
    onChangeIndex={props.handleChangeIndex}
  >
    <TabPanel value={props.tabIndex} index={0} dir={props.theme.direction}>
      {props.view === "card" ? (
        <CardContainer
          userName={props.currentUserName}
          container={AppConstants.DASHBOARD_TYPE.ADMIN}
          handleOpenDialog={props.handleOpenDialog}
          profileList={props.profilesToDisplay}
          handleProfileStatusChange={props.handleProfileStatusChange}
          getLeagueTextByValue={props.getLeagueTextByValue}
          handleEditProfileClick={props.handleEditProfileClick}
          handleViewProfileClick={props.handleViewProfileClick}
          handleProfileChecked={props.handleProfileChecked}
          selectedProfiles={props.selectedProfiles}
          handleDeleteProfile={props.handleDeleteProfile}
        ></CardContainer>
      ) : (
        <ListContainer
          userName={props.currentUserName}
          container={AppConstants.DASHBOARD_TYPE.ADMIN}
          profileList={props.profilesToDisplay}
          handleOpenDialog={props.handleOpenDialog}
          handleProfileStatusChange={props.handleProfileStatusChange}
          getLeagueTextByValue={props.getLeagueTextByValue}
          handleEditProfileClick={props.handleEditProfileClick}
          handleViewProfileClick={props.handleViewProfileClick}
          handleProfileChecked={props.handleProfileChecked}
          selectedProfiles={props.selectedProfiles}
        ></ListContainer>
      )}
    </TabPanel>
    <TabPanel value={props.tabIndex} index={1} dir={props.theme.direction}>
      {props.view === "card" ? (
        <CardContainer
          userName={props.currentUserName}
          container={AppConstants.DASHBOARD_TYPE.ADMIN}
          handleOpenDialog={props.handleOpenDialog}
          profileList={props.profilesToDisplay}
          handleProfileStatusChange={props.handleProfileStatusChange}
          getLeagueTextByValue={props.getLeagueTextByValue}
          handleEditProfileClick={props.handleEditProfileClick}
          handleViewProfileClick={props.handleViewProfileClick}
          handleProfileChecked={props.handleProfileChecked}
          selectedProfiles={props.selectedProfiles}
          handleDeleteProfile={props.handleDeleteProfile}
        ></CardContainer>
      ) : (
        <ListContainer
          userName={props.currentUserName}
          container={AppConstants.DASHBOARD_TYPE.ADMIN}
          profileList={props.profilesToDisplay}
          handleOpenDialog={props.handleOpenDialog}
          handleProfileStatusChange={props.handleProfileStatusChange}
          getLeagueTextByValue={props.getLeagueTextByValue}
          handleEditProfileClick={props.handleEditProfileClick}
          handleViewProfileClick={props.handleViewProfileClick}
          handleProfileChecked={props.handleProfileChecked}
          selectedProfiles={props.selectedProfiles}
        ></ListContainer>
      )}
    </TabPanel>
    <TabPanel value={props.tabIndex} index={2} dir={props.theme.direction}>
      {props.view === "card" ? (
        <CardContainer
          userName={props.currentUserName}
          container={AppConstants.DASHBOARD_TYPE.ADMIN}
          handleOpenDialog={props.handleOpenDialog}
          profileList={props.profilesToDisplay}
          handleProfileStatusChange={props.handleProfileStatusChange}
          getLeagueTextByValue={props.getLeagueTextByValue}
          handleEditProfileClick={props.handleEditProfileClick}
          handleViewProfileClick={props.handleViewProfileClick}
          handleProfileChecked={props.handleProfileChecked}
          selectedProfiles={props.selectedProfiles}
          handleDeleteProfile={props.handleDeleteProfile}
        ></CardContainer>
      ) : (
        <ListContainer
          userName={props.currentUserName}
          container={AppConstants.DASHBOARD_TYPE.ADMIN}
          profileList={props.profilesToDisplay}
          handleOpenDialog={props.handleOpenDialog}
          handleProfileStatusChange={props.handleProfileStatusChange}
          getLeagueTextByValue={props.getLeagueTextByValue}
          handleEditProfileClick={props.handleEditProfileClick}
          handleViewProfileClick={props.handleViewProfileClick}
          handleProfileChecked={props.handleProfileChecked}
          selectedProfiles={props.selectedProfiles}
        ></ListContainer>
      )}
    </TabPanel>
    <TabPanel value={props.tabIndex} index={3} dir={props.theme.direction}>
      {props.view === "card" ? (
        <CardContainer
          userName={props.currentUserName}
          container={AppConstants.DASHBOARD_TYPE.ADMIN}
          handleOpenDialog={props.handleOpenDialog}
          profileList={props.profilesToDisplay}
          handleProfileStatusChange={props.handleProfileStatusChange}
          getLeagueTextByValue={props.getLeagueTextByValue}
          handleEditProfileClick={props.handleEditProfileClick}
          handleViewProfileClick={props.handleViewProfileClick}
          handleProfileChecked={props.handleProfileChecked}
          selectedProfiles={props.selectedProfiles}
          handleDeleteProfile={props.handleDeleteProfile}
        ></CardContainer>
      ) : (
        <ListContainer
          userName={props.currentUserName}
          container={AppConstants.DASHBOARD_TYPE.ADMIN}
          profileList={props.profilesToDisplay}
          handleOpenDialog={props.handleOpenDialog}
          handleProfileStatusChange={props.handleProfileStatusChange}
          getLeagueTextByValue={props.getLeagueTextByValue}
          handleEditProfileClick={props.handleEditProfileClick}
          handleViewProfileClick={props.handleViewProfileClick}
          handleProfileChecked={props.handleProfileChecked}
          selectedProfiles={props.selectedProfiles}
        ></ListContainer>
      )}
    </TabPanel>
    <TabPanel value={props.tabIndex} index={4} dir={props.theme.direction}>
      {props.view === "card" ? (
        <CardContainer
          userName={props.currentUserName}
          container={AppConstants.DASHBOARD_TYPE.ADMIN}
          handleOpenDialog={props.handleOpenDialog}
          profileList={props.profilesToDisplay}
          handleProfileStatusChange={props.handleProfileStatusChange}
          getLeagueTextByValue={props.getLeagueTextByValue}
          handleEditProfileClick={props.handleEditProfileClick}
          handleViewProfileClick={props.handleViewProfileClick}
          handleProfileChecked={props.handleProfileChecked}
          selectedProfiles={props.selectedProfiles}
          handleDeleteProfile={props.handleDeleteProfile}
        ></CardContainer>
      ) : (
        <ListContainer
          userName={props.currentUserName}
          profileList={props.profilesToDisplay}
          container={AppConstants.DASHBOARD_TYPE.ADMIN}
          handleOpenDialog={props.handleOpenDialog}
          handleProfileStatusChange={props.handleProfileStatusChange}
          getLeagueTextByValue={props.getLeagueTextByValue}
          handleEditProfileClick={props.handleEditProfileClick}
          handleViewProfileClick={props.handleViewProfileClick}
          handleProfileChecked={props.handleProfileChecked}
          selectedProfiles={props.selectedProfiles}
        ></ListContainer>
      )}
    </TabPanel>
    <TabPanel value={props.tabIndex} index={5} dir={props.theme.direction}>
      {props.view === "card" ? (
        <CardContainer
          userName={props.currentUserName}
          container={AppConstants.DASHBOARD_TYPE.ADMIN}
          handleOpenDialog={props.handleOpenDialog}
          profileList={props.profilesToDisplay}
          handleProfileStatusChange={props.handleProfileStatusChange}
          getLeagueTextByValue={props.getLeagueTextByValue}
          handleEditProfileClick={props.handleEditProfileClick}
          handleViewProfileClick={props.handleViewProfileClick}
          handleProfileChecked={props.handleProfileChecked}
          selectedProfiles={props.selectedProfiles}
          handleDeleteProfile={props.handleDeleteProfile}
        ></CardContainer>
      ) : (
        <ListContainer
          userName={props.currentUserName}
          profileList={props.profilesToDisplay}
          container={AppConstants.DASHBOARD_TYPE.ADMIN}
          handleOpenDialog={props.handleOpenDialog}
          handleProfileStatusChange={props.handleProfileStatusChange}
          getLeagueTextByValue={props.getLeagueTextByValue}
          handleEditProfileClick={props.handleEditProfileClick}
          handleViewProfileClick={props.handleViewProfileClick}
          handleProfileChecked={props.handleProfileChecked}
          selectedProfiles={props.selectedProfiles}
        ></ListContainer>
      )}
    </TabPanel>
    {/* {(props.showUserPanel && props.showUserPanel == true) &&  */}
    <TabPanel value={props.tabIndex} index={6} dir={props.theme.direction}>
      {props.view === "card" ? (
        <CardContainer
          userName={props.currentUserName}
          container={AppConstants.DASHBOARD_TYPE.ADMIN}
          handleOpenDialog={props.handleOpenDialog}
          profileList={props.groupUsers}
          handleProfileStatusChange={props.handleProfileStatusChange}
          getLeagueTextByValue={props.getLeagueTextByValue}
          handleEditProfileClick={props.handleEditProfileClick}
          handleViewProfileClick={props.handleViewProfileClick}
          handleProfileChecked={props.handleProfileChecked}
          selectedProfiles={props.selectedProfiles}
          handleDeleteProfile={props.handleDeleteProfile}
        ></CardContainer>
      ) : (
        <ListContainer
          userName={props.currentUserName}
          profileList={props.groupUsers}
          container={AppConstants.DASHBOARD_TYPE.ADMIN}
          handleOpenDialog={props.handleOpenDialog}
          handleProfileStatusChange={props.handleProfileStatusChange}
          getLeagueTextByValue={props.getLeagueTextByValue}
          handleEditProfileClick={props.handleEditProfileClick}
          handleViewProfileClick={props.handleViewProfileClick}
          handleProfileChecked={props.handleProfileChecked}
          selectedProfiles={props.selectedProfiles}
        ></ListContainer>
      )}
    </TabPanel>

  </SwipeableViews>

  )
}



export default AdminCoachesTabPanel;