import React, { useState, useEffect, useContext } from "react";
import { Prompt } from "react-router-dom";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import Divider from "@material-ui/core/Divider";
import Stepper from "@material-ui/core/Stepper";
import Typography from "@material-ui/core/Typography";
import Loader from '../../../sharedComponents/Loader/Loader';
import AppConstants from "../../../common/AppConstants";
import PersonalDetails from "../../coachProfileEdit/sections/PersonalDetails";
import ProfileVideos from "../../coachProfileEdit/sections/ProfileVideos";
import CoachingAndOtherExperience from "../../coachProfileEdit/sections/CoachingAndOtherExperience";
import PlayingExperience from "../../coachProfileEdit/sections/PlayingExperience";
import AdditionalExperience from "../../coachProfileEdit/sections/AdditionalExperience";
import Skills from "../../coachProfileEdit/sections/Skills";
import EducationalDetails from "../../coachProfileEdit/sections/EducationalDetails";
import Other from "../../coachProfileEdit/sections/Other";
import { AdminCoachProfileContext } from "../../../common/AdminCoachProfileContext";
import { AppDataContext } from "../../../common/AppContext";
import PersonalDetailsValidationSchema from "../../../validationSchema/personalDetailsValidationSchema";
import otherValidationSchema from "../../../validationSchema/otherValidationSchema";
import skillValidationSchema from "../../../validationSchema/skillValidationSchema";
import { coachProfileService, authenticationService } from "../../../services";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import DialogContentText from "@material-ui/core/DialogContentText";
import APIURLConstants from "../../../common/ApiURLConstants";
import { Grid } from "@material-ui/core";
import SnackbarMsg from "../../../sharedComponents/Snackbar/Snackbar";
import { cacheStorage } from '../../../helper';
import ProfileSubmitValidations from "../../coachProfileEdit/ProfileSubmitValidations";
import Helper from "../../../common/Helper";
import EditProfileValidationDialog from "../../../sharedComponents/dialog/EditProfileValidationDialog";
import useTelemetry from "../../../utils/useTelemetry"

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    color: theme.palette.text.secondary,
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexShrink: 0,
    [theme.breakpoints.up("sm")]: {
      flexBasis: "85%",
    },
    [theme.breakpoints.down("xs")]: {
      flexBasis: "85%",
    },
    cursor: "pointer",
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  itemIcon: {
    [theme.breakpoints.up("sm")]: {
      minWidth: "45px",
    },
    [theme.breakpoints.down("xs")]: {
      minWidth: "35px",
    },
  },
  expansionPanelSummary: {
    [theme.breakpoints.down("xs")]: {
      padding: "0 16px 0 16px",
    },
  },
  expansionPanelDetails: {
    [theme.breakpoints.down("xs")]: {
      paddingTop: theme.spacing(1),
      paddingRight: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      paddingLeft: theme.spacing(1),
    },
  },
  profileCompleteness: {
    color: theme.palette.text.secondary,
  },
  divider: {
    marginTop: theme.spacing(2),
    width: "100%",
  },
  submitButton: {
    alignContent: "right",
    textAlign: "right",
    paddingBottom: 10,
    paddingRight: 10,
  }
}));

export default function ProfileDetailsEdit(props) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [changesMade, setChangesMade] = React.useState(false);
  const { trackCustomEvents } = useTelemetry();

  const [showTermsAndConditions, setShowTermsAndConditions] = useState(false);

  const {
    fullName,
    roleAndCoachingPhilosophy,
    profilePhoto,
    profileVideo,
    mediaAndArticle,
    coachingVideos,
    coachingExperience,
    other,
    additionalExperience,
    skillSet,
    educationDetail,
    profileVideos,
    profileCompleteness,
    updateProfile,
    coachingAndOtherJobExperience,
    playingExperience,
    updatePersonalDetails,
    updateOtherDescription,
    updateSkillSet,
    updateEducationDetails,
    updateProfileVideosDetails,
    updateAdditionalExperience,
    updatePlayingExperience,
    updateCoachingAndOtherJobExperience,
    userName,
    status,
    updateStatus,
    retrieveAndSetProfileContext,
  } = useContext(AdminCoachProfileContext);

  useEffect(() => {
    retrieveAndSetProfileContext(props.params);
  }, [])

  const {
    schools,
    skills,
    language,
    teamList,
    headCoachesList,
    eventList,
    positionTitle,
    coachTitle,
    degrees,
    awards,
    notablePlayers,
    leagueOptionsList,
  } = useContext(AppDataContext);
  const [expanded, setExpanded] = React.useState("panel1");
  ////////////////////// Personal Data State ///////////////////
  const [fullNameState, setfullNameState] = useState(fullName);
  const [profilePhotoState, setProfilePhotoState] = useState(profilePhoto);
  const [profileVideoState, setProfileVideoState] = useState(profileVideo);

  const [statusState, setStatusState] = useState(status);
  const [philosophyState, setphilosophyState] = useState(
    roleAndCoachingPhilosophy
  );
  const [showSubmitProfileConfirmation, setShowSubmitProfileConfirmation] = useState(false);
  const [videoUrlState, setVideoUrlState] = useState(
    coachingVideos && coachingVideos.externalLinks ? coachingVideos.externalLinks : []);
  const [mediaAndArticleState, setMediaAndArticle] = useState(
    mediaAndArticle && mediaAndArticle.externalLinks ? mediaAndArticle.externalLinks : []);

  useEffect(() => {
    setfullNameState(fullName);
    setProfilePhotoState(profilePhoto);
    setProfileVideoState(profileVideo);
    setphilosophyState(roleAndCoachingPhilosophy);
    setVideoUrlState(
      coachingVideos && coachingVideos.externalLinks
        ? coachingVideos.externalLinks
        : []
    );
    setMediaAndArticle(
      mediaAndArticle && mediaAndArticle.externalLinks
        ? mediaAndArticle.externalLinks
        : []
    );
  }, [
    fullName,
    roleAndCoachingPhilosophy,
    profilePhoto,
    profileVideo,
    mediaAndArticle,
    coachingVideos,
  ]);

  useEffect(() => {
    CompareDataifChangesMade();
  }, [
    fullNameState,
    profilePhotoState,
    profileVideoState,
    philosophyState,
    videoUrlState,
    mediaAndArticleState,
  ]);

  function stripHtml(html) {
    let tmp = document.createElement("DIV");
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || "";
  }

  function CompareDataifChangesMade() {
    let deepequal = require("deep-equal");
    let equalFullName = deepequal(fullNameState, fullName);
    let equalprofilePhoto = deepequal(profilePhotoState, profilePhoto);
    let equalprofileVideo = deepequal(profileVideoState, profileVideo);
    let equalphilosophyState = deepequal(
      stripHtml(philosophyState),
      stripHtml(roleAndCoachingPhilosophy)
    );
    let equalvideoUrlState = deepequal(
      videoUrlState,
      coachingVideos && coachingVideos.externalLinks
        ? coachingVideos.externalLinks
        : []
    );
    let equalmediaAndArticleState = deepequal(
      mediaAndArticleState,
      mediaAndArticle && mediaAndArticle.externalLinks
        ? mediaAndArticle.externalLinks
        : []
    );
    if (
      equalFullName &&
      equalprofilePhoto &&
      equalprofileVideo &&
      equalphilosophyState &&
      equalvideoUrlState &&
      equalmediaAndArticleState
    )
      setChangesMade(false);
    else setChangesMade(true);
  }

  const toggleTermsAndCondition = (event, value) => {
    event.preventDefault();
    setShowTermsAndConditions(value);
  };

  const onFullnameChange = (name, value) => {
    setfullNameState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const onPhilosophyChange = (name, value) => {
    setphilosophyState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const onVideoUrlChange = (videoLinks) => {
    setIsInValid(false);
    setVideoUrlState(videoLinks);
  };

  const onMediaLinkChange = (mediaLinks) => {
    setIsInValid(false);
    setMediaAndArticle(mediaLinks);
  };
  const onProfilePhotoChange = (profilePhotoImage) => {
    cacheStorage.removeProfilePhotoFromLocalStorage(userName);
    uploadFile(
      profilePhotoImage,
      APIURLConstants.SAVE_PROFILE_PHOTO,
      updateProfilePhotoState
    );
  };

  const onProfileVideoChange = (profileVideo) => {
    uploadFile(
      profileVideo,
      APIURLConstants.SAVE_PROFILE_VIDEO,
      updateProfileVideoState
    );
  };

  const updateProfilePhotoState = (imageUrl) => {
    if (imageUrl) {
      var profilePhoto = {
        photoURL: imageUrl,
      };
      setProfilePhotoState(profilePhoto);
    }
  };

  const updateProfileVideoState = (videoUrl) => {
    if (videoUrl) {
      var profileVideo = {
        videoURL: videoUrl,
      };
      setProfileVideoState(profileVideo);
    }
  };

  const uploadFile = (file, url, updateState) => {
    setLoading(true);
    let form = new FormData();
    form.append("files", file);
    coachProfileService
      .uploadProfileAssets(form)
      .then((imageUrl) => {
        updateState(imageUrl);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const onProfilePhotoRemove = () => {
    var profilePhoto = {};
    setProfilePhotoState(profilePhoto);
  };
  const onProfileVideoRemove = () => {
    let videoData = {};
    setProfileVideoState(videoData);
  };
  /////////////////////////////////////Personal Detail section 1 State Over////////////////////////////////////////
  ////////////////////////////////////////Coaching And Other Jon Experience Section 2 State///////////////////////
  const [headCoachExperience, setHeadCoachExperience] = useState(
    coachingAndOtherJobExperience &&
      coachingAndOtherJobExperience.headCoachExperience
      ? coachingAndOtherJobExperience.headCoachExperience
      : []
  );

  const [skillExperience, setSkillExperience] = useState(
    coachingAndOtherJobExperience &&
      coachingAndOtherJobExperience.skillExperience
      ? coachingAndOtherJobExperience.skillExperience
      : []
  );

  const [assistantCoachExperience, setAssistantCoachExperience] = useState(
    coachingAndOtherJobExperience &&
      coachingAndOtherJobExperience.assistantCoachExperience
      ? coachingAndOtherJobExperience.assistantCoachExperience
      : []
  );

  const [awardsAndAllocadesCoaching, setAwardsAndAllocadesCoaching] = useState(
    coachingAndOtherJobExperience &&
      coachingAndOtherJobExperience.awardsAndAllocades
      ? coachingAndOtherJobExperience.awardsAndAllocades
      : []
  );

  const [championshipCoaching, setchampionshipCoaching] = useState(
    coachingAndOtherJobExperience && coachingAndOtherJobExperience.championship
      ? coachingAndOtherJobExperience.championship
      : []
  );

  const [notablePlayer, setNotablePlayer] = useState(
    coachingAndOtherJobExperience && coachingAndOtherJobExperience.notablePlayer
      ? coachingAndOtherJobExperience.notablePlayer
      : []
  );

  const [taskInfo, setTaskInfo] = useState(
    coachingAndOtherJobExperience && coachingAndOtherJobExperience.taskInfo
      ? coachingAndOtherJobExperience.taskInfo
      : ""
  );

  useEffect(() => {
    setHeadCoachExperience(
      coachingAndOtherJobExperience &&
        coachingAndOtherJobExperience.headCoachExperience
        ? coachingAndOtherJobExperience.headCoachExperience
        : []
    );

    setSkillExperience(
      coachingAndOtherJobExperience &&
        coachingAndOtherJobExperience.skillExperience
        ? coachingAndOtherJobExperience.skillExperience
        : []
    );

    setAssistantCoachExperience(
      coachingAndOtherJobExperience &&
        coachingAndOtherJobExperience.assistantCoachExperience
        ? coachingAndOtherJobExperience.assistantCoachExperience
        : []
    );

    setAwardsAndAllocadesCoaching(
      coachingAndOtherJobExperience &&
        coachingAndOtherJobExperience.awardsAndAllocades
        ? coachingAndOtherJobExperience.awardsAndAllocades
        : []
    );

    setchampionshipCoaching(
      coachingAndOtherJobExperience &&
        coachingAndOtherJobExperience.championship
        ? coachingAndOtherJobExperience.championship
        : []
    );

    setNotablePlayer(
      coachingAndOtherJobExperience &&
        coachingAndOtherJobExperience.notablePlayer
        ? coachingAndOtherJobExperience.notablePlayer
        : []
    );

    setTaskInfo(
      coachingAndOtherJobExperience && coachingAndOtherJobExperience.taskInfo
        ? coachingAndOtherJobExperience.taskInfo
        : ""
    );
  }, [coachingAndOtherJobExperience]);

  useEffect(() => {
    compareCoachingAndOtherJobExp();
  }, [
    headCoachExperience,
    skillExperience,
    assistantCoachExperience,
    awardsAndAllocadesCoaching,
    championshipCoaching,
    notablePlayer,
    taskInfo,
  ]);

  function compareCoachingAndOtherJobExp() {
    let deepequal = require("deep-equal");
    let equalheadCoachExperience = deepequal(
      headCoachExperience,
      coachingAndOtherJobExperience.headCoachExperience
    );
    let equalassistantCoachExperience = deepequal(
      assistantCoachExperience,
      coachingAndOtherJobExperience.assistantCoachExperience
    );
    let equalawardsAndAllocades = deepequal(
      awardsAndAllocadesCoaching,
      coachingAndOtherJobExperience.awardsAndAllocades
    );
    let equalchampionship = deepequal(
      championshipCoaching,
      coachingAndOtherJobExperience.championship
    );
    let equalskillExperience = deepequal(
      skillExperience,
      coachingAndOtherJobExperience.skillExperience
    );
    let equalnotablePlayer = deepequal(
      notablePlayer,
      coachingAndOtherJobExperience.notablePlayer
    );
    let equaltaskInfo = deepequal(
      taskInfo,
      coachingAndOtherJobExperience.taskInfo
    );
    if (
      equalheadCoachExperience &&
      equalassistantCoachExperience &&
      equalskillExperience &&
      equalawardsAndAllocades &&
      equalnotablePlayer &&
      equalchampionship &&
      equaltaskInfo
    )
      setChangesMade(false);
    else setChangesMade(true);
  }

  const onChangeAwardsAndAllocadesCoaching = (data) => {
    setIsInValid(false);
    setAwardsAndAllocadesCoaching(data);
  };

  const handleTaskInfoChange = (name, value) => {
    setIsInValid(false);
    setTaskInfo(value);
  };

  const onChangeNotablePlayer = (data) => {
    setIsInValid(false);
    setNotablePlayer(data);
  };

  const onChampionshipChangeCoaching = (data) => {
    setIsInValid(false);
    setchampionshipCoaching(data);
  };

  const onHeadCoachingExperienceChange = (data) => {
    setIsInValid(false);
    setHeadCoachExperience(data);
  };

  const onSkillExperienceChange = (data) => {
    setIsInValid(false);
    setSkillExperience(data);
  };

  const onAssistantCoachingExperienceChange = (data) => {
    setIsInValid(false);
    setAssistantCoachExperience(data);
  };

  ////////////////////////////////////////Coaching And Other Jon Experience Section 2 State Over///////////////////////

  ///////////////////////////////////////// Playing Experience state Section 3////////////////////////////////////////

  const [formerExperience, setformerExperience] = useState(
    playingExperience && playingExperience.formerExperience
      ? playingExperience.formerExperience
      : []
  );

  const [championship, setchampionship] = useState(
    playingExperience && playingExperience.championship
      ? playingExperience.championship
      : []
  );

  const [awardsAndAllocades, setAwardsAndAllocades] = useState(
    playingExperience && playingExperience.awardsAndAllocades
      ? playingExperience.awardsAndAllocades
      : []
  );

  useEffect(() => {
    setformerExperience(
      playingExperience && playingExperience.formerExperience
        ? playingExperience.formerExperience
        : []
    );
    setchampionship(
      playingExperience && playingExperience.championship
        ? playingExperience.championship
        : []
    );
    setAwardsAndAllocades(
      playingExperience && playingExperience.awardsAndAllocades
        ? playingExperience.awardsAndAllocades
        : []
    );
  }, [playingExperience]);

  useEffect(() => {
    comparePlayingExperience();
  }, [formerExperience, championship, awardsAndAllocades]);

  function comparePlayingExperience() {
    let deepequal = require("deep-equal");
    let equalformerExperience = deepequal(
      formerExperience,
      playingExperience.formerExperience
    );
    let equalawardsAndAllocades = deepequal(
      awardsAndAllocades,
      playingExperience.awardsAndAllocades
    );
    let equalchampionship = deepequal(
      championship,
      playingExperience.championship
    );
    if (equalformerExperience && equalawardsAndAllocades && equalchampionship)
      setChangesMade(false);
    else setChangesMade(true);
  }

  const onFormerExperienceChange = (data) => {
    setformerExperience(data);
  };

  const onChampionshipChange = (data) => {
    setchampionship(data);
  };

  const onChangeAwardsAndAllocades = (data) => {
    setAwardsAndAllocades(data);
  };
  ///////////////////////////////////////// Playing Experience state Section 3 Over////////////////////////////////////////
  ////////////////////////////////////////////// Additinal Experience State Section 4 ////////////
  const [eventDetail, setEventDetail] = useState(
    additionalExperience && additionalExperience.eventDetail
      ? additionalExperience.eventDetail
      : []
  );

  // const [availableEvents, setAvailableEvents] = useState(
  //   eventDetail ?
  //   eventDetail.filter(x => {x.events.Name = (eventList.map(p => p.Name)) ? x.events.Name : ""; return x;})
  //   : []
  // );

  // useEffect(() => {
  //   var updatedEvents = eventDetail && eventList.length > 0 ?
  //     eventDetail.filter(x => {
  //       x.events.Name = (eventList.map(p => p.Name)) ?
  //         x.events.Name : ""; return x;
  //     })
  //     : [];
  //   setAvailableEvents(updatedEvents);
  // }, [eventDetail, eventList]
  // );


  const [charitableExperience, setCharitableExperience] = useState(
    additionalExperience && additionalExperience.charitableExperience
      ? additionalExperience.charitableExperience
      : ""
  );

  const handleCharityInformation = (data) => {
    setCharitableExperience(data);
  };

  useEffect(() => {
    setEventDetail(
      additionalExperience && additionalExperience.eventDetail
        ? additionalExperience.eventDetail
        : []
    );
    setCharitableExperience(
      additionalExperience && additionalExperience.charitableExperience
        ? additionalExperience.charitableExperience
        : ""
    );
  }, [additionalExperience]);

  useEffect(() => {
    compareAdditionalDetail();
  }, [eventDetail, charitableExperience]);

  function compareAdditionalDetail() {
    let deepequal = require("deep-equal");
    let equalEvent = deepequal(
      eventDetail,
      additionalExperience && additionalExperience.eventDetail
        ? additionalExperience.eventDetail
        : []
    );
    let equalCharitableExp = deepequal(
      charitableExperience,
      additionalExperience && additionalExperience.charitableExperience
        ? additionalExperience.charitableExperience
        : ""
    );
    if (equalEvent && equalCharitableExp) setChangesMade(false);
    else setChangesMade(true);
  }

  const handleEventInputChange = (data, mode) => {
    let eventDetails = [];
    let length = data.length - 1;
    if (mode === "onchange") {
      length = data.length;
    }
    for (let i = 0; i < length; i++) {
      eventDetails.push({
        id: data[i].id,
        events: {
          Id: data[i].eventName.value,
          Name: data[i].eventName.label,
        },
        experience: data[i].experience.value,
      });
    }
    setEventDetail(eventDetails);
  };

  ////////////////////////////////////////////// Additinal Experience State Section 4 Over ////////////

  ////////////////////////////////////////////// Skill State Section 5 ////////////

  const [coachingSkillState, setCoachingSkillState] = useState(
    skillSet && skillSet.coachingSkill ? skillSet.coachingSkill : []
  );

  const [languageSkillState, setLanguageSkill] = useState(
    skillSet && skillSet.languageSkill ? skillSet.languageSkill : []
  );
  const [coordinatorState, setCoordinator] = useState(
    skillSet && skillSet.coordinator ? skillSet.coordinator : ""
  );
  const [coachPhilosophyAndSkillState, setCoachPhilosophyAndSkill] = useState(
    skillSet && skillSet.coachPhilosophyAndSkill
      ? skillSet.coachPhilosophyAndSkill
      : ""
  );

  // const [availableSkills, setAvailableSkills] = useState(coachingSkillState.filter(x => skills.map(p => p.Name)));
  // const [availableLanguages, setAvailableLanguages] = useState(languageSkillState);
  //  //useState(languageSkillState.filter(x => language.map(p => p.Name).includes(x)));

  // useEffect(() => {
  //   setAvailableSkills(
  //     coachingSkillState ?
  //       coachingSkillState.filter(x => skills.map(p => p.Name)) : []
  //   );
  // }, [coachingSkillState, skills]
  // )

  // useEffect(() => {
  //   setAvailableLanguages(
  //     languageSkillState ?
  //     languageSkillState: []
  //    // languageSkillState.filter(x => language.map(p => p.Name).includes(x)) : []
  //   );
  // }, [languageSkillState,language]
  // )

  useEffect(() => {
    setCoachingSkillState(
      skillSet && skillSet.coachingSkill ? skillSet.coachingSkill : []
    );
    setLanguageSkill(
      skillSet && skillSet.languageSkill ? skillSet.languageSkill : []
    );
    setCoordinator(
      skillSet && skillSet.coordinator ? skillSet.coordinator : ""
    );
    setCoachPhilosophyAndSkill(
      skillSet && skillSet.coachPhilosophyAndSkill
        ? skillSet.coachPhilosophyAndSkill
        : ""
    );
  }, [skillSet]);

  useEffect(() => {
    compareSkill();
  }, [
    coachingSkillState,
    languageSkillState,
    coordinatorState,
    coachPhilosophyAndSkillState,
  ]);

  function compareSkill() {
    let deepequal = require("deep-equal");
    let equalSkill = deepequal(coachingSkillState, skillSet.coachingSkill);
    let equalLanguage = deepequal(languageSkillState, skillSet.languageSkill);
    let equalCoordinator = deepequal(coordinatorState, skillSet.coordinator);
    let equalCoachPhilosophy = deepequal(
      coachPhilosophyAndSkillState,
      skillSet.coachPhilosophyAndSkill
    );
    if (equalSkill && equalLanguage && equalCoordinator && equalCoachPhilosophy)
      setChangesMade(false);
    else setChangesMade(true);
  }

  const oncoachingSkillChange = (coachingSkill) => {
    setCoachingSkillState(coachingSkill);
  };

  const onLanguageSkillChange = (languages) => {
    setLanguageSkill(languages);
  };

  const onCoordinatorChange = (Coordinator) => {
    setCoordinator(Coordinator);
  };

  const onsetCoachPhilosophyAndSkillChange = (formData) => {
    setCoachPhilosophyAndSkill(formData);
  };

  /////////////////////////////// Skill Section 5 Over /////////////////////////////////

  /////////////////////////////////////Educatinal Detaile section 6 State ////////////////////////////////////////

  const [educationDetailData, seteducationDetailData] = useState(
    educationDetail ? educationDetail : []
  );
  // const [availableDegrees, setAvailableDegrees] = useState(
  //   educationDetailData ?
  //   educationDetailData.filter(x => {x.degree.Name = (degrees.map(p => p.Name))?x.degree.Name : "" ; return x;}) : []  
  // );

  useEffect(() => {
    seteducationDetailData(educationDetail ? educationDetail : []);
  }, [educationDetail]);

  // useEffect(() => {
  //   setAvailableDegrees(
  //     educationDetailData ?
  //     educationDetailData.filter(x => {x.degree.Name = (degrees.map(p => p.Name))?x.degree.Name : "" ; return x;}) : []  
  //     );
  // }, [educationDetailData, degrees]
  // );

  useEffect(() => {
    compareEducationalDetal();
  }, [educationDetailData]);

  function compareEducationalDetal() {
    let deepequal = require("deep-equal");
    let equal = deepequal(educationDetailData, educationDetail);
    if (equal) setChangesMade(false);
    else setChangesMade(true);
  }

  const handleEducatinalDetalInputChange = (data, mode) => {
    let eduDetail = [];
    let length = data.length - 1;
    if (mode === "onchange") {
      length = data.length;
    }
    for (let i = 0; i < length; i++) {
      eduDetail.push({
        id: data[i].id,
        minor_major: data[i].minor_major.value,
        // category: {
        //   Id: data[i].category.value,
        //   category: data[i].category.lable,
        // },
        degree: { Id: data[i].degree.value, Name: data[i].degree.lable },
        university: data[i].university.value,
      });
    }
    seteducationDetailData(eduDetail);
  };

  /////////////////////////////////////Educatinal Detaile section 6 State Over////////////////////////////////////////

  ////////////////////////////////////// Profile Videos Section ////////////////////////////////////////////

  const [profileVideosState, setProfileVideosState] = useState(profileVideos ? profileVideos : []);

  useEffect(() => {
    setProfileVideosState(profileVideos ? profileVideos : []);
  }, [profileVideos])

  const handelProfileVideosOnChange = (data) => {
    setIsInValid(false);
    setProfileVideosState(data);
  }

  useEffect(() => {
    compareProfileVideoDetal();
  }, [profileVideosState])


  function compareProfileVideoDetal() {
    let deepequal = require("deep-equal");
    let equal = deepequal(profileVideosState, profileVideos);
    if (equal) setChangesMade(false);
    else setChangesMade(true);
  }



  const onProfileVideoChange2 = (profileVideo, id) => {
    // uploadFile2(
    //   profileVideo,
    //   id
    // );
  };

  const updateProfileVideoState2 = (videoUrl, obj) => {
    var data = profileVideosState;
    let itemIndex = -1;
    if (data && data.length > 0) {
      itemIndex = data.findIndex(x => x.id == obj.id);
    }
    if (itemIndex > -1) {
      data.forEach(element => {
        if (element.id == obj.id) {
          element.videoURL = videoUrl;
        }
      });
    }
    else {
      obj.videoURL = videoUrl;
      data.push(obj);
    }
    setProfileVideosState(data);
  };

  const uploadFile2 = (file, id) => {
    setLoading(true);
    let form = new FormData();
    form.append("files", file);
    coachProfileService
      .uploadProfileAssets(form)

      .then((imageUrl) => {
        updateProfileVideoState2(imageUrl, id);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };



  ///////////////////////////////////// Profile Videos Section Over /////////////////////////////////////////

  /////////////////////////////////////////////////// Other Detail Section State ////////////////////

  const [addittionalData, setaddittionalData] = useState(other);

  useEffect(() => {
    setaddittionalData(other);
  }, [other]);

  const onOtherDetailChange = (name, value) => {
    setaddittionalData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  useEffect(() => {
    CompareOther();
  }, [addittionalData]);

  function CompareOther() {
    let deepequal = require("deep-equal");
    let equalOther = deepequal(addittionalData, other);
    if (equalOther) setChangesMade(false);
    else setChangesMade(true);
  }

  ////////////////////////////////////////////////////////////////Other Detail Section Over///////

  // const handleChange = (panel) => (event, isExpanded) => {
  //   setExpanded(isExpanded ? panel : false);
  // };

  const onPersonalDetailChange = () => {
    if (ValidatePersonalDetail()) {
      let updatedPersonalDetails = {
        fullName: fullNameState,
        profilePhoto: profilePhotoState,
        profileVideo: profileVideoState,
        roleAndCoachingPhilosophy: philosophyState,
        mediaAndArticle: { externalLinks: mediaAndArticleState },
        coachingVideos: { externalLinks: videoUrlState },
      };
      setChangesMade(false);
      setIsLoading(true);
      coachProfileService
        .updatePersonalDetails(updatedPersonalDetails, userName)
        .then((data) => {
          ShowSnacBar("success");
          updatePersonalDetails(data);
          // authenticationService.updateName(updatedPersonalDetails.fullName.firstName, updatedPersonalDetails.fullName.lastName);
          //remove photo from local storage if blank
          if (!updatedPersonalDetails.profilePhoto || !updatedPersonalDetails.profilePhoto.photoURL) {
            cacheStorage.removeProfilePhotoFromLocalStorage(userName);
          }
        }, error => { ShowSnacBar("error"); })
        .catch((err) => { ShowSnacBar("error"); });
    } else {
      return false;
    }
    return true;
  };

  const [showError, setShowError] = useState(false);
  let [errorMessage, setErrorMessage] = useState("");

  const ValidatePersonalDetail = () => {
    let regex = /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/;
    let isValid = true;
    if (fullNameState.firstName == "" || fullNameState.lastName == "") {
      isValid = false;
      ShowSnacBar("error");
    }
    if (mediaAndArticleState.length > 0) {
      mediaAndArticleState.forEach((element) => {
        let test = regex.test(element.url);
        if (!test) {
          isValid = false;
          setIsInValid(true);
          setErrorMessage("Invalid URL in media and articles.");
        }
        else { setIsInValid(false); }
      });
    }
    if (videoUrlState.length > 0) {
      videoUrlState.forEach((element) => {
        let test = regex.test(element.url);
        if (!test) {
          isValid = false;
          setIsInValid(true);
          setErrorMessage("Invalid URL in video urls.");
        }
      });
    }
    if (philosophyState.description == "" || philosophyState.description == undefined || philosophyState.description == null) {
      isValid = false;
      ShowSnacBar("error");
    }
    if (!profilePhotoState.photoURL || profilePhotoState.photoURL == "") {
      isValid = false;
      ShowSnacBar("error");
    }
    if (philosophyState && philosophyState.description != null) {
      let stringWithoutHtmlTags = Helper.RemoveTags(philosophyState.description);
      if (stringWithoutHtmlTags.length > 180) {
        isValid = false;
        setIsInValid(true);
        setErrorMessage("Please describe your approach to coaching in 180 characters or less.");
      }
    }


    if (!isValid) { setShowError(true); setSeverity("error"); }
    return isValid;
  };

  // default value for useTitleSubCategory is set to false
  const validateExperience = (dataList, useHeadCoach, useTitle, useTitleSubCategory = false) => {
    let valid = true;
    if (dataList && dataList.length > 0) {
      dataList.forEach(element => {
        if (element.experience && element.experience.length > 0) {
          element.experience.forEach(data => {
            if (
              (data.team.teamId === "" || data.team.teamId === 0) ||
              (data.team.teamName === "") ||
              (data.fromDate === null || data.fromDate == "") ||
              (data.toDate === null || data.toDate == "") ||
              (useHeadCoach && data.headCoachName.length === 0) ||
              (useTitle && (data.title.Id === "" || data.title.Id == 0 || data.title.Name == "")) 
              // || (data.titleSubCategory === null || useTitleSubCategory && element.league === 'nba' && (data.titleSubCategory?.Id === "" || data.titleSubCategory?.Id == 0 || data.titleSubCategory?.Name == ""))
              //it checks if data.titleSubCategory is null or useTitleSubCategory is true and any of the properties of data.titleSubCategory
            ) {
              valid = false;
            }
          });
        }
      });
    }
    return valid;
  }

  const validateChampionsheepExperience = (dataList) => {
    let valid = true;
    if (dataList && dataList.length > 0) {
      dataList.forEach(element => {
        if (element.experience && element.experience.length > 0) {
          element.experience.forEach(data => {
            if ((data.team.teamId === "" || data.team.teamId === 0) ||
              (data.season === null || data.season == "")) {
              valid = false;
            }
          });
        }
      });
    }
    return valid;
  }

  const validateCoachingAndOtherJobExperience = () => {
    let valid = true;
    valid = validateExperience(headCoachExperience, false, false);
    if (!valid) return valid;
    valid = validateExperience(assistantCoachExperience, true, true, true);
    if (!valid) return valid;
    valid = validateExperience(skillExperience, true, true, true);
    if (!valid) return valid;
    valid = validateChampionsheepExperience(championshipCoaching);
    if (!valid) return valid;
    valid = validateAwardsAndAccolades(awardsAndAllocadesCoaching);
    return valid;
  }

  const validatePlayingExperience = () => {
    let valid = true;
    valid = validateExperience(formerExperience, true, false);
    if (!valid) return valid;
    valid = validateAwardsAndAccolades(awardsAndAllocades);
    if (!valid) return valid;
    valid = validateChampionsheepExperience(championship);
    return valid;
  }

  const validateAwardsAndAccolades = (awards) => {
    let valid = true;
    let s = awardsAndAllocadesCoaching;
    awardsAndAllocadesCoaching.forEach(e => {
      if (e.award.Name == "") {
        valid = false;
      }
    });
    return valid;
  }


  const handleOnChangecoachingAndOtherJobExperience = () => {
    let championship = championshipCoaching;
    let awardsAndAllocades = awardsAndAllocadesCoaching;
    setChangesMade(false);
    let coachingExperience = {
      headCoachExperience,
      assistantCoachExperience,
      skillExperience,
      championship,
      awardsAndAllocades,
      notablePlayer,
      taskInfo,
    };
    let valid = validateCoachingAndOtherJobExperience(coachingExperience)
    if (valid) {
      console.log(valid)


      if (assistantCoachExperience) {
        // titleSubCategory entries null if from assistantCoachExperience if titleSubCategory is 0
        const updatedAssistantCoachExperience = assistantCoachExperience.map(exp => {
          const updatedExperience = exp.experience.map(item => {
            if (item.titleSubCategory && (item.titleSubCategory.Id === 0 || item.titleSubCategory.Id === "") && item.titleSubCategory.Name === "") {
              return { ...item, titleSubCategory: null };
            }
            return item;
          });
          return { ...exp, experience: updatedExperience };
        });

        // Update coachingExperience with filtered assistantCoachExperience
        coachingExperience = {
          ...coachingExperience,
          assistantCoachExperience: updatedAssistantCoachExperience
        };
      }

      if (skillExperience) {
        // titleSubCategory entries null if from skillExperience if titleSubCategory is 0
        const updatedSkillExperience = skillExperience.map(exp => {
          const updatedSkills = exp.experience.map(item => {
            if (item.titleSubCategory && (item.titleSubCategory.Id === 0 || item.titleSubCategory.Id === "") && item.titleSubCategory.Name === "") {
              return { ...item, titleSubCategory: null };
            }
            return item;
          });
          return { ...exp, experience: updatedSkills };
        });

        // Update skillExperience with filtered skillExperience
        coachingExperience = {
          ...coachingExperience,
          skillExperience: updatedSkillExperience
        };
      }

      setIsLoading(true);
      coachProfileService
        .updateCoachingAndJobExperience(coachingExperience, userName)
        .then((data) => {
          ShowSnacBar("success");
          updateCoachingAndOtherJobExperience(data);
        }, error => { ShowSnacBar("error"); })
        .catch((err) => { ShowSnacBar("error"); });
    }
    else {
      ShowSnacBar("error");
    }
  }

  const onChangePlayingExperience = () => {
    setChangesMade(false);
    let dataPlayingExperience = {
      formerExperience,
      championship,
      awardsAndAllocades,
    };

    if (validatePlayingExperience()) {
      setIsLoading(true);
      coachProfileService
        .updatePlayingExperience(dataPlayingExperience, userName)
        .then((data) => {
          ShowSnacBar("success");
          updatePlayingExperience(dataPlayingExperience, userName);
        }, error => { ShowSnacBar("error"); })
        .catch((err) => { ShowSnacBar("error"); });
    } else {
      ShowSnacBar("error");
    }
  }

  const onAdditionalExperienceChange = () => {
    setChangesMade(false);
    setIsLoading(true);
    let additionalExperienceDetails = { eventDetail, charitableExperience };
    let validEvents = true;
    eventDetail.forEach(e => {
      if (e.events.Name == "") {
        validEvents = false;
      }
    });

    if (validEvents == false) {
      ShowSnacBar("error");
    }
    else {
      coachProfileService
        .updateAdditionalExperience(additionalExperienceDetails, userName)
        .then((data) => {
          ShowSnacBar("success");
          updateAdditionalExperience(additionalExperienceDetails, userName);
        }, error => { ShowSnacBar("error"); })
        .catch((err) => { ShowSnacBar("error"); });
    }
  }

  const ValidateSkillExperience = () => {
    let valid = true;
    if (!coachingSkillState || coachingSkillState.length == 0)
      valid = false;
    return valid;
  }

  const onSkillsDetailChange = () => {
    setChangesMade(false);
    let skillDetailChange = {
      coachingSkill: coachingSkillState,
      languageSkill: languageSkillState,
      coordinator: coordinatorState,
      coachPhilosophyAndSkill: coachPhilosophyAndSkillState,
    };
    let valid = ValidateSkillExperience();
    if (valid) {
      setIsLoading(true);
      coachProfileService
        .updateSkills(skillDetailChange, userName)
        .then((data) => {
          ShowSnacBar("success");
          updateSkillSet(skillDetailChange);
        }, error => { ShowSnacBar("error"); })
        .catch((err) => { ShowSnacBar("error"); });
    }
    else {
      ShowSnacBar("error");
    }
  }

  const onEducationDetailChange = () => {
    let educationDetails = { ...educationDetailData };
    let isValid = true;
    educationDetailData.forEach(d => {
      if (d.degree && d.degree.Name == "") {
        isValid = false;
      }
    })

    if (isValid == false) {
      ShowSnacBar("error");
    }
    else {
      setChangesMade(false);
      setIsLoading(true);
      coachProfileService
        .updateEducationalDetails(educationDetails, userName)
        .then((data) => {
          ShowSnacBar("success");
          updateEducationDetails(educationDetails);
        }, error => { ShowSnacBar("error"); })
        .catch((err) => { ShowSnacBar("error"); });
    }
  }

  const onProfileVideosDetailChange = () => {
    let profileVideosDetails = profileVideosState;
    let isValid = true;
    if (profileVideosDetails.length > 0) {
      profileVideosDetails.forEach(d => {
        if (!d.videoURL || d.videoURL == "") {
          isValid = false;
        }
      })
    }

    if (isValid == false) {
      ShowSnacBar("error");
    }
    else {
      setChangesMade(false);
      setIsLoading(true);
      coachProfileService
        .updateProfileVideosDetails(profileVideosDetails, userName)
        .then((data) => {
          ShowSnacBar("success");
          updateProfileVideosDetails(profileVideosDetails);
        }, error => { ShowSnacBar("error"); })
        .catch((err) => { ShowSnacBar("error"); });
    }
    trackCustomEvents("EditEvent", { action: "Media" });
  }


  const saveOtherDetailChange = () => {
    let updateOtherDetail = { other: addittionalData };
    setChangesMade(false);
    setIsLoading(true);
    coachProfileService
      .updateOtherDetails(updateOtherDetail, userName)
      .then((data) => {
        ShowSnacBar("success");
        updateOtherDescription(updateOtherDetail);
      }, error => { ShowSnacBar("error"); })
      .catch((err) => {
        ShowSnacBar("error");
      });
  }

  const [severity, setSeverity] = useState("error");
  const [isInValid, setIsInValid] = useState(false);
  const ShowSnacBar = (mode) => {
    let messageToShow = "";
    if (mode == "error") {
      setSeverity("error");
      messageToShow = "Please complete missing fields highlighted in red.";
      setIsInValid(true);
    }
    if (mode == "success") { setSeverity("success"); messageToShow = "Data saved successfully."; setIsInValid(false); }
    if (mode == "submit") { setSeverity("success"); messageToShow = "Thank you for submitting your profile"; setIsInValid(false); }
    setIsLoading(false);
    setErrorMessage(messageToShow);
    setShowError(true);
  }

  const [activeStep, setActiveStep] = React.useState(0);
  const [openDiscardDialog, setOpenDiscardDialog] = React.useState(false);
  const [prevStep, setPrevStep] = React.useState(0);

  const DialogContent = withStyles((theme) => ({
    root: {
      padding: theme.spacing(2),
    },
  }))(MuiDialogContent);

  const DialogActions = withStyles((theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(1),
    },
  }))(MuiDialogActions);

  const handleStepClick = (index) => {
    if (prevStep != index) {
      if (changesMade) setOpenDiscardDialog(true);
      else setActiveStep(index);
    } else {
      setActiveStep(index);
    }
    setPrevStep(index);
  };

  const discardChanges = () => {
    setfullNameState(fullName);
    setProfilePhotoState(profilePhoto);
    setProfileVideoState(profileVideo);
    setphilosophyState(roleAndCoachingPhilosophy);
    setVideoUrlState(
      coachingVideos && coachingVideos.externalLinks
        ? coachingVideos.externalLinks
        : []
    );
    setMediaAndArticle(
      mediaAndArticle && mediaAndArticle.externalLinks
        ? mediaAndArticle.externalLinks
        : []
    );

    setHeadCoachExperience(
      coachingAndOtherJobExperience &&
        coachingAndOtherJobExperience.headCoachExperience
        ? coachingAndOtherJobExperience.headCoachExperience
        : []
    );

    setSkillExperience(
      coachingAndOtherJobExperience &&
        coachingAndOtherJobExperience.skillExperience
        ? coachingAndOtherJobExperience.skillExperience
        : []
    );

    setAssistantCoachExperience(
      coachingAndOtherJobExperience &&
        coachingAndOtherJobExperience.assistantCoachExperience
        ? coachingAndOtherJobExperience.assistantCoachExperience
        : []
    );

    setAwardsAndAllocadesCoaching(
      coachingAndOtherJobExperience &&
        coachingAndOtherJobExperience.awardsAndAllocades
        ? coachingAndOtherJobExperience.awardsAndAllocades
        : []
    );

    setchampionshipCoaching(
      coachingAndOtherJobExperience &&
        coachingAndOtherJobExperience.championship
        ? coachingAndOtherJobExperience.championship
        : []
    );

    setNotablePlayer(
      coachingAndOtherJobExperience &&
        coachingAndOtherJobExperience.notablePlayer
        ? coachingAndOtherJobExperience.notablePlayer
        : []
    );

    setTaskInfo(
      coachingAndOtherJobExperience && coachingAndOtherJobExperience.taskInfo
        ? coachingAndOtherJobExperience.taskInfo
        : ""
    );

    setformerExperience(
      playingExperience && playingExperience.formerExperience
        ? playingExperience.formerExperience
        : []
    );
    setchampionship(
      playingExperience && playingExperience.championship
        ? playingExperience.championship
        : []
    );
    setAwardsAndAllocades(
      playingExperience && playingExperience.awardsAndAllocades
        ? playingExperience.awardsAndAllocades
        : []
    );

    setEventDetail(
      additionalExperience && additionalExperience.eventDetail
        ? additionalExperience.eventDetail
        : []
    );
    setCharitableExperience(
      additionalExperience && additionalExperience.charitableExperience
        ? additionalExperience.charitableExperience
        : ""
    );

    setCoachingSkillState(
      skillSet && skillSet.coachingSkill ? skillSet.coachingSkill : []
    );
    setLanguageSkill(
      skillSet && skillSet.languageSkill ? skillSet.languageSkill : []
    );
    setCoordinator(
      skillSet && skillSet.coordinator ? skillSet.coordinator : ""
    );
    setCoachPhilosophyAndSkill(
      skillSet && skillSet.coachPhilosophyAndSkill
        ? skillSet.coachPhilosophyAndSkill
        : ""
    );
    seteducationDetailData(educationDetail ? educationDetail : []);
    setProfileVideosState(profileVideos ? profileVideos : []);
    setaddittionalData(other);
  };

  const handleDiscardClose = (mode) => {
    if (mode === "Yes") {
      setActiveStep(prevStep);
      setChangesMade(false);
      discardChanges();
    } else if (mode == "Save") {
      let isSaved = true;
      if (activeStep == 1) {
        handleOnChangecoachingAndOtherJobExperience();
      } else if (activeStep == 2) {
        onChangePlayingExperience();
      } else if (activeStep == 3) {
        onAdditionalExperienceChange();
      } else if (activeStep == 4) {
        onSkillsDetailChange();
      } else if (activeStep == 5) {
        onEducationDetailChange();
      } else if (activeStep == 6) {
        onProfileVideosDetailChange();
      }
      else if (activeStep == 7) {
        saveOtherDetailChange();
      } else if (activeStep == 0) {
        isSaved = onPersonalDetailChange();
      }
      if (isSaved) {
        setActiveStep(prevStep);
        setChangesMade(false);
      } else {
        setPrevStep(activeStep);
      }
    } else {
      setPrevStep(activeStep);
    }
    setOpenDiscardDialog(false);
  };

  // const handleNextStepClick = (index) => {
  //   setActiveStep(index + 1);
  // };

  const handlePreveStepClick = (index) => {
    handleStepClick(index);
  };

  const [btnSubmit, setBtnSubmit] = useState(true);
  const handleTermsandCondition = (event) => {
    ////// To Do Terms Save
    if (event.target.checked) {
      setBtnSubmit(false);
    } else {
      setBtnSubmit(true);
    }
  };

  const [validationDialogOpen, setValidationDialogOpen] = useState(false);
  const [validationMessageList, setValidationMessageList] = useState([]);

  const confirmSubmit = () => {
    let validationMessage = [];
    validationMessage = ProfileSubmitValidations.ValidateProfile(fullName, roleAndCoachingPhilosophy, profilePhoto, profileVideo, mediaAndArticle, coachingVideos,
      coachingExperience, other, additionalExperience, skillSet, educationDetail, coachingAndOtherJobExperience, playingExperience)
    if (validationMessage.length == 0) {
      setShowSubmitProfileConfirmation(true);
    }
    else {
      setValidationDialogOpen(true);
      setValidationMessageList(validationMessage);
    }
  }

  const closeSubmitProfileConfirmationDialog = () => {
    setShowSubmitProfileConfirmation(false);
  }

  const handleSubmitClick = () => {
    setShowSubmitProfileConfirmation(false);
    coachProfileService.submitProfile(props.params)
      .then(status => {
        ShowSnacBar("submit");
        updateStatus(AppConstants.PROFILE_STATUS.SUBMITTED);
        setStatusState(AppConstants.PROFILE_STATUS.SUBMITTED);
      });
  };
  useEffect(() => {
    setStatusState(status);
  }, [status]);
  return (
    <div className={classes.root}>
      <Prompt
        when={changesMade}
        message={JSON.stringify({
          confirmText: "Yes, Leave this page",
          messageText: "You have unsaved changes that will be lose if you navigate away from this page. Are you sure you want to leave this page?",
          cancelText: "Stay on this Page",
        })}
      />
      <Stepper activeStep={activeStep} orientation="vertical">
        <Step key="0">
          <StepLabel onClick={() => handleStepClick(0)}>
            <Typography color="primary" className={classes.heading}>
              {AppConstants.EDIT_SECTION_TITLE.PersonalDetails}
            </Typography>
          </StepLabel>
          <StepContent>
            <PersonalDetails
              data={{
                fullNameState,
                profilePhotoState,
                profileVideoState,
                philosophyState,
                videoUrlState,
                mediaAndArticleState,
              }}
              userName={userName}
              handlePreveStepClick={handlePreveStepClick}
              validationSchema={PersonalDetailsValidationSchema}
              // onChangesMade={handelonChangesMade}
              onFullnameChange={onFullnameChange}
              onPhilosophyChange={onPhilosophyChange}
              onProfilePhotoChange={onProfilePhotoChange}
              onProfilePhotoRemove={onProfilePhotoRemove}
              onProfileVideoChange={onProfileVideoChange}
              onProfileVideoRemove={onProfileVideoRemove}
              onMediaLinkChange={onMediaLinkChange}
              onVideoUrlChange={onVideoUrlChange}
              onChange={onPersonalDetailChange}
              isLoading={isLoading}
              isInValid={isInValid}
            />
            <Divider className={classes.divider}></Divider>
          </StepContent>
        </Step>
        <Step key="1">
          <StepLabel onClick={() => handleStepClick(1)}>
            <Typography color="primary" className={classes.heading}>
              {AppConstants.EDIT_SECTION_TITLE.CoachingExp}
            </Typography>
          </StepLabel>
          <StepContent>
            <CoachingAndOtherExperience
              handlePreveStepClick={() => handlePreveStepClick(0)}
              headCoachExperience={headCoachExperience}
              assistantCoachExperience={assistantCoachExperience}
              skillExperience={skillExperience}
              championship={championshipCoaching}
              awardsAndAllocades={awardsAndAllocadesCoaching}
              notablePlayer={notablePlayer}
              taskInfo={taskInfo}
              teamList={teamList}
              headCoachesList={headCoachesList}
              awards={awards}
              notablePlayers={notablePlayers}
              positionTitle={positionTitle}
              coachTitle={coachTitle}
              onHeadCoachingExperienceChange={onHeadCoachingExperienceChange}
              onAssistantCoachingExperienceChange={
                onAssistantCoachingExperienceChange
              }
              onSkillExperienceChange={onSkillExperienceChange}
              onChampionshipChange={onChampionshipChangeCoaching}
              onChangeAwardsAndAllocades={onChangeAwardsAndAllocadesCoaching}
              onChangeNotablePlayer={onChangeNotablePlayer}
              handleTaskInfoChange={handleTaskInfoChange}
              onChange={handleOnChangecoachingAndOtherJobExperience}
              isLoading={isLoading}
              leagueOptionsList={leagueOptionsList}
              isInValid={isInValid}
            ></CoachingAndOtherExperience>
            <Divider className={classes.divider}></Divider>
          </StepContent>
        </Step>
        <Step key="2">
          <StepLabel onClick={() => handleStepClick(2)}>
            <Typography color="primary" className={classes.heading}>
              {AppConstants.EDIT_SECTION_TITLE.PlayingExp}
            </Typography>
          </StepLabel>
          <StepContent>
            <PlayingExperience
              teamList={teamList}
              headCoachesList={headCoachesList}
              formerExperience={formerExperience}
              championship={championship}
              awardsAndAllocades={awardsAndAllocades}
              onFormerExperienceChange={onFormerExperienceChange}
              onChampionshipChange={onChampionshipChange}
              onChangeAwardsAndAllocades={onChangeAwardsAndAllocades}
              awards={awards}
              onChange={onChangePlayingExperience}
              handlePreveStepClick={() => handlePreveStepClick(1)}
              isLoading={isLoading}
              leagueOptionsList={leagueOptionsList}
              isInValid={isInValid}
            ></PlayingExperience>
            <Divider className={classes.divider}></Divider>
          </StepContent>
        </Step>
        <Step key="3">
          <StepLabel onClick={() => handleStepClick(3)}>
            <Typography color="primary" className={classes.heading}>
              {AppConstants.EDIT_SECTION_TITLE.AdditionalExp}
            </Typography>
          </StepLabel>
          <StepContent>
            <AdditionalExperience
              eventList={eventList}
              eventDetail={eventDetail}
              charitableExperience={charitableExperience}
              onChange={onAdditionalExperienceChange}
              handleEventInputChange={handleEventInputChange}
              handleCharityInformation={handleCharityInformation}
              handlePreveStepClick={() => handlePreveStepClick(2)}
              isLoading={isLoading}
            ></AdditionalExperience>
            <Divider className={classes.divider}></Divider>
          </StepContent>
        </Step>

        <Step key="4">
          <StepLabel onClick={() => handleStepClick(4)}>
            <Typography color="primary" className={classes.heading}>
              {AppConstants.EDIT_SECTION_TITLE.Skills}
            </Typography>
          </StepLabel>
          <StepContent>
            <Skills
              data={skillSet}
              validationSchema={skillValidationSchema}
              onChange={onSkillsDetailChange}
              handlePreveStepClick={() => handlePreveStepClick(3)}
              skills={skills}
              language={language}
              coachingSkillState={coachingSkillState}
              languageSkillState={languageSkillState}
              coordinatorState={coordinatorState}
              coachPhilosophyAndSkillState={coachPhilosophyAndSkillState}
              oncoachingSkillChange={oncoachingSkillChange}
              onLanguageSkillChange={onLanguageSkillChange}
              onCoordinatorChange={onCoordinatorChange}
              onsetCoachPhilosophyAndSkillChange={onsetCoachPhilosophyAndSkillChange}
              isLoading={isLoading}
              isInValid={isInValid}
            ></Skills>
            <Divider className={classes.divider}></Divider>
          </StepContent>
        </Step>

        <Step key="5">
          <StepLabel onClick={() => handleStepClick(5)}>
            <Typography color="primary" className={classes.heading}>
              {AppConstants.EDIT_SECTION_TITLE.EducationalDetails}
            </Typography>
          </StepLabel>
          <StepContent>
            <EducationalDetails
              data={educationDetailData}
              handleEducatinalDetalInputChange={handleEducatinalDetalInputChange}
              degrees={degrees}
              schools={schools}
              onChange={onEducationDetailChange}
              handlePreveStepClick={() => handlePreveStepClick(4)}
              isLoading={isLoading}
            ></EducationalDetails>
            <Divider className={classes.divider}></Divider>
          </StepContent>
        </Step>


        <Step key="6">
          <StepLabel onClick={() => handleStepClick(6)}>
            <Typography color="primary" className={classes.heading}>
              {AppConstants.EDIT_SECTION_TITLE.Videos}
            </Typography>
          </StepLabel>
          <StepContent>
            <ProfileVideos
              data={profileVideosState}
              //userName={userName}              
              onChange={handelProfileVideosOnChange}
              onProfileVideoChange={onProfileVideoChange2}
              onProfileVideoRemove={updateProfileVideoState2}
              handlePreveStepClick={() => handlePreveStepClick(5)}
              onSave={onProfileVideosDetailChange}
              //validationSchema={PersonalDetailsValidationSchema}
              // onChangesMade={handelonChangesMade}
              // onFullnameChange={onFullnameChange}
              // onPhilosophyChange={onPhilosophyChange}
              // onProfilePhotoChange={onProfilePhotoChange}
              // onProfilePhotoRemove={onProfilePhotoRemove}
              // onProfileVideoChange={onProfileVideoChange}
              // onProfileVideoRemove={onProfileVideoRemove}
              // onMediaLinkChange={onMediaLinkChange}
              // onVideoUrlChange={onVideoUrlChange}
              // onChange={onPersonalDetailChange}
              isLoading={isLoading}
              isInValid={isInValid}
              showBackButton={true}
              showSaveButton={false}

            />
          </StepContent>
        </Step>

        <Step key="7">
          <StepLabel onClick={() => handleStepClick(7)}>
            <Typography color="primary" className={classes.heading}>
              {AppConstants.EDIT_SECTION_TITLE.Other}
            </Typography>
          </StepLabel>
          <StepContent>
            <Other
              data={addittionalData}
              onOtherDetailChange={onOtherDetailChange}
              validationSchema={otherValidationSchema}
              onChange={saveOtherDetailChange}
              handlePreveStepClick={() => handlePreveStepClick(5)}
              isLoading={isLoading}
            ></Other>
            <Divider className={classes.divider}></Divider>
          </StepContent>
        </Step>
      </Stepper>
      {(statusState && (statusState == AppConstants.PROFILE_STATUS.REGISTERED ||
        statusState == AppConstants.PROFILE_STATUS.DENIED)) &&
        <Grid container className={classes.submitButton}>
          <Grid item md={4} xs={4}></Grid>
          <Grid item md={8} xs={8}>
            {/* <Checkbox
              name="acceptTerms"
              onChange={handleTermsandCondition}
              title="Accept Terms And Condition"
            />
          Accept &nbsp;
          <Link href="#" onClick={(e) => toggleTermsAndCondition(e, true)}>
              Terms and Conditions
          </Link> */}
            {/* {profileCompleteness < 60 &&
              <CaptionMsg message={AppConstants.PROFILECOMPLETENESS_VALIDATION_MESSAGE} />} */}
            &nbsp; &nbsp;
            <Button
              onClick={confirmSubmit}
              color="primary"
              variant="contained"
            // disabled={profileCompleteness < 60}
            >
              Submit Profile
            </Button>
          </Grid>
        </Grid>
      }

      <SnackbarMsg
        showError={showError}
        onClose={() => setShowError(false)}
        severity={severity}
        msg={errorMessage}
      />
      {/* ///////////////Dialog For Discard Changes //////////////////////////  */}

      <Dialog
        open={openDiscardDialog}
        onClose={() => handleDiscardClose("No")}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Do you want to save the changes?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleDiscardClose("Save")} color="primary">
            Save
          </Button>
          <Button
            onClick={() => handleDiscardClose("Yes")}
            color="primary"
            autoFocus
          >
            Discard
          </Button>
        </DialogActions>
      </Dialog>


      <Dialog
        open={showSubmitProfileConfirmation}
        onClose={() => closeSubmitProfileConfirmationDialog()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Please confirm you have saved your work by clicking the "Save" button at the bottom of the section you are working in before submitting your profile
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => closeSubmitProfileConfirmationDialog()}
            color="primary"
            autoFocus>
            Return to page to save changes
          </Button>
          <Button onClick={() => handleSubmitClick()} color="secondary">
            Submit Profile
          </Button>
        </DialogActions>
      </Dialog>


      <EditProfileValidationDialog validationMessageList={validationMessageList} validationDialogOpen={validationDialogOpen} onClose={() => setValidationDialogOpen(false)} />
      {/* <TermsAndCondition showTermsAndConditions={showTermsAndConditions} onClose={() => setShowTermsAndConditions(false)} /> */}
      <Loader isLoading={loading} />
    </div>
  );
}