import { useState, useEffect } from 'react';

const usePostForm = (callback, validate) => {
    const [values, setValues] = useState({});
    const [errors, setErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect(() => {
        if (Object.keys(errors).length === 0 && isSubmitting) {
            callback();
        }
    }, [errors]);

    const handleSubmit = () => {
        setErrors(validate(values));
        setIsSubmitting(true);
    };

    const handleChange = (fieldName, fieldValue, isMultiple = false) => {

        setValues((prevValues) => {
            if (!isMultiple) {
                // Handle regular input fields
                return { ...prevValues, [fieldName]: fieldValue };
            } else {
                // Handle checkboxes
                return {
                    ...prevValues,
                    [fieldName]: Array.isArray(prevValues[fieldName])
                        ? prevValues[fieldName].includes(fieldValue)
                            ? prevValues[fieldName].filter((value) => value !== fieldValue)
                            : [...prevValues[fieldName], fieldValue]
                        : [fieldValue],
                };
            }
        });
    };

    const prefillForm = (fields) => {
        setValues((prevValues) => ({ ...prevValues, ...fields }));
    };

    return {
        handleChange,
        handleSubmit,
        prefillForm,
        setValues,
        values,
        errors,
    }
};

export default usePostForm;