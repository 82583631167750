const skillValidationSchema = {
    coachingSkill: {
      required: false,
    },
    languageSkill: {
      required: false,
    },
    coordinator: {
      required: false,
    },
    coachPhilosophyAndSkill: {
      required: false,
    },
  };
  
  export default skillValidationSchema;
  