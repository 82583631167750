import React, {
    Fragment,
    useState,
    useEffect,
    forwardRef,
    useImperativeHandle,
    useContext,
    useRef,
} from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import ToolTip from "@material-ui/core/Tooltip";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import DeleteIcon from "@material-ui/icons/Delete";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Helper from "../../common/Helper";
import CultureInfo from "../../common/CultureInfo";
import MomentUtils from "@date-io/moment";
import moment from "moment";



const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    grid: {
        padding: theme.spacing(1),
        alignSelf: "center",
        display: "flex",
    },
    gridIcons: {
        display: 'flex',
        '& button':{
            [theme.breakpoints.down("xs")]: {
                padding: theme.spacing(.75),
              },
            },
    },
    error: {
        borderColor: theme.palette.error.dark,
    },
    btnDel: {
        color: theme.palette.secondary.main,
    },
    teamListImage: {
        marginRight: theme.spacing(1),
    },
}));
//let allData=[];
const AwardsAndAllocades = (props) => {
    
    let preData = [];
    const [formDataCollection, setFormData] = useState([getEmptyItem()]);

    useEffect(() => {
        preData = props.data;
        let detail = formDataFromProp();
        if (preData.length == 0) {
            detail.push(getEmptyItem());
        }
        setFormData(detail);
    }, [props.data]);


    function formDataFromProp() {
        return props.data.map((item) => {
            return {
                id: item.id,
                awards: {
                    value: item.award,
                    validationMessage: "",
                    hasError: null,
                },
                year: { value: item.year, validationMessage: "", hasError: null },
            };
        });
    }
    function getEmptyItem() {
        let randomId = Helper.GetRandomId();
        return {
            id: randomId,
            awards: {
                value: "",
                validationMessage: "",
                hasError: null,
            },
            year: { value: null, validationMessage: "", hasError: null },
        };
    }

    function handleInputChange(e,hasError) {
        let dataCollection = [...formDataCollection];
        let index = dataCollection.findIndex((x) => x.id === e.itemId);
        dataCollection[index] = e.formData;
        setFormData(dataCollection);
        if (!hasError || hasError == undefined)
            notifyParentAboutDataChange(dataCollection);
    }
    
    const notifyParentAboutDataChange = (newData) => {
        let DataCollection = newData;
        let data = [];

        for (let i = 0; i < DataCollection.length; i++) {
            let formData = DataCollection[i];

            data.push({
                id:formData.id,
                award: formData.awards.value,
                year: formData.year.value,
            });
        }
        preData = data;
        props.onDataChange(data);
    };

    function addItem() {
        //... Add a new item to the formDataCollection array.
        let dataCollection = [...formDataCollection];
        let lastData = dataCollection[dataCollection.length - 1];
        if (lastData.awards.value === "" || lastData.year.value === null) {
            lastData.awards.value === ""
                ? (lastData.awards.hasError = true)
                : (lastData.awards.hasError = false);
            lastData.year.value === null
                ? (lastData.year.hasError = true)
                : (lastData.year.hasError = false);
            handleInputChange({ itemId: lastData.id, formData: lastData }, true);
        } else {
            lastData.awards.hasError = false;
            lastData.year.hasError = false;
            dataCollection.push(getEmptyItem());
            setFormData(dataCollection);
        }
    }
    function removeItem(itemId) {
        //... Remove an item from formDataCollection array.
        let DataCollection = [...formDataCollection];
        let index = DataCollection.findIndex((x) => x.id === itemId);
        DataCollection.splice(index, 1);
        setFormData(DataCollection);
        notifyParentAboutDataChange(DataCollection);
    }

    let awardsList = props.awards ? props.awards.filter(a => a.type == (props.type ? props.type : 'coach')) : [];

    return (
        <Grid container>
            {formDataCollection.map((item, index) => {
                return (
                    <AwardsDetail
                        key={index}
                        addItem={addItem}
                        itemRow={item}
                        onChange={handleInputChange}
                        showAddOption={index === formDataCollection.length - 1}
                        removeItem={removeItem}
                        awardsList={awardsList}
                        type={props.type ? props.type : 'coach'}
                    />
                );
            })}
        </Grid>
    );
};

function AwardsDetail(props) {
    const classes = useStyles();
    const awardSelected = getAwardValue(props.itemRow.awards.value.Name);

    function handleAddClick() {
        props.addItem();
    }
    function handleInputChange(value, name) {
        if (value !== null && (name === "year")) {
            value = moment(value).format("YYYY");
        }
        let formData = props.itemRow;
        formData[name].value = name == 'awards' ? { Name: value, type: props.type, leagueName: 'nba' } : value;
        formData[name].hasError = false;
        //... Call Parent
        props.onChange({ itemId: props.itemRow.id, formData: formData });
    }

    function handelOnKeyUp(e) {
        if (e.target.value != "") {
            var awardExist = {};
            awardExist = props.awardsList.find(x => x.Name === e.target.value)
            if (awardExist === undefined) {
                let formData = props.itemRow;
                formData["awards"].value = { Name: e.target.value, type: props.type, leagueName: 'nba' };
                formData["awards"].hasError = false;
                //... Call Parent
                props.onChange({ itemId: props.itemRow.id, formData: formData });

            }
        }
    }

    function getAwardValue(val) {
        let item = [...props.awardsList];
        let sele = item.find((x) => x.Name == val);
        return sele || { Id: "New", Name: val };
    }

    return (
        <Fragment>
            <Grid item md={6} xs={12} className={classes.grid}>
                <Autocomplete
                    freesolo
                    onClose={handelOnKeyUp}
                    value={awardSelected}
                    id={`team_${props.itemRow.id}`}
                    size="small"
                    options={props.awardsList}
                    getOptionSelected={(option, { multiple, awardSelected }) => {
                        if (!multiple) {
                            if (awardSelected != null) {
                                return option.Name === awardSelected && awardSelected.value && awardSelected.value.Name ? awardSelected.value.Name : '';
                            }
                            return false;
                        }

                        return false;
                    }}
                    
                    getOptionLabel={(option) => option.Name}
                    onChange={(event, newValue) => {
                        let val = newValue !== null ? newValue.Name : "";
                        handleInputChange(val, "awards");
                    }}
                    style={{ width: "100%" }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                            error={(props.itemRow.awards.value == "" || props.itemRow.awards.value.Name == "") && (props.itemRow.year.value != "" || props.itemRow.year.value != null)}
                            //error={props.itemRow.awards.hasError}
                            label={<Typography variant="body2">Awards</Typography>}
                        />
                    )}
                />
            </Grid>
            <Grid item md={4} xs={8} className={classes.grid}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
                    <DatePicker
                        error={props.itemRow.year.hasError}
                        id={`from_${props.itemRow.id}`}
                        label={<Typography variant="body2">Year</Typography>}
                        inputVariant="outlined"
                        format={CultureInfo.GetCultureInfo().year}
                        views={["year"]}
                        fullWidth
                        clearable
                        autoOk
                        disableFuture
                        okLabel=""
                        value={
                            props.itemRow.year.value != null
                                ? moment().year(props.itemRow.year.value)
                                : null
                        }
                        onChange={(value) => handleInputChange(value, "year")}
                        size="small"
                        style={{ width: "100%" }}
                    ></DatePicker>
                </MuiPickersUtilsProvider>


                {/* <TextField
                    id={`season_${props.itemRow.id}`}
                    error={props.itemRow.year.hasError}
                    variant="outlined"
                    value={props.itemRow.year.value}
                    onChange={(e) => handleInputChange(e.target.value, "year")}
                    label={<Typography variant="body2">Year</Typography>}
                    size="small"
                    fullWidth
                /> */}
            </Grid>
            <Grid item md={1} xs={4} className={classes.gridIcons}>
                <ToolTip title="Remove">
                    <IconButton onClick={(e) => props.removeItem(props.itemRow.id)}>
                        <DeleteIcon className={classes.btnDel} >Delete</DeleteIcon>
                    </IconButton>
                </ToolTip>
                {props.showAddOption &&
                    <ToolTip title="Add more">
                        <IconButton onClick={handleAddClick}>
                            <AddCircleIcon color="primary" >Add</AddCircleIcon>
                        </IconButton>
                    </ToolTip>
                }
            </Grid>
        </Fragment>
    );
}
AwardsAndAllocades.defaultProps = {
    data: [],
};

export default AwardsAndAllocades;
