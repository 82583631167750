import React, { useContext, useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, TextField, Button } from "@material-ui/core/";
import useRegistrationForm from "../../sharedComponents/customHooks/useRegistrationForm";
import { UserProfileContext } from "../../common/ProfileContext";
import changeEmailIdValidationSchema from "../../validationSchema/changeEmailIdValidationSchema";
import SnackbarMsg from "../../sharedComponents/Snackbar/Snackbar";
import { userService } from '../../services';
import Loader from "../../sharedComponents/Loader/Loader";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
  },
  grid: {
    display: "flex",
    flexGrow: 1,
    flexWrap: "wrap",
    paddingTop: theme.spacing(1),
    backgroundColor: theme.palette.background.default,
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(1),
    },
    [theme.breakpoints.down("xs")]: {
      padding: 0,
    },
  },
  section: {
    // marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
    textAlign: "left",
    padding: "5px 25px",
  },
  gridItem: {
    [theme.breakpoints.up("sm")]: {
      width: "100%",
      padding: theme.spacing(1),
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      paddingBottom: theme.spacing(2),
    },
  },
  profileSummary: {
    padding: theme.spacing(2),
    minHeight: "300px",
  },
  profileDetails: {
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  sectiondivider: {
    margin: theme.spacing(1),
  },
}));

const ChangeEmailId = (props) => {
  const { email, updateEmailId } = useContext(UserProfileContext);
  const [isLoading, setIsLoading] = useState(false);

  const classes = useStyles();

  const [state, setState] = useState({
    emailId: "",
  });

  const [showError, setShowError] = useState(false);
  const [severity, setSeverity] = useState("");
  const [message, setMessage] = useState("");
  const onSubmitForm = (validationErrors) => {
    //validationErrors: this is hack for now. need to correct it with useeffect
    let errorKeys = Object.keys(validationErrors);
    //submit the form
    if (validationErrors.emailId) {
      showAlert("error", validationErrors.emailId.error); return;
    }

    if (values.emailId != values.confirmEmailId) {
      showAlert("error", "Email and confirm email do not match.");
      return;
    }
    if (errorKeys.length === 1) {
      setIsLoading(true);
      userService.changeEmail(values.emailId)
        .then((data) => {
          values.confirmEmailId = "";
          setIsLoading(false);
          if (!data) {
            showAlert("error","Email already taken");
          }
          else if (typeof data == "string") {
            showAlert("error", data);
          }
          else {
            updateEmailId(values.emailId);
            showAlert("success", "Email Changed Successfully");
          }
        })
        .catch((err) => {
          values.confirmEmailId = "";
          setIsLoading(false);
          showAlert("error", err);
        })
    }
  };


  function showAlert(sever, msg) {
    setShowError(true);
    setSeverity(sever)
    setMessage(msg);

  }


  const handleClose = () => {
    setShowError(false);
    setSeverity("")
    setMessage("");
  }

  const {
    errors,
    handleInputChange,
    handleOnSubmit,
    values,
  } = useRegistrationForm(state, onSubmitForm, changeEmailIdValidationSchema);


  return (

    <div className={classes.section}>
      <Typography variant="subtitle1" color="secondary">
        Update Email
        </Typography>
      <div className={classes.sectiondivider}></div>

      <Typography variant="subtitle1" style={{ paddingLeft: 8, fontWeight: "bold" }}>
        Current Email: {email}
      </Typography>
      <Grid item md={4} sm={12} className={classes.gridItem}>
        <TextField
          id="coachEmailId"
          variant="outlined"
          value={values.emailId}
          size="small"
          name="emailId"
          label="New Email"
          onChange={handleInputChange}
          fullWidth
          error={errors && errors.emailId && errors.emailId.error}
        ></TextField>
        <div className={classes.sectiondivider}></div>
        <TextField
          id="confirmEmailId"
          variant="outlined"
          //defaultValue={values.emailId}
          value={values.confirmEmailId}
          size="small"
          name="confirmEmailId"
          label="Confirm Email"
          onChange={handleInputChange}
          fullWidth
          error={errors && errors.confirmEmailId && errors.confirmEmailId.error}
        ></TextField>

        <div className={classes.sectiondivider}></div>
        <Button
          color="primary"
          variant="contained"
          fullWidth
          onClick={handleOnSubmit}
          disabled={isLoading}
        >
          Submit
          </Button>
      </Grid>
      <SnackbarMsg showError={showError} onClose={handleClose} severity={severity} msg={message} />
      <Loader isLoading={isLoading} />
    </div>

  );
};
export default ChangeEmailId;
