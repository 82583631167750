import React, { useState, useEffect, useRef, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Typography,
  Paper,
  Divider,
  Grid,
  Box,
  CardHeader,
} from "@material-ui/core/";
import ShowHTML from "../../../sharedComponents/HTMLEditor/ShowHTML";
import ShowArrayList from "./subComponent/ShowArrayList";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  card: {
    border: "1px solid gray",
    backgroundColor: "#F3F3F3",
    header: {
      backgroundColor: "#E5ECFC",
      color: "#2D4D89",
    },
  },
  header: {
    backgroundColor: "#E5ECFC",
    color: "#2D4D89",
    fontSize: "12px",
    fontWeight: "bold",
    padding: "3px 0 3px 10px",
  },
  subHeading: {
    backgroundColor: "#63C5DA",
    color: "#1520A6",
    padding: "5px 25px",
  },
  section: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
    textAlign: "left",
  },
  section2: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
    borderLeft: "5px solid lightgray",
    paddingLeft: "20px",
    width: "50%",
  },
  grid: {
    padding: theme.spacing(1),
    alignSelf: "center",
  },
  gridInRow: {
    display: "flex",
    justifyContent: "space-between",
    paddingLeft: "25px",
  },
  notablePlayers: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    width: "95%",
    paddingLeft: "25px",
    lineHeight: "2",
    color: "black",
  },
  teamName: {
    fontWeight: "bold",
    fontSize: "12px",
    color: "black",
  },
}));

const SkillView = (props) => {
  const classes = useStyles();
  const [coachPhilosophyAndSkill, setCoachPhilosophyAndSkill] = useState(
    props.data && props.data.coachPhilosophyAndSkill
      ? props.data.coachPhilosophyAndSkill
      : ""
  );

  const [coordinator, setCoordinator] = useState(
    props.data && props.data.coordinator ? props.data.coordinator : ""
  );

  useEffect(() => {
    setCoachPhilosophyAndSkill(props.data && props.data.coachPhilosophyAndSkill
      ? props.data.coachPhilosophyAndSkill
      : "")
    setCoordinator(props.data && props.data.coordinator ? props.data.coordinator : "");
  }, [props.data])

  return (
    <div className={classes.root}>      
      <ShowArrayList heading="Basketball & Coaching Skills" data={props.data && props.data.coachingSkill ? props.data.coachingSkill : []} />
      <Divider></Divider>      
      <ShowArrayList heading="Language Skills" data={props.data && props.data.languageSkill ? props.data.languageSkill : []} />
      <Divider></Divider>      
      {/* <ShowArrayList heading="Coordinator" data={[coordinator ? coordinator.charAt(0).toUpperCase() + coordinator.slice(1) : '']} />
      <Divider></Divider>       */}
      {/* <ShowArrayList heading="Coaching Philosophy" data={[coachPhilosophyAndSkill]} /> */}
      <ShowHTML heading="Coaching Philosophy" data={coachPhilosophyAndSkill} />
    </div>
  );
};

export default SkillView;
