import Button from '@material-ui/core/Button';
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from '@material-ui/core/styles';
import React, { useContext, useEffect, useState } from 'react';
import { withRouter } from "react-router-dom";
import { AdminCoachProfileContext } from "../../common/AdminCoachProfileContext";
import AppConstants from '../../common/AppConstants';
import { AppDataContext } from "../../common/AppContext";
import RouteConstants from "../../common/RouteConstants";
import { Role } from '../../helper';
import ProfileDetails from '../../screens/coachProfile/ProfileDetails';
import { userService } from "../../services";
import AdminService from "../../services/admin.service";
import RejectDialog from "../../sharedComponents/dialog/RejectDialog";
import ProfileSummary from "../../sharedComponents/profileSummary/ProfileSummary";
import useTelemetry from '../../utils/useTelemetry';

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    dialogPaper: {
        minHeight: '80vh',
        maxHeight: '80vh',
    },
});

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
    },
    grid: {
        display: "flex",
        flexGrow: 1,
        flexWrap: "wrap",
        paddingTop: theme.spacing(1),
        backgroundColor: theme.palette.background.default,
        [theme.breakpoints.up("sm")]: {
            padding: theme.spacing(1),
        },
        [theme.breakpoints.down("xs")]: {
            padding: 0,
        },
    },
    section: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(3),
        textAlign: "left",
        padding: "5px 25px",
    },
    gridItem: {
        [theme.breakpoints.up("sm")]: {
            width: "100%",
            padding: theme.spacing(1),
        },
        [theme.breakpoints.down("xs")]: {
            width: "100%",
            paddingBottom: theme.spacing(2),
        },
    },
    profileSummary: {
        // paddingBottom: theme.spacing(2),
        minHeight: "300px",
        boxShadow: '0px 0px 5px rgba(6, 37, 91, .2)',
        borderRadius: '0',
        backgroundColor: '#06255b',
    },
    profileDetails: {
        textAlign: "left",
        color: theme.palette.text.secondary,
    },
}));



function CoachProfileView(props) {

    const classes = useStyles();

    const {
        fullName,
        roleAndCoachingPhilosophy,
        profilePhoto,
        profileVideo,
        mediaAndArticle,
        coachingVideos,
        coachingExperience,
        other,
        additionalExperience,
        skillSet,
        educationDetail,
        coachingAndOtherJobExperience,
        playingExperience,
        userName,
        retrieveAndSetProfileContext,
        status,
        profileVideos,
        lastModifiedAt,
        jwtTokenDetails
    } = useContext(AdminCoachProfileContext);

    const { getLeagueTextByValue } = useContext(AppDataContext);
    const [data, setData] = useState(props.data && props.data ? props.data : []);
    const [personalDetails, setPersonalDetails] = useState(props.data && props.data.personalDetails ? props.data.personalDetails : []);
    const [showApprove, setShowApprove] = useState(false);
    const [showReject, setShowReject] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [openRejectDialog, setOpenRejectDialog] = useState(false);
    const [role, setRole] = useState("");
    const [videoData, setVideoData] = React.useState({});
    const { trackCustomEvents } = useTelemetry();


    useEffect(() => {
        setRole(userService.getUserRole());
        console.log(props.data)
    }, [])


    function btnRejectClick(note, sendMail) {
        setOpenRejectDialog(false);
        handleProfileStatusChange(props.params, AppConstants.PROFILE_STATUS.DENIED, note, sendMail)
        setShowApprove(true)
        setShowReject(false)
    }

    function handleApproveClick() {
        if (showApprove) {
            handleProfileStatusChange(props.params, AppConstants.PROFILE_STATUS.APPROVED)
            setShowApprove(false)
            setShowReject(true)
        }
        trackCustomEvents("EditEvent", { action: "Approve" });
    }

    const handleProfileStatusChange = (userName, status, note, sendMail) => {
        let postData = { status: status, profileUserName: userName, reason: note, sendEmail: sendMail };
        setIsLoading(true);
        AdminService.changeProfileStatus(postData).then((data) => {
            // setFilterProfile([]);
            setIsLoading(false);
            // updateStatusProfile(data, 1);
        });
    };

    useEffect(() => {
        retrieveAndSetProfileContext(props.params);
    }, [props.params])

    useEffect(() => {
        getShowApprove();
        getShowReject();
    }, [status])

    const handleEditProfileClick = () => {
        props.history.push(RouteConstants.ADMIN_COACH_PROFILE_EDIT + "/" + props.params);
        trackCustomEvents("EditEvent", { action: "Edit Profile" });
    }


    function getShowApprove() {
        if (status.toLowerCase() === AppConstants.PROFILE_STATUS.SUBMITTED ||
            status.toLowerCase() === AppConstants.PROFILE_STATUS.DENIED ||
            status.toLowerCase() === AppConstants.PROFILE_STATUS.RESUBMITTED) {
            setShowApprove(true);
        } else {
            setShowApprove(false);
        }
    }

    function getShowReject() {
        if (status.toLowerCase() === AppConstants.PROFILE_STATUS.SUBMITTED ||
            status.toLowerCase() === AppConstants.PROFILE_STATUS.APPROVED ||
            status.toLowerCase() === AppConstants.PROFILE_STATUS.RESUBMITTED) {
            setShowReject(true);
        } else {
            setShowReject(false);
        }
    }

    //const [profileCompleteness, setProfileCompleteness] = useState(0);
    useEffect(() => {
        setData(props.data ? props.data : []);
        setPersonalDetails(props.data && props.data.personalDetails ? props.data.personalDetails : []);
    }, [props.data])


    return (
        <div>
            <Grid className={classes.grid}>
                <Grid item md={3} sm={3} className={classes.gridItem}>
                    <Paper className={classes.profileSummary}>
                        <ProfileSummary
                            profilePhoto={profilePhoto}
                            profileVideos={profileVideos}
                            fullName={fullName}
                            userName={userName}
                            roleAndCoachingPhilosophy={roleAndCoachingPhilosophy}
                            mediaAndArticle={mediaAndArticle}
                            coachingVideos={coachingVideos}
                            jwtTokenDetails={jwtTokenDetails}
                            profileCompleteness={false}
                            isViewMode={true} isCoachProfile={false}
                            lastModifiedAt={lastModifiedAt}></ProfileSummary>
                    </Paper>
                </Grid>

                <Grid item md={9} sm={9} className={classes.gridItem}>
                    <Paper className={classes.profileDetails}>
                        {/* Other users profile */}
                        <ProfileDetails
                            userName={userName}
                            other={other}
                            additionalExperience={additionalExperience}
                            skillSet={skillSet}
                            educationDetail={educationDetail ? educationDetail : []}
                            coachingAndOtherJobExperience={coachingAndOtherJobExperience}
                            playingExperience={playingExperience}
                            getLeagueTextByValue={getLeagueTextByValue}
                            videoData={profileVideos}
                            coachingVideos={coachingVideos}
                        ></ProfileDetails>
                    </Paper>
                </Grid>
            </Grid>
            {role == Role.Admin && (showApprove || showReject) &&
                <>
                    <Grid className={classes.grid} justify="flex-end">
                        <Button onClick={handleEditProfileClick} variant="contained" color="primary">
                            Edit Profile
                        </Button> &nbsp;&nbsp;
                        <Button disabled={showReject && !showApprove} onClick={handleApproveClick} variant="contained" color="primary">
                            Approve
                        </Button>&nbsp;&nbsp;
                        <Button disabled={showApprove && !showReject} onClick={() => { setOpenRejectDialog(true); trackCustomEvents("EditEvent", { action: "On Hold" }) }} variant="contained" color="secondary">
                            On Hold
                        </Button>
                    </Grid>
                </>}

            <RejectDialog
                open={openRejectDialog}
                onClose={() => setOpenRejectDialog(false)}
                onReject={(note, sendMail) => btnRejectClick(note, sendMail)}
            />
        </div>
    );
}

export default withRouter(CoachProfileView)