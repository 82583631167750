import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import ChipInput from 'material-ui-chip-input';
import Grid from '@material-ui/core/Grid';
import Typography from "@material-ui/core/Typography";
import TitleWithTooltip from "../../sharedComponents/TitleWithTooltip";
import CaptionMsg from "../../sharedComponents/CaptionMsg";

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Button from "@material-ui/core/Button";
import ErrorPopover from "../../sharedComponents/dialog/ErrorPopover";
import { userService } from '../../services';
import adminService from "../../services/admin.service";
import { Role } from '../../helper';
import Loader from "../../sharedComponents/Loader/Loader";
import SnackbarMsg from "../../sharedComponents/Snackbar/Snackbar";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, withStyles } from '@material-ui/core';
import InviteHistoryList from './InviteHistoryList';
import FilterableTable from '../../thirdParty/react-filterable-table/Components/FilterableTable';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    inviteUserForm: {
        // marginTop: theme.spacing(2),
        marginBottom: theme.spacing(3),
        textAlign: "left",
        padding: "24px",
        color: "#000000",
    },
    inviteUserSection: {
        marginBottom: theme.spacing(3),
        textAlign: "left",
        padding: "24px",
        color: "#000000",
        marginTop: 16
    },
    grid: {
        // padding: theme.spacing(1),
        alignSelf: "center",
    },
    question: {
        color: theme.palette.info,
        [theme.breakpoints.down("xs")]: {
            padding: theme.spacing(1),
        },
    },
    questionSubtext: {
        marginLeft: theme.spacing(1),
    },
    sectiondivider: {
        margin: theme.spacing(1),
    },
    customFilterableTable: {
        width: '100%',
        borderCollapse: 'collapse',
        '& th': {
            background: '#316ad8',
            color: '#fff',
        },
        '& th, & td': {
            padding: theme.spacing(.75),
            borderBottom: '1px solid #ccc',
            fontSize: '0.875rem',
        },
        '& th:first-child,  & td:first-child': {
            textAlign: 'left',
            width: '5%'
        },
        '& th:last-child, & td:last-child': {
            textAlign: 'right',
        },
    }
}));

const InviteUser = (props) => {
    const classes = useStyles();
    const [data, setData] = useState("");
    const [role, setRole] = useState("Coach");
    const [isLoading, setIsLoading] = useState(false);
    const [showMessage, setShowMessage] = useState(false);
    const [message, setMessage] = useState("");
    const [severity, setSeverity] = useState("error");
    const [inviteHistory, setInviteHistory] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [filterText, setFilterText] = useState('');

    useEffect(() => {
        getInvitationHistory();
    }, [])

    const getInvitationHistory = () => {
        adminService.invitationHistory().then((inviteHistory) => {
            setInviteHistory(inviteHistory);
        }).catch(error => {
            console.log("Error", error);
        });
    };

    const validateEmail = (list) => {
        let valid = true;
        let regex = /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/;
        let invalidEmails = "";
        if (list.length > 0) {
            list.forEach(element => {
                let validEmail = regex.test(element)
                if (!validEmail) {
                    if (invalidEmails == "")
                        invalidEmails += element
                    else
                        invalidEmails += ", " + element

                    valid = false;
                }
            });
        }
        if (!valid) {
            setSeverity("error");
            setMessage("Enter valid comma separated email(s)")
            setShowMessage(true);
        }
        return valid;
    }

    const handleInviteClick = () => {
        if (!data) return;
        let emailText = data.replace(/,\s*$/, "");
        if (emailText.trim() != "") {
            let list = emailText.trim().split(",").map(function (item) {
                return item.trim().toLowerCase();
            });
            list = list.filter(function (entry) { return entry.trim() != ''; });
            list = Array.from(new Set(list));
            if (validateEmail(list)) {
                if (list.length > 0) {
                    setIsLoading(true);
                    adminService.InviteUser(list, role).then((emailList) => {
                        setIsLoading(false);
                        setData([]);
                        setMessage("Invitation sent successfully to these email(s): " + emailList);
                        setSeverity("success");
                        setShowMessage(true);

                    });
                }
                else {
                    setMessage("Please add emails for invite");
                    setSeverity("error");
                    setShowMessage(true);
                }
            }
        }

    }
    const handleClear = () => {
        setData("");
        //setEmailList([]);
        setRole("Coach");
    }
    const [invalidEmail, setInvalidEmail] = useState(false);
    const [maxEmail, setMaxEmail] = useState(false);
    // const handleInputChange = (value) => {
    //     if (data.length < 50) {
    //         setMaxEmail(false);
    //         let regex = /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/;
    //         let validEmail = regex.test(value)
    //         if (validEmail) {
    //             let values = data;
    //             values.push(value);
    //             setData(values);
    //         }
    //         else { setInvalidEmail(true); }
    //     }
    //     else {
    //         setMaxEmail(true);
    //     }
    // }

    const StyledTableCell = withStyles((theme) => ({
        head: {
            backgroundColor: "#316ad8",
            color: theme.palette.common.white,
        },
        body: {
            fontSize: 14,
        },
    }))(TableCell);

    const fields = [
        { name: 'email', displayName: "Email", inputFilterable: false, sortable: false, },
        {
            name: 'invitedBy', displayName: "Invited By", inputFilterable: true, sortable: true
        },
        {
            name: 'invitationDate', displayName: "Invitation Date", inputFilterable: false, sortable: false
        },
        { name: 'role', displayName: "Role", inputFilterable: true, sortable: true },
        { name: 'status', displayName: "Invitation Status", inputFilterable: true, sortable: true },
    ];

    const handlePageChange = (pageNo) => {
        setCurrentPage(pageNo);
    };

    const handleFilterTextChange = (filterChangedText) => {
        setFilterText(filterChangedText);
        setCurrentPage(0);
    }

    return (
        <div>
            <div className={classes.inviteUserForm}>
                <Typography variant="subtitle1" color="secondary">
                    Invite User(s)
                </Typography>
                <div className={classes.sectiondivider}></div>
                <div>
                    <TitleWithTooltip
                        title="Please add email(s) to Invite."
                        tooltipTitle="Invite_Email_Tooltip"
                        variant="body2"
                        class={classes.question}
                    />
                    <CaptionMsg message="(Add valid email separated by comma, Maximum 50 email(s).)" />
                    <div className={classes.grid}>
                        <Grid container>
                            <Grid item md={12} xs={12} className={classes.grid}>
                                <List>
                                    <ListItem>
                                        <TextareaAutosize
                                            rowsMin={7}
                                            style={{ width: "100%", fontSize: "15px" }}
                                            id="Emails"
                                            aria-label="maximum height"
                                            placeholder="Invite Emails"
                                            value={data}
                                            onChange={e => setData(e.target.value)}
                                        />

                                        {/* <ChipInput
                                        fullWidth={true}
                                        fullWidthInput={true}
                                        id="Emails"
                                        value={data}
                                        label=""
                                        fullWidth
                                        newChipKeys={[',', 'Enter']}
                                        onAdd={(chips) => handleInputChange(chips)}
                                        onKeyDown={() => setInvalidEmail(false)}
                                        variant='outlined'
                                    /> */}
                                        {/* <ErrorPopover target="Emails" isOpen={invalidEmail} for="Email" />
                                    <ErrorPopover target="Emails" isOpen={maxEmail} for="maxEmail" /> */}
                                    </ListItem>
                                </List>
                            </Grid>
                        </Grid>
                    </div>
                    <TitleWithTooltip
                        title="Select Role."
                        tooltipTitle="FOR_SELECT_ROLE"
                        variant="body2"
                        class={classes.question}
                    />
                    <FormControl component="fieldset">
                        <RadioGroup row aria-label="position" name="role" value={role}>
                            <FormControlLabel value="Coach" control={<Radio onClick={() => setRole("Coach")} color="secondary" />} label="Coach" />
                            <FormControlLabel value="FrontOffice" control={<Radio onClick={() => setRole("FrontOffice")} color="secondary" />} label="Front Office" />
                            <FormControlLabel value="FrontOfficePlusCoach" control={<Radio onClick={() => setRole("FrontOfficePlusCoach")} color="secondary" />} label="Front Office/Coach" />
                            {userService.getUserRole() == Role.Admin &&
                                <FormControlLabel value="Admin" control={<Radio onClick={() => setRole("Admin")} color="secondary" />} label="Admin" />}
                        </RadioGroup>
                    </FormControl>

                </div>
                <div className={classes.sectiondivider}></div>
                <div className={classes.sectiondivider}></div>
                <div>

                    <Button onClick={handleClear} variant="contained" color="secondary">
                        Clear
                    </Button> {" "}
                    <Button onClick={handleInviteClick} disabled={isLoading} variant="contained" color="primary" disabled={!data}>
                        Invite
                    </Button>
                </div>
                <Loader isLoading={isLoading} />
                <SnackbarMsg showError={showMessage} onClose={() => setShowMessage(false)} severity={severity} msg={message} />
            </div >
            <div className={[classes.inviteUserSection]}>
                <Typography variant="subtitle1" color="secondary">
                    User Invitation History
                </Typography>
                {
                    inviteHistory && <FilterableTable
                        namespace="Team"
                        initialSort="Name"
                        data={inviteHistory}
                        fields={fields}
                        noRecordsMessage="There is no data available"
                        noFilteredRecordsMessage="No data match your filters!"
                        pageSizes={[12, 24, 48, 72]}
                        topPagerVisible={false}
                        tableClassName={classes.customFilterableTable}
                        maintainPageOnSetData
                        currentPage={currentPage}
                        currentFilterText={filterText}
                        onPageChange={handlePageChange}
                        onFilterTextChange={handleFilterTextChange}
                    />
                }
            </div>
        </div>
    );
};

export default InviteUser;
