import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

function PendingApproval(props) {
    return (
        <SvgIcon {...props}>
            <path d="M14.08 21L2 21C0.91 20.96 0.04 20.09 0 19L0 5C0.04 3.91 0.91 3.04 2 3L22 3C23.09 3.04 23.96 3.91 24 5L24 15.53C23.42 15 22.75 14.61 22 14.34L22 5L2 5L2 19L14.08 19C14.03 19.33 14 19.66 14 20C14 20.34 14.03 20.68 14.08 21ZM14 17L4 17L4 15.75C4 14.09 7.34 13.25 9 13.25C10.66 13.25 14 14.09 14 15.75L14 17ZM14 11L18 11L18 12L14 12L14 11ZM11.5 9.5C11.5 10.87 10.37 12 9 12C7.63 12 6.5 10.87 6.5 9.5C6.5 8.13 7.63 7 9 7C10.37 7 11.5 8.13 11.5 9.5ZM14 9L20 9L20 10L14 10L14 9ZM14 7L20 7L20 8L14 8L14 7ZM24 19.5C24 21.98 21.97 24 19.5 24C17.02 24 15 21.98 15 19.5C15 17.02 17.02 15 19.5 15C21.98 15 24 17.03 24 19.5ZM21.75 20.36L19.72 19.23L19.73 17.25L19.05 17.25L19.05 19.64L21.43 20.94L21.75 20.36Z" />
        </SvgIcon>
    );
}

export default PendingApproval;