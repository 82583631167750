import React, { useState, useEffect } from "react";
import ReactJWPlayer from 'react-jw-player';

import { jwplayerService } from '../../services';

const JWPlayer = (props) => {
    const [fileUrl, setFileUrl] = useState(props.fileURL);
    const [signedUrl, setSignedUrl] = useState('');
    useEffect(() => {
        setFileUrl(props.fileURL);
        if (props.playExternalVideo)
            setSignedUrl(props.fileURL);
        else
            getSignedUrl(props.fileURL);
    }, [props.fileURL]);

    const getSignedUrl = (url) => {
        jwplayerService.getSignedUrlForProfileVideo(props.userName)
            .then(signedUrl => {
                setSignedUrl(signedUrl);
            })
    }

    return (
        <div style={{ width: '100%' }}>
            <ReactJWPlayer
                playerId='my-unique-id'
                playerScript={props.jwPlayersrc}
                licenseKey={props.jwPlayerkey}
                file={signedUrl}
                width="100%"
            />
        </div>
    )
}

export default JWPlayer;