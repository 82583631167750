import React, { useContext, useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Hidden from '@material-ui/core/Hidden';
import nbaLogo from './images/nba_login.png';
import nbca_splash from './images/nbca_splash.png';
import play_store from './images/Play_store.png';
import app_store from './images/App_Store.png';
import { AppDataContext } from "./common/AppContext";


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        justifyContent: 'center',
        alignItems: 'center'
    },
    logoStrip: {
        display: 'flex',
        // flex: 1,
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'start',
        width: '100%',
        height: '150px',
        // paddingTop: '7%'
    },
    logo: {
        [theme.breakpoints.up('sm')]: {
            width: '425px',
            height: '170px'
        },
        [theme.breakpoints.down('md')]: {
            height: '83px',
            width: '260px'
        },
    },
    loginBox: {
        width: '280px',
        position: 'fixed',
        padding: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: theme.spacing(1.5),
        [theme.breakpoints.up('lg')]: {
            right: '10%',
            alignSelf: 'flex-end',
            height: '260px',
        },
        [theme.breakpoints.down('sm')]: {
            alignSelf: 'center',
            height: '350px',
            position: 'static',
            margin: theme.spacing(2),
        },
    },
    sectiondivider: {
        margin: theme.spacing(1)
    },
    nbaLogo: {
        width: '50px',
        paddingLeft: '10.5%',
        paddingRight: '2.5rem',
    },
    nbcaSplashLogo: {
        width: '100px',
        paddingLeft: '30px',
    }
}));

const Discovery = () => {
    const classes = useStyles();
    const {
        androidURL,
        iosURL
    } = useContext(AppDataContext);
   

    if (navigator.userAgent.toLowerCase().indexOf("android") > -1) {
        window.location.href = 'https://play.google.com/store/apps/details?id=com.nba.coachesdb';
    }
    if (navigator.userAgent.toLowerCase().indexOf("iphone") > -1) {
        window.location.href = 'https://apps.apple.com/us/app/nba-coaches-database/id6444841922';
    }

    return (
        <div className={clsx("login-bg", classes.root)}>
            <Hidden smDown>
                <div style={{ flex: 2 }}></div>
            </Hidden>
            <Hidden mdDown>
                <div className={clsx("login-strip-bg", classes.logoStrip)}>
                    <p className={clsx("appTitle")}>NBA Coaches Database</p>
                </div>
            </Hidden>
            <Hidden smDown>
                <div style={{ flex: 2, width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'flex-start', }}>
                    <img src={nbaLogo} className={classes.nbaLogo} />
                    <img src={nbca_splash} className={classes.nbcaSplashLogo} />
                </div>
            </Hidden>
            <Paper className={classes.loginBox} elevation={10}>
                <Hidden lgUp>
                    <div className={clsx("app-logo-small", classes.logo)}></div>
                </Hidden>
                <div style={{ display: 'flex', flexDirection: 'column', alignContent: 'space-between' }}>
                    <div>
                        Enjoy the experience on mobile app.
                    </div>
                    <div className={classes.sectiondivider}></div>
                    <div className={classes.sectiondivider}></div>
                    <div>
                        <a href={androidURL}><img src={play_store}></img></a>
                    </div>
                    <div className={classes.sectiondivider}></div>
                    <div className={classes.sectiondivider}></div>
                    <div>
                        <a href={iosURL}><img src={app_store}></img></a>
                    </div>
                </div>
            </Paper>
        </div>
    )
}

export default Discovery;