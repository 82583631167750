const ResetPasswordValidationSchema = {
    password: {
        required: true,
    },
    confirmPassword: {
        required: true,
    },
};

export default ResetPasswordValidationSchema;
