import React, { useContext, useState } from 'react';
import { AppDataContext } from "../../common/AppContext";
import { UserProfileConsumer, UserProfileContext, UserProfileProvider } from '../../common/ProfileContext';
import Loader from "../../sharedComponents/Loader/Loader";
import ProfileDetails from './ProfileDetails';

export default function CoachProfileMainContainer() {

    return (
        <UserProfileProvider>
            <UserProfileConsumer>
                {props => {
                    return (
                        <ProfileView></ProfileView>
                    )
                }}
            </UserProfileConsumer>
        </UserProfileProvider>
    );
}


function ProfileView() {
    const [isLoading, setIsLoading] = useState(false);

    const {
        other,
        additionalExperience,
        skillSet,
        educationDetail,
        coachingAndOtherJobExperience,
        playingExperience,
        profileVideos,
        userName,
        coachingVideos
    } = useContext(UserProfileContext);

    const { getLeagueTextByValue } = useContext(AppDataContext);

    //... My Profile
    return (
        <>

            <ProfileDetails
                userName={userName}
                other={other}
                additionalExperience={additionalExperience}
                skillSet={skillSet}
                educationDetail={educationDetail}
                coachingAndOtherJobExperience={coachingAndOtherJobExperience}
                getLeagueTextByValue={getLeagueTextByValue}
                playingExperience={playingExperience}
                videoData={profileVideos}
                coachingVideos={coachingVideos}
            />
            <Loader isLoading={isLoading} />
        </>
    )
}