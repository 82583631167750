import React from 'react';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import RouteConstants from '../../common/RouteConstants';
import Hidden from '@material-ui/core/Hidden';

import Typography from '@material-ui/core/Typography';
import BrokenImageTwoToneIcon from '@material-ui/icons/BrokenImageTwoTone';
import Fab from '@material-ui/core/Fab';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';

import './PageNotFoundUI.css';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        flexGrow: 1,
        alignSelf: 'center',
        width: '100%',
        height: '100vh',
        backgroundColor: theme.palette.background.default
    },
    icon: {
        color: theme.palette.primary.light,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: '10rem !important',
        marginBottom: theme.spacing(3),
    },
    subtext: {
        marginTop: theme.spacing(1),
        width: '250px',
        textAlign: 'center',
        color: theme.palette.text.secondary
    },
    fabHome: {
        margin: theme.spacing(1)
    }
}));

const PageNotFoundUI = (props) => {

    const classes = useStyles();

    const gotoHome = () => {
        props.history.push({
            pathname: RouteConstants.COACH_PROFILE_VIEW,
            state: undefined
        });
    }

    return (
        <div className={classes.root}>
            <BrokenImageTwoToneIcon className={classes.icon} />

            <Typography variant="h6" color="primary">Oops!</Typography>
            <Typography variant="body1" className={classes.subtext}>We can't seem to find the page you're looking for.</Typography>

            <br /><br />
            <Fab size="medium" color="primary" aria-label="search"
                className={classes.fabHome} onClick={() => gotoHome()}>
                <HomeOutlinedIcon />
            </Fab>
            <br />

        </div>
    )
}

export default withRouter(PageNotFoundUI);