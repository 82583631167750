import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import SaveIcon from '@material-ui/icons/Save';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
    },
    wrapper: {
        //margin: theme.spacing(1),
        display: 'flex',
        alignItems: 'center'
    },
    buttonProgress: {
        marginRight: theme.spacing(1),
    },
}));

const SaveButton = (props) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <div className={classes.wrapper}>
                {
                    (props.isLoading) &&
                    <CircularProgress size={25} color="secondary" className={classes.buttonProgress} />
                }

                <Button onClick={props.onClick}
                    color="primary" size="small" disabled={props.isLoading || props.disabled}
                    startIcon={<SaveIcon />}>Save</Button>
            </div>
        </div>
    )
}

SaveButton.defaultProps = {
    onClick: () => { },
    isLoading: false
}

export default SaveButton;