import { authHeader, handleResponse, handleError } from '../helper';
import APIHelper from "../common/ApiHelper";
import APIURLConstants from "../common/ApiURLConstants";


export const referenceDataService = {
    getTeams,
    getTitles,
    getTitlesSubCategory,
    getCoachTitles,
    getAwards,
    getPlayers,
    getSchools,
    getSkills,
    getLanguages,
    getDegrees,
    getEvents,
    getHeadCoaches,
    getLeagues,
    getTermsAndConditions,
    getFaqAndTrainingVideo,
    deleteData,
    saveLanguage,
    saveAssistanceCoach,
    saveEvent,
    savePlayer,
    savePositionTitle,
    saveTitlesSubCategory,
    saveSkill,
    saveTeam,
    saveAward,
    saveDegree,
    saveCoach,
    getHeadCoachesByFilter
};

function getTeams() {
    return APIHelper.get(APIURLConstants.GET_TEAMS, authHeader())
        .then(handleResponse)
        .then(data => {
            return data;
        }).catch(handleError);
}

function getTitles() {
    return APIHelper.get(APIURLConstants.GET_POSITION_TITLE, authHeader())
        .then(handleResponse)
        .then(data => {
            return data;
        }).catch(handleError);
}

function getTitlesSubCategory() {
    return APIHelper.get(APIURLConstants.GET_TITLES_SUBCATEGORY, authHeader())
        .then(handleResponse)
        .then(data => {
            return data;
        }).catch(handleError);
}

function getCoachTitles() {
    return APIHelper.get(APIURLConstants.GET_COACH_TITLE, authHeader())
        .then(handleResponse)
        .then(data => {
            return data;
        }).catch(handleError);
}

function getAwards() {
    return APIHelper.get(APIURLConstants.GET_AWARDS, authHeader())
        .then(handleResponse)
        .then(data => {
            return data;
        }).catch(handleError);
}

function getPlayers() {
    return APIHelper.get(APIURLConstants.GET_PLAYERS, authHeader())
        .then(handleResponse)
        .then(data => {
            return data;
        }).catch(handleError);
}

function getSchools() {
    return APIHelper.get(APIURLConstants.GET_SCHOOLS, authHeader())
        .then(handleResponse)
        .then(data => {
            return data;
        }).catch(handleError);
}

function getSkills() {
    return APIHelper.get(APIURLConstants.GET_SKILLS, authHeader())
        .then(handleResponse)
        .then(data => {
            return data;
        }).catch(handleError);
}

function getLanguages() {
    return APIHelper.get(APIURLConstants.GET_LANGUAGE, authHeader())
        .then(handleResponse)
        .then(data => {
            return data;
        }).catch(handleError);
}

function getDegrees() {
    return APIHelper.get(APIURLConstants.GET_DEGREES, authHeader())
        .then(handleResponse)
        .then(data => {
            return data;
        }).catch(handleError);
}

function getEvents() {
    return APIHelper.get(APIURLConstants.GET_EVENT, authHeader())
        .then(handleResponse)
        .then(data => {
            return data;
        }).catch(handleError);
}

function getHeadCoachesByFilter(data) {
    return APIHelper.post(APIURLConstants.GET_COACHES_BY_FILTER, data, authHeader())
        .then(handleResponse)
        .then(data => {
            return data;
        }).catch(handleError);
}

function getHeadCoaches() {
    return APIHelper.get(APIURLConstants.GET_COACHES, authHeader())
        .then(handleResponse)
        .then(data => {
            return data;
        }).catch(handleError);
}

function getLeagues() {
    return APIHelper.get(APIURLConstants.GET_LEAGUES, authHeader())
        .then(handleResponse)
        .then(data => {
            return data;
        }).catch(handleError);
}

function getTermsAndConditions() {
    return APIHelper.get(APIURLConstants.GET_TERMS_AND_CONDITIONS, authHeader())
        .then(handleResponse)
        .then(data => {
            return data;
        }).catch(handleError);
}

function getFaqAndTrainingVideo() {
    return APIHelper.get(APIURLConstants.GET_FAQ, authHeader())
        .then(handleResponse)
        .then(data => {
            return data;
        }).catch(handleError);

}
function deleteData(data) {
    return APIHelper.post(APIURLConstants.DELETE_DATA, data, authHeader())
        .then(handleResponse)
        .then(data => {
            return data
        })
        .catch(handleError);
}
function saveLanguage(postData) {
    return APIHelper.post(APIURLConstants.SAVE_LANGUAGE, postData, authHeader())
        .then(handleResponse)
        .then(data => {
            return data;
        }).catch(handleError);
}

function saveAssistanceCoach(postData) {
    return APIHelper.post(APIURLConstants.SAVE_TILTE, postData, authHeader())
        .then(handleResponse)
        .then(data => {
            return data;
        }).catch(handleError);
}
function saveEvent(postData) {
    return APIHelper.post(APIURLConstants.SAVE_EVENT, postData, authHeader())
        .then(handleResponse)
        .then(data => {
            return data;
        }).catch(handleError);
}
function saveCoach(postData) {
    return APIHelper.post(APIURLConstants.SAVE_COACH, postData, authHeader())
        .then(handleResponse)
        .then(data => {
            return data;
        }).catch(handleError);
}
function saveSkill(postData) {
    return APIHelper.post(APIURLConstants.SAVE_SKILL, postData, authHeader())
        .then(handleResponse)
        .then(data => {
            return data;
        }).catch(handleError);
}
function saveDegree(postData) {
    return APIHelper.post(APIURLConstants.SAVE_DEGREE, postData, authHeader())
        .then(handleResponse)
        .then(data => {
            return data;
        }).catch(handleError);
}
function savePlayer(postData) {
    return APIHelper.post(APIURLConstants.SAVE_PLAYER, postData, authHeader())
        .then(handleResponse)
        .then(data => {
            return data;
        }).catch(handleError);
}
function savePositionTitle(postData) {
    return APIHelper.post(APIURLConstants.SAVE_POSITION_TITLE, postData, authHeader())
        .then(handleResponse)
        .then(data => {
            return data;
        }).catch(handleError);
}
function saveTitlesSubCategory(postData) {
    return APIHelper.post(APIURLConstants.SAVE_TITLES_SUBCATEGORY, postData, authHeader())
        .then(handleResponse)
        .then(data => {
            return data;
        }).catch(handleError);
}
function saveAward(postData) {
    return APIHelper.post(APIURLConstants.SAVE_AWARD, postData, authHeader())
        .then(handleResponse)
        .then(data => {
            return data;
        }).catch(handleError);
}
function saveTeam(postData) {
    return APIHelper.post(APIURLConstants.SAVE_TEAM, postData, authHeader())
        .then(handleResponse)
        .then(data => {
            return data;
        }).catch(handleError);
}
