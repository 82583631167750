import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import ListItem from "@material-ui/core/ListItem";
import SelectLeagueOptions from "../../../sharedComponents/questionComponents/SelectLeagueOptions";
import AutocompleteComponent from "../../../sharedComponents/questionComponents/AutocompleteComponent";
import SaveButton from "../../../sharedComponents/saveButton/SaveButton";
import ResponsibilityAndTask from "../../../sharedComponents/questionComponents/ResponsibilityAndTask";
import BackButton from "../../../sharedComponents/saveButton/BackButton";
import Typography from '@material-ui/core/Typography'
import TitleWithTooltip from "../../../sharedComponents/TitleWithTooltip";
import CaptionMsg from "../../../sharedComponents/CaptionMsg";
import AwardsAndAllocades from "../../../sharedComponents/questionComponents/AwardsAndAllocades";
import useTelemetry from "../../../utils/useTelemetry";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  section: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  grid: {
    padding: theme.spacing(1),
    alignSelf: "center",
  },
  question: {
    color: theme.palette.info,
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(1),
    },
  },

}));

const PastCoachingExperience = (props) => {
  const classes = useStyles();
  const container = useRef(null);
  const [loading, setLoading] = useState(false);
  const { trackCustomEvents } = useTelemetry();
  // const [availableNotablePlayers, setAvailableNotablePlayers] = useState(props.notablePlayer.filter(x => props.notablePlayers.map(p => p.Name)));
  // const [availableAwards, setAvailableAwards] = useState(
  //   props.awardsAndAllocades.map(x => { 
  //     if(x.award)
  //     {
  //       x.award.Name = (props.awards.map(p => p.Name).includes(x.award.Name)) ? x.award.Name : ""; 
  //     }
  //     return x; 
  //   })
  // );

  // useEffect(() => {
  //   setAvailableNotablePlayers(props.notablePlayer.filter(x => props.notablePlayers.map(p => p.Name).includes(x)));
  // }, [props.notablePlayer]);

  // useEffect(() => {
  //   let ac = props.awardsAndAllocades.map(x => { 
  //     if(x.award)
  //     {
  //       x.award.Name = (props.awards.map(p => p.Name).includes(x.award.Name)) ? x.award.Name : ""; 
  //     }
  //     return x; 
  //   });
  //   setAvailableAwards(ac);
  // }, [props.awardsAndAllocades]);

  useEffect(() => {
    container.current.scrollIntoView({ block: 'end', behavior: 'smooth' });
  }, []);

  function save() {
    props.onChange();
    trackCustomEvents("EditEvent", { action: "Coaching And OtherExperience" });
  }

  return (
    <div ref={container} className={classes.root}>
      <div className={classes.section}>
        <TitleWithTooltip
          title=" 1. Provide details of your head coaching experience."
          showRequired
          tooltipTitle="HeadCoaching_ToolTip"
          class={classes.question}
        />
        <CaptionMsg message="(Please type in the values if you don't find them listed.)" />
        <SelectLeagueOptions
          data={props.headCoachExperience}
          teamList={props.teamList}
          headCoachesList={props.headCoachesList}
          onChange={props.onHeadCoachingExperienceChange}
          leagueOptionsList={props.leagueOptionsList}
          showCurrentCheckBox={true}
          isInValid={props.isInValid}
        />
      </div>

      <div className={classes.section}>
        <TitleWithTooltip
          title="2. Provide details of your assistant/other coaching experience."
          showRequired
          tooltipTitle="AssistantCoaching_ToolTip"
          class={classes.question}
        />
        <CaptionMsg message="(Please type in the values if you don't find them listed.)" />
        <SelectLeagueOptions
          data={props.assistantCoachExperience}
          teamList={props.teamList}
          coachTitle={props.coachTitle}
          headCoachesList={props.headCoachesList}
          onChange={props.onAssistantCoachingExperienceChange}
          leagueOptionsList={props.leagueOptionsList}
          showCurrentCheckBox={true}
          useHeadCoach={true}
          useTitle={true}
          useTitleSubCategory={true}
          isInValid={props.isInValid}
        />
      </div>

      <div className={classes.section}>
        <TitleWithTooltip
          title="3. Outside of coaching, what other experience do you have?"
          tooltipTitle="OtherSkill_ToolTip"
          class={classes.question}
        />
        <CaptionMsg message="(Please type in the values if you don't find them listed.)" />
        <SelectLeagueOptions
          data={props.skillExperience}
          teamList={props.teamList}
          coachTitle={props.positionTitle}
          headCoachesList={props.headCoachesList}
          onChange={props.onSkillExperienceChange}
          leagueOptionsList={props.leagueOptionsList}
          showCurrentCheckBox={true}
          useHeadCoach={true}
          useTitle={true}
          useTitleSubCategory={true}
          isInValid={props.isInValid}
        />
      </div>

      <div className={classes.section}>
        <TitleWithTooltip
          title="4. Have you won a championship as a coach?"
          tooltipTitle="Championship_ToolTip"
          class={classes.question}
        />
        <CaptionMsg message="(Please type in the values if you don't find them listed.)" />
        <SelectLeagueOptions
          championship={true}
          teamList={props.teamList}
          leagueOptionsList={props.leagueOptionsList}
          data={props.championship}
          useHeadCoach={true}
          onChange={props.onChampionshipChange}
          isInValid={props.isInValid}
        />
      </div>

      <div className={classes.section}>
        <TitleWithTooltip
          title="5. Have you received any coaching awards or accolades?"
          tooltipTitle="Award_ToolTip"
          class={classes.question}
        />
        <CaptionMsg message="(Please type in the values if you don't find them listed.)" />
        <AwardsAndAllocades awards={props.awards} data={props.awardsAndAllocades}
          onDataChange={props.onChangeAwardsAndAllocades} />
      </div>

      <div className={classes.section}>
        <TitleWithTooltip
          title=" 6. Please select any and all notable players you have coached"
          showRequired
          tooltipTitle="NotablePlayer_ToolTip"
          class={classes.question}
        />
        <CaptionMsg message="(Select from the answers below and/or create your own by typing your text and pressing “enter”.)" />        <AutocompleteComponent
          label=""
          list={props.notablePlayers}
          data={props.notablePlayer}
          onChange={props.onChangeNotablePlayer}
        />
      </div>

      <div className={classes.section}>
        <TitleWithTooltip
          title="7. Please detail your current responsibilities and tasks performed"
          tooltipTitle="CurrentResponsibility_ToolTip"
          class={classes.question}
        />

        <Grid container>
          <Grid item md={12} xs={12} className={classes.grid}>
            <ResponsibilityAndTask
              data={{ "description": props.taskInfo }}
              onChange={props.handleTaskInfoChange}
            />
          </Grid>
        </Grid>
      </div>

      <Grid item md={12} xs={12}>
        <ListItem style={{ justifyContent: "flex-end" }}>
          <BackButton
            isLoading={loading}
            onClick={props.handlePreveStepClick}
          ></BackButton>
          <SaveButton isLoading={props.isLoading} onClick={save}></SaveButton>
        </ListItem>
      </Grid>
    </div>
  );
};
export default PastCoachingExperience;
