import React, { useState, useEffect, useRef, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Paper, Divider, Grid, Box } from "@material-ui/core/";
import CoachingExperiene from "./subComponent/HeadCoachingExperience";
import ExperienceDetail from "./subComponent/ExperienceDetail";
import ViewProfileHeading from '../../../sharedComponents/ViewProfileHeading';
import NotAvailable from "../../../sharedComponents/NotAvailable/NotAvailable";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  card: {
    border: "1px solid gray",
    backgroundColor: "#F3F3F3",
    header: {
      backgroundColor: "#E5ECFC",
      color: "#2D4D89",
    },
  },
  header: {
    backgroundColor: "#E5ECFC",
    color: "#2D4D89",
    fontSize: "12px",
    fontWeight: "bold",
    padding: "3px 0 3px 10px",
  },
  subHeading: {
    backgroundColor: "#63C5DA",
    color: "#1520A6",
    padding: "5px 25px",
  },
  section: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
    textAlign: "left",
  },
  grid: {
    padding: theme.spacing(1),
    alignSelf: "center",
  },
  gridInRow: {
    display: "flex",
    justifyContent: "space-between",
    paddingLeft: "25px",
  },
  notablePlayers: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    width: "95%",
    paddingLeft: "25px",
    lineHeight: "2",
    color: "black",
  },
  section1: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
    borderLeft: "5px solid lightgray",
    paddingLeft: "20px",
    // width: "50%",
  },
  teamName: {
    fontWeight: "bold",
    fontSize: "12px",
    color: "black",
  },
  dates: {
    fontSize: "12px",
  },
  headCoach: {
    fontSize: "12px",
    color: "black",
  },
  teams: {
    fontSize: '0.9rem',
    fontWeight: '500',
  },
}));

const PlayingExperienceView = (props) => {
  const classes = useStyles();

  const [formerExperience, setFormerExperience] = useState([]);

  const [championship, setChampionship] = useState(
    props.data && props.data.championship ? props.data.championship.filter(c => c.league && c.league != "na") : []
  );


  function GetFormarBasketballExp() {
    let formerCoachingArray = props.data && props.data.formerExperience ? props.data.formerExperience : [];
    let formerCoach = [];
    if (formerCoachingArray.length > 0) {
      for (let i = 0; i < formerCoachingArray.length; i++) {
        let expArray = formerCoachingArray[i].experience;

        if (expArray.length > 0) {
          for (let y = 0; y < expArray.length; y++) {
            if (expArray[y].toDate != "Present")
              formerCoach.push({ league: formerCoachingArray[i].league, teamName: expArray[y].team && expArray[y].team.teamName ? expArray[y].team.teamName : "", fromDate: expArray[y].fromDate, toDate: expArray[y].toDate, headCoachName: expArray[y].headCoachName })
          }
        }
        // else if (expArray.length == 0 && formerCoachingArray[i].league == "na") {
        //   formerCoach.push({ league: formerCoachingArray[i].league, teamName: "", fromDate: "", toDate: "", headCoachName: [] })
        // }
      }
    }
    // sort by form Date
    formerCoach.sort(function (a, b) {
      if (a.fromDate > b.fromDate) {
        return -1;
      }
      if (a.fromDate < b.fromDate) {
        return 1;
      }
      return 0;
    });
    setFormerExperience(formerCoach);
  }

  useEffect(() => {
    GetFormarBasketballExp();
  }, [props.data])


  return (
    <div className={classes.root}>
      {/* /////////////////////////////// Former Basketball Experience //////////////////////////// */}

      <div className={classes.section}>
        <ViewProfileHeading title="Playing Experience" />
        <Grid container>
          <Grid item md={12} xs={12} className={classes.gridInRow}>
            <Grid container>
              {formerExperience.length == 0 && <NotAvailable />}
              {formerExperience.map((data, index) => {
                return (
                  <Grid item md={4} sm={6} xs={12}>
                    <ExperienceDetail
                      key={index}
                      headcoaches={true}
                      headCoachName={data.headCoachName}
                      league={props.getLeagueTextByValue(data.league)}
                      experience={data}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </Grid>
      </div>
      <Divider></Divider>
      
        <div className={classes.section}>
          <ViewProfileHeading title="Championships" />
          <Grid container>
            <Grid item md={12} xs={12} className={classes.gridInRow}>
            {championship.length > 0 &&
              <Grid container>
                {championship.map((data, index) => {
                  return (
                    <CoachingExperiene
                      key={index}
                      championship={true}
                      league={props.getLeagueTextByValue(data.league)}
                      experience={data.experience}
                    />
                  );
                })}
              </Grid>
              }
              {championship.length == 0 &&
                <NotAvailable />
              }
            </Grid>
          </Grid>
        </div>
      <Divider></Divider>
      <div className={classes.section}>
        <ViewProfileHeading title=" Awards And Accolades" />
        <Grid container>
          <Grid item md={12} xs={12} className={classes.gridInRow}>
            <Grid container>
              {(props.data && props.data.awardsAndAllocades ? props.data.awardsAndAllocades : []).length == 0 && <NotAvailable />}
              {(props.data && props.data.awardsAndAllocades ? props.data.awardsAndAllocades : []).map((data, index) => {
                return (
                  <Grid item md={4} sm={6} xs={12}>
                    <div key={index} className={classes.section1}>
                      <Typography color="primary" className={classes.teams}>{data.award && data.award.Name ? data.award.Name : ''}</Typography>
                      <Typography className={classes.teamName}>
                        {data.year ? data.year : ''}
                      </Typography>
                    </div>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default PlayingExperienceView;
