import React, { useState } from "react";
import { Box, Divider, Grid, IconButton, ListItem, ListItemText, Typography, useTheme } from "@material-ui/core/";
import DeleteIcon from '@material-ui/icons/Delete';
import ModeEditOutlineIcon from '@material-ui/icons/Edit';
import Helper from "../../common/Helper";
import HeadShot from "../../sharedComponents/profilePhoto/HeadShot";
import ReadMore from "./ReadMore";
import ReplySection from "./ReplySection";
import { userService } from "../../services";
import { Role, convertTextToLinks } from "../../helper";
import CofirmationDialogBox from "../ManageData/cofirmationDialogBox";

const CommentSection = (props) => {
    const theme = useTheme();
    const [editIndex, setEditIndex] = useState(null);
    const [commentToDelete, setCommentIdToDelete] = useState(null);
    const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);

    const handleEdit = (index) => {
        setEditIndex(editIndex === index ? null : index);
    };

    const handleSaveComment = (id, editedComment) => {
        setEditIndex(null);
        props.onSaveComment(id, editedComment);
    }

    const handleCancelEdit = () => {
        setEditIndex(null);
    };

    const handleConfirmation = (flag => {
        setOpenConfirmationDialog(false);
        if (flag == 'Y') {
            props.onDeleteComment(commentToDelete)
        }
        else {
            setCommentIdToDelete(null);
        }
    })


    const onPressDeleteComment = (id) => {
        setOpenConfirmationDialog(true);
        setCommentIdToDelete(id)
    }

    const RenderCommentList = ({ item, index }) => {
        const formattedDate = (date) => Helper.getFormattedDate(date);

        return (
            <div>
                <ListItem key={index}>
                    <ListItemText
                        primary={
                            <div style={{ marginBottom: 16, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <div style={{ marginRight: 16 }}>
                                    <HeadShot
                                        coachName={item.createdByUserName}
                                        className={{ width: 24, height: 24, border: "2px solid #1D428A" }}
                                        profilePhoto={""}
                                    />
                                </div>
                                <div>
                                    <Typography variant="subtitle1" style={{ color: theme.palette.text.secondary, fontWeight: "bold" }}>
                                        {item.createdByName}
                                    </Typography>
                                    <Typography variant="body2" style={{ color: theme.palette.text.hint }}>
                                        {formattedDate(item.createdAt)}
                                    </Typography>
                                </div>
                            </div>}
                        secondary={
                            <div>
                                {editIndex === index ? (
                                    <ReplySection
                                        postId={item.socialPostId}
                                        commentData={item}
                                        onEditComment={(id, updatedComment) => handleSaveComment(id, updatedComment)}
                                        onCancelEdit={handleCancelEdit}
                                        isEdit={true} />
                                ) : (
                                    <>
                                        <Typography variant="h5" style={{ color: theme.palette.text.primary }}>
                                            {item.title}
                                        </Typography>
                                        <ReadMore>
                                            <div dangerouslySetInnerHTML={{ __html: convertTextToLinks(item.comment) }} />
                                        </ReadMore>
                                    </>
                                )}
                            </div>
                        }
                    />
                    {editIndex !== index && (
                        <>
                            {item.createdByUserName === userService.getUsername() && <IconButton edge="end" aria-label="edit" onClick={() => handleEdit(index)}>
                                <ModeEditOutlineIcon />
                            </IconButton>}
                            {(userService.getUserRole() == Role.Admin || item.createdByUserName === userService.getUsername()) && <IconButton edge="end" aria-label="delete" onClick={() => onPressDeleteComment(item.id)}>
                                <DeleteIcon />
                            </IconButton>}
                        </>
                    )}
                </ListItem >
                {index !== props.commentList.length - 1 && <Divider variant='fullWidth' />}
            </div>

        );
    };

    return (
        <Box sx={{ backgroundColor: theme.palette.background.paper, padding: theme.spacing(2), borderRadius: theme.shape.borderRadius, marginTop: theme.spacing(2) }}>
            {props?.commentList && props?.commentList?.length > 0 ?
                <Grid item xs={12} style={{ marginLeft: 16, marginRight: 16, marginTop: 16 }}>
                    <Box sx={{ marginLeft: 12 }}>
                        <Typography variant="subtitle1" color="secondary" >
                            Comments
                        </Typography>
                    </Box>
                    {props?.commentList?.map((item, index) => (
                        <RenderCommentList key={index} item={item} index={index} />
                    ))}
                </Grid> :
                <Grid item xs={12} style={{ display: "flex", justifyContent: "center", alignItems: 'center' }}>
                    <Typography variant="body2" >
                        No comments available
                    </Typography>
                </Grid>
            }

            <CofirmationDialogBox
                message="Are you sure you want to delete this entry?"
                open={openConfirmationDialog}
                onClose={() => handleConfirmation("N")}
                onYesClick={() => handleConfirmation("Y")}
                onNoClick={() => handleConfirmation("N")}
            ></CofirmationDialogBox>
        </Box >
    );
};

export default CommentSection;
