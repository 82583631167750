import React, { useState, useEffect, useContext } from "react";
import APIHelper from "./ApiHelper";
import APIURLConstants from "./ApiURLConstants";
import { jwplayerService } from "../services";
import FrontOfficeService from "../services/frontOffice.service";
import { userService } from "../services/user.service";
/*
    This context provider contains data to fuel the multiple questions and allow us to maintain it at one place, 
    hence achieving flexibility in placing the questions anywhere in/and across multiple sections.
*/

//... This JSON should always match the structure of JSON retrieved from the API.
// let initialProfileList = {
//   profileList: [],
// };

export const UserContext = React.createContext([]);

export const UserProvider = (props) => {
    const [jwtTokenDetails, setJwtTokenDetails] = useState({});
    const [userList, setUserList] = useState([]);

    const [error, setError] = useState("");
    const [processing, setProcessing] = useState(true);
    useEffect(() => {

        getUserList();
        // handleServiceCall();
    }, []);

    const refreshUserList = (user) => {
        setProcessing(true);
        let list = userList;
        let index = list.findIndex(x => x.id == user.id);
        list[index] = user;
        setProcessing(false);
        setUserList(list);
    }

    //... This provides values to the context consumers.
    const providerValue = {
        userList: userList,
        jwtTokenDetails,
        error: error,
        isProcessing: processing,
        getUserList: () => { getUserList(); },
        refreshUserList: refreshUserList,
    };

    const getUserList = () => {
        setProcessing(true);
        userService.getAllUser()
            .then((data) => {
                setProcessing(false);
                setUserList(data);
            })
            .catch((err) => {
                setProcessing(false);
                console.log(err);
            });
    };

   

    return (
        <UserContext.Provider value={providerValue}>
            {props.children}
        </UserContext.Provider>
    );
};

export const UserConsumer = UserContext.Consumer;

export default UserContext;
