import React, { useState, useEffect } from "react";
import { withRouter } from 'react-router-dom';
import { BehaviorSubject } from 'rxjs';
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, Card, CardHeader, CardContent, CardActions, Button, Divider } from "@material-ui/core/";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { referenceDataService, userService } from '../../services';
import { Role, cacheStorage } from '../../helper';
import RouteConstants from '../../common/RouteConstants';

const currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('currentUser')));

const useStyles = makeStyles((theme) => ({
    section: {
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        alignItems: 'center',
        height: '100%'
    },
    card: {
        height: "100%",
        border: "1px solid #e8e8e8",
        boxShadow: "0px 3px 5px 2px rgba(0, 0, 0, 0.1)",
        marginBottom: "25px",
        marginRight: "25px"
    },
    CardContent: {
        paddingBottom: "0px !important",
    },
    cardHeader: {
        height: "20px",
    },
    cardActions: {
        float: 'right'
    }
}));

const TermsAndConditions = (props) => {
    const classes = useStyles();
    const [terms, setTerms] = useState("");
    const [isApplicable, setIsApplicable] = useState(true);

    useEffect(() => {
        referenceDataService.getTermsAndConditions()
            .then(data => {
                setTerms(data.termsAndConditionText);
                if (!data.isApplicable) {
                    redirectUser();
                }
            });
    }, []);

    const acceptTermsAndConditions = () => {
        userService.acceptTerms()
            .then(data => {
                let cachedUser = JSON.parse(cacheStorage.getProfilePhotoFromLocalStorage('currentUser'));
                cachedUser['termsAccepted'] = true;
                currentUserSubject.next(cachedUser);
                cacheStorage.saveProfilePhotoToLocalStorage('currentUser', JSON.stringify(cachedUser));
                redirectUser();
            })
            .catch(err => {
                console.log(err);
            })
    }

    const redirectUser = () => {
        let role = userService.getUserRole();
        if (role && (role == Role.FrontOffice || role == Role.FrontOfficePlusCoach)) {
            const { from } = { from: { pathname: RouteConstants.DASHBOARD } };
            props.history.push(from);
        }
        else if (role && role == Role.Admin) {
            const { from } = { from: { pathname: RouteConstants.COACH_MANAGEMENT } };
            props.history.push(from);
        }
        else {
            const { from } = props.location.state || { from: { pathname: RouteConstants.COACH_PROFILE_VIEW } };
            props.history.push(from);
        }
    }

    return (
        <Grid container className={classes.section}>
            <Grid xs={12} sm={6} md={4} lg={4} xl={3}>
                <Card className={classes.card}>
                    <CardHeader className={classes.cardHeader}
                        title={<Typography variant="h6" color="primary">Terms and Conditions</Typography>}
                    />
                    <Divider></Divider>
                    <CardContent className={classes.CardContent}>
                        <Typography variant="subtitle2"><div dangerouslySetInnerHTML={{ __html: terms }} /></Typography>
                    </CardContent>
                    <CardActions className={classes.cardActions}>
                        <Button variant="contained" color="primary" size="small" endIcon={<ArrowForwardIcon />} onClick={acceptTermsAndConditions}>
                            Accept
                        </Button>
                    </CardActions>
                </Card>
            </Grid>
        </Grid>
    );
}

export default withRouter(TermsAndConditions);