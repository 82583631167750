import React, { useState, useEffect, useContext } from "react";
import APIHelper from "./ApiHelper";
import APIURLConstants from "./ApiURLConstants";

import { coachProfileService, jwplayerService, userService } from "../services";

import { authenticationService } from "../services";
import { Role } from "../helper";
import RouteConstants from "../common/RouteConstants";
/*
    This context provider contains data to fuel the multiple questions and allow us to maintain it at one place, 
    hence achieving flexibility in placing the questions anywhere in/and across multiple sections.
*/

//... This JSON should always match the structure of JSON retrieved from the API.
let initialProfile = {
  fullName: {
    firstName: "",
    lastName: "",
    middleName: "",
    suffix: "",
  },
  userName: "",
  roleAndCoachingPhilosophy: {
    description: "",
  },
  profilePhoto: {},
  mediaAndArticle: {
    externalLinks: [],
  },
  coachingVideos: {
    externalLinks: [],
  },
  coachingAndOtherJobExperience: {
    headCoachExperience: [],
    assistantCoachExperience: [],
    skillExperience: [],
    championship: [],
    awardsAndAllocades: [],
    notablePlayer: [],
    taskInfo: "",
  },

  playingExperience: {
    formerExperience: [],
    championship: [],
    awardsAndAllocades: [],
  },

  educationDetail: [],
  profileVideos: [],
  additionalExperience: {
    charitableExperience: "",
    eventDetail: [],
  },

  skillSet: {
    coachingSkill: [],
    languageSkill: [],
    coordinator: "",
    coachPhilosophyAndSkill: "",
  },
  other: {
    description: "",
  },
  status: '',
};

export const AdminCoachProfileContext = React.createContext(initialProfile);

export const CoachProfileProvider = (props) => {
  //... State to maintain entire user profile.
  const [profile, setProfile] = useState(initialProfile);

  const [loggedInUser, setLoggedInUser] = useState({});
  const [jwtTokenDetails, setJwtTokenDetails] = useState({});
  //const [profileCompleteness, setProfileCompleteness] = useState(0);
  const [status, setStatus] = useState("");
  //const [remainingSection, setremainingSection] = useState([]);

  // const getProfileCompleteness = () => {
  //   let percent = 0;
  //   let remainingSections = [];
  //   if (Object.keys(profile.fullName).length != 0) { percent += 15; }

  //   if (Object.keys(profile.coachingAndOtherJobExperience).length != 0) {
  //     if (profile.coachingAndOtherJobExperience.headCoachExperience.length > 0 || profile.coachingAndOtherJobExperience.assistantCoachExperience.length > 0 || profile.coachingAndOtherJobExperience.notablePlayer.length > 0 || profile.coachingAndOtherJobExperience.awardsAndAllocades && profile.coachingAndOtherJobExperience.awardsAndAllocades.length > 0) {
  //       percent += 30;
  //     }
  //     else { remainingSections.push("Coaching And Other Job Experience"); }
  //   } else { remainingSections.push("Coaching And Other Job Experience"); }
  //   if (Object.keys(profile.playingExperience).length != 0) {
  //     if (profile.playingExperience.formerExperience.length > 0) percent += 15;
  //     else { remainingSections.push("Playing Experience"); }
  //   } else { remainingSections.push("Playing Experience"); }

  //   if (Object.keys(profile.additionalExperience).length != 0) {
  //     if (profile.additionalExperience.eventDetail.length > 0) { percent += 10; }
  //     else { remainingSections.push("Additional Experience"); }
  //   } else { remainingSections.push("Additional Experience"); }
  //   if (Object.keys(profile.skillSet).length != 0) {
  //     if (profile.skillSet.coachingSkill.length > 0 || profile.skillSet.languageSkill.length > 0) { percent += 15 }
  //     else { remainingSections.push("Skills"); }
  //   } else { remainingSections.push("Skills"); }
  //   if (profile.educationDetail.length > 0) { percent += 5; }
  //   else { remainingSections.push("Education Detail"); }

  //   if (profile.profileVideos.length > 0) { percent += 5; }
  //   else { remainingSections.push("Profile Videos"); }


  //   if (Object.keys(profile.other).length != 0) {
  //     if (profile.other.description != "") { percent += 5; }
  //     else { remainingSections.push("Other Experience"); }
  //   } else { remainingSections.push("Other Experience"); }

  //   setProfileCompleteness(percent);
  //   setremainingSection(remainingSections);
  // }

  useEffect(() => {
    //getProfileCompleteness();
    setStatus(profile.status);
  }, [profile])
  //... This provides values to the context consumers.
  const providerValue = {
    fullName: profile.fullName,
    userName: profile.userName,
    profilePhoto: profile.profilePhoto,
    profileVideo: profile.profileVideo,
    roleAndCoachingPhilosophy: profile.roleAndCoachingPhilosophy,
    mediaAndArticle: profile.mediaAndArticle,
    coachingVideos: profile.coachingVideos,
    educationDetail: profile.educationDetail,
    profileVideos: profile.profileVideos,
    playingExperience: profile.playingExperience,
    coachingAndOtherJobExperience: profile.coachingAndOtherJobExperience,
    additionalExperience: profile.additionalExperience,
    other: profile.other,
    skillSet: profile.skillSet,
    email: profile.email,
    jwtTokenDetails: jwtTokenDetails,
    profileCompleteness: profile.profileCompleteness,
    remainingSection: profile.remainingSection,
    status: status,
    lastModifiedAt: profile.lastModifiedAt,
    updateProfile: (data) => {
      return handleUpdate(data);
    },
    updatePersonalDetails: (personalDetails) => {
      return handlePersonalDetailsUpdate(personalDetails);
    },
    updateOtherDescription: (otherDetails) => {
      return handleOtherDetailsUpdate(otherDetails);
    },
    updateSkillSet: (skillsetDetails) => {
      return handleSkillsetUpdate(skillsetDetails);
    },
    updateEducationDetails: (educationDetails) => {
      return handleEducationDetailsUpdate(educationDetails);
    },
    updateProfileVideosDetails: (profileVideoss) => {
      return handleProfileVideosUpdate(profileVideoss);
    },
    updateAdditionalExperience: (additionalExperienceDetails) => {
      return handleAdditionalExperienceUpdate(additionalExperienceDetails);
    },
    updatePlayingExperience: (playingExperienceDetails) => {
      return handlePlayingExperienceUpdate(playingExperienceDetails);
    },
    updateCoachingAndOtherJobExperience: (coachingAndOtherJobExperienceDetails) => {
      return handleCoachingAndJobExperienceUpdate(coachingAndOtherJobExperienceDetails);
    },
    updateEmailId: (emailId) => {
      return handleEmailUpdate(emailId);
    },
    updateStatus: (newStatus) => { setStatus(newStatus); },
    retrieveAndSetProfileContext: (userName) => {
      return retrieveAndSetProfileContext(userName);
    }
  };

  const handlePersonalDetailsUpdate = (personalDetails) => {
    let profileVideoUrl = '';
    if ((personalDetails.profileVideo && personalDetails.profileVideo.length > 0 && personalDetails.profileVideo[0].videoURL)) {
      profileVideoUrl = personalDetails.profileVideo[0].videoURL;
    }

    if (personalDetails.profileVideo && personalDetails.profileVideo.videoURL) {
      profileVideoUrl = personalDetails.profileVideo.videoURL;
    }

    setProfile((prevState) => ({
      ...prevState,
      fullName: personalDetails.fullName,
      profilePhoto: personalDetails.profilePhoto,
      roleAndCoachingPhilosophy: personalDetails.roleAndCoachingPhilosophy,
      mediaAndArticle: personalDetails.mediaAndArticle,
      coachingVideos: personalDetails.coachingVideos,
      profileVideo: profileVideoUrl && profileVideoUrl !== '' ? { videoURL: profileVideoUrl } : {},
    }));
  };

  const handleCoachingAndJobExperienceUpdate = (coachingJobExperience) => {
    setProfile((prevState) => ({
      ...prevState,
      coachingAndOtherJobExperience: coachingJobExperience
    }));
  };

  const handlePlayingExperienceUpdate = (playingExperienceDetails) => {
    setProfile((prevState) => ({
      ...prevState,
      playingExperience: playingExperienceDetails,
    }));
  };

  const handleAdditionalExperienceUpdate = (additionalExperienceDetails) => {

    setProfile((prevState) => ({
      ...prevState,
      additionalExperience: additionalExperienceDetails
    }));
  };

  const handleSkillsetUpdate = (skillsets) => {
    setProfile((prevState) => ({
      ...prevState,
      skillSet: skillsets,
    }));
  };

  const handleEducationDetailsUpdate = (educationDetails) => {
    setProfile((prevState) => ({
      ...prevState,
      educationDetail: Object.values(educationDetails),
    }));
  };

  const handleProfileVideosUpdate = (profileVideoss) => {
    setProfile((prevState) => ({
      ...prevState,
      profileVideos: Object.values(profileVideoss),
    }));
  }

  const handleOtherDetailsUpdate = (otherDescription) => {
    let description = otherDescription && otherDescription.other && otherDescription.other.description ? otherDescription.other.description : "";
    setProfile((prevState) => ({
      ...prevState,
      other: { description },
    }));
  };

  const handleEmailUpdate = (emailId) => {
    setProfile((prevState) => ({
      ...prevState,
      email: emailId,
    }));

    return true;
  };

  const handleUpdate = (data) => {
    let p = { ...profile };

    //... Update the profile object matching the keys.
    Object.keys(data).forEach((key) => {
      p[key] = data[key];
    });

    //... Update provider's internal state.
    setProfile(p);

    //... Finally, call API to save the profile in DB.
    //... Return actual status received from the API.

    return true;
  };

  useEffect(() => {
    let userName = userService.getUsername();
    setLoggedInUser({ username: userName, email: "" });
    getJWToken();
  }, []);

  const getJWToken = () => {
    jwplayerService.getToken()
      .then(token => {
        setJwtTokenDetails(token);
      })
  }

  const retrieveAndSetProfileContext = (userName) => {
    coachProfileService.getCoachProfile(userName)
      .then((data) => {
        let profileObject = {
          fullName: data.personalDetails && data.personalDetails.fullName ? data.personalDetails.fullName : {},
          userName: data.userName,
          profilePhoto: data.personalDetails && data.personalDetails.profilePhoto ? data.personalDetails.profilePhoto : {},
          profileVideo: data.personalDetails && data.personalDetails.profileVideo ? data.personalDetails.profileVideo : {},
          roleAndCoachingPhilosophy: data.personalDetails && data.personalDetails.roleAndCoachingPhilosophy ? data.personalDetails.roleAndCoachingPhilosophy : {},
          mediaAndArticle: data.personalDetails && data.personalDetails.mediaAndArticle ? data.personalDetails.mediaAndArticle : {},
          coachingVideos: data.personalDetails && data.personalDetails.coachingVideos ? data.personalDetails.coachingVideos : {},
          other: { description: data.other && data.other.description ? data.other.description : "", },
          skillSet: data.skillSet ? data.skillSet : { coachingSkill: [], languageSkill: [], coordinator: "", coachPhilosophyAndSkill: "" },
          educationDetail: data.educationDetail && data.educationDetail.educationDetail ? data.educationDetail.educationDetail : [],
          profileVideos: data.profileVideos && data.profileVideos.ProfileVideo ? data.profileVideos.ProfileVideo : [],
          additionalExperience: data.additionalExperience ? data.additionalExperience : {},
          playingExperience: data.playingExperience ? data.playingExperience : { formerExperience: [], championship: [], awardsAndAllocades: [] },
          coachingAndOtherJobExperience: data.coachingAndOtherJobExperience ? data.coachingAndOtherJobExperience : {
            assistantCoachExperience: [],
            awardsAndAllocades: [],
            championship: [],
            headCoachExperience: [],
            notablePlayer: [],
            skillExperience: [],
            taskInfo: "",
          },
          email: data.email ? data.email : "", status: data.status, lastModifiedAt: data.lastModifiedAt,
          profileCompleteness: data.profileCompleteness,
          remainingSection: data.remainingSections ? data.remainingSections : []
        };
        setLoggedInUser({ username: data.userName, email: data.email ? data.email : "" });
        setProfile(profileObject);
      })
  };

  return (
    <AdminCoachProfileContext.Provider value={providerValue}>
      {props.children}
    </AdminCoachProfileContext.Provider>
  );
};

export const CoachProfileConsumer = AdminCoachProfileContext.Consumer;

export default AdminCoachProfileContext;
