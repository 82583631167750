import { validate } from "../helper/validateUtils";

export default function createPostSchema(values) {
  let errors = {};
  let validationResult;

  validationResult = validate(values.postTitle, { Required: true });
  if (!validationResult.isValid) {
    errors.postTitle = validationResult.message;
  }

  validationResult = validate(values.postDescription, { Required: true });
  if (!validationResult.isValid) {
    errors.postDescription = validationResult.message;
  }

  return errors;
};
