import { makeStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import React, { useState } from "react";
import MediaManagementAppBar from "./MediaManagementAppBar";
import MediaManagementTabPanel from "./MediaManagementTabPanel";
import Loader from "../../sharedComponents/Loader/Loader";
import { useGameVideo } from "../../context/GameVideoContext";

const useStyles = makeStyles((theme) => ({
    content: {
        margin: theme.spacing(2),
        boxShadow: "0px 0px 5px 3px rgba(0,0,0,0.1)",
        backgroundColor: '#ffffff',
        zIndex: '2',
    },
    toolbar: {
        flex: 1,
        display: "flex",
        justifyContent: "flex-end",
        alignItems: 'flex-end',
        [theme.breakpoints.down("xs")]: {
            paddingLeft: 0,
            paddingRight: 0,
        },
    },
    button: {
        border: '1px solid #1d428a',
        borderRadius: '4px',
        padding: '6px 8px',
        marginBottom: '10px',
        fontSize: '1rem',
        color: '#fff',
        background: '#1d428a',
        '&:hover': {
            color: '#fff',
            background: '#1d428a',
        },
        '&:focus': {
            color: '#fff',
            background: '#1d428a',
        },
        '& svg': {
            marginRight: '5px',
        },
    },
}));


const MediaManagementContainer = (props) => {

    const classes = useStyles();
    const [tabIndex, setTabIndex] = useState(0);
    const { isLoading } = useGameVideo();

    function TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <Typography
                component="div"
                role="tabpanel"
                hidden={value !== index}
                id={`scroll-tabpanel-${index}`}
                aria-labelledby={`scroll-tab-${index}`}
                {...other}
            >
                {value === index && <div p={3}>{children}</div>}
            </Typography>
        );
    }

    TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.any.isRequired,
        value: PropTypes.any.isRequired,
    };

    const handleTabChange = (event, newValue) => {
        setTabIndex(newValue);
    }

    return (
        <div className={classes.content}>
            <MediaManagementAppBar tabIndex={tabIndex} handleTabChange={handleTabChange} />
            <MediaManagementTabPanel tabIndex={tabIndex} handleTabChange={handleTabChange} />
            <Loader isLoading={isLoading} />
        </div >
    )
}

export default MediaManagementContainer;