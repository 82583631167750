import React from "react";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function SnackbarMsg(props) {
    return (
        <Snackbar open={props.showError} autoHideDuration={6000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} onClose={props.onClose}
            TransitionComponent="fade">
            <Alert onClose={props.onClose} severity={props.severity}>
                {props.msg}
            </Alert>
        </Snackbar>
    )

}