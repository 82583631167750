import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from "react";
import { userService } from '../../services';
import ProfileCard from "../../sharedComponents/ProfileCard/ProfileCard";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        [theme.breakpoints.up('sm')]: {
            padding: theme.spacing(1)
        },
    }
}));


export default function CardContainer(props) {

    const classes = useStyles();
    const [role, setRole] = useState();

    useEffect(() => {
        setRole(userService.getUserRole())
    }, []);

    return (
        <div className={classes.root}>
            <Grid container className={classes.container}>
                {props.profileList.map((value, index) => {
                    return <ProfileCard userName={props.userName}
                        handleEditProfileClick={props.handleEditProfileClick}
                        handleViewProfileClick={props.handleViewProfileClick}
                        getLeagueTextByValue={props.getLeagueTextByValue}
                        handleBookMarked={props.handleBookMarked}
                        handleProfileStatusChange={props.handleProfileStatusChange}
                        container={props.container}
                        key={index}
                        handleOpenDialog={props.handleOpenDialog}
                        data={value}
                        handleProfileChecked={props.handleProfileChecked}
                        selectedProfiles={props.selectedProfiles}
                        handleDeleteProfile={props.handleDeleteProfile}
                        hideAction={props.hideAction}
                        role={role}
                    />
                })}
            </Grid>
        </div>
    );
}
