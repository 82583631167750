import React, { useContext, useState, useEffect } from 'react';
import { useHistory, useParams, withRouter } from 'react-router-dom';
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import AddCircleIcon from '@material-ui/icons/AddCircleOutline';
import { Paper, Typography } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Dialog from "@material-ui/core/Dialog";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";
import SnackbarMsg from "../../sharedComponents/Snackbar/Snackbar";
import Button from "@material-ui/core/Button";
import adminService from "../../services/admin.service";
import {userService} from "../../services";
import { UserContext } from '../../common/UserContext';
import LinearProgress from '@material-ui/core/LinearProgress';
import MemoItem from './memoItem';
import RouteConstants from "../../common/RouteConstants";
import MemoAddIcon from '@material-ui/icons/PostAdd'
import IconButton from "@material-ui/core/IconButton";
import AddViewGroupParticipantsDialog from '../../sharedComponents/GroupDialog/addViewGroupParticipantsDialog';
import UserList from '../../sharedComponents/UserList/UserList';

import ViewAssignedUsers from './viewAssignedUsers';


const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    searchBox: {
        width: '250px',
        [theme.breakpoints.only('xs')]: {
            width: '200px',
        },
        [theme.breakpoints.down(325)]: {
            width: '165px',
        },
    },
    memoboardContent: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        overflow: 'auto',
        height: 'auto',
    },
    addMemoSection: {
        padding: theme.spacing(1, 2, 0, 2),
        display: 'flex',
        justifyContent: 'flex-end',
    },
    cursorPointer: {
        cursor: 'pointer',
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 150,
        color: '#fff',
    },
    table: {
        borderRadius: 0,
        margin: '10px',
    },
    addGroup: {
        border: '1px solid #1d428a',
        borderRadius: '4px',
        padding: '6px 8px',
        marginBottom: '10px',
        fontSize: '1rem',
        color: '#fff',
        background: '#1d428a',
        '&:hover': {
            color: '#fff',
            background: '#1d428a',
        },
        '&:focus': {
            color: '#fff',
            background: '#1d428a',
        },
        '& svg': {
            marginRight: '5px',
        },
    },
}));
const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: "#316ad8",
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);
const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

function MemoBoardContainer(props) {
    const classes = useStyles();
    const context = useContext(UserContext);
    const [loading, setLoading] = useState(false);
    const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
    const [allMemos, setAllMemos] = useState(null);
    const [currentMemoId, setCurrentMemoId] = useState();
    const [showError, setShowError] = useState(false);
    const [severity, setSeverity] = useState("");
    const [message, setMessage] = useState("");
    const [openParticipantDialog, toggleParticipantDialog] = useState(false);
    const [allGroups, setAllGroups] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [action , setAction] = useState();
    const [openUsersDialog, setOpenUsersDialog] = useState(false);
    const [memoProfileList, setMemoProfileList] = useState([]);

    useEffect(() => {
        getAllMemos();
        getAllGroups();
    }, [context.user]);

    const getAllGroups = () => {
        adminService.getAllGroups()
            .then(result => {
                //Load all profiles and add it to groups list so that memos can be assigned
                let allUserGroup = {id: 'XXXXX', isActive: 1, name: 'All Users', rules: null, type: 'static', users: []};
                let allCoachGroup = {id: 'CCCCC', isActive: 1, name: 'All Coaches', rules: null, type: 'static', users: []};
                let allFrontOfficeGroup = {id: 'FFFFF', isActive: 1, name: 'All FO Users', rules: null, type: 'static', users: []};
                let allFrontOfficPlusCoachGroup = {id: 'FCFCFC', isActive: 1, name: 'All FO + Coach', rules: null, type: 'static', users: []};
                userService.getAllUser()
                .then(usersList => {
                    usersList = usersList.filter(u => u.role != 'Admin');
                    allUserGroup.users = usersList.map(u => u.userName);

                    let coachUsersist = usersList.filter(u => u.role == 'Coach');
                    allCoachGroup.users = coachUsersist.map(u => u.userName);

                    let FOUsersist = usersList.filter(u => u.role == 'FrontOffice');
                    allFrontOfficeGroup.users = FOUsersist.map(u => u.userName);

                    let FOPlusCoachUsersist = usersList.filter(u => u.role == 'FrontOfficePlusCoach');
                    allFrontOfficPlusCoachGroup.users = FOPlusCoachUsersist.map(u => u.userName);

                    let groupResults = [allUserGroup, allCoachGroup, allFrontOfficeGroup, allFrontOfficPlusCoachGroup];
                    if (result && result.length > 0) {
                        groupResults = groupResults.concat(result);
                    }
                    setAllGroups(groupResults);
                    setLoading(true);
                })
                .catch((err) => {

                });
                
            }).catch((err) => {
            });
    }
    const getAllMemos = () => {
        adminService.getAllMemos()
            .then(result => {
                if (result) {
                    setAllMemos(result);
                }
                setLoading(true);
            }).catch((err) => {
            });
    };

    const createMemo = () => {
        props.history.push(RouteConstants.ADMIN_ADD_MEMO + "/");
    }

    const onEditClick = (id => {
        props.history.push(RouteConstants.ADMIN_EDIT_MEMO + "/" + id);
    });
    const onDeleteClick = (id => {
        setCurrentMemoId(id);
        setAction("D");
        setOpenConfirmationDialog(true);
    });
    const onPublishClick = (id => {
        setCurrentMemoId(id);
        setAction("P");
        setOpenConfirmationDialog(true);
    });

    const publishMemo = (id) =>{
        if (id) {
            setLoading(true);
            adminService.publishMemo(id).then(result => {
                if(!result){
                    showAlert("error", 'Please assign users to Memo.');
                }
                else if (typeof data == "string") {
                    showAlert("error", data);
                }
                else
                {
                showAlert("success", 'Memo published successfully.');
                }
                getAllMemos();
                setLoading(true);
                setCurrentMemoId(null);
                setAction();
                setOpenConfirmationDialog(false);
            }).catch((err) => {
                setLoading(false);
                setAction();
                setOpenConfirmationDialog(false);
                
            });
        }
    }
    const handleConfirmation = (mode) => {
        if (mode === "Y") {
            if (currentMemoId) {
                setLoading(false);
                if(action === "D")
                    deleteMemo(currentMemoId);
                else if(action ==="P")
                    publishMemo(currentMemoId);
            }
        }
        else
            setOpenConfirmationDialog(false);
    }

    const showAlert = (sever, msg) => {
        setShowError(true);
        setSeverity(sever)
        setMessage(msg);
    }
    const deleteMemo = (id) =>
    {
        adminService.deleteMemo(id).then(result => {
            getAllMemos();
            setLoading(true);
            setOpenConfirmationDialog(false);
            setCurrentMemoId(null);
            setAction();
            showAlert("success", 'Memo deleted successfully.');
        }).catch((err) => {
            setLoading(false);
            setAction();
        });
    }
    const handleClose = () => {
        setShowError(false);
        setSeverity("")
        setMessage("");
    }
    const onManageGroupClick = (id) => {
        setSelectedUsers([]);
        setCurrentMemoId(id);
        adminService.getUsersByMemoId(id)
            .then(res => {
                if(res && res.userIds)
                    setSelectedUsers(res.userIds);
                    toggleParticipantDialog(true);
            })
            .catch(err => {
                console.error(err);
            })
    }
    const handleCloseClick = (isSuccess) => {
        // if (!!isSuccess) {
        // }
        toggleParticipantDialog(false);
        setCurrentMemoId(null);
    }

    const handleSaveClick = (obj) => {
        //console.log(obj);
        adminService.saveUserMemo(obj)
            .then(response => {
                if (response) {
                    setShowError(true);
                    setMessage("Users added to Memo.")
                    setSeverity("success");
                    getAllMemos();
                    setLoading(true);
                    setCurrentMemoId(null);
                    setAction();
                }
                handleCloseClick(true);
            })
            .catch(err => {
                console.error(err);
            })
    }

    const handleViewUsersClick = (memoId) => {
        adminService.getUsersProfilesByMemoId(memoId)
        .then(profilesList => {
            setMemoProfileList(profilesList);
        })
        .catch(err => {
            console.log(err);
        })
        setOpenUsersDialog(true);
    }

    return (
        <div className={classes.root} style={{ backgroundColor: '#f5f5f5' }}>
            <div className="repsContainer" style={{ overflow: 'hidden' }}>
                <div className={classes.memoboardContent}>
                    {
                        <React.Fragment>
                            {
                                (!loading) &&
                                <LinearProgress color="secondary"></LinearProgress>
                            }
                        </React.Fragment>
                    }
                    <Grid item className={classes.addMemoSection}>
                        {/* <Tooltip title="Add" > */}
                        <IconButton onClick={() => createMemo()} className={classes.addGroup}>
                            <MemoAddIcon
                                className={classes.cursorPointer} ></MemoAddIcon> Add Memo
                                </IconButton>
                        {/* </Tooltip> */}
                    </Grid>
                    <Grid container>
                        <TableContainer component={Paper} className={classes.table}>
                            <Table size="small" aria-label="a dense table">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell style={{ width: '45%' }}>Title</StyledTableCell>
                                        <StyledTableCell style={{ width: '25%' }}>Created By</StyledTableCell>
                                        <StyledTableCell style={{ width: '20%' }}>Last Updated Date</StyledTableCell>
                                        <StyledTableCell align="center" style={{ width: '10%' }}>Action</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {allMemos && allMemos.map((item, index) => {
                                        return <MemoItem
                                            key={index}
                                            data={item}
                                            onEditClick={onEditClick}
                                            onDeleteClick={onDeleteClick}
                                            onManageGroupClick={onManageGroupClick}
                                            onPublishClick = {onPublishClick}
                                            showEditDeleteButton={true}
                                            onViewUsersClick={handleViewUsersClick}
                                        />
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                    <Dialog
                        open={openConfirmationDialog}
                        onClose={() => handleConfirmation("N")}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description">
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                Do you want to {action === "D" ? 'delete' : 'publish'} this Memo?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => handleConfirmation("Y")} color="primary">
                                Yes</Button>
                            <Button
                                onClick={() => handleConfirmation("N")}
                                color="primary"
                                autoFocus>No
                            </Button>
                        </DialogActions>
                    </Dialog>
                    {openParticipantDialog &&
                        <AddViewGroupParticipantsDialog
                            groupList={allGroups}
                            open={openParticipantDialog}
                            handleCloseClick={handleCloseClick}
                            memoId={currentMemoId}
                            userIds={selectedUsers}
                            handleSaveClick={handleSaveClick}
                            showLegend = {true}
                        />}
                        {openUsersDialog && <ViewAssignedUsers
                            users={memoProfileList}
                            open={openUsersDialog}
                            handleCloseClick={() => setOpenUsersDialog(false)}
                    />}
                    <SnackbarMsg showError={showError} onClose={handleClose} severity={severity} msg={message} />
                </div>
            </div>
        </div>
    );
};

export default withRouter(MemoBoardContainer);