import { Box, useTheme } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";
import SwipeableViews from "react-swipeable-views";
import AdminDashboard from "./AdminDashboard";
import ApplicationInsights from "./ApplicationInsights";

const AdminDashboardTabPanel = (props) => {

    const theme = useTheme();

    function TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <Box
                component="div"
                role="tabpanel"
                hidden={value !== index}
                id={`scroll-tabpanel-${index}`}
                aria-labelledby={`scroll-tab-${index}`}
                {...other}
            >
                {value === index && <div p={3}>{children}</div>}
            </Box>
        );
    }

    TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.any.isRequired,
        value: PropTypes.any.isRequired,
    };

    return (
        <SwipeableViews
            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
            index={props.tabIndex}
            onChangeIndex={props.handleTabChange}
        >
            <TabPanel value={props.tabIndex} index={0} dir={theme.direction === "rtl" ? "x-reverse" : "x"}>
                <AdminDashboard />
            </TabPanel>
            <TabPanel value={props.tabIndex} index={1} dir={theme.direction === "rtl" ? "x-reverse" : "x"}>
                <ApplicationInsights />
            </TabPanel>
        </SwipeableViews>
    )
}

export default AdminDashboardTabPanel;