import React, {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import useForm from "../../sharedComponents/customHooks/useForm";
import HTMLEditor from "../HTMLEditor/HTMLEditor";

const useStyles = makeStyles((theme) => ({
  grid: {
    padding: theme.spacing(1),
    alignSelf: "center",
  },
  question: {
    color: theme.palette.primary.main,
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(1),
    },
  },
}));

const initialFormData = {
  description: { value: "", hasError: null },
};

const CoachPhilosophyAndSkill = (props) => {
  const classes = useStyles();

  const [formData, setFormData] = useState(props.data);

  useEffect(() => {
    props.onChange(formData);
  }, [formData]);

  const onChange = (event) => {
    setFormData(event.editor.getData());
  }
  return (
    <Grid container>
      <Grid item md={12} xs={12} className={classes.grid}>
      <HTMLEditor
                    id="description" name="description"
                    data={formData}
                    onChange={onChange}
                    error={undefined}
                />
        {/* <TextField
          id="description"
          variant="outlined"          
          size="small"
          fullWidth
          multiline
          rows={4}
          value={formData}
          onChange={(e) => setFormData(e.target.value)}
        /> */}
      </Grid>
    </Grid>
  );
};

export default CoachPhilosophyAndSkill;
