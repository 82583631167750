import React, { useState, useEffect, useRef, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import  NotAvailable from "../NotAvailable/NotAvailable";
import ViewProfileHeading from '../ViewProfileHeading';
import parse from 'html-react-parser';
import DOMPurify from 'dompurify'; 

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  section: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
    textAlign: "left",
  },
  arrayText: {
    fontSize: "12px",
    color: "black",
  },
  arrayItem: {
    paddingLeft: "25px",
    color: "black",
    font: '8px'
  },
}));

export default function ShowHTML(props) {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <ViewProfileHeading title={props.heading} />
      <Grid container className={classes.arrayItem}>
        {props.data && props.data.length > 0 &&
          <Typography className={classes.arrayText} >{parse(DOMPurify.sanitize(props.data))}</Typography>
        }
        {(!props.data || props.data.length == 0 ||props.data[0]=="") &&
          <NotAvailable/>
        }
      </Grid>

    </div>
  );
}
