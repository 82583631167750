import React, { useEffect, useState, useContext } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import {
    CoachProfileProvider,
    CoachProfileConsumer,
    AdminCoachProfileContext
} from "../../common/AdminCoachProfileContext";
import CoachProfileView from "./CoachProfileView";


const CoachProfileViewContainer = (props) => {
    let isViewMode = false;

    const [params, setParams] = useState(props.match.params.userName);

    useEffect(() => {
        setParams(props.match.params.userName)
    }, [props])

    return (
        <CoachProfileProvider>
            <CoachProfileConsumer>
                {(props) => {
                    return (
                        <div>
                            <CoachProfileView params={params} />
                        </div>)
                }}
            </CoachProfileConsumer>
        </CoachProfileProvider>
    );
}

export default CoachProfileViewContainer 