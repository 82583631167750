import Axios from 'axios';
import { authHeader } from '../helper';

Axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

const webAPIURL = () => {
    let url = '/api';
    return url
};

const instance = Axios.create({
    withCredentials: true,
    baseURL: webAPIURL(),
    timeout: 60000
})

export default class APIHelper {

    static apiUrl() {
        return webAPIURL();
    }

    static get(url, headers={}, obj) {
        return new Promise((resolve, reject) => {
            instance
                .get(url, {headers})
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error);
                });
        });
    }

    static post(url, obj, headers={'Content-Type': 'application/json', Accept: 'application/json'}) {

        return new Promise((resolve, reject) => {
            instance
                .post(url, obj, {headers})
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error);
                });
        });
    }

    static delete(url, headers={'Content-Type': 'application/json', Accept: 'application/json'}) {
        return new Promise((resolve, reject) => {
            instance
                .delete(url,{headers})
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error);
                });
        });
    }
}