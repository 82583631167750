import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

function DownloadIcon(props) {
    return (
        <SvgIcon {...props}>
            <path fill="currentColor" d="M9,1V7H5L12,14L19,7H15V1H9M5,16V18H19V16H5M5,20V22H19V20H5Z" />
        </SvgIcon>
    );
}

export default DownloadIcon;