export default class DataMapper {
    static GetTextByValue(val) {
        return DataMap[val];
    }
}

export const DataMap = {
    // "nba": "NBA",
    // "wnba": "WNBA",
    // "nbagleague": "NBA G League",
    // "ncaa": "College/University",
    // "international": "International",
    // "highschool": "High School",
    // "other": "Other",
    "unemployed": "In between coaching jobs",
    "baskatballAnalytics": "Basketball Analytics",
    "basketballoperations": "Basketball Operations",
    "mediaBroadcasting": "Media/Broadcasting",
    "playerPersonnel": "Player Personnel",
    //"na": "N/A",
    "Coach": "Coach",
    "FrontOffice": "Front Office",
    "FrontOfficePlusCoach": "Front Office/Coach",
    "Admin": "Admin"
}