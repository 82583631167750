import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import CardAccountDetailsOutline from "../../sharedComponents/customIcons/CardAccountDetailsOutline";
import AppConstants from "../../common/AppConstants";
import QueueOutlinedIcon from "@material-ui/icons/QueueOutlined";
import CardAccountDetailsStarOutline from "../../sharedComponents/customIcons/CardAccountDetailsStarOutline";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  tab: {
    fontSize: theme.typography.pxToRem(12),
  },
  tabsColor: {
    '& button': {
      color: '#ffffff !important',
    },
  }
}));


const FrontOfficeCoachesAppBar = (props) => {
  const classes = useStyles();

  return (
    <AppBar position="static" color="primary" elevation={1}>
      <Tabs
        value={props.tabIndex}
        onChange={props.handleChange}
        scrollButtons="auto"
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        aria-label="profile tabs"
        className={classes.tabsColor}
      >
        <Tab
          className={classes.tab}
          icon={<CardAccountDetailsOutline />}
          label={
            !props.isXsDown() && AppConstants.DASHBOARD_SECTION_TITLE.AllProfiles
          }
          {...props.a11yProps(0)}
        />
        <Tab
          className={classes.tab}
          icon={<CardAccountDetailsStarOutline />}
          label={
            !props.isXsDown() &&
            AppConstants.DASHBOARD_SECTION_TITLE.BookmarkedProfiles
          }
          {...props.a11yProps(1)}
        />
        <Tab
          className={classes.tab}
          icon={<QueueOutlinedIcon />}
          label={
            !props.isXsDown() &&
            AppConstants.DASHBOARD_SECTION_TITLE.RecentlyAddedProfiles
          }
          {...props.a11yProps(2)}
        />

        {props.foTabs && props.foTabs.length > 0 &&
          props.foTabs.map((val, ind) => {
            return (
              <Tab
                className={classes.tab}
                icon={<QueueOutlinedIcon />}
                label={                  
                  val.name
                }
                {...props.a11yProps(3 + parseInt(ind))}
              />)
          })
        }

      </Tabs>
    </AppBar>
  )
}

export default FrontOfficeCoachesAppBar;