import React, { Component } from 'react';
import ErrorUI from '../emptyStateUIContainers/ErrorUI';

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);

        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        //... TODO: Call error logging API to log the client side errors.
    }

    render() {
        if (this.state.hasError) {
            return <ErrorUI></ErrorUI>;
        }

        return this.props.children;
    }
}

export default ErrorBoundary;