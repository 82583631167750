import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import React, { useContext, useEffect, useState } from "react";
import NBAChart from "../../reports/NBAChart";

import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import GetAppIcon from "@material-ui/icons/GetApp";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { Link } from "react-router-dom";
import RouteConstants from "../../common/RouteConstants";
import { FilterContext } from "../../common/FilterContext";
import { userService } from "../../services";

export const adminDashboardContext = React.createContext();

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        //   margin: '25px',
        width: '100%',
        flexWrap: 'wrap',
        justifyContent: 'flex-start',
        [theme.breakpoints.up("sm")]: {
            padding: theme.spacing(1),
        },
    },
    gridItem: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        width: '100%',
        flexWrap: 'wrap'
    },
    card: {
        height: "400px",
        border: "1px solid #e8e8e8",
        boxShadow: "0px 3px 5px 2px rgba(0, 0, 0, 0.1)",
        marginBottom: "25px",
        marginRight: "25px"
    },
    smallCard: {
        height: "125px",
        border: "1px solid #e8e8e8",
        boxShadow: "0px 3px 5px 2px rgba(0, 0, 0, 0.1)",
        marginBottom: "25px",
        marginRight: "25px"
    },
    CardContent: {
        paddingBottom: "0px !important",
    },
    smallCardContent: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontWeight: 'bolder',
        fontSize: '24px',
        color: '#c9082a',
        paddingBottom: "0px !important",
    },
    cardHeader: {
        height: "20px",
    },
    cardActions: {
        float: 'right'
    },
    downloadPDF: {
        display: 'flex',
        width: '100%',
        marginBottom: theme.spacing(2),
        '& h6': {
            flex: '1',
        },
    },
    downloadAction: {
        marginLeft: "auto",
        color: "#000",
    },
}));

const AdminDashboard = (props) => {
    const classes = useStyles();
    const { setDataFromAnalytics, refreshFilterData } = useContext(FilterContext)

    const [userRolesSeries, setUserRolesSeries] = useState([0, 0, 0]);

    const [users, setUsers] = useState([]);
    const [totalCoach, setTotalCoach] = useState(0);
    const [HeadCoachCount, setHeadCoachCount] = useState(0);
    const [AssistantCoachCount, setAssistantCoachCount] = useState(0);
    const [OtherExperienceCount, setOtherExperienceCount] = useState(0);
    const [NoOfFormerPlayers, setNoOfFormerPlayers] = useState(0);
    const [unEmployedCount, setUnEmployedCount] = useState(0);
    const [leagueCoaches, setLeagueCoaches] = useState({ labels: ["NBA", "WNBA", "G-League"], series: [0, 0, 0] });
    const [coachStatus, setCoachStatus] = useState({ labels: ["Approved", "Pending", "On Hold"], series: [0, 0, 0] });
    const [coachWorking, setCoachWorking] = useState({ labels: ["Currently with a Team", "Not Currently with a Team"], series: [0, 0] });
    const [currentlyWorkingLeagueCoaches, setCurrentlyWorkingLeagueCoaches] = useState({ labels: ["NBA", "WNBA", "G-League", "Other"], series: [0, 0, 0, 0] });
    const byLeaugeTitle = "By Leauge";
    const byStatusTitle = "By Status";
    const byEmploymentStatus = "By Employment Status";
    const currentlyWorkingHeadCoaches = "Currently working Head Coaches";

    useEffect(() => {
        userService.getAnalytics()
            .then(analytics => {
                setTotalCoach(analytics.TotalCoachesCount);
                setHeadCoachCount(analytics.CurrentWorkingHeadCoaches);
                setAssistantCoachCount(analytics.AssistantCoachCount);
                setOtherExperienceCount(analytics.OtherExperienceCount);
                setNoOfFormerPlayers(analytics.WasFormerPlayerCount);
                setUnEmployedCount(analytics.NotWorkingCoachesCount);

                setLeagueCoaches({
                    labels: ["NBA", "WNBA", "G-League"],
                    series: [analytics.CurrentlyWorkingWithNBACount, analytics.CurrentlyWorkingWithWNBACount, analytics.CurrentlyWorkingWithGLeagueCount]
                });
                setCoachStatus({
                    labels: ["Approved", "Pending", "On Hold"],
                    series: [analytics.ApprovedProfilesCount, analytics.PendingProfilesCount, analytics.OnHoldProfilesCount]
                });
                setCoachWorking({
                    labels: ["Employed", "Not Employed"],
                    series: [analytics.WorkingCoachesCount, analytics.NotWorkingCoachesCount]
                });
                setCurrentlyWorkingLeagueCoaches({
                    labels: ["NBA", "WNBA", "G-League", "Other"],
                    series: [analytics.CurrentNBAHeadCoaches, analytics.CurrentWNBAHeadCoaches, analytics.CurrentGLeagueHeadCoaches, analytics.CurrentOtherHeadCoaches]
                });
                // setUsers(usersList);
                // let adminUsers = usersList && usersList.length > 0 ? usersList.filter(u => u.role && u.role == 'Admin') : [];
                // let frontOfficeUsers = usersList && usersList.length > 0 ? usersList.filter(u => u.role && u.role == 'FrontOffice') : [];

                // setUserRolesSeries([adminUsers.length, frontOfficeUsers.length, usersList.length - adminUsers.length - frontOfficeUsers.length]);

                // let activeUsers = usersList && usersList.length > 0 ? usersList.filter(u => u.isActive) : [];
                // setUserStatusSeries([activeUsers.length, usersList.length - activeUsers.length]);
            })
    }, []);


    const onChartClick = (data) => {
        if (data.title) {
            refreshFilterData();
            switch (data.title) {
                case byLeaugeTitle:
                    {
                        if (data.selected === "G-League") {
                            data.selected = "nbagleague"
                        }
                        setDataFromAnalytics({ section: "championship", selectedFilter: [data.selected] });
                    }
                    break;
                case byStatusTitle:
                    switch (data.selected) {
                        case "Approved":
                            data.selected = 4;
                            data.selectedView = 5;
                            break;
                        case "Pending":
                            data.selected = 3;
                            data.selectedView = 7;
                            break;
                        case "On Hold":
                            data.selected = 5;
                            data.selectedView = 6;
                            break;

                    }
                    setDataFromAnalytics({ section: "Tab", selectedFilter: data.selected, selectedView: data.selectedView });
                    break;
                case byEmploymentStatus:
                    {
                        // if (data.selected === "Employed") {
                        //     data.selected = "Currently with a Team"
                        // }
                        // else {
                        //     data.selected = "Not Currently with a Team"
                        // }
                        setDataFromAnalytics({ section: "currentWork", selectedFilter: [data.selected] });
                    }
                    break;
                case currentlyWorkingHeadCoaches:
                    {
                        if (data.selected === "G-League") {
                            data.selected = "nbagleague"
                        }
                        if (data.selected === "Other") {
                            setDataFromAnalytics({ section: "headCoach", selectedFilter: ["summerleague", "ncaa", "international", "highschool", "other"] });
                        }
                        else {
                            setDataFromAnalytics({ section: "headCoach", selectedFilter: [data.selected != null ? data.selected : data.selected] });
                        }
                    }

                    break;
            }
        }
        const { from } = { from: { pathname: RouteConstants.COACH_MANAGEMENT } };
        props.history.push(from);
    }

    const RedirectToCoachesManagementPage = () => {
        refreshFilterData();
        const { from } = { from: { pathname: RouteConstants.COACH_MANAGEMENT } };
        props.history.push(from);

    }
    function donwloadAnalyticsPDF(event) {
        html2canvas(document.getElementById('divToPrint'))
            .then((canvas) => {
                const imgData = canvas.toDataURL('image/png');
                const pdf = new jsPDF({
                    orientation: "landscape",
                });
                const width = pdf.internal.pageSize.getWidth();
                const height = pdf.internal.pageSize.getHeight();
                pdf.addImage(imgData, 'JPEG', 0, 0, width, height);
                // pdf.output('dataurlnewwindow');
                pdf.save("CoachAnalytics.pdf");
            })
            ;
        // event.preventDefault();
        // var xhr = userService.downloadAnalyticsPDF();
        // xhr.onload = function (e) {
        //     if (this.status == 200) {
        //         let fileName = "CoachAnalytics";
        //         var blob = new Blob([this.response], { type: "application/pdf" });
        //         var link = document.createElement("a");
        //         link.href = window.URL.createObjectURL(blob);
        //         link.download = fileName + ".pdf";
        //         link.click();
        //     }
        // };
        // xhr.send();
    }
    return (
        // <div style={{display: 'flex', width: '100%', flexWrap: 'wrap', padding: '25px', justifyContent: 'flex-start'}}>
        <div id="divToPrint">
            <Grid container className={classes.root}>
                <Grid container>
                    <Grid item xs={12}>
                        <Box display="flex" alignItems="center" className={classes.downloadPDF}>
                            <Typography variant="h6" component="h6" align="center" color="primary">NBA Coaches Database Insights</Typography>
                            <Tooltip title="Download Analytics PDF">
                                <div>
                                    <Link to="#" onClick={donwloadAnalyticsPDF}>
                                        <IconButton
                                            aria-label="download"
                                            className={classes.downloadAction}
                                        >
                                            <GetAppIcon />
                                        </IconButton>
                                    </Link>
                                </div>
                            </Tooltip>
                        </Box>
                    </Grid>
                </Grid>
                <Grid container className={classes.gridItem}>
                    <Card className={classes.smallCard}>
                        <CardHeader className={classes.cardHeader}
                            title={<Typography variant="subtitle2" color="primary">Total Coaches</Typography>}
                        />
                        <Divider></Divider>
                        <CardContent className={classes.smallCardContent}>
                            {totalCoach}
                        </CardContent>
                    </Card>
                    <Card className={classes.smallCard}>
                        <CardHeader className={classes.cardHeader}
                            title={<Typography variant="subtitle2" color="primary">Not Currently with a Team</Typography>}
                        />
                        <Divider></Divider>
                        <CardContent className={classes.smallCardContent}>
                            {unEmployedCount}
                        </CardContent>
                    </Card>
                    <Card className={classes.smallCard}>
                        <CardHeader className={classes.cardHeader}
                            title={<Typography variant="subtitle2" color="primary">No. of Former Player</Typography>}
                        />
                        <Divider></Divider>
                        <CardContent className={classes.smallCardContent}>
                            {NoOfFormerPlayers}
                        </CardContent>
                    </Card>
                    <Card className={classes.smallCard}>
                        <CardHeader className={classes.cardHeader}
                            title={<Typography variant="subtitle2" color="primary">Current Head Coaches</Typography>}
                        />
                        <Divider></Divider>
                        <CardContent className={classes.smallCardContent}>
                            {HeadCoachCount}
                        </CardContent>
                    </Card>
                    <Card className={classes.smallCard}>
                        <CardHeader className={classes.cardHeader}
                            title={<Typography variant="subtitle2" color="primary">Current Assistant Coaches</Typography>}
                        />
                        <Divider></Divider>
                        <CardContent className={classes.smallCardContent}>
                            {AssistantCoachCount}
                        </CardContent>
                    </Card>
                    {/* <Card className={classes.smallCard}>
                        <CardHeader className={classes.cardHeader}
                            title={<Typography variant="subtitle2" color="primary">Other Experience Coaches</Typography>}
                        />
                        <Divider></Divider>
                        <CardContent className={classes.smallCardContent}>
                            {OtherExperienceCount}
                        </CardContent>
                    </Card> */}
                </Grid>
                <Grid container className={classes.gridItem}>
                    <Card className={classes.card}>
                        <CardHeader className={classes.cardHeader}
                            title={<Typography variant="subtitle2" color="primary">By League Current Status</Typography>}
                        />
                        <Divider></Divider>
                        <CardContent className={classes.CardContent}>
                            <NBAChart series={leagueCoaches.series} labels={leagueCoaches.labels} width="350" onChartClick={onChartClick} graphTitle={byLeaugeTitle} />
                        </CardContent>
                        {/* <CardActions className={classes.cardActions}>
                            <Button data-html2canvas-ignore="true" variant="contained" color="primary" size="small" endIcon={<ArrowForwardIcon />} onClick={RedirectToCoachesManagementPage}>
                                More
                        </Button>
                        </CardActions> */}
                    </Card>
                    <Card className={classes.card}>
                        <CardHeader className={classes.cardHeader}
                            title={<Typography variant="subtitle2" color="primary">By Status</Typography>}
                        />
                        <Divider></Divider>
                        <CardContent className={classes.CardContent}>
                            <NBAChart series={coachStatus.series} labels={coachStatus.labels} width="350" onChartClick={onChartClick} graphTitle={byStatusTitle} />
                        </CardContent>
                        {/* <CardActions className={classes.cardActions}>
                            <Button data-html2canvas-ignore="true" variant="contained" color="primary" size="small" endIcon={<ArrowForwardIcon />} onClick={RedirectToCoachesManagementPage}>
                                More
                        </Button>
                        </CardActions> */}
                    </Card>
                    {/* <Card className={classes.card}>
                        <CardHeader className={classes.cardHeader}
                            title={<Typography variant="subtitle2" color="primary">Current Status</Typography>}
                        />
                        <Divider></Divider>
                        <CardContent className={classes.CardContent}>
                            <NBAChart series={coachWorking.series} labels={coachWorking.labels} width="370" onChartClick={onChartClick} graphTitle={byEmploymentStatus} />
                        </CardContent>
                        
                    </Card> */}
                    <Card className={classes.card}>
                        <CardHeader className={classes.cardHeader}
                            title={<Typography variant="subtitle2" color="primary">Currently working Head Coaches</Typography>}
                        />
                        <Divider></Divider>
                        <CardContent className={classes.CardContent}>
                            <NBAChart series={currentlyWorkingLeagueCoaches.series} labels={currentlyWorkingLeagueCoaches.labels} width="370" onChartClick={onChartClick} graphTitle={currentlyWorkingHeadCoaches} />
                        </CardContent>
                        {/* <CardActions className={classes.cardActions}>
                            <Button data-html2canvas-ignore="true" variant="contained" color="primary" size="small" endIcon={<ArrowForwardIcon />} onClick={() => { onChartClick({ title: "Currently working Head Coaches", selected: null }) }}>
                                More
                        </Button>
                        </CardActions> */}
                    </Card>
                    {/* <Card className={classes.card}>
                    <CardHeader className={classes.cardHeader}
                        title={<Typography variant="subtitle2" color="primary">Currently Working / Available</Typography>}
                    />
                    <Divider></Divider>
                    <CardContent className={classes.CardContent}>
                        <NBAChart series={coachesStatusSeries} labels={coachesStatusLabels} width="400" />
                    </CardContent>
                    <CardActions className={classes.cardActions}>
                        <Button variant="contained" color="primary" size="small" endIcon={<ArrowForwardIcon />} onClick={RedirectToCoachesManagementPage}>
                            More
                        </Button>
                    </CardActions>
                </Card> */}
                </Grid>
                <Grid container className={classes.gridItem}>
                    {/* <Card className={classes.card}>
                    <CardHeader className={classes.cardHeader}
                        title={<Typography variant="subtitle2" color="primary">User Roles</Typography>}
                    />
                    <Divider></Divider>
                    <CardContent className={classes.CardContent}>
                        <NBAChart series={userRolesSeries} labels={["Admin", "Front Office", "Coaching Candidate"]} width="400"/>
                    </CardContent>
                    <CardActions className={classes.cardActions}>
                        <Button variant="contained" color="primary" size="small" endIcon={<ArrowForwardIcon />} onClick={RedirectToUserManagementPage}>
                            More
                        </Button>
                    </CardActions>
                </Card>
                <Card className={classes.card}>
                    <CardHeader className={classes.cardHeader}
                        title={<Typography variant="subtitle2" color="primary">Active / Inactive users</Typography>}
                    />
                    <Divider></Divider>
                    <CardContent className={classes.CardContent}>
                        <NBAChart series={userStatusSeries} labels={["Active users", "Inactive users"]} width="375"/>
                    </CardContent>
                    <CardActions className={classes.cardActions}>
                        <Button variant="contained" color="primary" size="small" endIcon={<ArrowForwardIcon />} onClick={RedirectToUserManagementPage}>
                            More
                        </Button>
                    </CardActions>
                </Card> */}
                </Grid>
            </Grid>

        </div>
    )
}

export default AdminDashboard;