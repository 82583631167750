import { authenticationService } from '../services';

export function handleError(error) {
    return new Promise((resolve, reject) => {
        const errorState = error.response;
        if (errorState) {
            if ([401, 403].indexOf(errorState.status) !== -1) {
                // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
                authenticationService.logout();
                location.reload(true);
            }
    
            const error = errorState && errorState.message;
            reject(error);
        }

        resolve(errorState);
    });
}