import { authHeader, handleResponse, handleError } from '../helper';
import { authenticationService } from '../services';
import APIHelper from "../common/ApiHelper";
import APIURLConstants from "../common/ApiURLConstants";

const frontOfficeService = {
    getCoachProfileList,
    getSearchedCoachProfileList,
    setBookmarkProfile,
};

function getCoachProfileList(section) {
    return APIHelper.get(APIURLConstants.GET_Coach_PROFILE_LIST + section, authHeader())
        .then(handleResponse)
        .then(data => {
            return data;
        }).catch(handleError);
}


function getSearchedCoachProfileList(postData) {
    return APIHelper.post(APIURLConstants.GET_SEARCHED_PROFILES, postData, authHeader())
        .then(handleResponse)
        .then(data => {
            return data;
        })
        .catch(handleError);
}


function setBookmarkProfile(postData) {
    return APIHelper.post(APIURLConstants.FOR_BOOKMARK_PROFILE, postData, authHeader())
        .then(handleResponse)
        .then(data => {
            return data;
        }).catch(handleError);
}

export default frontOfficeService;