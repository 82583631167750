import React, { useState, useEffect, useRef, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Helper from "../../../../common/Helper";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  section: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
    borderLeft: "5px solid lightgray",
    paddingLeft: "20px",
    // width: "50%",
  },
  teamName: {
    fontWeight: "bold",
    fontSize: "12px",
    color: "black",
  },
  dates: {
    fontSize: "12px",
  },
  headCoach: {
    fontSize: "12px",
    color: "black",
  },
  teams: {
    fontSize: '0.9rem',
    fontWeight:'500',
},
}));

export default function CoachingExperiene(props) {
  const classes = useStyles();
  const [experience, setExperience] = useState(
    props.experience ? props.experience : []
  );
  return (
    <>
      {props.league == "na" &&
        <Grid item md={4} sm={6} xs={12}>
          <div className={classes.section}>
            <Typography color="primary">{props.league}</Typography></div></Grid>
      }

      {experience.map((data, index) => {
        return (
          <Grid item md={4} sm={6} xs={12}>
            <div key={index} className={classes.section}>
              <Typography color="primary" className={classes.teams}>{props.league}</Typography>
              <Typography className={classes.teamName}>
                {data.team.teamName}
              </Typography>
              {data.title && data.title.Name && data.title.Name != '' && (
                <Typography className={classes.dates}>
                  {data.title.Name}
                </Typography>
              )}
              {props.championship && (
                <Typography className={classes.dates}>
                  Season- {data.season}
                </Typography>
              )}

              {(props.headcoaches ||
                props.asscoaches ||
                props.playingExperience) && (
                  <Typography className={classes.dates}>
                    From- {Helper.FormatDateMMYYYY(data.fromDate)} To-{" "}
                    {Helper.FormatDateMMYYYY(data.toDate)}
                  </Typography>
                )}

              {props.useTitle && (
                <Typography>Title: {props.titleName}</Typography>
              )}

              {(props.asscoaches || props.playingExperience) && (
                <>
                  <Typography className={classes.headCoach}>
                    Head Coach: {data.headCoachName.join(", ")}
                  </Typography>
                </>
              )}
            </div>
          </Grid>
        );
      })}
    </>
  );
}
