import React, { useEffect, useState, useRef } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import ListItem from "@material-ui/core/ListItem";
import SaveIcon from "@material-ui/icons/Save";
import CancelPresentationIcon from "@material-ui/icons/CancelPresentation";

import SelectLeagueOptions from "../../../sharedComponents/questionComponents/SelectLeagueOptions";
import TitleWithTooltip from '../../../sharedComponents/TitleWithTooltip';
import SaveButton from "../../../sharedComponents/saveButton/SaveButton";
import BackButton from "../../../sharedComponents/saveButton/BackButton";
import CaptionMsg from "../../../sharedComponents/CaptionMsg";
import AwardsAndAllocades from "../../../sharedComponents/questionComponents/AwardsAndAllocades";
import useTelemetry from "../../../utils/useTelemetry";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  section: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  grid: {
    padding: theme.spacing(1),
    alignSelf: "center",
  },
  question: {
    color: theme.palette.info,
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(1),
    },
  },
}));

const PlayingExperience = (props) => {
  const classes = useStyles();
  const container = useRef(null);
  const { trackCustomEvents } = useTelemetry();
  // const [availableAwards, setAvailableAwards] = useState(
  //   props.awardsAndAllocades.filter(x => { x.award.Name = (props.awards.map(p => p.Name)) ? x.award.Name : ""; return x; })
  // );

  // useEffect(() => {
  //   setAvailableAwards(
  //   props.awardsAndAllocades.filter(x => { x.award.Name = (props.awards.map(p => p.Name)) ? x.award.Name : ""; return x; })
  //   )
  // }, [props.awardsAndAllocades]);

  useEffect(() => {
    container.current.scrollIntoView({ block: 'end', behavior: 'smooth' });
  }, []);

  function save() {
    props.onChange();
    trackCustomEvents("EditEvent", { action: "Playing Experience" });
  }
  return (
    <div ref={container} className={classes.root}>
      <div className={classes.section}>
        <TitleWithTooltip
          title="1. Are you a former basketball player?"
          tooltipTitle="Former_Basketball_ToolTip"
          variant="body2"
          class={classes.question}
        />
        <CaptionMsg message="(Please type in the values if you don't find them listed.)" />
        <SelectLeagueOptions
          data={props.formerExperience}
          teamList={props.teamList}
          coachTitle={props.positionTitle}
          headCoachesList={props.headCoachesList}
          onChange={props.onFormerExperienceChange}
          leagueOptionsList={props.leagueOptionsList}
          showCurrentCheckBox={false}
          useHeadCoach={true}
          isInValid={props.isInValid}
        />
      </div>

      <div className={classes.section}>
        <TitleWithTooltip
          title="2. Have you won a championship as a player?"
          tooltipTitle="Championship_ToolTip"
          variant="body2"
          class={classes.question}
        />
        <CaptionMsg message="(Please type in the values if you don't find them listed.)" />
        <SelectLeagueOptions
          championship={true}
          leagueOptionsList={props.leagueOptionsList}
          teamList={props.teamList}
          data={props.championship}
          useHeadCoach={true}
          onChange={props.onChampionshipChange}
          isInValid={props.isInValid}
        />
      </div>

      <div className={classes.section}>
        <TitleWithTooltip
          title=" 3. Have you received any playing awards or accolades?"
          tooltipTitle="Award_ToolTip"
          variant="body2"
          class={classes.question}
        />
        <CaptionMsg message="(Please type in the values if you don't find them listed.)" />
        <AwardsAndAllocades awards={props.awards} data={props.awardsAndAllocades}
          onDataChange={props.onChangeAwardsAndAllocades} type="player" />
      </div>
      <Grid item md={12} xs={12}>
        <ListItem style={{ justifyContent: "flex-end" }}>
          <BackButton
            onClick={props.handlePreveStepClick}
          ></BackButton>
          <SaveButton isLoading={props.isLoading} onClick={save}></SaveButton>
        </ListItem>
      </Grid>
    </div>
  );
};
export default PlayingExperience;
