import React, {
    Fragment,
    useState,
    forwardRef,
    useEffect,
    useRef,
    useImperativeHandle,
} from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import ToolTip from "@material-ui/core/Tooltip";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import DeleteIcon from "@material-ui/icons/Delete";
import Helper from "../../common/Helper";

import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    grid: {
        padding: theme.spacing(1),
        alignSelf: "center",
        display: "flex",
    },
    gridIcons: {
        display: 'flex',
        '& button': {
            [theme.breakpoints.down("xs")]: {
                padding: theme.spacing(.75),
            },
        },
    },
    error: {
        borderColor: theme.palette.error.dark,
    },
    btnDel: {
        color: theme.palette.secondary.main,
    },
}));

let Data = [];
const QualificationDetails = (props) => {

    const [formDataCollection, setFormData] = useState([getEmptyItem()]);
    const [schools, setSchools] = useState(props.schools);
    // const [categoryList, setCategoryList] = useState([
    //     { Id: "1", category: "Undergraduate Degree" },
    //     { Id: "2", category: "Graduate Degree" },
    //     { Id: "3", category: "Coaching Certifications" },
    //     { Id: "4", category: "Other Qualification" },
    // ]);

    const degreeList = props.degree;
    useEffect(() => {
        //if (props.data !== Data) {
        setSchools(props.schools);
        Data = props.data;

        let detail = formDataFromProp();
        if (Data.length == 0) {
            detail.push(getEmptyItem());
        }
        setFormData(detail);
        // }
    }, [props.data]);

    function formDataFromProp() {
        if (props.data) {
            return props.data.map((item) => {
                return {
                    id: item.id,
                    minor_major: { value: item.minor_major, validationMessage: "", hasError: null },
                    degree: {
                        value: item.degree.Id,
                        lable: item.degree.Name,
                        validationMessage: "",
                        hasError: item.degree.Name ? null :true,
                    },
                    university: {
                        value: item.university,
                        validationMessage: "",
                        hasError: null,
                    },
                };
            });
        } else {
            return [];
        }
    }

    function getEmptyItem() {
        let randomId = Helper.GetRandomId();
        return {
            id: randomId,
            minor_major: { value: "", validationMessage: "", hasError: null },
            // category: { value: "", lable: "", validationMessage: "", hasError: null },
            degree: { value: "", lable: "", validationMessage: "", hasError: null },
            university: { value: "", validationMessage: "", hasError: null },
        };
    }

    function handleInputChange(e, hasError) {
        let data = [...formDataCollection];
        let index = data.findIndex((x) => x.id === e.itemId);
        data[index] = e.formData;
        setFormData(data);
        if (!hasError || hasError == undefined)
            props.onChange(data, "onchange");
    }

    function addItem() {
        //... Add a new item to the formDataCollection array.
        let dataCollection = [...formDataCollection];
        let lastData = dataCollection[dataCollection.length - 1];
        if (
            lastData.minor_major.value.trim() === "" ||
            lastData.degree.value.trim() === "" ||
            lastData.university.value.trim() === ""
        ) {
            lastData.minor_major.value.trim() === ""
                ? (lastData.minor_major.hasError = true)
                : (lastData.minor_major.hasError = false);
            lastData.degree.value.trim() === ""
                ? (lastData.degree.hasError = true)
                : (lastData.degree.hasError = false);
            lastData.university.value.trim() === ""
                ? (lastData.university.hasError = true)
                : (lastData.university.hasError = false);
            handleInputChange({ itemId: lastData.id, formData: lastData }, true);
        } else {
            lastData.minor_major.hasError = false;
            lastData.degree.hasError = false;
            lastData.university.hasError = false;

            dataCollection.push(getEmptyItem());
            //props.onChange(dataCollection);
            setFormData(dataCollection);
        }
    }

    function removeItem(itemId) {
        //... Remove an item from formDataCollection array.
        let DataCollection = [...formDataCollection];
        let index = DataCollection.findIndex((x) => x.id === itemId);
        DataCollection.splice(index, 1);
        props.onChange(DataCollection, "onchange");
        setFormData(DataCollection);

        // this.notifyParentAboutDataChange();
    }

    return (
        <Grid container>
            {formDataCollection.map((item, index) => {
                return (
                    <Qualification
                        key={index}
                        addItem={addItem}
                        itemRow={item}
                        schools={schools}
                        onChange={handleInputChange}
                        showAddOption={index === formDataCollection.length - 1}
                        removeItem={removeItem}
                        degreeList={degreeList}
                    />
                );
            })}
        </Grid>
    );
};

function Qualification(props) {
    const classes = useStyles();
    ///const cateorySelected = getCategoryValue(props.itemRow.category.value);    
    const degreeSelected = getDegreeValue(props.itemRow.degree.lable);
    const schoolSelected = getSchoolValue(props.itemRow.university.value);
    function handleAddClick() {
        props.addItem();
    }

    function handleInputChange(name, newValue) {
        let formData = props.itemRow;
        formData[name].value = newValue.Id;
        formData[name].lable = newValue.Name;
        formData[name].hasError = false;
        //... Call Parent
        props.onChange({ itemId: props.itemRow.id, formData: formData });
    }

    function handelOnKeyUp(e, field) {
        if (e.target.value != "") {
            var isExist = {};
            if (field == "degree") {
                isExist = props.degreeList.find(x => x.Name === e.target.value)
            }
            else { isExist = props.schools.find(x => x.Name === e.target.value) }
            if (isExist === undefined) {
                let formData = props.itemRow;
                if (field == "degree") {
                    formData[field].value = "New";
                }
                else { formData[field].value = e.target.value; }
                formData[field].hasError = false;
                formData[field].lable = e.target.value;
                //... Call Parent
                props.onChange({ itemId: props.itemRow.id, formData: formData });

            }
        }
    }

    function handleTextInputChange(name, value) {
        let formData = props.itemRow;
        formData[name].value = value;
        formData[name].lable = name;
        formData[name].hasError = false;
        //... Call Parent
        props.onChange({ itemId: props.itemRow.id, formData: formData });
    }

    function getDegreeValue(val) {
        let item = [...props.degreeList];
        let sele = item.find((x) => x.Name === val);
        return sele || { Name: val, Id: "New" };
    }

    function getSchoolValue(val) {
        let item = [...props.schools];
        let sele = item.find((x) => x.Name === val);
        return sele || { Name: val, Id: "New" };
    }

    return (
        <Fragment>
            {/* <Grid item md={4} xs={6} className={classes.grid}>
                <Autocomplete
                    value={cateorySelected}
                    id={`team_${props.itemRow.id}`}
                    size="small"
                    options={props.categoryList}
                    getOptionSelected={(option, { multiple, cateorySelected }) => {
                        if (!multiple) {
                            if (cateorySelected != null) {
                                return option.Id === cateorySelected.value;
                            }
                            return false;
                        }
                        return false;
                    }}
                    // renderOption={(option) => option.eventName}
                    getOptionLabel={(option) => option.category}
                    onChange={(event, newValue) => {
                        let val = newValue !== null ? newValue.Id : "";
                        handleInputChange("category", newValue);
                    }}
                    style={{ width: "100%" }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                            error={props.itemRow.category.hasError}
                            label={<Typography variant="body2">Category</Typography>}
                        />
                    )}
                />
            </Grid> */}
            <Grid item md={4} xs={6} className={classes.grid}>
                <Autocomplete
                    freeSolo
                    value={degreeSelected}
                    id={`team_${props.itemRow.id}`}
                    size="small"
                    options={props.degreeList}
                    getOptionSelected={(option, { multiple, degreeSelected }) => {
                        if (!multiple) {
                            if (degreeSelected != null) {
                                return option.Id === degreeSelected.value;
                            }
                            return false;
                        }

                        return false;
                    }}
                    // renderOption={(option) => option.eventName}
                    getOptionLabel={(option) => option.Name}
                    onChange={(event, newValue) => {
                        let val = newValue !== null ? newValue.Id : "";
                        handleInputChange("degree", newValue);
                    }}
                    onClose={(e) => handelOnKeyUp(e, "degree")}
                    style={{ width: "100%" }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                            error={props.itemRow.degree.hasError}
                            label={<Typography variant="body2">Degree</Typography>}
                        />
                    )}
                />
            </Grid>
            <Grid item md={4} xs={6} className={classes.grid}>
                <TextField
                    id={`minor_major${props.itemRow.id}`}
                    variant="outlined"
                    onChange={(event) => handleTextInputChange("minor_major", event.target.value)}
                    value={props.itemRow.minor_major.value}
                    label={<Typography variant="body2">Field Of Study</Typography>}
                    size="small"
                    fullWidth
                    error={props.itemRow.minor_major.hasError}
                />
            </Grid>
            <Grid item md={3} xs={6} className={classes.grid}>
                <Autocomplete
                    freeSolo
                    value={schoolSelected}
                    id={`team_${props.itemRow.id}`}
                    size="small"
                    options={props.schools}
                    getOptionSelected={(option, { multiple, schoolSelected }) => {
                        if (!multiple) {
                            if (schoolSelected != null) {
                                return option.name === schoolSelected.value;
                            }
                            return false;
                        }

                        return false;
                    }}
                    // renderOption={(option) => option.eventName}
                    getOptionLabel={(option) => option.Name}
                    onChange={(event, newValue) => {
                        let val = newValue !== null ? newValue.Name : "";
                        handleTextInputChange("university", val);
                    }}
                    onClose={(e) => handelOnKeyUp(e, "university")}
                    style={{ width: "100%" }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                            error={props.itemRow.university.hasError}
                            label={<Typography variant="body2">College/University</Typography>}
                        />
                    )}
                />

                {/* <TextField
          id={`season_${props.itemRow.id}`}
          error={props.itemRow.university.hasError}
          variant="outlined"
          value={props.itemRow.university.value}
          onChange={(e) =>
            handleUniversityInputChange("university", e.target.value)
          }
          label={<Typography variant="body2">University</Typography>}
          size="small"
          fullWidth
        /> */}
            </Grid>
            <Grid item md={1} xs={6} className={classes.gridIcons}>
                <ToolTip title="Remove">
                    <IconButton onClick={(e) => props.removeItem(props.itemRow.id)}>
                        <DeleteIcon className={classes.btnDel} >Delete</DeleteIcon>
                    </IconButton>
                </ToolTip>
                {props.showAddOption &&
                    <ToolTip title="Add more">
                        <IconButton onClick={handleAddClick}>
                            <AddCircleIcon color="primary" >Add</AddCircleIcon>
                        </IconButton>
                    </ToolTip>
                }
            </Grid>
        </Fragment>
    );
}

export default QualificationDetails;
