import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

function CreateShowcaseIcon(props) {
    return (
        <SvgIcon {...props}>
            <path fill="currentColor" d="M5.8 21L7.4 14L2 9.2L9.2 8.6L12 2L14.8 8.6L22 9.2L18.8 12H18C14.9 12 12.4 14.3 12 17.3L5.8 21M17 14V17H14V19H17V22H19V19H22V17H19V14H17Z" />
        </SvgIcon>
    );
}

export default CreateShowcaseIcon;