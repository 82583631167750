import React, { useEffect, useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";

const useStyles = makeStyles((theme) => ({
  checkBox: {
    minWidth: 0,
  },
  chips:{
    '&.MuiChip-root': {
      border: '1px solid #fff',
      color: '#fff',
      '& .MuiChip-deleteIcon': {
        color: '#fff',
      },
    },
  }
}));
export default function ShowFilter(props) {
  const classes = useStyles();
  const [list, setList] = useState([]);

  useEffect(() => {
    setList(props.list);   
  }, [props]);
  return list.map((item, index) => {
    return (<React.Fragment key={index}>
      <Chip
      className={classes.chips}
      variant="outlined"
        label={
          props.for != "league" && props.for !== "championship" && item
            ? (props.for=="currentWork" ? `Current Coaches-${item}` : 
            props.for=="formerCoaches" ? `Former Coaches-${item}` : props.for=="playingExperience" ?`Playing Experience-${item}`: item )
            : props.getLeagueTextByValue(item)
        }
        onDelete={() => props.onDelete(props.for, item)}
      />{" "} </React.Fragment>
    );
  });
}
