import React, { useContext, useState, useEffect, useRef } from 'react';
import { withRouter, useParams, } from 'react-router-dom';
import CKEditor from 'ckeditor4-react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    dialogHeight: {
        // minHeight: "500px",
        // maxHeight: "500px"
        overflow: 'hidden'
    },
    content: {
        margin: theme.spacing(4),
    }
}));
const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
        backgroundColor: theme.palette.primary.main,
        color: '#fff'
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography className="font500" variant="subtitle1" noWrap>{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        // padding: '25px 16px',
        padding: '0px 0px',
       // width: '500px',
        // [theme.breakpoints.down(281)]: {
        //     width: '218px',
        // },
        // [theme.breakpoints.between(281, 322)]: {
        //     width: '258px',
        // },
        // [theme.breakpoints.between(321, 556)]: {
        //     width: '275px'
        // },
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);
function ViewMemo(props) {
    const classes = useStyles();

    return (
        <>
            <div>
                <Dialog fullWidth={true} onClose={props.onCloseClick} aria-labelledby="customized-dialog-title"
                    open={props.open}>
                    <DialogTitle id="customized-dialog-title" onClose={props.onCloseClick}>
                        View Memo
                </DialogTitle>
                    <DialogContent dividers className={classes.dialogHeight}>
                        <Grid container alignItems='center'>
                            <Grid item lg={12} md={12} sm={12} xs={12} >
                                <div style={{width: '90%'}}>
                                    <Grid container direction="row" spacing={2} alignItems="center" className={classes.content}>
                                        <Grid item lg={12} md={12} sm={12} xs={12}>
                                            <Typography variant="subtitle1" noWrap>{props.data.Title}</Typography>
                                        </Grid>
                                        <Grid item lg={12} md={12} sm={12} xs={12}>
                                            <CKEditor
                                                config={{
                                                    toolbar: [],
                                                    removePlugins :"elementspath,resize",
                                                    allowedContent: 'p h1 h2 strong em s blockquote; a[!href]; img[!src,width,height];'    
                                                }}
                                                data={props.data.Content}
                                                // onChange={null}
                                                readOnly={true}
                                            />
                                        </Grid>
                                    </Grid>
                                </div>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        {/* <Button color="primary" variant='contained' onClick={props.onCloseClick}>Close</Button> */}
                    </DialogActions>
                </Dialog>
            </div>
        </>
    )
}

export default withRouter(ViewMemo);
