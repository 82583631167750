import React, { useEffect, useState,forwardRef, useImperativeHandle } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import useForm from '../customHooks/useForm';
import HTMLEditor from "../HTMLEditor/HTMLEditor";

const useStyles = makeStyles((theme) => ({
    grid: {
        padding: theme.spacing(1),
        alignSelf: 'center'
    },
    question: {
        color: theme.palette.primary.main,
        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(1)
        },
    }
}));

const RoleAndCoachingPhilosophy = ((props) => {
    const classes = useStyles();
    const [state, setState] = useState(props.data ? props.data : {});

    useEffect(() => {
       setState(props.data ? props.data : {});
    }, [props.data]);

    const { errors, handleInputChange, values } = useForm(state, props.onChange, props.validationSchema);

    return (
        <Grid container>
            <Grid item md={12} xs={12} className={classes.grid}>
                <HTMLEditor
                    id="description" name="description"
                    data={values && values.description ? values.description : ''}
                    onChange={handleInputChange}
                    error={errors && errors.description && errors.description.error}
                   // dangerouslySetInnerHTML={{__html: values && values.description ? values.description : ''}}
                />
                {/* <TextField id="description" variant="outlined"
                    //label={<Typography variant="body2"></Typography>}
                    size="small" fullWidth multiline rows={4}
                    value={values.description}
                    name="description"
                    inputProps={{maxLength: 180}}
                    onChange={handleInputChange}
                    error={errors && errors.description && errors.description.error} /> */}
            </Grid>
        </Grid>
    )
})

export default RoleAndCoachingPhilosophy;