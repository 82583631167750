import React, { useState, useEffect, useRef, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Typography,
  Grid,
} from "@material-ui/core/";
import  NotAvailable from "../../../sharedComponents/NotAvailable/NotAvailable";

import ViewProfileHeading from '../../../sharedComponents/ViewProfileHeading';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  card: {
    border: "1px solid gray",
    backgroundColor: "#F3F3F3",
    header: {
      backgroundColor: "#E5ECFC",
      color: "#2D4D89",
    },
  },
  header: {
    backgroundColor: "#E5ECFC",
    color: "#2D4D89",
    fontSize: "12px",
    fontWeight: "bold",
    padding: "3px 0 3px 10px",
  },
  subHeading: {
    backgroundColor: "#63C5DA",
    color: "#1520A6",
    padding: "5px 25px",
  },
  section: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
    textAlign: "left",
  },
  section2: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
    borderLeft: "5px solid lightgray",
    paddingLeft: "20px",
    // width: "50%",
  },
  grid: {
    padding: theme.spacing(1),
    alignSelf: "center",
  },
  gridInRow: {
    display: "flex",
    justifyContent: "space-between",
    paddingLeft: "25px",
  },
  notablePlayers: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    width: "95%",
    paddingLeft: "25px",
    lineHeight: "2",
    color: "black",
  },
  teamName: {
    fontWeight: "bold",
    fontSize: "12px",
    color: "black",
  },
  teams: {
    fontSize: '0.9rem',
    fontWeight:'500',
},
university: {
  fontSize: "12px",
  color: "black",
},
}));

const EducationalDetailView = (props) => {
  const classes = useStyles();
  const [educationDetail, seteducationDetail] = useState(
    props.data ? props.data : []
  );

  useEffect(() => {
    seteducationDetail(props.data ? props.data : []);
  }, [props.data])

  return (
    <div className={classes.root}>
      {/* /////////////////////////////// Events and Experience //////////////////////////// */}      
      <div className={classes.section}>
        <ViewProfileHeading title="Education" />
        <Grid container>
          <Grid item md={12} xs={12} className={classes.gridInRow}>
          <Grid container>
          {educationDetail.length==0 && <NotAvailable/>}
            {educationDetail.map((data, index) => {
              return (
                <Grid item md={4} sm={6} xs={12}>
                <div key={index} className={classes.section2}>                  
                  <Typography color="primary" className={classes.teams}>
                    {data.degree.Name}
                  </Typography>
                  <Typography className={classes.teamName}>
                    {data.minor_major}
                  </Typography>
                  <Typography className={classes.university}>
                    {data.university}
                  </Typography>
                </div>
                </Grid>
              );
            })}
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default EducationalDetailView;
