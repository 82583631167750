import React, { useEffect, useState } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import ProfileDetails from '../../screens/coachProfile/ProfileDetails';
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import ProfileSummary from "../../sharedComponents/profileSummary/ProfileSummary";
import AppHeaderBar from "../../sharedComponents/appBar/AppHeaderBar";
const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    dialogPaper: {
        minHeight: '80vh',
        maxHeight: '80vh',
    },
});

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
    },
    grid: {
        display: "flex",
        flexGrow: 1,
        flexWrap: "wrap",
        paddingTop: theme.spacing(1),
        backgroundColor: theme.palette.background.default,
        [theme.breakpoints.up("sm")]: {
            padding: theme.spacing(1),
        },
        [theme.breakpoints.down("xs")]: {
            padding: 0,
        },
    },
    section: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(3),
        textAlign: "left",
        padding: "5px 25px",
    },
    gridItem: {
        [theme.breakpoints.up("sm")]: {
            width: "100%",
            padding: theme.spacing(1),
        },
        [theme.breakpoints.down("xs")]: {
            width: "100%",
            paddingBottom: theme.spacing(2),
        },
    },
    profileSummary: {
        // paddingBottom: theme.spacing(2),
        minHeight: "300px",
        boxShadow: '0px 0px 5px rgba(6, 37, 91, .2)',
        borderRadius: '0',
        backgroundColor: '#06255b',
    },
    profileDetails: {
        textAlign: "left",
        color: theme.palette.text.secondary,
    },
}));


const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, jwtTokenDetails, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

export default function ProfileDialogs(props) {

    const classes = useStyles();
    const handleClose = () => {
        props.onClose();
    };

    const [data, setData] = useState(props.data && props.data ? props.data : []);
    const [personalDetails, setPersonalDetails] = useState(props.data && props.data.personalDetails ? props.data.personalDetails : []);
    const [profileCompleteness, setProfileCompleteness] = useState(0);
    useEffect(() => {
        setData(props.data ? props.data : []);
        setPersonalDetails(props.data && props.data.personalDetails ? props.data.personalDetails : []);
    }, [props.data])

    return (
        <div>
            <Dialog onClose={handleClose} fullWidth={true} fullScreen={true}
                maxWidth="lg" aria-labelledby="customized-dialog-title" open={props.open}>

                <DialogContent dividers onClose={handleClose}>
                    <Grid className={classes.grid}>
                        <Grid item md={3} sm={3} className={classes.gridItem}>
                            <Paper className={classes.profileSummary}>
                                <ProfileSummary
                                    profilePhoto={personalDetails.profilePhoto}
                                    profileVideo={personalDetails.profileVideo}
                                    fullName={personalDetails.fullName}
                                    userName={personalDetails.userName}
                                    roleAndCoachingPhilosophy={personalDetails.roleAndCoachingPhilosophy}
                                    mediaAndArticle={personalDetails.mediaAndArticle}
                                    coachingVideos={personalDetails.coachingVideos}
                                    jwtTokenDetails={props.jwtTokenDetails}
                                    profileCompleteness={false}
                                    userName={props.data && props.data.userName ? props.data.userName : ""}
                                    isViewMode={true} isCoachProfile={false}
                                    lastModifiedAt={data ? data.lastModifiedAt:''}></ProfileSummary>
                            </Paper>
                        </Grid>

                        <Grid item md={9} sm={9} className={classes.gridItem}>
                            <Paper className={classes.profileDetails}>
                                <ProfileDetails
                                    other={data.other}
                                    additionalExperience={data.additionalExperience}
                                    skillSet={data.skillSet}
                                    educationDetail={data.educationDetail && data.educationDetail.educationDetail ? data.educationDetail.educationDetail : []}
                                    coachingAndOtherJobExperience={data.coachingAndOtherJobExperience}
                                    playingExperience={data.playingExperience}
                                    getLeagueTextByValue={props.getLeagueTextByValue}
                                ></ProfileDetails>
                            </Paper>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    {(props.showApprove || props.showReject) && <>
                        <Button autoFocus onClick={() => props.handleEditProfileClick(data.userName)} variant="contained" color="primary">
                            Edit Profile
                       </Button>
                        <Button autoFocus disabled={props.showReject && !props.showApprove} onClick={props.onApproveClick} variant="contained" color="primary">
                            Approve
                       </Button>
                        <Button autoFocus disabled={props.showApprove && !props.showReject} onClick={props.onRejectClick} variant="contained" color="secondary">
                            On Hold
                       </Button></>}
                    <Button autoFocus onClick={handleClose} variant="contained" color="secondary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}