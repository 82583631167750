import { validate } from "../helper/validateUtils";

export default function postCommentSchema(values) {
    let errors = {};
    let validationResult;

    validationResult = validate(values.comment, { Required: true });
    if (!validationResult.isValid) {
        errors.comment = validationResult.message;
    }

    return errors;
};
