import React, { useEffect, useState, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import nbaLogo from '../../images/nba_login.png';
import nbca_splash from '../../images/nbca_splash.png';
import RouteConstants from '../../common/RouteConstants';

import useForm from '../../sharedComponents/customHooks/useForm';
import Loader from '../../sharedComponents/Loader/Loader';
import { AppDataContext } from "../../common/AppContext";

import { authenticationService, userService } from '../../services';

import { UserProfileContext } from "../../common/ProfileContext";
import { Role } from '../../helper';


import './CoachLogin.css';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        justifyContent: 'center',
        alignItems: 'center'
    },
    logoStrip: {
        display: 'flex',
        // flex: 1,
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'start',
        width: '100%',
        height: '150px',
        // paddingTop: '7%'
    },
    logo: {
        [theme.breakpoints.up('sm')]: {
            width: '425px',
            height: '170px'
        },
        [theme.breakpoints.down('md')]: {
            height: '83px',
            width: '260px'
        },
    },
    loginBox: {
        width: '280px',
        position: 'fixed',
        padding: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: theme.spacing(1.5),
        [theme.breakpoints.up('lg')]: {
            right: '10%',
            alignSelf: 'flex-end',
            height: '320px',
        },
        [theme.breakpoints.down('sm')]: {
            alignSelf: 'center',
            height: '385px',
            position: 'static',
            margin: theme.spacing(2),
        },
    },
    sectiondivider: {
        margin: theme.spacing(1)
    },
    nbaLogo: {
        width: '50px',
        paddingLeft: '10.5%',
        paddingRight: '2.5rem',
    },
    nbcaSplashLogo: {
        width: '100px',
        paddingLeft: '30px',
    }
}));

const CoachLogin = (props) => {
    const classes = useStyles();
    const {
        setProfileContext
    } = useContext(UserProfileContext);

    useEffect(() => {
        if (authenticationService.currentUserValue) {
            let user = authenticationService.currentUserValue;
            returnToRedirect(user);
        }
    }, []);

    let initialState = { username: '', password: '' };

    const [state, setState] = useState(initialState);

    const [showError, setShowError] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const onChange = () => {
    }


    const { errors, handleInputChange, values } = useForm(state, onChange, props.validationSchema);

    const keyPress = (e) => {
        if (e.keyCode == 13) {
            handleLoginClick();
        }
    }

    function returnToRedirect(user) {
        let userRole = userService.getUserRole();
        let path = props && props.location.state ? props.location.state.from.pathname : "";

        if (path != "") {
            props.history.push(path);
        }
        // else if (user && user.termsAccepted && user.termsAccepted == true && userRole === Role.Coach) {
        //     const { from } = props.location.state || { from: { pathname: RouteConstants.COACH_PROFILE_VIEW } };
        //     props.history.push(from);
        // }
        else if (user && user.termsAccepted && user.termsAccepted == true) {
            if (user && userRole && (userRole == Role.FrontOffice || userRole == Role.FrontOfficePlusCoach)) {
                const { from } = { from: { pathname: RouteConstants.ACTIVE_SHOWCASE } };
                props.history.push(from);
            }
            else if (user && userRole && userRole == Role.Admin) {
                const { from } = { from: { pathname: RouteConstants.COACH_MANAGEMENT } };
                props.history.push(from);
            }
            else {
                const { from } = props.location.state || { from: { pathname: RouteConstants.COACH_PROFILE_VIEW } };
                props.history.push(from);
            }
        }
        else {
            const { from } = { from: { pathname: RouteConstants.TERMS_AND_CONDITIONS } };
            props.history.push(from);
        }
    }

    const handleLoginClick = () => {
        //validate user
        setIsLoading(true);
        authenticationService.login(values.username, values.password)
            .then(user => {
                if (!user) {
                    setIsLoading(false);
                    setErrorMsg('Incorrect credentials. Please try again.');
                    setShowError(true);
                }
                else {
                    document.cookie = `HangFireCookie=${user.token};Path=/`;
                    setIsLoading(false);
                    setShowError(false);
                    returnToRedirect(user);
                }
            },
                error => {
                    setIsLoading(false);
                    setErrorMsg('Incorrect credentials. Please try again.');
                    if (error == "inactive") {
                        setErrorMsg('Your account is inactive.');
                    }
                    setShowError(true);
                    //console.log(error);
                }
            );
    }

    // const gotoSignup = () => {
    //     props.history.push(RouteConstants.COACH_REGISTRATION);
    // }

    const gotoForgotPassword = () => {
        props.history.push(RouteConstants.FORGOT_PASSWORD);
    }

    const gotoForgotUsername = () => {
        props.history.push(RouteConstants.FORGOT_USERNAME);
    }

    const gotoPrivacyPolicy = () => {
        props.history.push(RouteConstants.PRIVACY_POLICY);
    }

    return (
        <div className={clsx("login-bg", classes.root)}>
            <Hidden smDown>
                <div style={{ flex: 2 }}></div>
            </Hidden>
            <Hidden mdDown>
                <div className={clsx("login-strip-bg", classes.logoStrip)}>
                    {/* <img src={nbaLogo} className={classes.nbaLogo} /> */}
                    <p className={clsx("appTitle")}>NBA Coaches Database</p>
                </div>
            </Hidden>
            <Hidden smDown>
                <div style={{ flex: 2, width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'flex-start', }}>
                    <img src={nbaLogo} className={classes.nbaLogo} />
                    <img src={nbca_splash} className={classes.nbcaSplashLogo} />
                </div>
            </Hidden>

            <Paper className={classes.loginBox} elevation={10}>
                <Hidden lgUp>
                    <div className={clsx("app-logo-small", classes.logo)}></div>
                </Hidden>

                <div className={classes.sectiondivider}></div>
                <Typography variant="h5" color="secondary">Welcome</Typography>
                <div className={classes.sectiondivider}></div>
                <div className={classes.sectiondivider}></div>
                <TextField variant="outlined"
                    id="coachUsername"
                    size="small"
                    label="Username"
                    name="username"
                    onChange={handleInputChange}
                    fullWidth
                    autoFocus
                    onKeyDown={keyPress}
                />
                <div className={classes.sectiondivider}></div>
                <TextField
                    type="password"
                    id="coachPassword"
                    variant="outlined"
                    size="small"
                    name="password"
                    onChange={handleInputChange}
                    label="Password" fullWidth
                    onKeyDown={keyPress}
                />
                <div className={classes.sectiondivider}></div>
                {showError &&
                    <>
                        <Typography color="error">{errorMsg}</Typography>
                        <div className={classes.sectiondivider}></div>
                    </>
                }
                <Button color="secondary" disabled={isLoading} variant="contained" fullWidth onClick={handleLoginClick} className="capitalize">Login</Button>

                <div className={classes.sectiondivider}></div>

                <Button className="forgot-link" variant="text" onClick={gotoForgotUsername}>
                    <Typography variant="caption" style={{ cursor: 'pointer' }}>Forgot Username</Typography></Button>

                <Button className="forgot-link" variant="text" onClick={gotoForgotPassword}>
                    <Typography variant="caption" style={{ cursor: 'pointer' }}>Forgot Password</Typography></Button>
                <Button className="forgot-link" variant="text" onClick={gotoPrivacyPolicy}>
                    <Typography variant="caption" style={{ cursor: 'pointer' }}>Privacy Policy</Typography></Button>

                {/* <Button className="create-btn" variant="text" onClick={gotoSignup}>
                    <Typography variant="caption" style={{ cursor: 'pointer' }}>Create New Account
                    </Typography>
                </Button> */}
                <Loader isLoading={isLoading} />
            </Paper>
        </div>
    );
}

export default withRouter(CoachLogin);