import React, { useState, useContext, Fragment, useEffect } from 'react';
import { UserContext } from '../../common/UserContext';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Tooltip from '@material-ui/core/Tooltip';
import EditTwoToneIcon from '@material-ui/icons/EditTwoTone';
import DeleteTwoToneIcon from '@material-ui/icons/DeleteTwoTone';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';

import parse from 'html-react-parser';
import AppConstants from '../../common/AppConstants';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        [theme.breakpoints.up('sm')]: {
            padding: theme.spacing(1)
        },
    },
    gridItem: {
        [theme.breakpoints.up('sm')]: {
            width: '100%',
            padding: theme.spacing(1)
        },
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            paddingBottom: theme.spacing(1)
        },
    },
    Name: {
        fontWeight: 'bold',
        fontSize: '18px',
        textDecoration: 'none',
        color: '#393939'
    },
    tableRow: {
        '& td p': {
            margin: 0,
        },
        // height: '100px !important',
        // boxShadow: '1px 1px 1px #aaaaaa',
        // '& td:nth-of-type(4)': {
        //     paddingRight: theme.spacing(1),
        // }
    },
    contentCell: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        maxWidth: 0,
        '& *': {
            fontSize: '1rem',
            fontWeight: 'normal',
        },
        '& p *': {
            margin: 0,
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
        },
    },
}));

const GroupItem = (props) => {

    const classes = useStyles()
    const context = useContext(UserContext);

    const onEditClick = () => {
        props.onEditClick(props.data);
    }
    const onDeleteClick = () => {
        props.onDeleteClick(props.data.id);
    }
    const onViewUsersClick = () => {
        props.onViewUsersClick(props.data.id);
    }

    const onEditGroupDetailClick = () => {
        props.onEditGroupDetailClick(props.data);
    }
    return (
        <>
            <TableRow className={classes.tableRow}>
                <TableCell align="left" style={{ width: '30%' }} >
                    <Typography variant="body2"> {props.data.name} </Typography>
                </TableCell>
                <TableCell align="left" style={{overflow: "hidden", textOverflow: "ellipsis", width: '11rem'}} >
                    <Typography variant="body2"> {props.data.description} </Typography>
                </TableCell>
                <TableCell align="left" style={{ width: '10%' }} >
                    <Typography variant="body2"> {props.data.showAsTAB ? 'Yes' : 'No'} </Typography>
                </TableCell>
                <TableCell align="right" style={{ width: '20%' }}>
                    {props.data.type == AppConstants.Static &&
                        <Tooltip title="Edit Group Details" onClick={onEditClick} style={{ cursor: "pointer" }}>
                            <EditTwoToneIcon color="primary" />
                        </Tooltip>}
                        {props.data.type == AppConstants.Dynamic &&
                    <Tooltip title="Edit Group Details" onClick={onEditGroupDetailClick} style={{ paddingRight: 5, cursor: "pointer" }}>
                        <EditTwoToneIcon color="primary" />
                    </Tooltip>}
                    <Tooltip title="Users" onClick={onViewUsersClick} style={{ paddingRight: 5, cursor: "pointer" }}>
                        <PeopleAltIcon color="primary" />
                    </Tooltip>
                    <Tooltip title="Delete" onClick={onDeleteClick} style={{ cursor: "pointer" }}>
                        <DeleteTwoToneIcon color="primary" />
                    </Tooltip>
                </TableCell>
            </TableRow>
        </>
    );
};

export default GroupItem;