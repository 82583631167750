import React, { useContext, useState, useEffect, useRef } from 'react';
import { withRouter, useParams, } from 'react-router-dom';
import CKEditor from 'ckeditor4-react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { UserContext } from '../../common/UserContext';
import RouteConstants from "../../common/RouteConstants";
import SnackbarMsg from "../../sharedComponents/Snackbar/Snackbar";
import adminService from "../../services/admin.service";
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

import './addEditMemo.css';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    memoContent: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        overflow: 'auto',
        height: 'auto',
        padding: theme.spacing(4),
        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(2),
        }
    },
    formControl: {
        width: '100%',
        margin: 0,
        display: 'flex',
        wrap: 'nowrap'
    },
    content: {
        // margin: theme.spacing(4),
    },

    backdrop: {
        zIndex: theme.zIndex.drawer + 150,
        color: '#fff',
    },
    appBar: {
        top: 'auto',
        bottom: 0,
        right: 0,
        left: 0,
        zIndex: '1250',
        width:'100%',
        // maxWidth: '1440px',
        backgroundColor: '#fff !important'
    },
}));

function AddEditmemo(props) {
    const classes = useStyles();
    const context = useContext(UserContext);
    let params = useParams();
    const [memoTitle, setmemoTitle] = useState('')
    const [editorHTML, setEditorHTML] = useState(null);
    const [errorTitle, setErrorTitle] = useState(false)
    const [currentmemo, setCurrentMemo] = useState({
    });
    const [showError, setShowError] = useState(false);
    const [severity, setSeverity] = useState("");
    const [message, setMessage] = useState("");

    useEffect(() => {
        GetMemoById();
    }, [params.id, context]);


    const GetMemoById = () => {
        var memoId = params.id;
        if (memoId && memoId !== "0") {
            adminService.getMemo(memoId)
                .then(memoDetail => {
                    if (memoDetail) {
                        //  let memoDetail = result;
                        setmemoTitle(memoDetail.Title);
                        setTimeout(() => {
                            setEditorHTML(memoDetail.Content);
                        }, 400);

                        setCurrentMemo({ memoDetail });
                    }
                })
        }
    }

    function showAlert(sever, msg) {
        setShowError(true);
        setSeverity(sever)
        setMessage(msg);

    }
    const handleClose = () => {
        setShowError(false);
        setSeverity("")
        setMessage("");
    }

    const redirectToMemos = () => {
        props.history.push({
            pathname: RouteConstants.ADMIN_MEMO,
            state: {}
        });
    }
    const handleInputChange = (e) => {
        if (e.target.id == "Title") {
            var title = e.target.value;
            if (title == null || title.trim().length == 0)
                setErrorTitle(true);
            else setErrorTitle(false);
            setmemoTitle(title);
        }
    }
    const saveUpdateMemo = () => {
        let getEditorHTML = editorHTML;
        if (memoTitle == null || memoTitle.trim().length == 0) {
            setErrorTitle(true);
            showAlert("error", 'Please enter Title');
        }
        else if (getEditorHTML == null || getEditorHTML.trim().length == 0) {
            showAlert("error", 'Please enter Content');
        }
        else {
            var memo = currentmemo.memoDetail ? currentmemo.memoDetail : new Object();
            memo.Title = memoTitle.trim();
            memo.Content = getEditorHTML;

            //setCurrentMemo(memo);
            adminService.saveMemo(memo)
                .then(data => {
                    if (!data) {
                        showAlert("error", "Memo already exists.");
                    }
                    else if (typeof data == "string") {
                        showAlert("error", data);
                    }
                    else {
                        if (memo.Id)
                            showAlert("success", 'Memo updated successfully.');
                        else
                            showAlert("success", 'Memo created successfully.');
                        setTimeout(() => {
                            redirectToMemos();
                        }, 500);
                    }
                })
                .catch((err) => {
                    showAlert("error", 'Error occured while saving.');
                });
        }
    }

    return (
        <>
            <div className={classes.memoContent}>
                <Grid container direction="row" spacing={2} alignItems="center" className={classes.content}>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <TextField
                            required
                            id="Title"
                            label="Title"
                            value={memoTitle}
                            variant="outlined"
                            fullWidth
                            size="small"
                            onChange={(e) => handleInputChange(e)}
                            error={errorTitle}
                        />
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <CKEditor
                            data={editorHTML}
                            onChange={(event) => setEditorHTML(event.editor.getData())}
                        />
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Box>
                            <Toolbar></Toolbar>
                            <AppBar position="fixed" color="primary" className={classes.appBar}>
                                <Toolbar>
                                    <Grid container alignItems='center' spacing={2}>
                                        <Grid item lg={12} md={12} sm={12} xs={12}>
                                            <Grid container alignItems='center' justify='flex-end'>
                                                <Button
                                                    id='btnCreatePlaylist'
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={saveUpdateMemo}>
                                                    {params.id ? 'Update' : 'Save'}
                                                </Button>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={redirectToMemos}
                                                    style={{ marginLeft: '16px' }}>
                                                    Cancel
                                    </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Toolbar>
                            </AppBar>
                        </Box>
                    </Grid>
                </Grid>
            </div>

            <SnackbarMsg showError={showError} onClose={handleClose} severity={severity} msg={message} />
        </>
    )
}

export default withRouter(AddEditmemo);
