const APIURLConstants = {
    PING: "/coach/Ping",
    AUTHENTICATE_USER: "/user/validateuser/",
    SIGNOUT: "/coach/SignOut",
    GET_TEAMS: "/data/teams",
    GET_POSITION_TITLE: "/data/titles",
    GET_TITLES_SUBCATEGORY: "/data/titlesSubCategory",
    GET_COACH_TITLE: "/data/coachtitles",
    GET_SKILLS: "/data/skills",
    GET_LANGUAGE: "/data/languages",
    GET_DEGREES: "/data/Degrees",
    GET_SCHOOLS: "/data/schools",
    GET_AWARDS: "/data/awards",
    GET_PLAYERS: "/data/players",
    GET_COACHES: "/data/coaches",
    GET_COACHES_BY_FILTER: "/data/coachesbyfilter",
    GET_EVENT: "/data/events",
    GET_LEAGUES: "/data/leagues",
    GET_TERMS_AND_CONDITIONS: "/data/termsandconditions",
    CHANGE_PASSWORD: "/user/changepassword",
    CHANGE_EMAIL: "/user/changeemail",
    SINGNUP_COACH: "/user/Save",
    SINGNUP_INVITED_USER: "/user/invite-signup",
    GET_COACH_PROFILE: "/coach/profile/",
    SAVE_PERSONAL_DETAILS: "/coach/profile/personaldetails/",
    SAVE_OTHER_DESCRIPTION: "/coach/profile/otherdescription/",
    SAVE_SKILLSET: "/coach/profile/skillset/",
    SAVE_EDUCATION_DETAILS: "/coach/profile/educationaldetails/",
    SAVE_PROFILEVIDEOS_DETAILS: "/coach/profile/profileVideosDetails/",
    SAVE_ADDITIONAL_EXPERIENCE: "/coach/profile/additionalexperience/",
    SAVE_PLAYING_EXPERIENCE: "/coach/profile/playingexperience/",
    SAVE_COACHINNG_JOB_EXPERIENCE: "/coach/profile/coachingexperience/",
    SAVE_PROFILE_PHOTO: '/coach/profile/photos/',
    SAVE_PROFILE_VIDEO: '/coach/profile/videos/',
    DELETE_PROFILE_VIDEO: '/coach/profile/deleteVideo/',
    GET_JWTOKEN: '/user/getjwplayertoken/',
    GET_PROFILE_PHOTO: '/coach/profile/photo/',
    GET_PROFILE_VIDEO: '/coach/profile/video/',
    GET_TRAINING_VIDEO: '/coach/profile/training-video/',
    GET_VIDEOS_SIGNED_LINK: '/coach/profile/profile-video/',
    GET_Coach_PROFILE_LIST: '/coach/get/',
    GET_PROFILE_PDF: '/coach/profile/pdf/',
    GET_COACH_PROFILE_PDF: '/coach/profile/coachpdf/',
    GET_SEARCHED_PROFILES: '/coach/profile/search',
    FOR_BOOKMARK_PROFILE: '/coach/profile/bookmark',
    GET_ALL_USERS: '/user/all',
    UPADTE_USER_STATUS: '/user/changestatus/',
    CHANGE_PROFILE_STATUS: '/coach/profile/status',
    SUBMIT_PROFILE: '/coach/profile/submit',
    UPADTE_USER_DETAIL: "/user/update",
    FORGOT_PASSWORD: "/user/forgotpassword",
    FORGOT_USERNAME: "/user/forgotusername",
    RESET_PASSWORD: "/user/resetpassword",
    CHANGE_PASSWORD_BY_ADMIN: "/user/changepasswordbyadmin",
    INVITE_USER: "/user/sendinvitation",
    VALIDATE_TOKEN: "/user/validatetoken",
    ACCEPT_TERMS_CONDITIONS: "/user/accepttnc",
    GET_SITEKEY: "/user/getrecaptchasitekey",
    GET_FAQ: "/data/faqandtrainingvideo",
    SAVE_MEMO: '/memo/save',
    UPDATE_MEMO: '/memo/update',
    GET_ALL_MEMOS: "memo/all",
    GET_MEMO: "memo/get",
    DELETE_MEMO: "memo/delete",
    PUBLISH_MEMO: "memo/publish",
    SAVE_GROUP: "userGroup/save",
    UPDATE_GROUP: "userGroup/update",
    GET_ALL_GROUP: "userGroup/all",
    GET_FRONTOFFICE_TAB: "userGroup/getFrontOfficeTabs",
    DELETE_GROUP: "userGroup/delete",
    GET_USERS_BY_GROUPID: 'UserGroup/groupProfiles',
    GET_USERS_BY_GROUPID_FOR_TAB: 'UserGroup/groupProfilesForTab',
    GET_USERS_PROFILE_BY_MEMOID: 'usermemo/memoprofiles',
    SAVE_USERS_MEMO: 'usermemo/save',
    GET_USERS_BY_MEMOID: 'usermemo/get/',
    GET_ALL_USER_MEMOS: "usermemo/all",
    GET_ANALYTICS: "coach/analytics",
    GET_ANALYTICS_PDF: '/coach/analyticsPDF',
    GET_MULTIPLE_PROFILE_PDF: '/coach/profile/pdfs',
    DELETE_DATA: "data/delete",
    SAVE_LANGUAGE: "/data/language/save",
    SAVE_TILTE: "/data/title/save",
    SAVE_EVENT: "/data/event/save",
    SAVE_COACH: "/data/coach/save",
    SAVE_SKILL: "/data/skill/save",
    SAVE_DEGREE: "/data/degree/save",
    SAVE_PLAYER: "/data/player/save",
    SAVE_POSITION_TITLE: "/data/positiontitle/save",
    SAVE_TITLES_SUBCATEGORY: "/data/titlesSubCategory/save",
    SAVE_AWARD: "/data/award/save",
    SAVE_TEAM: "/data/team/save",
    GET_FRONTOFFICE_USERS: '/user/frontofficeusers',
    DELETE_PROFILE: "coach/delete/profile/",
    GET_ACTIVE_SHOWCASE: '/showcase/active',
    HIDE_SHOWCASE: '/showcase/hide/',
    SAVE_SHOWCASE: '/showcase/save',
    UPDATE_SHOWCASE: '/showcase/update',
    GET_ALL_SHOWCASES: "showcase/all",
    DELETE_SHOWCASES: "showcase/delete",
    GET_SHOWCASE: "showcase/get",
    SEND_EMAIL_SHOWCASE: "showcase/sendShowCaseEmail",
    GET_REMINDER_DAYS: "config/reminderdays",
    GET_CONFIG_VALUE: "config/getconfig/",
    GET_PUBLIC_CONFIGS: "config/getPublicConfig/",
    SET_REMINDER_DAYS: "config/save",
    GET_PROFILES_BY_USERIDS: "coach/profiles",
    SEND_PROFILE: "coach/profile/shareProfile",
    GET_PUBLIC_CONFIG_VALUE: "config/getPubliconfig/",
    ////////////// User Role ////////////////
    GET_ALL_USER_ROLE: "UserRole/all",
    CREATE_USER_ROLE: "UserRole/save",
    UPDATE_USER_ROLE: "UserRole/update",
    DELETE_USER_ROLE: "UserRole/delete/",

    ///coaching tree//
    GET_COACHING_TREE_HEAD_COACHES_DATA: "coach/profile/getCoachingTreeHeadCoacheseData",
    GET_COACHING_TREE_SAME_STAFF_DATA: "coach/profile/getCoachingTreeSameStaffData",
    GET_COACHING_TREE_ASSISTANTS_AND_PLAYERS_DATA: "coach/profile/getCoachingTreeAssitantsAndPlayersData",

    /// Privicy Policy
    GET_PRIVECY_POLICY: "/pages/getbypagetype/PrivecyPolicy",

    //... App Settings
    GET_PROXY_API_SETTINGS: "/settings/proxySettings",

    //... Coaches Community API
    INVITE_HISTORY: '/user/allinvitation',
    POST_LIST: '/socialpost/all',
    CREATE_POST: '/socialpost/save',
    LIKE_POST: '/socialpost/like',
    GET_ALL_COMMENTS: '/socialpost/comments',
    ADD_COMMENT: 'socialpost/addComment',
    UPDATE_POST: 'socialpost/update',
    APPROVE_REJECT_POST: 'socialpost/approveRejectPost',
    DELETE_POST: 'socialpost/delete',
    DELETE_COMMENT: 'socialpost/deleteComment',
    UPDATE_COMMENT: 'socialpost/updateComment',

    SMART_SEARCH: '/search/response',
    SMART_SEARCH_PREVIEW_SUGGESTIONS: '/search/preview',

    //... Video Game API
    GET_PENDING_VIDEOS: 'coach/getAllPendingProfileVideos',
    APPROVE_REJECT_VIDEOS: 'coach/approveRejectPendingVideo',

};

export default APIURLConstants;