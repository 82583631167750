import MomentUtils from "@date-io/moment";
import Checkbox from "@material-ui/core/Checkbox";
import Chip from "@material-ui/core/Chip";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import ToolTip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import DeleteIcon from "@material-ui/icons/Delete";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from "moment";
import React, {
    Fragment,
    useContext,
    useEffect,
    useState
} from "react";
import CultureInfo from "../common/CultureInfo";
import Helper from "../common/Helper";

import { AppDataContext } from "../common/AppContext";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    grid: {
        alignSelf: "center",
        padding: theme.spacing(1),
    },
    gridIcons: {
        display: 'flex',
        '& button': {
            [theme.breakpoints.down("xs")]: {
                padding: theme.spacing(.75),
            },
        },
    },
    error: {
        borderColor: theme.palette.error.dark,
    },
    btnDel: {
        color: theme.palette.secondary.main,
    },
    teamListImage: {
        marginRight: theme.spacing(1),
    },
    posRel: {
        position: "relative",
    },
    inputCheckBox: {
        position: "absolute",
        top: "7px",
        right: "7px",
    }
}));

const ExperienceDetailsList = (props) => {
    let preData = props.data;
    const [formDataCollection, setFormData] = useState([getEmptyItem()]);
    const { getTeamsByLeague, titlesSubCategory } = useContext(AppDataContext);


    useEffect(() => {
        preData = props.data;
        let coachingDetail = formDataFromProp();

        if (props.isInValid) {
            coachingDetail = ValidateData(coachingDetail);
        }
        if (preData.length == 0) coachingDetail.push(getEmptyItem());

        setFormData(coachingDetail);
    }, [props.data, props.isInValid]);

    const getTeamData = () => {
        let list = getTeamsByLeague(props.league);

        return list;
    };

    const notifyParentAboutDataChange = (newData) => {
        let DataCollection = newData;
        let data = [];
        for (let i = 0; i < DataCollection.length; i++) {
            let formData = DataCollection[i];

            data.push({
                id: formData.id,
                league: props.league,
                teamId: formData.teamName.value,
                teamName: formData.teamName.label,
                fromDate: formData.fromDate.value,
                toDate: formData.toDate.value,
                logoURL: formData.teamName.logoURL,
                Id: formData.title.value,
                Name: formData.title.label,
                titleSubCategoryId: formData.titleSubCategory.value,
                titleSubCategoryName: formData.titleSubCategory.label,
                headCoachName: formData.headCoachName.value,
            });
        }
        preData = data;
        props.onDataChange(data, props.league);
    };

    const ValidateData = (coachingDetail) => {
        coachingDetail.forEach(lastData => {
            if (
                lastData.teamName.value === "" ||
                lastData.fromDate.value === null ||
                lastData.toDate.value === null ||
                (props.useHeadCoach && lastData.headCoachName.value.length === 0) ||
                (props.useTitle && (lastData.title.value === "" || lastData.title.value == 0))
                // (props.useTitleSubCategory && lastData.league === 'nba' && (lastData.titleSubCategory.value === "" || lastData.titleSubCategory.value == 0))
            ) {
                lastData.fromDate.value === null
                    ? (lastData.fromDate.hasError = true)
                    : (lastData.fromDate.hasError = false);
                lastData.toDate.value === null
                    ? (lastData.toDate.hasError = true)
                    : (lastData.toDate.hasError = false);
                lastData.teamName.value === ""
                    ? (lastData.teamName.hasError = true)
                    : (lastData.teamName.hasError = false);
                props.useHeadCoach && lastData.headCoachName.value.length === 0
                    ? (lastData.headCoachName.hasError = true)
                    : (lastData.headCoachName.hasError = false);
                props.useTitle && (lastData.title.value === "" || lastData.title.value == 0)
                    ? (lastData.title.hasError = true)
                    : (lastData.title.hasError = false);
                // props.useTitleSubCategory && lastData.league === 'nba' && (lastData.titleSubCategory.value === "" || lastData.titleSubCategory.value == 0)
                //     ? (lastData.titleSubCategory.hasError = true)
                //     : (lastData.titleSubCategory.hasError = false);
            }
        })
        return coachingDetail;
    }

    function formDataFromProp() {
        return props.data.map((item) => {

            return {
                id: item.id,
                league: props.league,
                teamName: {
                    value: item.team.teamId,
                    label: item.team.teamName,
                    logoURL: item.team.logoURL ? item.team.logoURL : "",
                    validationMessage: "",
                    hasError: (item.team && item.team.teamName) ? null : true,
                },
                fromDate: {
                    value: item.fromDate,
                    validationMessage: "",
                    hasError: null,
                },
                toDate: { value: item.toDate, validationMessage: "", hasError: null },

                title: {
                    value: item.title && item.title.Id ? item.title.Id : 0,
                    label: item.title && item.title.Name ? item.title.Name : "",
                    validationMessage: "",
                    hasError: (item.title && item.title.Name) ? null : true,
                },
                titleSubCategory: {
                    value: item.titleSubCategory && item.titleSubCategory.Id ? item.titleSubCategory.Id : 0,
                    label: item.titleSubCategory && item.titleSubCategory.Name ? item.titleSubCategory.Name : "",
                    validationMessage: "",
                    hasError: null,
                    // hasError: props.league === 'nba' ? (item.titleSubCategory && item.titleSubCategory.Name) ? null : true : null,

                },
                headCoachName: {
                    value: item.headCoachName,
                    validationMessage: "",
                    hasError: null,
                },
            };

        });

    }
    function getEmptyItem() {
        let randomId = Helper.GetRandomId();
        return {
            id: randomId,
            league: props.league,
            teamName: { value: "", label: "", logoURL: "", validationMessage: "", hasError: null },
            fromDate: { value: null, validationMessage: "", hasError: null },
            toDate: { value: null, validationMessage: "", hasError: null },
            title: { value: "", label: "", validationMessage: "", hasError: null },
            titleSubCategory: { value: "", label: "", validationMessage: "", hasError: null },
            headCoachName: { value: [], validationMessage: "", hasError: null },
        };
    }
    function handleInputChange(e, hasError) {
        let data = [...formDataCollection];
        let index = data.findIndex((x) => x.id === e.itemId);
        data[index] = e.formData;
        setFormData(data);
        if (!hasError || hasError == undefined)
            notifyParentAboutDataChange(data);
    }
    function addItem() {
        //... Add a new item to the formDataCollection array.
        let dataCollection = [...formDataCollection];
        let lastData = dataCollection[dataCollection.length - 1];

        if (
            lastData.teamName.value === "" ||
            lastData.fromDate.value === null ||
            lastData.toDate.value === null ||
            (props.useHeadCoach && lastData.headCoachName.value.length === 0) ||
            (props.useTitle && (lastData.title.value === "" || lastData.title.value == 0))
            // (props.useTitleSubCategory && (lastData.titleSubCategory.value === "" || lastData.titleSubCategory.value == 0))
        ) {
            lastData.fromDate.value === null
                ? (lastData.fromDate.hasError = true)
                : (lastData.fromDate.hasError = false);
            lastData.toDate.value === null
                ? (lastData.toDate.hasError = true)
                : (lastData.toDate.hasError = false);
            lastData.teamName.value === ""
                ? (lastData.teamName.hasError = true)
                : (lastData.teamName.hasError = false);
            props.useHeadCoach && lastData.headCoachName.value.length === 0
                ? (lastData.headCoachName.hasError = true)
                : (lastData.headCoachName.hasError = false);
            props.useTitle && (lastData.title.value === "" || lastData.title.value == 0)
                ? (lastData.title.hasError = true)
                : (lastData.title.hasError = false);
            // props.useTitleSubCategory && (lastData.titleSubCategory.value === "" || lastData.titleSubCategory.value == 0)
            //     ? (lastData.titleSubCategory.hasError = true)
            //     : (lastData.titleSubCategory.hasError = false);
            handleInputChange({ itemId: lastData.id, formData: lastData }, true);
        } else if (
            moment(lastData.fromDate.value) > moment(lastData.toDate.value)
        ) {
            lastData.toDate.hasError = true;
            handleInputChange({ itemId: lastData.id, formData: lastData });
        } else {
            lastData.teamName.hasError = false;
            lastData.fromDate.hasError = false;
            lastData.toDate.hasError = false;
            lastData.title.hasError = false;
            lastData.titleSubCategory.hasError = false;
            lastData.headCoachName.hasError = false;
            dataCollection.push(getEmptyItem());
            setFormData(dataCollection);
        }
    }
    function removeItem(itemId) {
        //... Remove an item from formDataCollection array.
        let DataCollection = [...formDataCollection];
        let index = DataCollection.findIndex((x) => x.id === itemId);
        DataCollection.splice(index, 1);
        setFormData(DataCollection);

        notifyParentAboutDataChange(DataCollection);
    }

    let possitionTitle = props.positionTitle ? props.positionTitle : [];
    return (
        <Grid container>
            {formDataCollection.map((item, index) => {
                return (
                    <ExperienceDetail
                        key={index}
                        addMediaItem={addItem}
                        itemRow={item}
                        headCoachesList={props.headCoachesList}
                        onChange={handleInputChange}
                        showAddOption={index === formDataCollection.length - 1}
                        removeItem={removeItem}
                        showHeadCoach={props.useHeadCoach}
                        teamData={getTeamData()}
                        showTitle={props.useTitle}
                        showTitleSubCategory={props.useTitleSubCategory}
                        titleData={possitionTitle}
                        titleSubCategoryData={titlesSubCategory}
                        showCurrentCheckBox={props.showCurrentCheckBox}
                    />
                );
            })}
        </Grid>
    );
};

function ExperienceDetail(props) {
    const classes = useStyles();
    const teamSelected = getTeamValue(props.itemRow.teamName.label);
    const titleSelected = getTitleValue(props.itemRow.title.label);
    var intersectionHeadCoaches = props.itemRow.headCoachName.value.filter(x => props.headCoachesList.map(hc => hc.Name));
    const [headCoachSelected, setheadCoachSelected] = useState(intersectionHeadCoaches);
    const [showToDate, setShowToDate] = useState(true);
    const [lableToDat, setLableToDate] = useState("To");
    const titleSubCategorySelected = getTitleSubCategoryValue(props.itemRow.titleSubCategory.label);

    useEffect(() => {
        let intersectionHeadCoaches = props.itemRow.headCoachName.value.filter(x => props.headCoachesList.map(hc => hc.Name));
        setheadCoachSelected(intersectionHeadCoaches);
    }, [props.itemRow])

    useEffect(() => {
        if (props.showCurrentCheckBox) { setLableToDate("To (If current role select checkbox)") }
        else { setLableToDate("To") }
    }, [props.showCurrentCheckBox])

    useEffect(() => {
        if (
            props.itemRow.toDate.value != null &&
            props.itemRow.toDate.value == "Present"
        ) {
            setShowToDate(false);
        } else {
            setShowToDate(true);
        }
    });

    function handleAddClick() {
        props.addMediaItem();
    }
    function handleInputChange(value, name, newValue) {
        if (value !== null && (name === "fromDate" || name === "toDate")) {
            value = moment(value).format("YYYY/MM/DD");
        }
        let formData = props.itemRow;
        formData[name].value = value;
        formData[name].hasError = false;
        if (name === "teamName") {
            formData[name].logoURL = newValue && newValue.logoURL ? newValue.logoURL : "";
            formData[name].label = newValue && newValue.teamName ? newValue.teamName : "";
        }

        if (name === "title") {
            formData[name].label = newValue ? newValue : "";
        }

        if (name === "titleSubCategory") {
            formData[name].label = newValue;
        }
        //... Call Parent
        props.onChange({ itemId: props.itemRow.id, formData: formData });
    }

    function handleOnKeyUp(e) {
        if (e.target.value != "") {
            var teamExist = {};
            teamExist = props.teamData.find(x => x.teamName === e.target.value)
            if (teamExist === undefined) {
                let formData = props.itemRow;
                formData["teamName"].value = "New";
                formData["teamName"].hasError = false;
                formData["teamName"].label = e.target.value;
                //... Call Parent
                props.onChange({ itemId: props.itemRow.id, formData: formData });

            }
        }
    }

    function handelKeyUpHeadCoach(e) {
        if (e.keyCode == 188) {
            setheadCoachSelected([]);
            let formData = props.itemRow;
            let val = e.target.value.replace(",", "");
            let headCoachArray = props.itemRow.headCoachName.value;
            headCoachArray.push(val);
            handleInputChange(headCoachArray, "headCoachName");
        }
    }

    function handelOnKeyUpTitle(e) {
        if (e.target.value != "") {
            var titleExist = {};
            titleExist = props.titleData.find(x => x.Name === e.target.value)
            if (titleExist === undefined) {
                let formData = props.itemRow;
                formData["title"].value = "New";
                formData["title"].hasError = false;
                formData["title"].label = e.target.value;
                //... Call Parent
                props.onChange({ itemId: props.itemRow.id, formData: formData });

            }
        }
    }

    //function used to check the selected value and to check wheather the selected value is undefined 
    //and it is called upon onClose prop of autocomplete
    function handleOnKeyUpTitleSubCategory(e) {
        if (e.target.value != "") {
            var titleSubCategoryExist = {};
            titleSubCategoryExist = props.titleSubCategoryData.find(x => x.Name === e.target.value);

            if (titleSubCategoryExist === undefined) {
                let formData = props.itemRow;
                formData["titleSubCategory"].value = "";
                formData["titleSubCategory"].hasError = false;
                formData["titleSubCategory"].label = e.target.value;

                // Call Parent onChange method
                props.onChange({ itemId: props.itemRow.id, formData: formData });
            }
        }
    }

    function handleCurrentWorkingInputChange(event) {
        let formData = props.itemRow;
        if (event.target.checked) {
            formData["toDate"].value = "Present";
            formData["toDate"].hasError = false;
            setShowToDate(false);
        } else {
            formData["toDate"].value = null;
            formData["toDate"].hasError = false;
            setShowToDate(true);
        }
        //... Call Parent
        props.onChange({ itemId: props.itemRow.id, formData: formData });
    }

    function getTeamValue(val) {
        let item = [...props.teamData];
        let sele = item.find((x) => x.teamName == val);
        return sele || { teamName: val, teamId: 'New' };
    }

    const renderTeamItem = (option) => {
        return (
            <React.Fragment>
                {option.logoURL ? (
                    <img
                        className={classes.teamListImage}
                        src={option.logoURL}
                        height={35}
                        width={35}
                    ></img>
                ) : (
                    <div
                        className={classes.teamListImage}
                        style={{ height: "35px", width: "35px" }}
                    >
                        &nbsp;
                    </div>
                )}
                {option.teamName}
            </React.Fragment>
        );
    };
    function getHeadCoachValue(val) {
        let item = [...props.headCoachesList];
        let sele = item.find((x) => x.name === val);
        return sele || null;
    }
    function getTitleValue(val) {
        let item = [...props.titleData];
        let sele = item.find((x) => x.Name === val);
        return sele || { Name: val, Id: "New" };
    }

    function getTitleSubCategoryValue(val) {
        let item = [...props.titleSubCategoryData];
        let sele = item.find((x) => x.Name === val);
        return sele || { Name: val, Id: "" };
    }

    const today = new Date();
    //var intersectionHeadCoaches = headCoachSelected.filter(x => props.headCoachesList.map(hc => hc.Name).includes(x));
    return (
        <Fragment>
            <Grid container xs={12}>
                <Grid item md={3} xs={12} className={classes.grid}>
                    <Autocomplete
                        freeSolo
                        value={teamSelected}
                        id={`team_${props.itemRow.id}`}
                        size="small"
                        options={props.teamData}
                        getOptionSelected={(option, { multiple, teamSelected }) => {
                            if (!multiple) {
                                if (teamSelected) {
                                    return option.teamName === teamSelected.value;
                                }
                                return false;
                            }

                            return false;
                        }}
                        renderOption={(option) => renderTeamItem(option)}
                        getOptionLabel={(option) => option.teamName}
                        onChange={(event, newValue) => {
                            let val = newValue !== null ? newValue.teamId : "";
                            handleInputChange(val, "teamName", newValue, event);
                        }}
                        onClose={handleOnKeyUp}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                // value={teamSelected && teamSelected.label ? teamSelected.label : ''}
                                variant="outlined"
                                error={props.itemRow.teamName.hasError}
                                label={<Typography variant="body2">Team</Typography>}
                            />
                        )}
                    />
                </Grid>
                <Grid
                    item
                    md={2}
                    xs={props.showTitle || props.showHeadCoach ? 6 : 5}
                    className={classes.grid}
                >
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                        <DatePicker
                            error={props.itemRow.fromDate.hasError}
                            id={`from_${props.itemRow.id}`}
                            label={<Typography variant="body2">From</Typography>}
                            inputVariant="outlined"
                            format={CultureInfo.GetCultureInfo().MonthWithYearFormat}
                            views={["year", "month"]}
                            fullWidth
                            clearable
                            autoOk
                            disableFuture
                            okLabel=""
                            value={
                                props.itemRow.fromDate.value != null
                                    ? moment(props.itemRow.fromDate.value)
                                    : null
                            }
                            onChange={(value) => handleInputChange(value, "fromDate")}
                            size="small"
                            style={{ width: "100%" }}
                        ></DatePicker>
                    </MuiPickersUtilsProvider>
                </Grid>
                <Grid
                    item
                    md={3}
                    xs={props.showTitle || props.showHeadCoach ? 6 : 5}
                    className={`${classes.grid} ${classes.posRel}`}
                >
                    {showToDate && (
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <DatePicker
                                minDate={props.itemRow.fromDate.value != null
                                    ? moment(props.itemRow.fromDate.value)
                                    : today}
                                error={props.itemRow.toDate.hasError}
                                id={`to_${props.itemRow.id}`}
                                label={<><Hidden smDown> {lableToDat}</Hidden> <Hidden mdUp> To</Hidden></>}
                                inputVariant="outlined"
                                format={CultureInfo.GetCultureInfo().MonthWithYearFormat}
                                views={["year", "month"]}
                                fullWidth
                                clearable
                                autoOk
                                disableFuture
                                okLabel=""
                                value={
                                    props.itemRow.toDate.value != null
                                        ? moment(props.itemRow.toDate.value)
                                        : null
                                }
                                onChange={(value) => handleInputChange(value, "toDate")}
                                size="small"
                                style={{ width: "100%" }}
                            ></DatePicker>
                        </MuiPickersUtilsProvider>
                    )}
                    {!showToDate && (
                        <TextField
                            id={`toDate_${props.itemRow.id}`}
                            variant="outlined"
                            value={props.itemRow.toDate.value}
                            disabled
                            label={<><Hidden smDown> {lableToDat}</Hidden> <Hidden mdUp> To</Hidden></>}
                            size="small"
                            fullWidth
                        />
                    )}
                    {props.showCurrentCheckBox &&
                        <Grid item className={classes.inputCheckBox}>
                            <ToolTip title="Mark As Currently Working">
                                <Checkbox
                                    checked={!showToDate}
                                    name="currentWorking"
                                    onChange={handleCurrentWorkingInputChange}
                                />
                            </ToolTip>
                        </Grid>
                    }
                </Grid>
            </Grid>
            <Grid container xs={12}>
                {props.showTitle && (
                    <Grid
                        item
                        md={4}
                        xs={props.showHeadCoach ? 12 : 10}
                        className={classes.grid}
                    >
                        <Autocomplete
                            value={titleSelected}
                            id={`title_${props.itemRow.id}`}
                            size="small"
                            options={props.titleData}
                            getOptionSelected={(option, { multiple, titleSelected }) => {
                                if (!multiple) {
                                    if (titleSelected != null) {
                                        return option.Id === titleSelected.title;
                                    }
                                    return false;
                                }

                                return false;
                            }}
                            // renderOption={(option) => option.eventName}
                            getOptionLabel={(option) => option.Name}
                            onChange={(event, newValue) => {
                                let val = newValue && newValue.Id ? newValue.Id : "";
                                handleInputChange(val, "title", newValue && newValue.Name ? newValue.Name : "");
                            }}
                            onClose={handelOnKeyUpTitle}
                            style={{ width: "100%" }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    error={props.itemRow.title.hasError}
                                    label={<Typography variant="body2">Title</Typography>}
                                />
                            )}
                        />
                    </Grid>
                )}
                {props.showTitleSubCategory && (
                    <Grid item md={4} xs={props.showHeadCoach ? 12 : 10} className={classes.grid}>
                        <Autocomplete
                            value={titleSubCategorySelected} // Use appropriate selected titleSubCategory value
                            id={`titleSubCategory_${props.itemRow.id}`}
                            size="small"
                            options={props.titleSubCategoryData}
                            getOptionSelected={(option, { multiple, titleSubCategorySelected }) => {
                                if (!multiple) {
                                    if (titleSubCategorySelected != null) {
                                        return option.Id === titleSubCategorySelected.titleSubCategory;
                                    }
                                    return false;
                                }
                                return false;
                            }}
                            getOptionLabel={(option) => option.Name || ""}
                            onChange={(event, newValue) => {

                                let val = newValue && newValue.Id ? newValue.Id : "";
                                handleInputChange(val, "titleSubCategory", newValue && newValue.Name ? newValue.Name : "");

                            }}
                            onClose={handleOnKeyUpTitleSubCategory}
                            style={{ width: "100%" }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    error={props.itemRow.titleSubCategory.hasError}
                                    label={<Typography variant="body2">Title Sub-Category</Typography>}
                                    required
                                    InputLabelProps={{ required: false }}   // Keep the field required
                                />
                            )}
                        />
                    </Grid>
                )}
            </Grid>
            <Grid container xs={12}>
                {props.showHeadCoach && (
                    <Grid
                        item
                        md={props.showTitle ? 4 : 6}
                        xs={8}
                        className={classes.grid}
                    >
                        <Autocomplete
                            freeSolo
                            multiple
                            value={headCoachSelected}
                            id={`headcoach_${props.itemRow.id}`}
                            size="small"
                            options={props.headCoachesList.map((option) => option.Name)}
                            renderTags={(headCoachSelected, getTagProps) =>
                                headCoachSelected.map((option, index) => (
                                    <Chip
                                        label={option}
                                        {...getTagProps({ index })}
                                    />
                                ))
                            }
                            disableCloseOnSelect
                            onChange={(event, newValue) => {
                                let val = newValue.length > 0 ? newValue : [];
                                handleInputChange(val, "headCoachName");
                            }}
                            onKeyUp={handelKeyUpHeadCoach}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    error={props.itemRow.headCoachName.hasError}
                                    label={<Typography variant="body2">Head Coach(+)</Typography>}
                                />
                            )}
                        />
                    </Grid>
                )}
                <Grid
                    item
                    md={2}
                    xs={2}
                    className={classes.gridIcons}
                >

                    <ToolTip title="Remove">
                        <IconButton onClick={(e) => props.removeItem(props.itemRow.id)}>
                            <DeleteIcon className={classes.btnDel} >Delete</DeleteIcon>
                        </IconButton>
                    </ToolTip>
                    {props.showAddOption &&
                        <ToolTip title="Add more">
                            <IconButton onClick={handleAddClick}>
                                <AddCircleIcon color="primary" >Add</AddCircleIcon>
                            </IconButton>
                        </ToolTip>
                    }
                </Grid>
            </Grid>
        </Fragment>
    );
}

export default ExperienceDetailsList;
ExperienceDetailsList.defaultProps = {
    data: [],
};

// const headCoach = [
//     { name: "Scott Brooks", league: "NBA" },
//     { name: "Byron Scott", league: "NBA" },
//     { name: "Jeff Van Gundy", league: "NBA" },
//     { name: "Dan Issel", league: "NBA" },
//     { name: "Mike Evans", league: "NBA" },
//     { name: "Jeff Bzdelik", league: "NBA" },
//     { name: "Michael Cooper", league: "NBA" },
// ];
