import React from 'react';
import APPConstant from "../../common/AppConstants";
import Typography from "@material-ui/core/Typography";
function ApprovedProfiles(props) {
    return (<>
        {props.currentCoaching && <Typography style={{ color: "rgba(0, 0, 0, 0.54)" }}>{APPConstant.CURRENTLY_NOT_WORKING}</Typography>}
        {!props.currentCoaching && <Typography  style={{ color: "rgba(0, 0, 0, 0.54)" }}>{APPConstant.NA}</Typography>}
    </>
    );
}

export default ApprovedProfiles;