import React, { useState, useEffect } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { Container } from '@material-ui/core';
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Checkbox from '@material-ui/core/Checkbox';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import { userService, adminService } from '../../services';
import AddViewGroupParticipantsDialog from '../../sharedComponents/GroupDialog/addViewGroupParticipantsDialog';
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import FormHelperText from '@material-ui/core/FormHelperText';
import HeadShot from '../../sharedComponents/profilePhoto/HeadShot';
import HTMLEditor from '../../sharedComponents/HTMLEditor/HTMLEditor';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    notesHeader: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(1.5),
    },
    dialogHeight: {
        padding: theme.spacing(2, 0) + '!important',
    },
    ErrorName: {
        color: 'red',
    },
    formLabel: {
        color: theme.palette.primary.main,
        fontWeight: 500,
    },
    userLabel: {
        paddingLeft: '10px',
    },
    addButton: {
        position: 'relative',
        top: theme.spacing(-4),
        paddingRight: theme.spacing(1)
    },
    progress: {
        marginLeft: '50%',

    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    dialogTabPanel: {
        '& > div': {
            padding: 0
        }
    },
    addButton: {
        cursor: "pointer",
        //marginTop: '7',
        border: '1px solid #1d428a',
        borderRadius: '4px',
        //padding: '6px 8px',
        //marginLeft: '5px',
        fontSize: '1rem',
        color: '#fff',
        background: '#1d428a',
        //width: 'fit-content',
        marginBottom: '5px',
        alignSelf: "center",
        '&:hover': {
            color: '#fff',
            background: '#1d428a',
        },
        '&:focus': {
            color: '#fff',
            background: '#1d428a',
        },
        '& svg': {
            marginRight: '5px',
            width: '20px',
            height: '20px'
        },
    }
}));

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
        backgroundColor: theme.palette.primary.main,
        color: '#fff'
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography className="font500" variant="subtitle1" noWrap>{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        // padding: '25px 16px',
        //padding: '0px 0px',
        width: '700px',
        [theme.breakpoints.down(281)]: {
            width: '218px',
        },
        [theme.breakpoints.between(281, 322)]: {
            width: '258px',
        },
        [theme.breakpoints.between(321, 556)]: {
            width: '275px'
        },
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const ExpansionPanelSegment = (props) => {
    const classes = useStyles();
    const [isExpanded, setIsExpanded] = useState(props.expanded);

    useEffect(() => {
        setIsExpanded(props.expanded);
    }, [props.expanded]);

    return (
        <ExpansionPanel className={classes.expansionPanel}
            expanded={isExpanded}
            onChange={() => setIsExpanded(!isExpanded)}
        >
            <ExpansionPanelSummary
                className={classes.expansionPanelSummary}
                expandIcon={<ExpandMoreIcon />}
            >
                <Typography display="inline" className={classes.filterTitle} color="primary">
                    {props.title}</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={classes.expansionPanelDetails}>
                {isExpanded && <React.Fragment>{props.children}</React.Fragment>}
            </ExpansionPanelDetails>
        </ExpansionPanel>
    );
};

const AddShowcaseParticipantsDialog = (props) => {
    const classes = useStyles();
    const [selectedUsers, setSelectedUsers] = useState( props.data ? props.data.assignedUsers : []);
    const [selectedFrontOfficeUsers, setSelectedFrontOfficeUsers] = useState([]);
    const [value, setValue] = React.useState('0');
    const [loading, setLoading] = useState(false);
    const [users, setUsers] = useState([]);
    const [frontOfficeUsers, setfrontOfficeUsers] = useState([]);
    const [openParticipantDialog, toggleParticipantDialog] = useState(false);
    const [showcaseTitle, setShowcaseTitle] = useState(props.data ? props.data.title : null);
    const [showcaseStartDate, setShowcaseStartDate] = useState(props.data ? props.data.startDate :moment());
    const [showcaseEndDate, setShowcaseEndDate] = useState(props.data ? props.data.endDate : moment());
    const [showcaseMessage, setShowcaseMessage] = useState(props.data ? props.data.message : null);

    const [errorTitleText, setTitleErrorText] = useState();
    const [errorFrontUserText, setFrontUserText] = useState();
    const [existFrontUser, setExistFrontUserText] = useState([]);
    const [selectedDetailedProfiles, setSelectedDetailedProfiles] = useState([]);
    const [errorMessageText, setMessageErrorText] = useState();
    //const [startMinDate, setStartMinDate] = useState( (props.data && new Date(props.data.startDate) < moment().toDate()) ? props.startDate: moment().subtract(2,'day').toDate());

    useEffect(() => {
        userService.getAllFrontOfficeUser()
            .then(res => {
                if (res.length > 0) {
                    res.unshift({"id":0,"firstName" : "Select", "lastName" : "All" ,"userName" : "all"})
                    setfrontOfficeUsers(res);
                }
            })
            .catch(err => {
                console.error(err);
            })
           
            if(props.selectedProfiles && props.selectedProfiles.length > 0)
            {
                adminService.getProfilesByUserIds({userIds: props.selectedProfiles})
                .then(res => {
                    setSelectedDetailedProfiles(res);
                })
                .catch(err => {
                    console.error(err);
                })
            }
    }, [])

    useEffect(() => {
        // props.data ?
        // ( 
        let existUser = frontOfficeUsers.filter(user => props.data  && props.data.assignedUsers.includes(user.userName));
        if (existUser.length == frontOfficeUsers.filter((u) => u.id != 0).length) {
            existUser.unshift({ "id": 0, "firstName": "Select", "lastName": "All", "userName": "all" });
        }
        setExistFrontUserText(existUser);
        //  : [];        
    }, [frontOfficeUsers])

    const handleClose = (e, success) => {
        props.handleCloseClick(success);
    };

    const handleCloseClick = (isSuccess) => {
        // if (!!isSuccess) {
        //     setShowError(true);
        //     setMessage("Memo sent to selected users.")
        //     setSeverity("success")
        // }
        toggleParticipantDialog(false);
        //setCurrentMemoId(null);
    }

    const handleSaveClick = (obj) => {
        // if (!!isSuccess) {
        //     setShowError(true);
        //     setMessage("Memo sent to selected users.")
        //     setSeverity("success")
        // }
        toggleParticipantDialog(false);
        setSelectedUsers(obj.userIds);
        //setCurrentMemoId(null);
    }

    const errorTitleCheck = (value) => {
        const charactersExp = new RegExp('[^a-zA-Z0-9_@&.,)( \-]');
        setShowcaseTitle(value);
        if (!value)
            setTitleErrorText("Name is required.");
        else if (value.match(charactersExp)) {
            setTitleErrorText("Please enter valid name.");
        } else {
            setTitleErrorText();
        }
    }

    const errorFrontUserCheck = (value) => {
        console.log(value)
        if (value === 0 && existFrontUser.length === 0)
            setFrontUserText("Front office user is required.");
         else {
            setFrontUserText();
        }
    }

    const handleTitleChange = (event) => {
        setShowcaseTitle(event.target.value);
        errorTitleCheck(event.target.value);
    };

    const handleSaveShowcaseClick = () => {
        errorTitleCheck(showcaseTitle);
        errorFrontUserCheck(selectedFrontOfficeUsers.length>0 ? selectedFrontOfficeUsers.length : selectedUsers.length);
        checkMessage(showcaseMessage);
        const assignedfrontUsers = selectedFrontOfficeUsers.length > 0 ? selectedFrontOfficeUsers : existFrontUser.filter(a => a.id !== 0).map(a => a.userName);
        if ( (assignedfrontUsers.length > 0 || selectedUsers.length > 0) && showcaseTitle && !errorTitleText && !errorFrontUserText && showcaseMessage){
            props.handleSaveShowcaseClick({ title: showcaseTitle, startDate: showcaseStartDate, endDate: showcaseEndDate, assignedUsers: [...assignedfrontUsers, ...selectedUsers],message:showcaseMessage })
        }        
    }

    const onAddClick = () => {
        toggleParticipantDialog(true);
    };
    const handleChange = (newValue) => {
        if (newValue && newValue.filter(v => { if (v.id === 0) return v; }).length === 0) {
            setSelectedFrontOfficeUsers([]);
            setSelectedFrontOfficeUsers(newValue.map(a => a.userName));
            errorFrontUserCheck(newValue.length);
            setExistFrontUserText(newValue);
        }
    }
    const handleSelecteAll = (option, selected) => {
        if (option.id === 0) {
            var users = frontOfficeUsers.filter(a => { if (a.id !== 0) return a; }).map(a => a.userName);
            if (selected === false) {
                setExistFrontUserText([...frontOfficeUsers]);
                setSelectedFrontOfficeUsers([]);
                setSelectedFrontOfficeUsers(users);
                errorFrontUserCheck(users.length);
            }
            else {
                setSelectedFrontOfficeUsers([]);
                errorFrontUserCheck(0);
                setExistFrontUserText([]);
                var selUsers = selectedUsers.filter((u) => !users.includes(u));
                setSelectedUsers(selUsers);
            }
        }
        else {
            if (selected === true) {
                {
                    var selUsers = selectedUsers.filter((u) => u != option.userName);
                    setSelectedUsers(selUsers);
                }
            }
        }
    }

    const onChange = (event) => {
            setShowcaseMessage(event.editor.getData());
            checkMessage(event.editor.getData());
    }

    const checkMessage = (value)=>{
        let content = value?value.trim():'';
        if (content.length !==0) {
            setMessageErrorText();
        }
        else
            setMessageErrorText('Email Content is required.')
    }
    const renderUsersList = () => {

        return selectedDetailedProfiles && selectedDetailedProfiles.map((user, index) => {
            return <ListItem>
                <ListItemAvatar>
                    <HeadShot userName={user.userName} profilePhoto={user.personalDetails.profilePhoto} />
                </ListItemAvatar>
                <ListItemText primary={`${user.personalDetails.fullName.firstName} ${user.personalDetails.fullName.lastName}`}/>

            </ListItem>
        })

    }

    return (
        <div>
            <Dialog maxWidth={true} onClose={handleClose} aria-labelledby="customized-dialog-title" open={props.open} fullScreen={false}>
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                    {props.action} Showcase
                </DialogTitle>
                <DialogContent dividers className={classes.dialogHeight}>
                    <Container>
                        <Grid container alignItems='center' spacing={1}>
                            <Grid item xs={12} sm={12}>
                                <TextField id="DataField" variant="outlined"
                                    label={<Typography variant="body2">Title</Typography>}
                                    size="small" fullWidth
                                    value={showcaseTitle}
                                    name="Title"
                                    // required={true}
                                    onChange={handleTitleChange}
                                //error={errorNameText}
                                />
                                {errorTitleText && <FormHelperText className={classes.ErrorName} >{errorTitleText}</FormHelperText>}
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                {/* {startMinDate.toString()} */}
                                <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                                    <DatePicker
                                        label={"Start Date"}
                                        minDate={(props.data && new Date(props.data.startDate) < moment().toDate()) ? props.data.startDate: moment()}
                                        //disablePast={true}
                                        showTodayButton={false}
                                        disabled={(props.data && new Date(props.data.startDate) < moment().toDate())}
                                        value={showcaseStartDate}
                                        format="MM-DD-YYYY"
                                        onChange={(value) => {
                                            setShowcaseStartDate(value);
                                            setShowcaseEndDate(moment(value).add(1,"months"))
                                        }}
                                    ></DatePicker>
                                </MuiPickersUtilsProvider>
                                {/* {errorNameText && <FormHelperText className={classes.ErrorName} >{errorNameText}</FormHelperText>} */}
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                                    <DatePicker
                                        label={"End Date"}
                                        minDate={showcaseStartDate}
                                        //disablePast={true}
                                        showTodayButton={false}
                                        value={showcaseEndDate}
                                        format="MM-DD-YYYY"
                                        onChange={setShowcaseEndDate}
                                    ></DatePicker>
                                </MuiPickersUtilsProvider>
                                {/* {errorNameText && <FormHelperText className={classes.ErrorName} >{errorNameText}</FormHelperText>} */}
                            </Grid>
                            {selectedDetailedProfiles && selectedDetailedProfiles.length > 0 &&
                                <Grid item xs={12} sm={12}>
                                <div style={{border: '0.5px dotted orange', padding: '1rem', display: 'flex', flexDirection: 'column', overflowY: 'auto', height: '150px'}}>
                                    <div style={{fontWeight: 'bold', paddingBottom: '5px'}}>You have Selected {selectedDetailedProfiles.length} profiles for this showcase.</div>
                                        {/* <div style={{padding: '0 5px', lineHeight: '1.5'}}>
                                            {props.selectedProfiles && props.selectedProfiles.map((usersName) => (
                                                <div>{usersName}</div>
                                            ))}
                                        </div>*/}
                                        {renderUsersList()}
                                </div> 
                                
                            </Grid>
                            }
                            
                            <Grid item xs={12} sm={9}>
                                <Autocomplete
                                    value={existFrontUser.filter(a => a.id !== 0)}
                                    multiple
                                    limitTags={2}
                                    id="checkboxes-tags-demo"
                                    options={frontOfficeUsers}
                                    onChange={(event, newValue) => {
                                        handleChange(newValue);
                                    }}
                                    disableCloseOnSelect
                                    getOptionLabel={(option) => option.firstName + " " + option.lastName}
                                    renderOption={(option, { selected }) => {
                                        const selectAllIndex = existFrontUser.findIndex(
                                            a => a.id === 0
                                        );
                                        if (selectAllIndex > -1) {
                                            selected = true;
                                        }
                                        return (
                                            <React.Fragment>
                                                <Checkbox
                                                    icon={icon}
                                                    checkedIcon={checkedIcon}
                                                    style={{ marginRight: 8 }}
                                                    checked={selected}
                                                    onChange={() => handleSelecteAll(option, selected)}
                                                />
                                                {option.firstName + " " + option.lastName}
                                            </React.Fragment>
                                        );
                                    }
                                    }
                                    // style={{ width: 500 }}
                                    renderInput={(params) => (
                                        <TextField {...params} variant="outlined" label="Front Office Users" placeholder="Select Front Office Users" />
                                    )}
                                />
                                {errorFrontUserText && <FormHelperText className={classes.ErrorName} >{errorFrontUserText}</FormHelperText>}
                            </Grid>

                            <Grid item xs={12} sm={3}>
                                <IconButton className={classes.addButton} onClick={onAddClick}>Group Users</IconButton>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <HTMLEditor
                                    id="message" name="message"
                                    data={showcaseMessage}
                                    onChange={onChange}
                                    error={undefined}
                                />
                                {errorMessageText && <FormHelperText className={classes.ErrorName} >{errorMessageText}</FormHelperText>}

                            </Grid>
                        </Grid>
                    </Container>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" variant='contained' onClick={handleSaveShowcaseClick}>Save</Button>
                </DialogActions>
            </Dialog>
            {openParticipantDialog &&
                <AddViewGroupParticipantsDialog
                    groupList={props.groupList}
                    open={openParticipantDialog}
                    handleCloseClick={handleCloseClick}
                    userIds={selectedUsers}
                    handleSaveClick={handleSaveClick}
                    showOnlyFOGroup = {true}
                />}
        </div >
    );
}

export default AddShowcaseParticipantsDialog;

