import axios from 'axios';
import { useAppData } from '../common/AppContext';

const useAPI = () => {

    const { proxyAPISettings } = useAppData();

    //... Axios instance for the secured API calls.
    const secureAPI = axios.create({
        baseURL: '',
    });

    //... Axios instance for the public/open API calls.
    const publicAPI = axios.create({
        baseURL: '',
    });

    //... Include authorization header with the JWT bearer token.
    secureAPI.interceptors.request.use(
        config => {
            config.headers['X-NBA-KEY'] = proxyAPISettings.CoachesDBProxyAPISecretKey;
            return config;
        },
        error => {
            console.log("Error:", error);
            return Promise.reject(error);
        },
    );

    return { secureAPI, publicAPI };
}

export default useAPI;