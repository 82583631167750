import { Typography } from '@material-ui/core';
import AppBar from "@material-ui/core/AppBar";
import IconButton from "@material-ui/core/IconButton";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { CategoryOutlined, TrainRounded, TramOutlined } from '@material-ui/icons';
import AddIcon from '@material-ui/icons/Add';
import AssistantOutlinedIcon from '@material-ui/icons/AssistantOutlined';
import BookOutlinedIcon from '@material-ui/icons/BookOutlined';
import DeleteIcon from '@material-ui/icons/Delete';
import EventAvailableOutlinedIcon from '@material-ui/icons/EventAvailableOutlined';
import GroupOutlinedIcon from '@material-ui/icons/GroupOutlined';
import LanguageOutlinedIcon from '@material-ui/icons/LanguageOutlined';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import PortraitOutlinedIcon from '@material-ui/icons/PortraitOutlined';
import SchoolOutlinedIcon from '@material-ui/icons/SchoolOutlined';
import SportsBasketballOutlinedIcon from '@material-ui/icons/SportsBasketballOutlined';
import SportsOutlinedIcon from '@material-ui/icons/SportsOutlined';
import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import AppConstants from "../../common/AppConstants";
import { UserContext } from '../../common/UserContext';
import { referenceDataService } from "../../services";
import Loader from "../../sharedComponents/Loader/Loader";
import SnackbarMsg from "../../sharedComponents/Snackbar/Snackbar";
import AwardsDataContainer from "./AwardsDataContainer";
import RefDataContainer from './RefDataContainer';
import TeamDataContainer from './TeamDataContainer';
import AssistantList from './assistantsList';
import AwardDialog from "./awardDialog";
import ConfirmationDialogBox from './cofirmationDialogBox';
import DataDialog from "./dataDialog";
import HeadCoachDataContainer from './headCoachDataContainer';
import HeadCoachDataDialogue from "./headCoachDataDialogue";
import SelectedNames from './selectedNames';
import SkillDataContainer from "./skillDataContainer";
import SkillDataDialogue from "./skillDataDialogue";
import TeamDialog from "./teamDialog";


const useStyles = makeStyles(theme => ({
    root: {
        margin: 10,
        flexGrow: 1,
    },
    tab: {
        fontSize: theme.typography.pxToRem(12),
        [theme.breakpoints.up('sm')]: {
            minWidth: 'initial !important',
        },
    },
    tabsColor: {
        '& button': {
            color: '#ffffff !important',
        },
        flexContainer: {
            justifyContent: "space-evenly"
        }
    },
    addButton: {
        marginTop: '7',
        border: '1px solid #1d428a',
        borderRadius: '4px',
        padding: '6px 8px',
        marginLeft: '5px',
        fontSize: '1rem',
        color: '#fff',
        background: '#1d428a',
        width: 'fit-content',
        marginBottom: '5px',
        alignSelf: "center",
        '&:hover': {
            color: '#fff',
            background: '#1d428a',
        },
        '&:focus': {
            color: '#fff',
            background: '#1d428a',
        },
        '& svg': {
            marginRight: '5px',
            width: '20px',
            height: '20px'
        },
    },
    deleteButton: {
        marginTop: '7',
        border: '1px solid #1d428a',
        borderRadius: '4px',
        padding: '6px 8px',
        marginLeft: '5px',
        fontSize: '1rem',
        color: theme.palette.text.secondary,
        background: '#c8102e',
        width: 'fit-content',
        marginBottom: '5px',
        alignSelf: "center",
        '& svg': {
            marginRight: '5px',
            width: '20px',
            height: '20px'
        },
    },
    addButtonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        paddingTop: theme.spacing(1),
        backgroundColor: '#fff'
    },
    searchBox: {
        width: '250px',
        [theme.breakpoints.only('xs')]: {
            width: '200px',
        },
        [theme.breakpoints.down(325)]: {
            width: '165px',
        },
    },
    groupContent: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        overflow: 'auto',
        height: 'auto',
    },
    addGroupSection: {
        padding: theme.spacing(1, 2, 0, 2),
        display: 'flex',
        justifyContent: 'flex-end',
    },
    cursorPointer: {
        cursor: 'pointer',
    },
    table: {
        borderRadius: 0,
    },
    addGroup: {
        border: '1px solid #1d428a',
        borderRadius: '4px',
        padding: '6px 8px',
        marginBottom: '10px',
        fontSize: '1rem',
        color: '#fff',
        background: '#1d428a',
        '&:hover': {
            color: '#fff',
            background: '#1d428a',
        },
        '&:focus': {
            color: '#fff',
            background: '#1d428a',
        },
        '& svg': {
            marginRight: '5px',
        },
    },
}));

function a11yProps(index) {
    return {
        id: `scroll-tab-${index}`,
        "aria-controls": `scroll-tabpanel-${index}`,
    };
}


function ManageData(props) {
    const classes = useStyles();
    const context = useContext(UserContext);
    const [loading, setLoading] = useState(false);
    const [openConfirmationDialog, setOpenConfirmationDialog] = React.useState(false);
    const [openUsersDialog, setOpenUsersDialog] = React.useState(false);
    const [openHeadCoachDialog, setOpenHeadCoachDialog] = React.useState(false);
    const [openSkillDialog, setOpenSkillDialog] = React.useState(false);     //Sushrut
    const [openAssistantsList, setOpenAssistantsList] = React.useState(false);
    const [openAwardDialog, setOpenAwardDialog] = React.useState(false);
    const [openTeamDialog, setOpenTeamDialog] = React.useState(false);
    const [allData, setAllData] = useState(null);
    const [currentDataId, setCurrentDataId] = useState();
    const [currentAction, setCurrentAction] = useState();
    const [CurrentName, setCurrentName] = useState();
    const [CurrentAward, setCurrentAward] = useState();
    const [CurrentTeam, setCurrentTeam] = useState();
    const [currentIsManaged, setCurrentIsManaged] = useState(false);
    const [currentIncludeFilter, setCurrentIncludeFilter] = useState(false);   //Sushrut
    const [showError, setShowError] = useState(false);
    const [severity, setSeverity] = useState("");
    const [message, setMessage] = useState("");
    const [tabIndex, setTabIndex] = useState(0);
    const [leagueOptionsList, setLeagueOptionsList] = useState([]);
    const [selectedData, setSelectedData] = useState([]);
    const [selectedDataToDisplay, setSelectedDataToDisplay] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [filterText, setFilterText] = useState('');
    const [selectHeadCoach, setSelectedHeadCoach] = useState();
    const [totalHeadCoachCount, setTotalHeadCoachCount] = useState(0);
    const [defaultPageSize, setDefaultPageSize] = useState(12);
    const [headCoachPopupTitle, setHeadCoachPopupTitle] = useState('Assistants');
    const theme = useTheme();

    useEffect(() => {
        getAllAsstCoaches();
    }, [context.user]);

    useEffect(() => {
        handleReferenceData();
    }, []);

    function TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <Typography
                component="div"
                role="tabpanel"
                style={{ outline: 'none' }}
                hidden={value !== props.index}
                id={`scroll-tabpanel-${props.tabIndex}`}
                aria-labelledby={`scroll-tab-${props.tabIndex}`}
                {...other}
            >
                {value === index && <div p={3}>{children}</div>}
            </Typography>
        );
    }

    TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.any.isRequired,
        value: PropTypes.any.isRequired,
    };

    const handleReferenceData = () => {
        referenceDataService.getLeagues()
            .then(data => {
                if (data) {
                    let fieldNameChangesForLeagues = data.filter(x => x.Show == true && x.Type !== "na").sort((a, b) => (a.DisplayOrder > b.DisplayOrder) ? 1 : -1);
                    setLeagueOptionsList(fieldNameChangesForLeagues);
                }
            });
    }

    const getAllTeams = () => {
        setLoading(true);

        referenceDataService.getTeams()
            .then(result => {
                if (result) {
                    const newResult = result.map(item => {
                        return { LeaugeFullName: getLeagueName(item.Type), Id: item.teamId, Name: item.teamName, ...item };
                    });
                    setAllData(newResult);
                }
                setLoading(false);
            }).catch((err) => {
                setLoading(false);
            });
    };

    const getAllTitles = () => {
        setLoading(true);
        referenceDataService.getTitles()
            .then(result => {
                if (result) {
                    setAllData(result);
                }
                setLoading(false);
            }).catch((err) => {
                setLoading(false);
            });
    };

    const getAllTitlesSubCategory = () => {
        setLoading(true);
        referenceDataService.getTitlesSubCategory()
            .then(result => {
                if (result) {
                    setAllData(result);
                }
                setLoading(false);
            }).catch((err) => {
                setLoading(false);
            });
    };

    const getAllAsstCoaches = () => {
        setLoading(true);
        referenceDataService.getCoachTitles()
            .then(result => {
                if (result) {
                    setAllData(result);
                }
                setLoading(false);
            }).catch((err) => {
                setLoading(false);
            });
    };

    const getAllSkills = () => {
        setLoading(true);
        referenceDataService.getSkills()
            .then(result => {
                if (result) {
                    setAllData(result);
                }
                setLoading(false);
            }).catch((err) => {
                setLoading(false);
            });
    };

    const getAllLanguages = () => {
        setLoading(true);
        referenceDataService.getLanguages()
            .then(result => {
                if (result) {
                    setAllData(result);
                }
                setLoading(false);
            }).catch((err) => {
                setLoading(false);
            });
    };

    const getAllDegrees = () => {
        setLoading(TramOutlined);
        referenceDataService.getDegrees()
            .then(result => {
                if (result) {
                    setAllData(result);
                }
                setLoading(false);
            }).catch((err) => {
                setLoading(false);
            });
    };

    const getLeagueName = (value) => {
        console.log(value ? leagueOptionsList.find(league => league.Type === value).Name : "");
        return value ? leagueOptionsList.find(league => league.Type === value).Name : "";
    }

    const getAllAwards = () => {
        setLoading(true);
        referenceDataService.getAwards()
            .then(result => {
                if (result) {
                    const newResult = result.map(item => {
                        return { LeaugeFullName: getLeagueName(item.leagueName), ...item };
                    });
                    setAllData(newResult);
                }
                setLoading(false);
            }).catch((err) => {
                setLoading(false);
            });
    };

    const getAllNotablePlayers = () => {
        setLoading(TrainRounded);
        referenceDataService.getPlayers()
            .then(result => {
                if (result) {
                    setAllData(result);
                }
                setLoading(false);
            }).catch((err) => {
                setLoading(false);
            });
    };

    const getAllEvents = () => {
        setLoading(true);
        referenceDataService.getEvents()
            .then(result => {
                if (result) {
                    setAllData(result);
                }
                setLoading(false);
            }).catch((err) => {
                setLoading(false);
            });
    };
    const getAllCoaches = (pageNo, filterText) => {
        let postData = {
            pageSize: defaultPageSize,
            pageNo: pageNo,
            searchText: filterText
        };
        setLoading(true);
        referenceDataService.getHeadCoachesByFilter(postData)
            .then(result => {
                if (result) {
                    setAllData(result.data);
                    setTotalHeadCoachCount(result.totalResults)
                }
                setLoading(false);
            }).catch((err) => {
                setLoading(false);
            });
    };
    const onDeleteClick = ((id, mode) => {
        //setCurrentDataId(id);
        if (mode == "s") {
            let CurrentId = [id];
            setSelectedData([...CurrentId]);
        }
        setOpenConfirmationDialog(true);
    });
    const saveLanguage = (updatedName) => {
        if (updatedName) {
            var postdata = { Id: currentDataId, Name: updatedName };
            referenceDataService.saveLanguage(postdata)
                .then(result => {
                    if (!result)
                        showAlert("error", "Language already exists.");
                    else {
                        resetTab();
                        showAlert("success", "Language " + (currentAction == "Add" ? "saved" : "updated ") + " successfully.")
                    }
                }).catch((err) => {
                });
        }
    };
    const saveAssistanceCoach = (updatedName) => {
        if (updatedName) {
            var postdata = { Id: currentDataId, Name: updatedName };
            referenceDataService.saveAssistanceCoach(postdata)
                .then(result => {
                    if (!result)
                        showAlert("error", "Coach already exists.");
                    else {
                        resetTab();
                        showAlert("success", "Coach " + (currentAction == "Add" ? "saved" : "updated ") + " successfully.")
                    }
                }).catch((err) => {
                });
        }
        else
            setLoading(false);
    };
    const saveEvent = (updatedName) => {
        if (updatedName) {
            var postdata = { Id: currentDataId, Name: updatedName };
            referenceDataService.saveEvent(postdata)
                .then(result => {
                    if (!result)
                        showAlert("error", "Event already exists.");
                    else {
                        resetTab();
                        showAlert("success", "Event " + (currentAction == "Add" ? "saved" : "updated ") + " successfully.")
                    }
                }).catch((err) => {
                });
        }
    };
    const saveSkill = (updatedName, includeFilter) => {
        if (updatedName) {
            var postdata = { Id: currentDataId, Name: updatedName, includeUnderSearchFilter: includeFilter || false };
            referenceDataService.saveSkill(postdata)
                .then(result => {
                    if (!result)
                        showAlert("error", "Skill already exists.");
                    else {
                        resetskillTab();
                        showAlert("success", "Skill " + (currentAction == "Add" ? "saved" : "updated ") + " successfully.")
                    }
                }).catch((err) => {
                });
        }
    };
    const saveCoach = (updatedName, isManaged) => {
        if (updatedName) {
            var postdata = { Id: currentDataId, Name: updatedName, isManageData: isManaged };
            referenceDataService.saveCoach(postdata)
                .then(result => {
                    if (!result)
                        showAlert("error", "Coach already exists.");
                    else {
                        resetheadCoachTab();
                        showAlert("success", "Coach " + (currentAction == "Add" ? "saved" : "updated ") + " successfully.")
                    }
                }).catch((err) => {
                });
        }
    };

    const saveAward = (updatedData) => {
        if (updatedData) {
            var postdata = updatedData;
            referenceDataService.saveAward(postdata)
                .then(result => {
                    if (!result)
                        showAlert("error", "Award already exists.");
                    else {
                        resetTab();
                        showAlert("success", "Award  " + (currentAction == "Add" ? "saved" : "updated ") + " successfully.")
                    }
                }).catch((err) => {
                });
        }
    }
    const saveDegree = (updatedName) => {
        if (updatedName) {
            var postdata = { Id: currentDataId, Name: updatedName };
            referenceDataService.saveDegree(postdata)
                .then(result => {
                    if (!result)
                        showAlert("error", "Degree already exists.");
                    else {
                        resetTab();
                        showAlert("success", "Degree " + (currentAction == "Add" ? "saved" : "updated ") + " successfully.")
                    }
                }).catch((err) => {
                });
        }
        else
            setLoading(false);
    };
    const savePlayer = (updatedName) => {
        if (updatedName) {
            var postdata = { Id: currentDataId, Name: updatedName };
            referenceDataService.savePlayer(postdata)
                .then(result => {
                    if (!result)
                        showAlert("error", "Player already exists.");
                    else {
                        resetTab();
                        showAlert("success", "Player " + (currentAction == "Add" ? "saved" : "updated ") + " successfully.")
                    }
                }).catch((err) => {
                });
        }
    };
    const savePositionTitle = (updatedName) => {
        if (updatedName) {
            var postdata = { Id: currentDataId, Name: updatedName };
            referenceDataService.savePositionTitle(postdata)
                .then(result => {
                    if (!result)
                        showAlert("error", "Title already exists.");
                    else {
                        resetTab();
                        showAlert("success", "Title " + (currentAction == "Add" ? "saved" : "updated ") + " successfully.")
                    }
                }).catch((err) => {
                });
        }

    };
    const saveTitleSubCategory = (updatedName) => {
        if (updatedName) {
            var postdata = { Id: currentDataId, Name: updatedName };
            referenceDataService.saveTitlesSubCategory(postdata)
                .then(result => {
                    if (!result)
                        showAlert("error", "Title Sub Category already exists.");
                    else {
                        resetTab();
                        showAlert("success", "Title " + (currentAction == "Add" ? "saved" : "updated ") + " successfully.")
                    }
                }).catch((err) => {
                });
        }

    };

    const saveTeam = (updatedName) => {
        if (updatedName) {
            var postdata = updatedName;
            referenceDataService.saveTeam(postdata)
                .then(result => {
                    if (!result)
                        showAlert("error", "Team already exists.");
                    else {
                        resetTab();
                        showAlert("success", "Team " + (currentAction == "Add" ? "saved" : "updated ") + " successfully.")
                    }
                }).catch((err) => {
                });
        }
    };

    const onEditClick = (obj) => {
        setShowError(false);
        setCurrentAction("Edit");
        if (tabIndex == 6) {
            setCurrentAward(obj);
            setOpenAwardDialog(true);
        }
        else if (tabIndex == 8) {
            setCurrentTeam(obj);
            setOpenTeamDialog(true);
        }
        else if (tabIndex == 2) {

            setCurrentDataId(obj.Id);
            setCurrentName(obj.Name);
            setCurrentIsManaged(obj.isManageData)
            setOpenHeadCoachDialog(true);
        }
        else if (tabIndex == 3) {
            setCurrentDataId(obj.Id);
            setCurrentName(obj.Name);
            setCurrentIncludeFilter(obj.includeUnderSearchFilter)
            setOpenSkillDialog(true);
        }
        else {
            setCurrentDataId(obj.Id);
            setCurrentName(obj.Name);
            setOpenUsersDialog(true);
        }
    };

    const onAssistantCountClick = (obj, isPlayer) => {
        if (isPlayer) {
            setHeadCoachPopupTitle('Players')
            setSelectedHeadCoach(obj.players);
        } else {
            setHeadCoachPopupTitle('Assistant Coaches')
            setSelectedHeadCoach(obj.assistants);

        }

        setOpenAssistantsList(true);
    }

    const onAddClick = () => {
        setShowError(false);
        setCurrentAction("Add");
        if (tabIndex === 6) {
            setOpenAwardDialog(true);
        }
        else if (tabIndex === 8) {
            setOpenTeamDialog(true);
        }
        else if (tabIndex === 2) {
            setOpenHeadCoachDialog(true);
        }
        else if (tabIndex === 3) {
            setOpenSkillDialog(true);
        }
        else {
            setOpenUsersDialog(true);
        }

        //  setCurrentDataId(null);
    }

    const handleCloseClick = () => {
        resetTab();
    }

    const handleEditHeadCoachClose = () => {
        setCurrentName(null);
        setCurrentIsManaged(false);
        // setFilterText('');
        setOpenHeadCoachDialog(false);
    }

    const handleEditSkillClose = () => {
        setCurrentName(null);
        setCurrentIncludeFilter(false);
        // setFilterText('');
        setOpenSkillDialog(false);
    }

    const handleCoachCountCloseClick = () => {
        setOpenAssistantsList(false);
    }

    // setOpenAssistantsList(false);

    const handlePageChange = (pageNo) => {
        setCurrentPage(pageNo);
    }

    const handleHeadCoachPageChange = (pageNo) => {
        setCurrentPage(pageNo);
        getAllCoaches(pageNo, filterText);

    }

    const handleFilterTextChange = (filterChangedText) => {
        setFilterText(filterChangedText);
        setCurrentPage(0);
    }

    const handleHeadCoachFilterTextChange = (filterChangedText, prevFilterText) => {
        setFilterText(filterChangedText);
        if (filterChangedText.trim().length > 2) {
            setCurrentPage(0);
            getAllCoaches(0, filterChangedText.trim());
        } else if (filterChangedText.trim().length == 0 && prevFilterText.trim().length > 2) {
            setCurrentPage(0);
            getAllCoaches(0, '');
        } else if (filterChangedText.trim().length == 2 && prevFilterText.trim().length > 2) {
            setCurrentPage(0);
            getAllCoaches(0, '');
        }
    }


    const resetTab = () => {
        setOpenUsersDialog(false);
        setOpenAwardDialog(false);
        setOpenTeamDialog(false);
        setCurrentDataId(null);
        setCurrentAction(null);
        handleChange(null, tabIndex);
        setCurrentName(null);
        setCurrentAward(null);
        setCurrentTeam(null);
        setCurrentPage(0);
        setFilterText('');
        setOpenAssistantsList(false);

    }

    const resetheadCoachTab = () => {
        getAllCoaches(currentPage, filterText)
        setCurrentName(null);
        setCurrentIsManaged(false);
        setOpenHeadCoachDialog(false);

    }

    const resetskillTab = () => {
        getAllSkills(currentPage, filterText)
        setCurrentName(null);
        setCurrentIncludeFilter(false);
        setOpenSkillDialog(false);

    }



    const handleConfirmation = (mode) => {
        if (mode === "Y") {
            if (setSelectedData && setSelectedData.length > 0) {
                referenceDataService.deleteData({ Ids: [...selectedData], Type: tabIndex }).then(result => {
                    handleChange(null, tabIndex);
                    setLoading(true);
                    setOpenConfirmationDialog(false);
                    setCurrentDataId(null);
                    setSelectedData([]);
                    showAlert("success", 'Data deleted successfully.');
                }).catch((err) => {
                    setLoading(false);
                });
            }
        }
        else {
            handleChange(null, tabIndex);
            setOpenConfirmationDialog(false);
        }
    }

    const handleSubmit = (updatedName, isManaged, includeFilter) => {
        setCurrentName(updatedName);
        // setOpenUsersDialog(false);
        switch (tabIndex) {
            case 0:
                saveAssistanceCoach(updatedName);
                break;
            case 1:
                saveEvent(updatedName);
                break;
            case 2:
                saveCoach(updatedName, isManaged);
                break;
            case 3:
                saveSkill(updatedName, includeFilter);
                break;
            case 4:
                saveLanguage(updatedName);
                break;
            case 5:
                saveDegree(updatedName);
                break;
            case 6:
                setCurrentAward(updatedName);
                saveAward(updatedName);
                break;
            case 7:
                savePlayer(updatedName);
                break;
            case 8:
                setCurrentTeam(updatedName);
                saveTeam(updatedName);
                break;
            case 9:
                savePositionTitle(updatedName);
                break;
            case 10:
                saveTitleSubCategory(updatedName);
                break;
            // }
        }
    }

    const showAlert = (sever, msg) => {
        setShowError(true);
        setSeverity(sever)
        setMessage(msg);
    }
    const handleClose = () => {
        setShowError(false);
        setSeverity("")
        setMessage("");
    }

    const handleChange = (event, newValue) => {
        setLoading(true);
        setShowError(false);
        setSelectedData([]);
        setCurrentPage(0);
        setFilterText('');
        switch (newValue) {
            case 0:
                getAllAsstCoaches();
                break;
            case 1:
                getAllEvents();
                break;
            case 2:
                getAllCoaches(currentPage);
                break;
            case 3:
                getAllSkills();
                break;
            case 4:
                getAllLanguages();
                break;
            case 5:
                getAllDegrees();
                break;
            case 6:
                getAllAwards();
                break;
            case 7:
                getAllNotablePlayers();
                break;
            case 8:
                getAllTeams();
                break;
            case 9:
                getAllTitles();
                break;
            case 10:
                getAllTitlesSubCategory();
                break;
        }
        setTabIndex(newValue);
    };
    function isXsDown() {
        return useMediaQuery(theme.breakpoints.down("xs"));
    }

    const handleRefChecked = (e, refId) => {
        e.stopPropagation();
        let selected = [...selectedData];
        if (e.target.checked) selected.push(refId);
        else {
            let index = selected.indexOf(refId);
            selected.splice(index, 1);
        }
        setSelectedDataToDisplay(allData.filter(s => selected.includes(s.Id)));
        setSelectedData(selected);
    }

    const removeSelected = (refId) => {
        let selected = [...selectedData];
        let index = selected.indexOf(refId);
        selected.splice(index, 1);
        setSelectedDataToDisplay(allData.filter(s => selected.includes(s.Id)));
        setSelectedData(selected);
    }

    const clearAllSelection = () => {
        setSelectedDataToDisplay([]);
        setSelectedData([]);
    }

    return (
        <div className={classes.root} style={{ backgroundColor: '#f5f5f5' }}>
            <AppBar position="static" color="primary" elevation={1}>
                <Tabs
                    value={tabIndex}
                    onChange={handleChange}
                    scrollButtons="auto"
                    textColor="primary"
                    variant="scrollable"
                    aria-label="Data tabs"
                    className={classes.tabsColor}
                    classes={{ flexContainer: classes.flexContainer }}
                >
                    <Tab
                        label={!isXsDown() && AppConstants.MANAGE_DATA_TITLE.AssistantCoach}
                        {...a11yProps(0)}
                        className={classes.tab}
                        icon={<PersonOutlineOutlinedIcon />} />
                    <Tab
                        label={!isXsDown() && AppConstants.MANAGE_DATA_TITLE.Events}
                        {...a11yProps(1)}
                        className={classes.tab}
                        icon={<EventAvailableOutlinedIcon />} />
                    <Tab
                        label={!isXsDown() && AppConstants.MANAGE_DATA_TITLE.HeadCoaches}
                        {...a11yProps(2)}
                        className={classes.tab}
                        icon={<PortraitOutlinedIcon />} />
                    <Tab
                        className={classes.tab}
                        icon={<BookOutlinedIcon />}
                        label={
                            !isXsDown() &&
                            AppConstants.MANAGE_DATA_TITLE.Skills
                        }
                        {...a11yProps(3)}
                    />
                    <Tab
                        className={classes.tab}
                        icon={<LanguageOutlinedIcon />}
                        label={
                            !isXsDown() &&
                            AppConstants.MANAGE_DATA_TITLE.Languages
                        }
                        {...a11yProps(4)}
                    />
                    <Tab
                        className={classes.tab}
                        icon={<SchoolOutlinedIcon />}
                        label={
                            !isXsDown() &&
                            AppConstants.MANAGE_DATA_TITLE.Degree
                        }
                        {...a11yProps(5)}
                    />
                    <Tab
                        className={classes.tab}
                        icon={<AssistantOutlinedIcon />}
                        label={
                            !isXsDown() &&
                            AppConstants.MANAGE_DATA_TITLE.Awards
                        }
                        {...a11yProps(6)}
                    />
                    <Tab
                        className={classes.tab}
                        icon={<SportsBasketballOutlinedIcon />}
                        label={
                            !isXsDown() &&
                            AppConstants.MANAGE_DATA_TITLE.NotablePlayers
                        }
                        {...a11yProps(7)}
                    />
                    <Tab
                        className={classes.tab}
                        icon={<GroupOutlinedIcon />}
                        label={
                            !isXsDown() &&
                            AppConstants.MANAGE_DATA_TITLE.Teams
                        }
                        {...a11yProps(8)}
                    />
                    <Tab
                        className={classes.tab}
                        icon={<SportsOutlinedIcon />}
                        label={
                            !isXsDown() &&
                            AppConstants.MANAGE_DATA_TITLE.PositionTitles
                        }
                        {...a11yProps(9)}
                    />
                    <Tab
                        className={classes.tab}
                        icon={<CategoryOutlined />}
                        label={
                            !isXsDown() &&
                            AppConstants.MANAGE_DATA_TITLE.TitlesSubCategory
                        }
                        {...a11yProps(10)}
                    />
                </Tabs>
            </AppBar>
            <TabPanel
                theme={theme}
                tabIndex={tabIndex}
            >
                <div className={classes.addButtonContainer}>
                    {selectedData && selectedData.length > 0 &&
                        <>
                            {selectedDataToDisplay.length > 0 &&
                                <SelectedNames data={selectedDataToDisplay} onRemove={removeSelected} onClearAll={clearAllSelection} />
                            }
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: '#17408B', width: '100px' }}>
                                {selectedData.length} record(s)
                            </div>
                            <IconButton className={classes.addButton} onClick={() => onDeleteClick(0, "a")}>
                                <DeleteIcon /> Delete </IconButton>
                        </>
                    }
                    <IconButton className={classes.addButton} onClick={onAddClick}><AddIcon /> Add</IconButton>
                </div>
                <div style={{ backgroundColor: '#f5f5f5' }}>
                    {tabIndex !== 6 && tabIndex !== 8 && tabIndex !== 2 && tabIndex !== 3 &&
                        <RefDataContainer
                            selectedData={selectedData}
                            data={allData}
                            onDeleteClick={(id) => onDeleteClick(id, 's')}
                            onEditClick={onEditClick}
                            handleRefChecked={handleRefChecked}
                            currentPage={currentPage}
                            onPageChange={handlePageChange}
                            currentFilterText={filterText}
                            onFilterTextChange={handleFilterTextChange}
                            tabIndex={tabIndex}
                            onAssistantCountClick={onAssistantCountClick}
                        ></RefDataContainer>
                    }
                    {tabIndex === 2 &&
                        <HeadCoachDataContainer
                            selectedData={selectedData}
                            data={allData}
                            onDeleteClick={(id) => onDeleteClick(id, 's')}
                            onEditClick={onEditClick}
                            handleRefChecked={handleRefChecked}
                            currentPage={currentPage}
                            onPageChange={handleHeadCoachPageChange}
                            currentFilterText={filterText}
                            onFilterTextChange={handleHeadCoachFilterTextChange}
                            tabIndex={tabIndex}
                            onAssistantCountClick={onAssistantCountClick}
                            totalHeadCoachCount={totalHeadCoachCount}
                            defaultPageSize={defaultPageSize}
                            loading={loading}
                        ></HeadCoachDataContainer>
                    }
                    {tabIndex === 3 &&
                        <SkillDataContainer
                            selectedData={selectedData}
                            data={allData}
                            onDeleteClick={(id) => onDeleteClick(id, 's')}
                            onEditClick={onEditClick}
                            handleRefChecked={handleRefChecked}
                            currentPage={currentPage}
                            onPageChange={handlePageChange}
                            currentFilterText={filterText}
                            onFilterTextChange={handleFilterTextChange}
                            tabIndex={tabIndex}
                            onAssistantCountClick={onAssistantCountClick}
                            loading={loading}
                        ></SkillDataContainer>
                    }
                    {tabIndex === 6 &&
                        <AwardsDataContainer
                            selectedData={selectedData}
                            data={allData}
                            onDeleteClick={(id) => onDeleteClick(id, 's')}
                            onEditClick={onEditClick}
                            handleRefChecked={handleRefChecked}
                            currentPage={currentPage}
                            onPageChange={handlePageChange}
                            currentFilterText={filterText}
                            onFilterTextChange={handleFilterTextChange}
                        ></AwardsDataContainer>
                    }
                    {tabIndex === 8 &&
                        <TeamDataContainer
                            selectedData={selectedData}
                            data={allData}
                            onDeleteClick={(id) => onDeleteClick(id, 's')}
                            onEditClick={onEditClick}
                            handleRefChecked={handleRefChecked}
                            currentPage={currentPage}
                            onPageChange={handlePageChange}
                            currentFilterText={filterText}
                            onFilterTextChange={handleFilterTextChange}
                        ></TeamDataContainer>
                    }
                    <ConfirmationDialogBox
                        message="Do you want to delete this item/s?"
                        open={openConfirmationDialog}
                        onClose={() => handleConfirmation("N")}
                        onYesClick={() => handleConfirmation("Y")}
                        onNoClick={() => handleConfirmation("N")}
                    ></ConfirmationDialogBox>
                    {openUsersDialog && <DataDialog
                        open={openUsersDialog}
                        handleCloseClick={handleCloseClick}
                        onSubmitClick={handleSubmit}
                        onCancelClick={handleCloseClick}
                        groupId={currentDataId}
                        action={currentAction}
                        Name={CurrentName}
                    />}
                    {openHeadCoachDialog && <HeadCoachDataDialogue
                        open={openHeadCoachDialog}
                        handleCloseClick={handleEditHeadCoachClose}
                        onSubmitClick={handleSubmit}
                        onCancelClick={handleEditHeadCoachClose}
                        groupId={currentDataId}
                        action={currentAction}
                        Name={CurrentName}
                        isManageData={currentIsManaged}
                    />}

                    {openSkillDialog && <SkillDataDialogue
                        open={openSkillDialog}
                        handleCloseClick={handleEditSkillClose}
                        onSubmitClick={handleSubmit}
                        onCancelClick={handleEditSkillClose}
                        groupId={currentDataId}
                        action={currentAction}
                        Name={CurrentName}
                        includeUnderSearchFilter={currentIncludeFilter}
                    />}


                    {openAwardDialog && <AwardDialog
                        open={openAwardDialog}
                        handleCloseClick={handleCloseClick}
                        onSubmitClick={handleSubmit}
                        onCancelClick={handleCloseClick}
                        data={CurrentAward}
                        leagueOptionsList={leagueOptionsList}
                        action={currentAction}
                    />}
                    {openTeamDialog && <TeamDialog
                        open={openTeamDialog}
                        handleCloseClick={handleCloseClick}
                        onSubmitClick={handleSubmit}
                        onCancelClick={handleCloseClick}
                        data={CurrentTeam}
                        leagueOptionsList={leagueOptionsList}
                        action={currentAction}
                    />}
                    {openAssistantsList && <AssistantList
                        open={openAssistantsList}
                        handleCloseClick={handleCoachCountCloseClick}
                        data={selectHeadCoach}
                        title={headCoachPopupTitle}


                    />}
                    <SnackbarMsg showError={showError} onClose={handleClose} severity={severity} msg={message} />
                </div>
                <Loader isLoading={loading} />
            </TabPanel>
        </div>
    );
};

export default withRouter(ManageData);