import React, { useState, useEffect, useCallback } from "react";

const useForm = (initialValues, inputChangeCallback, validationSchema = {}) => {
  // HOOKS
  const [values, setValues] = useState(initialValues);
  const [errors, setErrors] = useState([]);

  //Set the values if there is change in props
  useEffect(() => {
    setValues(initialValues);
  }, [initialValues]);

  // useEffect hook to hide and display errors while working on a validated form, runs when values change
  useEffect(() => {
    validateState(values);
  }, [values]);

  const validateState = useCallback(() => {
    setErrors([]);
    const hasErrorInState = Object.keys(validationSchema).some((key) => {
      let error = "";
      let value = values[key];
      if (validationSchema[key].required) {
        if (!value) {
          error = "This is required field.";
          setErrors((prevErrors) => ({
            ...prevErrors,
            [key]: { error },
          }));
        }
      }

      if (
        validationSchema[key].validator !== null &&
        typeof validationSchema[key].validator === "object"
      ) {
        if (value && !validationSchema[key]?.validator?.regEx?.test(value)) {
          error = validationSchema[key].validator.error;
          setErrors((prevErrors) => ({
            ...prevErrors,
            [key]: { error },
          }));
        }
      }
    });

    return hasErrorInState;
  }, [values, validationSchema]);

  const handleInputChange = useCallback(
    (event) => {
      const name = event.target ? event.target.name : event.editor ? event.editor.name : undefined;
      let value = event.target ? event.target.value : event.editor ? event.editor.getData() : undefined;
      if (name == "firstName" || name == "lastName" || name == "middleName") {
        value = value.replace(/[^A-Za-z. ]/ig, '')
      }
      inputChangeCallback(name, value);
      setValues((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    },
    [validationSchema]
  );

  const handleChipInputChange = useCallback(
    (event) => {
      inputChangeCallback(event);
      setValues(event);
    },
    [validationSchema]
  );

  return {
    errors,
    handleInputChange,
    handleChipInputChange,
    values
  };
};

export default useForm;
