import React, {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import useForm from "../../sharedComponents/customHooks/useForm";
import HTMLEditor from "../../sharedComponents/HTMLEditor/HTMLEditor";

const useStyles = makeStyles((theme) => ({
  grid: {
    padding: theme.spacing(1),
    alignSelf: "center",
  },
  question: {
    color: theme.palette.primary.main,
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(1),
    },
  },
}));

const AdditionalInformation = (props) => {
  const classes = useStyles();

  const [state, setState] = useState(props.data);

  useEffect(() => {
    setState(props.data);
  }, [props.data]);

  const { errors, handleInputChange, values } = useForm(
    state,
    props.onChange,
    props.validationSchema
  );

  return (
    <Grid container>
      <Grid item md={12} xs={12} className={classes.grid}>
        <HTMLEditor
          id="description" name="description"
          data={values && values.description ? values.description : ''}
          onChange={handleInputChange}
          error={undefined}
          />
        {/* <TextField
          id="description"
          variant="outlined"
          //label={<Typography variant="body2"></Typography>}
          size="small"
          fullWidth
          multiline
          rows={4}
          name="description"
          value={values && values.description ? values.description : ''}
          onChange={handleInputChange}
          error={errors && errors.description && errors.description.error}
        /> */}
      </Grid>
    </Grid>
  );
};

export default AdditionalInformation;
