import React, { useEffect, useState } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import ArrowRightSharpIcon from '@material-ui/icons/ArrowRight';
import DialogContentText from "@material-ui/core/DialogContentText";
import {  ListItem, List, Typography} from "@material-ui/core";
const styles = (theme) => ({
    root: {
        display: "flex",
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    }
});

const useStyles = makeStyles((theme) => ({
    sectiondivider: {
        margin: theme.spacing(1),
    },
}));

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
        display: 'flex',
        justifyContent: "left",
        backgroundColor: "#e6e6e6",
        [theme.breakpoints.down('sm')]: {
            padding: "0.5rem",
        }
    },
    UserDetails: {
        display: 'flex',
    }
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);


export default function EditProfileValidationDialog(props) {
    const classes = useStyles();
    return (
        <Dialog
            open={props.validationDialogOpen}
            onClose={props.onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle>Your profile cannot be submitted. Please return to your profile, complete the following required questions, and save your work before clicking "submit".</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description" style={{ color: "black" }}>

                    <List>
                        {props.validationMessageList.map(message => {
                            return <ListItem style={{ alignItems: "self-start" }}>
                                <ArrowRightSharpIcon size="small" fontSize="small" style={{ color: "black", paddingRight: '5px' }} />
                                {" " + message}
                            </ListItem>
                        })}</List>
                </DialogContentText>

            </DialogContent>
            <DialogActions>
                <Button
                    onClick={props.onClose}
                    color="primary"
                    autoFocus
                >
                    Close
      </Button>
            </DialogActions>
        </Dialog>
    )
}