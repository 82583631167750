import React, { memo, useEffect, useState } from "react";
import { Box, Grid, Typography, useTheme } from "@material-ui/core/";
import AppConstants from "../../common/AppConstants";
import { getCurrentUser } from '../../helper/currentLoggedInUser';
import { socialPostService } from '../../services';
import Pagination from "../../sharedComponents/Pagination/Pagination";
import PostCard from "./PostCard";

const SocialPostList = (props) => {
    const theme = useTheme();
    const CONST_PAGE_SIZE = 20; // Define CONST_PAGE_SIZE here
    const [postList, setPostList] = useState([]);
    const [skip, setSkip] = useState(0);
    const tabIndex = props.tabIndex;

    // Fetch post list based on tab index and skip
    useEffect(() => {

        const currentUserLocalStorage = getCurrentUser();
        if (currentUserLocalStorage?.token) {
            if (tabIndex === 0) {
                //... Approved Post List
                getStatusBasedPostList(AppConstants.REVIEW_STATUS.APPROVED, skip);
            } else if (tabIndex === 1) {
                //... Pending review post list
                getStatusBasedPostList(AppConstants.REVIEW_STATUS.PENDING, skip);
            } else if (tabIndex === 2) {
                //... Returned  post list
                getStatusBasedPostList(AppConstants.REVIEW_STATUS.RETURNED, skip);
            }
        }
    }, [props.tabIndex, skip, props.searchedText]);

    const getStatusBasedPostList = (postReviewStatus, skip = 0) => {
        props.setIsLoading(true);

        const searchedText = props.searchedText ?? "";

        socialPostService.getAllPostList(postReviewStatus, CONST_PAGE_SIZE, skip, searchedText).then((postList) => {
            setPostList(postList);
        }).catch(error => {
            console.log("Error searchText", error);
        }).finally(() => {
            props.setIsLoading(false);
        });
    };

    const handlePostAction = (id, reviewedStatus, reviewedReason, sendMail) => {
        props.setIsLoading(true);

        const postData = {
            id: id,
            reviewedStatus: reviewedStatus,
            reviewedReason: reviewedReason,
        }

        socialPostService.approveRejectPost(postData, sendMail).then((res) => {
            const postListClone = [...postList];
            // Filter out the res object from the postList
            const updatedPostList = postListClone.filter(post => post.id !== res.id);
            // Update the post list data
            setPostList(updatedPostList);

            // Check if the updatedPost list is empty call the api to show the data for the next or previous page.
            if (updatedPostList && updatedPostList.length === 0) {
                setSkip(0);
                getStatusBasedPostList(tabIndex === 0 ? AppConstants.REVIEW_STATUS.APPROVED :
                    tabIndex === 1 ? AppConstants.REVIEW_STATUS.PENDING :
                        AppConstants.REVIEW_STATUS.RETURNED);
            }
        }).catch(error => {
            console.log("Error", error);
        }).finally(() => {
            props.setIsLoading(false);
        });
    };

    return (
        <Box sizeHeight={"100vh"} sizeWidth={'100vw'} display={"flex"} style={{ justifyContent: "center", backgroundColor: theme.palette.background.default, }}>
            <Grid item xs={12} md={8} lg={6} style={{ padding: 24 }}>
                {postList && postList?.length > 0 ?
                    <Grid item spacing={2} xs={12} style={{ marginTop: 8, }}>
                        {postList.map((item, index) =>
                            <PostCard item={item}
                                key={item.id}
                                index={index}
                                handlePostAction={handlePostAction}
                                postList={postList}
                                setPostList={setPostList}
                            />)
                        }
                    </Grid> :
                    <Grid item xs={12} style={{ margin: 24, display: "flex", justifyContent: "center", alignItems: "center", minHeight: '60vh' }}>
                        <Typography variant="body1" >{props.fallBackMessage}</Typography>
                    </Grid>
                }
                <Pagination
                    pageSize={CONST_PAGE_SIZE}
                    totalProfile={postList?.length > 0 && postList[0]?.totalCount}
                    changePageNumber={(e) => {
                        setSkip(e.selected * CONST_PAGE_SIZE);
                    }}
                    currentPageNo={Math.floor((skip / CONST_PAGE_SIZE) + 1)}
                />
            </Grid>
        </Box>
    );
};

export default memo(SocialPostList);