import React, { useContext, useState, useEffect } from 'react';
import { useHistory, useParams, withRouter } from 'react-router-dom';
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import { Paper } from '@material-ui/core';
import Checkbox from "@material-ui/core/Checkbox";
import Tooltip from '@material-ui/core/Tooltip';
import EditTwoToneIcon from '@material-ui/icons/EditTwoTone';
import DeleteTwoToneIcon from '@material-ui/icons/DeleteTwoTone';
import './FilterableData.css';
import FilterableTable from '../../thirdParty/react-filterable-table/Components/FilterableTable';

const useStyles = makeStyles(theme => ({
    root: {
        margin: 10,
        flexGrow: 1,
    },
    groupContent: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        overflow: 'auto',
        height: 'auto',
    },
    cursorPointer: {
        cursor: 'pointer',
    },
    customFilterableTable: {
        width: '100%',
        borderCollapse: 'collapse',
        '& th': {
            background: '#316ad8',
            color: '#fff',
        },
        '& th, & td': {
            padding: theme.spacing(.75),
            borderBottom: '1px solid #ccc',
            fontSize: '0.875rem',
        },
        '& th:first-child,  & td:first-child': {
            textAlign: 'left',
            width: '5%'
        },
        '& th:last-child, & td:last-child': {
            textAlign: 'right',
        },
    }
}));

function AwardsDataContainer(props) {
    const onEditClick = (item) => {
        props.onEditClick(item);
    }
    const onDeleteClick = (item) => {
        props.onDeleteClick(item.Id);
    }
    const classes = useStyles();

    const renderAction = (data) => {
        return (
            <>
                <Tooltip title="Edit" onClick={() => onEditClick(data.record)} style={{ cursor: "pointer" }}>
                    <EditTwoToneIcon color="primary" />
                </Tooltip>
                <Tooltip title="Delete" onClick={() => onDeleteClick(data.record)} style={{ cursor: "pointer" }}>
                    <DeleteTwoToneIcon color="primary" />
                </Tooltip>
            </>
        );
    };

    const renderCheckBox = (data) => {
        return (
            <Checkbox
                name="chkGroup"
                id={data.record.Id}
                checked={checkRefCheckbox(data.record.Id)}
                onChange={(event) => props.handleRefChecked(event,data.record.Id)}>
            </Checkbox>
        )
    };

    const checkRefCheckbox = (id) => {
		return props.selectedData?  props.selectedData.includes(id):false;
	}

    const fields = [
        { name: 'Id', displayName: "", inputFilterable: false, sortable: false, render: renderCheckBox },
        { name: 'Name', displayName: "Name", inputFilterable: true, sortable: true },
        { name: 'LeaugeFullName', displayName: "League", inputFilterable: true, sortable: true },
        { name: 'type', displayName: "Type", inputFilterable: true, sortable: true },
        { name: 'Id', displayName: "Action", inputFilterable: false, sortable: false, render: renderAction },

    ];

    return (
        <div className="repsContainer" style={{ overflow: 'hidden' }}>
            <div className={classes.groupContent}>
                <Paper>
                    {
                        props.data && <FilterableTable
                            namespace="Awards"
                            initialSort="Name"
                            data={props.data}
                            fields={fields}
                            noRecordsMessage="There is no data available"
                            noFilteredRecordsMessage="No data match your filters!"
                            pageSizes={[12, 24, 48, 72]}
                            topPagerVisible ={false}
                            tableClassName ={classes.customFilterableTable}
                            maintainPageOnSetData
                            currentPage={props.currentPage}
                            onPageChange={props.onPageChange}
                            currentFilterText={props.currentFilterText}
                            onFilterTextChange={props.onFilterTextChange}
                        />
                    }
                </Paper>
            </div>
        </div>
    );
};
export default withRouter(AwardsDataContainer);