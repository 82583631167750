import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ReactPaginate from "react-paginate";

const useStyles = makeStyles((theme) => ({
  container: {
    justifyContent: "center",
    display: "flex",
    flexDirection: "row",
    marginTop: 10,
    marginBottom: 10,
    "& ul": {
      margin: 0,
      display: "flex",
      padding: 0,
      flexWrap: "wrap",
      listStyle: "none",
      textAlign: "center",
    },
  },
  active: {
    backgroundColor: "#1d428a !important",
    color: "#ffffff !important",
    border: 1,
    borderRadius: 18,
  },
  li: {
    display: "flex",
    alignItems: "center",
    marginLeft: 5,
    marginRight: 5,
    border: 1,
    borderRadius: 18,
    height: 36,
    minWidth: 36,
    color: "#1d428a",
    backgroundColor: "#e5ecfc",
    "& a": {
      textAlign: "center",
      fontWeight: 500,
      padding: 13,
      outline: "none",
      cursor: 'pointer',
    },
  },
  break: {
    marginLeft: 5,
    marginRight: 5,
    fontWeight: 500,
    alignSelf: "center",
    "& a": {
      textAlign: "center",
      fontWeight: 500,
      outline: "none",
    },
  },
  previous: {
    display: "none",
  },
  next: {
    display: "none",
  },
}));
const Pagination = ({ pageSize, totalProfile, changePageNumber,currentPageNo }) => {
  const classes = useStyles();
  let pageCount = Math.ceil(totalProfile / pageSize);
  return (
    <div className={classes.container}>
      <ReactPaginate
       forcePage={currentPageNo-1}
        //initialPage={0}
        previousClassName={classes.previous}
        nextClassName={classes.next}
        //previousLabel={"previous"}
        //nextLabel={"next"}
        breakLabel={"..."}
        breakClassName={classes.break}
        pageCount={pageCount}
        marginPagesDisplayed={1}
        pageRangeDisplayed={2}
        onPageChange={changePageNumber}
        containerClassName={classes.pagination}
        pageClassName={classes.li}
        //subContainerClassName={"pages pagination"}
        activeClassName={classes.active}
        disableInitialCallback ={true}
      />
    </div>
    // <TablePagination
    //   component="div"
    //   count={totalProfile}
    //   page={pageNumber}
    //   onChangePage={changePageNumber}
    //   rowsPerPage={pageSize}
    //   onChangeRowsPerPage={changePageSize}
    // />
  );
};

export default Pagination;
