import React, { useState, useEffect, useContext } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      flexShrink: 0,
      [theme.breakpoints.up("sm")]: {
        flexBasis: "85%",
      },
      [theme.breakpoints.down("xs")]: {
        flexBasis: "85%",
      },
      cursor: "pointer",
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(15),
      color: theme.palette.text.secondary,
    },
    itemIcon: {
      [theme.breakpoints.up("sm")]: {
        minWidth: "45px",
      },
      [theme.breakpoints.down("xs")]: {
        minWidth: "35px",
      },
    },
    expansionPanelSummary: {
      [theme.breakpoints.down("xs")]: {
        padding: "0 16px 0 16px",
      },
    },
    expansionPanelDetails: {
      [theme.breakpoints.down("xs")]: {
        paddingTop: theme.spacing(1),
        paddingRight: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(1),
      },
    },
    profileCompleteness: {
      color: theme.palette.text.secondary,
    },
    divider: {
      marginTop: theme.spacing(2),
      width: "100%",
    },
    submitButton: {
      alignContent: "right",
      textAlign: "right",
      paddingBottom: 10,
      paddingRight: 10,
    },
  }));

  const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });
  
  
export default function TermsAndCondition(props) {

const classes=useStyles();
    
    const DialogTitleTerms = withStyles(styles)((props) => {
        const { children, classes, onClose, ...other } = props;
        return (
            <MuiDialogTitle disableTypography className={classes.root} {...other}>
                <Typography variant="h6">{children}</Typography>
                {onClose ? (
                    <IconButton
                        aria-label="close"
                        className={classes.closeButton}
                        onClick={onClose}
                    >
                        <CloseIcon />
                    </IconButton>
                ) : null}
            </MuiDialogTitle>
        );
    });

    const DialogContentTerms = withStyles((theme) => ({
        root: {
            padding: theme.spacing(2),
            display: "flex",
            justifyContent: "center",
            backgroundColor: "#e6e6e6",
            [theme.breakpoints.down("sm")]: {
                padding: "0.5rem",
            },
        },
    }))(MuiDialogContent);

    const DialogActions = withStyles((theme) => ({
        root: {
            margin: 0,
            padding: theme.spacing(1),
        },
    }))(MuiDialogActions);



    return (
        <Dialog
            onClose={props.onClose}
            maxWidth="md"
            aria-labelledby="customized-dialog-title"
            open={props.showTermsAndConditions}
        >
            <DialogTitleTerms
                id="customized-dialog-title"
                onClose={props.onClose}
            >
                Terms and Conditions
        </DialogTitleTerms>
            <DialogContentTerms>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy text ever
                since the 1500s, when an unknown printer took a galley of type and
                scrambled it to make a type specimen book. It has survived not only
                five centuries, but also the leap into electronic typesetting,
                remaining essentially unchanged. It was popularised in the 1960s with
                the release of Letraset sheets containing Lorem Ipsum passages, and
                more recently with desktop publishing software like Aldus PageMaker
                including versions of Lorem Ipsum.
        </DialogContentTerms>
            <DialogActions>
                <Button
                    autoFocus
                    onClick={props.onClose}
                    color="primary"
                >
                    {" "}
            Close{" "}
                </Button>
            </DialogActions>
        </Dialog>
    )


}