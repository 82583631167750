import { Box, Typography, useTheme } from "@material-ui/core";
import { useState } from "react";

const ReadMore = ({ children }) => {

    const theme = useTheme();
    const text = children;
    const [isReadMore, setIsReadMore] = useState(true);

    const toggleReadMore = () => {
        setIsReadMore(!isReadMore);
    };
    return (
        text.length > 100 ?
            <Box className="text" >
                <Typography variant="body1" style={{ color: theme.palette.text.secondary }}>
                    {isReadMore ? text.slice(0, 200) : text}
                </Typography>
                <span
                    onClick={toggleReadMore}
                    variant="text"
                >
                    <Typography variant="body1" color="primary">
                        {isReadMore ? "...read more" : " show less"}
                    </Typography>
                </span>
            </Box> :
            <Box className="text" >
                <Typography variant="body1" style={{ color: theme.palette.text.secondary }}>
                    {text}
                </Typography>
            </Box>
    );
};

export default ReadMore;