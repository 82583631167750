import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import JWPlayer from "../JWPlayer/JWPlayer";
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
        display: 'flex',
        justifyContent: "center",
        backgroundColor: "#e6e6e6",
        [theme.breakpoints.down('sm')]: {
            padding: "0.5rem",
        }
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

export default function CustomizedDialogs(props) {
    const [jwtTokenDetails, setJwtTokenDetails] = useState(props.data.jwtTokenDetails);
    const [loading, setLoading] = useState(false);
    const [videoURL, setvideoURL] = useState(props.data.videoURL);

    useEffect(() => {
        setvideoURL(props.data.videoURL);
      }, [props.data.videoURL]);

    const handleClose = () => {
        props.onClose();
    };
    return (
        <div>
            <Dialog onClose={handleClose} 
                maxWidth="md" aria-labelledby="customized-dialog-title" open={props.open}>
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                {props.data.title}
                </DialogTitle>
                <DialogContent>
                        <Grid container direction="row" alignItems="center">
                            {jwtTokenDetails && jwtTokenDetails.JWPlayerKey && jwtTokenDetails.JWPlayerSource && videoURL &&
                                <JWPlayer jwPlayerkey={jwtTokenDetails.JWPlayerKey}
                                    jwPlayersrc={jwtTokenDetails.JWPlayerSource}
                                    fileURL={videoURL}
                                   userName={ props.data.userName}
                                   playExternalVideo={props.data.playExternalVideo}
                                />}
                        </Grid>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleClose} color="primary"> Close </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}