import React, { useContext, useState, useEffect } from 'react';
import { useHistory, useParams, withRouter } from 'react-router-dom';
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import AddCircleIcon from '@material-ui/icons/AddCircleOutline';
import { Paper, Typography } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import adminService from "../../services/admin.service";
import { UserContext } from '../../common/UserContext';
import LinearProgress from '@material-ui/core/LinearProgress';
import MemoItem from './memoItem';
import RouteConstants from "../../common/RouteConstants";
import MemoAddIcon from '@material-ui/icons/PostAdd'
import IconButton from "@material-ui/core/IconButton";

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    searchBox: {
        width: '250px',
        [theme.breakpoints.only('xs')]: {
            width: '200px',
        },
        [theme.breakpoints.down(325)]: {
            width: '165px',
        },
    },
    memoboardContent: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        overflow: 'auto',
        height: 'auto',
    },
    addMemoSection: {
        padding: theme.spacing(1, 2, 0, 2),
        display: 'flex',
        justifyContent: 'flex-end',
    },
    cursorPointer :{
    cursor:'pointer',
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 150,
        color: '#fff',
    },
    table: {
        borderRadius: 0,
    },
    addGroup: {
        border: '1px solid #1d428a',
        borderRadius: '4px',
        padding: '6px 8px',
        marginBottom: '10px',
        fontSize: '1rem',
        color: '#fff',
        background: '#1d428a',
        '&:hover': {
          color: '#fff',
          background: '#1d428a',
        },
        '&:focus': {
          color: '#fff',
          background: '#1d428a',
        },
        '& svg': {
          marginRight: '5px',
        },
      },
}));
const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: "#316ad8",
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);


function UserMemoBoardContainer(props) {
    const classes = useStyles();
    const context = useContext(UserContext);
    const [loading, setLoading] = useState(false);
    const [allUserMemos, setAllUserMemos] = useState(null);

    useEffect(() => {
        geAllUserMemos();
    }, []);

    const geAllUserMemos = () => {
        adminService.geAllUserMemos()
            .then(result => {
                if (result) {
                    setAllUserMemos(result.userMemos);
                }
                setLoading(true);
            }).catch((err) => {
            });
    };

    return (
        <div className={classes.root} style={{ backgroundColor: '#f5f5f5' }}>
            <div className="repsContainer" style={{ overflow: 'hidden' }}>
                <div className={classes.memoboardContent}>
                    {
                        <React.Fragment>
                            {
                                (!loading) &&
                                <LinearProgress color="secondary"></LinearProgress>
                            }
                        </React.Fragment>
                    }
                    <Grid container>
                        <TableContainer component={Paper} className={classes.table}>
                            <Table size="small" aria-label="a dense table">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell style={{ width: '90%' }}>Title</StyledTableCell>
                                        <StyledTableCell align="center" style={{ width: '10%' }}>Action</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {allUserMemos && allUserMemos.map((item, index) => {
                                        return <MemoItem
                                            key={index}
                                            data={item}
                                            onEditClick={null}
                                            onDeleteClick={null}
                                            showEditDeleteButton={false}
                                        />
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </div>
            </div>
        </div>
    );
};

export default withRouter(UserMemoBoardContainer);