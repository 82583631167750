import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import RouteConstants from '../common/RouteConstants';
import { authenticationService } from '../services';
import { cacheStorage, Role } from '../helper';

export const PrivateRoute = ({ component: Component, layout: Layout, roles, ...rest }) => (
    <Route {...rest} render={props => {
        //const currentUser = authenticationService.currentUserValue;

        var jwtDecode = require('jwt-decode');
        let currentUserLocalStorage = JSON.parse(localStorage.getItem('currentUser'));

        if (!currentUserLocalStorage) {
            // not logged in so redirect to login page with the return url
            return <Redirect to={{ pathname: '/', state: { from: props.location } }} />
        }

        let jwt = currentUserLocalStorage && currentUserLocalStorage.token ? currentUserLocalStorage.token : '';
        const currentUser = jwtDecode(jwt);

        //Redirect to terms and condition if not accepted and user is not coach
        let cachedUser = JSON.parse(cacheStorage.getProfilePhotoFromLocalStorage('currentUser'));
        //if(currentUser.role !== Role.Coach)
        //{
        if (typeof cachedUser.termsAccepted == 'undefined' || (typeof cachedUser.termsAccepted !== 'undefined' && cachedUser.termsAccepted == false)) {
            const { from } = { from: { pathname: RouteConstants.TERMS_AND_CONDITIONS } };
            props.history.push(from);
        }
        //}


        // check if route is restricted by role
        if (roles && roles.indexOf(currentUser.role) === -1) {
            // role not authorised so redirect to home page
            return <Redirect to={{ pathname: '/logout' }} />
        }

        // authorised so return component
        return (
            <Route
                {...rest}
                render={props =>
                    Layout ? (
                        <Layout><Component  {...props} /></Layout>
                    )
                        :
                        (<Component  {...props} />)
                } />)
    }} />
)