import React from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";


const useStyles = makeStyles((theme) => ({
    question1: {
        color: theme.palette.tonalOffset,
        fontStyle:'italic',
        [theme.breakpoints.down("xs")]: {
            padding: theme.spacing(1),
        },
        display: 'inline-block',
    },
}));

const CaptionMsg = (props) => {
    const classes = useStyles();
    return (
        <Typography variant="caption" className={classes.question1}>
            {props.message ? props.message : ''}
        </Typography>
    )
}

export default CaptionMsg;