import React, { useState, useEffect, useContext } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";




const useStyles = makeStyles((theme) => ({

    description: {
        verticalAlign: 'middle',        
        display: 'inline-block',
        marginLeft: '4px',
        fontFamily: "'Lato', sans-serif",
		fontWeight: '500',
		lineHeight: '14.4px',
		fontSize: theme.spacing(2),
    }





}));

function CoachingTreeLegendNode({ data }) {



    const classes = useStyles();
    return (

        <>

            <div >
                <div style={{ height: '10px', borderWidth: '0', backgroundColor: data.color, width: '20px', display: 'inline-block' }} />
                <span className={classes.description}><label> {data.description}</label></span>

            </div>


        </>

    );
}

export default CoachingTreeLegendNode;
