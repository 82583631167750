import React, { useState, useCallback, useRef } from 'react'
import Button from '@material-ui/core/Button'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import 'react-image-crop/dist/ReactCrop.css';
import Cropper from 'react-image-crop';

const styles = makeStyles((theme) => ({
    cropContainer: {
        position: 'relative',
        // width: 500,
        // height: 420,
        background: '#000',
        '& .ReactCrop': {
            display: 'flex',
            '& > div': {
                display: 'flex',
                justifyContent: 'center',
                flex: '1',
                '& .ReactCrop__image': {
                    // maxHeight: '420px',
                },
            },
        },
        [theme.breakpoints.down('sm')]: {
            height: '100%',
            width: '100%',
        },
    },
    cropButton: {
        flexShrink: 0,
        marginLeft: 16,
    },
    controls: {
        padding: 16,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        justifyContent: 'flex-end',
        [theme.breakpoints.up('sm')]: {
            flexDirection: 'row',
            alignItems: 'center',
        },
    }
})
)
const ImageCropper = (props) => {
    const classes = styles();
    const [crop, setCrop] = useState({  x: 5,
        y: 5,
        width: 80,
        height: 80, })

   // const [croppedImage, setCroppedImage] = useState(null)
    const [completedCrop, setCompletedCrop] = useState(null);
    const imgRef = useRef(null);
    const previewCanvasRef = useRef(null);

    const onLoad = useCallback((img) => {
        imgRef.current = img;
    }, []);

    const getCroppedImg = (img, crop) => {
        const image = img.current;
        const canvas = document.createElement('canvas')//previewCanvasRef.current;

        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        const ctx = canvas.getContext('2d');
        const pixelRatio = window.devicePixelRatio;

        canvas.width = crop.width;
        canvas.height = crop.height;

       // ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
        ctx.imageSmoothingQuality = 'high';

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );
        return new Promise((resolve, reject) => {
            resolve( canvas.toDataURL());
        })
    }

    const saveCroppedImage = () => {
        setCropedImage(completedCrop);
    }

    const setCropedImage = async (crops) => {
        if (imgRef && crop.width && crop.height) {
            const croppedImg = await getCroppedImg(
                imgRef,
                crops
            );
            props.saveCroppedImage(croppedImg);
        }
    }

    return (
        <div>
            <div className={classes.cropContainer}>
                <Cropper src={props.src}
                    onImageLoaded={onLoad}
                    crop={crop}
                    onChange={(c) => setCrop(c)}
                    onComplete={(c) => setCompletedCrop(c)}
                ></Cropper>

            </div>
            <div className={classes.controls}>
                <Button
                    onClick={() => saveCroppedImage(previewCanvasRef.current, completedCrop)}
                    color="primary"
                >Save
                </Button>
                <Button
                    onClick={props.onClose}
                    color="primary"
                >
                    {" "}
            Close{" "}
                </Button>
            </div>
            {/* <ImgDialog img={croppedImage} onClose={onClose} /> */}
        </div>
    )
}

export default ImageCropper;