import APIHelper from "../common/ApiHelper";
import APIURLConstants from "../common/ApiURLConstants";
import { authHeader, handleError, handleResponse } from '../helper';

export const gameVideoService = {
    getAllPendingProfileVideos,
    approveRejectPendingProfileVideos
};

function getAllPendingProfileVideos() {
    return APIHelper.get(APIURLConstants.GET_PENDING_VIDEOS, authHeader())
        .then(handleResponse)
        .then(data => {
            return data
        })
        .catch(handleError);
}

function approveRejectPendingProfileVideos(requestPayload) {
    return APIHelper.post(APIURLConstants.APPROVE_REJECT_VIDEOS, requestPayload, authHeader())
        .then(handleResponse)
        .then(data => {
            return data
        })
        .catch(handleError);
}