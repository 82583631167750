import React, { useState, useContext, useEffect } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import { referenceDataService } from "../../services";

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    notesHeader: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(1.5),
    },
    dialogHeight: {
        padding: theme.spacing(2, 0) + '!important',
    },
    ErrorName: {
        color: 'red',
    },
    nameContainer: {
        margin: '15px 15px 0 15px',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    formLabel: {
        color: theme.palette.primary.main,
        fontWeight: 500,
    },
    userLabel: {
        paddingLeft: '10px',
    },
    addButton: {
        position: 'relative',
        top: theme.spacing(-4),
        paddingRight: theme.spacing(1)
    },
    progress: {
        marginLeft: '50%',

    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
}));

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
        backgroundColor: theme.palette.primary.main,
        color: '#fff'
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography className="font500" variant="subtitle1" noWrap>{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        // padding: '25px 16px',
        padding: '0px 0px',
        width: '500px',
        [theme.breakpoints.down(281)]: {
            width: '218px',
        },
        [theme.breakpoints.between(281, 322)]: {
            width: '258px',
        },
        [theme.breakpoints.between(321, 556)]: {
            width: '275px'
        },
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

const AwardDialog = (props) => {
    const classes = useStyles();
    const [updatedName, setUpdateName] = useState(props.data ? props.data.Name : null);
    const [leagueName, setLeaugeName] = useState(props.data ? props.data.leagueName : null);
    const [type, setType] = useState(props.data ? props.data.type : null);
    const [errorNameText, setNameErrorText] = useState();
    const [errorLeaugeText, setLeaugeErrorText] = useState();
    const [errorTypeText, setTypeErrorText] = useState();
    const [leagueOptionsList, setLeagueOptionsList] = useState(props.leagueOptionsList);
    const [typeList, setTypeList] = useState([{ name: "Coach", value: "coach" }, { name: "Player", value: "player" }]);

    const handleClose = () => {
        props.handleCloseClick();
    };

    const errorCheck = (value) => {
        const charactersExp = new RegExp("[^a-zA-Z0-9_@&.,')( \-]");
        setUpdateName(value);
        if (!value)
            setNameErrorText("Name is required.");
        else if (value.match(charactersExp)) {
            setNameErrorText("Please enter valid name.");
        } else {
            setNameErrorText();
        }
    }

    const errorCheckLeauge = (value) => {
        if (!value)
            setLeaugeErrorText("League is required.");
        else
            setLeaugeErrorText();
    }

    const errorCheckType = (value) => {
        if (!value)
            setTypeErrorText("Type is required.");
        else
            setTypeErrorText();
    }

    const handleNameChange = (event) => {
        errorCheck(event.target.value);
    };

    const handleLeaugeChange = (event) => {
        setLeaugeName(event.target.value);
        errorCheckLeauge(event.target.value);
    };

    const handleTypeChange = (event) => {
        setType(event.target.value);
        errorCheckType(event.target.value);
    };

    const handleSubmit = () => {
        errorCheck(updatedName);
        errorCheckLeauge(leagueName);
        errorCheckType(type);
        if (updatedName && !errorNameText && !errorLeaugeText && !errorTypeText)
            props.onSubmitClick({ ID: props.data ? props.data.Id : null, Name: updatedName.trim(), leagueName: leagueName, type: type });
    };

    const renderLeaugeOptions = () => {
        return leagueOptionsList.map((dt, i) => {
            return (
                <MenuItem
                    label="Select a League"
                    value={dt.Type}
                    key={i} name={dt.Name}>{dt.Name}
                </MenuItem>

            );
        });
    }

    const renderTypeOptions = () => {
        return typeList.map((dt, i) => {
            return (
                <MenuItem
                    label="Select a Type"
                    value={dt.value}
                    key={i} name={dt.name}>{dt.name}</MenuItem>

            );
        });
    }

    return (
        <div>
            <Dialog maxWidth={false} onClose={handleClose} aria-labelledby="customized-dialog-title" open={props.open}>
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                    {props.action}
                </DialogTitle>
                <DialogContent dividers className={classes.dialogHeight}>
                    <Container>
                        <Grid container alignItems='center' spacing={2}>
                            <Grid item xs={12} sm={12}>
                                <TextField id="DataField" variant="outlined"
                                    label={<Typography variant="body2">Name</Typography>}
                                    size="small" fullWidth
                                    value={updatedName}
                                    name="Name"
                                    // required={true}
                                    onChange={handleNameChange}
                                    error={errorNameText}
                                />
                                {errorNameText && <FormHelperText className={classes.ErrorName} >{errorNameText}</FormHelperText>}
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl fullWidth={true} className={classes.formControl}>
                                    <InputLabel id="demo-simple-select-label">League</InputLabel>
                                    <Select
                                        name="Leauge"
                                        className="width50" value={leagueName}
                                        onChange={handleLeaugeChange}
                                        error={errorLeaugeText}>
                                        {renderLeaugeOptions()}
                                    </Select>
                                </FormControl>
                                {errorLeaugeText && <FormHelperText className={classes.ErrorName} >{errorLeaugeText}</FormHelperText>}
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <FormControl fullWidth={true} className={classes.formControl}>
                                    <InputLabel id="demo-simple-select-label">Type</InputLabel>
                                    <Select
                                        className="width50" value={type}
                                        onChange={handleTypeChange}
                                        error={errorTypeText}>
                                        {renderTypeOptions()}
                                    </Select>
                                </FormControl>
                                {errorTypeText && <FormHelperText className={classes.ErrorName} >{errorTypeText}</FormHelperText>}
                            </Grid>
                        </Grid>
                    </Container>
                </DialogContent>

                <DialogActions>
                    <Button onClick={handleSubmit} color="primary">
                        Save</Button>
                    <Button
                        onClick={props.onCancelClick}
                        color="primary"
                        autoFocus>Cancel
                        </Button>
                </DialogActions>

            </Dialog>
        </div >
    );
}

export default AwardDialog;