import { authHeader, handleResponse, handleError } from '../helper';
import { authenticationService } from '../services';
import APIHelper from "../common/ApiHelper";
import APIURLConstants from "../common/ApiURLConstants";

export const adminService = {
    changeProfileStatus,
    invitationHistory,
    InviteUser,
    getAllMemos,
    getMemo,
    saveMemo,
    deleteMemo,
    saveGroup,
    getAllGroups,
    deleteGroup,
    getUsersByGroupId,
    getUsersByGroupIdForTab,
    saveUserMemo,
    getUsersByMemoId,
    getUsersProfilesByMemoId,
    geAllUserMemos,
    donwnloadMultiplePDF,
    saveShowcase,
    deleteProfile,
    getAllShowcases,
    deleteShowcase,
    getShowcase,
    publishMemo,
    getProfilesByUserIds,
    sendShowCaseEmail,
    getFrontOfficeTabs
};

function changeProfileStatus(postData) {
    return APIHelper.post(APIURLConstants.CHANGE_PROFILE_STATUS, postData, authHeader())
        .then(handleResponse)
        .then(data => {
            return data
        })
        .catch(handleError);
}

function getProfilesByUserIds(postData) {
    return APIHelper.post(APIURLConstants.GET_PROFILES_BY_USERIDS, postData, authHeader())
        .then(handleResponse)
        .then(data => {
            return data
        })
        .catch(handleError);
}

function invitationHistory() {
    return APIHelper.get(APIURLConstants.INVITE_HISTORY, authHeader())
        .then(handleResponse)
        .then(data => {
            return data
        })
        .catch(handleError);
}

function InviteUser(emailids, role) {
    let postData = { emailids, role };
    return APIHelper.post(APIURLConstants.INVITE_USER, postData, authHeader())
        .then(handleResponse)
        .then(data => {
            return data
        })
        .catch(handleError);
}

function getAllMemos() {
    return APIHelper.get(APIURLConstants.GET_ALL_MEMOS, authHeader())
        .then(handleResponse)
        .then(lstMemos => {
            return lstMemos;
        }).catch(handleError);
}
function getMemo(id) {
    return APIHelper.get(APIURLConstants.GET_MEMO + "/" + id, authHeader())
        .then(handleResponse)
        .then(memo => {
            return memo;
        }).catch(handleError);
}
function saveMemo(memo) {
    let postData = memo;
    var url = APIURLConstants.SAVE_MEMO;
    if (memo.Id)
        url = APIURLConstants.UPDATE_MEMO;
    return APIHelper.post(url, postData, authHeader())
        .then(handleResponse)
        .then(data => {
            return data
        })
        .catch(handleError);
}
function deleteMemo(id) {
    return APIHelper.post(APIURLConstants.DELETE_MEMO + "/" + id, {}, authHeader())
        .then(handleResponse)
        .then(data => {
            return data
        })
        .catch(handleError);
}
function saveGroup(group) {
    let postData = group;
    var url = APIURLConstants.SAVE_GROUP;
    if (group.id)
        url = APIURLConstants.UPDATE_GROUP;
    return APIHelper.post(url, postData, authHeader())
        .then(handleResponse)
        .then(data => {
            return data
        })
        .catch(handleError);
}

function getAllGroups() {
    return APIHelper.get(APIURLConstants.GET_ALL_GROUP, authHeader())
        .then(handleResponse)
        .then(lst => {
            return lst;
        }).catch(handleError);
}

function deleteGroup(id) {
    return APIHelper.post(APIURLConstants.DELETE_GROUP + "/" + id, {}, authHeader())
        .then(handleResponse)
        .then(data => {
            return data
        })
        .catch(handleError);
}

function getUsersByGroupId(id) {
    return APIHelper.get(APIURLConstants.GET_USERS_BY_GROUPID + "/" + id, authHeader())
        .then(handleResponse)
        .then(data => {
            return data
        })
        .catch(handleError);
}

function getUsersByGroupIdForTab(id, pageSize, pageNo) {
    return APIHelper.get(APIURLConstants.GET_USERS_BY_GROUPID_FOR_TAB + "/" + id + "/" + pageSize + "/" + pageNo, authHeader())
        .then(handleResponse)
        .then(data => {
            return data
        })
        .catch(handleError);
}

function getFrontOfficeTabs() {
    return APIHelper.get(APIURLConstants.GET_FRONTOFFICE_TAB, authHeader())
        .then(handleResponse)
        .then(data => {
            return data
        })
        .catch(handleError);
}

function getUsersProfilesByMemoId(id) {
    return APIHelper.get(APIURLConstants.GET_USERS_PROFILE_BY_MEMOID + "/" + id, authHeader())
        .then(handleResponse)
        .then(data => {
            return data
        })
        .catch(handleError);
}

function geAllUserMemos() {
    return APIHelper.get(APIURLConstants.GET_ALL_USER_MEMOS, authHeader())
        .then(handleResponse)
        .then(lstMemos => {
            return lstMemos;
        }).catch(handleError);
}

function saveUserMemo(obj) {
    return APIHelper.post(APIURLConstants.SAVE_USERS_MEMO, obj, authHeader())
        .then(handleResponse)
        .then(data => {
            return data
        })
        .catch(handleError)
}

function getUsersByMemoId(id) {
    return APIHelper.get(APIURLConstants.GET_USERS_BY_MEMOID + id, authHeader())
        .then(handleResponse)
        .then(data => {
            return data
        })
        .catch(handleError);
}

function deleteProfile(email) {
    return APIHelper.post(APIURLConstants.DELETE_PROFILE + email, {}, authHeader())
        .then(handleResponse)
        .then(data => {
            return data
        })
        .catch(handleError);
}


function donwnloadMultiplePDF() {
    var xhr = new XMLHttpRequest();
    xhr.open('post', 'api' + APIURLConstants.GET_MULTIPLE_PROFILE_PDF, true);
    let auth = authHeader();
    xhr.setRequestHeader('Authorization', auth['Authorization']);
    xhr.setRequestHeader('Content-type', 'application/json');
    xhr.responseType = 'arraybuffer';
    return xhr;
}

function saveShowcase(data) {
    let postData = data;
    var url = APIURLConstants.SAVE_SHOWCASE;
    // if (data.id)
    //     url = APIURLConstants.UPDATE_SHOWCASE;
    return APIHelper.post(url, postData, authHeader())
        .then(handleResponse)
        .then(data => {
            return data
        })
        .catch(handleError);
}

function getAllShowcases() {
    return APIHelper.get(APIURLConstants.GET_ALL_SHOWCASES, authHeader())
        .then(handleResponse)
        .then(lstShowcases => {
            return lstShowcases;
        }).catch(handleError);
}

function sendShowCaseEmail(id, emailId) {
    return APIHelper.post(APIURLConstants.SEND_EMAIL_SHOWCASE + '/' + emailId + '/' + id, {}, authHeader())
        .then(handleResponse)
        .then(lstShowcases => {
            return lstShowcases;
        }).catch(ex => console.log(ex));
}


function deleteShowcase(id) {
    return APIHelper.post(APIURLConstants.DELETE_SHOWCASES + "/" + id, {}, authHeader())
        .then(handleResponse)
        .then(data => {
            return data
        })
        .catch(handleError);
}

function getShowcase(id) {
    return APIHelper.get(APIURLConstants.GET_SHOWCASE + "/" + id, authHeader())
        .then(handleResponse)
        .then(showcase => {
            return showcase;
        }).catch(handleError);
}

function publishMemo(id) {
    var postData = { 'Id': id }
    return APIHelper.post(APIURLConstants.PUBLISH_MEMO, postData, authHeader())
        .then(handleResponse)
        .then(data => {
            return data
        })
        .catch(handleError);
}
export default adminService;