import React, { useState, useEffect, useContext } from "react";
import APIHelper from "./ApiHelper";
import APIURLConstants from "./ApiURLConstants";
import { jwplayerService, userService } from "../services";
import FrontOfficeService from "../services/frontOffice.service";
import Helper from "./Helper";
import { Role } from "../helper";

export const FilterContext = React.createContext([]);

export const FilterProvider = (props) => {

    const [selectedSkillContext, setSelectedSkill] = useState([]);
    const [selectSortContext, setSelectSort] = useState(userService.getUserRole() === Role.Admin ? 'Name' : 'None' );
    const [selectedCurrentlyWithATeamContext, setSelectedCurrentlyWithATeam] = useState([]);
    const [selectedFormerCoachesContext, setSelectedFormerCoachesContext] = useState([]);
    const [selectedExpTypeContext, setSelectedExpType] = useState([]);

    const [selectedTeamsContext, setSelectedTeams] = useState([]);
    const [selectedNBATeamsContext, setSelectedNBATeams] = useState([]);
    const [selectedWNBATeamsContext, setSelectedWNBATeams] = useState([]);
    const [selectedNBAGLeagueTeamsContext, setSelectedNBAGLeagueTeams] = useState([]);
    const [selectedLeagueContext, setSelectedLeague] = useState([]);
    const [selectedChampionshipContext, setSelectedChampionship] = useState([]);
    const [selectedTitleContext, setSelectedTitle] = useState([]);
    const [searchedTextContext, setSearchedText] = useState("");
    const [titlesearchedTextContext, settTitleSearchedText] = useState("");
    const [leaguesearchedTextContext, setLeagueSearchedText] = useState("");
    const [championshipsearchedTextContext, setChampionshipSearchedText] = useState("");
    const [skillsearchedTextContext, setSkillSearchedText] = useState("");
    const [currentlyWithATeamsearchedTextContext, setCurrentlyWithATeamText] = useState("");
    const [formerCoachessearchedTextContext, setFormerCoachesText] = useState("");
    const [selectedPExpereienceContext, setSelectedPExpereienceContext] = useState([]);
    const [pExpereienceTextContext, setPExpereienceTextContext] = useState("");


    const [teamsearchedTextContext, setTeamSearchedText] = useState("");
    const [nbaTeamsearchedTextContext, setNbaTeamsearchedText] = useState("");
    const [wnbaTeamsearchedTextContext, setWnbaTeamsearchedText] = useState("");
    const [nbaGLeagueTeamsearchedTextContext, setNbaGLeagueTeamsearchedText] = useState("");
    const [sortOrderContext, setSortOrder] = useState("asc");
    const [filterViewContext, setFilterView] = useState(0);
    const [filterCriteriaContext, setFilterCriteria] = useState("AND");
    const [pageSizeContext, setPageSize] = useState(12);
    const [currentPageContext, setCurrentPage] = useState(1);
    const [tabIndexContext, setTabIndex] = useState(0);
    const [internationalContext, setInternational] = useState(false);
    const [userGroup, setUserGroup] = useState();

    useEffect(() => {
        setSelectSort(userService.getUserRole() === Role.Admin ? 'Name' : 'None')

    }, [userService.getUserRole()]);



    const updateAllSearchedState = (searchedText,
        selectedTitle,
        selectedLeague,
        selectedChampionship,
        selectedSkill,
        selectSort,
        sortOrder,
        filterView,
        selectedTeams,
        selectedNBATeams,
        selectedWNBATeams,
        selectedNBAGLeagueTeams,
        selectedCurrentlyWithATeam,
        selectedFormerCoach,
        titlesearchedText,
        leaguesearchedText,
        championshipsearchedText,
        skillsearchedText,
        currentlyWithATeamsearchedText,
        formerCoachesText,
        teamsearchedText,
        nbaTeamsearchedText,
        wnbaTeamsearchedText,
        nbaGLeagueTeamsearchedText,
        filterCriteria,
        pageSize,
        currentPage,
        tabIndex,
        selectedInternational,
        selectedExpType,
        selectedPExpereience,
        pExperiencesearchedText
    ) => {

        setSearchedText(searchedText);
        setSelectedTitle(selectedTitle);
        setSelectedLeague(selectedLeague);
        setSelectedChampionship(selectedChampionship);
        setSelectedSkill(selectedSkill);
        setSelectSort(selectSort);
        setSortOrder(sortOrder);
        setFilterView(filterView);
        setSelectedTeams(selectedTeams);
        setSelectedNBATeams(selectedNBATeams);
        setSelectedWNBATeams(selectedWNBATeams);
        setSelectedNBAGLeagueTeams(selectedNBAGLeagueTeams);
        setSelectedCurrentlyWithATeam(selectedCurrentlyWithATeam);
        setSelectedFormerCoachesContext(selectedFormerCoach);
        settTitleSearchedText(titlesearchedText);
        setLeagueSearchedText(leaguesearchedText);
        setChampionshipSearchedText(championshipsearchedText);
        setSkillSearchedText(skillsearchedText);
        setCurrentlyWithATeamText(currentlyWithATeamsearchedText);
        setFormerCoachesText(formerCoachesText);
        setTeamSearchedText(teamsearchedText);
        setNbaTeamsearchedText(nbaTeamsearchedText);
        setWnbaTeamsearchedText(wnbaTeamsearchedText);
        setNbaGLeagueTeamsearchedText(nbaGLeagueTeamsearchedText);
        setFilterCriteria(filterCriteria);
        setPageSize(pageSize);
        setCurrentPage(currentPage);
        setTabIndex(tabIndex);
        setInternational(selectedInternational);
        setSelectedExpType(selectedExpType);
        setSelectedPExpereienceContext(selectedPExpereience)
        setPExpereienceTextContext(pExperiencesearchedText)
    }

    const refreshFilterData = () => {
        setSearchedText("");
        setSelectedTitle([]);
        setSelectedLeague([]);
        setSelectedChampionship([]);
        setSelectedSkill([]);
        setSelectSort(userService.getUserRole() === Role.Admin ? 'Name' : 'None');
        setSortOrder("asc");
        setFilterView(0);
        setSelectedTeams([]);
        setSelectedNBATeams([]);
        setSelectedWNBATeams([]);
        setSelectedNBAGLeagueTeams([]);
        setSelectedCurrentlyWithATeam([]);
        setSelectedFormerCoachesContext([])
        settTitleSearchedText("");
        setLeagueSearchedText("");
        setChampionshipSearchedText("");
        setSkillSearchedText("");
        setCurrentlyWithATeamText("");
        setFormerCoachesText("");
        setTeamSearchedText("");
        setNbaTeamsearchedText("");
        setWnbaTeamsearchedText("");
        setNbaGLeagueTeamsearchedText("");
        setFilterCriteria("AND");
        setPageSize(12);
        setCurrentPage(1);
        setTabIndex(0);
        setInternational(false);
        setSelectedExpType([]);
        setPExpereienceTextContext("");
        setSelectedPExpereienceContext([]);


    }

    const setDataFromAnalytics = (data) => {
        switch (data.section) {
            case "championship":
                setSelectedExpType(["Current"]);
                if (data.selectedFilter.length == 1) {
                    setSelectedCurrentlyWithATeam(data.selectedFilter);
                }
                else {
                    setSelectedLeague(data.selectedFilter);
                }
                break;
            // case byStatusTitle:
            //     setDataFromAnalytics(["Currently with a Team"]);
            //     break;
            case "currentWork":
                if (data.selectedFilter.length == 1) {
                    setSelectedCurrentlyWithATeam(data.selectedFilter);
                }
                else {
                    setSelectedLeague(data.selectedFilter);
                }

                break;
            case "headCoach":
                setSelectedExpType(["Current"]);
                setSelectedTitle(["Head Coach"])
                if (data.selectedFilter[0] != null) {
                    if (data.selectedFilter.length == 1) {
                        setSelectedCurrentlyWithATeam(data.selectedFilter);
                    }
                    else {
                        setSelectedLeague(data.selectedFilter);
                    }
                }
                break;
            case "Tab":
                setSelectedExpType(["Current"]);
                setTabIndex(data.selectedFilter);
                setFilterView(data.selectedView);
                break;
        }
        //setSelectedCurrentlyWithATeam(data);
        console.log(data);
    }

    const updateGroup = (group) => {
        setUserGroup(group);
    }
    const providerValue = {
        searchedTextContext,
        selectedTitleContext,
        selectedLeagueContext,
        selectedChampionshipContext,
        selectedSkillContext,
        selectSortContext,
        sortOrderContext,
        filterViewContext,
        selectedTeamsContext,
        selectedNBATeamsContext,
        selectedWNBATeamsContext,
        selectedNBAGLeagueTeamsContext,
        selectedCurrentlyWithATeamContext,
        selectedFormerCoachesContext,
        titlesearchedTextContext,
        leaguesearchedTextContext,
        championshipsearchedTextContext,
        skillsearchedTextContext,
        currentlyWithATeamsearchedTextContext,
        formerCoachessearchedTextContext,
        teamsearchedTextContext,
        nbaTeamsearchedTextContext,
        wnbaTeamsearchedTextContext,
        nbaGLeagueTeamsearchedTextContext,
        filterCriteriaContext,
        pageSizeContext,
        currentPageContext,
        tabIndexContext,
        refreshFilterData,
        updateAllSearchedState,
        internationalContext,
        selectedExpTypeContext,
        setDataFromAnalytics,
        updateGroup,
        userGroup,
        selectedPExpereienceContext,
        pExpereienceTextContext
    };

    return (
        <FilterContext.Provider value={providerValue}>
            {props.children}
        </FilterContext.Provider>
    );
};

export const FilterConsumer = FilterContext.Consumer;

export default FilterContext;
