import React, { Fragment, useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from '@material-ui/icons/Delete';

import TextField from '@material-ui/core/TextField';
import Helper from '../../common/Helper';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1
    },
    grid: {
        padding: theme.spacing(1),
        alignSelf: 'center',
        display: 'flex',
    },
    gridIcons: {
        display: 'flex',
        '& button': {
            [theme.breakpoints.down("xs")]: {
                padding: theme.spacing(.75),
            },
        },
    },
    error: {
        borderColor: theme.palette.error.dark,
    },
    btnDel: {
        color: theme.palette.secondary.main
    }
}));


const VideoURLs = ((props) => {
    const [formDataCollection, setFormData] = useState([getEmptyItem()]);

    useEffect(() => {        
        let dataURL = formDataFromProp();
        if (props.isInValid) {
            dataURL = ValidateData(dataURL);
        }
        if (props.data && props.data.length == 0) {
            dataURL.push(getEmptyItem());
        }
        setFormData(dataURL);
    }, [props.data, props.isInValid])


    const ValidateData = (dataURL) => {
        dataURL.forEach(lastData => {
            if (lastData.label.value.trim() === '' || lastData.url.value.trim() === '') {
                lastData.label.hasError = (lastData.label.value.trim() === '') ? true : false;
                lastData.url.hasError = (lastData.url.value.trim() === '') ? true : false;
            }
        })
        return dataURL;
    }


    function formDataFromProp() {
        return (props.data.map((item) => {
            return {
                id: item.id,
                label: { value: item.label, validationMessage: '', hasError: null },
                url: { value: item.url, validationMessage: '', hasError: null },
            }
        }))
    }

    function getEmptyItem() {
        let randomId = Helper.GetRandomId();
        return {
            id: randomId,
            label: { value: '', validationMessage: '', hasError: null },
            url: { value: '', validationMessage: '', hasError: null },
        }
    }

    function handleInputChange(e, hasError) {
        let data = [...formDataCollection];
        let index = data.findIndex(x => x.id === e.itemId)
        data[index] = e.formData;
        setFormData(data);
        if (!hasError || hasError == undefined) {
            let pureObject = formDataCollection.map(function (dc) { return { id: dc.id, label: dc.label.value, url: dc.url.value } });
            props.onChange(pureObject);
        }
    }


    function addItem() {
        //... Add a new item to the formDataCollection array.
        let dataCollection = [...formDataCollection];
        let lastData = dataCollection[dataCollection.length - 1];

        if (lastData.label.value.trim() === '' || lastData.url.value.trim() === '') {
            lastData.label.hasError = (lastData.label.value.trim() === '') ? true : false;
            lastData.url.hasError = (lastData.url.value.trim() === '') ? true : false;

            handleInputChange({ itemId: lastData.id, formData: lastData }, true);
        } else {

            lastData.label.hasError = false;
            lastData.url.hasError = false;
            dataCollection.push(getEmptyItem());
            setFormData(dataCollection);
        }
    }

    function removeItem(itemId) {
        //... Remove an item from formDataCollection array.
        let DataCollection = [...formDataCollection];
        let index = DataCollection.findIndex(x => x.label.value === itemId.label.value && x.url.value === itemId.url.value);
        DataCollection.splice(index, 1);
        setFormData(DataCollection);
        let pureObject = DataCollection.map(function (dc) { return { id: dc.id, label: dc.label.value, url: dc.url.value } });
        props.onChange(pureObject);
        // this.notifyParentAboutDataChange();
    }


    return (
        <Grid container>
            {formDataCollection.map((item, index) => {
                return (<Item key={index}
                    addMediaItem={addItem}
                    itemRow={item}
                    onChange={handleInputChange}
                    showAddOption={(index === formDataCollection.length - 1)}
                    removeItem={removeItem}
                />
                )
            })}
        </Grid>
    )
});

function Item(props) {

    const classes = useStyles();

    function handleAddClick() {
        props.addMediaItem();

    }

    function handleInputChange(value, name) {

        let formData = props.itemRow;
        formData[name].value = value;
        formData[name].hasError = false;
        //... Call Parent
        props.onChange({ itemId: props.itemRow.id, formData: formData });
    }
    const [validURL, setValidURL] = useState(true);
    function validateURL(e) {
        let regex = /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/
        if (e.target.value != "") {
            setValidURL(regex.test(e.target.value));
        }
        else {
            setValidURL(true);
        }
    }

    return (
        <Fragment>
            <Grid item md={6} xs={12} className={classes.grid}>
                <TextField id={`url_${props.itemRow.id}`} onBlur={validateURL} error={props.itemRow.url.hasError || !validURL}
                    variant="outlined" value={props.itemRow.url.value}
                    label={<Typography variant="body2">URL</Typography>}
                    placeholder="ex. https://www.nba.com"
                    onChange={e => handleInputChange(e.target.value, 'url')}
                    size="small" style={{ width: '100%' }} />
                {/* {props.itemRow.url.validationMessage!= '' && <Typography className={classes.error} variant="body2">{props.itemRow.url.validationMessage}</Typography> } */}
            </Grid>
            <Grid item md={4} xs={8} className={classes.grid}>
                <TextField id={`title_${props.itemRow.id}`} error={props.itemRow.label.hasError} variant="outlined" value={props.itemRow.label.value}

                    onChange={e => handleInputChange(e.target.value, 'label')}
                    label={<Typography variant="body2">URL Label</Typography>}
                    size="small" style={{ width: '100%' }} />
                {/* {props.itemRow.label.validationMessage!= '' && <Typography className={classes.error} variant="body2" >{props.itemRow.label.validationMessage}</Typography> } */}
            </Grid>
            <Grid item md={2} xs={4} className={classes.gridIcons}>
                <Tooltip title="Remove">
                    <IconButton onClick={(e) => props.removeItem(props.itemRow)}>
                        <DeleteIcon className={classes.btnDel} >Delete</DeleteIcon>
                    </IconButton>
                </Tooltip>
                {props.showAddOption &&
                    <Tooltip title="Add more">
                        <IconButton onClick={handleAddClick}>
                            <AddCircleIcon color="primary" >Add</AddCircleIcon>
                        </IconButton>
                    </Tooltip>
                }
            </Grid>
        </Fragment >
    )
}

export default VideoURLs;