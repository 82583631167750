import React, { useEffect, useState, useContext } from 'react';
import { withRouter } from "react-router-dom";
import { authenticationService } from '../../services';


const Logout = (props) => {
    useEffect(() => {
        logout();
    }, [])

    function logout() {
        document.cookie = 'HangFireCookie=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;'
        authenticationService.logout();
        props.history.push('/');
    }

    return (
        <></>
    );
}

export default withRouter(Logout);