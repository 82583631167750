import React, { useState, useContext, useEffect } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import { Box, Container, Grid, Typography, Paper, Divider,Checkbox,FormControlLabel } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    root: {
        minWidth: '400px'
    },
    notesHeader: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(1.5),
    },
    dialogWidth: {
        overflow: 'hidden',
        minWidth: '400px',
    },
    ErrorName: {
        color: 'red',
    },
    nameContainer: {
        margin: 15,
        display: 'flex',
        flexGrow: '1',
        flexDirection: 'column'
    },
    formLabel: {
        color: theme.palette.primary.main,
        fontWeight: 500,
    },
    userLabel: {
        paddingLeft: '10px',
    },
    addButton: {
        position: 'relative',
        top: theme.spacing(-4),
        paddingRight: theme.spacing(1)
    },
    progress: {
        marginLeft: '50%',

    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
}));

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
        backgroundColor: theme.palette.primary.main,
        color: '#fff'
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography className="font500" variant="subtitle1" noWrap>{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: '0px 0px',
        [theme.breakpoints.down(281)]: {
            width: '218px',
        },
        [theme.breakpoints.between(281, 322)]: {
            width: '258px',
        },
        [theme.breakpoints.between(321, 556)]: {
            width: '275px'
        },
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

const HeadCoachDataDialogue = (props) => {
    const classes = useStyles();
    const [updatedName, setUpdateName] = useState(props.Name);
    const [isManaged, setIsManaged] = useState(props.isManageData);
    const [errorText, setErrorText] = useState();

    const handleClose = () => {
        props.handleCloseClick();
    };

    const errorCheck = (value) => {
        const charactersExp = new RegExp("[^a-zA-Z0-9_@&.,')( ]");
        setUpdateName(value);
        if (!value)
            setErrorText("Name is required.");
        else if (value.match(charactersExp) || value.trim() == '') {
            setErrorText("Please enter valid name.");
        } else {
            setErrorText(null);
        }
    }

    const handleChange = (event) => {
        errorCheck(event.target.value);
    };

    const handleSubmit = () => {
        errorCheck(updatedName);
        if (updatedName && !errorText)
            props.onSubmitClick(updatedName.trim(),isManaged);
    };

    const handleUpdateManaged = (e) => {
        setIsManaged(e.target.checked)
    };

    return (
        <div>
            <Dialog maxWidth="sm" onClose={handleClose} aria-labelledby="customized-dialog-title" open={props.open}>
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                    {props.action}
                </DialogTitle>
                <DialogContent dividers className={classes.dialogWidth}>
                    <Grid container alignItems='center'>
                    <Box display="flex" flexDirection="column">
                        <Grid item>
                        <div className={classes.nameContainer}>
                            <TextField id="DataField" variant="outlined" fullWidth={true}
                                label={<Typography variant="body2">Name</Typography>}
                                size="small"
                                value={updatedName}
                                name="Name"
                                onChange={handleChange}
                                error={errorText}
                                inputProps={{ maxLength: 150 }}
                            />
                            {errorText && <Typography className={classes.ErrorName} variant="body2">{errorText}</Typography>}
                        </div>
                        </Grid>
                        <Grid item>     
                        <div className={classes.nameContainer}>                
                        <FormControlLabel
                                className={classes.invite}
                                label="Is Managed?"
                                labelPlacement='end'
                                control={
                                    <Checkbox   
                                        checked={isManaged}
                                        onChange={handleUpdateManaged}
                                        name="isManaged"
                                        color="primary"
                                    />
                                }

                            />  
                            </div>                  
                        </Grid>
                        </Box>
                    </Grid>
                </DialogContent>

                <DialogActions>
                    <Button onClick={handleSubmit} color="primary">
                        Save</Button>
                    <Button
                        onClick={props.onCancelClick}
                        color="primary"
                        autoFocus>Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </div >
    );
}

export default HeadCoachDataDialogue;