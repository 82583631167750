import React, { useState, useEffect, useRef, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import ShowHTML from "../../../sharedComponents/HTMLEditor/ShowHTML";
import { useStaticState } from "@material-ui/pickers";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
}));

const OtherView = (props) => {
  const classes = useStyles();
  const [description, setDescription] = useState(props.data && props.data.description ? props.data.description : "");
  useEffect(() => {
    setDescription(props.data && props.data.description ? props.data.description : "");
  })
  return (
    <div className={classes.root}>
      <ShowHTML heading="Other Details" data={description} />
    </div>
  );
};

export default OtherView;
