import Helper from '../../common/Helper';

export default class ProfileCommonFunction {

    static getYearOfExperience(coachingAndOtherJobExperience) {
        let exp = [];
        let league = "";
        let totalExp = [];
        let headExp = coachingAndOtherJobExperience && coachingAndOtherJobExperience.headCoachExperience ? coachingAndOtherJobExperience.headCoachExperience : [];
        let assExp = coachingAndOtherJobExperience && coachingAndOtherJobExperience.assistantCoachExperience ? coachingAndOtherJobExperience.assistantCoachExperience : [];
        if (headExp.length > 0) {
            for (let i = 0; i < headExp.length; i++) {
                league = headExp[i].league;
                exp = headExp[i].experience;
                if (exp.length > 0) {
                    for (let y = 0; y < exp.length; y++) {
                        let obj = {
                            fromDate: exp[y].fromDate,
                            toDate: exp[y].toDate,
                        };
                        totalExp.push(obj);
                    }
                }
            }
        }
        if (assExp.length > 0) {
            for (let i = 0; i < assExp.length; i++) {
                league = assExp[i].league;
                exp = assExp[i].experience;
                if (exp.length > 0) {
                    for (let y = 0; y < exp.length; y++) {
                        let obj = {
                            fromDate: exp[y].fromDate,
                            toDate: exp[y].toDate,
                        };
                        totalExp.push(obj);
                    }
                }
            }
        }

        totalExp.sort(function (a, b) {
            if (a.fromDate > b.fromDate) {
                return -1;
            }
            if (a.fromDate < b.fromDate) {
                return 1;
            }
            return 0;
        });

        let totalDays = ProfileCommonFunction.calculatTotalDays(totalExp);
        let yearAndMonth = ProfileCommonFunction.calculateYearAndMonth(totalDays)
        return yearAndMonth;

    }

    static calculateYearAndMonth(totalDays) {
        let year = 0;
        let month = 0;
        year = ~~(totalDays / 365);
        let remainDays = totalDays - (year * 365);
        month = ~~(remainDays / 30);
        return { "year": year, "month": month };
    }

    static calculatTotalDays(totalExp) {
        let totalDays = 0;
        let date1 = "";
        let date2 = "";
        for (let x = 0; x < totalExp.length; x++) {
            if (totalExp[x].toDate == "Present") {
                totalDays = 0;
                date1 = new Date();
                date2 = totalExp[x].fromDate;
                totalDays += ProfileCommonFunction.GetDiffrenceBWDates(date1, date2);
            }
            else {
                if (date2 >= totalExp[x].toDate || date2 == "") {
                    date2 = totalExp[x].fromDate;
                    date1 = totalExp[x].toDate;
                    totalDays += ProfileCommonFunction.GetDiffrenceBWDates(date1, date2);
                }
                else if (date1 < totalExp[x].toDate && date2 < totalExp[x].toDate) {
                    let days = ProfileCommonFunction.GetDiffrenceBWDates(date1, date2);
                    date1 = totalExp[x].toDate;
                    date2 = totalExp[x].fromDate;
                    totalDays += ProfileCommonFunction.GetDiffrenceBWDates(date1, date2) - days;
                }
                else if (date2 < totalExp[x].toDate && date2 > totalExp[x].fromDate) {
                    date1 = date2;
                    date2 = totalExp[x].fromDate;
                    totalDays += ProfileCommonFunction.GetDiffrenceBWDates(date1, date2);
                }
                else if (date1 != "Present" && date1 < totalExp[x].toDate) {
                    date1 = totalExp[x].toDate;
                    date2 = totalExp[x].fromDate;
                    totalDays += ProfileCommonFunction.GetDiffrenceBWDates(date1, date2);
                }
            }
        }
        return totalDays;
    }


    static GetDiffrenceBWDates(date1, date2) {
        var d1 = new Date(date1);
        var d2 = new Date(date2);
        const diffTime = Math.abs(d1 - d2);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        return diffDays;
    }

    static getPastCoachingExperience(coachingAndOtherJobExperience) {
        let exp = [];
        let league = "";
        let pastExp = [];
        let headCoach = [];
        let headExp = coachingAndOtherJobExperience && coachingAndOtherJobExperience.headCoachExperience ? coachingAndOtherJobExperience.headCoachExperience : [];
        let assExp = coachingAndOtherJobExperience && coachingAndOtherJobExperience.assistantCoachExperience ? coachingAndOtherJobExperience.assistantCoachExperience : [];
        if (headExp.length > 0) {
            for (let i = 0; i < headExp.length; i++) {
                league = headExp[i].league;
                exp = headExp[i].experience.filter((x) => x.toDate != "Present");
                if (exp.length > 0) {
                    for (let y = 0; y < exp.length; y++) {
                        let obj = {
                            league: league,
                            teamName: exp[y].team.teamName,
                            logoURL: exp[y].team.logoURL,
                            title: "Head Coach",
                            fromDate: Helper.GetYearFromDate(exp[y].fromDate),
                            toDate: Helper.GetYearFromDate(exp[y].toDate),
                        };
                        pastExp.push(obj);
                    }
                }
            }
        }
        if (assExp.length > 0) {
            for (let i = 0; i < assExp.length; i++) {
                league = assExp[i].league;
                exp = assExp[i].experience.filter((x) => x.toDate != "Present");
                if (exp.length > 0) {

                    for (let y = 0; y < exp.length; y++) {
                        let obj = {
                            league: league,
                            teamName: exp[y].team.teamName,
                            logoURL: exp[y].team.logoURL,
                            title: exp[y].title && exp[y].title.Name ? exp[y].title.Name : "Assistant Coach",
                            fromDate: Helper.GetYearFromDate(exp[y].fromDate),
                            toDate: Helper.GetYearFromDate(exp[y].toDate),
                        };
                        pastExp.push(obj);

                    }
                }
            }

        }
        pastExp.sort(function (a, b) {
            if (a.fromDate > b.fromDate) {
                return -1;
            }
            if (a.fromDate < b.fromDate) {
                return 1;
            }
            return 0;
        });


        return { "pastExp": pastExp }
    }

    static getCurrentCoachingExperience(coachingAndOtherJobExperience) {
        let exp = [];
        let league = "";
        let currentExp = [];
        let headCoach = [];
        let headExp = coachingAndOtherJobExperience && coachingAndOtherJobExperience.headCoachExperience ? coachingAndOtherJobExperience.headCoachExperience : [];
        let assExp = coachingAndOtherJobExperience && coachingAndOtherJobExperience.assistantCoachExperience ? coachingAndOtherJobExperience.assistantCoachExperience : [];
        if (headExp.length > 0) {
            for (let i = 0; i < headExp.length; i++) {
                league = headExp[i].league;
                exp = headExp[i].experience.filter((x) => x.toDate === "Present");
                if (exp.length > 0) {
                    for (let y = 0; y < exp.length; y++) {
                        let obj = {
                            league: league,
                            teamName: exp[y].team.teamName,
                            logoURL: exp[y].team.logoURL,
                            title: "Head Coach",
                            titleSubCategory: exp[y].titleSubCategory && exp[y].titleSubCategory.Name ? exp[y].titleSubCategory.Name : "",
                        };
                        currentExp.push(obj);
                    }
                }
            }
        }
        if (assExp.length > 0) {
            for (let i = 0; i < assExp.length; i++) {
                league = assExp[i].league;
                exp = assExp[i].experience.filter((x) => x.toDate === "Present");
                if (exp.length > 0) {
                    for (let y = 0; y < exp.length; y++) {
                        let titleSubCategory = exp[y].titleSubCategory && exp[y].titleSubCategory.Name ? `(${exp[y].titleSubCategory.Name})` : "";
                        let obj = {
                            league: league,
                            teamName: exp[y].team.teamName,
                            logoURL: exp[y].team.logoURL,
                            title: `Assistant Coach ${titleSubCategory}`,   // appending the titleSubCategory in brackets
                        };
                        currentExp.push(obj);
                    }
                }
            }
        }
        return { "currentExp": currentExp }
    }

    static getWonChampionship(coachingAndOtherJobExperience, playingExperience) {
        let count = coachingAndOtherJobExperience ? this.getWonChampionshipCountWithoutNA(coachingAndOtherJobExperience.championship) : 0;
        count += playingExperience ? this.getWonChampionshipCountWithoutNA(playingExperience.championship) : 0;

        return count;
    }

    static calculateHeadcoaches(coachingAndOtherJobExperience, playingExperience) {
        let assExp = coachingAndOtherJobExperience && coachingAndOtherJobExperience.assistantCoachExperience ? coachingAndOtherJobExperience.assistantCoachExperience : [];
        //let skillExperience = coachingAndOtherJobExperience && coachingAndOtherJobExperience.skillExperience ? coachingAndOtherJobExperience.skillExperience : [];
        //let formerExperience = playingExperience.formerExperience && playingExperience.formerExperience ? playingExperience.formerExperience : [];
        let headCoachesArray = [];
        let exp = [];

        if (assExp.length > 0) {
            for (let i = 0; i < assExp.length; i++) {
                exp = assExp[i].experience;
                if (exp.length > 0) {
                    for (let y = 0; y < exp.length; y++) {
                        if (exp[y].headCoachName.length > 0) {
                            exp[y].headCoachName.forEach(element => {
                                headCoachesArray.push(element);
                            });
                        }
                    }
                }
            }
        }

        // if (skillExperience.length > 0) {
        //     for (let i = 0; i < skillExperience.length; i++) {
        //         exp = skillExperience[i].experience;
        //         if (exp.length > 0) {
        //             for (let y = 0; y < exp.length; y++) {
        //                 if (exp[y].headCoachName.length > 0) {
        //                     exp[y].headCoachName.forEach(element => {
        //                         headCoachesArray.push(element);
        //                     });
        //                 }
        //             }
        //         }
        //     }
        // }

        // if (formerExperience.length > 0) {
        //     for (let i = 0; i < formerExperience.length; i++) {
        //         exp = formerExperience[i].experience;
        //         if (exp.length > 0) {
        //             for (let y = 0; y < exp.length; y++) {
        //                 if (exp[y].headCoachName.length > 0) {
        //                     exp[y].headCoachName.forEach(element => {
        //                         headCoachesArray.push(element);
        //                     });
        //                 }
        //             }
        //         }
        //     }
        // }

        const unique = (value, index, self) => {
            return self.indexOf(value) === index
        }
        const uniqueCoaches = headCoachesArray.filter(unique)

        //console.log(uniqueAges)
        return uniqueCoaches;
    }

    static getWonChampionshipCountWithoutNA(championship) {
        if (championship && championship.length > 0) {
            let championshipsWithoutNA = championship.filter(c => c.league && c.league != 'na');
            if (championshipsWithoutNA) {
                {
                    let count = 0;
                    if (championshipsWithoutNA.length > 0) {
                        championshipsWithoutNA.forEach(element => {
                            count += element.experience.length;
                        });
                    }
                    return count;
                }
            }
        }
        return 0;
    }

    static sortByValue(list, value, order = "asc") {
        if (value == "Name") {
            if (order == "desc") {
                list.sort(function (a, b) {
                    if (a.Name > b.Name) {
                        return -1;
                    }
                    if (a.Name < b.Name) {
                        return 1;
                    }
                    return 0;
                });
            } else {
                list.sort(function (a, b) {
                    if (a.Name < b.Name) {
                        return -1;
                    }
                    if (a.Name > b.Name) {
                        return 1;
                    }
                    return 0;
                });
            }
        }
        return list;
    }

    static sortById(list, value, order = "asc") {
        if (value == "Id") {
            if (order == "desc") {
                list.sort(function (a, b) {
                    if (a.Id > b.Id) {
                        return -1;
                    }
                    if (a.Id < b.Id) {
                        return 1;
                    }
                    return 0;
                });
            } else {
                list.sort(function (a, b) {
                    if (a.Id < b.Id) {
                        return -1;
                    }
                    if (a.Id > b.Id) {
                        return 1;
                    }
                    return 0;
                });
            }
        }
        return list;
    }

}
