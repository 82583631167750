import { authHeader, handleResponse, handleError } from '../helper';
import APIHelper from "../common/ApiHelper";
import APIURLConstants from "../common/ApiURLConstants";


export const configService = {
    getReminderDays,
    setReminderDays,
    getConfigValue,
    getPublicConfigsValue
};

function getReminderDays() {
    return APIHelper.get(APIURLConstants.GET_REMINDER_DAYS, authHeader())
        .then(data => {
            return data;
        })
        .catch(handleError);
}

function setReminderDays(postData) {
    return APIHelper.post(APIURLConstants.SET_REMINDER_DAYS, postData, authHeader())
        .then(data => {
            return data;
        }).catch(handleError);
}

function getConfigValue(configKey) {
    return APIHelper.get(APIURLConstants.GET_CONFIG_VALUE + configKey, authHeader())
        .then(data => {
            return data;
        })
        .catch(handleError);
}

function getPublicConfigsValue() {
    return APIHelper.get(APIURLConstants.GET_PUBLIC_CONFIGS, authHeader())
        .then(data => {
            return data;
        })
        .catch(handleError);
}