
import { Box, Button, FormControl, FormHelperText, Grid, TextField, useTheme } from "@material-ui/core/";
import React, { useEffect, useState } from "react";
import usePostForm from "../../sharedComponents/customHooks/usePostForm";
import postCommentSchema from "../../validationSchema/postCommentSchema";
import { socialPostService } from "../../services";
import { useToast } from "../../context/SnackBarContext";

const ReplySection = (props) => {

    const theme = useTheme();
    const { showToast } = useToast();

    const {
        errors,
        handleChange,
        handleSubmit,
        setValues,
        values,
    } = usePostForm(() => onSubmitComment(values), postCommentSchema);

    const [isLoading, setIsLoading] = useState(false);

    const postActionTabs = {
        REPLY: 'REPLY_TAB',
        COMMENT: 'COMMENT_TAB'
    };


    useEffect(() => {
        // If isEdit is true, populate the comment text into the form
        if (props.isEdit && props.commentData) {
            setValues({ comment: props.commentData.comment });
        }
    }, [props.isEdit, props.commentData]);

    const onSubmitComment = () => {
        setIsLoading(false);
    
        // Prepare comment data based on whether it's an update or a add comment
        const commentData = {
            comment: values.comment,
            socialPostId: props.postId
        };
    
        if (props.isEdit) {
            // If it's an edit, include additional data
            commentData.id = props.commentData.id;
            commentData.createdAt = props.commentData.createdAt;
            commentData.createdByUserName = props.commentData.createdByUserName;
            commentData.createdByName = props.commentData.createdByName;
        }
    
        // service function to call according to add or edit comment
        const serviceFunction = props.isEdit ? socialPostService.updateComment : socialPostService.addComment;
    
        serviceFunction(commentData)
            .then(() => {
                showToast("success", 'Comment added successfully.');
                // If it's an edit, update the comment data
                if (props.isEdit) {
                    props.onEditComment(props.commentData.id, values.comment);
                } else {
                    // If it's a new comment, handle tab index and fetch all comments
                    props.handleTabIndex(postActionTabs.COMMENT);
                    getAllComments();
                }
            })
            .catch(error => {
                console.error("Error", error);
                showToast("error", 'Failed to add comment.');
            })
            .finally(() => {
                setIsLoading(false); // Reset loading state
                setValues({ comment: '' }); // Clear comment field
            });
    };
    

    const getAllComments = () => {
        socialPostService.getAllComments(props.postId).then((res) => {
            props.setCommentList(res)
        }).catch(error => {
            console.log("Error", error);
        })
    };


    return (
        <Box sx={{ backgroundColor: theme.palette.background.paper, padding: theme.spacing(2), borderRadius: theme.shape.borderRadius, marginTop: theme.spacing(2) }} >
            <Grid item xs={12} style={{ marginLeft: 16, marginRight: 16, marginTop: 16 }}>
                <Grid item spacing={2} xs={12} style={{ marginBottom: 8 }} >
                    <FormControl fullWidth>
                        <TextField
                            id="comment"
                            variant="outlined"
                            value={values.comment}
                            name="comment"
                            label={props.isEdit ? "Update a comment" : "Add a comment"}
                            onChange={(event) => handleChange('comment', event.target.value)}
                            multiline
                            InputProps={{
                                endAdornment:
                                    <Button
                                        color="secondary"
                                        variant="contained"
                                        onClick={handleSubmit}
                                        disabled={props.isLoadingReplyRequest}>
                                        {props.isEdit ? "Update" : "Reply"}
                                    </Button>
                            }}
                        />
                        <FormHelperText disabled style={{ fontStyle: 'italic' }} >Do not share personal information such as contact info, emails, etc.</FormHelperText>
                        <FormHelperText error>{errors.comment}</FormHelperText>
                    </FormControl>
                </Grid>
            </Grid>
        </Box>
    );
};

export default ReplySection;
