import React, { useState, useEffect, useRef, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core/";
import Card from "@material-ui/core/Card";
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';
import { AppDataContext} from '../../common/AppContext';
import CardContent from "@material-ui/core/CardContent";
import { useParams } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  cardDetails: {
    display: 'flex',
    justifyContent: 'left',
  },
  section: {
    textAlign: "left",
    '& div': {
      borderRadius:'0',
    },
  },
}));

export default function ContactHelpDesk(props) {
  const classes = useStyles();
  const {
    setMobileView
  } = useContext(AppDataContext);
  let params = useParams();
  const query = new URLSearchParams(props.location.search);
  const view = query.get('view')

  useEffect(() => {
    if(view && view === 'm')
    {
      setMobileView(true)

    }   

    return () => {
      // Anything in here is fired on component unmount.
      setMobileView(false)
  }
  }, [])

  return (
    <div className={classes.section}>
      <Card>
        <CardContent>
          <Typography variant="subtitle1" color="secondary">
            Contact Us
        </Typography>
          <br />
          <Typography variant="body2" className={classes.cardDetails}>
            If you have any questions or would like to share additional feedback, please reach out to:
            {/* <EmailIcon />&nbsp;&nbsp; <a href='mailto: info@nbacoachesdatabase.com' className={classes.email}>info@nbacoachesdatabase.com</a> */}
          </Typography>

          <Typography variant="body2" className={classes.cardDetails}><span>David Fogel (<PhoneIcon style={{verticalAlign: 'middle', width: '18px', height: '18px'}} /> 212-424-0100 Ext. 28;&nbsp; <EmailIcon style={{verticalAlign: 'middle', width: '18px', height: '18px'}} /> <a href='mailto: david.fogel@nbacoaches.com' className={classes.email}>david.fogel@nbacoaches.com</a>) at the NBCA or Chris Montefusco at the NBA league office (<PhoneIcon style={{verticalAlign: 'middle', width: '18px', height: '18px'}} /> 201-673-7983; <EmailIcon style={{verticalAlign: 'middle', width: '18px', height: '18px'}} /> <a href='mailto: cmontefusco@nba.com' className={classes.email}> cmontefusco@nba.com</a>)</span></Typography>

          <br />
          {/* <Typography className={classes.cardDetails}>
            <PhoneIcon />&nbsp;&nbsp; +1 111-111-1111
        </Typography> */}
        </CardContent>
      </Card></div>
  );
}
