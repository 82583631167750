import React, { useEffect, useState, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import AppHeaderBar from '../../sharedComponents/appBar/AppHeaderBar';
import { userService } from '../../services';
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        // justifyContent: 'center',
        // alignItems: 'center'
    },
    logoStrip: {
        display: 'flex',
        // flex: 1,
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'start',
        width: '100%',
        height: '150px',
        // paddingTop: '7%'
    },
}));

const PrivacyPolicy = (props) => {
    const classes = useStyles();
    const [privacyPolicy, setPrivacyPolicy] = useState({});
    useEffect(() => {
        userService.getPrivacyPolicy().then(data => {
            setPrivacyPolicy(data);
        });
    }, [])




    return (
        <div className={classes.root}>
            <AppHeaderBar onlyHeader={true}></AppHeaderBar>
            <Typography variant='h4' component='h6' color='primary' style={{ paddingLeft: '50px', paddingTop: "50px" }}>
                Privacy Policy
            </Typography>


            <div style={{ padding: '20px 20px' }}>
                <div className="content" dangerouslySetInnerHTML={{ __html: privacyPolicy.pageContain }}></div>
            </div>
        </div>
    );
}

export default withRouter(PrivacyPolicy);