import React, { useEffect, useState, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import nbaLogo from '../../images/nba_logo.png';

import RouteConstants from '../../common/RouteConstants';
import useRegistrationForm from '../../sharedComponents/customHooks/useRegistrationForm';
import { authenticationService } from '../../services';
import Loader from "../../sharedComponents/Loader/Loader";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import './ForgotPassword.css';
import ErrorPopover from "../../sharedComponents/dialog/ErrorPopover";
import zxcvbn from "zxcvbn";
import ResetPasswordValidationSchema from '../../validationSchema/ResetPasswordValidationSchema';
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        justifyContent: 'center',
        alignItems: 'center'
    },
    logoStrip: {
        display: 'flex',
        // flex: 1,
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'start',
        width: '100%',
        height: '150px',
        // paddingTop: '7%'
    },
    logo: {
        [theme.breakpoints.up('sm')]: {
            width: '425px',
            height: '170px'
        },
        [theme.breakpoints.down('md')]: {
            height: '79px',
            width: '255px'
        },
    },
    loginBox: {
        width: '280px',
        position: 'fixed',
        padding: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: theme.spacing(1.5),
        [theme.breakpoints.up('lg')]: {
            right: '10%',
            alignSelf: 'flex-end',
            height: '320px',
        },
        [theme.breakpoints.down('sm')]: {
            alignSelf: 'center',
            height: '400px',
            position: 'static',
            margin: theme.spacing(2),
        },
    },
    sectiondivider: {
        margin: theme.spacing(1)
    },
    nbaLogo: {
        width: '50px',
        paddingLeft: '10%',
        paddingRight: '2.5rem',
    },
}));

const ResetPassword = (props) => {
    const classes = useStyles();

    useEffect(() => {
        if (authenticationService.currentUserValue) {
            props.history.push('/view');
        }
    }, []);

    let initialState = { password: '', confirmPassword: '' };

    const [state, setState] = useState(initialState);

    const [showError, setShowError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [saverity, setSaverity] = useState('error');
    const [isLoading,setIsLoading]=useState(false);

    const onChange = () => { }
    const handleSubmit = (validationErrors) => {
        let errorKeys = Object.keys(validationErrors);
        if (errorKeys.length == 0) {
            //validate user       
            let token = props.match.params.token;
            setIsLoading(true);
            authenticationService.resetPassword(values.password, token)
                .then(user => {
                    setIsLoading(false);
                    setErrorMessage('Password has been changed successfully')
                    setShowError(true);
                    setSaverity('success');
                    props.history.push(RouteConstants.COACH_LOGIN);
                },
                    error => {          
                        setIsLoading(false);              
                        setErrorMessage('Password can not reset, your token is expired. Please try again.');
                        if (error == "inactive") {
                            setErrorMessage('Your account is inactive.');
                        }
                        setSaverity('error');
                        setShowError(true);
                        //console.log(error);
                    }
                );
        }
    }

    const { errors, handleInputChange, handleOnSubmit, values } = useRegistrationForm(state, handleSubmit, ResetPasswordValidationSchema);

    //Move this to common components
    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    const [popoverOpen, setPopoverOpen] = useState(false);
    const handleOpen = () => setPopoverOpen(true);
    const handleClosePopOver = () => setPopoverOpen(false);



    const keyPress = (e) => {
        if (e.keyCode == 13) {
            handleSubmit();
        }
    }


    const gotoSignin = () => {
        props.history.push(RouteConstants.COACH_LOGIN);
    }

    const testedResult = zxcvbn(values.password);
    const createPasswordLabel = (result) => {
        switch (result.score) {
            case 0:
                return "Weak";
            case 1:
                return "Weak";
            case 2:
                return "Fair";
            case 3:
                return "Good";
            case 4:
                return "Strong";
            default:
                return "Weak";
        }
    };

    return (
        <div className={clsx("login-bg", classes.root)}>
            <Hidden smDown>
                <div style={{ flex: 2 }}></div>
            </Hidden>
            <Hidden mdDown>
                <div className={clsx("login-strip-bg", classes.logoStrip)}>
                    <img src={nbaLogo} className={classes.nbaLogo} />
                    <p className={clsx("appTitle")}>NBA Coaches Database</p>
                </div>
            </Hidden>
            <Hidden smDown>
                <div style={{ flex: 2 }}></div>
            </Hidden>

            <Paper className={classes.loginBox} elevation={10}>
                <Hidden lgUp>
                    <div className={clsx("app-logo-small", classes.logo)}></div>
                </Hidden>

                <div className={classes.sectiondivider}></div>
                <Typography variant="h5" color="secondary">Reset Password</Typography>
                <div className={classes.sectiondivider}></div>
                <div className={classes.sectiondivider}></div>
                <TextField
                    type="password"
                    id="coachPassword"
                    name="password"
                    variant="outlined"
                    onFocus={handleOpen}
                    onBlur={handleClosePopOver}
                    size="small"
                    label="New Password"
                    onChange={handleInputChange}
                    error={errors && errors.password && errors.password.error}
                    fullWidth
                ></TextField>

                <ErrorPopover target="coachPassword" isOpen={popoverOpen || (errors && errors.password && errors.password.error)} for="Password" />

                <div className="password-strength-meter">
                    <progress
                        value={testedResult.score}
                        className={`password-strength-meter-progress strength-${createPasswordLabel(testedResult)}`}
                        max="4"
                    />
                </div>

                {/* <Typography>{values.password}</Typography> */}
                <div className={classes.sectiondivider}></div>
                <TextField
                    type="password"
                    id="coachConfirmPassword"
                    variant="outlined"
                    size="small"
                    name="confirmPassword"
                    label="Confirm Password"
                    onChange={handleInputChange}
                    fullWidth
                    error={
                        errors && errors.confirmPassword && errors.confirmPassword.error
                    }
                ></TextField>
                <ErrorPopover target="coachConfirmPassword" isOpen={errors && errors.confirmPassword && errors.confirmPassword.error} for="ConfirmPassword" />


                <div className={classes.sectiondivider}></div>

                <Button color="secondary" variant="contained" className="capitalize" fullWidth onClick={handleOnSubmit}>Submit</Button>


                <div className={classes.sectiondivider}></div>
             
                   <Typography variant="caption" className="forgot-link">Remember Password?
                    </Typography>
                <Button className="create-btn" variant="text" onClick={gotoSignin}>
                    <Typography variant="caption">Sign In
                    </Typography>
                </Button>

                <Snackbar
                    open={showError}
                    autoHideDuration={6000}
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    onClose={() => setShowError(false)}
                >
                    <Alert onClose={() => setShowError(false)} severity={saverity}>
                        {errorMessage}
                    </Alert>
                </Snackbar>
            </Paper>
            <Loader isLoading={isLoading}/>

        </div>
    );
}

export default withRouter(ResetPassword);