import React, { useState, useContext, useEffect } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import UserList from '../UserList/UserList';
import adminService from "../../services/admin.service";
import SwipeableViews from 'react-swipeable-views';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import LinearProgress from '@material-ui/core/LinearProgress';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    notesHeader: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(1.5),
    },
    dialogHeight: {
        // minHeight: "500px",
        // maxHeight: "500px"
        overflow: 'hidden'
    },
    formLabel: {
        color: theme.palette.primary.main,
        fontWeight: 500,
    },
    userLabel: {
        paddingLeft: '10px',
    },
    addButton: {
        position: 'relative',
        top: theme.spacing(-4),
        paddingRight: theme.spacing(1)
    },
    progress: {
        marginLeft: '50%',

    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    dialogTabPanel: {
        '& > div': {
            padding: 0
        }
    }
}));

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
        backgroundColor: theme.palette.primary.main,
        color: '#fff'
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography className="font500" variant="subtitle1" noWrap>{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        // padding: '25px 16px',
        padding: '0px 0px',
        width: '500px',
        [theme.breakpoints.down(281)]: {
            width: '218px',
        },
        [theme.breakpoints.between(281, 322)]: {
            width: '258px',
        },
        [theme.breakpoints.between(321, 556)]: {
            width: '275px'
        },
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

const AddViewParticipantsDialog = (props) => {
    const classes = useStyles();
    const [selectedUsers, setSelectedUsers] = useState(props.userIds);
    const [value, setValue] = React.useState('0');
    const [loading, setLoading] = useState(false);
    const [users, setUsers] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [scrollTopList, setScrollTopList] = useState(0);

    useEffect(() => {
        setLoading(true);
        if (props.groupList && props.groupList.length > 0) {
            let id = props.groupList[value].id
            adminService.getUsersByGroupId(id)
                .then(res => {
                    setLoading(false);
                    if (res.length > 0) {
                        setUsers(res);
                    }
                })
                .catch(err => {
                    console.error(err);
                })
        }
        else {
            setLoading(false);
        }
    }, [value])

    const handleClose = (e, success) => {
        props.handleCloseClick(success);
    };

    const handleSearchTextChange = (text) => {
        setSearchText(text);
    }

    const TabPanel = (props) => {
        const { children, value, index, ...other } = props;

        return (
            <div style={{ overflow: 'auto' }}
                role="tabpanel"
                hidden={value !== index}
                id={`scrollable-auto-tabpanel-${index}`}
                aria-labelledby={`scrollable-auto-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box p={3}>
                        {children}
                    </Box>
                )}
            </div>
        );
    }
    const a11yProps = (index) => {
        return {
            id: `scrollable-auto-tab-${index}`,
            'aria-controls': `scrollable-auto-tabpanel-${index}`,
        };
    }

    const handleScrollChange = (scrollTop) => {
        setScrollTopList(scrollTop);
    }

    const handleSelectUser = (checked, id) => {
        let users = [...selectedUsers];
        
        if (checked) {
            setSelectedUsers([...users, id]);
        } else {
            let index = users.findIndex(x => x === id);
            users.splice(index, 1);
            setSelectedUsers(users);
        }
    }
    const handleAddClick = () => {
        if ((selectedUsers && selectedUsers.length > 0) || props.showOnlyFOGroup) {
            props.handleSaveClick({ memoId: props.memoId, userIds: selectedUsers })
        }
    }

    const handleChange = (event, newValue) => {
        //  setSelectedAllUser(uniqueSelectedUsers);
        setValue(newValue);
        setScrollTopList(0);
    };
    return (
        <div>
            <Dialog maxWidth={false} onClose={handleClose} aria-labelledby="customized-dialog-title" open={props.open}>
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                    Assign to user(s)
                </DialogTitle>
                <DialogContent dividers className={classes.dialogHeight}>
                    <Grid container alignItems='center'>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <div>
                                <AppBar position="static" color='primary'>
                                    <Tabs
                                        value={value}
                                        onChange={handleChange}
                                        indicatorColor="primary"
                                        variant="scrollable"
                                        scrollButtons="auto"
                                        aria-label="scrollable auto tabs example"
                                    >
                                        {props.groupList.length > 0 &&
                                            props.groupList.map((item, index) => {
                                                return <Tab key={index} value={`${index}`} label={item.name} {...a11yProps(`${index}`)} />
                                            })}
                                    </Tabs>
                                    {(loading) &&
                                        <LinearProgress color="secondary"></LinearProgress>}
                                </AppBar>

                                {props.groupList.length == 0 &&
                                    <Typography className="font500" variant="subtitle1" noWrap> No group found.</Typography>
                                }
                                 {props.groupList.length > 0 &&
                                    props.groupList.map((item, index) => {
                                        return <TabPanel className={classes.dialogTabPanel} value={value} index={`${index}`}>
                                            <UserList setScrollTop={handleScrollChange} scrollTopList={scrollTopList} searchText={searchText} onSearchTextChange={handleSearchTextChange} setSelectedUsers={setSelectedUsers} groupList={props.groupList} selectedUsers={selectedUsers} handleSelectUser={handleSelectUser} value={value} users={users} showCheckBox={false} showLegend ={props.showLegend}/>
                                        </TabPanel>
                                    })}
                            </div>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" variant='contained' onClick={handleAddClick}>Save</Button>
                </DialogActions>
            </Dialog>
        </div >
    );
}

export default AddViewParticipantsDialog;

