import React, { useState, useEffect,useRef } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import AdditionalInformation from "../../../sharedComponents/questionComponents/AdditionalInformation";
import Grid from "@material-ui/core/Grid";
import ListItem from "@material-ui/core/ListItem";
import SaveButton from "../../../sharedComponents/saveButton/SaveButton";
import BackButton from "../../../sharedComponents/saveButton/BackButton";
import useTelemetry from "../../../utils/useTelemetry";
import TitleWithTooltip from '../../../sharedComponents/TitleWithTooltip';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  section: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  grid: {
    padding: theme.spacing(1),
    alignSelf: "center",
  },
  question: {
    color: theme.palette.info,
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(1),
    },
  },
}));

const PastCoachingExperience = (props) => {
  const classes = useStyles();
  const theme = useTheme();  
  const container = useRef(null);
  const { trackCustomEvents } = useTelemetry();



  useEffect(() => {
    container.current.scrollIntoView({ block: 'end', behavior: 'smooth' });
  }, []);
 
  function saveOtherDetails() {    
    props.onChange(); 
    trackCustomEvents("EditEvent", { action: "Other Details" });   
  }

 
  return (
    <div ref={container} className={classes.root}>
      <div className={classes.section}>
        <TitleWithTooltip
          title="1. Is there any information we have not yet captured that you would like to include in your NBA Coaches Database profile? Please detail it here. (Some items to note could be key influences, mentors, and accomplishments.)"
          tooltipTitle="Other_ToolTip"
          variant="body2"
          class={classes.question}
        />
        
        <AdditionalInformation
          data={props.data}
          onChange={props.onOtherDetailChange}
          validationSchema={props.validationSchema.otherValidationSchema}
        />
      </div>
      <Grid item md={12} xs={12}>
        <ListItem style={{ justifyContent: "flex-end" }}>
          <BackButton            
            onClick={props.handlePreveStepClick}
          ></BackButton>
          <SaveButton
            isLoading={props.isLoading}
            onClick={saveOtherDetails}
          ></SaveButton>
        </ListItem>
      </Grid>
    </div>
  );
};

export default PastCoachingExperience;
