import React, {
    Fragment,
    useState,
    forwardRef,
    useEffect,
    useRef,
    useImperativeHandle,
} from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import ToolTip from "@material-ui/core/Tooltip";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import DeleteIcon from "@material-ui/icons/Delete";
import Helper from "../../common/Helper";

import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    grid: {
        padding: theme.spacing(1),
        alignSelf: "start",
        display: "flex",
    },
    gridIcons: {
        display: 'flex',
        '& button': {
            [theme.breakpoints.down("xs")]: {
                padding: theme.spacing(.75),
            },
        },
    },
    error: {
        borderColor: theme.palette.error.dark,
    },
    btnDel: {
        color: theme.palette.secondary.main,
    },
}));

let Data = [];
const EventExperienceList = (props) => {

    const [formDataCollection, setFormData] = useState([getEmptyItem()]);
    useEffect(() => {
        //if (props.data !== Data) {
        Data = props.data;
        let detail = formDataFromProp();
        if (Data.length == 0) {
            detail.push(getEmptyItem());
        }
        setFormData(detail);
        // }
    }, [props.data]);

    function formDataFromProp() {
        return props.data.map((item) => {
            return {
                id: item.id,
                eventName: {
                    value: item.events.Id,
                    label: item.events.Name,
                    validationMessage: "",
                    hasError: item.events.Name ? null: true,
                },
                experience: {
                    value: item.experience,
                    validationMessage: "",
                    hasError: null,
                },
            };
        });
    }



    function getEmptyItem() {
        let randomId = Helper.GetRandomId();
        return {
            id: randomId,
            eventName: {
                value: "",
                label: "",
                validationMessage: "",
                hasError: null,
            },
            experience: { value: "", validationMessage: "", hasError: null },
        };
    }

    const getEventData = () => {
        return props.eventList;
    };

    function handleInputChange(e, hasError) {
        let data = [...formDataCollection];
        let index = data.findIndex((x) => x.id === e.itemId);
        data[index] = e.formData;
        setFormData(data);
        if (!hasError || hasError == undefined)
            props.onChange(data, "onchange");
    }



    function addItem() {
        //... Add a new item to the formDataCollection array.
        let dataCollection = [...formDataCollection];
        let lastData = dataCollection[dataCollection.length - 1];

        if (lastData.eventName.value === "" || lastData.experience.value === "") {
            lastData.eventName.value === ""
                ? (lastData.eventName.hasError = true)
                : (lastData.eventName.hasError = false);
            lastData.experience.value === ""
                ? (lastData.experience.hasError = true)
                : (lastData.experience.hasError = false);
            handleInputChange({ itemId: lastData.id, formData: lastData }, true);
        } else {
            lastData.eventName.hasError = false;
            lastData.experience.hasError = false;
            dataCollection.push(getEmptyItem());
            //props.onChange(dataCollection);
            setFormData(dataCollection);
        }
    }

    function removeItem(itemId) {
        //... Remove an item from formDataCollection array.
        let DataCollection = [...formDataCollection];
        let index = DataCollection.findIndex((x) => x.id === itemId);
        DataCollection.splice(index, 1);
        props.onChange(DataCollection, "onchange");
        setFormData(DataCollection);
    }

    return (
        <Grid container>
            {formDataCollection.map((item, index) => {
                return (
                    <EventExperienceDetail
                        key={index}
                        addItem={addItem}
                        itemRow={item}
                        onChange={handleInputChange}
                        showAddOption={index === formDataCollection.length - 1}
                        removeItem={removeItem}
                        eventData={getEventData()}
                    />
                );
            })}
        </Grid>
    );
};

function EventExperienceDetail(props) {
    const classes = useStyles();
    const eventSelected = getTeamValue(props.itemRow.eventName.label);
    function handleAddClick() {
        props.addItem();
    }
    function handleInputChange(value, name) {
        let formData = props.itemRow;
        formData[name].value = value;
        formData[name].hasError = false;
        //... Call Parent
        props.onChange({ itemId: props.itemRow.id, formData: formData });
    }

    function handleInputChangeDDL(value, name) {
        let formData = props.itemRow;
        formData[name].value = value && value.Id ? value.Id : "";
        formData[name].label = value && value.Name ? value.Name : "";
        formData[name].hasError = false;
        //... Call Parent
        props.onChange({ itemId: props.itemRow.id, formData: formData });
    }

    function handelOnBlur(e, field) {
        if (e.target.value != "") {
            var isExist = {};
            isExist = props.eventData.find(x => x.Name === e.target.value)
            if (isExist === undefined) {
                let formData = props.itemRow;
                formData[field].value = "New";
                formData[field].hasError = false;
                formData[field].label = e.target.value;
                //... Call Parent
                props.onChange({ itemId: props.itemRow.id, formData: formData });

            }
        }
    }

    function getTeamValue(val) {
        let item = [...props.eventData];
        let sele = item.find((x) => x.Name === val);
        return sele || { Name: val, Id: "New" };
    }

    return (
        <Fragment>
            <Grid item md={12} xs={12} className={classes.grid}>
                <Autocomplete
                    value={eventSelected}
                    freeSolo
                    id={`team_${props.itemRow.id}`}
                    size="small"
                    options={props.eventData}
                    getOptionSelected={(option, { multiple, eventSelected }) => {
                        if (!multiple) {
                            if (eventSelected != null) {
                                return option.Id === eventSelected.value;
                            }
                            return false;
                        }

                        return false;
                    }}
                    // renderOption={(option) => option.eventName}
                    getOptionLabel={(option) => option.Name}
                    onChange={(event, newValue) => {
                        let val = newValue !== null ? newValue.Id : "";
                        handleInputChangeDDL(newValue, "eventName");
                    }}
                    onBlur={(e) => handelOnBlur(e, "eventName")}
                    style={{ width: "100%" }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                            error={props.itemRow.eventName.hasError}
                            label={<Typography variant="body2">Event(+)</Typography>}
                        />
                    )}
                />
            </Grid>
            <Grid item md={12} xs={12} className={classes.grid}>
                <TextField
                    id={`season_${props.itemRow.id}`}
                    error={props.itemRow.experience.hasError}
                    variant="outlined"
                    value={props.itemRow.experience.value}
                    onChange={(e) => handleInputChange(e.target.value, "experience")}
                    label={<Typography variant="body2">Describe Role</Typography>}
                    size="small"
                    multiline
                    rows={4}
                    fullWidth
                />
            </Grid>
            <Grid item md={1} xs={4} className={classes.gridIcons}>
                <ToolTip title="Remove">
                    <IconButton onClick={(e) => props.removeItem(props.itemRow.id)}>
                        <DeleteIcon className={classes.btnDel} >Delete</DeleteIcon>
                    </IconButton>
                </ToolTip>
                {props.showAddOption &&
                    <ToolTip title="Add more">
                        <IconButton onClick={handleAddClick}>
                            <AddCircleIcon color="primary" >Add</AddCircleIcon>
                        </IconButton>
                    </ToolTip>
                }
            </Grid>
        </Fragment>
    );
}

export default EventExperienceList;
