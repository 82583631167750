import { authHeader, handleResponse,handleError  } from '../helper';
import APIHelper from "../common/ApiHelper";
import APIURLConstants from "../common/ApiURLConstants";


export const jwplayerService = {
    getToken,
    getSignedUrlForProfileVideo
};

function getToken() {
    return APIHelper.get(APIURLConstants.GET_JWTOKEN, authHeader())
    .then(handleResponse)
    .then(token => {
        return token;
    }).catch(handleError);
}

function getSignedUrlForProfileVideo(username) {
    return APIHelper.get(APIURLConstants.GET_PROFILE_VIDEO + username, authHeader())
    .then(signedUrl => {
        return signedUrl;
    }).catch(handleError);
}