import React, { useState, useEffect, useContext } from "react";
import { referenceDataService, authenticationService, configService, appSettingsService } from "../services";
import APIURLConstants from "./ApiURLConstants";
import AppConstants from "./AppConstants";
import ContentReviewMessage from "./GetContentReviewMessage";
export const AppDataContext = React.createContext();

// let leagueOptions = [
//   { value: "nba", text: "NBA", show: true },
//   { value: "wnba", text: "WNBA", show: true },
//   { value: "nbagleague", text: "NBA G League", show: true },
//   { value: "summerleague", text: "Summer League", show: true },
//   { value: "ncaa", text: "College/University", show: true },
//   { value: "international", text: "International", show: true },
//   { value: "highschool", text: "High School", show: false },
//   { value: "other", text: "Other", show: true },
//   { value: "na", text: "N/A", show: true }]

export const AppDataProvider = (props) => {
  // const [nbaTeams, setNBATeams] = useState([]);
  // const [wnbaTeams, setWNBATeams] = useState([]);
  // const [nbaGLeagueTeams, setNBAGLeagueTeams] = useState([]);
  // const [ncaaTeams, SetncaaTeams] = useState([]);
  // const [internationalTeams, SetInternationalTeams] = useState([]);
  const [league, setLeague] = useState([]);
  const [leagueOptionsList, setLeagueOptionsList] = useState([]);
  // const [highschoolTeams, SetHighschoolTeams] = useState([]);
  const [positionTitle, setPositionTitle] = useState([]);
  const [coachTitle, setCoachTitle] = useState([]);
  const [titlesSubCategory, setTitlesSubCategory] = useState([]);

  const [skills, setSkills] = useState([]);
  const [language, setLanguage] = useState([]);
  const [degrees, setDegrees] = useState([]);
  const [schools, setSchools] = useState([]);
  const [teamList, setTeamList] = useState([]);
  const [awards, setAwards] = useState([]);
  const [notablePlayers, setNotablePlayers] = useState([]);
  const [eventList, setEventList] = useState([]);
  const [headCoachesList, setHeadCoachesList] = useState([]);
  const [loggedInUser, setLoggedInUser] = useState({});
  const [mobileView, setMobileView] = useState(false);
  const [canISeeCoachingTree, setCanISeeCoachingTree] = useState(false);
  const [androidURL, setAndroidURL] = useState('');
  const [iosURL, setIosURL] = useState('');
  const [proxyAPISettings, setProxyAPISettings] = useState({});

  const [postConfigValues, setPostConfigValues] = useState({
    returnedPostReviewMessage: null,
    approvedPostReviewMessage: null,
    pendingPostReviewMessage: null
  });

  const getLeagueTextByValue = (leagueValue) => {
    try {
      if (leagueOptionsList.length > 0 && leagueValue != undefined && leagueValue != "")
        return leagueOptionsList.find(x => x.value == leagueValue).text;
      else return "";
    }
    catch {
      return "";
    }
  }

  const getTeamsByLeague = (type) => {
    let list = teamList.filter(t => t.Type == type);
    return list.length > 0 && list ? list : [{ teamId: 0, teamName: "", logoURL: "" }];
  }

  useEffect(() => {
    let list = [];
    leagueOptionsList.forEach(element => {
      if (element.show)
        list.push(element.value);
    })
    setLeague(list);
  }, [leagueOptionsList])


  const providerValue = {
    // nbaTeams,
    // wnbaTeams,
    // nbaGLeagueTeams,
    // ncaaTeams,
    // internationalTeams,
    // highschoolTeams,
    positionTitle,
    titlesSubCategory,
    coachTitle,
    skills,
    language,
    degrees,
    schools,
    teamList,
    eventList,
    headCoachesList,
    awards,
    notablePlayers,
    league,
    leagueOptionsList,
    mobileView,
    setMobileView,
    getLeagueTextByValue: getLeagueTextByValue,
    getTeamsByLeague: getTeamsByLeague,
    loadReferenceData: () => {
      //return handleReferenceData();
    },
    canISeeCoachingTree,
    androidURL,
    iosURL,
    proxyAPISettings,
    postConfigValues
  };

  useEffect(() => {
    getPublicConfigKeys();

    authenticationService.currentUser.subscribe(x => {
      if (x) {
        handleReferenceData();
        getConfigValues();
        getProxyAPISettings();
        fetchPostConfigData();
      }
    });
  }, []);

  const getConfigValues = () => {
    configService.getConfigValue(AppConstants.COACHING_TREE_CONFIG_KEY)
      .then(data => {
        if (data) {
          if (data.key.toLowerCase() == AppConstants.COACHING_TREE_CONFIG_KEY.toLowerCase()) {
            setCanISeeCoachingTree(data.value);
          }
        }
      });
  };

  // Function to fetch post config data
  const fetchPostConfigData = async () => {
    try {
      const [returnedMessage, approvedMessage, pendingMessage] = await Promise.all([
        ContentReviewMessage.getPostReviewMessage(AppConstants.REVIEW_STATUS.RETURNED),
        ContentReviewMessage.getPostReviewMessage(AppConstants.REVIEW_STATUS.APPROVED),
        ContentReviewMessage.getPostReviewMessage(AppConstants.REVIEW_STATUS.PENDING)
      ]);

      setPostConfigValues({
        returnedPostReviewMessage: returnedMessage,
        approvedPostReviewMessage: approvedMessage,
        pendingPostReviewMessage: pendingMessage
      });
    } catch (error) {
      console.error('Error fetching config values:', error);
    }
  }; 

  const getPublicConfigKeys = () => {
    configService.getPublicConfigsValue()
      .then(data => {
        if (data && data.length > 0) {
          let android = data.find(x => x.key === AppConstants.ANDROID_URL_KEY)
          setAndroidURL(android !== null ? android.value : '')
          let ios = data.find(x => x.key === AppConstants.IOS_URL_KEY)
          setIosURL(ios !== null ? ios.value : '')
        }
      });
  };

  const handleReferenceData = () => {
    referenceDataService.getLeagues()
      .then(data => {
        if (data) {
          let fieldNameChangesForLeagues = data.map(l => { return { value: l.Type, text: l.Name, show: l.Show, order: l.DisplayOrder } });
          setLeagueOptionsList(fieldNameChangesForLeagues);
        }
      });

    referenceDataService.getTeams()
      .then(data => {
        setTeamList(data);
      });


    referenceDataService.getDegrees()
      .then(data => {
        setDegrees(data);
      });

    referenceDataService.getTitles()
      .then(data => {
        setPositionTitle(data);
      });

    referenceDataService.getTitlesSubCategory()
      .then(data => {
        setTitlesSubCategory(data);
      });

    referenceDataService.getCoachTitles()
      .then(data => {
        setCoachTitle(data);
      });

    referenceDataService.getAwards()
      .then(data => {
        setAwards(data);
      });

    referenceDataService.getPlayers()
      .then(data => {
        setNotablePlayers(data);
      });

    referenceDataService.getSchools()
      .then(data => {
        setSchools(data);
      });

    referenceDataService.getSkills()
      .then(data => {
        setSkills(data);
      });

    referenceDataService.getLanguages()
      .then(data => {
        setLanguage(data);
      });

    referenceDataService.getEvents()
      .then(data => {
        setEventList(data);
      });

    referenceDataService.getHeadCoaches()
      .then(data => {
        setHeadCoachesList(data);
      });
  }

  const getProxyAPISettings = () => {
    appSettingsService.getProxyAPISettings()
      .then(data => {
        if (data) {
          const settings = {
            "CoachesDBProxyAPIBaseURL": data.CoachesDBProxyAPIBaseURL,
            "CoachesDBProxyAPISecretKey": data.CoachesDBProxyAPISecretKey
          }
          setProxyAPISettings(settings);
        }
      });
  };

  return (
    <AppDataContext.Provider value={providerValue}>
      {props.children}
    </AppDataContext.Provider>
  );
};

export function useAppData() {
  return useContext(AppDataContext);
}

export const AppDataConsumer = AppDataContext.Consumer;

export default AppDataContext;
