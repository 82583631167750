import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Grid,
    IconButton,
    TextField,
    Typography,
    makeStyles,
    useMediaQuery,
    useTheme,
    withStyles
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
    },
    grid: {
        display: "flex",
        flexGrow: 1,
        flexWrap: "wrap",
        paddingTop: theme.spacing(1),
        backgroundColor: theme.palette.background.default,
        [theme.breakpoints.up("sm")]: {
            padding: theme.spacing(1),
        },
        [theme.breakpoints.down("xs")]: {
            padding: 0,
        },
    },
    noteBox: {
        width: "100%",
    }
}));

const CustomDialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <DialogTitle disableTypography className={classes.root} {...other}>
            <Typography color="secondary">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
});

const ReviewContentDialog = ({
    title,
    reviewMessageFunction,
    onApproveOrReject,
    onClose,
    open,
    actionText,
    showNotifyCheck
}) => {
    const classes = useStyles();
    const [note, setNote] = useState('');
    const [sendMail, setSendMail] = useState(true);
    const [showError, setShowError] = useState("");



    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
        const setReviewNote = async () => {
            const reviewMessage = await reviewMessageFunction();
            setNote(reviewMessage);
        }

        setReviewNote();
    }, [reviewMessageFunction]);

    const handleClose = () => {
        onClose();
    };

    const handleChange = (e) => {
        setSendMail(e.target.checked);
    }

    const handleActionButtonClick = () => {
        if (!note.trim()) {
            setShowError('Reason is required.');
        } else {
            onApproveOrReject(note, sendMail);
            setShowError("");
        }
    };


    return (
        <Dialog onClose={handleClose}
            fullScreen={fullScreen}
            fullWidth
            maxWidth="xs" aria-labelledby="customized-dialog-title" open={open}>
            <CustomDialogTitle id="customized-dialog-title" onClose={handleClose}>
                <Typography color="secondary">{title}</Typography>
            </CustomDialogTitle>
            <DialogContent>
                <Grid container>
                    <Grid className={classes.grid}>
                        <TextField
                            fullWidth
                            variant='outlined'
                            multiline
                            value={note}
                            className={classes.noteBox}
                            rows={4}
                            onChange={(e) => {
                                setNote(e.target.value);
                                setShowError("");

                            }}
                            label="Reason"
                            required
                            error={showError}
                        />
                        {showNotifyCheck &&
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={sendMail}
                                        onChange={handleChange}
                                        name="sendEmail"
                                        title="Notify all users"
                                        color="primary"
                                    />
                                }
                                label="Notify all users"
                            />
                        }
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleActionButtonClick} style={{ color: actionText === "APPROVE" ? theme.palette.success.dark : theme.palette.error.dark }}>
                    {actionText}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

ReviewContentDialog.propTypes = {
    title: PropTypes.string.isRequired,
    reviewMessageFunction: PropTypes.func.isRequired,
    onApproveOrReject: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    actionText: PropTypes.string.isRequired,
    showNotifyCheck: PropTypes.bool.isRequired
};

export default ReviewContentDialog;