import { Paper } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import Helper from "../../common/Helper";
import { userService } from '../../services';
import ProfileList from "../../sharedComponents/ProfileCard/ProfileList";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        [theme.breakpoints.up('sm')]: {},
        paddingTop: 10
    },
}));

export default function ListContainer(props) {

    const classes = useStyles();
    const [role, setRole] = useState();

    useEffect(() => {
        setRole(userService.getUserRole())
    }, []);

    return (
        <div className={classes.root}>
            <Grid container>
                <TableContainer component={Paper}>
                    <Table size="small" aria-label="a dense table">
                        <TableBody>
                            {props.profileList.map((value, index) => {
                                return <ProfileList userName={props.userName} handleEditProfileClick={props.handleEditProfileClick}
                                    handleViewProfileClick={props.handleViewProfileClick}
                                    getLeagueTextByValue={props.getLeagueTextByValue} key={Helper.GetRandomId()}
                                    handleBookMarked={props.handleBookMarked} handleProfileStatusChange={props.handleProfileStatusChange}
                                    container={props.container} handleOpenDialog={props.handleOpenDialog} data={value}
                                    handleProfileChecked={props.handleProfileChecked}
                                    selectedProfiles={props.selectedProfiles}
                                    role={role}
                                ></ProfileList>
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </div>
    );

}
