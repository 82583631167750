import React, { useContext, useState, useEffect } from 'react';
import { useHistory, useParams, withRouter } from 'react-router-dom';
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import { Paper, Typography } from '@material-ui/core';
import Checkbox from "@material-ui/core/Checkbox";
import './FilterableData.css';
import Tooltip from '@material-ui/core/Tooltip';
import EditTwoToneIcon from '@material-ui/icons/EditTwoTone';
import DeleteTwoToneIcon from '@material-ui/icons/DeleteTwoTone';
import IconButton from "@material-ui/core/IconButton";
import FilterableTable from '../../thirdParty/react-filterable-table/Components/FilterableTable';

const useStyles = makeStyles(theme => ({
    root: {
        margin: 10,
        flexGrow: 1,
    },
    btnAssistants: {
        backgroundColor:'#D3D3D3',
        color: '#000',
    },
    groupContent: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        overflow: 'auto',
        height: 'auto',
    },
    cursorPointer: {
        cursor: 'pointer',
    },
    customFilterableTable :{
        width: '100%',
        borderCollapse: 'collapse',
        '& th': {
            background: '#316ad8',
            color: '#fff',
        },
        '& th, & td': {
            padding: theme.spacing(.75),
            borderBottom: '1px solid #ccc',
            fontSize: '0.875rem',
        },
        '& th:first-child,  & td:first-child': {
            textAlign: 'left',
            width : '5%'
        },
        '& th:last-child, & td:last-child': {
            textAlign: 'right',
        },

    }
}));

function RefDataContainer(props) {
    const classes = useStyles();
    const [currentPage, setCurrentPage] = useState(0);

   
    const renderAction = (data) => {
        return (
            <>
                <Tooltip title="Edit" onClick={() => props.onEditClick(data.record)} style={{ cursor: "pointer" }}>
                    <EditTwoToneIcon color="primary" />
                </Tooltip>
                <Tooltip title="Delete" onClick={()=>props.onDeleteClick(data.record.Id)} style={{ cursor: "pointer" }}>
                    <DeleteTwoToneIcon color="primary" />
                </Tooltip>
            </>
        );
    };

    
    const renderCheckBox = (data) => {
        return (
            <Checkbox
                name="chkGroup"
                id={data.record.Id}
                checked={checkRefCheckbox(data.record.Id)}
                onChange={(event) => props.handleRefChecked(event,data.record.Id)}>
            </Checkbox>
        )
    };

    const checkRefCheckbox = (id) => {
		return props.selectedData?  props.selectedData.includes(id):false;
	}

    const fields = [
        { name: 'Id', displayName: "", inputFilterable: false, sortable: false, render: renderCheckBox },
        { name: 'Name', displayName: "Name", inputFilterable: true, sortable: true},
        { name: 'Id', displayName: "Action", inputFilterable: false, sortable: false, exactFilterable: true, render: renderAction },

    ];

    
    return (
        <div className="repsContainer" style={{ overflow: 'hidden' }}>
            <div className={classes.groupContent}>
                <Paper>
                    {props.data && <FilterableTable
                        filter={'behind'}
                        namespace="Assistant Coaches"
                        initialSort="name"
                        data={props.data}
                        fields={fields}
                        noRecordsMessage="There is no data available"
                        noFilteredRecordsMessage="No data match your filters!"
                        pageSize={12}
                        pageSizes={[12, 24, 48, 72]}
                        topPagerVisible ={false}
                        tableClassName ={classes.customFilterableTable}
                        maintainPageOnSetData
                        currentPage={props.currentPage}
                        onPageChange={props.onPageChange}
                        currentFilterText={props.currentFilterText}
                        onFilterTextChange={props.onFilterTextChange}
                    />
                    }
                    {/* <TableContainer component={Paper} className={classes.table}>
                        <Table size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell style={{ width: '60%' }}>Name</StyledTableCell>
                                    <StyledTableCell align="right" style={{ width: '40%' }}>Action</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {props.data && props.data.map((item, index) => {
                                    return <ManageItem
                                        key={index}
                                        data={item}
                                        onDeleteClick={props.onDeleteClick}
                                        onEditClick={props.onEditClick}
                                    />
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer> */}
                </Paper>
            </div>
        </div>
    );
};
export default withRouter(RefDataContainer);